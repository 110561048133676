import * as ng from 'angular';
import { LoginController } from './LoginController';
import { NavigationService } from '@app/shared/services/navigation/navigation.service';
import { NavigationSection } from '@app/shared/services/navigation/navigation-section';
import { NavigationSectionInfo } from '@app/shared/services/navigation/navigation-section-info';
import IRouteParamsService = ng.route.IRouteParamsService;

export class LoginConfig {
  private static ROUTE: string;

  private static routeProvider($routeProvider: ng.route.IRouteProvider) {
    $routeProvider.when(LoginConfig.ROUTE, {
      template: require('raw-loader!./login.html').default,
      controller: [
        '$scope',
        'SessionService',
        '$routeParams',
        'LoginNavigationInfo',
        'LoginMethodsService',
        LoginController
      ],
      controllerAs: 'login'
    });
  }

  static run(
    navigationService: NavigationService,
    navigationServiceInfo: NavigationSectionInfo
  ) {
    navigationService.addSectionToSiteMap(
      new NavigationSection(navigationServiceInfo, () => '/login')
    );
  }

  static configure(route: string) {
    this.ROUTE = route;
    ng.module('portal')
      .config(['$routeProvider', this.routeProvider])
      .run(['NavigationService', 'LoginNavigationInfo', this.run]);
  }
}

export interface ILoginRouteParamsService extends IRouteParamsService {
  redirectUrl: string;
}
