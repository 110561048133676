<div class="buttons-container">
  @if(showAutoRefresh) {
    <button class='btn self-center' [ngClass]='{ "hover": autoRefresh }' (click)='setAutoRefresh()'
      [matTooltip]="autoRefresh ? 'Stop auto refresh' : 'Enable auto refresh'" data-cy="auto-refresh" mat-button>
      <i class="material-icons text-red-500">{{autoRefresh ? 'stop_circle':'play_circle'}}</i>
    </button>
    @if(autoRefresh) {
      <app-refresh-timer [elapsedTimeInSeconds]="120" (timerExpired)="refreshPage()" class="flex px-2"></app-refresh-timer>
    }
  }
  @if(!autoRefresh) {
    <button class='btn' (click)='refreshPage()' matTooltip="Refresh" data-cy="refresh" mat-button>
      <i class="material-icons text-red-500" [ngClass]='{"image": refresh}'>autorenew</i>
    </button>
  }
</div>