export class ColorUtils {
  public static generateColor(min = 8, max = 14, generateFraction: () => number = () => Math.random()): string {
    let color = '';

    for (let i = 0; i < 6; i++) {
      const randNumber = min + generateFraction() * (max - min);
      color += Math.round(randNumber).toString(16);
    }

    return color;
  }

  public static getConstrastingGrey(bgColor: string): string {
    return this.isDark(bgColor) ? '#FFFFFF' : '#555555';
  }

  public static isDark(bgColor: string): boolean {
    let dark = true;
    if (bgColor && bgColor.length === 3) {
      const r = parseInt(bgColor.substring(0, 1), 16);
      const g = parseInt(bgColor.substring(1, 2), 16);
      const b = parseInt(bgColor.substring(2, 3), 16);
      if (g > 12) {
        dark = false;
      }
    } else if (bgColor && bgColor.length === 6) {
      const r = parseInt(bgColor.substring(0, 2), 16);
      const g = parseInt(bgColor.substring(2, 4), 16);
      const b = parseInt(bgColor.substring(4, 6), 16);
      if (g > 189) {
        dark = false;
      }
    }

    return dark;
  }
}
