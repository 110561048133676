import * as angular from 'angular'; // Automatically added
import { Map } from '@angularjs/or/util/Map';
import { UserRole } from '@angularjs/or/api/auth/UserRole';
import { Role } from '@angularjs/or/api/auth/Role';
import { IUserService } from '@angularjs/or/services/IUserService';
import { Action } from '@angularjs/or/delegates/Delegates';
import { MultiselectOption } from '../or-multiselect/MultiselectOption';
import IScope = angular.IScope;

export class OrFormUserDetailsController {
  private roleOptions: MultiselectOption<number>[];
  private originalRoles: Role[];

  public isBusy: boolean;
  public roles: Role[];
  public roleIds: number[];
  public details: UserRole;
  public isEditMode = false;
  public onDeleteUser: Action;

  constructor(private scope: IScope, private userService: IUserService) {}

  public $onInit() {
    this.roleOptions = this.roles.map(
      (role) => new MultiselectOption(role.name, role.id)
    );
    this.details.roleIds = this.details.roles.map((role) => role.id);
    this.roleIds = angular.copy(this.details.roleIds);
  }

  public produceUserThumbnailStyle(userRole: UserRole) {
    const style: Map<string> = {};
    if (userRole.user.pictureUrl) {
      style['background-image'] = 'url(' + userRole.user.pictureUrl + ')';
    }
    return style;
  }

  public produceUserClass(userRole: UserRole) {
    const classes: Map<boolean> = {};
    if (!userRole.user.pictureUrl) {
      classes['or-icon-userpic'] = true;
      classes['or-form-user-details-dafault-image'] = true;
    }

    return classes;
  }

  public startEditMode() {
    this.isEditMode = true;
  }

  public save() {
    this.userService
      .updateUserRoles(this.details.userId, this.details.roleIds)
      .then(() => {
        this.scope.$apply(() => {
          this.roleIds = angular.copy(this.details.roleIds);
          this.isEditMode = false;
        });
      });
  }

  public discard() {
    this.isEditMode = false;
    this.reset();
  }

  public delete() {
    if (
      !confirm(`Delete user ${'"' + (this.details.user.name || 'N/A') + '"'}?`)
    ) {
      return;
    }
    this.onDeleteUser();
  }

  public reset() {
    this.details.roleIds = angular.copy(this.roleIds);
  }

  public getRoleOptions(): MultiselectOption<number>[] {
    if (this.roleOptions == null) {
      this.roleOptions = this.roles.map(
        (role) => new MultiselectOption(role.name, role.id)
      );
    }

    return this.roleOptions;
  }
}
