import { Injectable } from '@angular/core';
import { Building } from '@app/shared/models/building.interface';
import {
  CheckboxState,
  FloorplanTag,
  SelectableWithTags,
  Tag,
  tagsToFloorplanTags
} from '@app/shared/models/tag.interface';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { TagResource } from '@app/shared/resources/tag.resource';
import { SavedEntity } from '@app/shared/models/saved-entity.interface';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TagService {
  private tagsForFloor = new BehaviorSubject<FloorplanTag[]>([]);
  constructor(private resource: TagResource) {}

  get tagsForFloor$(): Observable<FloorplanTag[]> {
    return this.tagsForFloor.asObservable();
  }

  clearSelection(): void {
    const currentTags = this.tagsForFloor.value;
    currentTags.forEach((tag) => {
      tag.checked = CheckboxState.UNCHECKED;
    });
    this.tagsForFloor.next(currentTags);
  }

  addTagsFromSelectedNodes(nodes: SelectableWithTags[]): void {
    const tags: Tag[] = [];
    nodes.forEach((node) => {
      if (Array.isArray(node.tags) && node.tags.length > 0) {
        node.tags.forEach((tag) => {
          if (tags.find((t) => t.id === tag.id) == null) {
            tags.push(tag);
          }
        });
      }
    });
    // marking the tags as checked based on tags from the selected nodes
    tags.forEach((tag) => {
      const currentTags = this.tagsForFloor.value;
      const index = currentTags.findIndex((t) => t.id === tag.id);
      if (index > -1) {
        currentTags[index].checked = CheckboxState.CHECKED;
      }
    });
  }

  getTags(building: Building, editable: boolean): Observable<FloorplanTag[]> {
    if (editable) {
      return this.resource.getEditableTagsForBuilding(building.id).pipe(
        distinctUntilChanged(),
        shareReplay(),
        map((tags) => {
          const result = tagsToFloorplanTags(tags);
          this.tagsForFloor.next(result);
          return result;
        })
      );
    } else {
      return this.resource.getTagsForBuilding(building.id).pipe(
        distinctUntilChanged(),
        shareReplay(),
        map((tags) => {
          const result = tagsToFloorplanTags(tags);
          this.tagsForFloor.next(result);
          return result;
        })
      );
    }
  }

  getTagsForBuildingId(id: number, editable: boolean, skipTagsWithNoEMDrivers?: boolean): Observable<Tag[]> {
    if (editable) {
      return this.resource.getEditableTagsForBuilding(id, skipTagsWithNoEMDrivers).pipe(
        distinctUntilChanged(),
        shareReplay(),
        tap((tags) => this.tagsForFloor.next(tagsToFloorplanTags(tags)))
      );
    } else {
      return this.resource.getTagsForBuilding(id, skipTagsWithNoEMDrivers).pipe(
        distinctUntilChanged(),
        shareReplay(),
        tap((tags) => this.tagsForFloor.next(tagsToFloorplanTags(tags)))
      );
    }
  }

  getTagsByIds(tagIds: number[]): Observable<Tag[]> {
    return this.resource.getTagsFromIds(tagIds);
  }

  getTag(id: number): Observable<Tag> {
    return this.resource.retrieve(id);
  }

  createTag(tag: Tag): Observable<SavedEntity<Tag, number>> {
    return this.resource.add(tag);
  }

  deleteTag(id: number): Observable<{}> {
    return this.resource.delete(id);
  }

  updateTag(id: number, tag: Tag): Observable<{}> {
    return this.resource.update(id, tag);
  }

  decorateTag(tag: Tag): { 'background-color': string } {
    return {
      'background-color': '#' + tag.color
    };
  }
}
