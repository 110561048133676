import { Component, OnInit } from '@angular/core';
import { Tab } from '@app/shared/components/tabs/tab.interface';
import { Building } from '@app/shared/models/building.interface';
import { User } from '@app/shared/models/user.interface';
import { HeaderService } from '@app/shared/services/header.service';
import { UserService } from '@app/shared/services/user/user.service';
import { combineLatest, concatMap, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { administrationTabs } from './administration-tabs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.scss']
})
export class AdministrationComponent implements OnInit {
  public tabs$: Observable<Tab[]>;

  constructor(
    private readonly header: HeaderService,
    private readonly userService: UserService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.header.showBuildingsMenu();
    this.header.showSiteMenu();
    this.header.showUserMenu();
    this.header.showSessionMenu();
    this.header.hideFloorsMenu();

    this.tabs$ = combineLatest([
      this.userService.getCurrentUser(),
      this.route.params.pipe(
        concatMap((params) => {
          const { buildingId } = params;
          return this.userService.getBuilding(buildingId);
        })
      )
    ]).pipe(
      map(([user, building]): Tab[] => {
        return this.createAdministrationMenu(user, building);
      })
    );
  }

  private createAdministrationMenu(user: User, building: Building): Tab[] {
    return administrationTabs.reduce<Tab[]>((acc, curr) => {
      if (curr.isAuthorized(user, building)) {
        return [...acc, curr];
      }
      return acc;
    }, []);
  }
}
