import { TagService } from '@angularjs/or/services/TagService';
import { SensorNodeService } from '@angularjs/or/services/SensorNodeService';
import { Map } from '@angularjs/or/util/Map';
import { SensorNode } from '@angularjs/or/api/building/SensorNode';
import { DataType } from '@angularjs/or/data/SensorNodeDataType';
import { MappingService } from '@angularjs/or/services/MappingService';
import { FloorplanSensorNode } from '@angularjs/or/api/building/FloorplanSensorNode';
import { ISelectableContext } from '@angularjs/or/api/query/outline/context/IContext';
import { EmergencyLightingTestType } from '@angularjs/or/data/EmergencyLightingTestType';
import { IObservableModifiable } from '@angularjs/or/util/IObservable';
import { ColorUtils } from '@angularjs/or/util/ColorUtils';
import { LightingConfigurationService } from '@angularjs/or/services/LightingConfigurationService';
import { UIRefreshService } from '@angularjs/or/services/UIRefreshService';
import { SensorNodeAlert } from '@angularjs/angular/modules/layout/or-sensor-node/SensorNodeAlert';
import * as angular from 'angular';

export class OrSensorNodeController {
  public x: number;
  public y: number;
  public buildingId: number;
  public zoomLevel: number;
  public dataType: DataType;
  public details: FloorplanSensorNode;
  public isNotifying: boolean;
  public markFaulty: boolean;
  public markEmergencyLight: boolean;
  public notificationMode: boolean;
  public isEmergencyLightingTestModeActive: boolean;
  public isManualFunctionalTestModeActive: boolean;
  public isManualDurationTestModeActive: boolean;
  public isCancelTestModeActive: boolean;
  public hasTestBeenActivated = false;
  public disableSelection: boolean;
  public isCumulativeSelectionActive: boolean;
  public showNodeProperties: boolean;
  private gatewayToColorWheelIndexMap: Map<number> = {}; // TODO Is this needed? Im not sure where the Colour wheel thing is and it looks like it needs relay mode on the MeshNode to be enabled?
  public gatewayToNodesMap: Map<SensorNode[]> = {};
  public isGatewayModeActive: boolean;
  public isHighlighted = false;
  public isChanged = false;
  public activePage: string;
  public alerts: SensorNodeAlert[];
  private refreshNodesAfterMap;
  private activityPromise;
  private waitingTimeForPostMappingPause = 200;

  constructor(
    private scope: ng.IScope,
    private $timeout: angular.ITimeoutService,
    private element,
    private tagService: TagService,
    private uiRefreshService: UIRefreshService,
    private nodeService: SensorNodeService,
    private mappingService: MappingService,
    private selectableContext: IObservableModifiable<ISelectableContext>,
    public lightingConfigurationService: LightingConfigurationService
  ) {}

  public $onInit(): void {
    this.nodeService.getGatewayToSensorNodesMap().then((gatewayToNodesMap: Map<SensorNode[]>) => {
      let index = 0;
      this.gatewayToNodesMap = gatewayToNodesMap;
      // tslint:disable-next-line:forin
      for (const gatewayAddress in gatewayToNodesMap) {
        this.gatewayToColorWheelIndexMap[gatewayAddress] = index;
        index++;
      }
    });
    if (this.activePage === 'lighting-configuration' || this.activePage === 'beacons') {
      this.nodeService.registerToFloorplanNodes(this.details);
    }

    this.nodeService.refreshFloorPlanNodes.subscribe(() => {
      this.$timeout(() => {
        this.alerts = this.details.alerts;
      }, 10);
    });
    this.alerts = this.details.alerts;
  }

  public isNodeSelected(): boolean {
    return this.nodeService.isNodeSelected(this.details.id);
  }

  private isAnyTestModeActive(): boolean {
    return this.isManualFunctionalTestModeActive || this.isManualDurationTestModeActive || this.isCancelTestModeActive;
  }

  private shouldCancelTest(): boolean {
    if (!this.details.emDrivers?.some((driver) => driver.activeTest)) {
      alert('No functional or duration test is currently running for this node.');
      return false;
    }
    // TODO: Remove/Fix it when EM test bug been fixed.
    if (!this.details.emDrivers?.some((driver) => driver.inProgressTest)) {
      alert(
        'It is not possible to cancel a test unless it is ‘running’.' +
          ' If the test does not transition to ‘running’ it will time out in approximately 2 minutes.'
      );
      return false;
    }
    // TODO: Remove/Fix it when EM test bug been fixed.
    if (this.details.isDriverEmergencyLightingTestStarted) {
      alert(
        'Another driver in this setup has already started an emergency lighting test. Please wait ' +
          "until it progresses into 'running' state before starting or canceling a new test."
      );
      return false;
    }

    return confirm('Are you sure you wish to cancel the running test for this node?');
  }

  private shouldRunTest(testType: string): boolean {
    // TODO: Remove/Fix it when EM test bug been fixed.
    if (this.details.emDrivers?.some((driver) => driver.inInitiatedTest)) {
      alert("Please wait until test progresses into 'running' state before starting a new test.");
      return false;
    }
    // TODO: Remove/Fix it when EM test bug been fixed.
    if (this.details.isDriverEmergencyLightingTestStarted) {
      alert(
        'Another driver in this setup has already started an emergency lighting test. Please wait ' +
          "until it progresses into 'running' state before starting a new test."
      );
      return false;
    }
    // TODO: Remove/Fix it when EM test bug been fixed.
    if (!this.hasRunningTest() && this.details.isTooManyDriverEmergencyLightingTest) {
      alert('Emergency lighting test for all drivers cannot be run at the same time.');
      return false;
    }

    if (confirm('Are you sure you wish to run a ' + testType + ' test for this node?')) {
      if (this.hasRunningTest()) {
        return confirm(
          'A test is already running on this node.\r\n Running a new test cancels the current one. Continue Anyway?'
        );
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  private hasRunningTest(): boolean {
    return (
      this.details.emDrivers?.some((driver) => driver.inProgressTest) ||
      this.details.emDrivers?.some((driver) => driver.inInitiatedTest)
    );
  }

  private includesEM(): boolean {
    // TODO: Remove/Fix it when EM test bug been fixed.
    // Temporary disable running test from parent node.
    // return this.details.getTotalEMDriver > 0 || this.details.isEmergencyDevice;
    return this.details.emDrivers?.length > 0;
  }

  public onClick(): void {
    if (this.isAnyTestModeActive() && !this.includesEM()) {
      alert('It is not possible to take any test action on a non-emergency device.');
      return;
    }

    if (this.isManualFunctionalTestModeActive) {
      if (this.shouldRunTest('functional')) {
        this.nodeService
          .startEmergencyLightingTestBatch(
            this.buildingId,
            this.details.emDrivers?.map((driver) => driver.id),
            EmergencyLightingTestType.FUNCTION
          )
          .then(() => {
            this.scope.$apply(() => (this.hasTestBeenActivated = true));
          });
      }
    } else if (this.isManualDurationTestModeActive) {
      if (this.shouldRunTest('duration')) {
        this.nodeService
          .startEmergencyLightingTestBatch(
            this.buildingId,
            this.details.emDrivers?.map((driver) => driver.id),
            EmergencyLightingTestType.DURATION
          )
          .then(() => {
            this.scope.$apply(() => (this.hasTestBeenActivated = true));
          });
      }
    } else if (this.isCancelTestModeActive) {
      if (this.shouldCancelTest()) {
        this.nodeService.cancelEmergencyLightingTestBatch(
          this.buildingId,
          this.details.emDrivers?.map((driver) => driver.id)
        );
      }
    } else if (this.notificationMode) {
      if (this.details.properlyMapped) {
        if (this.details.nodeType === SensorNode.PASSIVE_NODE_TYPE) {
          alert("Can't unmap PN when on SN3 mapping mode");
        } else {
          this.mappingService.unmapSN3s([this.details]).then(() => {
            if (this.refreshNodesAfterMap) {
              clearTimeout(this.refreshNodesAfterMap);
            }
            this.refreshNodesAfterMap = setTimeout(() => {
              this.nodeService.requestNodes.next(true);
            }, 3000);
          });
        }
        return;
      }

      if (
        !this.isNotifying &&
        this.details.address == null &&
        !this.mappingService.isActivityInProgress &&
        !this.mappingService.isActivityWaiting
      ) {
        this.mappingService.mappingTried = true;
        this.isNotifying = true;
        this.mappingService.setActivityWaiting(true);
        this.performMappingActivity();
      }
    } else {
      this.updateSelection(this.details);
    }
  }

  private performMappingActivity() {
    this.activityPromise = this.$timeout(() => {
      if (!this.mappingService.isPostActivityInProgress) {
        this.mappingService
          .doMapping(this.details.id, this.buildingId)
          .then(() => {
            this.scope.$apply(() => {
              this.isNotifying = false;
              this.details.hasMappingBeenAttempted = true;
              this.stopMappingActivity();
            });
          })
          .catch(() => {
            this.scope.$apply(() => {
              this.isNotifying = false;
              this.stopMappingActivity();
            });
            console.error('Mapping failed.', this.details.id);
          });
        this.mappingService.setActivityWaiting(false);
      } else {
        this.performMappingActivity();
      }
    }, this.waitingTimeForPostMappingPause);
  }

  private stopMappingActivity() {
    this.$timeout.cancel(this.activityPromise);
  }
  public onMouseLeave($event: MouseEvent): void {
    this.showNodeProperties = false;
  }

  public onMouseEnter($event: MouseEvent): void {
    if ($event.buttons === 0) {
      this.showNodeProperties = true;
    }
  }

  public get propertiesStyle(): Map<string> {
    const style: Map<string> = {};
    const transform = 'translate(-50%, -8px) scale(' + 1 / this.zoomLevel + ')';
    style.transform = transform;
    style['-webkit-transform'] = transform;

    return style;
  }

  public produceNodeStyle(): Map<string> {
    const style: Map<string> = {};
    style.left = this.x + 'px';
    style.top = this.y + 'px';
    return style;
  }

  public updateSelection(targetNode): void {
    if (this.disableSelection) {
      return;
    }
    if (targetNode.id >= 0) {
      if (!this.isCumulativeSelectionActive) {
        this.nodeService.clearSelection();
        this.tagService.clearSelection();
      }
      this.nodeService.updateSelection(targetNode, this.isEmergencyLightingTestModeActive);
      this.tagService.selection = this.tagService.getTagsFromSelection();
      this.tagService.updateTagList();

      this.selectableContext.change((ctx) => {
        const nodeIds = this.nodeService.selectedNodes.value().map((node) => node.id);
        ctx.sensorNodeIds = nodeIds;
      });
    }
  }

  private isRelay(): boolean {
    return false;
  }

  public produceClassForSensorNode(): {} {
    const styleClass: { [p: string]: any } = {};
    const indexInColorWheel = this.gatewayToColorWheelIndexMap[1] % 10;
    const selected = this.isNodeSelected();
    const hasLightLevel = this.details.lightLevel && this.details.lightLevel > 0;
    const showLightLevel = this.activePage === 'heatmap' && hasLightLevel;
    // if in gateway mode AND this node is relaying
    styleClass['or-ripple-color-wheel-' + indexInColorWheel] = this.isGatewayModeActive && this.isRelay();
    styleClass['sensor-node-regular-shadow'] = !this.isRelay();
    styleClass['sensor-node-light-level'] = showLightLevel && !selected && !this.isRelay();
    styleClass.selected = !this.disableSelection && this.isNodeSelected();
    styleClass.faulty =
      this.details.properlyMapped && this.details.isFaulty && this.markFaulty && !this.details.connected;
    styleClass.notifying = this.isNotifying;
    styleClass.unmapped = !this.details.properlyMapped;
    styleClass.mapped = this.details.properlyMapped;
    styleClass['duplicated-mapping'] = this.details.duplicateAddressMappings?.length > 0;
    styleClass.hasMappingBeenAttempted = this.notificationMode && this.details.hasMappingBeenAttempted;
    styleClass.disconnected = this.details.properlyMapped && this.markFaulty && !this.details.connected;
    styleClass['has-emergency-gear'] = this.details.emDrivers?.length > 0;
    styleClass['is-highlighted'] = this.details.isHighlighted;
    styleClass['is-changed'] = this.details.isChanged;
    styleClass['passive-node'] = this.details.isPassiveNode;
    styleClass['sensor-node-alert-shadow'] =
      this.activePage !== 'heatmap' && Array.isArray(this.details.alerts) && this.details.alerts.length > 0;
    if (this.markEmergencyLight) {
      styleClass.muted = this.details.emDrivers == null || this.details.emDrivers?.length === 0;
    } else if (this.activePage === 'lighting-configuration' || this.activePage === 'beacons') {
      // show PNs and HIM84s as disabled on lighting configuration page
      styleClass.muted = !this.details.isSN3Node;
    }
    return styleClass;
  }

  public produceClassForSensorBackgroundIcon(): {} {
    const styleClass = {};
    const indexInColorWheel = this.gatewayToColorWheelIndexMap[1] % 10;
    if (this.isGatewayModeActive) {
      styleClass['or-bg-color-wheel-' + indexInColorWheel] = true;
      styleClass['sensor-node-border'] = true;
    }
    return styleClass;
  }

  public produceClassForSensorNodeIcon(): {} {
    const styleClass = {};
    styleClass['or-icon-node'] = this.details.isSN3Node || this.details.isPassiveNode || this.details.nodeType == null;
    styleClass['or-icon-him84'] = this.details.isHIM84Node;
    styleClass['sensor-node-gatewaymode'] = this.isGatewayModeActive;

    return styleClass;
  }

  public produceClassForGatewayLabel(): {} {
    const styleClass = {};
    const indexInColorWheel = this.gatewayToColorWheelIndexMap[1] % 10;
    if (this.isGatewayModeActive) {
      styleClass['or-bg-transparent-color-wheel-' + indexInColorWheel] = true;
      const color = ColorUtils.getNextColorInWheel(indexInColorWheel);
      if (ColorUtils.isDark(color)) {
        styleClass['sensor-node-light-text'] = true;
      } else {
        styleClass['sensor-node-dark-text'] = true;
      }
    }
    return styleClass;
  }

  public getLampTypeName(): string {
    if (this.details.luminaireDrivers && this.details.luminaireDrivers[0]?.lampType?.name) {
      return (
        this.details.luminaireDrivers[0]?.lampType?.name +
        ' (' +
        this.details.luminaireDrivers[0]?.lampType?.powerConsumptionMax +
        'W)'
      );
    } else {
      return null;
    }
  }

  public getNodeIdText(): string {
    let text = 'Node';
    text += ' #' + this.details.id;
    return text;
  }

  public showDataSet(): boolean {
    let show = true;
    if (this.details.nodeType === SensorNode.PASSIVE_NODE_TYPE && this.dataType === DataType.PRESENCE) {
      show = false;
    }
    return show;
  }
}
