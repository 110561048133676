import { Tag } from '@app/shared/models/tag.interface';
import { Floor } from '@app/shared/models/floor.interface';
import { IEmergencyLightingSchedule } from '@app/shared/models/emergency-lighting-schedule.interface';
import { ScheduleRecurrence } from '@app/shared/models/schedule-recurrence/schedule-recurrence';
import { EmergencyLightingSchedulingStrategy } from '@app/shared/models/emergency-lighting-scheduling-strategy';
import { EmergencyLightingTestType } from '@app/shared/models/emergency-lighting-test-type';
import { IElmtScheduleDto } from '@app/shared/models/elmt-schedule-dto.interface';
import { RepeatUnit } from '@app/shared/models/schedule-recurrence/repeat-unit';
import { Weekday } from '@app/shared/models/weekday';
import { TimeUtils } from '@app/shared/utils/time.utils';

export class EmergencyLightingSchedule implements IEmergencyLightingSchedule {
  public id?: number;
  public name?: string;
  public testsType: EmergencyLightingTestType;
  public active: boolean;
  public enabled: boolean;
  public buildingId?: number;
  public tags?: Tag[];
  public floors?: Floor[];
  public timezone: string;
  public startDateTime: number;
  public endDateTime: number;
  public repeat?: ScheduleRecurrence;
  public schedulingStrategy: EmergencyLightingSchedulingStrategy;
  public lastRun?: number;
  public currentRunStartTime?: number;
  public currentRunEstimatedEndTime?: number;
  public nextRunStartTime?: number;
  public nextRunEstimatedEndTime?: number;
  public floorIds?: number[];
  public tagIds?: number[];
  public startDate: Date;
  public startTime: string;
  public endTime: string;
  public hasRan: boolean;
  public editable: boolean;

  constructor(params: IEmergencyLightingSchedule = {} as IEmergencyLightingSchedule) {
    this.name = params.name;
    this.testsType = params.testsType;
    this.active = params.active;
    this.enabled = params.enabled;
    this.buildingId = params.buildingId;
    this.timezone = params.timezone;
    this.startDateTime = params.startDateTime;
    this.endDateTime = params.endDateTime;
    this.repeat = params.repeat;
    this.schedulingStrategy = params.schedulingStrategy;
    this.tagIds = params.tagIds;
    this.floorIds = params.floorIds;
    this.tags = params.tags;
    this.floors = params.floors;
    this.currentRunStartTime = params.currentRunStartTime;
    this.currentRunEstimatedEndTime = params.currentRunEstimatedEndTime;
    this.nextRunStartTime = params.nextRunStartTime;
    this.nextRunEstimatedEndTime = params.nextRunEstimatedEndTime;
    this.startDate = params.startDate;
    this.startTime = params.startTime;
    this.endTime = params.endTime;
    this.hasRan = params.hasRan;
    this.editable = params.editable;
  }

  static fromDto(elmtScheduleDto: IElmtScheduleDto): IEmergencyLightingSchedule {
    const startDateFromDto = TimeUtils.convertTZ(elmtScheduleDto.startDateTime, elmtScheduleDto.timezone);
    const endDateFromDto = TimeUtils.convertTZ(elmtScheduleDto.endDateTime, elmtScheduleDto.timezone);
    return {
      id: elmtScheduleDto.id,
      name: elmtScheduleDto.name,
      buildingId: elmtScheduleDto.buildingId,
      timezone: elmtScheduleDto.timezone,
      testsType: EmergencyLightingTestType.fromValue(elmtScheduleDto.testsType),
      startDateTime: startDateFromDto.getTime() / 1000,
      endDateTime: endDateFromDto.getTime() / 1000,
      schedulingStrategy: EmergencyLightingSchedulingStrategy.fromValue(elmtScheduleDto.schedulingStrategy),
      repeat: new ScheduleRecurrence(
        TimeUtils.convertTZ(elmtScheduleDto.startDateTime, elmtScheduleDto.timezone),
        elmtScheduleDto.repeatEvery,
        RepeatUnit.fromValue(elmtScheduleDto.repeatUnit),
        elmtScheduleDto.repeatOnWeekdays?.flatMap((repeatOn) => Weekday.fromValue(repeatOn)),
        elmtScheduleDto.repeatEndsOn,
        elmtScheduleDto.repeatEndsAfterNumOccurrences
      ),
      enabled: elmtScheduleDto.enabled,
      active: false,
      floorIds: elmtScheduleDto.floorIds,
      tagIds: elmtScheduleDto.tagIds,
      currentRunStartTime: TimeUtils.convertTZToMillis(elmtScheduleDto.currentRunStartTime, elmtScheduleDto.timezone),
      currentRunEstimatedEndTime: TimeUtils.convertTZToMillis(
        elmtScheduleDto.currentRunEstimatedEndTime,
        elmtScheduleDto.timezone
      ),
      nextRunStartTime: TimeUtils.convertTZToMillis(elmtScheduleDto.nextRunStartTime, elmtScheduleDto.timezone),
      nextRunEstimatedEndTime: TimeUtils.convertTZToMillis(
        elmtScheduleDto.nextRunEstimatedEndTime,
        elmtScheduleDto.timezone
      ),
      nodeCount: elmtScheduleDto.nodeCount,
      startTime: ('0' + startDateFromDto.getHours()).slice(-2) + ':' + ('0' + startDateFromDto.getMinutes()).slice(-2),
      startDate: startDateFromDto,
      endTime: ('0' + endDateFromDto.getHours()).slice(-2) + ':' + ('0' + endDateFromDto.getMinutes()).slice(-2),
      lastRun: TimeUtils.convertTZToMillis(elmtScheduleDto.lastRunStartTime, elmtScheduleDto.timezone),
      hasRan: elmtScheduleDto.hasRan,
      editable: elmtScheduleDto.editable
    } as IEmergencyLightingSchedule;
  }

  static toDto(elmtSchedule: IEmergencyLightingSchedule): IElmtScheduleDto {
    return {
      id: elmtSchedule.id,
      name: elmtSchedule.name,
      buildingId: elmtSchedule.buildingId,
      timezone: elmtSchedule.timezone,
      tagIds: elmtSchedule.tagIds,
      floorIds: elmtSchedule.floorIds,
      testsType: elmtSchedule.testsType?.value,
      startDateTime: TimeUtils.toBrowserTimeZone(new Date(elmtSchedule.startDateTime * 1000), elmtSchedule.timezone),
      endDateTime: TimeUtils.toBrowserTimeZone(new Date(elmtSchedule.endDateTime * 1000), elmtSchedule.timezone),
      schedulingStrategy: elmtSchedule.schedulingStrategy?.value,
      repeatEvery: elmtSchedule.repeat?.repeatOccurrence,
      repeatUnit: elmtSchedule.repeat?.repetitionUnit?.value,
      repeatOnWeekdays: elmtSchedule.repeat?.repeatOnDays?.map((weekday) => weekday.value),
      repeatEndsOn: elmtSchedule.repeat?.endsOnDate,
      repeatEndsAfterNumOccurrences: elmtSchedule.repeat?.endsAfterOccurrences,
      enabled: elmtSchedule.enabled,
      hasRan: elmtSchedule.hasRan,
      editable: elmtSchedule.editable
    };
  }

  static clone(elmtSchedule: IEmergencyLightingSchedule): IEmergencyLightingSchedule {
    return {
      id: elmtSchedule.id,
      name: elmtSchedule.name,
      buildingId: elmtSchedule.buildingId,
      timezone: elmtSchedule.timezone,
      testsType: elmtSchedule.testsType,
      startDateTime: elmtSchedule.startDateTime,
      endDateTime: elmtSchedule.endDateTime,
      schedulingStrategy: elmtSchedule.schedulingStrategy,
      repeat: elmtSchedule.repeat,
      enabled: elmtSchedule.enabled,
      active: elmtSchedule.active,
      floorIds: elmtSchedule.floorIds,
      tagIds: elmtSchedule.tagIds,
      currentRunStartTime: elmtSchedule.currentRunStartTime,
      currentRunEstimatedEndTime: elmtSchedule.currentRunEstimatedEndTime,
      nextRunStartTime: elmtSchedule.nextRunStartTime,
      nextRunEstimatedEndTime: elmtSchedule.nextRunEstimatedEndTime,
      nodeCount: elmtSchedule.nodeCount,
      startTime: elmtSchedule.startTime,
      startDate: elmtSchedule.startDate,
      endTime: elmtSchedule.endTime,
      lastRun: elmtSchedule.lastRun,
      hasRan: elmtSchedule.hasRan,
      editable: elmtSchedule.editable
    };
  }
}
