import { DdlNode } from '../../api/building/Ddl';
import { ISimpleService } from '../../api/ISimpleService';

export class DdlNodeService {
  private endpoints = {
    building: '/api/v1/building',
    node: '/api/v1/node',
    nodeTags: '/api/v1/node/tagsForNodes'
  };

  constructor(
    private simpleService: ISimpleService<DdlNode, DdlNode, void, void>,
    private generateApiUrl: (endpoint: string) => string
  ) {}

  public getDdlNode(nodeId: number): Promise<DdlNode> {
    return this.simpleService.get(
      this.generateApiUrl(`${this.endpoints.node}/${nodeId}`)
    );
  }

  public getDdlNodeTags(nodeId: number): Promise<any> {
    return this.simpleService.post(
      this.generateApiUrl(`${this.endpoints.nodeTags}`),
      [nodeId]
    );
  }
}
