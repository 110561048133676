import { IDialogService } from 'ng-dialog';
import { User } from '@angularjs/or/api/auth/User';
import { IUserService } from '@angularjs/or/services/IUserService';
import { SessionService } from '@angularjs/or/services/SessionService';
import { ConfigService } from '@app/config-service';

export class OrFooterController {
  constructor(
    private ngDialog: IDialogService,
    private sessionService: SessionService,
    private userService: IUserService,
    private configService: ConfigService
  ) {}

  public $onInit(): void {
    this.userService.getCurrentUser().then((user: User) => {
      if (user.joinedAt == null) {
        const dialog = this.ngDialog.open({
          template: require('raw-loader!./privacy-notice.html').default,
          plain: true,
          className: 'ngdialog-theme-default or-footer-dialog or-footer-dialog-privacy',
          closeByDocument: false
        });
        dialog.closePromise.then((data) => {
          if (data.value === 'Accept') {
            this.userService.join().then((updatedUser: User) => {
              user.joinedAt = updatedUser.joinedAt;
            });
          } else {
            this.userService.delete(user.id).then(() => {
              this.sessionService.logout();
            });
          }
        });
      }
    });
  }

  public getCommitHash(): string {
    return this.configService.config.commitHash;
  }

  public showPrivacyPolicy(): void {
    this.ngDialog.open({
      template: require('raw-loader!./privacy-notice.html').default,
      plain: true,
      className: 'ngdialog-theme-default or-footer-dialog'
    });
  }
}
