export class MultiUserInvitation {
  constructor(
    public buildingId: number,
    public invitations: UserInvitation[]
  ) {}
}

export class UserInvitation {
  constructor(
    public emailAddress: string = '',
    public roleIds: number[] = []
  ) {}
}

export class InvitationConfirmation {
  constructor(
    public url: string,
    public absoluteUrl: string,
    public token: string,
    public emailAddress: string,
    public subject: string,
    public body: string
  ) {}
}

export class MultiInvitationConfirmation {
  constructor(public confirmations: InvitationConfirmation[]) {}
}
