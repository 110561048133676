import { BacnetAreaService } from '@angularjs/or/services/BacnetAreaService/BacnetAreaService';
import { IBuildingService } from '@angularjs/or/services/IBuildingService';
import { FloorService } from '@angularjs/or/services/FloorService';
import { NavigationService } from '@app/shared/services/navigation/navigation.service';
import { NavigationSection } from '@app/shared/services/navigation/navigation-section';
import { NavigationSectionInfo } from '@app/shared/services/navigation/navigation-section-info';
import { GatewayService } from '@angularjs/or/services/GatewayService';

export class IntegrationsAreasController {
  public disableSiteMenu = false;
  public disableUserMenu = false;
  public disableFloorsMenu = true;
  public disableSessionMenu = false;

  public isBusy = true;
  public isAreaListBusy = false;
  public isLoadingGateways = false;
  public isGatewaysLoaded = false;

  public buildingId: number;
  public floorId: number;
  public tabs: NavigationSection[];

  public filterQuery = '';
  public filterConfigured = false;
  public isAreaDetailsActive = false;
  public areas;
  private originalAreas;
  public gateways;
  public newArea;

  constructor(
    private $routeParams,
    private $scope: angular.IScope,
    private $location,
    private $timeout,
    private bacnetAreaService: BacnetAreaService,
    private buildingService: IBuildingService,
    private floorService: FloorService,
    private gatewayService: GatewayService,
    private navigationService: NavigationService,
    private sectionInfo: NavigationSectionInfo,
    private urlGenerator: (buildingId?: number, floorId?: number) => string
  ) {
    this.updateEverything();
    this.newArea = {};
  }

  public produceUrlForArea(areaId) {
    const url = this.urlGenerator(this.buildingId, this.floorId);
    return `${url}/${areaId}`;
  }

  private updateEverything() {
    this.isBusy = true;
    this.initializeValues();
    this.initNavigation();
    this.loadGateways();
    this.loadAreas();
    this.isBusy = false;
  }

  public loadGateways() {
    this.isBusy = true;
    this.isAreaListBusy = true;
    const buildingId = this.buildingId;
    this.gatewayService.getGateways(buildingId).then((response) => {
      this.$scope.$apply(() => {
        this.gateways = response.gateway;
        this.isGatewaysLoaded = true;
        this.isBusy = false;
        this.isAreaListBusy = false;
      });
    });
  }

  public loadAreas() {
    this.isBusy = true;
    this.isAreaListBusy = true;
    const buildingId = this.buildingId;
    this.areas = null;
    this.bacnetAreaService.getAreas(buildingId).then((areas) => {
      this.$scope.$apply(() => {
        this.areas = areas;
        this.newArea = {};
        this.isBusy = false;
        this.isAreaListBusy = false;
      });
    });
  }

  private initNavigation() {
    this.navigationService.applyContext(window.location.href, {
      buildingId: this.buildingId,
      floorId: this.floorId
    });
    this.tabs = this.navigationService.getSiblingsFor(this.sectionInfo.Id);
  }

  private initializeValues() {
    this.buildingId = parseInt(this.$routeParams.buildingId);
    this.floorId = parseInt(this.$routeParams.floorId);
    this.floorService.setCurrentFloorId(this.floorId);
    this.buildingService.setCurrentBuildingId(this.buildingId);
  }
}
