import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MultiselectOption as SelectOptionInterface } from '@app/shared/components/multiselect/multiselect-option';
import { Floor, floorCompare } from '@app/shared/models/floor.interface';
import { MultiselectOption } from '@app/shared/models/multiselect-option';

@Component({
  selector: 'app-inline-floors',
  templateUrl: './inline-floors.component.html',
  styleUrls: ['./inline-floors.component.scss']
})
export class InlineFloorsComponent implements OnInit {
  @Input() isEditable: boolean;
  @Input() selectedFloorIds: number[] = []; // floors which are part of this curve
  @Input() isSelectionDisabled = false;
  @Input() floors: Floor[] = [];
  @Output() updatedSelection = new EventEmitter<Floor[]>(); // this will inform the parent about updated floor selection

  availableOptions: SelectOptionInterface<Floor>[] = []; // this is the updated list of floor options after a floor has been added or removed
  currentSelection: Floor[] = []; // floors that are linked to the entity
  private options: MultiselectOption<Floor>[] = []; // create options from the floors received

  constructor() {
    // add any component creation logic here
  }

  ngOnInit(): void {
    const sortedFloors = [...(this.floors || [])].sort(floorCompare);
    sortedFloors.forEach((floor) => {
      if (this.selectedFloorIds?.includes(floor.id)) {
        this.currentSelection.push(floor);
      }
      this.options.push(new MultiselectOption<Floor>(floor.name, floor));
    });

    // populate dropdown if the component is editable
    if (this.isEditable) {
      // with all floor options received, only keep those which refer to floors that are not part of curve
      this.availableOptions = this.getAvailableOptions();
    }
  }

  public removeFloor($index): void {
    this.currentSelection.splice($index, 1);
    this.updatedSelection.emit(this.currentSelection);
    this.updateAvailableOptions();
  }

  addActiveFloor(value): void {
    const selectedFloor = JSON.parse(value);
    this.currentSelection.push(selectedFloor);
    this.currentSelection.sort(floorCompare);
    this.updatedSelection.emit(this.currentSelection);
    this.updateAvailableOptions();
  }

  private getAvailableOptions(): SelectOptionInterface<Floor>[] {
    return this.options.filter((floor) => {
      return this.currentSelection.map((item) => item.id).indexOf(floor.value.id) < 0;
    });
  }

  private updateAvailableOptions(): void {
    this.availableOptions = this.getAvailableOptions();
  }

  produceFloorLabel(floor): string {
    return floor.name ? floor.name : `Floor ${floor.floorNumber}`;
  }
}
