export class OrDatepickerController {
  public label;
  public date;
  public dateFormat: string;
  public min: string;
  public max: string;
  public change;

  constructor() {
    if (!this.dateFormat) {
      this.dateFormat = 'dd.MM.yyyy';
    }
  }
}
