import { OrHeaderController } from './OrHeaderController';

export class OrHeaderDirective {
  constructor() {
    return {
      bindToController: true,
      controller: [OrHeaderController],
      controllerAs: 'header',
      replace: true,
      restrict: 'E',
      scope: {
        disableSiteMenu: '=?',
        disableUserMenu: '=?',
        disableBuildingsMenu: '=?',
        disableFloorsMenu: '=?',
        disableTenantsMenu: '=?',
        disableSessionMenu: '=?'
      },
      template: require('raw-loader!./or-header.html').default
    };
  }
}
