<div class="or-user-menu">
  <ul class="or-inline-items">
    @if (floorsMenuActive$ | async) {

    }
    <li class="or-inline-item" *ngIf="floorsMenuActive$ | async">
      <app-floors-menu></app-floors-menu>
    </li>
    <li class="or-inline-item" *ngIf="buildingsMenuActive$ | async">
      <app-buildings-menu [user$]="user$" [buildings$]='buildings$'></app-buildings-menu>
    </li>
    <li class="or-inline-item" *ngIf="sessionMenuActive$ | async">
      <app-session-menu [user$]="user$"></app-session-menu>
    </li>
    <li class="or-inline-item">
      <a data-cy='portal-help-link' class="or-button or-site-menu-link" href="https://organicresponse.atlassian.net/servicedesk/customer/portal/1">
        <span data-cy='portal-help-icon' class="or-icon or-small or-icon-info or-inverse"></span>
      </a>
    </li>
  </ul>
</div>
