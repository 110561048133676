import { SensorNode } from '../../api/building/SensorNode';
import { PromiseRejectionHandler } from '../../promises/PromiseRejectionHandler';
import { PromiseUtils } from '../promises/PromiseUtils';
import { DuplicateMapping } from '@angularjs/or/api/building/DuplicateMapping';

export class DuplicateMappingResource {
  constructor(
    private $http: ng.IHttpService,
    private baseUrl: string,
    private rejectionHandler: PromiseRejectionHandler
  ) {}

  public getDuplicateAddressMappings(
    buildingId: number,
    nodes: SensorNode[]
  ): Promise<Map<number, DuplicateMapping[]>> {
    const nodeIds = [];
    nodes.forEach((node) => nodeIds.push(node.id));

    return this.getDuplicateAddressMappingsByNodeIds(buildingId, nodeIds);
  }

  public getDuplicateAddressMappingsByNodeIds(
      buildingId: number,
      nodeIds: number[]
  ): Promise<Map<number, DuplicateMapping[]>> {

    return PromiseUtils.wrapSingle(
      this.$http.post(
        this.baseUrl + '/' + buildingId + '/nodes/address',
        nodeIds
      ),
      this.rejectionHandler
    );
  }

  public getDuplicateGroupMappings(
    buildingId: number,
    nodes: SensorNode[]
  ): Promise<Map<number, DuplicateMapping[]>> {
    const nodeIds = [];
    nodes.forEach((node) => nodeIds.push(node.id));
    return PromiseUtils.wrapSingle(
      this.$http.post(
        this.baseUrl + '/' + buildingId + '/nodes/group',
        nodeIds
      ),
      this.rejectionHandler
    );
  }
}
