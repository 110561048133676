import { ColorUtils } from '@angularjs/or/util/ColorUtils';
import { ISecurityService } from '@angularjs/or/services/ISecurityService';
import { FilterService } from '@angularjs/or/services/FilterService';
import { EmDriver } from '@angularjs/or/api/building/EmDriver';
import { FloorplanSensorNode } from '@angularjs/or/api/building/FloorplanSensorNode';

export class OrEmDriverTileController {
  public details: EmDriver;
  public node: FloorplanSensorNode;
  private showHidden: boolean;

  constructor(
    private $scope: ng.IScope,
    private $element,
    private securityService: ISecurityService,
    private filterService: FilterService
  ) {}

  public get sortBy(): string {
    return this.filterService.sortBy;
  }

  public get reverse(): boolean {
    return this.filterService.reverse;
  }

  public getForegroundColor(bgColor: string): any {
    return {
      'background-color': '#' + bgColor,
      color: ColorUtils.getConstrastingGrey(bgColor)
    };
  }

  public getClassIfHidden(): string {
    let hiddenClass = 'or-hide-hidden';
    if (this.showHidden) {
      hiddenClass = 'or-show-hidden';
    }

    return hiddenClass;
  }

  public getClassForTilesVisibility(): string {
    return '';
  }

  public getNodeStatusAndTypeClass(): string {
    return (
      'or-em-driver-tile-status-body-' + (this.details.status || 'UNKNOWN')
    );
  }
}
