import * as angular from 'angular'; // Automatically added
import { BacnetAreaService } from '@angularjs/or/services/BacnetAreaService/BacnetAreaService';
import { IBuildingService } from '@angularjs/or/services/IBuildingService';
import { FloorService } from '@angularjs/or/services/FloorService';
import { NavigationService } from '@app/shared/services/navigation/navigation.service';

export class OrBacnetAreaTileController {
  public buildingId: number;
  public floorId: number;

  public form;
  private area;
  public originalArea;
  public isNew: boolean;

  public gateways;
  public gatewayOptions;
  public activeGatewayOption;
  public isLoadingDetails = false;
  public isLoadingGateways = false;
  public isDeletingArea = false;
  public isSubmitting = false;

  public onLoadAreas;

  constructor(
    private $routeParams,
    private $scope,
    private $location,
    private $timeout,
    private bacnetAreaService: BacnetAreaService,
    private buildingService: IBuildingService,
    private floorService: FloorService,
    private navigationService: NavigationService
  ) {}

  public $onInit() {
    this.updateEverything();
    this.loadAreaDetails();
  }

  private updateEverything() {
    this.initializeValues();
    this.initNavigation();
    this.gatewayOptions = this.produceGatewayOptions(this.gateways);
  }

  private initNavigation() {
    this.navigationService.applyContext(window.location.href, {
      buildingId: this.buildingId,
      floorId: this.floorId
    });
  }

  public setActiveGatewayOption() {
    this.activeGatewayOption = this.gatewayOptions.find((gatewayOption) => {
      return gatewayOption.id === this.area.gatewayId;
    });
  }

  private initializeValues() {
    this.buildingId = parseInt(this.$routeParams.buildingId);
    this.floorId = parseInt(this.$routeParams.floorId);
    this.floorService.setCurrentFloorId(this.floorId);
    this.buildingService.setCurrentBuildingId(this.buildingId);
  }

  private loadAreaDetails() {
    this.isLoadingDetails = true;

    if (this.isNew) {
      this.area = {
        id: null,
        name: null,
        description: null,
        gatewayId: null
      };
      this.originalArea = angular.copy(this.area);
      this.isLoadingDetails = false;
      return;
    }

    this.originalArea = angular.copy(this.area);
    this.setActiveGatewayOption();
    this.isLoadingDetails = false;
  }

  public isBusy() {
    return (
      this.isLoadingDetails ||
      this.isLoadingGateways ||
      this.isDeletingArea ||
      this.isSubmitting
    );
  }

  public discardChanges() {
    if (this.isNew) {
      if (!confirm(`Discard changes for the new area?`)) {
        return;
      }
    } else if (!confirm(`Discard changes for ${this.area.name}?`)) {
      return;
    }

    this.area = angular.copy(this.originalArea);

    if (!this.isNew) {
      this.activeGatewayOption.id = this.area.gatewayId;
    } else {
      if (this.activeGatewayOption) {
        this.activeGatewayOption.id = null;
      }
    }

    this.form.$setPristine();
    this.form.$setUntouched();
  }

  public goToAreas() {
    this.form.$setPristine();
    this.form.$setUntouched();
    this.onLoadAreas();
  }

  public deleteArea() {
    if (!confirm(`Area '${this.area.name}' will be deleted.`)) {
      return;
    }

    this.isDeletingArea = true;
    this.bacnetAreaService.deleteArea(this.area.id).then(() => {
      this.$timeout(
        function () {
          this.isDeletingArea = false;
          this.goToAreas();
        }.bind(this)
      );
    });
  }

  public submit() {
    if (this.isNew) {
      this.addArea(this.area);
      return;
    }

    this.updateArea(this.area);
  }

  private addArea(area) {
    if (area.name == null || area.gatewayId == null) {
      alert('Both Name and Gateway fields need values.');
      return;
    }

    this.isSubmitting = true;
    this.bacnetAreaService
      .addArea(area)
      .then(() => {
        this.$timeout(
          function () {
            this.isSubmitting = false;
            this.goToAreas();
          }.bind(this)
        );
      })
      .catch((err) => {
        alert('An error happened when trying to add the new area.');
        this.isSubmitting = false;
        this.form.$setPristine();
        this.form.$setUntouched();
      });

    this.area = angular.copy(this.originalArea);
    this.activeGatewayOption.id = null;
  }

  private updateArea(area) {
    if (area.name == null || area.gatewayId == null) {
      alert('Both Name and Gateway fields need values.');
      return;
    }

    if (
      !confirm(`Area '${this.area.name}' will be updated with the new values.`)
    ) {
      return;
    }

    this.isSubmitting = true;
    this.originalArea = angular.copy(this.area);

    const areaToUpdate = {
      id: area.id,
      name: area.name,
      description: area.description,
      gatewayId: area.gatewayId
    };

    this.bacnetAreaService.updateArea(areaToUpdate).then(() => {
      this.$timeout(
        function () {
          this.isSubmitting = false;
          this.goToAreas();
        }.bind(this)
      );
    });
  }

  public isValidAreaModel() {
    const area = this.area;
    return (
      isObject(area) &&
      isNumber(area.gatewayId) &&
      isNonEmptyString(area.name) &&
      !this.form.$pristine
    );
  }

  public updateGatewayId() {
    const gatewayOption = this.activeGatewayOption;
    this.area.gatewayId = gatewayOption.id;
  }

  private produceGatewayOptions(gateways) {
    return gateways.map((gateway) => {
      return {
        id: gateway.id,
        label:
          gateway.address +
          '  [ ' +
          (gateway.name ? gateway.name : ' N/A ') +
          ' ]'
      };
    });
  }
}

function isObject(item) {
  return toStringCall(item) === '[object Object]';
}

function isNumber(item) {
  return toStringCall(item) === '[object Number]' && isFinite(item);
}

function isNonEmptyString(item) {
  return toStringCall(item) === '[object String]' && item.trim().length > 0;
}

function toStringCall(item) {
  return Object.prototype.toString.call(item);
}
