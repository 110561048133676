import { OrBeaconSettingsController } from './OrBeaconSettingsController';

export class OrBeaconSettingsDirective {
  constructor() {
    return {
      bindToController: true,
      controller: [
        'SensorNodeService',
        'BeaconSettingService',
        'BuildingService',
        'OrPanelService',
        'QueryContext',
        '$scope',
        OrBeaconSettingsController
      ],
      controllerAs: 'beaconSettings',
      replace: true,
      restrict: 'E',
      scope: {
        floorId: '=',
        isReady: '='
      },
      document,
      template: require('raw-loader!./or-beacon-settings.html').default
    };
  }
}
