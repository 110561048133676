import { Action, Consumer, Func } from '../delegates/Delegates';

export interface IClearable {
  clear();
}

export interface INotifier {
  onNotify(subscriber: Action);
}

export interface INotifiable {
  notify();
}

export interface ISender<M> {
  onReceive(subscriber: Consumer<M>);
}

export interface IReceiver<M> {
  send(message: M);
}

export interface IChannel<M> extends ISender<M>, IReceiver<M>, IClearable {}

export class Channel<M> implements IChannel<M> {
  private handlers: Array<Consumer<M>> = [];

  public onReceive(handler: Consumer<M>) {
    this.handlers.push(handler);
  }

  public send(message: M) {
    this.handlers.forEach((recipient) => recipient(message));
  }

  public clear() {
    this.handlers = [];
  }
}

export class TransformingMessageSender<A, B> implements ISender<B> {
  constructor(private underlying: IChannel<A>, private transform: Func<A, B>) {}

  public onReceive(handler: Consumer<B>) {
    this.underlying.onReceive((message) => {
      const transformedMessage = this.transform(message);
      if (transformedMessage != null) {
        handler(transformedMessage);
      }
    });
  }
}
