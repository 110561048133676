import { Inject, Injectable } from '@angular/core';
import { Resource } from '@app/shared/resources/resource';
import { HttpClient } from '@angular/common/http';
import { Environment, environmentToken } from '@environment';
import { Observable } from 'rxjs';
import { IEmergencyLightingSchedule } from '@app/shared/models/emergency-lighting-schedule.interface';
import { IElmtScheduleDto } from '@app/shared/models/elmt-schedule-dto.interface';
import { IEmergencyTest } from '@app/shared/resources/emergency-lighting-test.resource';

@Injectable({
  providedIn: 'root'
})
export class EmergencyLightingScheduleResource extends Resource<IElmtScheduleDto, number> {
  constructor(protected httpClient: HttpClient, @Inject(environmentToken) environment: Environment) {
    super(httpClient, `${environment.apiUrl + environment.apiPath}/emergency-lighting-schedule`);
  }

  public addResource(buildingId: number, item: IElmtScheduleDto): Observable<IEmergencyLightingSchedule> {
    return this.httpClient.post<IEmergencyLightingSchedule>(`${this.baseUrl}/${buildingId}`, item);
  }

  public updateResource(id: number, item: IElmtScheduleDto): Observable<IEmergencyLightingSchedule> {
    return this.httpClient.put<IEmergencyLightingSchedule>(`${this.baseUrl}/schedule/${id}`, item);
  }

  validateSchedule(buildingId: number, item: IElmtScheduleDto): Observable<void> {
    return this.httpClient.post<void>(`${this.baseUrl}/${buildingId}/validate`, item);
  }

  public getTestsForSchedule(id: number): Observable<IEmergencyTest[]> {
    return this.httpClient.get<IEmergencyTest[]>(`${this.baseUrl}/schedule/${id}/tests`);
  }

  public deleteSchedule(buildingId: number, scheduleId: number): Observable<string[]> {
    return this.httpClient.delete<string[]>(`${this.baseUrl}/schedule/${buildingId}/${scheduleId}`);
  }

  public getImpactedEmailReportsByDeletion(buildingId: number, scheduleId: number): Observable<string[]> {
    return this.httpClient.get<string[]>(`${this.baseUrl}/schedule/related-email-reports/${buildingId}/${scheduleId}`);
  }
}
