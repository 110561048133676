import { OrMetricWidgetController } from './OrMetricWidgetController';
import { QueryExecutor } from '@angularjs/or/angular/QueryExecutor';

export class OrMetricWidgetDirective {
  constructor() {
    return {
      bindToController: true,
      controller: [
        'TimeNavigator',
        'QueryExecutor',
        'QueryOutlineBuilder',
        'QueryContext',
        'BuildingService',
        'CopyToClipboardService',
        '$element',
        '$scope',
        '$window',
        OrMetricWidgetController
      ],
      controllerAs: 'widget',
      replace: true,
      restrict: 'E',
      scope: {
        metric: '=',
        isActive: '&',
        setActive: '&'
      },
      template: require('raw-loader!./or-metric-widget.html').default
    };
  }
}
