import * as ng from 'angular';
import { NavigationService } from '@app/shared/services/navigation/navigation.service';
import { NavigationSection } from '@app/shared/services/navigation/navigation-section';
import { NavigationSectionInfo } from '@app/shared/services/navigation/navigation-section-info';
import { DropdownService } from '@angularjs/or/services/DropdownService';
import { environment } from 'src/environments/environment';
import { Environment } from '@environment';

export class PortalConfig {
  routeProvider($routeProvider: ng.route.IRouteProvider): void {
    $routeProvider.when('/', { redirectTo: '/buildings' });
    $routeProvider.otherwise({ template: '' });
  }

  locationProvider($locationProvider: ng.ILocationProvider): void {
    $locationProvider.html5Mode({
      enabled: true,
      requireBase: true
    });
  }

  environment(): Environment {
    return environment;
  }

  run(
    navigationService: NavigationService,
    navigationSectionInfo: NavigationSectionInfo,
    dropdownService: DropdownService
  ): void {
    navigationService.addRootSection(
      new NavigationSection(navigationSectionInfo)
    );

    // prevent the increment/decrement-by-mousewheeling-over
    // behaviour in number inputs
    document.addEventListener('mousewheel', (event: any) => {
      if (event.target.type !== 'number') {
        return;
      }
      event.target.blur();
    });

    // collapse open dropdowns when mousedown outside a dropdown
    document.addEventListener('mousedown', (e) => {
      dropdownService.notifyMousedown(e.target);
    });
  }
}
