export interface Point {
  x: number;
  y: number;
}

export class Coordinate implements Point {
  constructor(public x: number, public y: number) {}

  public plus(other: Coordinate): Coordinate {
    return new Coordinate(this.x + other.x, this.y + other.y);
  }

  public minus(other: Coordinate): Coordinate {
    return new Coordinate(this.x - other.x, this.y - other.y);
  }

  public distance(other: Coordinate): number {
    return Math.sqrt(
      Math.pow(this.x - other.x, 2) + Math.pow(this.y - other.y, 2)
    );
  }
}
