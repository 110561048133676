import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { RefreshPageService } from '@services/refresh-page/refresh-page.service';
import { AsyncPipe, NgClass } from '@angular/common';
import { NumericUtils } from '@app/shared/utils/numeric';
import { WidgetItem } from '@app/dashboard/model/widget-item';
import { BehaviorSubject, merge, Observable, of, Subject, switchMap } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatDivider } from '@angular/material/divider';

@Component({
  selector: 'app-count-compared-widget',
  standalone: true,
  imports: [NgClass, MatProgressSpinner, AsyncPipe, MatDivider],
  templateUrl: './count-compared-widget.component.html',
  styleUrl: './count-compared-widget.component.scss'
})
export class CountComparedWidgetComponent implements OnInit, OnDestroy {
  @Input({ required: true }) item: WidgetItem;
  @Input({ required: true }) refreshEvent: EventEmitter<WidgetItem>;
  private isLoadingSource = new BehaviorSubject(true);
  private destroy$ = new Subject<void>();
  countA: string;
  countB: string;

  constructor(private refreshPageService: RefreshPageService) {}

  ngOnInit(): void {
    merge(
      this.item.getData().pipe(finalize(() => this.isLoadingSource.next(false))),
      this.refreshEvent.pipe(
        switchMap((item) => {
          if (!item || item.id === this.item.id) {
            this.isLoadingSource.next(true);
            return this.item.getData().pipe(finalize(() => this.isLoadingSource.next(false)));
          }
          return of();
        })
      ),
      this.refreshPageService.pageRefresh$.pipe(
        switchMap(() => {
          this.isLoadingSource.next(true);
          return this.item.getData().pipe(finalize(() => this.isLoadingSource.next(false)));
        })
      )
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (result: { currentYear: string; lastYear: string }) => {
          this.countA = NumericUtils.fixDigitsInValue(result.currentYear);
          this.countB = NumericUtils.fixDigitsInValue(result.lastYear);
          this.isLoadingSource.next(false);
        },
        error: (err) => console.log(err)
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get isLoading$(): Observable<boolean> {
    return this.isLoadingSource.asObservable();
  }

  get prevYearText(): string {
    return `${new Date().getFullYear() - 1}`;
  }

  get currentYearText(): string {
    return `${new Date().getFullYear()}`;
  }
}
