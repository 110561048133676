import { Inject, Injectable } from '@angular/core';
import { Resource } from './resource';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Environment, environmentToken } from '@environment';
import { AddJobDTO, IOtapUpgradeDTO, JobTaskTemplateMap } from '@app/shared/models/otap.upgrade.interface';
import { Observable } from 'rxjs';
import { IOtapUpgradeGatewayDTO, OtapUpgradeGatewayDTO } from '@app/shared/models/otap-upgrade-gateway.inerface';
import { OtapUpgradeTaskDTO, OtapUpgradeTaskInputParametersDTO } from '@app/shared/models/otap-upgrade-task.interface';

@Injectable({
  providedIn: 'root'
})
export class OtapUpgradeResource extends Resource<IOtapUpgradeDTO, number> {
  constructor(protected httpClient: HttpClient, @Inject(environmentToken) environment: Environment) {
    super(httpClient, `${environment.apiUrl + environment.apiPath}/upgrade/jobs`);
  }

  public getJobs(statusses?: string): Observable<IOtapUpgradeDTO[]> {
    if (statusses) {
      return this.httpClient.get<IOtapUpgradeDTO[]>(`${this.baseUrl}`, {
        params: new HttpParams().set('statuses', statusses)
      });
    }
    return this.httpClient.get<IOtapUpgradeDTO[]>(`${this.baseUrl}`);
  }

  public getJob(jobId: number): Observable<IOtapUpgradeDTO> {
    return this.httpClient.get<IOtapUpgradeDTO>(`${this.baseUrl}/${jobId}`);
  }

  public addJob(payload: AddJobDTO): Observable<IOtapUpgradeDTO> {
    if (payload) {
      return this.httpClient.post<IOtapUpgradeDTO>(`${this.baseUrl}`, payload);
    }
  }

  public getJobTemplates(): Observable<JobTaskTemplateMap> {
    return this.httpClient.get<JobTaskTemplateMap>(`${this.baseUrl}/templates`);
  }

  public getAllGatewaysForAJob(jobId: number): Observable<OtapUpgradeGatewayDTO[]> {
    return this.httpClient.get<OtapUpgradeGatewayDTO[]>(`${this.baseUrl}/${jobId}/gateways`);
  }

  public getAllTasksForAJob(jobId: number): Observable<OtapUpgradeTaskDTO[]> {
    return this.httpClient.get<OtapUpgradeTaskDTO[]>(`${this.baseUrl}/${jobId}/tasks`);
  }

  public startTask(jobId: number, taskId: number, parameters: OtapUpgradeTaskInputParametersDTO[]): Observable<any> {
    return this.httpClient.put<OtapUpgradeTaskDTO>(`${this.baseUrl}/${jobId}/tasks/${taskId}/start`, parameters);
  }

  public deleteGatewayFromJob(jobId: number, gatewayId: number): Observable<{}> {
    return this.httpClient.delete<HttpResponse<any>>(`${this.baseUrl}/${jobId}/gateways/${gatewayId}`);
  }

  public addGatewayToJob(jobId: number, gateway: OtapUpgradeGatewayDTO): Observable<IOtapUpgradeGatewayDTO> {
    return this.httpClient.post<OtapUpgradeGatewayDTO>(`${this.baseUrl}/${jobId}/gateways`, gateway);
  }

  public rebootGateway(jobId: number, gatewayId: number): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this.baseUrl}/${jobId}/gateways/${gatewayId}/reboot`, null);
  }

  public rebootAllGateways(jobId: number): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this.baseUrl}/${jobId}/gateways/reboot`, null);
  }

  public downloadExport(jobId: number, exportId: string): Observable<Blob> {
    return this.httpClient.get(`${this.baseUrl}/${jobId}/export/${exportId}/download`, {
      responseType: 'blob'
    });
  }

  public startExport(jobId: number): Observable<string> {
    return this.httpClient.post(`${this.baseUrl}/${jobId}/export/start`, null, { responseType: 'text' });
  }

  public stopJob(jobId: number): Observable<IOtapUpgradeDTO> {
    return this.httpClient.put<IOtapUpgradeDTO>(`${this.baseUrl}/${jobId}/stop`, null);
  }

  public reopenJob(jobId: number): Observable<IOtapUpgradeDTO> {
    return this.httpClient.put<IOtapUpgradeDTO>(`${this.baseUrl}/${jobId}/reopen`, null);
  }
}
