export class LiveQueryOutline {
  constructor(
    public dataType: string,
    public buildingId: number,
    public tagIds?: number[],
    public sensorNodeIds?: number[],
    public floorIds?: number[],
    public zone?: string,
    public unit?: string,
    public dataPeriod?: number,
    public timezoneOffset?: string,
    public decayPeriod?: number,
    public dwellTime?: number
  ) {
    this.dataPeriod = 30;
    this.decayPeriod = 180;
    this.dwellTime = 10;
  }
}
