<form
  [formGroup]='tokenForm'
  class='or-form or-form-token-details'
  [ngClass]='{ "or-form-token-details-enable-labels": index === 0 }'
  novalidate='novalidate'
  [attr.data-cy]="isNew ? 'new-tokens-form' : 'existing-tokens-form'"
>
  <div class='or-form-items'>
    <div class='or-form-item or-form-token-details-token'>
      <mat-form-field class='form-token-details-token'>
        <mat-label>Token</mat-label>
        <input matInput
               formControlName='usableToken'
               maxlength='256'
               placeholder='WILL BE GENERATED AUTOMATICALLY'
               type='text'
               data-cy='input-usable-token'>
      </mat-form-field>
    </div>
    <div class='or-form-item or-form-token-details-roles'>
      <mat-form-field class='form-token-details-roles'>
        <mat-label>Role(s)</mat-label>
        <mat-select id='roleIds' formControlName="roleIds" data-cy="select-roles" multiple>
          <mat-option disabled>--- Choose role(s) ---</mat-option>
          <mat-option *ngFor="let role of roles" [value]="role.id" [attr.data-cy]="'roleId-option-' + role.id">
            {{ role.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class='or-form-item or-form-token-details-tenant'>
      <mat-form-field>
        <mat-label>Tenant(s)</mat-label>
        <mat-select id='tenantId' formControlName="tenantIds" data-cy="select-tenants">
          <mat-option>--- Choose a tenant ---</mat-option>
          <mat-option *ngFor="let tenant of tenants" [value]="tenant.id"  [attr.data-cy]="'tenantId-option-' + tenant.id">
            {{ tenant.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class='or-form-item or-form-token-details-ip-addresses'>
      <mat-form-field class='form-token-details-ip-addresses'>
        <mat-label data-cy='label-allowed-ip-addresses'>IP Address(es)</mat-label>
        <input matInput
               formControlName='allowedIpAddresses'
               placeholder='IP Addresses (Comma separated)'
               type='text'
               data-cy='input-allowed-ip-addresses'>
      </mat-form-field>
    </div>
    <div class='or-form-item or-form-token-details-description'>
      <mat-form-field class='form-token-details-ip-addresses'>
        <mat-label data-cy='label-description'>Description</mat-label>
        <input matInput
               formControlName='description'
               maxlength='256'
               placeholder=''
               type='text'
               data-cy='input-description'>
      </mat-form-field>
    </div>
    <div class='or-form-item or-form-token-details-actions'
      *ngIf='!isDeactivated'>
      <div class='or-inline-items'>
        <div class='or-inline-item' *ngIf="isNew && token?.tokenStatus !== 'DEACTIVATED'">
          <button
            mat-raised-button
            class='or-button or-iconic or-primary or-positive'
            type='button'
            (click)='createToken()'
            [disabled]='isBusy || tokenForm.invalid || tokenForm.pristine || (token?.roles?.length == 0) ? true : null'
            title='Generate Token'
            data-cy='btn-create-token'
          >
            <span class='or-icon or-small or-inverse or-icon-plus'></span>
          </button>
        </div>
        <div class='or-inline-item' *ngIf="!isNew && token?.tokenStatus !== 'DEACTIVATED'">
          <button
            mat-raised-button
            class='or-button or-iconic or-primary or-positive'
            type='button'
            (click)='updateToken()'
            [disabled]='isBusy || tokenForm.invalid || tokenForm.pristine || (token.roles.length == 0) ? true : null'
            title='Update Token'
            data-cy='btn-update-token'
          >
            <span class='or-icon or-small or-inverse or-icon-checkmark'></span>
          </button>
        </div>
        <div class='or-inline-item' *ngIf="token?.tokenStatus !== 'DEACTIVATED'">
          <button
            mat-raised-button
            class='or-button or-iconic'
            type='button'
            color='accent'
            (click)='resetToken()'
            [disabled]='isBusy || tokenForm.pristine'
            title='Reset Changes'
            data-cy='btn-reset-token'
          >
            <span class='or-icon or-small or-icon-reload'></span>
          </button>
        </div>
        <div class='or-inline-item' *ngIf="!isNew && token?.tokenStatus !== 'DEACTIVATED'">
          <button
            mat-raised-button
            class='or-button or-iconic'
            type='button'
            color='warn'
            (click)='deactivateToken()'
            [disabled]="isBusy"
            title='Deactivate Token'
            data-cy='btn-deactivate-token'
          >
            <span class='or-icon or-small or-inverse or-icon-deactivate'></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
