<div class="ble-dialog">
  <h1 mat-dialog-title>Broadcast Warning</h1>
  <div mat-dialog-content>
    <p>{{queryType}} BLE scanning command is going to be broadcasted to all the nodes (mapped/unmapped) in the building network through the gateways added in the building.</p>
    <p data-cy='ble-dialog-change-state-warning' *ngIf='queryType === "Disable" || queryType === "Enable"'>Please be aware that it can take up to 30 minutes for some nodes to reflect the desired BLE state</p>
    <p data-cy='ble-dialog-disable-warning' class='or-warning' *ngIf='queryType === "Disable"'>Please note Express App and EnOcean switches will become unavailable while scanning is turned off.</p>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button (click)="cancel()" data-cy="close-ble-dialog">Close</button>
    <button mat-raised-button color='primary' (click)="send()" data-cy="send-ble-dialog">Send</button>
  </div>
</div>
