import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Role } from '@app/shared/models/role.interface';
import { RoleResource } from '@app/shared/resources/role.resource';
import { BuildingAuthorityType } from '@app/shared/models/building-authority-type';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  constructor(private roleResource: RoleResource) {}

  public getAllForBuilding(buildingId: number): Observable<Role[]> {
    return this.roleResource.retrieveAllByParentId(buildingId);
  }

  public getAuthorityTypesForBuildingServiceLevel(buildingId: number): Observable<BuildingAuthorityType[]> {
    return this.roleResource.getAuthorityTypesForBuildingServiceLevel(buildingId);
  }

  addRole(buildingId: number, role: Role): Observable<Role> {
    return this.roleResource.addRole(buildingId, role);
  }

  updateRole(role: Role): Observable<void> {
    return this.roleResource.updateRole(role);
  }

  deleteRole(roleId: number): Observable<{}> {
    return this.roleResource.delete(roleId);
  }
}
