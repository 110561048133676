import moment from 'moment';

import { ArrayUtils } from '../util/ArrayUtils';
import { Dates } from '../api/query/outline/context/Dates';
import { Resolution } from '../data/Resolution';
import Moment = moment.Moment;

export class TimeScale {
  private values: number[] = [];

  constructor(
    public resolution: Resolution,
    public getPointLabel: (value: number) => string,
    private name: string,
    private format: string,
    public longFormat: string,
    private _updateMoment: (moment: Moment, values: number) => {},
    private _fromMoment: (moment: Moment) => number,
    private _get: (dates: Dates) => number[],
    private _set: (dates: Dates, values: number[]) => {},
    public isUsedToInitialize: boolean = false
  ) {}

  public shiftMoment(moment: Moment, amount: number) {
    moment.add(amount, this.name as moment.unitOfTime.DurationConstructor);
  }

  public select(blocks: number[]) {
    this.values = blocks;
    this.values.sort((a, b) => a - b);
  }

  public hasValues(): boolean {
    return this.values.length > 0;
  }

  public getValues(): number[] {
    return this.values;
  }

  public isSingleValue(): boolean {
    return this.values.length == 1;
  }

  public getSingleValue(): number {
    return this.values[0];
  }

  public updateMoment(moment: Moment) {
    this._updateMoment(moment, this.values[0]);
  }

  public toDates(dates: Dates) {
    this._set(dates, this.values);
  }

  public fromDates(dates: Dates) {
    const update = this._get(dates);

    if (update != null && update.length > 0) {
      this.values = update;
    }
  }

  public reset() {
    this.values = [];
  }

  public setSingleValue(moment: Moment) {
    this.values = [this._fromMoment(moment)];
  }

  public isActive(value: number): boolean {
    return ArrayUtils.contains(this.values, value);
  }

  public getLabel(time: Moment): string {
    return this.values
      .map((value) => {
        this._updateMoment(time, value);
        return time.format(this.format);
      })
      .join(', ');
  }
}
