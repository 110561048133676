import { UserService } from './UserService';
import { CircadianCurve } from '@angularjs/or/data/CircadianCurve';
import { ICircadianCurveResource } from '@angularjs/or/api/ICircadianCurveResource';

export class CircadianCurveService {
  constructor(
    private circadianCurveResource: ICircadianCurveResource,
    private userService: UserService
  ) {}

  public saveCurve(curve: CircadianCurve): Promise<CircadianCurve> {
    return new Promise((resolve, reject) => {
      this.userService.getCurrentBuilding().then((building) => {
        this.circadianCurveResource
          .addResource(building.id, curve)
          .then((curve) => {
            resolve(curve);
          })
          .catch(() => {
            reject();
          });
      });
    });
  }

  public updateCurve(curve: CircadianCurve): Promise<CircadianCurve> {
    return new Promise((resolve, reject) => {
      this.circadianCurveResource
        .updateResource(curve.id, curve)
        .then((curve) => {
          resolve(curve);
        })
        .catch(() => {
          reject();
        });
    });
  }

  public getCurves(): Promise<CircadianCurve[]> {
    return new Promise((resolve, reject) => {
      this.userService.getCurrentBuilding().then((building) => {
        this.circadianCurveResource
          .retrieveAllByParentId(building.id)
          .then((curves: CircadianCurve[]) => {
            // FIXME: remove this standard curve once backend/UI fully implemented
            if (curves.length === 0) {
              curves.push(CircadianCurve.STANDARD_CURVE);
              resolve(curves);
            } else {
              resolve(curves.map((curve) => CircadianCurve.clone(curve)));
            }
          });
      });
    });
  }

  public deleteCurve(curve: CircadianCurve): Promise<{}> {
    return this.circadianCurveResource.delete(curve.id);
  }
}
