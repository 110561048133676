import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Subject, switchMap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { NgClass } from '@angular/common';
import { CopyToClipboardService } from '@services/copy-to-clipboard.service';
import { ToastService } from '@services/toast/toast.service';
import { AnalyticsMetricsService } from '@services/analytics-metrics.service';
import { map, takeUntil } from 'rxjs/operators';
import { ChartData } from '@app/analytics/metric-widget/data-objects/chart-data';
import { QueryResult } from '@app/analytics/metric-widget/data-objects/query-result';
import { RenderableChart } from '@app/analytics/metric-widget/data-objects/renderable-chart';
import { DataPoint } from '@app/analytics/metric-widget/data-objects/data-point';
import { IQueryExecutor, QueryExecutorToken } from '@app/analytics/metric-widget/query/query-executor';
import { IQueryContext } from '@app/analytics/metric-widget/query/context';
import { Pair } from '@app/analytics/metric-widget/data-objects/pair';
import { Metric } from '@app/analytics/metric-widget/data-objects/metric-types';
import { DataType } from '@app/shared/models/sensor-node-data-type';
import { TimeNavigatorService } from '@app/shared/time/time-navigator.service';
import { UserService } from '@services/user/user.service';
import { Building } from '@app/shared/models/building.interface';

@Component({
  selector: 'app-metric-widget',
  templateUrl: './metric-widget.component.html',
  standalone: true,
  imports: [NgClass],
  styleUrls: ['./metric-widget.component.scss', './chart.scss']
})
export class MetricWidgetComponent implements OnInit, OnDestroy {
  @ViewChild('OrMetricWidgetChartContent') chartContent: ElementRef;
  @Input() metric: Metric;
  @Output() setActive = new EventEmitter<DataType>();
  @Input() isActive: boolean;
  @Input({ required: true }) building: Building;
  @Input({ required: true }) floorId: number;
  canZoomIn = false;
  canZoomOut = false;
  canShiftTimeframe = false;
  isBusy = true;
  timeframeLabel: string;
  private destroy$ = new Subject<void>();
  private data: ChartData;

  constructor(
    @Inject(QueryExecutorToken) private queryExecutor: IQueryExecutor,
    private timeNavigator: TimeNavigatorService,
    private copyToClipboardService: CopyToClipboardService,
    private toastService: ToastService,
    private metricService: AnalyticsMetricsService
  ) {}

  ngOnInit(): void {
    this.metricService.updateContextBuilding(this.building.id, this.floorId);
    this.metricService.currentContext$.pipe(takeUntil(this.destroy$)).subscribe((metricContext) => {
      this.timeNavigator.reset(this.building.timeZone);
      this.timeNavigator.setDates(metricContext.dates);
      this.timeNavigator.setResolution(metricContext.resolution);
      this.timeframeLabel = this.timeNavigator.getLabel().slice(0, 15);
      this.updateData(metricContext);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  copyToClipboard(): void {
    if (this.data != null) {
      this.copyToClipboardService.copyString(this.data.asCsvData(Pair.of('Time', this.metric.label)));
      this.toastService.success({
        message: 'Copied data to your clipboard! Paste into a spreadsheet to import the data.',
        dataCy: 'copied-to-clipboard'
      });
    }
  }

  updateData(context: IQueryContext): void {
    if (context.dataType !== this.metric.dataType) {
      this.update();
      return;
    }
    const outline = this.metricService.getNavigationOutline();
    this.isBusy = true;
    this.queryExecutor.doComplexQuery(outline).subscribe((result: QueryResult<number>) => {
      this.update();
      this.isBusy = false;
      this.data = this.convertToChart(result, context);
      this.render(this.data);
    });
  }

  render(chart: ChartData): void {
    if (this.chartContent?.nativeElement) {
      const renderableChart = new RenderableChart(
        chart,
        this.chartContent.nativeElement,
        () => this.timeNavigator.canSelect(),
        (points) => this.select(points),
        (amount) => this.timeNavigator.canZoom(amount),
        (amount) => this.zoom(amount)
      );
      renderableChart.render();
    }
  }

  setActiveAndRefresh(): void {
    this.setActive.emit(this.metric.dataType);
    this.metricService.updateContextDataType(this.metric.dataType);
  }

  zoomIn(): void {
    this.zoom(1);
  }

  zoomOut(): void {
    this.zoom(-1);
  }

  select(points: DataPoint[]): void {
    this.timeNavigator.select(points.map((point) => point.x));
    this.metricService.updateContextDateAndResolution(
      this.timeNavigator.getDates(),
      this.timeNavigator.getResolution()
    );
  }

  shiftTimeframeForward(): void {
    this.shiftTimeframe(1);
  }

  shiftTimeframeBack(): void {
    this.shiftTimeframe(-1);
  }

  private convertToChart(result: QueryResult<number>, context: IQueryContext): ChartData {
    const dataType = context.dataType;
    const chart = ChartData.fromQueryResult(result);
    chart.values.forEach((point) => {
      point.isActive = this.timeNavigator.isActive(point.x);
      point.xLabel = this.timeNavigator.getPointLabel(point.x);
      point.yLabel = dataType.format(point.y, result.suffix);
    });
    return chart;
  }

  private shiftTimeframe(amount: number): void {
    this.timeNavigator.shift(amount);
    this.metricService.updateContextDateAndResolution(
      this.timeNavigator.getDates(),
      this.timeNavigator.getResolution()
    );
  }

  private update(): void {
    this.timeframeLabel = this.timeNavigator.getLabel().slice(0, 15);
    this.canZoomIn = this.timeNavigator.canZoom(1);
    this.canZoomOut = this.timeNavigator.canZoom(-1);
    this.canShiftTimeframe = this.timeNavigator.canShift();
  }

  private zoom(amount: number): void {
    this.timeNavigator.zoom(amount);
    this.metricService.updateContextDateAndResolution(
      this.timeNavigator.getDates(),
      this.timeNavigator.getResolution()
    );
  }
}
