import { Resource } from '../Resource';
import { SimpleLampType } from '../../api/building/LampType';
import { SavedEntity } from '../../api/SavedEntity';
import { IResource } from '../../api/IResource';
import { PromiseRejectionHandler } from '../../promises/PromiseRejectionHandler';
import { ILampTypeTemplateResource } from '../../api/ILampTypeTemplateResource';
import { LampTypeTemplate } from '../../api/building/ILampTypeTemplate';
import { PromiseUtils } from '../promises/PromiseUtils';

export class LampTypeResource
  extends Resource<SimpleLampType, number>
  implements IResource<SimpleLampType, number>, ILampTypeTemplateResource
{
  constructor(
    $http: ng.IHttpService,
    baserUrlGenerator: (id: number) => string,
    rejectionHandler: PromiseRejectionHandler
  ) {
    super($http, null, rejectionHandler, baserUrlGenerator);
  }

  public addByBuildingId(
    item: SimpleLampType,
    buildingId: number
  ): Promise<SimpleLampType> {
    // this.addTypeMetadata(item);
    return PromiseUtils.wrapSingle(
      this.$http.post(this.generateBaseUrl(buildingId), item),
      this.rejectionHandler
    );
  }

  private addTypeMetadata(item: SimpleLampType) {
    if (item instanceof SimpleLampType) {
      (item as any).type = 'simple';
    }
  }

  public getTemplates(buildingId: number): Promise<LampTypeTemplate[]> {
    return PromiseUtils.wrapSingle(
      this.$http.get(this.generateBaseUrl(buildingId) + '/template'),
      this.rejectionHandler
    );
  }

  public addTemplate(
    template: LampTypeTemplate
  ): Promise<SavedEntity<LampTypeTemplate, number>> {
    return PromiseUtils.wrapSingle(
      this.$http.post(this.baseUrl + '/template', template),
      this.rejectionHandler
    );
  }
}
