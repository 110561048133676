<div class='filter-container'>
  <div class='filter-top-container'>
    <mat-card class='left-panel' id='leftPanel'>
      @for (option of FilterConfig; track option; let last = $last) {
        <div (click)='selectItem(option.type)' class='min-w-full'>
          <div class='panel-options divider-spacing'>
            <div class='panel-options-name'>
              <button [ngClass]="{'primary-selected': option.type === selectedOption}"
                      [attr.data-cy]="'filter-by-' + option.formKey + '-button'"
                      mat-button>
                {{ option.label }}
              </button>
            </div>
            <mat-icon [ngClass]="option.type === selectedOption ? 'visible' : 'invisible' "
                      class='panel-options-chev'
                      color='primary'>
              chevron_right
            </mat-icon>
          </div>
          @if (!last) {
            <mat-divider class='divider-spacing'></mat-divider>
          }
        </div>
      }
    </mat-card>
    <mat-card class='right-panel' id='rightPanel'>
      <form [formGroup]='form' data-cy="filter-inputs-panel">
        @for (option of FilterConfig; track option.formKey) {
          @if (selectedOption === option.type && option.type !== FilterType.DATE) {
            <ng-container [formArrayName]='option.formKey'>
              @for (control of formArray(option.formKey).controls; track control.value; let i = $index) {
                <ng-container [formGroupName]='i'>
                  <mat-checkbox formControlName='checked'
                                [ngClass]="'checkbox-color-' + option.color"
                                [attr.data-cy]="option.dataCy + '-filter-checkbox-' + control?.value?.id">
                    {{ control?.value.name }}
                  </mat-checkbox>
                </ng-container>
              }
            </ng-container>
          }
        }
        @if (selectedOption === FilterType.DATE) {
          <section>
            <div id='emLogsFilterComponent'>
              <div class='grid grid-rows-3 gap-4'>
                <div class='row-span-1'>
                  <mat-card-subtitle>Filter by date</mat-card-subtitle>
                </div>
                <div class='row-span-2 grid grid-cols-2 date-filter-panel'>
                  <div class='col-span-1'>
                    <mat-form-field appearance='fill' class='from-date-field  filter-form-field'>
                      <mat-label>DD/MM/YYYY</mat-label>
                      <input formControlName='startDate'
                             (dateChange)='selectStartDate($event)'
                             [matDatepicker]='fromDatePicker'
                             [max]='maxFromDate'
                             data-cy='from-date-input'
                             matInput>
                      <mat-hint>From Date</mat-hint>
                      <mat-datepicker-toggle [for]='fromDatePicker' matSuffix data-cy="from-datepicker-toggle"></mat-datepicker-toggle>
                      <mat-datepicker #fromDatePicker></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div class='col-span-1'>
                    <mat-form-field appearance='fill' class='to-date-field filter-form-field'>
                      <mat-label>DD/MM/YYYY</mat-label>
                      <input formControlName='endDate'
                             (dateChange)='selectEndDate($event)'
                             [matDatepicker]='toDateDicker'
                             [max]='maxToDate'
                             [min]='minToDate'
                             data-cy='to-date-input' matInput>
                      <mat-hint>To Date</mat-hint>
                      <mat-datepicker-toggle [for]='toDateDicker' matSuffix data-cy="to-datepicker-toggle"></mat-datepicker-toggle>
                      <mat-datepicker #toDateDicker></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </section>
        }
      </form>
    </mat-card>
    <div class='filter-actions-panel'>
      <button [class]="isFilterSelected ? 'apply_enabled' : 'apply_disabled'"
              (click)='filter()'
              [disabled]='!isFilterSelected'
              data-cy='apply-filters-button'
              mat-button>
        <mat-icon>done</mat-icon>
        Apply Filters
      </button>
      <button [class]="isFilterSelected ? 'reset_enabled' : 'reset_disabled'"
              (click)='resetFilters()'
              [disabled]='!isFilterSelected'
              color='warn'
              data-cy='reset-filters-button'
              mat-button>
        <mat-icon>refresh</mat-icon>
        Reset Filters
      </button>
    </div>
  </div>
  <div class='applied-filters-container'>
    <h3 class='text-xl'>Applied Filters</h3>
    <mat-card class='selected-options-card' data-cy='applied-filters-section'>
      @if (!isFilterSelected) {
        <div class='no-filters-applied-container'>
          <h3 class='text-lg font-bold'>No Filters Applied</h3>
        </div>
      }
      <mat-chip-listbox class='scrollable-chip-listbox'>
        @for (option of FilterConfig; track option) {
          @if (option.formKey != 'date') {
            @for (control of selectedItemArray(option.formKey); track control) {
              <div>
                <mat-chip-option (removed)='removeSelection(formArray(option.formKey), control)'
                                 [ngClass]="'chip-color-' + option.color"
                                 [attr.data-cy]="'selected-' + option.dataCy + '-chip-' + control?.value?.id"
                                 selectable='false'>
                  <span [ngClass]="'chip-color-' + option.color">{{ control?.name }}</span>
                  <button matChipRemove>
                    <mat-icon [ngClass]="'chip-color-' + option.color">cancel</mat-icon>
                  </button>
                </mat-chip-option>
              </div>
            }
          }
        }
      </mat-chip-listbox>
      @if (startDate.value != null || endDate.value != null) {
        <mat-chip-listbox class='sticky-chip-listbox'>
          <div class='mt-[10px]'>
            <mat-chip-option (removed)='clearDateFilter()'
                             [ngClass]="'chip-color-grey'"
                             selectable='false'
                             data-cy='selected-date-chip'>
              @if (startDate.value != null) {
                <span [ngClass]="'chip-color-grey'" class='mr-[5px]'>{{ startDate.value?.toLocaleDateString() }} </span>
              }
              --
              @if (endDate.value != null) {
                <span [ngClass]="'chip-color-grey'" class='ml-[5px]'>  {{ endDate.value?.toLocaleDateString() }} </span>
              }
              <button matChipRemove>
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-option>
          </div>
        </mat-chip-listbox>
      }
    </mat-card>
  </div>
</div>
