<div class="p-2 h-full">
  @if (isLoading$ | async) {
    <div class="h-full flex justify-center items-center">
      <mat-spinner [diameter]='50' color="primary" />
    </div>
  } @else {
    <div class="header">
      <div class="title">
        <span class="text-base sm:text-xl md:text-xl lg:text-xl xl:text-2xl 2xl:text-3xl font-semibold" [attr.data-cy]="item.dataCy + '-title'" [innerHTML]="item.title"></span>
        <div class="sub-title">
          @if (item.subTitle) {
            <span [attr.data-cy]="item.dataCy + '-subtitle'" class="text-sm sm:text-xl">{{item.subTitle}}</span>
          }
        </div>
      </div>
    </div>
    <div class="body">
      <div class="flex flex-col items-center">
        @if (!item.hideIcon) {
          <div class="h-18">
            <span [attr.data-cy]="item.dataCy + '-icon'" [ngClass]="item.iconClass" class="w-10 h-10 md:w-16 md:h-16 lg:w-16 lg:h-16 xl:w-18 xl:h-18"></span>
          </div>
        }
        <div [attr.data-cy]="item.dataCy + '-amount'" class="count">
          {{countB}}
        </div>
        <p class="text-sm text-orange-500 p-[10px]">{{prevYearText}}</p>
      </div>
      <mat-divider vertical class="h-1/2 border-[1px]" />
      <div class="flex flex-col items-center">
        @if (!item.hideIcon) {
          <div class="h-18">
            <span [attr.data-cy]="item.dataCy + '-icon'" [ngClass]="item.iconClass" class="w-10 h-10 md:w-16 md:h-16 lg:w-16 lg:h-16 xl:w-18 xl:h-18"></span>
          </div>
        }
        <div [attr.data-cy]="item.dataCy + '-amount'" class="count">
          {{countA}}
        </div>
        <p class="text-sm text-orange-500 p-[10px]">{{currentYearText}}</p>
      </div>
    </div>
  }
</div>
