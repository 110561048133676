import { Action } from '@angularjs/or/delegates/Delegates';
import { Role } from '@angularjs/or/api/auth/Role';
import { MultiselectOption } from '../or-multiselect/MultiselectOption';
import { UserInvitation } from '@angularjs/or/api/building/UserInvitation';

export class OrFormInviteUserController {
  private roleOptions: MultiselectOption<number>[];

  public isBusy: boolean;
  public roles: Role[];
  public resetRoles: Action;
  public user: UserInvitation;
  public isValid: boolean;

  public getRoleOptions(): MultiselectOption<number>[] {
    if (this.roleOptions == null) {
      this.roleOptions = this.roles.map(
        (role) => new MultiselectOption(role.name, role.id)
      );
    }

    return this.roleOptions;
  }
}
