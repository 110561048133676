import { User } from '@angularjs/or/api/auth/User';
import { IUserService } from '@angularjs/or/services/IUserService';
import { SessionService } from '@angularjs/or/services/SessionService';
import { Map } from '@angularjs/or/util/Map';
import { IDropdown } from '@angularjs/or/services/IDropdown';
import { DropdownService } from '@angularjs/or/services/DropdownService';
import { Observable } from '@angularjs/or/util/Observable';
import { ObservableBool } from '@angularjs/or/util/ObservableBool';

export class OrSessionMenuController implements IDropdown {
  private isActiveObservable: Observable<ObservableBool>;
  private pictureUrl: string;
  public name: string;

  constructor(
    public $scope: ng.IScope,
    public sessionService: SessionService,
    public userService: IUserService,
    public userInfoBaseUrl: string,
    public globalAdministrationBaseUrl: string,
    private dropdownService: DropdownService
  ) {}

  public $onInit() {
    this.userService.getCurrentUser().then((user: User) => {
      this.init(user);
    });

    this.isActiveObservable = new Observable<ObservableBool>(
      new ObservableBool(false)
    );
    this.dropdownService.add(this);
  }

  logout() {
    this.sessionService.logout();
  }

  producePictureUrlStyle() {
    const style: Map<string> = {};
    if (this.pictureUrl) {
      style['background-image'] = 'url(' + this.pictureUrl + ')';
    }
    return style;
  }

  produceUserClass() {
    const classes: Map<boolean> = {};
    if (!this.pictureUrl) {
      classes['or-icon-userpic'] = true;
      if (!this.isActive) {
        classes['or-inverse'] = true;
      }
    }

    return classes;
  }

  toggle() {
    this.isActiveObservable.change((observableBool: ObservableBool) =>
      observableBool.toggle()
    );
    if (this.isActive()) {
      this.dropdownService.notify(this.getDropdownName());
    }
  }

  isActive() {
    return this.isActiveObservable.value().getBool();
  }

  collapse() {
    this.isActiveObservable.change((val) => val.setBool(false));
  }

  collapseWithApply() {
    this.$scope.$apply(() => {
      this.collapse();
    });
  }

  getDropdownName() {
    return 'sessions';
  }

  init(user: User) {
    this.$scope.$apply(() => {
      this.pictureUrl = user.pictureUrl;
      this.name = user.name;
    });
  }
}
