export class ComplexQueryOutline {
  constructor(
    public queryType: string,
    public dataType: string,
    public buildingId: number,
    public tagIds: number[],
    public sensorNodeIds: number[],
    public floorIds: number[],
    public years?: string[],
    public months?: string[],
    public days?: string[],
    public hours?: string[],
    public minutes?: string[],
    public zone?: string,
    public unit?: string
  ) {}
}
