import { Dates } from './outline/Dates';
import { IQueryContext } from '@app/analytics/metric-widget/query/context';
import { QueryContext } from '@app/analytics/metric-widget/query/outline/context/query-context';
import { DataType } from '@app/shared/models/sensor-node-data-type';
import { Resolution } from '@app/analytics/metric-widget/data-objects/resolution';
import { Coordinate } from '@app/shared/utils/coordinate';

export const defaultContext: IQueryContext = new QueryContext(
  DataType.PRESENCE,
  1,
  [1],
  undefined,
  new Dates(),
  Resolution.DAILY,
  [],
  [],
  'hour',
  'offset',
  1,
  new Coordinate(0, 0),
  false
);
