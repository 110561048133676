import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Environment, environmentToken } from '@environment';
import { Observable } from 'rxjs';
import { Resource } from '@app/shared/resources/resource';
import { ElmtEmailTableData } from '@app/shared/models/elmt-email-table-datasource';
import { Pageable } from '../models/Pageable.interface';
import { Filter } from '../models/filter.interface';

@Injectable({
  providedIn: 'root'
})
export class ElmtEmailDataResource extends Resource<ElmtEmailTableData, number> {
  constructor(protected httpClient: HttpClient, @Inject(environmentToken) environment: Environment) {
    super(httpClient, `${environment.apiUrl + environment.apiPath}/emergency-lighting-emails`);
  }

  public getAllResources(buildingId: number, filter: Filter): Observable<Pageable<ElmtEmailTableData>> {
    return this.httpClient.get<Pageable<ElmtEmailTableData>>(`${this.baseUrl}/${buildingId}`, {
      params: new HttpParams()
        .set('page', filter.pageIndex.toString())
        .set('size', filter.pageSize.toString())
        .set('sort', filter.sortOrder)
    });
  }

  addResource(buildingId: number, item: ElmtEmailTableData): Observable<ElmtEmailTableData> {
    return this.httpClient.post<ElmtEmailTableData>(`${this.baseUrl}/${buildingId}`, item);
  }

  updateResource(buildingId: number, item: ElmtEmailTableData): Observable<ElmtEmailTableData> {
    return this.httpClient.put<ElmtEmailTableData>(`${this.baseUrl}/${buildingId}`, item);
  }

  deleteResource(buildingId: number, emailScheduleId: number): Observable<void> {
    return this.httpClient.delete<void>(`${this.baseUrl}/${buildingId}/${emailScheduleId}`);
  }
}
