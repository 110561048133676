import { MappingService } from '@services/mapping.service';
import { Observable } from 'rxjs';

export class OrAudioController {
  private audio = null;

  constructor(private element, private mappingService: MappingService) {}

  public $onInit(): void {
    // this.mappingService.setAudioCallback((mappingId) => this.playAudio(mappingId));
  }

  private getAllAudio(): any {
    if (this.audio == null) {
      this.audio = this.element.find('audio');
    }

    return this.audio;
  }

  private getAudio(i: number): any {
    return this.getAllAudio()[i];
  }

  public playAudio(i: number, retries = 5, timeBetweenRetries = 100): Observable<void> {
    return new Observable<void>((observer) => {
      const attemptPlayback = (attempt: number) => {
        const audio = this.getAudio(i);
        if (audio == null) {
          observer.error('Audio not loaded: ' + i);
          return;
        }
        audio.onended = () => {
          setTimeout(() => {
            if (attempt < retries) {
              attemptPlayback(attempt + 1);
            } else {
              observer.complete();
            }
          }, timeBetweenRetries);
        };
        audio.oncanplaythrough = () => {
          const audioPlayResult = audio.play();
          if (audioPlayResult) {
            audioPlayResult.catch(() => observer.error('Could not play audio: ' + i));
          }
        };
        audio.load();
      };
      attemptPlayback(1);
    });
  }

  public getAudioSources(): void {
    // return this.mappingService.getAudioSources();
  }
}
