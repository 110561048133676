import { Resource } from '../Resource';
import { User } from '../../api/auth/User';
import { PromiseUtils } from '../promises/PromiseUtils';
import { IUserResource } from '../../api/IUserResource';

export class UserResource
  extends Resource<User, number>
  implements IUserResource
{
  public join(): Promise<User> {
    return PromiseUtils.wrapSingle(
      this.$http.get(this.baseUrl + '/join'),
      this.rejectionHandler
    );
  }

  public delete(id: number): Promise<{}> {
    return PromiseUtils.wrapSingle(
      this.$http.delete(this.baseUrl + '/' + id),
      this.rejectionHandler
    );
  }
}
