export class DataPoint {
  constructor(
    public x: number,
    public y: number,
    public xLabel?: string,
    public yLabel?: string,
    public isActive: boolean = true
  ) {
    this.xLabel = x.toString();
    this.yLabel = y.toString();
  }
}
