export class SimpleQueryOutline {
  constructor(
    public queryType: string,
    public dataType: string,
    public buildingId: number,
    public tagIds: number[],
    public sensorNodeIds: number[],
    public floorIds: number[],
    public resolution: string,
    public start: number,
    public end: number,
    public zone?: string,
    public unit?: string
  ) {}
}
