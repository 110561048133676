import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Environment, environmentToken } from '@environment';
import { Observable } from 'rxjs';
import { UserTenant } from '@app/shared/models/user-tenant';
import { Pageable } from '@app/shared/models/Pageable.interface';

@Injectable({
  providedIn: 'root'
})
export class UserTenantResource {
  private readonly baseUrl: string;
  constructor(private httpClient: HttpClient, @Inject(environmentToken) private environment: Environment) {
    this.baseUrl = `${environment.apiUrl + environment.apiPath}`;
  }

  public getUserTenantsForBuilding(
    buildingId: number,
    pageNumber: number,
    pageSize: number
  ): Observable<Pageable<UserTenant>> {
    return this.httpClient.get<Pageable<UserTenant>>(`${this.baseUrl}/user/building/${buildingId}/tenants`, {
      params: new HttpParams().set('page', pageNumber.toString()).set('size', pageSize.toString())
    });
  }

  public updateUserTenant(userId: number, newTenantId: number, oldTenantId: number): Observable<void> {
    return this.httpClient.post<void>(`${this.baseUrl}/user/${userId}/tenant/update`, { newTenantId, oldTenantId });
  }

  public addUserToTenant(userId: number, tenantId: number): Observable<void> {
    return this.httpClient.put<void>(`${this.baseUrl}/user/${userId}/tenant/${tenantId}`, {});
  }

  public removeUserFromTenant(userId: number, tenantId: number): Observable<void> {
    return this.httpClient.delete<void>(`${this.baseUrl}/user/${userId}/tenant/${tenantId}`);
  }
}
