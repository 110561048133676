export class OrBuildingsService {
  public $http;

  public buildings = [];

  private endpoints = {
    buildings: '/dummy-data/buildings.json'
  };

  constructor($http) {
    this.$http = $http;
  }

  getBuildings() {
    const promise = this.$http.get(this.endpoints.buildings);
    promise.then((response) => {
      this.buildings = response.data;
    });
    return promise;
  }
}
