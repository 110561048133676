<mat-card>
  <mat-card-header>
    <mat-card-title>Email Report Scheduling</mat-card-title>
  </mat-card-header>
  <form data-cy="schedule-email-form" [formGroup]="emailScheduleFormGroup">
    <div class="p-[16px]">
      <div class="form-fields-container">
        <div class="form-fields">
          <mat-form-field>
            <mat-label>Report Name</mat-label>
            <input matInput data-cy="report-name" formControlName="reportName" />
            @if (emailScheduleFormGroup.controls.reportName.errors?.required) {
              <mat-error>Report name is required</mat-error>
            } @else if (emailScheduleFormGroup.controls.reportName.errors?.maxlength) {
              <mat-error>Report name is too long!</mat-error>
            }
          </mat-form-field>
          <mat-form-field>
            <mat-label>Users to receive emails*</mat-label>
            <mat-chip-grid data-cy="add-users-chips" #chipUserGrid aria-label="Add users" [formControl]="userChipGridCtrl">
              @for (user of selectedUsers; track user.id) {
                <mat-chip-row (removed)="removeUser(user)">{{user.name}}
                  <button matChipRemove [attr.aria-label]="'remove ' + user">
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip-row>
              }
              <input
                placeholder="Add user"
                data-cy="add-user-input"
                #userInput
                [matChipInputFor]="chipUserGrid"
                [formControl]="userInputCtrl"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="true"
                [matAutocomplete]="userAutoComplete" />
            </mat-chip-grid>
            <mat-error>Please select at least one user</mat-error>
            <mat-autocomplete #userAutoComplete="matAutocomplete" (optionSelected)="userSelected($event)">
              @for (user of filteredUsernames | async; track user) {
                <mat-option [value]="user">{{user}}</mat-option>
              }
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="form-fields">
          <mat-form-field>
            <mat-label>Start Date</mat-label>
            <input matInput [min]="minSelectableDate" data-cy="start-date" [matDatepicker]="start_date_picker" placeholder="Repeats Monthly" formControlName="sendDate" />
            @if (emailScheduleFormGroup.controls.sendDate.errors?.required) {
              <mat-error>Start date is required</mat-error>
            } @else if (emailScheduleFormGroup.controls.sendDate.errors != null) {
              <mat-error>Start date cannot be in the past</mat-error>
            }
            <mat-datepicker-toggle [for]="start_date_picker" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #start_date_picker></mat-datepicker>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Schedules to send report for</mat-label>
            <mat-chip-grid #chipScheduleGrid aria-label="Add schedules" [formControl]="scheduleChipGridCtrl">
              @for (schedule of selectedSchedules; track schedule.id) {
                <mat-chip-row (removed)="removeSchedule(schedule.id)">{{schedule.name}}
                  <button matChipRemove [attr.aria-label]="'remove ' + schedule.name">
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip-row>
              }
              <input
                placeholder="Add schedule"
                data-cy="add-schedule-input"
                #scheduleInput
                [matChipInputFor]="chipScheduleGrid"
                [formControl]="scheduleInputCtrl"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="true"
                [matAutocomplete]="scheduleAutoComplete" />
            </mat-chip-grid>
            <mat-error>Please select at least one schedule</mat-error>
            <mat-autocomplete #scheduleAutoComplete="matAutocomplete" (optionSelected)="scheduleSelected($event)">
              @for (schedule of filteredSchedules | async; track schedule) {
                <mat-option [value]="schedule">{{schedule}}</mat-option>
              }
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
      <div class="mb-2">
        <mat-radio-group formControlName="scheduleRadio">
          <mat-radio-button data-cy="all-schedules-radio" aria-label="All Schedules" value="all" [checked]="true">All Schedules</mat-radio-button>
          <mat-radio-button data-cy="specific-schedules-radio" aria-label="Specific Schedules" value="specific">Specific Schedules</mat-radio-button>
        </mat-radio-group>
      </div>
      <section class="form-footer">
        <button mat-raised-button color="primary" data-cy="save-form-button" (click)="saveEmailSchedule()" [disabled]="isFormInvalid">{{submitBtnLabel}}</button>
        <button mat-raised-button color="accent" data-cy="reset-form-button" [disabled]="isFormUntouched" (click)="resetForm()">Reset</button>
      </section>
    </div>
  </form>
</mat-card>