import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class EventBridge {
  private readonly trigger = new Subject();

  public getTrigger(): Subject<{}> {
    return this.trigger;
  }

  public triggerUpdate(): void {
    this.trigger.next(null);
  }
}
