import { Component, OnInit } from '@angular/core';
import { HeaderService } from '@app/shared/services/header.service';
import { UserService } from '@app/shared/services/user/user.service';
import { NavigationService } from '@app/shared/services/navigation/navigation.service';
import { Building } from '@app/shared/models/building.interface';
import { concatMap, Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-circadian-curve',
  templateUrl: './circadian-curve.component.html',
  styleUrls: ['./circadian-curve.component.scss']
})
export class CircadianCurveComponent implements OnInit {
  public building$: Observable<Building>;

  constructor(
    private readonly header: HeaderService,
    private readonly userService: UserService,
    private readonly navigationService: NavigationService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.header.showBuildingsMenu();
    this.header.showSiteMenu();
    this.header.showUserMenu();
    this.header.showSessionMenu();
    this.header.hideFloorsMenu();
    this.setBuildingId();
  }

  setBuildingId(): void {
    this.building$ = this.route.params.pipe(
      concatMap((params) => {
        const { buildingId } = params;
        return this.userService.getBuilding(buildingId);
      })
    );
    this.building$.subscribe((building) => {
      this.navigationService.initNavigation(window.location.href, building);
    });
  }
}
