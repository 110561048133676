import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SavedEntity } from '../models/saved-entity.interface';
import { IResource } from './resource.interface';

@Injectable({
  providedIn: 'root'
})
export class Resource<I, K> implements IResource<I, K> {
  constructor(
    protected httpClient: HttpClient,
    protected baseUrl?: string,
    protected baseUrlGenerator?: (id: K) => string
  ) {}

  public add(item: I): Observable<SavedEntity<I, K>> {
    return this.httpClient.post<SavedEntity<I, K>>(this.baseUrl, item);
  }

  public addByParentId(item: I, id: K): Observable<SavedEntity<I, K>> {
    return this.httpClient.post<SavedEntity<I, K>>(this.generateBaseUrl(id), item);
  }

  public retrieve(id: K): Observable<I> {
    return this.httpClient.get<I>(this.generateBaseUrl(id));
  }

  public retrieveAllByParentId(id: K): Observable<I[]> {
    return this.httpClient.get<I[]>(this.generateBaseUrl(id));
  }

  public retrieveCurrent(reloginOnUnauthorized?: boolean): Observable<I> {
    return this.httpClient.get<I>(this.baseUrl);
  }

  public retrieveMany(ids: K[]): Observable<I>[] {
    return ids.map((id) => this.httpClient.get<I>(this.generateBaseUrl(id)));
  }

  public update(id: K, item: I): Observable<{}> {
    return this.httpClient.put<HttpResponse<I>>(this.generateBaseUrl(id), item);
  }

  public updateCurrent(item: I): Observable<{}> {
    return this.httpClient.put<HttpResponse<I>>(this.baseUrl, item);
  }

  public delete(id: K): Observable<{}> {
    return this.httpClient.delete<HttpResponse<any>>(this.generateBaseUrl(id));
  }

  public deleteByValue(item: I): Observable<{}> {
    return this.httpClient.delete<HttpResponse<I>>(this.baseUrl, item);
  }

  public deleteMany(ids: K[]): Observable<{}> {
    // TODO: Fix me - add ids to request
    return this.httpClient.request<HttpResponse<I>>('delete', this.baseUrl);
  }

  public setRouteId(id: K) {
    this.baseUrl = this.baseUrlGenerator(id);
  }

  protected generateBaseUrl(id: K): string {
    return this.baseUrlGenerator ? this.baseUrl + '/' + this.baseUrlGenerator(id) : this.baseUrl + '/' + id;
  }
}
