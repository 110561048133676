import * as angular from 'angular'; // Automatically added

import { OrSensorNodeController } from './OrSensorNodeController';
import * as d3 from 'd3';

export class OrSensorNodeDirective {
  constructor() {
    return {
      bindToController: true,
      link: (scope, element) => new OrSensorNodeLink(scope, element),
      controller: [
        '$scope',
        '$timeout',
        '$element',
        'TagService',
        'UIRefreshService',
        'SensorNodeService',
        'MappingService',
        'QueryContext',
        'LightingConfigurationService',
        OrSensorNodeController
      ],
      controllerAs: 'node',
      replace: true,
      restrict: 'E',
      scope: {
        buildingId: '<',
        details: '=',
        disableSelection: '=',
        isCumulativeSelectionActive: '=accumulateSelection',
        x: '=',
        y: '=',
        zoomLevel: '=',
        dataType: '=nodeDataType',
        markFaulty: '=',
        markEmergencyLight: '=',
        notificationMode: '=',
        isEmergencyLightingTestModeActive: '=emergencyLightingTestMode',
        isManualFunctionalTestModeActive: '=manualFunctionalTestMode',
        isManualDurationTestModeActive: '=manualDurationTestMode',
        isCancelTestModeActive: '=cancelTestMode',
        metric: '=',
        unit: '=',
        showValue: '=',
        isGatewayModeActive: '=',
        isFloorplanActive: '=',
        activePage: '=',
        refreshAlerts: '='
      },
      template: require('raw-loader!./or-sensor-node.html').default
    };
  }
}

export class OrSensorNodeLink {
  private defaultSize = this.scope.node.details.isPassiveNode ? 21 : 24;
  public tagsContainer: any;
  public size: any;

  constructor(private scope, private element) {
    this.scope.$watchCollection('[node.details.tags]', () => this.renderPieChart(), true);
    this.scope.$watch('node.details', () => this.renderPieChart(), true);
  }

  private loadSizes() {
    const ratio = 1;
    this.tagsContainer = d3.select(this.element.find('svg')[0]);
    this.size = {
      width: this.defaultSize / ratio,
      height: this.defaultSize / ratio,
      outerRadius: this.defaultSize / (2 * ratio),
      innerRadius: this.defaultSize / (this.scope.node.details.isPassiveNode ? 2.7 : 3.0 * ratio),
      x: 12,
      y: 12
    };
  }

  public renderPieChart() {
    this.loadSizes();
    const arc = d3.arc().outerRadius(this.size.outerRadius).innerRadius(this.size.innerRadius);
    const pie = d3.pie().value(function () {
      return 1;
    }); // 360 / node.details.tags.length; });

    this.tagsContainer.selectAll('*').remove();
    this.tagsContainer
      .append('g')
      .attr('transform', this.translate(this.size.x, this.size.y))
      .selectAll('.sensor-node-tag')
      .data(pie(this.scope.node.details.tags || []))
      .enter()
      .append('path')
      .attr('class', 'sensor-node-tag')
      .attr('d', arc)
      .attr('fill', (d) => '#' + d.data.color)
      .attr('title', (d) => d.data.name);
  }

  public translate(x: number, y: number): string {
    if (!angular.isDefined(y)) {
      y = x;
    }
    return 'translate(' + x + ',' + y + ')';
  }
}
