import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ElmtEmailTableData } from '@app/shared/models/elmt-email-table-datasource';

// This service will act as the state manager for the email schedules component
// passing data between the form and table components
@Injectable({
  providedIn: 'root'
})
export class EmailSchedulesService {
  private formSubmittedSource = new BehaviorSubject<void>(null); // using behaviour subject to start with null which results in getting data for table
  private rowClickedSource = new Subject<ElmtEmailTableData>();

  get formSubmitted$(): Observable<void> {
    return this.formSubmittedSource.asObservable();
  }

  get clickedRow$(): Observable<ElmtEmailTableData> {
    return this.rowClickedSource.asObservable();
  }

  submitClicked(): void {
    this.formSubmittedSource.next();
  }

  rowClicked(row: ElmtEmailTableData): void {
    this.rowClickedSource.next(row);
  }
}
