export class CopyToClipboardService {
  constructor() {}

  public copy(toCopy): void {
    navigator.clipboard.writeText(toCopy).then(
      () => {},
      (e) => {
        console.log('Failed to copy to clipboard', e);
        window.prompt('Copy to your clipboard.', toCopy);
      }
    );
  }
}
