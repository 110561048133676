import { Map } from './Map';

export class ArrayUtils {
  static all<T>(list: T[], predicate: (T, number) => boolean) {
    return (
      list.filter((item, index) => predicate(item, index)).length == list.length
    );
  }

  static add<T>(
    list: T[],
    element: T,
    equator?: (val1: T, val2: T) => boolean
  ) {
    if (ArrayUtils.indexOf(list, element, equator) < 0) {
      list.push(element);
    }
    return list;
  }

  static remove<T>(
    list: T[],
    element: T,
    equator?: (val1: any, val2: any) => boolean
  ) {
    return list.filter((item) =>
      equator != null ? !equator(item, element) : item != element
    );
  }

  static removeAll<T>(
    list: T[],
    listToRemove: T[],
    equator?: (val1: T, val2: T) => boolean
  ) {
    if (!listToRemove || listToRemove.length < 1) {
      return list;
    }
    for (const element of listToRemove) {
      ArrayUtils.removeElement(list, element, equator);
    }
  }

  static addAll<T>(
    list: T[],
    listToAdd: T[],
    equator?: (val1: T, val2: T) => boolean
  ) {
    if (!listToAdd || listToAdd.length < 1) {
      return list;
    }
    for (const element of listToAdd) {
      ArrayUtils.add(list, element, equator);
    }
  }

  static contains(
    list: any[],
    element: any,
    equator?: (val1: any, val2: any) => boolean
  ) {
    if (!list || list.length < 1) {
      return false;
    }
    let found = false;
    list.forEach((elementOfList) => {
      if (equator) {
        if (equator(elementOfList, element)) {
          found = true;
          return;
        }
      } else if (elementOfList.toString() === element.toString()) {
        found = true;
        return;
      }
    });
    return found;
  }

  static indexOf(
    list: any[],
    element: any,
    equator?: (val1: any, val2: any) => boolean
  ): number {
    if (!list || list.length < 1) {
      return -1;
    }
    let index = -1;
    let i = 0;
    for (const elementOfList of list) {
      if (equator) {
        if (equator(elementOfList, element)) {
          index = i;
          return index;
        }
      } else if (elementOfList.toString() === element.toString()) {
        index = i;
        return index;
      }
      i++;
    }
    return index;
  }

  static removeAtIndex(list: any[], index: number): any[] {
    if (index > -1 && index < list.length) {
      return list.splice(index, 1);
    }
    return [];
  }

  static removeElement(
    list: any[],
    element: any,
    equator?: (val1: any, val2: any) => boolean
  ): any[] {
    return ArrayUtils.removeAtIndex(
      list,
      ArrayUtils.indexOf(list, element, equator)
    );
  }

  // True if both lists contain the same elements.
  static equals(
    list1: any[],
    list2: any[],
    equator?: (val1: any, val2: any) => boolean
  ): boolean {
    if (!list1 && !list2) {
      return true;
    }

    if (
      (list1 && !list2) ||
      (!list1 && list2) ||
      list1.length !== list2.length
    ) {
      return false;
    }

    for (const val1 of list1) {
      if (!this.contains(list2, val1, equator)) {
        return false;
      }
    }

    return true;
  }

  // True only if both lists contain the same elements in the same order.
  static identical(
    list1: any[],
    list2: any[],
    equator?: (val1: any, val2: any) => boolean
  ): boolean {
    if (!list1 && !list2) {
      return true;
    }

    if (
      (list1 && !list2) ||
      (!list1 && list2) ||
      list1.length !== list2.length
    ) {
      return false;
    }

    for (const index in list1) {
      if (equator) {
        if (!equator(list1[index], list2[index])) {
          return false;
        }
      } else if (list1[index].toString() !== list2[index].toString) {
        return false;
      }
    }
    return true;
  }

  static distinct<T>(list: T[], getKey: (T) => string = (v) => v): T[] {
    const map = {} as Map<T>;
    const items = [];
    list.forEach((item) => {
      if (!(getKey(item) in map)) {
        map[getKey(item)] = item;
        items.push(item);
      }
    });
    return items;
  }

  static as<T, O extends T>(list: T[]): O[] {
    return list.map((item) => item as O);
  }

  static partition<T>(list: T[], predicate: (T) => boolean) {
    const matched = [];
    const unmatched = [];
    list.forEach((item) => (predicate(item) ? matched : unmatched).push(item));
    return [matched, unmatched];
  }
}
