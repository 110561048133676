import { OrScheduleRuleTileController } from './OrScheduleRuleTileController';

export class OrScheduleRuleTileDirective {
  constructor() {
    return {
      bindToController: true,
      controller: [OrScheduleRuleTileController],
      controllerAs: 'scheduleRuleTile',
      replace: true,
      restrict: 'E',
      scope: {
        rule: '='
      },
      template: require('raw-loader!./or-schedule-rule-tile.html').default
    };
  }
}
