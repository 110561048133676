export class EmergencyLightingTestPowerSupplyStatus {
  constructor(
    private value: EmergencyLightingTestPowerSupplyStatus_,
    private displayValue: string
  ) {}

  public static ACTIVE = new EmergencyLightingTestPowerSupplyStatus(
    'ACTIVE',
    'Active'
  );
  public static FAILED = new EmergencyLightingTestPowerSupplyStatus(
    'FAILED',
    'Failed'
  );

  public static all() {
    return [
      EmergencyLightingTestPowerSupplyStatus.ACTIVE,
      EmergencyLightingTestPowerSupplyStatus.FAILED
    ];
  }

  public static fromValue(
    value: string
  ): EmergencyLightingTestPowerSupplyStatus {
    switch (value) {
      case EmergencyLightingTestPowerSupplyStatus.ACTIVE.value:
        return EmergencyLightingTestPowerSupplyStatus.ACTIVE;
      case EmergencyLightingTestPowerSupplyStatus.FAILED.value:
        return EmergencyLightingTestPowerSupplyStatus.FAILED;
    }
  }

  public equals(other: EmergencyLightingTestPowerSupplyStatus): boolean {
    return other && this.equalsString(other.value);
  }

  public equalsString(other: string): boolean {
    return other !== null && other === this.value;
  }

  public get display(): string {
    return this.displayValue;
  }
}

type EmergencyLightingTestPowerSupplyStatus_ = 'ACTIVE' | 'FAILED';
