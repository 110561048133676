import * as ng from 'angular';
import { DDLController } from './DDLController';
import { NavigationService } from '@app/shared/services/navigation/navigation.service';
import { NavigationSectionInfo } from '@app/shared/services/navigation/navigation-section-info';
import { NavigationSection } from '@app/shared/services/navigation/navigation-section';

export class DDLConfig {
  private static ROUTE: string;

  private static routeProvider($routeProvider: ng.route.IRouteProvider) {
    $routeProvider.when(DDLConfig.ROUTE, {
      template: require('raw-loader!./ddl.html').default,
      controller: [
        '$routeParams',
        '$scope',
        'DdlSettingService',
        'DdlModeService',
        'DdlNodeService',
        'DdlTagService',
        'BuildingService',
        'NavigationService',
        'DDLAdministrationNavigationInfo',
        DDLController
      ],
      controllerAs: 'ddl'
    });
  }

  static run(
    navigationService: NavigationService,
    navigationServiceInfo: NavigationSectionInfo,
    urlGenerator: (buildingId?: number, floorId?: number) => string
  ) {
    navigationService.addSectionToSiteMap(
      new NavigationSection(navigationServiceInfo, urlGenerator)
    );
  }

  static configure(route: string) {
    this.ROUTE = route;
    ng.module('portal')
      .config(['$routeProvider', this.routeProvider])
      .run([
        'NavigationService',
        'DDLAdministrationNavigationInfo',
        'DDLAdministrationUrlGenerator',
        this.run
      ]);
  }
}
