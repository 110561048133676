import { OrPanelToggleController } from './OrPanelToggleController';

export class OrPanelToggleDirective {
  constructor() {
    return {
      bindToController: true,
      controller: ['OrPanelService', 'CopyToClipboardService', OrPanelToggleController],
      controllerAs: 'panelToggle',
      replace: true,
      restrict: 'E',
      scope: {
        iconClass: '@',
        name: '@',
        panelId: '@',
        panelName: '@',
        isActive: '=?',
        isPrintable: '=',
        timezone: '=?'
      },
      template: require('raw-loader!./or-panel-toggle.html').default,
      transclude: true
    };
  }
}
