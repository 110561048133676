import { IEmergencyLightGroup } from './IEmergencyLightGroup';
import { IBuildingService } from '../IBuildingService';
import { FloorService } from '../FloorService';

export class LightingGroupService {
  private endpoints = {
    group: '/api/v1/emergency-light-group',
    groupsByFloor: (floorId: number) =>
      `/api/v1/floor/${floorId}/emergency-light-groups`
  };

  constructor(
    private $http,
    private buildingService: IBuildingService,
    private floorService: FloorService,
    private generateApiUrl: (endpoint: string) => string
  ) {}

  public getGroups(): Promise<IEmergencyLightGroup[]> {
    return new Promise((resolve, reject) => {
      this.floorService
        .getCurrentFloor()
        .then(
          (floor) =>
            this.getGroupsForFloor(floor.id)
              .then((group) => {
                resolve(group.data);
              })
              .catch((error) => reject(error)),
          (reason) => console.warn(reason)
        )
        .catch((error) => reject(error));
    });
  }

  public updateGroup(groupId: number, group: IEmergencyLightGroup) {
    const endpoint = this.endpoints.group;
    return this.$http.put(this.generateApiUrl(`${endpoint}/${groupId}`), group);
  }

  public addGroup(group: IEmergencyLightGroup) {
    const endpoint = this.endpoints.group;
    return this.$http.post(this.generateApiUrl(endpoint), group);
  }

  public getGroupsForFloor(floorId: number) {
    const endpointGenerator = this.endpoints.groupsByFloor;
    return this.$http.get(this.generateApiUrl(endpointGenerator(floorId)));
  }

  public getGroup(groupId: number) {
    const endpoint = this.endpoints.group;
    return this.$http.get(`${endpoint}/${groupId}`);
  }
}
