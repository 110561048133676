import { EmergencyLightingTestResultsController } from './EmergencyLightingTestResultsController';

export class EmergencyLightingTestResultsDirective {
  constructor() {
    return {
      bindToController: true,
      controller: ['$scope', '$timeout', 'SensorNodeService', EmergencyLightingTestResultsController],
      controllerAs: 'testResults',
      replace: true,
      restrict: 'E',
      scope: {
        results: '='
      },
      template: require('raw-loader!./emergency-lighting-test-results.html').default
    };
  }
}
