<div id='orGlobalAdministrationOtapUpgradesComponent'>

  <div class='spinner-container' *ngIf='dataSource.loading$ | async'>
    <mat-spinner style='margin:0 auto;' mode='indeterminate'></mat-spinner>
  </div>

  <mat-table class='mat-elevation-z8 jobs-list-table' [dataSource]='dataSource'
             matSort matSortActive='id' matSortDirection='asc' matSortDisableClear>

    <ng-container matColumnDef='id'>
      <mat-header-cell *matHeaderCellDef >#</mat-header-cell>
      <mat-cell *matCellDef='let job'>{{job.id}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef='name'>
      <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
      <mat-cell *matCellDef='let job'>{{job.name}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef='jobTaskTemplateName'>
      <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
      <mat-cell *matCellDef='let job'>{{job.jobTaskTemplateName}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef='status'>
      <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
      <mat-cell *matCellDef='let job'>{{job.status}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef='createdDate'>
      <mat-header-cell *matHeaderCellDef>Created date</mat-header-cell>
      <mat-cell *matCellDef='let job'>{{job.createdDateTime.split('T')[0]}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef='createdBy'>
      <mat-header-cell *matHeaderCellDef>Created by</mat-header-cell>
      <mat-cell *matCellDef='let job'>{{job.createdByUser}}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef='displayedColumns'></mat-header-row>
    <mat-row *matRowDef='let row; columns: displayedColumns' (click)="onJobClicked(row)"></mat-row>

  </mat-table>

  <mat-paginator [length]='dataSource.counter$ | async' [pageSize]='15'
                 [pageSizeOptions]='[15, 30, 100]'></mat-paginator>
</div>

<div class='add-job-button'>
  <button
    matTooltip='Add jobs'
    matTooltipClass='mat-tooltip font-15'
    matTooltipPosition='above'
    aria-label='Button to invite users to this building'
    mat-fab
    (click)='addJobs()'
    class='mat-elevation-z8'
    color='primary'>
    <mat-icon>add</mat-icon>
  </button>
</div>



