import {SensorNodeStatus} from "@angularjs/angular/modules/layout/or-sensor-node/SensorNodeStatus";

export abstract class Driver {
    protected constructor(
        public address?: number,
        public connected?: boolean,
    ) {
    }

    public get status(): SensorNodeStatus {
        if (this.address == null) {
            return SensorNodeStatus.UNKNOWN;
        }
        if (!this.connected) {
            return SensorNodeStatus.DISCONNECTED;
        }
        if (this.isFaulty) {
            return SensorNodeStatus.FAULTY;
        }
        return SensorNodeStatus.OK;
    }

    abstract get isFaulty(): boolean;
}
