export class EmergencyLightingTestType {
  constructor(public value: EmergencyLightingTestType_, public displayValue: string) {}

  public get display(): string {
    return this.displayValue;
  }

  public static FUNCTION = new EmergencyLightingTestType('FUNCTION', 'Functional');
  public static DURATION = new EmergencyLightingTestType('DURATION', 'Duration');

  public static all(): EmergencyLightingTestType[] {
    return [EmergencyLightingTestType.FUNCTION, EmergencyLightingTestType.DURATION];
  }

  public static fromValue(value: string): EmergencyLightingTestType {
    switch (value) {
      case EmergencyLightingTestType.FUNCTION.value:
        return EmergencyLightingTestType.FUNCTION;
      case EmergencyLightingTestType.DURATION.value:
        return EmergencyLightingTestType.DURATION;
    }
  }

  public toString(): string {
    return this.value;
  }

  public equals(other: EmergencyLightingTestType): boolean {
    return this.equalsString(other.value);
  }

  public equalsString(other: string): boolean {
    return other !== null && other === this.value;
  }
}

type EmergencyLightingTestType_ = 'FUNCTION' | 'DURATION';
