import { CheckboxState, FloorplanTag, Tag } from '../api/building/Tag';
import { IResource } from '../api/IResource';
import { SavedEntity } from '../api/SavedEntity';
import { IBuildingService } from './IBuildingService';
import { SensorNodeService } from './SensorNodeService';
import { SensorNodeService as NewSensorNodeService } from '@services/sensor-node.service';
import { IUserService } from './IUserService';
import { MultiTag } from '../angular/resources/MultiTagResource';
import { TagResource } from '@angularjs/or/angular/resources/TagResource';
import { Building } from '@angularjs/or/api/building/Building';
import { Subject } from 'rxjs';
import { SensorNode } from '@angularjs/or/api/building/SensorNode';
import { ArrayUtils } from '@angularjs/or/util/ArrayUtils';

export class TagService {
  public list = [];
  public selection = [];
  private buildingId: number;
  private tagsPromise: Promise<Tag[]>;
  private tags: Tag[];
  public shouldReloadTags = new Subject<boolean>();

  constructor(
    private userService: IUserService,
    private buildingService: IBuildingService,
    private resource: TagResource,
    private multiTagResource: IResource<MultiTag, number>,
    private nodesService: SensorNodeService,
    private newNodesService: NewSensorNodeService
  ) {}

  public getTags(building: Building, force: boolean, editable: boolean): Promise<Tag[]> {
    const tagResourcePromise = editable
      ? this.resource.getEditableTagsForBuilding(building.id)
      : this.resource.getTagsForBuilding(building.id);

    if (force || this.buildingId === null || building.id !== this.buildingId) {
      this.buildingId = building.id;
      this.tagsPromise = new Promise((resolve, reject) => {
        tagResourcePromise.then((tags) => {
          this.tags = tags.map(
            (tag) => new Tag(tag.buildingId, tag.name, tag.color, tag.id, tag.isActive, tag.tagType, tag.isAvailable)
          );
          this.tagsPromise = null;
          resolve(this.tags);
        });
      });
      return this.tagsPromise;
    } else if (this.tagsPromise && this.buildingId === building.id) {
      return this.tagsPromise;
    } else if (!force && this.tagsPromise == null && this.tags != null && this.buildingId === building.id) {
      return new Promise((resolve) => resolve(this.tags));
    }
  }

  public getTagsByIds(tagIds: number[]): Promise<Tag[]> {
    return this.resource.getTagsFromIds(tagIds);
  }

  public getTag(id: number): Promise<Tag> {
    return this.resource.retrieve(id);
  }

  public addTag(tag: Tag): Promise<SavedEntity<Tag, number>> {
    return new Promise((resolve, reject) => {
      const newTag = new Promise<SavedEntity<Tag, number>>((innerResolve) => {
        this.resource
          .add(tag)
          .then((savedTag) => {
            this.invalidateTags();
            innerResolve(savedTag);
          })
          .catch((reason) => {
            if (reason === 400) {
              alert('Invalid tag name. A tag with the same name already exists in the building.');
              reject();
            }
          });
      });
      resolve(newTag);
    });
  }

  public deleteTag(id: number): Promise<{}> {
    const promise = this.resource.delete(id);
    promise.then(() => this.invalidateTags());
    return promise;
  }

  public updateTag(id: number, tag: Tag): Promise<{}> {
    return new Promise((resolve, reject) =>
      resolve(
        this.resource
          .update(id, tag)
          .then(() => this.invalidateTags())
          .catch((reason) => {
            if (reason === 400) {
              alert('Invalid tag name. A tag with the same name already exists in the building.');
              reject();
            }
          })
      )
    );
  }

  private invalidateTags(): void {
    this.buildingId = null;
    this.shouldReloadTags.next(true);
  }

  public decorateTag(tag): { [p: string]: string } {
    return {
      'background-color': '#' + tag.color
    };
  }

  public updateTagList(): void {
    for (let currentTag, idx = 0, len = this.list.length; idx < len; idx += 1) {
      currentTag = this.list[idx];
      currentTag.isSelected = this.isTagSelected(currentTag.id);
      currentTag.isPartiallySelected = this.isTagPartiallySelected(currentTag.id);
      this.updateTagInList(currentTag.id, currentTag);
    }
  }

  public updateTagInList(tagId, tagDetails): void {
    for (let idx = 0, len = this.list.length; idx < len; idx += 1) {
      if (this.list[idx].id === tagId) {
        tagDetails.id = tagId;
        this.list[idx] = tagDetails;
        break;
      }
    }
  }

  public addTagToSelection(tag): void {
    tag.isSelected = true;
    tag.isPartiallySelected = false;
    this.updateTagInList(tag.id, tag);
    this.selection.push(tag);
  }

  public removeTagFromSelection(tagId): void {
    let currentTag;
    for (let idx = 0, len = this.selection.length; idx < len; idx += 1) {
      currentTag = this.selection[idx];
      if (currentTag.id === tagId) {
        currentTag.isSelected = false;
        currentTag.isPartiallySelected = false;
        this.updateTagInList(currentTag.id, currentTag);
        this.selection.splice(idx, 1);
        break;
      }
    }
  }

  public addTagTolist(tag): void {
    tag.isSelected = false;
    tag.isPartiallySelected = false;
    this.list.push(tag);
  }

  public removeTagFromList(tagId): void {
    for (let idx = 0, len = this.list.length; idx < len; idx += 1) {
      if (this.list[idx].id === tagId) {
        this.list.splice(idx, 1);
        break;
      }
    }
  }

  public clearSelection(): void {
    this.selection.length = 0;
    for (let idx = 0, len = this.list.length; idx < len; idx += 1) {
      this.list[idx].isSelected = false;
      this.list[idx].isPartiallySelected = false;
    }
    this.updateTagList();
  }

  public updateSelection(tag): void {
    if (this.isTagSelected(tag.id)) {
      this.removeTagFromSelection(tag.id);
    } else {
      this.addTagToSelection(tag);
    }
  }

  public isTagSelected(tagId): boolean {
    for (let idx = 0, len = this.selection.length; idx < len; idx += 1) {
      if (this.selection[idx].id === tagId) {
        return true;
      }
    }
  }

  public isTagPartiallySelected(tagId): boolean {
    let matches = 0;
    let currentNode;
    const selectedNodes = this.newNodesService.selectedEntities;
    for (let nodeIdx = 0, nodeLen = selectedNodes.length; nodeIdx < nodeLen; nodeIdx += 1) {
      currentNode = selectedNodes[nodeIdx];
      for (let tagIdx = 0, tagLen = currentNode.tags.length; tagIdx < tagLen; tagIdx += 1) {
        if (currentNode.tags[tagIdx].id === tagId) {
          matches += 1;
        }
      }
    }
    return matches > 0 && matches !== selectedNodes.length;
  }

  public getTagIndex(tagId, tags): number {
    for (let idx = 0, len = tags.length; idx < len; idx += 1) {
      if (tags[idx].id === tagId) {
        return idx;
      }
    }
    return -1;
  }

  public getTagsFromSelection(): Tag[] {
    const tags = [];
    let currentNode;
    let currentTag;
    const selectedNodes = this.newNodesService.selectedEntities;
    for (let nodeIdx = 0, nodeLen = selectedNodes.length; nodeIdx < nodeLen; nodeIdx += 1) {
      currentNode = selectedNodes[nodeIdx];
      if (this.newNodesService.isNodeSelected(currentNode.id) && currentNode.tags != null) {
        for (let tagIdx = 0, tagLen = currentNode.tags.length; tagIdx < tagLen; tagIdx += 1) {
          currentTag = currentNode.tags[tagIdx];
          if (this.getTagIndex(currentTag.id, tags) < 0) {
            tags.push(currentTag);
          }
        }
      }
    }
    return tags;
  }

  public tagNodes(multitag: MultiTag): Promise<{}> {
    const promise = this.multiTagResource.add(multitag);
    return promise;
  }

  public unTagNodes(multitag: MultiTag): Promise<{}> {
    const promise = this.multiTagResource.deleteByValue(multitag);
    return promise;
  }

  public updateCheckboxesForTags(tags: FloorplanTag[], selectedNodes: SensorNode[]): void {
    tags.forEach((tagInAll) => {
      tagInAll.checked = CheckboxState.UNCHECKED;
      let firstNode = true;
      selectedNodes.forEach((node) => {
        if (!node.tags || node.tags.length < 1) {
          if (tagInAll.checked.equals(CheckboxState.CHECKED)) {
            tagInAll.checked = CheckboxState.MIXED;
          }
        } else {
          let tagFoundInNodeTags = false;

          for (const tagForNode of node.tags) {
            if (tagForNode.id === tagInAll.id) {
              tagFoundInNodeTags = true;
            }
            if (firstNode) {
              if (tagForNode.id === tagInAll.id) {
                tagInAll.checked = CheckboxState.CHECKED;
              }
            } else {
              if (tagForNode.id === tagInAll.id) {
                if (!tagInAll.checked.equals(CheckboxState.CHECKED)) {
                  tagInAll.checked = CheckboxState.MIXED;
                }
              }
            }
          }

          if (!tagFoundInNodeTags) {
            if (!tagInAll.checked.equals(CheckboxState.UNCHECKED)) {
              tagInAll.checked = CheckboxState.MIXED;
            }
          }
        }
        firstNode = false;
      });
    });
  }

  public updateNodesForTag(tag: FloorplanTag, remove?: boolean, pristine?: boolean): void {
    this.nodesService.nodes.forEach((node) => {
      const index = this.indexOf(node.tags, tag);
      if (index > -1) {
        if (remove) {
          ArrayUtils.removeAtIndex(node.tags, index);
        } else {
          node.tags[index].name = tag.name;
          node.tags[index].color = tag.color;
        }
      }
      this.nodesService.refreshFloorPlanNodes.next([]);
      this.nodesService.refreshFloorPlanNodes.next(this.nodesService.createFloorplanNodes(this.nodesService.nodes));
    });
    if (!remove && !pristine) {
      tag.isDirty = true;
    }
  }

  private indexOf(tags: Tag[], tag: Tag): number {
    return ArrayUtils.indexOf(tags, tag, (tag1: Tag, tag2: Tag) => {
      return tag1.id === tag2.id;
    });
  }
}
