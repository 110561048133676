import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of, switchMap } from 'rxjs';
import { Floor } from '../models/floor.interface';
import { SavedEntity } from '../models/saved-entity.interface';
import { FloorResource } from '../resources/floor.resource';
import { catchError, concatMap, distinctUntilChanged, filter, map, shareReplay } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Event, NavigationEnd, Params, Router, RouterEvent, Scroll } from '@angular/router';
import { UserService } from '@services/user/user.service';
import { ToastService } from '@services/toast/toast.service';

type StringPair = [string, string];
@Injectable({
  providedIn: 'root'
})
export class FloorService {
  private floorCache: Record<number, Observable<Floor>> = {};
  private currentFloorId$ = new BehaviorSubject<number>(null);

  constructor(
    private readonly floorResource: FloorResource,
    private readonly router: Router,
    private readonly userService: UserService,
    private toast: ToastService
  ) {
    this.router.events
      .pipe(
        filter((event: Event | RouterEvent) => event instanceof NavigationEnd || event instanceof Scroll),
        distinctUntilChanged((previousEvent: NavigationEnd | Scroll, currentEvent: NavigationEnd | Scroll) => {
          // We do not want to update currentFloorId$ if only query params were updated in the URL [OTP-5898]
          // Both NavigationEnd and Scroll events are checked because Angular v15 onwards, Scroll event is triggered
          // whenever query params are updated
          const [previousUrl, currentUrl] = this.extractPreviousAndCurrentUrls(previousEvent, currentEvent);
          return previousUrl === currentUrl;
        }),
        map((_) => this.extractParams(this.router.routerState.snapshot.root)),
        filter((params) => params.buildingId && params.floorId),
        switchMap((params) =>
          forkJoin([
            of(params),
            this.userService.getFloorsForBuilding(params.buildingId).pipe(catchError((_) => of(null)))
          ])
        ),
        map(([params, floors]) => ({ floors, params }))
      )
      .subscribe(({ floors, params }) => {
        const { floorId, buildingId } = params;
        // if floors is received as null, it means an invalid buildingId was passed in the url
        if (floors == null) {
          this.showErrorAndNavigateToHome(`Building ${buildingId} does not exist, redirecting back to home page`);
          this.currentFloorId$.next(null);
          return;
        }
        const isFloorExisting = floors.some((f) => f.id === Number(floorId));
        if (!isFloorExisting) {
          // choose the first floor in the list if the floorId is invalid
          this.currentFloorId$.next(Number(floors[0].id));
          return;
        }
        // if all is good lets update the current floor id
        this.currentFloorId$.next(Number(floorId));
      });
  }

  private extractPreviousAndCurrentUrls(
    previousEvent: NavigationEnd | Scroll,
    currentEvent: NavigationEnd | Scroll
  ): StringPair {
    let previousUrl = previousEvent instanceof Scroll ? previousEvent.routerEvent.url : previousEvent.url;
    let currentUrl = currentEvent instanceof Scroll ? currentEvent.routerEvent.url : currentEvent.url;
    previousUrl = previousUrl.includes('?') ? previousUrl.split('?')[0] : previousUrl;
    currentUrl = currentUrl.includes('?') ? currentUrl.split('?')[0] : currentUrl;
    return [previousUrl, currentUrl];
  }

  private extractParams(route: ActivatedRouteSnapshot): Params {
    const params: Params = {};
    // Traverse the route tree to extract the params
    while (route) {
      Object.assign(params, route.params);
      route = route.firstChild;
    }
    return params;
  }

  private showErrorAndNavigateToHome(message: string): void {
    this.toast.error({
      message,
      dataCy: 'load-error-toast',
      autoClose: false,
      dismissible: true
    });
    this.router.navigate(['/buildings']).catch((err) => console.error(err));
  }

  private retrieveFloor(floorId: number): Observable<Floor> {
    if (!this.floorCache[floorId]) {
      this.floorCache[floorId] = this.floorResource.retrieve(floorId).pipe(shareReplay());
    }
    return this.floorCache[floorId];
  }

  getCurrentFloorId(): Observable<number> {
    return this.currentFloorId$.asObservable();
  }

  getCurrentFloor(): Observable<Floor> {
    // currentFloorId$ subject starts with null. we return "null" wrapped as an Observable in that case
    return this.currentFloorId$.pipe(concatMap((floorId) => (floorId ? this.retrieveFloor(floorId) : of(null))));
  }

  getAllFloorsForBuildingId(buildingId: number): Observable<Floor[]> {
    return this.floorResource.getFloorsForBuilding(buildingId);
  }

  getFloorImageUrl(floor: Floor): string {
    return this.floorResource.getFloorImageUrl(floor);
  }

  invalidateFloorCache(id: number): void {
    delete this.floorCache[id];
  }

  public updateFloor(floor: Floor): Observable<{}> {
    this.invalidateFloorCache(floor.id);
    return this.floorResource.update(floor.id, floor);
  }

  public addFloor(floor: Floor): Observable<SavedEntity<Floor, number>> {
    return this.floorResource.add(floor);
  }

  public deleteFloor(floorId: number): Observable<{}> {
    this.invalidateFloorCache(floorId);
    return this.floorResource.delete(floorId);
  }
}
