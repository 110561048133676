import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { Building } from '@app/shared/models/building.interface';
import { concatMap } from 'rxjs/operators';
import { UserService } from '@services/user/user.service';
import { TokenService } from '@services/token/token.service';
import { Token } from '@app/shared/models/token.interface';
import { Tenant } from '@app/shared/models/tenant.interface';
import { TenantService } from '@services/building/tenant.service';
import { TokenStatus } from '@app/shared/models/token-status';
import { ToastService } from '@services/toast/toast.service';
import { RoleService } from '@services/role.service';
import { Role } from '@app/shared/models/role.interface';

@Component({
  selector: 'app-tokens',
  templateUrl: './tokens.component.html',
  styleUrls: ['./tokens.component.scss']
})
export class TokensComponent implements OnInit {
  tokens: Token[];
  deactivatedTokens: Token[];
  roles: Role[];
  tenants: Tenant[];
  building: Building;
  isBusy = true;
  isShowDeactivatedTokens = false;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private tokenService: TokenService,
    private roleService: RoleService,
    private tenantService: TenantService,
    private toast: ToastService
  ) {}

  ngOnInit(): void {
    this.loadValues();
  }

  loadValues(): void {
    this.isBusy = true;
    this.getSelectedBuilding().subscribe((selectedBuilding) => {
      this.building = selectedBuilding;
      forkJoin([this.loadTokens(selectedBuilding), this.loadRoles(selectedBuilding), this.loadTenants(selectedBuilding)]).subscribe({
        next: (res) => this.handleTokensRolesAndTenants(res),
        error: (err) => this.handleError(err, 'Failed to load token data.')
      });
    });
  }

  handleTokensRolesAndTenants(res: [Token[], Role[], Tenant[]]): void {
    if (res && res.length > 2) {
      this.tenants = res[2].sort((tenantA, tenantB) => this.sortTenants(tenantA, tenantB));
    }

    if (res && res.length > 1) {
      this.roles = res[1];
    }

    if (res && res.length > 0) {
      this.tokens = res[0].filter(
        (val) =>
          val.tokenStatus === TokenStatus.READY_FOR_USE.toString() ||
          val.tokenStatus === TokenStatus.ACTIVATED.toString()
      );
      this.mapDeactivatedTokens(res);
    }
    this.isBusy = false;
  }

  sortTenants(tenantA: Tenant, tenantB: Tenant): number {
    const nameA = tenantA.name.toUpperCase();
    const nameB = tenantB.name.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }

  loadTokens(selectedBuilding: Building): Observable<Token[]> {
    return this.tokenService.getAllTokensForBuilding(selectedBuilding.id, this.isShowDeactivatedTokens);
  }

  loadRoles(selectedBuilding: Building): Observable<any[]> {
    return this.roleService.getAllForBuilding(selectedBuilding.id);
  }

  loadTenants(selectedBuilding: Building): Observable<any[]> {
    return this.tenantService.getAllTenants(selectedBuilding.id);
  }

  getSelectedBuilding(): Observable<Building> {
    return this.route.params.pipe(
      concatMap((params: any) => {
        return this.userService.getBuilding(params.buildingId);
      })
    );
  }

  mapDeactivatedTokens(res: any[]): void {
    this.deactivatedTokens = res[0].filter((val) => val.tokenStatus === TokenStatus.DEACTIVATED.toString());
    if (this.deactivatedTokens && this.tenants) {
      this.deactivatedTokens = this.deactivatedTokens.map((val: any) => {
        const dTenant = this.tenants?.filter((t) => val.tenantIds && t.id === val.tenantIds[0]);
        val.disabledTenant = dTenant && dTenant.length > 0 ? dTenant[0].name : 'Select Tenant';
        return val;
      });
    }
  }

  toggleShowDeactivatedTokens(): void {
    this.isShowDeactivatedTokens = !this.isShowDeactivatedTokens;
    this.loadValues();
  }

  handleError(error: any, message: any): void {
    this.toast.error({ message, dataCy: 'load-error-toast' });
  }
}
