import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ImageZoomService } from '@app/shared/services/image-zoom.service';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-floorplan-zoom-control',
  templateUrl: './floorplan-zoom-control.component.html',
  styleUrls: ['./floorplan-zoom-control.component.scss']
})
export class FloorplanZoomControlComponent implements OnInit {
  suppressFloorplan: boolean;
  isScaled$: Observable<boolean>;
  isCentered$: Observable<boolean>;
  canIncrease$: Observable<boolean>;
  canDecrease$: Observable<boolean>;
  @Output() onZoomIncrease = new EventEmitter<void>();
  @Output() onZoomDecrease = new EventEmitter<void>();

  constructor(private readonly imageZoomService: ImageZoomService) {}

  ngOnInit(): void {
    this.isScaled$ = this.imageZoomService.isScaled();
    this.isCentered$ = this.imageZoomService.isCentered();
    this.canIncrease$ = this.imageZoomService.canZoomIn();
    this.canDecrease$ = this.imageZoomService.canZoomOut();
  }

  isResetDisabled$(): Observable<boolean> {
    return combineLatest<boolean[]>([this.isCentered$, this.isScaled$]).pipe(
      map(([isCentered, isScaled]) => isCentered && !isScaled)
    );
  }

  toggleSuppressFloorplan(isActive: boolean): void {
    this.suppressFloorplan = isActive;
  }

  resetAndRecenter(): void {
    this.imageZoomService.resetAndRecenter();
  }

  increase(): void {
    this.onZoomIncrease.emit();
  }

  decrease(): void {
    this.onZoomDecrease.emit();
  }
}
