import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserTenantResource } from '@app/shared/resources/user-tenant.resource';
import { UserTenant } from '@app/shared/models/user-tenant';

export class TenantUserDatasource implements DataSource<UserTenant> {
  private tenantsSubject = new BehaviorSubject<UserTenant[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private countSubject = new BehaviorSubject<number>(0);
  public counter$ = this.countSubject.asObservable();
  public loading$ = this.loadingSubject.asObservable();
  public pageIndex = 0;
  constructor(private tenantResource: UserTenantResource, private buildingId: number) {}

  loadUserTenants(pageIndex = 0, pageSize = 10): void {
    this.pageIndex = pageIndex;
    this.loadingSubject.next(true);
    this.tenantsSubject.next([]);
    this.tenantResource.getUserTenantsForBuilding(this.buildingId, pageIndex, pageSize).subscribe((tenants) => {
      this.countSubject.next(tenants.totalElements);
      this.tenantsSubject.next(tenants.content);
      this.loadingSubject.next(false);
    });
  }

  connect(_collectionViewer: CollectionViewer): Observable<UserTenant[]> {
    return this.tenantsSubject.asObservable();
  }

  disconnect(_collectionViewer: CollectionViewer): void {
    return this.tenantsSubject.complete();
  }
}
