import { OrTimerangeWidgetController } from './OrTimerangeWidgetController';

export class OrTimerangeWidgetDirective {
  constructor() {
    return {
      bindToController: true,
      controller: ['$scope', '$element', 'PropertiesService', OrTimerangeWidgetController],
      controllerAs: 'timerange',
      replace: true,
      restrict: 'E',
      scope: {
        startTime: '=',
        endTime: '=',
        minHours: '=',
        isValidRange: '='
      },
      template: require('raw-loader!./or-timerange-widget.html').default
    };
  }
}
