import { OrHeatmapController } from './OrHeatmapController';

export class OrHeatmapDirective {
  constructor() {
    return {
      bindToController: true,
      controller: [
        '$scope',
        'SensorNodeService',
        'QueryOutlineBuilder',
        'HeatmapRenderer',
        'QueryContext',
        'NavigationService',
        OrHeatmapController
      ],
      controllerAs: 'heatmap',
      replace: true,
      restrict: 'E',
      scope: {
        isScaleNormalized: '=',
        nodes: '=',
        data: '=',
        width: '=',
        height: '=',
        enabled: '=',
        nodesSub: '='
      },
      template: require('raw-loader!./or-heatmap.html').default
    };
  }
}
