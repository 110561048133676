import * as angular from 'angular'; // Automatically added
import { Action, Consumer } from '@angularjs/or/delegates/Delegates';
import { TrayButton } from '../or-floorplan-actions-tray/OrFloorplanActionsTrayController';
import { NavigationService } from '@app/shared/services/navigation/navigation.service';
import { SensorNodeService } from '@angularjs/or/services/SensorNodeService';
import { SensorNodeService as NewSensorNodeService } from '@services/sensor-node.service';
import { PassiveNodeService } from '@app/shared/services/passive-node.service';
import { IBuildingService } from '@angularjs/or/services/IBuildingService';
import { SensorNodeIdAndAddressDTO } from '@app/shared/models/sensor-node-id-and-address-dto.interface';
import { VirtualNotificationDTO } from '@app/shared/models/virtual-notification-dto.interface';
import { MappingService } from '@services/mapping.service';
import { SensorNode } from '@angularjs/or/api/building/SensorNode';
import { DISCRIMINATOR, Selectable } from '@app/shared/models/selectable.interface';
import { SensorNodeIdsBatch } from '@app/shared/models/sensor-node-batch.interface';

export interface EnhancedSelectable extends Selectable {
  groupId?: number;
  address?: number;
  properlyMapped?: boolean;
  hasMappingBeenAttempted?: boolean;
}

export class OrFloorplanActionsController {
  public suppressFloorplan: boolean;
  public isHeatmapEnabled: boolean;
  public areNodesEnabled: boolean;
  public areDriversEnabled: boolean;
  public areDriversEnabledAfterMapping: boolean;
  public areFaultyNodesEnabled: boolean;
  public areEmergencyLightsEnabled: boolean;
  public areMappedNodesEnabled: boolean;
  public areUnmappedNodesEnabled: boolean;
  public areSubscriberConnectionsEnabled: boolean;
  public isLiveModeEnabled: boolean;
  public isScaleNormalized: boolean;
  public isAddModeEnabled: boolean;
  public isMoveModeEnabled: boolean;
  public isMoveAllModeEnabled: boolean;
  public isMappingModeEnabled: boolean;
  public isPassiveNodeMappingModeEnabled: boolean;
  public isCumulativeSelectionEnabled: boolean;
  public isAreaSelectionEnabled: boolean;
  public isGatewayModeEnabled: boolean;
  public isManualFunctionalTestModeEnabled: boolean;
  public isManualDurationTestModeEnabled: boolean;
  public isCancelTestModeEnabled: boolean;
  public runTestsInBatch: Consumer<string>;
  public cancelTestsManually: Action;
  public saveFloorplan: Action;
  public discardFloorplan: Action;
  public undoFloorplan: Action;
  public saveChangedSensorNodes: Action;
  public undoChangedSensorNodes: Action;
  public discardChangedSensorNodes: Action;
  public clearSelection: Action;
  public selectAllNodes: Action;
  public deleteNodes: Action;
  public showTray: TrayButton[] = [];
  public addTray: TrayButton[] = [];
  public mapNodesTray: TrayButton[] = [];
  public unmappedNodesListTray: TrayButton[] = [];
  public moveTray: TrayButton[] = [];
  public selectionTray: TrayButton[] = [];
  public nodeOptionsTray: TrayButton[] = [];
  public availableUnmappedPNList: SensorNodeIdAndAddressDTO[] = [];
  public selectedPN: SensorNode;

  private isSensorNodesPage: boolean;
  private isAnalyticsPage: boolean;
  private isEmergencyLightingPage: boolean;
  private isBeaconsPage: boolean;
  private isLightingConfigurationPage: boolean;
  private readonly UNMAPPING_FAILED = 'Could not unmap selected nodes';
  private readonly UNGROUPED_PNS_IN_SELECTION = 'No Passive Nodes without a Group in Selection';
  private readonly PUBLISHER_UNMAP_MSG =
    'Selected node(s) is a publisher and subscribed passive nodes will be affected. Do you want to unmap?';
  private readonly UNMAP_NODE_MSG = 'Do you want to unmap selected node(s)?';
  private readonly INVALID_NODE_TYPE_UNMAP_MSG = 'Please only select 1 type of node to map/unmap';
  private readonly ONLY_MAPPED_CONNECTED_MSG = 'Please only select mapped and connected nodes';
  public hasShowTray: boolean;
  public showMapNodesTray: boolean;
  public hasAddTray: boolean;
  public hasMapNodesTray: boolean;
  public hasMoveTray: boolean;
  public hasSelectionTray: boolean;
  public hasHeatMap: boolean;
  public hasNodeOptionsTray: boolean;
  public isMappingAllowed: boolean;
  public isGatewayModeAllowed: boolean;
  public onMappingModeEnter: () => void;
  public onPassiveNodeMappingModeEnter: () => void;
  public onMappingModeLeave: () => void;
  public onPassiveNodeMappingModeLeave: () => void;
  public onReloadNodes: () => void;
  public onGatewayModeEnter: () => void;
  public onGatewayModeLeave: () => void;

  public buildingId: number;

  constructor(
    private navigationService: NavigationService,
    private nodesService: SensorNodeService,
    private newNodeService: NewSensorNodeService,
    private passiveNodeService: PassiveNodeService,
    private buildingService: IBuildingService,
    private mappingService: MappingService,
    private $scope: ng.IScope
  ) {}

  public $onInit(): void {
    this.buildingService.getCurrentBuilding().then((building) => {
      this.buildingId = building.id;
    });
    const sectionId = this.navigationService.getActiveSection().info.Id;
    this.areDriversEnabledAfterMapping = this.areDriversEnabled;
    if (sectionId === 'nodes') {
      this.isSensorNodesPage = true;
      this.isMappingAllowed = true;
      this.isGatewayModeAllowed = true;
    } else if (sectionId === 'heatmap') {
      this.isAnalyticsPage = true;
      this.hasHeatMap = true;
    } else if (sectionId === 'beacons') {
      this.isBeaconsPage = true;
      this.areDriversEnabled = false;
    } else if (sectionId === 'lighting-configuration') {
      this.isLightingConfigurationPage = true;
      this.areDriversEnabled = false;
    } else {
      this.isEmergencyLightingPage = true;
    }

    this.initialiseShowTray();

    if (this.isSensorNodesPage) {
      this.initializeAddTray();
      this.initializeMoveTray();
      this.initialiseNodeOptionsTray();
    }

    this.initializeSelectionTray(); // OTP-2722: Allow selection of multiple EM nodes in EM groups page

    this.initialiseMapNodesTray();
    this.initialiseUnmappedPNList();

    this.$scope.$watch('actions.isPassiveNodeMappingModeEnabled', (newValue: boolean) => {
      this.showMapNodesTray = newValue || this.isMappingModeEnabled;
    });

    this.$scope.$watch('actions.isMappingModeEnabled', (newValue: boolean) => {
      this.showMapNodesTray = newValue || this.isPassiveNodeMappingModeEnabled;
    });
  }

  private initialiseUnmappedPNList(): void {
    if (this.isSensorNodesPage) {
      this.passiveNodeService.getPassiveNodesList(this.buildingId).subscribe({
        next: (sensorNodes) => {
          this.availableUnmappedPNList = sensorNodes;
        },
        error: (error) => {
          console.error('There was an error getting the Passive Node list', error);
        }
      });
    }
  }

  public hasAddTrayFirst(): boolean {
    return document.getElementById('show-tray') != null;
  }

  public hasMoveTrayFirst(): boolean {
    return document.getElementById('show-tray') != null && document.getElementById('add-tray') != null;
  }

  public hasSelectionTrayFirst(): boolean {
    return (
      document.getElementById('show-tray') != null &&
      document.getElementById('add-tray') != null &&
      document.getElementById('move-tray') != null
    );
  }

  public hasMapNodesTrayFirst(): boolean {
    return (
      document.getElementById('show-tray') != null &&
      document.getElementById('add-tray') != null &&
      document.getElementById('move-tray') != null &&
      document.getElementById('select-tray') != null
    );
  }

  private initialiseMapNodesTray(): void {
    this.hasMapNodesTray = true;
    this.mapNodesTray.push(
      new TrayButton(
        'Map Sensor Nodes',
        'or-icon-s-node',
        () => {
          this.toggleSensorNodeMappingMode();
        },
        () => {
          return this.isMappingModeEnabled;
        },
        false,
        's-node-button'
      )
    );
    this.mapNodesTray.push(
      new TrayButton(
        'Map Passive Nodes',
        'or-icon-p-node',
        () => {
          this.togglePassiveNodeMappingMode();
        },
        () => {
          return this.isPassiveNodeMappingModeEnabled;
        },
        false,
        'p-node-button'
      )
    );
    this.hasMapNodesTray = this.mapNodesTray.length !== 0;
  }

  public unmap(): void {
    const selectedNodes = this.newNodeService.selectedEntities;
    const somePassive = selectedNodes.some((node) => node.discriminator === DISCRIMINATOR.PN);
    const someSN3 = selectedNodes.some((node) => node.discriminator === DISCRIMINATOR.SN3);

    if (somePassive && someSN3) {
      alert(this.INVALID_NODE_TYPE_UNMAP_MSG);
    } else if (somePassive) {
      this.unmapSelectedPN(selectedNodes);
    } else if (someSN3) {
      this.unmapSelectedSN3s(selectedNodes);
    }
  }

  private unmapSelectedSN3s(selectedNodes: EnhancedSelectable[]): void {
    const batch = new SensorNodeIdsBatch(selectedNodes.map((node) => node.id));
    let message = this.UNMAP_NODE_MSG;
    if (
      selectedNodes.some(
        (node) => node.groupId != null && (node.discriminator == null || node.discriminator === DISCRIMINATOR.SN3)
      )
    ) {
      message = this.PUBLISHER_UNMAP_MSG;
    }
    // FIXME: replace confirm dialog
    if (batch.nodeIds.length === 0 || !confirm(message)) {
      return;
    }
    this.mappingService.unmapGenericNodes(batch).subscribe({
      next: () => {
        selectedNodes.forEach((node) => {
          node.address = null;
          node.properlyMapped = false;
          node.hasMappingBeenAttempted = false;
        });
        this.onReloadNodes();
      },
      error: (error) => {
        // FIXME: replace this with custom dialog component once migrated
        alert(this.UNMAPPING_FAILED);
      }
    });
  }

  public resetDriversForSelectedNodes(): void {
    const nodes = this.nodesService.selectedNodes.value();
    if (nodes.length !== 0) {
      const mappedAndConnectedNodes = nodes.filter((node) => node.isNodeConnected && node.properlyMapped);
      if (mappedAndConnectedNodes.length !== 0) {
        this.nodesService.clearDriversForNodes(mappedAndConnectedNodes).then(
          (response) => {
            mappedAndConnectedNodes.forEach((node) => {
              node.luminaireDrivers = [];
              node.emDrivers = [];
            });
            alert('Successfully cleared all connected devices for ' + mappedAndConnectedNodes.length + ' node(s)');
            this.onReloadNodes();
          },
          (error) => alert(error)
        );
      } else if (mappedAndConnectedNodes.length === 0) {
        alert(this.ONLY_MAPPED_CONNECTED_MSG);
        return;
      }
    }
  }

  public mapOrUnmapSelectedPassiveNode(): void {
    const nodes = this.newNodeService.selectedEntities.filter((n) => n.discriminator === DISCRIMINATOR.PN);
    if (nodes.length !== 1) {
      alert('Please select one unmapped node from the floor to map');
      return;
    }
    if (this.selectedPN == null) {
      this.unmapSelectedPN(nodes);
    } else {
      this.mapSelectedPN(nodes[0]);
    }
  }

  private unmapSelectedPN(nodes: EnhancedSelectable[]): void {
    // Unmap passive node
    // For us to be able to unmap a PN it should be:
    // 1. A passive node
    // 2. Ungrouped
    const groupedPNs = nodes.filter((node) => node.discriminator === DISCRIMINATOR.PN && node.groupId != null);
    if (groupedPNs.length !== 0) {
      // FIXME: replace this with custom dialog component once migrated
      alert("Can't unmap grouped PNs " + groupedPNs.map((node) => node.id));
      return;
    }

    const passiveNodes = nodes.filter(
      (node) => node.discriminator === DISCRIMINATOR.PN && node.groupId == null && node.properlyMapped
    );
    const nodeIds = passiveNodes.map((node) => node.id);
    if (nodeIds.length !== 0) {
      this.mappingService.unmapGenericNodes(new SensorNodeIdsBatch(nodeIds)).subscribe(() => {
        // FIXME: replace this with custom dialog component once migrated
        alert('Successfully unmapped PN with IDs: ' + nodeIds);
        passiveNodes.forEach((node) => {
          node.address = null;
          node.properlyMapped = false;
        });
        this.initialiseUnmappedPNList();
        this.onReloadNodes();
      });
    } else {
      // FIXME: replace this with custom dialog component once migrated
      alert(this.UNGROUPED_PNS_IN_SELECTION);
    }
  }

  private mapSelectedPN(node: EnhancedSelectable): void {
    if (node.groupId != null) {
      // FIXME: replace this with custom dialog component once migrated
      alert("Can't re-map a grouped PN");
      return;
    }
    // An already mapped PN must be unmapped first
    if (node.properlyMapped) {
      // FIXME: replace this with custom dialog component once migrated
      alert('This node is already mapped. Please unmap it first, before mapping it to a different virtual node');
      return;
    }
    // Map passive node
    this.mapVirtualToActual(node);
  }

  private mapVirtualToActual(node: EnhancedSelectable): void {
    this.passiveNodeService
      .mapVirtual2Actual(new VirtualNotificationDTO(this.selectedPN.id, node.id, this.buildingId))
      .subscribe({
        next: (next) => {
          alert(
            'Virtual Node with ID: ' +
              this.selectedPN.id +
              ', has been successfully mapped to actual node with id: ' +
              node.id
          );
          this.initialiseUnmappedPNList();
          this.onReloadNodes();
        },
        error: (error) => {
          console.error('There was an error mapping virtual node to actual passive node', error);
          alert('There was an error mapping virtual node to actual passive node');
        }
      });
  }

  private initialiseShowTray(): void {
    this.hasShowTray = true;

    if (!this.isLightingConfigurationPage) {
      this.showTray.push(
        new TrayButton(
          'Enable All Nodes',
          'or-icon-node',
          () => {
            this.toggleNodes();
          },
          () => {
            return this.areNodesEnabled;
          },
          false,
          'enable-all-nodes-button'
        )
      );
    }

    this.showTray.push(
      new TrayButton(
        'Show Subscriber connections',
        'or-icon-pub-sub',
        () => {
          this.toggleSubscriberConnections();
        },
        () => {
          return this.areSubscriberConnectionsEnabled;
        },
        false,
        'subscriber-connections-hide'
      )
    );

    if (!this.isAnalyticsPage && !this.isBeaconsPage && !this.isLightingConfigurationPage) {
      this.showTray.push(
        new TrayButton(
          'Show attached Devices',
          'or-icon-driver',
          () => {
            this.toggleDrivers();
          },
          () => {
            return this.areDriversEnabled;
          },
          false,
          'show-drivers-button'
        )
      );
    } else if (!this.isBeaconsPage && !this.isLightingConfigurationPage) {
      this.showTray.push(
        new TrayButton(
          'Enable Heatmap',
          'or-icon-heatmap',
          () => {
            this.toggleHeatmap();
          },
          () => {
            return this.isHeatmapEnabled;
          },
          false,
          'enable-heatmap-button'
        )
      );
    }

    if (this.isSensorNodesPage) {
      this.showTray.push(
        new TrayButton(
          'Show Faulty',
          'or-icon-node-faulty',
          () => {
            this.toggleFaultyNodes();
          },
          () => {
            return this.areFaultyNodesEnabled;
          },
          false,
          'show-faulty-button'
        )
      );
      this.showTray.push(
        new TrayButton(
          'Show Mapped',
          'or-icon-node-mapped',
          () => {
            this.toggleMappedNodes();
          },
          () => {
            return this.areMappedNodesEnabled;
          },
          false,
          'show-mapped-button'
        )
      );
      this.showTray.push(
        new TrayButton(
          'Show Unmapped',
          'or-icon-node-unmapped',
          () => {
            this.toggleUnmappedNodes();
          },
          () => {
            return this.areUnmappedNodesEnabled;
          },
          false,
          'show-unmapped-button'
        )
      );
    }

    if (!this.isSensorNodesPage && !this.isBeaconsPage && !this.isLightingConfigurationPage) {
      this.showTray.push(
        new TrayButton(
          'Highlight Emergency Lights',
          'or-icon-emergency',
          () => {
            this.toggleHighlightEmergencyLights();
          },
          () => {
            return this.areEmergencyLightsEnabled;
          },
          false,
          'highlight-emergency-lights-button'
        )
      );
    }
    this.hasShowTray = this.showTray.length !== 0;
  }

  private initialiseNodeOptionsTray(): void {
    this.hasNodeOptionsTray = true;
    if (this.isSensorNodesPage) {
      this.nodeOptionsTray.push(
        new TrayButton(
          'Clear attached Devices',
          'or-icon-node-reset',
          () => {
            this.resetDriversForSelectedNodes();
          },
          null,
          false,
          'reset-driver-button'
        )
      );
      this.nodeOptionsTray.push(
        new TrayButton(
          'Unmap Nodes',
          'or-icon-node-unmap',
          () => {
            this.unmap();
          },
          null,
          false,
          'selection-unmap-button',
          true
        )
      );
      this.nodeOptionsTray.push(
        new TrayButton(
          'Query BLE',
          'or-icon-ble-query',
          () => {
            console.log('Query Bluetooth!');
            this.nodesService.queryBleScanning(true).then((result) => {
              alert(result.message);
            });
          },
          null,
          false,
          'selection-ble-query-button'
        )
      );
      this.nodeOptionsTray.push(
        new TrayButton(
          'Turn on BLE',
          'or-icon-ble-on',
          () => {
            console.log('Turn on Bluetooth!');
            this.nodesService.enableBleScanning(true).then((result) => {
              alert(result.message);
            });
          },
          null,
          false,
          'selection-ble-on-button'
        )
      );
      this.nodeOptionsTray.push(
        new TrayButton(
          'Turn off BLE',
          'or-icon-ble-off',
          () => {
            console.log('Turn off Bluetooth!');
            this.nodesService.disableBleScanning(true).then((result) => {
              alert(result.message);
            });
          },
          null,
          false,
          'selection-ble-off-button'
        )
      );
    }
    this.hasNodeOptionsTray = this.nodeOptionsTray.length !== 0;
  }

  private initializeAddTray(): void {
    this.hasAddTray = true;
    this.addTray.push(
      new TrayButton(
        'Save',
        'or-icon-checkmark',
        () => {
          this.save();
        },
        null,
        true,
        'add-tray-save-button'
      )
    );
    this.addTray.push(
      new TrayButton(
        'Undo',
        'or-icon-undo',
        () => {
          this.undo();
        },
        null,
        false,
        'add-tray-undo-button'
      )
    );
    this.addTray.push(
      new TrayButton(
        'Discard',
        'or-icon-cross',
        () => {
          this.discard();
        },
        null,
        true,
        'add-tray-discard-button'
      )
    );
    this.hasAddTray = this.addTray.length !== 0;
  }

  private initializeMoveTray(): void {
    this.hasMoveTray = true;
    this.moveTray.push(
      new TrayButton(
        'Move All Relatively',
        'or-icon-selection',
        () => {
          this.toggleMoveAllMode();
        },
        () => {
          return this.isMoveAllModeEnabled;
        },
        false,
        'move-all-relatively-button'
      )
    );
    this.moveTray.push(
      new TrayButton(
        'Save',
        'or-icon-checkmark',
        () => {
          this.saveMove();
        },
        null,
        true,
        'move-save-button'
      )
    );
    this.moveTray.push(
      new TrayButton(
        'Undo',
        'or-icon-undo',
        () => {
          this.undoMove();
        },
        null,
        false,
        'move-undo-button'
      )
    );
    this.moveTray.push(
      new TrayButton(
        'Discard',
        'or-icon-cross',
        () => {
          this.discardMove();
        },
        null,
        true,
        'move-discard-button'
      )
    );
    this.hasMoveTray = this.moveTray.length !== 0;
  }

  private initializeSelectionTray(): void {
    this.hasSelectionTray = true;
    if (this.isLightingConfigurationPage) {
      this.selectionTray.push(
        new TrayButton(
          'Clear Selection',
          'or-icon-selection-clear',
          () => {
            this.clear();
          },
          null,
          false,
          'selection-clear-button'
        )
      );
    } else {
      this.selectionTray.push(
        new TrayButton(
          'Area Selection',
          'or-icon-selection',
          (event: Event) => {
            this.toggleAreaSelection(event);
          },
          () => {
            return this.isAreaSelectionEnabled;
          },
          false,
          'selection-area-button'
        )
      );
      this.selectionTray.push(
        new TrayButton(
          'Cumulative Selection',
          'or-icon-selection-add',
          () => {
            this.toggleCumulativeSelection();
          },
          () => {
            return this.isCumulativeSelectionEnabled;
          },
          false,
          'selection-add-button'
        )
      );
      this.selectionTray.push(
        new TrayButton(
          'Select All',
          'or-icon-selection-all',
          () => {
            this.selectAll();
          },
          null,
          false,
          'selection-all-button'
        )
      );
      this.selectionTray.push(
        new TrayButton(
          'Clear Selection',
          'or-icon-selection-clear',
          () => {
            this.clear();
          },
          null,
          false,
          'selection-area-button'
        )
      );
    }

    if (this.isSensorNodesPage) {
      this.selectionTray.push(
        new TrayButton(
          'Delete',
          'or-icon-trash',
          () => {
            this.delete();
          },
          null,
          false,
          'selection-delete-button',
          true
        )
      );
    }
    this.hasSelectionTray = this.selectionTray.length !== 0;
  }

  public toggleSuppressFloorplan(isActive: boolean): void {
    this.suppressFloorplan = isActive;
  }

  public toggleNodes(): void {
    this.areNodesEnabled = !this.areNodesEnabled;
  }

  public toggleDrivers(): void {
    this.areDriversEnabled = !this.areDriversEnabled;
    this.areDriversEnabledAfterMapping = this.areDriversEnabled;
  }

  public toggleHeatmap(): void {
    this.isHeatmapEnabled = !this.isHeatmapEnabled;
  }

  public toggleFaultyNodes(): void {
    this.areFaultyNodesEnabled = !this.areFaultyNodesEnabled;
  }

  public toggleHighlightEmergencyLights(): void {
    this.areEmergencyLightsEnabled = !this.areEmergencyLightsEnabled;
  }
  public toggleSubscriberConnections(): void {
    this.areSubscriberConnectionsEnabled = !this.areSubscriberConnectionsEnabled;
  }

  public toggleManualFunctionalTestMode(): void {
    this.isManualFunctionalTestModeEnabled = !this.isManualFunctionalTestModeEnabled;

    if (this.isManualDurationTestModeEnabled) {
      this.isManualDurationTestModeEnabled = false;
    }

    if (this.isCancelTestModeEnabled) {
      this.isCancelTestModeEnabled = false;
    }
  }

  public toggleManualDurationTestMode(): void {
    this.isManualDurationTestModeEnabled = !this.isManualDurationTestModeEnabled;

    if (this.isManualFunctionalTestModeEnabled) {
      this.isManualFunctionalTestModeEnabled = false;
    }

    if (this.isCancelTestModeEnabled) {
      this.isCancelTestModeEnabled = false;
    }
  }

  public toggleCancelTestMode(): void {
    this.isCancelTestModeEnabled = !this.isCancelTestModeEnabled;

    if (this.isManualFunctionalTestModeEnabled) {
      this.isManualFunctionalTestModeEnabled = false;
    }

    if (this.isManualDurationTestModeEnabled) {
      this.isManualDurationTestModeEnabled = false;
    }
  }

  public toggleMappedNodes(): void {
    this.areMappedNodesEnabled = !this.areMappedNodesEnabled;
  }

  public toggleUnmappedNodes(): void {
    this.areUnmappedNodesEnabled = !this.areUnmappedNodesEnabled;
  }

  public toggleNormalizeScale(): void {
    this.isScaleNormalized = !this.isScaleNormalized;
  }

  public toggleLiveMode(): void {
    this.isLiveModeEnabled = !this.isLiveModeEnabled;
  }

  public toggleAddMode(): void {
    this.isAddModeEnabled = !this.isAddModeEnabled;

    if (this.isAddModeEnabled) {
      this.areNodesEnabled = true;
      this.areDriversEnabled = true;
      this.areUnmappedNodesEnabled = true;
    }
  }

  public toggleMoveMode(): void {
    this.isMoveModeEnabled = !this.isMoveModeEnabled;

    if (this.isMoveModeEnabled) {
      this.isAddModeEnabled = false;
      this.isMappingModeEnabled = false;
      this.isPassiveNodeMappingModeEnabled = false;
      this.areNodesEnabled = true;
      this.areUnmappedNodesEnabled = true;
    }
  }

  private toggleMoveAllMode(): void {
    this.isMoveAllModeEnabled = !this.isMoveAllModeEnabled;
  }

  public toggleSensorNodeMappingMode(): void {
    if (this.isPassiveNodeMappingModeEnabled) return;
    this.isMappingModeEnabled = !this.isMappingModeEnabled;

    if (this.isMappingModeEnabled) {
      this.areDriversEnabledAfterMapping = this.areDriversEnabled;
      this.areDriversEnabled = false;
      if (angular.isFunction(this.onMappingModeEnter)) {
        this.onMappingModeEnter();
      }
    } else {
      this.areDriversEnabled = this.areDriversEnabledAfterMapping;
      if (angular.isFunction(this.onMappingModeLeave)) {
        this.onMappingModeLeave();
      }
    }
  }

  public togglePassiveNodeMappingMode(): void {
    if (this.isMappingModeEnabled) return;
    this.isPassiveNodeMappingModeEnabled = !this.isPassiveNodeMappingModeEnabled;

    if (this.isPassiveNodeMappingModeEnabled) {
      this.isGatewayModeAllowed = false;
      if (angular.isFunction(this.onPassiveNodeMappingModeEnter)) {
        this.onPassiveNodeMappingModeEnter();
      }
    } else {
      this.isGatewayModeAllowed = true;
      if (angular.isFunction(this.onPassiveNodeMappingModeLeave)) {
        this.onPassiveNodeMappingModeLeave();
      }
    }
  }

  public toggleGatewayMode(): void {
    this.isGatewayModeEnabled = !this.isGatewayModeEnabled;
    if (this.isGatewayModeEnabled) {
      if (angular.isFunction(this.onGatewayModeEnter)) {
        this.onGatewayModeEnter();
      }
    } else {
      if (angular.isFunction(this.onGatewayModeLeave)) {
        this.onGatewayModeLeave();
      }
    }
  }

  public save(): void {
    this.saveFloorplan();
    this.isAddModeEnabled = false;
  }

  public discard(): void {
    this.discardFloorplan();
    this.isAddModeEnabled = false;
  }

  public undo(): void {
    this.undoFloorplan();
  }

  public delete(): void {
    this.deleteNodes();
  }

  public saveMove(): void {
    this.saveChangedSensorNodes();
    this.isMoveModeEnabled = false;
  }

  public undoMove(): void {
    this.undoChangedSensorNodes();
  }

  public discardMove(): void {
    this.discardChangedSensorNodes();
    this.isMoveModeEnabled = false;
  }

  public toggleAreaSelection(event: Event): void {
    this.isAreaSelectionEnabled = !this.isAreaSelectionEnabled;
    event.stopPropagation();
  }

  public toggleCumulativeSelection(): void {
    this.isCumulativeSelectionEnabled = !this.isCumulativeSelectionEnabled;
    if (this.isCumulativeSelectionEnabled) {
      this.areNodesEnabled = true;
    }
  }

  public selectAll(): void {
    this.selectAllNodes();
  }

  public clear(): void {
    this.clearSelection();
  }
}
