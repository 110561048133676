import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MobileToken } from '@app/shared/models/mobile-token.interface';
import { MobileTokenResource } from '@app/shared/resources/mobile-token.resource';

@Injectable({
  providedIn: 'root'
})
export class MobileTokenService {
  constructor(protected tokenResource: MobileTokenResource) {}

  getAllTokensForBuilding(buildingId: number): Observable<MobileToken[]> {
    return this.tokenResource.getAllTokensForBuilding(buildingId);
  }

  createToken(buildingId: number, token: MobileToken): Observable<MobileToken> {
    return this.tokenResource.createToken(buildingId, token);
  }

  updateToken(buildingId: number, token: MobileToken): Observable<void> {
    return this.tokenResource.updateToken(buildingId, token);
  }

  deactivateToken(buildingId: number, tokenId: number): Observable<void> {
    return this.tokenResource.deactivateToken(buildingId, tokenId);
  }
}
