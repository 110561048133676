import { Selectable } from '@app/shared/models/selectable.interface';

export enum TagType {
  STANDARD = 'STANDARD',
  TENANT = 'TENANT',
  TENANT_MADE = 'TENANT_MADE'
}

export interface SelectableWithTags extends Selectable {
  tags?: Tag[];
}

export interface DisplayTags {
  displayTags: Tag[];
  tags: Tag[];
}

export interface Tag {
  buildingId?: number;
  name?: string;
  color?: string;
  id?: number;
  isActive: boolean;
  tagType?: TagType;
  isAvailable?: boolean;
}

export interface FloorplanTag extends Tag {
  isDirty: boolean;
  showEditForm?: boolean;
  checked: CheckboxState;
}

export function tagToFloorplanTag(other: Tag): FloorplanTag {
  return { ...other, isDirty: false, showEditForm: false, checked: CheckboxState.UNCHECKED };
}

export function tagsToFloorplanTags(other: Tag[]): FloorplanTag[] {
  return other.reduce((acc, curr) => [...acc, tagToFloorplanTag(curr)], []);
}

export function orderByTagName(t1: Tag, t2: Tag): number {
  if (t1.name == null) {
    return -1;
  } else {
    return t1.name.localeCompare(t2.name);
  }
}

export function orderTagById(t1: Tag, t2: Tag): number {
  if (t1.id > t2.id) {
    return 1;
  } else if (t1.id < t2.id) {
    return -1;
  } else {
    return 0;
  }
}

export enum CheckboxState {
  CHECKED = 'CHECKED',
  UNCHECKED = 'UNCHECKED',
  MIXED = 'MIXED'
}
