import { IResource } from '../api/IResource';
import { SavedEntity } from '../api/SavedEntity';
import { IRole, Role, RoleSerialised } from '../api/auth/Role';
import { IUserService } from './IUserService';

export class RoleService {
  public roles: IRole[];

  constructor(
    private rolesResource: IResource<IRole, number>,
    private userService: IUserService
  ) {}

  public getAllRolesForBuilding(buildingId: number): Promise<IRole[]> {
    return this.rolesResource.retrieveAllByParentId(buildingId);
  }

  public addRole(
    buildingId: number,
    role: IRole
  ): Promise<SavedEntity<Role, number>> {
    return this.rolesResource.addByParentId(
      new RoleSerialised(role),
      buildingId
    );
  }

  public updateRole(roleId: number, role: IRole): Promise<{}> {
    return new Promise((resolve, reject) => {
      this.rolesResource
        .update(roleId, new RoleSerialised(role))
        .then((result) => {
          this.userService.invalidateCurrentUser();
          resolve(result);
        })
        .catch((error) => reject(error));
    });
  }

  public deleteRole(roleId: number): Promise<{}> {
    return this.rolesResource.delete(roleId);
  }
}
