import { Injectable } from '@angular/core';

@Injectable()
export class WindowRefService {
  get window(): Window {
    return window;
  }

  public goTo(url: string) {
    this.window.location.href = url;
  }
}
