import { Injectable } from '@angular/core';
import { TokenResource } from '@app/shared/resources/token.resource';
import { Observable } from 'rxjs';
import { Token } from '@app/shared/models/token.interface';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  constructor(private tokenResource: TokenResource) {}

  getAllTokensForBuilding(buildingId: number, shouldIncludeDeactivated = false): Observable<Token[]> {
    return this.tokenResource.getAllTokensForBuilding(buildingId, shouldIncludeDeactivated);
  }

  create(buildingId: number, token: any): Observable<Token> {
    return this.tokenResource.create(buildingId, token);
  }

  update(buildingId: number, token: any): Observable<Token> {
    return this.tokenResource.update(buildingId, token);
  }

  deactivate(buildingId: number, tokenId: number): Observable<void> {
    return this.tokenResource.deactivate(buildingId, tokenId);
  }
}
