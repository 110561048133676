export class Tag {
  constructor(
    public buildingId?: number,
    public name?: string,
    public color?: string,
    public id?: number,
    public isActive: boolean = false,
    public tagType?: TagType,
    public isAvailable?: boolean
  ) {}
}

export class FloorplanTag extends Tag {
  public isDirty: boolean;

  constructor(
    public buildingId?: number,
    public name?: string,
    public color?: string,
    public id?: number,
    public isActive: boolean = false,
    public tagType: TagType = TagType.STANDARD,
    public checked: CheckboxState = CheckboxState.UNCHECKED
  ) {
    super(buildingId, name, color, id, isActive, tagType);
    this.isDirty = false;
  }

  public static from(other: Tag): FloorplanTag {
    return new FloorplanTag(
      other.buildingId,
      other.name,
      other.color,
      other.id,
      other.isActive,
      other.tagType
    );
  }

  public static fromArray(other: Tag[]): FloorplanTag[] {
    const tags: FloorplanTag[] = [];
    other.forEach((tag) => {
      tags.push(FloorplanTag.from(tag));
    });
    return tags;
  }
}

export class CheckboxState {
  constructor(public value: CheckboxState_) {}

  static CHECKED = new CheckboxState('CHECKED');
  static UNCHECKED = new CheckboxState('UNCHECKED');
  static MIXED = new CheckboxState('MIXED');

  public toString(): string {
    return this.value;
  }

  public equals(other: CheckboxState): boolean {
    return this.value === other.value;
  }
}

export type CheckboxState_ = 'CHECKED' | 'UNCHECKED' | 'MIXED';

export enum TagType {
  STANDARD = 'STANDARD',
  TENANT = 'TENANT',
  TENANT_MADE = 'TENANT_MADE'
}
