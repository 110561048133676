import { Resource } from '../Resource';
import { IResource } from '../../api/IResource';

export class MultiTagResource
  extends Resource<MultiTag, number>
  implements IResource<MultiTag, number> {}

export class MultiTag {
  constructor(public tagIds: number[], public nodeIds: number[]) {}
}
