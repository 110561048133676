import { MappingResource } from '../angular/resources/MappingResource';
import { VirtualNotification } from '../api/building/VirtualNotification';
import { SensorNodeService } from './SensorNodeService';
import { Map } from '../util/Map';
import { MapUtils } from '../util/MapUtils';
import { FloorplanSensorNode } from '../api/building/FloorplanSensorNode';
import { SensorNodeIdsBatch } from '@angularjs/or/api/building/SensorNodeBatch';
import { SensorNode } from '@angularjs/or/api/building/SensorNode';

export class MappingService {
  private static MAPPING_ID_COUNT = 32;
  public static USER_CANCELLED_MAPPING = 'USER_CANCELLED_MAPPING';
  private activityInProgress = false;
  private activityWaiting = false;
  private postActivityInProgress = false;
  public mappingTried = false;
  private postActivityTimeout = 0;

  // The value is a little bigger than the value in the backend (allowedUpdateDelay = 20 seconds)
  private postActivityAllowedTimeframe = 25000;

  private activities: Map<(nodeId: number, mappingId: number, buildingId: number) => Promise<{}>> = {};
  private postMappingActivity: () => void;
  private nextMappingId = 0;

  constructor(
    private mappingResource: MappingResource,
    private mappingAudioUrlGenerator: (number) => string,
    private nodeService: SensorNodeService
  ) {
    this.addActivity('virtual', (nodeId, mappingId, buildingId) => {
      return this.mappingResource.map(new VirtualNotification(nodeId, mappingId, buildingId));
    });
  }

  public addActivity(key: string, observer: (nodeId: number, mappingId: number, buildingId: number) => Promise<any>) {
    this.activities[key] = observer;
  }

  public setPostMappingActivity(caller: () => void): void {
    this.postMappingActivity = caller;
  }

  public doMapping(nodeId: number, buildingId: number): Promise<{}[]> {
    this.activityInProgress = true;
    const promise = Promise.all(
      MapUtils.toPairs(this.activities).map((activity) => activity.value(nodeId, this.nextMappingId, buildingId))
    );
    promise
      .then(() => {
        this.activityInProgress = false;
        this.postActivityTimeout = this.postActivityAllowedTimeframe + Date.now();
        this.postMappingActivity();
        this.update();
        this.nodeService.setMappingAttempted(nodeId);
      })
      .catch((error) => {
        this.activityInProgress = false;
        console.error(error);
        this.update();
      });
    return promise;
  }

  private update() {
    this.nextMappingId = (this.nextMappingId + 1) % 32;
  }

  public getAudioSources() {
    const sources = [];
    for (let i = 0; i < MappingService.MAPPING_ID_COUNT; i++) {
      sources[i] = this.mappingAudioUrlGenerator(i);
    }
    return sources;
  }

  public unmapSN3s(nodes: FloorplanSensorNode[]): Promise<void> {
    const batch = new SensorNodeIdsBatch(nodes.map((node) => node.id));
    let message;
    if (
      nodes.some(
        (node) => node.groupId != null && (node.nodeType == null || node.nodeType === SensorNode.SN3_NODE_TYPE)
      )
    ) {
      message = 'Selected node(s) is a publisher and subscribed passive nodes will be affected. Do you want to unmap?';
    }
    return this.unmapGenericNodes(batch, message).then(() => {
      nodes.forEach((node) => {
        node.address = null;
        node.properlyMapped = false;
        node.hasMappingBeenAttempted = false;
        this.nodeService.resetMappingAttempted(node.id);
      });
    });
  }

  public unmapGenericNodes(sensorNodeIdsBatch: SensorNodeIdsBatch, message?: string): Promise<void> {
    if (!message) {
      message = 'Do you want to unmap the selected node(s)?';
    }
    if (!sensorNodeIdsBatch.nodeIds.length || !confirm(message)) {
      return Promise.reject(MappingService.USER_CANCELLED_MAPPING);
    }

    return new Promise((resolve, reject) => {
      this.mappingResource
        .unmap(sensorNodeIdsBatch)
        .then(() => {
          resolve();
        })
        .catch((err) => {
          alert('Could not unmap selected nodes.');
          reject(err);
        });
    });
  }
  public get isActivityInProgress(): boolean {
    return this.activityInProgress;
  }

  public get getPostActivityTimeout(): number {
    return this.postActivityTimeout;
  }

  public get isPostActivityInProgress(): boolean {
    return this.postActivityInProgress;
  }

  public setPostActivityInProgress(inProgress: boolean): void {
    this.postActivityInProgress = inProgress;
  }

  public get isActivityWaiting(): boolean {
    return this.activityWaiting;
  }

  public setActivityWaiting(isWaiting: boolean): void {
    this.activityWaiting = isWaiting;
  }

  public clearMappingFlags(): void {
    this.activityWaiting = false;
    this.activityInProgress = false;
    this.postActivityInProgress = false;
  }
}
