import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Environment, environmentToken } from '@environment';

@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    @Inject(environmentToken) private readonly environment: Environment
  ) {}

  intercept<T, E>(
    req: HttpRequest<T>,
    next: HttpHandler
  ): Observable<HttpEvent<E>> {
    const requestWithCredentials = req.clone({ withCredentials: true });
    const response = next.handle(requestWithCredentials).pipe(
      catchError((e) => {
        const isHandlingRequired = e.status === 401 || e.status === 404;
        if (e instanceof HttpErrorResponse && isHandlingRequired) {
          switch (e.status) {
            // FIXME: Ideally we should redirect to error page on 400 as well, but with how some requests work currently
            // FIXME: creating new building for eg, which require floor id to be null for correct UX, we might have to
            // FIXME: with a better strategy to handle 400s
            // FIXME: Need to do the same for 500s
            // case 400:
            //   window.location.href = this.environment.apiUrl + '/error/400.html?base=' + this.environment.frontendUrl;
            //   break;
            case 401:
              this.handleUnauthenticatedError(this.route.queryParams);
              break;
            case 404:
              window.location.href =
                this.environment.apiUrl +
                '/error/404.html?base=' +
                this.environment.frontendUrl;
              break;
            // case 500:
            //  window.location.href = this.environment.apiUrl + '/error/500.html?base=' + this.environment.frontendUrl;
            //  break;
          }
        }
        throw e;
      })
    );

    return response;
  }

  private handleUnauthenticatedError(queryParams) {
    const path = this.getCurrentPath();

    if (this.hasAlreadyAttemptedLogin(queryParams)) {
      this.router.navigate(['/login'], {
        queryParams: { redirectUrl: path, alreadyHandledAuthError: true }
      });
    }
    this.router.navigate(['/login'], { queryParams: { redirectUrl: path } });
  }

  private getCurrentPath() {
    const urlTree = this.router.parseUrl(this.router.url);
    urlTree.queryParams = {};
    return urlTree.toString();
  }

  private hasAlreadyAttemptedLogin(queryParams): boolean {
    return queryParams.attemptAuthorization != null;
  }
}
