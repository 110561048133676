export class SensorNodeStatus {
  constructor(public value: SensorNodeStatus_) {}

  public static DISCONNECTED = new SensorNodeStatus('DISCONNECTED');
  public static FAULTY = new SensorNodeStatus('FAULTY');
  public static OK = new SensorNodeStatus('OK');
  public static UNKNOWN = new SensorNodeStatus('UNKNOWN');

  public static all() {
    return [
      SensorNodeStatus.DISCONNECTED,
      SensorNodeStatus.FAULTY,
      SensorNodeStatus.OK,
      SensorNodeStatus.UNKNOWN
    ];
  }

  public toString() {
    return this.value;
  }
}

type SensorNodeStatus_ = 'DISCONNECTED' | 'FAULTY' | 'OK' | 'UNKNOWN';
