import { OrLightingTestScheduleTileController } from './OrLightingTestScheduleTileController';

export class OrLightingTestScheduleTileDirective {
  constructor() {
    return {
      bindToController: true,
      controller: [
        '$scope',
        'FloorService',
        'PropertiesService',
        'SensorNodeService',
        'TagService',
        'LightingTestSchedulingService',
        OrLightingTestScheduleTileController
      ],
      controllerAs: 'scheduleTile',
      replace: true,
      restrict: 'E',
      scope: {
        details: '=',
        groupTags: '=',
        onSubmit: '&'
      },
      template: require('raw-loader!./or-lighting-test-schedule-tile.html').default
    };
  }
}
