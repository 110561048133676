import { OrBuildingsMenuController } from './OrBuildingsMenuController';

export class OrBuildingsMenuDirective {
  constructor() {
    return {
      bindToController: true,
      controller: [
        '$scope',
        'BuildingService',
        'FloorService',
        'BuildingService',
        'ThumbnailUrlGenerator',
        'BuildingUrlGenerator',
        'BuildingAdministrationUrlGenerator',
        'ImageCachingService',
        'UIRefreshService',
        'NavigationService',
        'DropdownService',
        OrBuildingsMenuController
      ],
      controllerAs: 'buildingsMenu',
      replace: true,
      restrict: 'E',
      scope: {
        isActive: '=?'
      },
      template: require('raw-loader!./or-buildings-menu.html').default
    };
  }
}
