import { Component, OnInit } from '@angular/core';
import { setUpLocationSync } from '@angular/router/upgrade';
import { UpgradeModule } from '@angular/upgrade/static';
import { ANGULAR_JS_MODULE_NAME } from '@common/app-constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  constructor(private upgrade: UpgradeModule) {}

  ngOnInit(): void {
    this.upgrade.bootstrap(document.body, [ANGULAR_JS_MODULE_NAME], {
      strictDi: true
    });
    setUpLocationSync(this.upgrade);
  }
}
