<div class="edit-managing-company-dialog">

  <h2 mat-dialog-title>#{{ managingCompany.id }} - {{ managingCompany.companyName }}</h2>

  <mat-dialog-content [formGroup]="form">
    <mat-form-field>
      <mat-label>Company Name</mat-label>
      <input matInput formControlName="companyName" disabled [(ngModel)]="managingCompany.companyName" value="{{managingCompany.companyName}}">
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-raised-button (click)="close()">Close</button>
    <button mat-raised-button color="primary" (click)="save()">Save</button>
  </mat-dialog-actions>

</div>