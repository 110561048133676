import { Component, Input, OnInit } from '@angular/core';
import { UserService } from '@app/shared/services/user/user.service';
import { NavigationService } from '@app/shared/services/navigation/navigation.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TenantUserDatasource } from '@app/administration/tenants/tenant-user/tenant-user.datasource';
import { UserTenantResource } from '@app/shared/resources/user-tenant.resource';
import { TenantService } from '@app/shared/services/building/tenant.service';
import { concatMap, Observable } from 'rxjs';
import { User } from '@app/shared/models/user.interface';
import { ITenant } from '@app/shared/models/tenant.interface';
import { ConfirmComponent, ConfirmDialogData } from '@app/shared/components/dialogs/confirm/confirm.component';
import {
  FORM_TYPE,
  TenantUserDialogComponent
} from '@app/administration/tenants/tenant-user/dialog/tenant-user-dialog.component';
import { UserTenant } from '@app/shared/models/user-tenant';
import { ActivatedRoute } from '@angular/router';
import { ToastService } from '@services/toast/toast.service';

@Component({
  selector: 'app-tenant-user',
  templateUrl: './tenant-user.component.html',
  styleUrls: ['./tenant-user.component.scss']
})
export class TenantUserComponent implements OnInit {
  tenantUserDatasource: TenantUserDatasource;

  @Input()
  reload: Observable<boolean>;

  private buildingId: number;

  private users$: Observable<User[]>;

  private tenants$: Observable<ITenant[]>;

  readonly tenantsPageSize = 10;

  tenantDisplayedColumns: string[] = ['userName', 'tenantName', 'actions'];

  constructor(
    private userService: UserService,
    private navigationService: NavigationService,
    private dialog: MatDialog,
    private userTenantResource: UserTenantResource,
    private tenantService: TenantService,
    private route: ActivatedRoute,
    private toast: ToastService
  ) {}

  ngOnInit(): void {
    this.route.params
      .pipe(
        concatMap((params) => {
          const { buildingId } = params;
          return this.userService.getBuilding(buildingId);
        })
      )
      .subscribe((building) => {
        this.navigationService.initNavigation(window.location.href, building);
        this.buildingId = building.id;
        this.tenants$ = this.tenantService.getAllTenants(this.buildingId);
        this.users$ = this.userService.getUsersInBuilding(this.buildingId);
        this.setup();
      });
  }

  setup(): void {
    this.tenantUserDatasource = new TenantUserDatasource(this.userTenantResource, this.buildingId);
    this.refresh();
    this.reload?.subscribe(() => {
      this.refresh();
    });
  }

  private refresh(): void {
    this.tenantUserDatasource.loadUserTenants(0, this.tenantsPageSize);
  }

  public doAdd(): void {
    const dialogData = { mode: FORM_TYPE.ADD, users$: this.users$, tenants$: this.tenants$ };
    this.openTenantDialog(this.dialog, dialogData).subscribe((result: { event: string; data: any }) => {
      if (result.data) {
        this.userTenantResource.addUserToTenant(result.data.userId, result.data.tenantId).subscribe({
          next: () => {
            this.tenantUserDatasource.loadUserTenants(this.tenantUserDatasource.pageIndex, this.tenantsPageSize);
          },
          error: (e) => {
            if (e.status === 409) {
              this.toast.error({
                message: 'User is already part of a Tenant in this building',
                dataCy: 'tenant-already-in-tenant'
              });
            } else if (e.status === 400) {
              this.toast.error({
                message: "User doesn't exist in this building",
                dataCy: 'tenant-user-not-in-building'
              });
            }
          }
        });
      }
    });
  }

  public doEdit(userTenant: UserTenant): void {
    const dialogData = {
      mode: FORM_TYPE.EDIT,
      users$: this.users$,
      tenants$: this.tenants$,
      userId: userTenant.userId,
      tenantId: userTenant.tenantId
    };
    this.openTenantDialog(this.dialog, dialogData).subscribe((result: { event: string; data: any }) => {
      if (result.data) {
        this.userTenantResource
          .updateUserTenant(result.data.userId, result.data.tenantId, userTenant.tenantId)
          .subscribe({
            next: () => {
              this.tenantUserDatasource.loadUserTenants(this.tenantUserDatasource.pageIndex, this.tenantsPageSize);
            },
            error: (e) => {
              if (e.status === 409) {
                this.toast.error({
                  message: 'User is already part of a Tenant in this building',
                  dataCy: 'tenant-already-in-tenant'
                });
              } else if (e.status === 400) {
                this.toast.error({
                  message: "User doesn't exist in this building",
                  dataCy: 'tenant-user-not-in-building'
                });
              }
            }
          });
      }
    });
  }

  public doDelete(userTenant: UserTenant): void {
    const data = new ConfirmDialogData(
      `Are you sure you want to delete user: "${userTenant.userName}" from tenant "${userTenant.tenantName}"`
    );
    this.openDeleteConfirmationDialog(this.dialog, data).subscribe((confirm: boolean) => {
      if (confirm) {
        this.userTenantResource.removeUserFromTenant(userTenant.userId, userTenant.tenantId).subscribe(() => {
          this.tenantUserDatasource.loadUserTenants(0, this.tenantsPageSize);
        });
      }
    });
  }

  openTenantDialog(dialog: MatDialog, obj): Observable<any> {
    const config = new MatDialogConfig();
    config.autoFocus = true;
    config.data = obj;
    config.width = '600px';
    const dialogRef = dialog.open(TenantUserDialogComponent, config);
    return dialogRef.afterClosed();
  }

  openDeleteConfirmationDialog(dialog: MatDialog, data): Observable<any> {
    const config = new MatDialogConfig();
    config.autoFocus = true;
    config.data = data;
    config.width = '600px';
    config.panelClass = 'no-overflow-dialog';
    const dialogRef = dialog.open(ConfirmComponent, config);
    return dialogRef.afterClosed();
  }
}
