import { ISecurityService } from '../../services/ISecurityService';
import { User } from '../../api/auth/User';
import { Building } from '../../api/building/Building';
import { BuildingAuthorityType } from '@app/shared/models/building-authority-type';
import { GlobalAuthority } from '@app/shared/models/global-authority';
import { UserService } from '../../services/UserService';
import angular from 'angular';
import 'angular-route';

export class SecurityService implements ISecurityService {
  constructor(
    private userService: UserService,
    private routeParams: angular.route.IRouteParamsService,
    private isDebugMode: boolean
  ) {}

  public isAuthorizedForBuilding(target: string, building?: Building): Promise<boolean> {
    return this.wrapPromise(this.userService.getCurrentUser(), (user) =>
      this.hasBuildingAuthority(user, BuildingAuthorityType.fromString(target), building.id)
    );
  }

  public canSeeTenantMadeTags(buildingId: number): Promise<boolean> {
    return this.wrapPromise(this.userService.getCurrentUser(), (user) => {
      if (user.tenantIds != null && user.tenantIds.length > 0) {
        return true;
      } else {
        return this.hasBuildingAuthority(
          user,
          BuildingAuthorityType.fromString(BuildingAuthorityType.MANAGE_TENANT.value),
          buildingId
        );
      }
    });
  }

  public isAuthorizedForBuildingId(target: string, building?: number): Promise<boolean> {
    return this.wrapPromise(this.userService.getCurrentUser(), (user) =>
      this.hasBuildingAuthority(user, BuildingAuthorityType.fromString(target), building)
    );
  }

  private hasBuildingAuthority(user: User, target: BuildingAuthorityType, building: number): boolean {
    const result =
      user.buildingAuthorizations.filter(
        (authorization) =>
          authorization.building.id === building &&
          authorization.authorities.filter((authority) => authority.toString() === target.toString()).length > 0
      ).length > 0;

    if (this.isDebugMode) {
      console.log('BUILDING AUTHORITY', {
        user: user.name,
        target: target.toString(),
        building,
        result
      });
    }

    return result;
  }

  public isAuthorized(target: string): Promise<boolean> {
    if (target == GlobalAuthority.VIEW_HIDDEN_COMPONENTS.toString()) {
      return Promise.resolve(this.routeParams.admin != null);
    }

    return this.wrapPromise(this.userService.getCurrentUser(), (user) =>
      this.hasGlobalAuthority(user, GlobalAuthority.fromString(target))
    );
  }

  private hasGlobalAuthority(user: User, target: GlobalAuthority): boolean {
    const result = user.globalAuthorities.filter((authority) => authority == target).length > 0;

    if (this.isDebugMode) {
      console.log('GLOBAL AUTHORITY', {
        user: user.name,
        target: target.toString(),
        result
      });
    }

    return result;
  }

  private wrapPromise<A, B>(promise: Promise<A>, transform: (A) => B): Promise<B> {
    return new Promise((resolve, reject) => {
      promise.then((a) => resolve(transform(a))).catch(() => reject());
    });
  }
}
