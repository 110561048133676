import { EmDriverResource } from '@angularjs/or/angular/resources/EmDriverResource';
import { EmDriver } from '@angularjs/or/api/building/EmDriver';
import { SensorNode } from '@angularjs/or/api/building/SensorNode';

export class EmDriverService {
  constructor(private emDriverResource: EmDriverResource) {}

  public getByNodeIds(nodeIds: number[]): Promise<EmDriver[]> {
    return this.emDriverResource.getByNodeIds(nodeIds);
  }

  public getByNodes(nodes: SensorNode[]): Promise<EmDriver[]> {
    return this.emDriverResource.getByNodeIds(nodes.map((node) => node.id));
  }

  public getByNodeId(nodeId: number): Promise<EmDriver[]> {
    return this.emDriverResource.getByNodeId(nodeId);
  }

  public getByNode(node: SensorNode): Promise<EmDriver[]> {
    return this.emDriverResource.getByNodeId(node.id);
  }
}
