import * as ng from 'angular';
import { NavigationService } from '@app/shared/services/navigation/navigation.service';
import { NavigationSection } from '@app/shared/services/navigation/navigation-section';
import { NavigationSectionInfo } from '@app/shared/services/navigation/navigation-section-info';

export class IntegrationsConfig {
  private static ROUTE: string;

  private static routeProvider($routeProvider) {
    $routeProvider.when(IntegrationsConfig.ROUTE, {
      redirectTo: IntegrationsConfig.ROUTE + '/areas'
    });
  }

  static run(
    navigationService: NavigationService,
    navigationServiceInfo: NavigationSectionInfo,
    urlGenerator: (buildingId?: number, floorId?: number) => string
  ) {
    navigationService.addSectionToSiteMap(
      new NavigationSection(navigationServiceInfo, urlGenerator)
    );
  }

  static configure(route: string) {
    this.ROUTE = route;
    ng.module('portal')
      .config(['$routeProvider', this.routeProvider])
      .run([
        'NavigationService',
        'IntegrationsNavigationInfo',
        'IntegrationsUrlGenerator',
        this.run
      ]);
  }
}
