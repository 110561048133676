export class Personality {
  constructor(private value: number, public label: Personality_) {
    this.value = value;
  }

  public static OPEN_FLOOR = new Personality(0, 'Open Floor');
  public static OPEN_FLOOR_SAVER = new Personality(1, 'Open Floor Saver');
  public static CORRIDOR = new Personality(2, 'Corridor');
  public static CLOSED_OFFICE = new Personality(3, 'Closed Office');
  public static AMENITIES = new Personality(4, 'Amenities');
  public static STORE_ROOM = new Personality(5, 'Store Room');
  public static BREAKOUT = new Personality(6, 'Breakout');
  public static SECURITY_LIGHT = new Personality(7, 'Security Light');
  public static DEMO = new Personality(8, 'Demo');
  public static ALL_ON_ALWAYS = new Personality(9, 'All on Always');
  public static EXTREME_SAVER = new Personality(10, 'Extreme Saver');
  public static _100_50_0 = new Personality(11, '100/50/0');
  public static DESKLAMP = new Personality(12, 'Desklamp');
  public static MOTION_ONLY = new Personality(13, 'Motion Only');
  public static RIPPLES = new Personality(14, 'Ripples');
  public static WALLWASH = new Personality(15, 'Wallwash');

  public static all(): Personality[] {
    return [
      Personality.OPEN_FLOOR,
      Personality.OPEN_FLOOR_SAVER,
      Personality.CORRIDOR,
      Personality.CLOSED_OFFICE,
      Personality.AMENITIES,
      Personality.STORE_ROOM,
      Personality.BREAKOUT,
      Personality.SECURITY_LIGHT,
      Personality.DEMO,
      Personality.ALL_ON_ALWAYS,
      Personality.EXTREME_SAVER,
      Personality._100_50_0,
      Personality.DESKLAMP,
      Personality.MOTION_ONLY,
      Personality.RIPPLES,
      Personality.WALLWASH
    ];
  }

  public static fromValue(value: number): Personality {
    for (const personality of Personality.all()) {
      if (value === personality.value) return personality;
    }
  }

  public toString(): string {
    return this.label;
  }
}

type Personality_ =
  | 'Open Floor'
  | 'Open Floor Saver'
  | 'Corridor'
  | 'Closed Office'
  | 'Amenities'
  | 'Store Room'
  | 'Breakout'
  | 'Security Light'
  | 'Demo'
  | 'All on Always'
  | 'Extreme Saver'
  | '100/50/0'
  | 'Desklamp'
  | 'Motion Only'
  | 'Ripples'
  | 'Wallwash';
