export class TokenIPAddress {
  public id: number;
  public ipAddress: string;
  public tokenUserId: number;

  constructor(id: number, ipAddress: string, tokenUserId: number) {
    this.id = id;
    this.ipAddress = ipAddress;
    this.tokenUserId = tokenUserId;
  }
}
