import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable
} from '@angular/material/table';
import { AbstractElmtEmailTableDatasource, ElmtEmailTableData } from '@app/shared/models/elmt-email-table-datasource';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { NotificationBlockComponent, StatusClass } from '@components/notification-block/notification-block.component';
import { Building } from '@app/shared/models/building.interface';
import { EmailSchedulesService } from '@app/emergency-lighting/email-schedules/email-schedules.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { format } from 'date-fns';
import { User } from '@app/shared/models/user.interface';
import { IElmtScheduleDto } from '@app/shared/models/elmt-schedule-dto.interface';
import { MatIcon } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { Filter } from '@app/shared/models/filter.interface';
import { MatSortModule, Sort } from '@angular/material/sort';
import { TimeUtils } from '@app/shared/utils/time.utils';

interface Column {
  columnDef: keyof ElmtEmailTableData | 'delete';
  header: string;
  cell?: (element: ElmtEmailTableData) => string;
}

@Component({
  selector: 'app-elmt-email-schedule-table',
  standalone: true,
  imports: [
    CommonModule,
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderCellDef,
    MatHeaderRow,
    MatHeaderRowDef,
    MatIcon,
    MatProgressSpinner,
    MatRow,
    MatRowDef,
    MatSortModule,
    MatTable,
    MatPaginatorModule,
    NotificationBlockComponent
  ],
  templateUrl: './elmt-email-schedule-table.component.html',
  styleUrl: './elmt-email-schedule-table.component.scss'
})
export class ElmtEmailScheduleTableComponent implements OnInit, OnDestroy {
  @Input({ required: true }) building: Building;
  @Input({ required: true }) users: User[];
  @Input({ required: true }) schedules: IElmtScheduleDto[];
  @Output() onRowClicked = new EventEmitter<ElmtEmailTableData>();
  @Output() onDeleteClicked = new EventEmitter<ElmtEmailTableData>();
  protected readonly StatusClass = StatusClass;
  pageSize = 20;
  pageIndex = 0;
  filter: Filter = {
    pageIndex: this.pageIndex,
    pageSize: this.pageSize,
    sortOrder: 'send_date,asc'
  };
  columns: Column[] = [
    {
      columnDef: 'reportName',
      header: 'Report Name',
      cell: (element: ElmtEmailTableData) => element.reportName
    },
    {
      columnDef: 'sendDate',
      header: 'Send Date',
      cell: (element: ElmtEmailTableData) => this.getSendDate(element)
    },
    {
      columnDef: 'userIds',
      header: 'Users',
      cell: (element: ElmtEmailTableData) => this.getUsersCellData(element)
    },
    {
      columnDef: 'scheduleIds',
      header: 'Schedules',
      cell: (element: ElmtEmailTableData) => this.getSchedulesCellData(element)
    },
    {
      columnDef: 'delete',
      header: ''
    }
  ];
  displayedColumns = this.columns.map((c) => c.columnDef);
  private destroy$ = new Subject<void>();
  constructor(
    public dataSource: AbstractElmtEmailTableDatasource,
    private emailSchedulesService: EmailSchedulesService
  ) {}

  ngOnInit(): void {
    this.emailSchedulesService.formSubmitted$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.dataSource.loadEmailData(this.building.id, this.filter);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getSchedulesCellData(element: ElmtEmailTableData): string {
    if (element.scheduleIds.length === 0) {
      return 'All';
    }
    return element.scheduleIds.map((scheduleId) => this.schedules.find((s) => s.id === scheduleId)?.name).join(', ');
  }

  private getUsersCellData(element: ElmtEmailTableData): string {
    return element.userIds.map((userId) => this.users.find((u) => u.id === userId)?.name).join(', ');
  }

  private getSendDate(element: ElmtEmailTableData): string {
    const date = typeof element.sendDate === 'number' ? new Date(element.sendDate * 1000) : element.sendDate;
    const buildingTimezoneDate = TimeUtils.adjustDateToTimezone(date, this.building.timeZone);
    return format(buildingTimezoneDate, 'dd/MM/yyyy');
  }

  handleRowClick(row: ElmtEmailTableData): void {
    this.onRowClicked.emit(row);
  }

  handleDeleteClick(e: MouseEvent, row: ElmtEmailTableData): void {
    e.preventDefault();
    e.stopPropagation();
    this.onDeleteClicked.emit(row);
  }

  onPageEvent($event) {
    this.filter = { ...this.filter, pageIndex: $event.pageIndex };
    this.dataSource.loadEmailData(this.building.id, this.filter);
  }

  onSortChanged(sortState: Sort) {
    if (sortState.direction !== '') {
      this.filter = { ...this.filter, sortOrder: `send_date,${sortState.direction}` };
      this.dataSource.loadEmailData(this.building.id, this.filter);
    }
  }
}
