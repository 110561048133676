import { Resource } from '../Resource';
import { Tag } from '../../api/building/Tag';
import { IResource } from '../../api/IResource';
import { PromiseUtils } from '@angularjs/or/angular/promises/PromiseUtils';

export class TagResource extends Resource<Tag, number> implements IResource<Tag, number> {
  public getTagsForBuilding(id: number): Promise<Tag[]> {
    return PromiseUtils.wrapSingle(this.$http.get(this.baseUrl + '/building/' + id), this.rejectionHandler);
  }

  public getEditableTagsForBuilding(id: number): Promise<Tag[]> {
    return PromiseUtils.wrapSingle(
      this.$http.get(this.baseUrl + '/building/' + id + '/editable'),
      this.rejectionHandler
    );
  }

  public getTagsFromIds(tagIds: number[]): Promise<Tag[]> {
    return PromiseUtils.wrapSingle(this.$http.post(this.baseUrl + '/all-by-ids', tagIds), this.rejectionHandler);
  }
}
