import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
  AbstractControl
} from '@angular/forms';
import { JobTaskTemplateMap } from '@app/shared/models/otap.upgrade.interface';

export type AddJobDialogData = {
  mode: FORM_TYPE;
  jobTaskTemplate: JobTaskTemplateMap;
};

export type DialogResult = {
  formValue: { name: string; jobTaskTemplate: JobTaskTemplateMap };
};

@Component({
  selector: 'app-create-jobs-dialog',
  templateUrl: './create-jobs-dialog.component.html',
  styleUrls: ['./create-jobs-dialog.component.scss']
})
export class CreateJobsDialogComponent implements OnInit {
  addJobForm: FormGroup;
  readonly mode: FORM_TYPE;
  readonly jobTaskTemplate: JobTaskTemplateMap;

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: AddJobDialogData,
    private dialogRef: MatDialogRef<CreateJobsDialogComponent>
  ) {
    this.mode = data.mode;
    this.jobTaskTemplate = data.jobTaskTemplate;

    this.addJobForm = this.fb.group({
      name: [
        '',
        [
          Validators.required,
          this.emptyNameValidator(),
          Validators.minLength(3)
        ]
      ],
      jobTaskTemplateName: ['', Validators.required]
    });
  }

  emptyNameValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      const isEmpty = !value || value.trim().length === 0;
      return isEmpty ? { emptyName: true } : null;
    };
  }

  ngOnInit(): void {}

  close(): void {
    this.dialogRef.close();
  }

  save(): void {
    if (this.addJobForm.invalid) {
      return;
    }
    const formValue = this.addJobForm.value;
    const selectedJobTaskTemplateKey = this.getJobTaskTemplateKeyByValue(
      formValue.jobTaskTemplateName
    );
    formValue.jobTaskTemplate = selectedJobTaskTemplateKey;
    this.dialogRef.close({ formValue });
  }

  get jobTaskTemplateNameArray(): string[] {
    return Object.values(this.jobTaskTemplate);
  }

  private getJobTaskTemplateKeyByValue(value: string): string {
    const foundEntry = Object.entries(this.jobTaskTemplate).find(
      ([, enumValue]) => enumValue === value
    );
    return foundEntry ? foundEntry[0] : '';
  }
}

export enum FORM_TYPE {
  ADD
}
