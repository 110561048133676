import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Environment, environmentToken } from '@environment';
import { Observable } from 'rxjs';
import { EmergencyLightingBuildingDetails } from '@app/shared/models/emergency-lighting-building-details';
import { shareReplay } from 'rxjs/operators';
import { EmergencyLightingTestType } from '@app/shared/models/emergency-lighting-test-type';
import { EmergencyLightingTestState } from '@app/shared/models/emergency-lighting-test-state';

@Injectable({
  providedIn: 'root'
})
export class EmergencyLightingTestService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient, @Inject(environmentToken) private environment: Environment) {
    this.baseUrl = `${environment.apiUrl}${environment.apiPath}/emergency_lighting`;
  }

  public getDetails(buildingId: number): Observable<EmergencyLightingBuildingDetails> {
    // tslint:disable-next-line
    return this.http
      .get<EmergencyLightingBuildingDetails>(`${this.baseUrl}/details/${buildingId}`)
      .pipe(shareReplay(3, 2000));
  }

  // tslint:disable-next-line
  public updateDetails(
    buildingId: number,
    buildingDetails: EmergencyLightingBuildingDetails
  ): Observable<EmergencyLightingBuildingDetails> {
    return this.http.post<EmergencyLightingBuildingDetails>(`${this.baseUrl}/details/${buildingId}`, buildingDetails);
  }

  public downloadExport(filter: {
    buildingId: number;
    scheduleIds: number[];
    floorIds: number[];
    tagIds: number[];
    testTypes: EmergencyLightingTestType[];
    testStates: EmergencyLightingTestState[];
    startDate: Date;
    endDate: Date;
  }): Observable<Blob> {
    return this.http.post(`${this.baseUrl}/export/${filter.buildingId}`, filter, {
      responseType: 'blob'
    });
  }
}
