import { CheckboxState, FloorplanTag, Tag, TagType } from '@angularjs/or/api/building/Tag';
import { TagService } from '@angularjs/or/services/TagService';
import { TagTenantBridgeService as DowngradedTagService } from '@app/shared/services/tag-tenant-bridge.service';
import { ITagContext } from '@angularjs/or/api/query/outline/context/IContext';
import { Observable } from '@angularjs/or/util/Observable';
import { SensorNodeService } from '@angularjs/or/services/SensorNodeService';
import { SensorNode } from '@angularjs/or/api/building/SensorNode';
import { ArrayUtils } from '@angularjs/or/util/ArrayUtils';
import { MultiTag } from '@angularjs/or/angular/resources/MultiTagResource';
import { Entity, UIRefreshService } from '@angularjs/or/services/UIRefreshService';
import { ColorUtils } from '@angularjs/or/util/ColorUtils';
import { IBuildingService } from '@angularjs/or/services/IBuildingService';
import { IObservableModifiable } from '@angularjs/or/util/IObservable';
import { FeatureService } from '@app/shared/services/feature.service';
import { SecurityService } from '@angularjs/or/angular/services/SecurityService';
import { BuildingAuthorityType } from '@app/shared/models/building-authority-type';
import { FloorplanSensorNode } from '@angularjs/or/api/building/FloorplanSensorNode';

export class OrTagsController {
  public hasTagListChanged: boolean;
  public isNodeTaggingModeActive: boolean;
  public isEditable: boolean;
  public all: FloorplanTag[] = [];
  public showEditForm: boolean[];
  private buildingId: number;
  private filteredTags = false;
  private nodes: SensorNode[];
  private timeoutRef;
  private hasManageTenantForBuilding: boolean;

  constructor(
    private tagContext: IObservableModifiable<ITagContext>,
    private tagService: TagService,
    private downgradedTagService: DowngradedTagService,
    private buildingService: IBuildingService,
    private nodeService: SensorNodeService,
    private uiRefreshService: UIRefreshService,
    private $scope: ng.IScope,
    private featureService: FeatureService,
    private securityService: SecurityService
  ) {}

  public $onInit(): void {
    this.refreshTagsList(true);
    this.tagService.shouldReloadTags.subscribe((force) => {
      this.refreshTagsList(force);
      this.forceRefreshTags();
    });

    this.downgradedTagService.shouldReloadTags.subscribe((force) => {
      this.refreshTagsList(force);
      this.forceRefreshTags();
    });

    this.nodeService.refreshNodes.subscribe((nodes) => {
      this.nodes = nodes;
      this.refreshTagsList();
    });

    this.featureService.isAvailable('filteredTags').subscribe((result) => {
      this.filteredTags = result;
    });

    this.$scope.$watch('tags.hasTagListChanged', () => {
      this.refreshTagsList();
    });
    this.tagContext.onChange((value) => {
      this.all.forEach((tag) => (tag.isActive = value.tagIds.indexOf(tag.id) >= 0));
    });

    this.nodeService.selectedNodes.onChange((selectedNodes: SensorNode[]) => {
      this.updateCheckboxesForTags(selectedNodes);
    });

    this.uiRefreshService.onChange(Entity.BUILDING, () => this.refreshTagsList());
    this.uiRefreshService.onChange(Entity.BUILDING, () => this.resetTagContext());
  }

  private getNodeTagList(): Tag[] {
    const tagMap = {};
    this.nodes?.forEach((node) => {
      if (node.tags) {
        node.tags.forEach((nodeTag) => {
          tagMap[nodeTag.id] = nodeTag;
        });
      }
    });
    const tagArr = [];
    for (const p in tagMap) {
      tagArr.push(tagMap[p]);
    }
    return tagArr;
  }

  private updateCheckboxesForTags(selectedNodes: SensorNode[]): void {
    this.all.forEach((tagInAll) => {
      tagInAll.checked = CheckboxState.UNCHECKED;
      let firstNode = true;
      selectedNodes.forEach((node) => {
        if (!node.tags || node.tags.length < 1) {
          if (tagInAll.checked.equals(CheckboxState.CHECKED)) {
            tagInAll.checked = CheckboxState.MIXED;
          }
        } else {
          let tagFoundInNodeTags = false;

          for (const tagForNode of node.tags) {
            if (tagForNode.id === tagInAll.id) {
              tagFoundInNodeTags = true;
            }
            if (firstNode) {
              if (tagForNode.id === tagInAll.id) {
                tagInAll.checked = CheckboxState.CHECKED;
              }
            } else {
              if (tagForNode.id === tagInAll.id) {
                if (!tagInAll.checked.equals(CheckboxState.CHECKED)) {
                  tagInAll.checked = CheckboxState.MIXED;
                }
              }
            }
          }

          if (!tagFoundInNodeTags) {
            if (!tagInAll.checked.equals(CheckboxState.UNCHECKED)) {
              tagInAll.checked = CheckboxState.MIXED;
            }
          }
        }
        firstNode = false;
      });
    });
  }

  private refreshTagsList(force?: boolean): void {
    this.buildingService.getCurrentBuilding().then((building) => {
      this.buildingId = building.id;
      this.securityService
        .isAuthorizedForBuildingId(BuildingAuthorityType.MANAGE_TENANT.value, this.buildingId)
        .then((result) => {
          this.hasManageTenantForBuilding = result;
        });
      this.tagService.getTags(building, force, this.isEditable).then((tags) => {
        if (!this.filteredTags || this.isNodeTaggingModeActive) {
          this.setAllTags(tags);
        } else {
          this.setAllTags(this.getNodeTagList());
        }
        const tagIds = this.tagContext.value().tagIds;
        this.all.forEach((tag) => (tag.isActive = tagIds.indexOf(tag.id) >= 0));
      });
    });
  }

  private containsTag(tags: Tag[], tag: Tag): boolean {
    return ArrayUtils.contains(tags, tag, (tag1: Tag, tag2: Tag) => {
      return tag1.id === tag2.id;
    });
  }

  private indexOf(tags: Tag[], tag: Tag): number {
    return ArrayUtils.indexOf(tags, tag, (tag1: Tag, tag2: Tag) => {
      return tag1.id === tag2.id;
    });
  }

  public toggle(tag: FloorplanTag): void {
    if (
      this.isNodeTaggingModeActive &&
      (tag.tagType !== TagType.TENANT || (tag.tagType === TagType.TENANT && this.hasManageTenantForBuilding))
    ) {
      const modifiedNodeIds: number[] = [];

      const selectedNodes: Observable<SensorNode[]> = this.nodeService.selectedNodes;
      tag.checked = tag.checked.equals(CheckboxState.CHECKED) ? CheckboxState.UNCHECKED : CheckboxState.CHECKED;
      const isAdd = tag.checked.equals(CheckboxState.CHECKED);
      selectedNodes.change((nodes) => {
        for (const node of nodes) {
          if (node instanceof FloorplanSensorNode) {
            if (!node.tags) {
              node.tags = [];
            }
            if (isAdd) {
              if (!this.containsTag(node.tags, tag)) {
                node.tags.push(tag);
                if (
                  !ArrayUtils.contains(modifiedNodeIds, node.id, (id1, id2) => {
                    return id1 === id2;
                  })
                ) {
                  modifiedNodeIds.push(node.id);
                }
              }
            } else {
              if (
                ArrayUtils.removeElement(node.tags, tag, (tag1: Tag, tag2: Tag) => {
                  return tag1.id === tag2.id;
                }).length > 0
              ) {
                if (
                  !ArrayUtils.contains(modifiedNodeIds, node.id, (id1, id2) => {
                    return id1 === id2;
                  })
                ) {
                  modifiedNodeIds.push(node.id);
                }
              }
            }
          }
        }
      });
      if (modifiedNodeIds && modifiedNodeIds.length > 0) {
        const multitag: MultiTag = new MultiTag([tag.id], modifiedNodeIds);
        if (isAdd) {
          this.tagService.tagNodes(multitag).then(() => {
            this.forceRefreshTags();
          });
        } else {
          this.tagService.unTagNodes(multitag).then(() => {
            this.forceRefreshTags();
          });
        }
      }
    } else {
      this.updateTagContext(tag);
    }
  }

  public getCheckboxClass(tag: FloorplanTag): string {
    if (this.isNodeTaggingModeActive) {
      if (tag.checked.equals(CheckboxState.CHECKED)) {
        return 'or-checked';
      } else if (tag.checked.equals(CheckboxState.MIXED)) {
        return 'or-mixed';
      } else {
        return 'or-unchecked';
      }
    } else {
      if (tag.isActive) {
        return 'or-checked';
      } else {
        return 'or-unchecked';
      }
    }
  }

  private updateTagContext(tag: Tag) {
    tag.isActive = !tag.isActive;

    this.tagContext.change((value) => {
      if (tag.isActive) {
        if (value.tagIds.indexOf(tag.id) < 0) {
          const values = value.tagIds;
          values.push(tag.id);
          value.tagIds = values;
        }
      } else {
        const values = value.tagIds;
        values.splice(value.tagIds.indexOf(tag.id), 1);
        value.tagIds = values;
      }
    });
  }

  private resetTagContext() {
    this.tagContext.change((value) => {
      value.tagIds = [];
    });
  }

  public setAllTags(all: Tag[]) {
    this.showEditForm = [];
    this.all = FloorplanTag.fromArray(all);
    this.updateCheckboxesForTags(this.nodeService.selectedNodes.value());
  }

  public deleteTag(tag: FloorplanTag) {
    if (!confirm(`Are you sure you want to remove tag "${tag.name}"?`)) {
      return;
    }

    this.tagService.deleteTag(tag.id).then(() => {
      this.$scope.$apply(() => {
        const index = this.indexOf(this.all, tag);
        ArrayUtils.removeAtIndex(this.all, index);
      });
      this.updateNodesForTag(tag, true);
      this.forceRefreshTags();
    });
  }

  public toggleCollapse(index: number): void {
    if (this.showEditForm[index] && this.all[index].isDirty) {
      this.reset(this.all[index]);
    }
    this.showEditForm[index] = !this.showEditForm[index];
  }

  public cycleColor(tag: FloorplanTag): void {
    tag.color = ColorUtils.generateColor();
    this.updateNodesForTag(tag);
  }

  private updateNodesForTag(tag: FloorplanTag, remove?: boolean, pristine?: boolean): void {
    this.nodeService.nodes.forEach((node) => {
      const index = this.indexOf(node.tags, tag);
      if (index > -1) {
        if (remove) {
          ArrayUtils.removeAtIndex(node.tags, index);
        } else {
          node.tags[index].name = tag.name;
          node.tags[index].color = tag.color;
        }
      }
      this.nodeService.refreshFloorPlanNodes.next([]);
      this.nodeService.refreshFloorPlanNodes.next(this.nodeService.createFloorplanNodes(this.nodeService.nodes));
    });
    if (!remove && !pristine) {
      tag.isDirty = true;
    }
  }

  public reset(tag: FloorplanTag) {
    this.tagService.getTag(tag.id).then((tagOld: Tag) => {
      this.$scope.$apply(() => {
        tag.name = tagOld.name;
        tag.color = tagOld.color;
      });
      this.updateNodesForTag(tag);
      tag.isDirty = false;
    });
  }

  public save(tag: FloorplanTag) {
    tag.buildingId = this.buildingId;
    this.tagService.updateTag(tag.id, tag).then(() => {
      this.updateNodesForTag(tag);
      tag.isDirty = false;
    });
  }

  private forceRefreshTags(): void {
    if (this.timeoutRef) {
      clearTimeout(this.timeoutRef);
    }
    this.timeoutRef = setTimeout(() => {
      this.nodeService.reloadSpecificData([SensorNodeService.tagDataIdx], [this.nodeService.getTagData()]);
    }, 3000);
  }

  getTextColor(fgColor: string): any {
    return { color: '#FFF' };
  }

  public isOfTenantType(tag: FloorplanTag): boolean {
    return tag.tagType === TagType.TENANT;
  }
}
