import {
  ImageZoomService,
  ZoomOperation
} from '@angularjs/or/services/ImageZoomService';
import { Observable } from '@angularjs/or/util/Observable';
import IScope = angular.IScope;

export class OrFloorplanZoomControlController {
  private static KEY = 'ZOOM_CONTROL';

  public suppressFloorplan: boolean;
  public canReset = false;
  public canRecenter = false;
  public canIncrease = true;
  public canDecrease = true;

  constructor(
    private scope: IScope,
    private zoomService: Observable<ImageZoomService>
  ) {}

  public $onInit() {
    this.zoomService.onChange((value) => {
      this.update(value);
    }, OrFloorplanZoomControlController.KEY);
    this.update(this.zoomService.value());
  }

  public toggleSuppressFloorplan(isActive: boolean) {
    this.suppressFloorplan = isActive;
  }

  public update(value: ImageZoomService) {
    this.canIncrease = value.canZoom(ZoomOperation.ZOOM_IN);
    this.canDecrease = value.canZoom(ZoomOperation.ZOOM_OUT);
    this.canReset = value.isScaled();
    this.canRecenter = !value.isCentered();
  }

  public resetAndRecenter() {
    this.zoomService.change((value) => {
      value.resetAndRecenter();
      this.update(value);
    }, OrFloorplanZoomControlController.KEY);
  }

  public increase() {
    this.zoomService.change((value) => {
      value.zoom(ZoomOperation.ZOOM_IN);
      this.update(value);
    }, OrFloorplanZoomControlController.KEY);
  }

  public decrease() {
    this.zoomService.change((value) => {
      value.zoom(ZoomOperation.ZOOM_OUT);
      this.update(value);
    }, OrFloorplanZoomControlController.KEY);
  }
}
