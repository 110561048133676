<footer class="or-footer">
  <div class="or-footer-section"><a class="or-logo" href="/"></a></div>
  <div class="or-footer-section"><p>© Organic Response, 2016-2024</p></div>
  <div class="or-footer-section">
    <ul class="or-inline-items">
      <li class="or-inline-item"><a href="#">About</a></li>
      <li class="or-inline-item">
        <a href='#' (click)="showPrivacyPolicy(false)">Privacy notice</a>
      </li>
      <li class="or-inline-item"><a href="#">Contact</a></li>
    </ul>
  </div>
  <div class="or-footer-section">
    <ul class="or-inline-items">
      <li class="or-inline-item">
        <a
          class="or-button or-iconic"
          href="https://www.linkedin.com/company/or-technologies"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span class="or-icon or-tiny or-icon-linkedin"></span>
        </a>
      </li>
      <li class="or-inline-item">
        <a
          class="or-button or-iconic"
          href="https://twitter.com/OrganicResponse"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span class="or-icon or-tiny or-icon-twitter"></span>
        </a>
      </li>
      <li class="or-inline-item">
        <a class="or-button or-iconic" href="#">
          <span class="or-icon or-tiny or-icon-facebook"></span>
        </a>
      </li>
      <li class="or-inline-item" appIsAuthorized="VIEW_HIDDEN_COMPONENTS">
        GIT commit: {{getCommitHash()}}
      </li>
    </ul>
  </div>
</footer>
