import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ITenant, Tenant } from '@app/shared/models/tenant.interface';
import { UserService } from '@app/shared/services/user/user.service';
import { DialogAction } from '@app/administration/tenants/tenants.component';

export interface TenantDialogData {
  action: DialogAction;
  tenant?: ITenant;
  buildingId: number;
}

@Component({
    selector: 'app-tenant-dialog',
    templateUrl: './tenant-dialog.component.html',
    styleUrls: ['./tenant-dialog.component.scss']
})
export class TenantDialogComponent implements OnInit {

    tenantForm: FormGroup;
    buildingId: number;
    tenant: ITenant;
    action: DialogAction;
    actionButtonEnabled: boolean;
    DA: typeof DialogAction = DialogAction;

    constructor(
        private usersService: UserService,
        private fb: FormBuilder,
        private dialogRef: MatDialogRef<TenantDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: TenantDialogData) {

        this.buildingId = data.buildingId;
        this.tenant = data.tenant;
        this.action = data.action;
        this.tenantForm = this.fb.group({
            tenantName: [this.tenant?.name || '', [Validators.required]],
            userId: ['', []]
        });

    }

    ngOnInit(): void {
        // intentionally left blank
    }

    get title(): string {
        return this.action === this.DA.ADD ? 'Add a new tenant' : `Update details for tenant: "${this.tenant.name}"`;
    }

    cancel(): void {
        this.dialogRef.close({ event: 'Cancel' });
    }

    doAction(): void {
        if (this.action === DialogAction.UPDATE) {
            const updatedValue = Object.assign({},
              {
                  ...this.tenantForm.value,
                  ...this.tenant,
                  buildingId: this.buildingId
              }
            );

            updatedValue.name = this.tenantForm.value.tenantName;
            this.dialogRef.close({ event: this.action, data: updatedValue });
        }
        else if (this.action === DialogAction.ADD) {
            const newTenant = new Tenant(this.tenantForm.value.tenantName, this.buildingId, [], '');
            this.dialogRef.close({ event: this.action, data: newTenant });
        }
    }

    setActionButtonEnabled(): void {
        this.actionButtonEnabled =
            (this.tenantForm.value.tenantName !== null) && (this.tenantForm.value.tenantName !== '')
            && ((this.action === DialogAction.ADD) ||
            (this.action === DialogAction.UPDATE  && this.tenant.name !== this.tenantForm.value.tenantName));
    }

    get isActionButtonEnabled(): boolean {
        return this.actionButtonEnabled;
    }
}
