import { Dates } from '../Dates';
import { IQueryContext } from '@app/analytics/metric-widget/query/context';
import { DataType } from '@app/shared/models/sensor-node-data-type';
import { Resolution } from '@app/analytics/metric-widget/data-objects/resolution';
import { Coordinate } from '@app/shared/utils/coordinate';

export class QueryContext implements IQueryContext {
  constructor(
    public dataType?: DataType,
    public buildingId?: number,
    public floorIds?: number[],
    public zone?: string,
    public dates?: Dates,
    public resolution?: Resolution,
    public tagIds?: number[],
    public sensorNodeIds?: number[],
    public unit?: string,
    public offsetDuration?: string,
    public zoom?: number,
    public coordinate?: Coordinate,
    public liveMode?: boolean
  ) {
    // this.reset();
  }

  public reset(): void {
    this.floorIds = [];
    this.tagIds = [];
    this.dates = new Dates();
    this.resolution = Resolution.YEARLY;
  }
}
