import { TimezoneUtils } from '@app/shared/utils/timezoneUtils';
import { DateType } from '@app/emergency-lighting/elmt-tests-filter/elmt-tests-filter.component';

export class TimeUtils {
  static convertTimezone(date: Date, timezone: string): Date {
    if (date) {
      return new Date(
        date.toLocaleString('en-US', {
          timeZone: timezone
        })
      );
    }
  }

  static convertTZ(dateInSeconds: number, timezone: string): Date {
    if (dateInSeconds) {
      const dateFromSeconds = new Date(dateInSeconds * 1000);
      return new Date(
        dateFromSeconds.toLocaleString('en-US', {
          timeZone: timezone
        })
      );
    }
  }

  static convertTZToMillis(dateInSeconds: number, timezone: string): number {
    if (dateInSeconds) {
      return TimeUtils.convertTZ(dateInSeconds, timezone).getTime();
    }
  }

  static toBrowserTimeZone(dateToConvert: Date, buildingTimezone: string): number {
    return (
      dateToConvert.getTime() / 1000 +
      Math.floor(
        (dateToConvert.getTime() -
          new Date(dateToConvert.toLocaleString('en-US', { timeZone: buildingTimezone })).getTime()) /
          1000
      )
    );
  }

  static getDateInUTC(referenceDate: Date, type: DateType, buildingTimezone: string): Date {
    if (!referenceDate) {
      return null;
    }
    const dateInUTC = TimeUtils.convertTimezone(new Date(), TimezoneUtils.mapUtcToKnownTimezone(buildingTimezone));
    if (referenceDate) {
      /* UTC date is set in the order of Year, Month Date - to ensure when start/end date is 30/31st of a month,
      the date value will be retained correctly.

      e.g. When the current month is Feb (with 28 days in the month typically)
      When setting start/end date as 30th or 31st of March - it would fail if the order in which UTC date is set is Date, Month, Year instead of Year, Month, Date
      * */
      dateInUTC.setFullYear(referenceDate.getFullYear());
      dateInUTC.setMonth(referenceDate.getMonth());
      dateInUTC.setDate(referenceDate.getDate());
    }
    dateInUTC.setHours(type === DateType.END_DATE ? 23 : 0);
    dateInUTC.setMinutes(type === DateType.END_DATE ? 59 : 0);
    dateInUTC.setSeconds(type === DateType.END_DATE ? 59 : 0);
    return dateInUTC;
  }

  static adjustDateToTimezone(date: Date | number | string, timezone: string): Date {
    return TimeUtils.convertTimezone(new Date(date), TimezoneUtils.mapUtcToKnownTimezone(timezone));
  }

  static getUTCOffset(timeZone: string): number {
    const today = new Date();
    const localDay = TimeUtils.adjustDateToTimezone(today, timeZone);
    const utcDay = new Date(today.toLocaleString('en-US', { timeZone: 'UTC' }));
    return utcDay.getTime() - localDay.getTime();
  }
}
