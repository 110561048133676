import * as ng from 'angular';
import { BeaconsController } from './BeaconsController';
import { NavigationService } from '@app/shared/services/navigation/navigation.service';
import { NavigationSection } from '@app/shared/services/navigation/navigation-section';
import { NavigationSectionInfo } from '@app/shared/services/navigation/navigation-section-info';
import IRouteParamsService = ng.route.IRouteParamsService;

export class BeaconsConfig {
  private static ROUTE: string;

  private static routeProvider($routeProvider: ng.route.IRouteProvider): void {
    $routeProvider.when(BeaconsConfig.ROUTE, {
      template: require('raw-loader!./beacons.html').default,
      controller: [
        '$routeParams',
        '$scope',
        'BuildingService',
        'FloorService',
        'SensorNodeService',
        'TenantService',
        'NavigationService',
        'QueryContext',
        'QueryOutlineBuilder',
        'SensorNodesNavigationInfo',
        'UIRefreshService',
        '$timeout',
        'BeaconSettingService',
        'FeatureService',
        'TagService',
        'QueryContext',
        'SecurityService',
        BeaconsController
      ],
      controllerAs: 'nodes'
    });
  }

  static run(
    navigationService: NavigationService,
    navigationServiceInfo: NavigationSectionInfo,
    urlGenerator: (buildingId?: number, floorId?: number) => string
  ): void {
    navigationService.addSectionToSiteMap(new NavigationSection(navigationServiceInfo, urlGenerator));
  }

  static configure(route: string): void {
    this.ROUTE = route;
    ng.module('portal')
      .config(['$routeProvider', this.routeProvider])
      .run(['NavigationService', 'BeaconsNavigationInfo', 'BeaconsUrlGenerator', this.run]);
  }
}

export interface INodesParamsService extends IRouteParamsService {
  buildingId: string;
  floorId: string;
}
