import { Resource } from '@app/shared/resources/resource';
import { Tag } from '@app/shared/models/tag.interface';
import { IResource } from '@app/shared/resources/resource.interface';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment, environmentToken } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class TagResource extends Resource<Tag, number> implements IResource<Tag, number> {
  constructor(protected httpClient: HttpClient, @Inject(environmentToken) environment: Environment) {
    super(httpClient, `${environment.apiUrl + environment.apiPath}/tag`);
  }

  public getTagsForBuilding(id: number, skipTagsWithNoEMDrivers?: boolean): Observable<Tag[]> {
    let params = new HttpParams();
    if (skipTagsWithNoEMDrivers) {
      params = params.set('skipTagsWithNoEMDrivers', skipTagsWithNoEMDrivers);
    }
    return this.httpClient.get<Tag[]>(`${this.baseUrl}/building/${id}`, { params });
  }

  public getEditableTagsForBuilding(id: number, skipTagsWithNoEMDrivers?: boolean): Observable<Tag[]> {
    let params = new HttpParams();
    if (skipTagsWithNoEMDrivers) {
      params = params.set('skipTagsWithNoEMDrivers', skipTagsWithNoEMDrivers);
    }
    return this.httpClient.get<Tag[]>(this.baseUrl + '/building/' + id + '/editable', { params });
  }

  public getTagsFromIds(tagIds: number[]): Observable<Tag[]> {
    return this.httpClient.post<Tag[]>(this.baseUrl + '/all-by-ids', tagIds);
  }
}
