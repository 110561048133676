export interface Point {
  x: number;
  y: number;
}

export interface BoundingRect {
  bottom: number;
  height: number;
  left: number;
  right: number;
  top: number;
  width: number;
}

export class Coordinate implements Point {
  constructor(public x: number, public y: number) {}

  plus(other: Coordinate): Coordinate {
    return new Coordinate(this.x + other.x, this.y + other.y);
  }

  minus(other: Coordinate): Coordinate {
    return new Coordinate(this.x - other.x, this.y - other.y);
  }

  distance(other: Coordinate): number {
    return Math.hypot(this.x - other.x, this.y - other.y);
  }
  within(boundary: BoundingRect): boolean {
    return boundary.top < this.y && this.y < boundary.bottom && boundary.left < this.x && this.x < boundary.right;
  }
}
