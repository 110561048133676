export class Gateway {
  public original: Gateway;

  constructor(
    public name: string,
    public serialNumber: string,
    public address: string,
    public buildingId: number,
    public id?: number,
    public versionNumber: number = GatewayVersion.VERSIONTHREE.value,
    public isDefaultNetwork: boolean = true,
    public isActive: boolean = false,
    public staticIpAddress?: string,
    public gatewayIpAddress?: string,
    public isDefaultDns?: boolean,
    public dnsServers?: string,
    public subnetMask: string = '255.255.255.0',
    public networkType: string = NetworkType.WIREPAS.value,
    public firmwareVersion: string = FirmwareVersion.FOURPOINTSIXFOUR.value,
    public gatewayType: string = GatewayType.GATEWAYONEPOINTTWO.value,
    public iotLogLevel: string = LogLevel.INFO.value,
    public isAwsIotEnabled: boolean = true,
    public otapLogLevel?: string,
    public isOtapEnabled?: boolean,
    public isWntEnabled?: boolean,
    public bacnetLogLevel?: string,
    public bacnetDeviceId?: number,
    public bacnetPort?: number,
    public isBacnetEnabled?: boolean,
    public hasKeys?: boolean,
    public lastRestart?: string,
    public lastUpdated?: string,
  ) {}

  public static clone(other: Gateway): Gateway {
    return new Gateway(
      other.name,
      other.serialNumber,
      other.address,
      other.buildingId,
      other.id,
      other.versionNumber,
      NetworkType.WIREPAS.equalsString(other.networkType) ? other.isDefaultNetwork : null,
      other.isActive,
      NetworkType.SILVAIR.equalsString(other.networkType) || other.isDefaultNetwork ? null : other.staticIpAddress,
      NetworkType.SILVAIR.equalsString(other.networkType) || other.isDefaultNetwork ? null : other.gatewayIpAddress,
      other.isDefaultDns,
      NetworkType.SILVAIR.equalsString(other.networkType) || other.isDefaultNetwork || !other.dnsServers ? null : other.dnsServers.replace(/ /g, ''),
      NetworkType.SILVAIR.equalsString(other.networkType) || other.isDefaultNetwork ? null : other.subnetMask,
      other.networkType,
      NetworkType.SILVAIR.equalsString(other.networkType) ? null : other.firmwareVersion,
      NetworkType.SILVAIR.equalsString(other.networkType) ? null : other.gatewayType,
      NetworkType.SILVAIR.equalsString(other.networkType) || GatewayType.VESTA.equalsString(other.networkType)
        ? null
        : other.iotLogLevel,
      NetworkType.SILVAIR.equalsString(other.networkType) || GatewayType.VESTA.equalsString(other.networkType)
        ? null
        : other.isAwsIotEnabled,
      NetworkType.SILVAIR.equalsString(other.networkType) || GatewayType.VESTA.equalsString(other.networkType)
        ? null
        : other.otapLogLevel,
      NetworkType.SILVAIR.equalsString(other.networkType) || GatewayType.VESTA.equalsString(other.networkType)
        ? null
        : other.isOtapEnabled,
      NetworkType.SILVAIR.equalsString(other.networkType) || GatewayType.VESTA.equalsString(other.networkType)
        ? null
        : other.isWntEnabled,
      NetworkType.SILVAIR.equalsString(other.networkType) || GatewayType.VESTA.equalsString(other.networkType)
        ? null
        : other.bacnetLogLevel,
      NetworkType.SILVAIR.equalsString(other.networkType) || GatewayType.VESTA.equalsString(other.networkType)
        ? null
        : other.bacnetDeviceId,
      NetworkType.SILVAIR.equalsString(other.networkType) || GatewayType.VESTA.equalsString(other.networkType)
        ? null
        : other.bacnetPort,
      NetworkType.SILVAIR.equalsString(other.networkType) || GatewayType.VESTA.equalsString(other.networkType)
        ? null
        : other.isBacnetEnabled,
      other.hasKeys,
      this.humanizeTimestamp(other.lastRestart),
      this.humanizeTimestamp(other.lastUpdated)
    );
  }

  public static humanizeTimestamp(unixtime): any {
    if (!unixtime) {
      return 'N/A';
    }
    return new Date(unixtime * 1000);
  }
}

export class NetworkType {
  public static SILVAIR = new NetworkType('SILVAIR', 'Silvair');
  public static WIREPAS = new NetworkType('WIREPAS', 'Wirepas');

  public static all(): NetworkType[] {
    return [NetworkType.SILVAIR, NetworkType.WIREPAS];
  }

  public static fromValue(value: string): NetworkType {
    return value === NetworkType.SILVAIR.value ? NetworkType.SILVAIR : NetworkType.WIREPAS;
  }
  public constructor(public value: _NetworkType, public name: string) {}

  public equals(other: NetworkType): boolean {
    return this.equalsString(other.value);
  }

  public equalsString(other: string): boolean {
    return other !== null && other === this.value;
  }

  public toString(): string {
    return this.value;
  }
}

export class FirmwareVersion {
  public static THREEPOINTFOUR = new FirmwareVersion('3.4', '3.4');
  public static FOURPOINTZEROFIVE = new FirmwareVersion('4.0.50', '4.0.50');
  public static FOURPOINTSIXFOUR = new FirmwareVersion('4.0.64', '4.0.64');
  public static FIVEPOINTNINESEVEN = new FirmwareVersion('5.1.0.97', '5.1.0.97');

  public static all(): FirmwareVersion[] {
    return [FirmwareVersion.THREEPOINTFOUR, FirmwareVersion.FOURPOINTZEROFIVE, FirmwareVersion.FOURPOINTSIXFOUR, FirmwareVersion.FIVEPOINTNINESEVEN];
  }

  public static fromValue(value: string): FirmwareVersion {
    switch (value) {
      case FirmwareVersion.THREEPOINTFOUR.value:
        return FirmwareVersion.THREEPOINTFOUR;
      case FirmwareVersion.FOURPOINTZEROFIVE.value:
        return FirmwareVersion.FOURPOINTZEROFIVE;
      case FirmwareVersion.FOURPOINTSIXFOUR.value:
        return FirmwareVersion.FOURPOINTSIXFOUR;
     case FirmwareVersion.FIVEPOINTNINESEVEN.value:
        return FirmwareVersion.FIVEPOINTNINESEVEN;
    }
  }
  public constructor(public value: _FirmwareVersion, public name: string) {}

  public equals(other: FirmwareVersion): boolean {
    return this.equalsString(other.value);
  }

  public equalsString(other: string): boolean {
    return other !== null && other === this.value;
  }

  public toString(): string {
    return this.value;
  }
}

export class GatewayType {
  public static VESTA = new GatewayType('VESTA', 'Vesta');
  public static GATEWAYONEPOINTONE = new GatewayType('LINUX_GATEWAY_1_1', 'Linux Gateway 1.1');
  public static GATEWAYONEPOINTTWO = new GatewayType('LINUX_GATEWAY_1_2', 'Linux Gateway 1.2');

  public static all(): GatewayType[] {
    return [GatewayType.VESTA, GatewayType.GATEWAYONEPOINTONE, GatewayType.GATEWAYONEPOINTTWO];
  }

  public static fromValue(value: string): GatewayType {
    switch (value) {
      case GatewayType.VESTA.value:
        return GatewayType.VESTA;
      case GatewayType.GATEWAYONEPOINTONE.value:
        return GatewayType.GATEWAYONEPOINTONE;
      case GatewayType.GATEWAYONEPOINTTWO.value:
        return GatewayType.GATEWAYONEPOINTTWO;
    }
  }
  public constructor(public value: _GatewayType, public name: string) {}

  public equals(other: GatewayType): boolean {
    return this.equalsString(other.value);
  }

  public equalsString(other: string): boolean {
    return other !== null && other === this.value;
  }

  public toString(): string {
    return this.value;
  }
}

export class GatewayVersion {
  public static VERSIONTWO = new GatewayVersion(2, '2');
  public static VERSIONTHREE = new GatewayVersion(3, '3');

  public static all(): GatewayVersion[] {
    return [GatewayVersion.VERSIONTWO, GatewayVersion.VERSIONTHREE];
  }

  public static fromValue(value: number): GatewayVersion {
    switch (value) {
      case GatewayVersion.VERSIONTWO.value:
        return GatewayVersion.VERSIONTWO;
      case GatewayVersion.VERSIONTHREE.value:
        return GatewayVersion.VERSIONTHREE;

    }
  }
  public constructor(public value: _GatewayVersion, public name: string) {}

  public equals(other: GatewayVersion): boolean {
    return this.equalsString(other.name);
  }

  public equalsString(other: string): boolean {
    return other !== null && other === this.name;
  }

  public toString(): string {
    return this.name;
  }
}

export class LogLevel {
  public static ERROR = new LogLevel('ERROR', 'Error');
  public static DEBUG = new LogLevel('DEBUG', 'Debug');
  public static INFO = new LogLevel('INFO', 'Info');

  public static all(): LogLevel[] {
    return [LogLevel.ERROR, LogLevel.DEBUG, LogLevel.INFO];
  }

  public static fromValue(value: string): LogLevel {
    switch (value) {
      case LogLevel.ERROR.value:
        return LogLevel.ERROR;
      case LogLevel.DEBUG.value:
        return LogLevel.DEBUG;
      case LogLevel.INFO.value:
        return LogLevel.INFO;
    }
  }
  public constructor(public value: _LogLevel, public name: string) {}

  public equals(other: LogLevel): boolean {
    return this.equalsString(other.value);
  }

  public equalsString(other: string): boolean {
    return other !== null && other === this.value;
  }

  public toString(): string {
    return this.value;
  }
}

type _NetworkType = 'SILVAIR' | 'WIREPAS';
type _FirmwareVersion = '3.4' | '4.0.50' | '4.0.64' | '5.1.0.97';
type _GatewayVersion = 2 | 3;
type _GatewayType = 'VESTA' | 'LINUX_GATEWAY_1_1' | 'LINUX_GATEWAY_1_2';
type _LogLevel = 'ERROR' | 'DEBUG' | 'INFO';
