<ul class="or-inline-items or-inline-tags or-editable">
  <li class="or-inline-item or-inline-tag-add">
    <select class="or-select" #selectTag (change)="addActiveTag(selectTag.value)" data-cy="pick-a-tag-list" *ngIf="isEditable" [disabled]="isSelectionDisabled">
      <option value="">Pick a tag</option>
      <option *ngFor="let tag of availableOptions" [value]="tag.value | json">{{tag.label}}</option>
      <option *ngIf="availableOptions.length === 0" disabled>No tags available</option>
    </select>
  </li>
  <li class="or-inline-item or-inline-tag" *ngFor="let tag of currentSelection; index as i;">
    <span class="or-inline-tag-color" [style]="{ backgroundColor: '#' + tag.color }"></span>
    <span class="or-icon or-tiny or-icon-tenant"  title='Tag is part of a Tenant' *ngIf="isOfTenantType(tag)"></span>
    <span class="or-inline-tag-name" [textContent]="tag.name" [style]="{ color: '#' + tag.color }" [attr.data-cy]="'selected-schedule-tag-'+i"></span>
    <button class="or-button or-inline-tag-remove" type='button' (click)="removeTag(i)" *ngIf="canEdit(tag) | async" [attr.data-cy]="'delete-schedule-tag-'+i">
      <span class="or-icon or-tiny or-icon-cross"></span>
    </button>
  </li>
</ul>
