import { OrFloorplanActionsController } from './OrFloorplanActionsController';

export class OrFloorplanActionsDirective {
  constructor() {
    return {
      bindToController: true,
      controller: [
        'NavigationService',
        'SensorNodeService',
        'NewSensorNodeService',
        'PassiveNodeService',
        'BuildingService',
        'NewMappingService',
        '$scope',
        OrFloorplanActionsController
      ],
      controllerAs: 'actions',
      replace: true,
      restrict: 'E',
      scope: {
        suppressFloorplan: '=?',
        isHeatmapEnabled: '=?',
        areNodesEnabled: '=',
        areDriversEnabled: '=',
        areFaultyNodesEnabled: '=',
        areEmergencyLightsEnabled: '=',
        areMappedNodesEnabled: '=',
        areUnmappedNodesEnabled: '=',
        areSubscriberConnectionsEnabled: '=',
        isLiveModeEnabled: '=',
        isScaleNormalized: '=',
        isAddModeEnabled: '=',
        isMoveModeEnabled: '=',
        isMoveAllModeEnabled: '=',
        isMappingModeEnabled: '=',
        isPassiveNodeMappingModeEnabled: '=',
        isCumulativeSelectionEnabled: '=',
        isAreaSelectionEnabled: '=',
        isManualFunctionalTestModeEnabled: '=',
        isManualDurationTestModeEnabled: '=',
        isCancelTestModeEnabled: '=',
        onMappingModeEnter: '&',
        onMappingModeLeave: '&',
        onPassiveNodeMappingModeEnter: '&',
        onPassiveNodeMappingModeLeave: '&',
        onReloadNodes: '&',
        saveFloorplan: '&',
        discardFloorplan: '&',
        undoFloorplan: '&',
        saveChangedSensorNodes: '&',
        undoChangedSensorNodes: '&',
        discardChangedSensorNodes: '&',
        clearSelection: '&',
        selectAllNodes: '&',
        deleteNodes: '&delete',
        disableSelectionTray: '=?',
        disableAddTray: '=?',
        disableMoveTray: '=?',
        disableMapping: '=?',
        onGatewayModeEnter: '&?',
        onGatewayModeLeave: '&?',
        buildingId: '=',
        runTestsInBatch: '&',
        cancelTestsManually: '&'
      },
      template: require('raw-loader!./or-floorplan-actions.html').default
    };
  }
}
