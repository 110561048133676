import { OrFloorplanZoomControlController } from './OrFloorplanZoomControlController';

export class OrFloorplanZoomControlDirective {
  constructor() {
    return {
      bindToController: true,
      controller: ['$scope', 'FloorplanZoomService', OrFloorplanZoomControlController],
      controllerAs: 'zoom',
      replace: true,
      restrict: 'E',
      scope: {
        suppressFloorplan: '=?'
      },
      template: require('raw-loader!./or-floorplan-zoom-control.html').default
    };
  }
}
