export interface IVirtualNotificationDTO {
  nodeId: number;
  virtualNodeId: number;
  buildingId: number;
}

interface Notification {
  nodeId: number;
  mappingId: number;
  buildingId: number;
}

export class VirtualNotificationDTO implements IVirtualNotificationDTO {
  nodeId: number;
  virtualNodeId: number;
  buildingId: number;

  constructor(nodeId: number, virtualNodeId: number, buildingId: number) {
    this.nodeId = nodeId;
    this.virtualNodeId = virtualNodeId;
    this.buildingId = buildingId;
  }
}

// For mapping purposes
export class VirtualNotification implements Notification {
  constructor(public nodeId: number, public mappingId: number, public buildingId: number) {}
}
