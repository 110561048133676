<ul class="or-inline-items or-inline-floors or-editable">
  <li class="or-inline-item or-inline-floor-add">
    <select class="or-select" #selectFloor (change)="addActiveFloor(selectFloor.value)" data-cy="pick-a-floor-list" *ngIf="isEditable" [disabled]="isSelectionDisabled">
      <option value="">Pick a floor</option>
      <option *ngFor="let floor of availableOptions" [value]="floor.value | json">{{produceFloorLabel(floor.value)}}</option>
      <option *ngIf="availableOptions.length === 0" disabled>No floors available</option>
    </select>
  </li>
  <li class="or-inline-item or-inline-floor" *ngFor="let floor of currentSelection; index as i">
    <span class="or-inline-floor-number" [textContent]="floor.floorNumber" [attr.data-cy]="'selected-schedule-floor-number-'+i"></span
    ><span class="or-inline-floor-name" [textContent]="produceFloorLabel(floor)" [attr.data-cy]="'selected-schedule-floor-name-'+i"></span>
    <button class="or-button or-inline-floor-remove" (click)="removeFloor(i)" *ngIf="isEditable" [attr.data-cy]="'delete-schedule-floor-'+i">
      <span class="or-icon or-tiny or-icon-cross"></span>
    </button>
  </li>
</ul>
