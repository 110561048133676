export enum FilterType {
  SCHEDULE = 'schedule',
  FLOOR = 'floor',
  TAG = 'tag',
  TEST_TYPE = 'testType',
  TEST_STATUS = 'testStatus',
  DATE = 'date'
}

export enum DateType {
  START_DATE,
  END_DATE
}

export const FilterConfig = [
  {
    type: FilterType.SCHEDULE,
    label: 'Filter by schedule(s)',
    formKey: 'schedule',
    color: 'blue',
    dataCy: 'schedule'
  },
  { type: FilterType.FLOOR, label: 'Filter by floor(s)', formKey: 'floor', color: 'green', dataCy: 'floor' },
  { type: FilterType.TAG, label: 'Filter by tag(s)', formKey: 'tag', color: 'red', dataCy: 'tag' },
  {
    type: FilterType.TEST_TYPE,
    label: 'Filter by test type(s)',
    formKey: 'testType',
    color: 'indigo',
    dataCy: 'test-type'
  },
  {
    type: FilterType.TEST_STATUS,
    label: 'Filter by test status',
    formKey: 'testStatus',
    color: 'teal',
    dataCy: 'test-status'
  },
  { type: FilterType.DATE, label: 'Filter by date', formKey: 'date', color: 'grey', dataCy: 'date' }
];
