import moment from 'moment';

import { Resolution } from '../data/Resolution';
import { TimeScale } from './TimeScale';
import { TimeNavigator } from './TimeNavigator';
import { BuildingAuthorityType } from '@app/shared/models/building-authority-type';
import { IBuildingService } from '../services/IBuildingService';
import { SecurityService } from '../angular/services/SecurityService';

export class TimeNavigatorFactory {
  private static timeNavigator: TimeNavigator;

  public static create(
    securityService: SecurityService,
    buildingService: IBuildingService
  ) {
    this.timeNavigator = new TimeNavigator([
      new TimeScale(
        Resolution.YEARLY,
        (value) => moment(value.toString(), 'M').format('MMM'),
        'years',
        'YYYY',
        'YYYY',
        (moment, value) => moment.year(value),
        (moment) => moment.year(),
        (dates) => dates.years,
        (dates, values) => (dates.years = values),
        true
      ),
      new TimeScale(
        Resolution.MONTHLY,
        (value) => moment('1-' + value.toString(), 'M-D').format('Do'),
        'months',
        'MMM',
        'MMMM YYYY',
        (moment, value) => moment.month(value - 1),
        (moment) => moment.month() + 1,
        (dates) => dates.months,
        (dates, values) => (dates.months = values),
        true
      ),
      new TimeScale(
        Resolution.DAILY,
        (value) => moment(value.toString(), 'H').format('hA'),
        'days',
        'Do',
        'Do MMM YYYY',
        (moment, value) => moment.date(value),
        (moment) => moment.date(),
        (dates) => dates.days,
        (dates, values) => (dates.days = values),
        true
      ),
      new TimeScale(
        Resolution.HOURLY,
        (value) => moment(value.toString(), 'mm').format('mm'),
        'hours',
        'hA',
        'Do MMM hA',
        (moment, value) => moment.hour(value),
        (moment) => moment.hour(),
        (dates) => dates.hours,
        (dates, values) => (dates.hours = values)
      )
    ]);
    if (securityService != null && buildingService != null) {
      buildingService.getCurrentBuilding().then((building) => {
        securityService
          .isAuthorizedForBuilding(
            BuildingAuthorityType.HIGH_RESOLUTION_ANALYTICS.toString(),
            building
          )
          .then((isAuthorised) => {
            if (isAuthorised) {
              this.timeNavigator.addScale(
                new TimeScale(
                  Resolution.FIVE_MINUTES,
                  (value) => '',
                  'minutes',
                  'hA:mm',
                  'Do MMM hA:mm',
                  (moment, value) => moment.minute(value),
                  (moment) => moment.minute(),
                  (dates) => dates.minutes,
                  (dates, values) => (dates.minutes = values)
                )
              );
            }
          });
      });
    }
    return this.timeNavigator;
  }
}
