import { Coordinate, Point } from '../../../util/Coordinate';

export interface SearchableFloorplan<T extends Point> {
  /**
   * Returns all sensor nodes that are within the given distance of the given node.
   */
  search(point: Point, distance: number): [number, T][];
}

export class SimpleSearchableFloorplan<T extends Point>
  implements SearchableFloorplan<T>
{
  constructor(private floorplanElements: T[]) {}

  search(point: Point, distance: number): [number, T][] {
    const pointCoords = new Coordinate(point.x, point.y);
    const nodesWithDistance: [number, T][] = this.floorplanElements.map(
      (element) =>
        [
          new Coordinate(element.x, element.y).distance(pointCoords),
          element
        ] as [number, T]
    );
    return nodesWithDistance.filter((x) => x[0] < distance);
  }
}

/**
 * Split the floorplan into a tree that divides it in half either by x or y coordinate (depending on which has the
 * bigger range). When searching for close points, we only recurse on trees that my contain close nodes, which should
 * give O(log n), rather than O(n) lookup performance assuming that not many points are close.
 */
export class TreeSearchableFloorplan<T extends Point>
  implements SearchableFloorplan<T>
{
  constructor(floorplanElements: T[]) {
    this.bounds = this.computeBounds(floorplanElements);
    if (floorplanElements.length <= 1) {
      this.tree = new SimpleSearchableFloorplan<T>(floorplanElements);
    } else {
      this.tree = this.constructBranch(floorplanElements);
    }
  }
  private tree: SearchableFloorplan<T>;
  private bounds: number[];

  private static closeToRange(
    value: number,
    min: number,
    max: number,
    distance: number
  ) {
    if (min < value && max >= value) {
      return true;
    }

    for (const bound of [min, max]) {
      if (Math.abs(bound - value) <= distance) {
        return true;
      }
    }

    return false;
  }

  private constructBranch(floorplanElements: T[]) {
    const xRange = this.bounds[2] - this.bounds[0];
    const yRange = this.bounds[3] - this.bounds[1];

    const sortedElements = floorplanElements.slice();

    if (xRange >= yRange) {
      sortedElements.sort((a, b) => a.x - b.x);
    } else {
      sortedElements.sort((a, b) => a.y - b.y);
    }

    const pivotIndex = sortedElements.length / 2;
    const leftTree = new TreeSearchableFloorplan<T>(
      sortedElements.slice(0, pivotIndex)
    );
    const rightTree = new TreeSearchableFloorplan<T>(
      sortedElements.slice(pivotIndex)
    );

    return {
      search(point: Point, distance: number): [number, T][] {
        let result = [];
        for (const tree of [leftTree, rightTree]) {
          if (
            TreeSearchableFloorplan.closeToRange(
              point.x,
              tree.bounds[0],
              tree.bounds[2],
              distance
            ) &&
            TreeSearchableFloorplan.closeToRange(
              point.y,
              tree.bounds[1],
              tree.bounds[3],
              distance
            )
          ) {
            result = result.concat(tree.search(point, distance));
          }
        }
        return result;
      }
    } as SearchableFloorplan<T>;
  }

  private computeBounds(floorplanElements: T[]) {
    let minX = Infinity;
    let maxX = -Infinity;
    let minY = Infinity;
    let maxY = -Infinity;
    floorplanElements.forEach((element) => {
      minX = Math.min(minX, element.x);
      maxX = Math.max(maxX, element.x);
      minY = Math.min(minY, element.y);
      maxY = Math.max(maxY, element.y);
    });

    return [minX, minY, maxX, maxY];
  }

  search(point: Point, distance: number): [number, T][] {
    return this.tree.search(point, distance);
  }
}
