import { EmergencyLightingTestState } from '@angularjs/or/data/EmergencyLightingTestState';
import { SensorNodeAlert } from '@angularjs/angular/modules/layout/or-sensor-node/SensorNodeAlert';
import { StringUtils } from '@angularjs/or/util/StringUtils';
import { ISelectable } from '@angularjs/or/api/building/ISelectable';
import { Driver } from '@angularjs/or/api/building/Driver';

export class EmDriver extends Driver implements ISelectable {
  constructor(
    public id?: number,
    public x?: number,
    public y?: number,
    public address?: number,
    public nodeId?: number,
    public durationTestResult?: number,
    public emergencyFailureState?: number,
    public emergencyMode?: number,
    public emergencyStatus?: number,
    public ratedDuration?: number,
    public conflictingAddress?: boolean,
    public updatedAt?: Date,
    public emergencyLightingFailureStates?: string[],
    public connected?: boolean,
    public functionalTestState?: EmergencyLightingTestState,
    public durationTestState?: EmergencyLightingTestState
  ) {
    super(address, connected);
  }

  public static from(other: EmDriver): EmDriver {
    return new EmDriver(
      other.id,
      other.x,
      other.y,
      other.address,
      other.nodeId,
      other.durationTestResult,
      other.emergencyFailureState,
      other.emergencyMode,
      other.emergencyStatus,
      other.ratedDuration,
      other.conflictingAddress,
      other.updatedAt,
      other.emergencyLightingFailureStates,
      other.connected,
      other.functionalTestState,
      other.durationTestState
    );
  }

  public get address16(): string {
    return this.address != null
      ? this.address.toString(16).toUpperCase()
      : 'Unmapped';
  }

  public get failedTest(): boolean {
    return (
      (this.functionalTestState != null &&
        EmergencyLightingTestState.FAILED.equals(this.functionalTestState)) ||
      (this.durationTestState != null &&
        EmergencyLightingTestState.FAILED.equals(this.durationTestState)) ||
      (this.functionalTestState != null &&
        EmergencyLightingTestState.TIMED_OUT.equals(
          this.functionalTestState
        )) ||
      (this.durationTestState != null &&
        EmergencyLightingTestState.TIMED_OUT.equals(this.durationTestState))
    );
  }

  public get passedTest(): boolean {
    return (
      !this.failedTest &&
      ((this.functionalTestState != null &&
        EmergencyLightingTestState.SUCCEEDED.equals(
          this.functionalTestState
        )) ||
        (this.durationTestState != null &&
          EmergencyLightingTestState.SUCCEEDED.equals(this.durationTestState)))
    );
  }

  public get activeTest(): boolean {
    return (
      EmergencyLightingTestState.INITIATED.equals(this.functionalTestState) ||
      EmergencyLightingTestState.IN_PROGRESS.equals(this.functionalTestState) ||
      EmergencyLightingTestState.INITIATED.equals(this.durationTestState) ||
      EmergencyLightingTestState.IN_PROGRESS.equals(this.durationTestState)
    );
  }

  public get inProgressTest(): boolean {
    return (
      EmergencyLightingTestState.IN_PROGRESS.equals(this.functionalTestState) ||
      EmergencyLightingTestState.IN_PROGRESS.equals(this.durationTestState)
    );
  }

  public get inInitiatedTest(): boolean {
    return (
      EmergencyLightingTestState.INITIATED.equals(this.functionalTestState) ||
      EmergencyLightingTestState.INITIATED.equals(this.durationTestState)
    );
  }

  public get isFaulty(): boolean {
    return (
      this.emergencyLightingFailureStates != null &&
      this.emergencyLightingFailureStates.length > 0
    );
  }

  public get alerts(): SensorNodeAlert[] {
    const alerts = [];

    if (!this.connected) {
      alerts.push(new SensorNodeAlert('Driver disconnected', null));
    }

    if (this.emergencyLightingFailureStates != null) {
      this.emergencyLightingFailureStates.forEach((state) =>
        alerts.push(
          new SensorNodeAlert(
            'EM Driver address:' +
              this.address16 +
              ' - ' +
              StringUtils.humanizeConstant(state)
          )
        )
      );
    }

    return alerts;
  }
}
