export class Resolution {
  constructor(public value: Resolution_) {}

  public static LIVE = new Resolution('LIVE');
  public static FIVE_MINUTES = new Resolution('FIVE_MINUTES');
  public static HOURLY = new Resolution('HOURLY');
  public static DAILY = new Resolution('DAILY');
  public static MONTHLY = new Resolution('MONTHLY');
  public static YEARLY = new Resolution('YEARLY');

  public static all() {
    return [
      Resolution.YEARLY,
      Resolution.MONTHLY,
      Resolution.DAILY,
      Resolution.HOURLY,
      Resolution.FIVE_MINUTES,
      Resolution.LIVE
    ];
  }

  public static fromString(value: string): Resolution {
    switch (value) {
      case 'LIVE':
        return Resolution.LIVE;
      case 'FIVE_MINUTES':
        return Resolution.FIVE_MINUTES;
      case 'HOURLY':
        return Resolution.HOURLY;
      case 'DAILY':
        return Resolution.DAILY;
      case 'MONTHLY':
        return Resolution.MONTHLY;
      case 'YEARLY':
        return Resolution.YEARLY;
    }
  }

  public toString() {
    return this.value;
  }
}

type Resolution_ =
  | 'LIVE'
  | 'FIVE_MINUTES'
  | 'HOURLY'
  | 'DAILY'
  | 'MONTHLY'
  | 'YEARLY';
