import { Component, Input, OnInit } from '@angular/core';
import { OtapUpgradeTaskDTO } from '@app/shared/models/otap-upgrade-task.interface';
import { OtapUpgradeDTO } from '@app/shared/models/otap.upgrade.interface';

@Component({
  selector: 'app-job-tasks-list',
  templateUrl: './job-tasks-list.component.html',
  styleUrls: ['./job-tasks-list.component.scss']
})
export class JobTasksListComponent {
  @Input() tasks: OtapUpgradeTaskDTO[];
  @Input() job: OtapUpgradeDTO;
  @Input() jobStartEnabled: boolean;
  constructor() {}
}
