import { Component, OnDestroy, OnInit } from '@angular/core';
import { EmergencyLightingTestService } from '@app/shared/services/emergency-lighting/emergency-lighting-test.service';
import { concatMap, interval, Observable, Subject, Subscription, switchMap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { HeaderService } from '@services/header.service';
import { FloorplanService, TestsSummary } from '@services/floorplan.service';
import { environment } from '@environment';
import { EmDriverService } from '@services/em-driver.service';
import { ToastService } from '@services/toast/toast.service';
import { TagService } from '@services/tag.service';
import { FloorplanTag, orderTagById } from '@app/shared/models/tag.interface';
import { TagChangeEvent } from '@components/tags/tags.component';
import { SensorNodeService } from '@services/sensor-node.service';
import { SelectableNode } from '@app/shared/models/sensor-node';
import { Selectable } from '@app/shared/models/selectable.interface';

@Component({
  selector: 'app-emergency-lighting-manual-tests',
  templateUrl: './emergency-lighting-manual-tests.component.html',
  styleUrls: ['./emergency-lighting-manual-tests.component.scss']
})
export class EmergencyLightingManualTestsComponent implements OnInit, OnDestroy {
  shouldShowCounter: boolean;
  latestEmResults$: Observable<TestsSummary>;
  tagPanelToggleState = true;
  floorPanelToggleState = true;
  buildingId: number;
  tags$: Observable<FloorplanTag[]>;
  private onDestroy$ = new Subject<void>();
  private timeInterval: Subscription;

  constructor(
    private floorplanService: FloorplanService,
    private readonly emDetailsService: EmergencyLightingTestService,
    private readonly emDriverService: EmDriverService,
    private readonly header: HeaderService,
    private readonly route: ActivatedRoute,
    private readonly snService: SensorNodeService,
    private readonly tagService: TagService,
    private readonly toast: ToastService
  ) {
    this.header.showFloorsMenu();
  }

  ngOnInit(): void {
    this.route.params
      .pipe(
        concatMap((p) => {
          this.buildingId = p.buildingId;
          this.tags$ = this.tagService
            .getTagsForBuildingId(this.buildingId, true)
            .pipe(map((tags: FloorplanTag[]) => tags.sort(orderTagById)));
          return this.emDetailsService.getDetails(p.buildingId);
        })
      )
      .subscribe((details) => {
        this.shouldShowCounter = details.bannerEnabled;
        this.startPollingForReceivedNodes();
      });
    this.latestEmResults$ = this.floorplanService.emergencyResultSummary;
  }

  ngOnDestroy(): void {
    if (this.timeInterval) {
      this.timeInterval.unsubscribe();
    }
    this.floorplanService.setLatestEmergencyResults([]);
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private startPollingForReceivedNodes(): void {
    // reset the interval if floor or building changes
    if (this.timeInterval) {
      this.timeInterval.unsubscribe();
    }
    const nodes$ = this.snService.getCurrentFloorNodes$();
    // poll for emergency results every <configurable> seconds
    this.timeInterval = interval(environment.elmtPollingInterval)
      .pipe(
        startWith(null), // this is to simulate an initial stream from interval operator, that triggers the api to be called immediately
        switchMap(() => nodes$.pipe(map((nodes) => nodes.flatMap((n) => n.emDrivers.map((e) => e.id))))),
        filter((drivers) => drivers.length > 0),
        switchMap((drivers) =>
          this.emDriverService.getEmergencyLightingTestResultsForDrivers(this.buildingId, drivers)
        ),
        takeUntil(this.onDestroy$)
      )
      .subscribe({
        next: (value) => {
          this.floorplanService.setLatestEmergencyResults(value);
        },
        error: (err) => {
          this.toast.error({
            message: 'Latest ELMT results could not be fetched',
            autoClose: false,
            dismissible: true,
            dataCy: 'load-error-toast'
          });
          console.error(err);
        }
      });
  }

  toggleTagPanel(val: boolean): void {
    this.tagPanelToggleState = val;
  }

  toggleFloorPanel(val: boolean): void {
    this.floorPanelToggleState = val;
  }

  toggleTag(event: TagChangeEvent): void {
    const tag = event.tag;
    tag.isActive = event.isActive;
    this.floorplanService.tagToggled(tag);
  }

  handleNodeClick(node: SelectableNode): void {
    if (node.emDrivers == null || node.emDrivers?.length === 0) {
      return; // if the node has no em drivers, no need to select it
    }
    if (node.selected && !this.floorplanService.isCumulativeModeActive) {
      return; // don't do anything if the SN/PN is already selected
    }
    if (this.floorplanService.isCumulativeModeActive) {
      this.snService.toggleEntitySelection(node, this.floorplanService.isCumulativeModeActive);
      return;
    }
    let selectables: Selectable[] = [node];
    selectables = selectables.concat(node.emDrivers as Selectable[]);
    this.snService.selectEntitiesInArea(selectables, this.floorplanService.isCumulativeModeActive);
    this.snService.updateQueryParams();
  }
}
