import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, delay, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IUserInvitation } from '@app/shared/models/user-invitation.interface';
import { UserInvitationResource } from '@app/shared/resources/user-invitation.resource';
import moment from 'moment';

export class InvitationDatasource implements DataSource<IUserInvitation> {

    private invitationsSubject = new BehaviorSubject<IUserInvitation[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    private countSubject = new BehaviorSubject<number>(0);
    public counter$ = this.countSubject.asObservable();
    public loading$ = this.loadingSubject.asObservable();

    constructor(private invitationResource: UserInvitationResource) { }

    private static sortByCreationTime(item1: IUserInvitation, item2: IUserInvitation): number {
        if (moment(item1.creationTime).isBefore(item2.creationTime, 'day')) {
            return -1;
        } else if (moment(item1.creationTime).isAfter(item2.creationTime, 'day')) {
            return 1;
        } else {
            return 0;
        }
    }

    loadInvitations(buildingId: number, filter = '', pageIndex = 0, pageSize = 10): void {
        this.loadingSubject.next(true);

        this.invitationResource.getAll(buildingId, filter, pageIndex, pageSize).pipe(
          catchError(() => of([])),
          // introduce a slight delay in order to show spinning icon. Any error, or empty response from backend
          // will result in a quick and abrupt response to the frontend. Having a consistent delay provides a
          // consistent UX
          delay(500),
        ).subscribe({
            next: (invitations) => {
                // sort the content by creation date
                invitations.content.sort(InvitationDatasource.sortByCreationTime);
                this.invitationsSubject.next(invitations.content);
                this.countSubject.next(invitations.totalElements);
                this.loadingSubject.next(false);
            },
            error: (err) => {
                this.loadingSubject.next(false);
                console.error(err);
            }
        });

    }

    connect(_collectionViewer: CollectionViewer): Observable<IUserInvitation[]> {
        return this.invitationsSubject.asObservable();
    }

    disconnect(_collectionViewer: CollectionViewer): void {
        this.invitationsSubject.complete();
        this.loadingSubject.complete();
    }

}