import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { AuthorizationModule } from '@app/shared/directives/authorization.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { LampTypeService } from '@services/lamp-type.service';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs';
import { LampTypeTemplate } from '@app/shared/models/lamp-type-template.interface';
import { SensorNodeService } from '@services/sensor-node.service';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { SensorNode } from '@app/shared/models/sensor-node';
import { ToastService } from '@services/toast/toast.service';

@Component({
  selector: 'app-lamp-type-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AuthorizationModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule
  ],
  templateUrl: './lamp-type-form.component.html',
  styleUrl: './lamp-type-form.component.scss'
})
export class LampTypeFormComponent implements OnInit {
  buildingId: number;
  floorId: number;
  areNodesSelected: boolean;
  isSingleTypeSelected: boolean;
  areLampTypesSelected: boolean;
  templates: LampTypeTemplate[];
  lampTypeForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private lampTypeService: LampTypeService,
    private route: ActivatedRoute,
    private nodeService: SensorNodeService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.lampTypeForm = this.fb.group({
      lampTypeTemplate: [''],
      powerConsumptionMax: [null]
    });
    this.route.params
      .pipe(
        switchMap((params) => {
          this.buildingId = params.buildingId;
          this.floorId = params.floorId;
          return this.lampTypeService.getTemplates(params.buildingId);
        })
      )
      .subscribe((lampTypeTemplates) => {
        this.templates = lampTypeTemplates;
      });
    this.nodeService.getCurrentFloorSelectedEntities$().subscribe((nodes: SensorNode[]) => {
      this.areNodesSelected = nodes.length > 0;
      const lampTypes = new Set(
        nodes
          .flatMap((node) => node.luminaireDrivers)
          .filter((driver) => driver && driver.lampTypeId && driver.lampType)
          .map((driver) => driver.lampType)
      );
      this.isSingleTypeSelected = lampTypes.size === 1;
      this.areLampTypesSelected = lampTypes.size > 0;

      if (this.templates) {
        lampTypes.forEach(
          (lampType) =>
            (lampType.template = this.templates.filter((template) => lampType.lampTypeTemplateId === template.id)[0])
        );
      }

      const lampType = lampTypes.values().next().value;
      if (this.isSingleTypeSelected && lampType != null) {
        this.lampTypeForm.patchValue({
          lampTypeTemplate: lampType.lampTypeTemplateId,
          powerConsumptionMax: lampType.powerConsumptionMax
        });
      } else {
        this.reset();
      }
    });
  }

  submit(): void {
    const formValue = this.lampTypeForm.value;
    this.lampTypeService
      .saveLampType(
        {
          lampTypeTemplateId: formValue.lampTypeTemplate,
          sensorNodeIds: this.nodeService.selectedEntities.map((s) => s.id),
          powerConsumptionMax: formValue.powerConsumptionMax
        },
        this.buildingId
      )
      .subscribe({
        next: (val) => {
          this.toastService.success({
            message: 'Successfully updated the power values.',
            dataCy: 'lamp-type-form-success'
          });
          this.nodeService.fetchNodes(this.floorId, this.buildingId);
        },
        error: () =>
          this.toastService.error({ message: 'Failed in updating power values', dataCy: 'lamp-type-form-error' })
      });
  }

  reset(): void {
    this.lampTypeForm.reset({
      lampTypeTemplate: '',
      power: null
    });
  }
}
