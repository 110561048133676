import { UserInfo } from './UserInfo';
import { Role } from './Role';

export class UserRole {
  constructor(
    public userId: number,
    public roleIds: number[],
    public hasGlobalAuthority?: boolean,
    public roles?: Role[],
    public user?: UserInfo
  ) {}
}
