import * as angular from 'angular'; // Automatically added
import { BuildingAuthorityType } from '@app/shared/models/building-authority-type';
import { FormToken } from '@angularjs/or/api/building/TokenUser';
import { TokenIPAddress } from '@angularjs/or/api/building/TokenIPAddress';

export class OrFormTokenDetailsController {
  public isNew: boolean;
  public isBusy: boolean;
  public token;
  public form;
  public initialToken;
  public authorityMap = {};
  public authorityNameMap = {};
  public onAddToken;
  public onDeactivateToken;
  public onUpdateToken;
  public tenants;
  public selectedTenant;
  public ipAddresses: string;

  ipv4Regex =
    /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/\d{1,2})?$/;
  ipv6Regex = /^([\da-fA-F]{1,4}:){7}[\da-fA-F]{1,4}(\/\d{1,3})?$/;

  public $onInit() {
    this.backupInitialToken();
    for (const buildingAuthority of BuildingAuthorityType.all()) {
      this.authorityNameMap[buildingAuthority.toString()] =
        buildingAuthority.getName();
    }
    if (angular.isObject(this.token) && this.token.tenantIds) {
      if (this.tenants) {
        this.token.tenantIds.forEach((tId) => {
          this.selectedTenant = this.tenants.find((t) => t.id === tId);
        });
      }
    }
    if (angular.isObject(this.token) && this.token.allowedIpAddresses) {
      this.ipAddresses = this.token.allowedIpAddresses
        .map((addressObj) => addressObj.ipAddress)
        .join(', ');
    }
  }

  produceAuthorityMap() {
    const authorityMap = {};
    const authority = BuildingAuthorityType;

    authorityMap[authority.ALL_TOKEN.toString()] = this.hasAuthority(
      authority.ALL_TOKEN.value
    );
    authorityMap[authority.VIEW.toString()] = this.hasAuthority(
      authority.VIEW.value
    );
    authorityMap[authority.ANALYTICS.toString()] = this.hasAuthority(
      authority.ANALYTICS.value
    );
    authorityMap[authority.HIGH_RESOLUTION_ANALYTICS.toString()] =
      this.hasAuthority(authority.HIGH_RESOLUTION_ANALYTICS.value);
    authorityMap[authority.CONTROL_LIGHTS.toString()] = this.hasAuthority(
      authority.CONTROL_LIGHTS.value
    );

    return authorityMap;
  }

  hasAuthority(authorityType: string): boolean {
    if (!angular.isObject(this.token)) {
      return;
    }
    const authorityTypes = this.token.authorityTypes;
    const hasAllAuthorityTypes =
      authorityTypes.indexOf(BuildingAuthorityType.ALL_TOKEN.toString()) >= 0;
    const hasThisAuthorityType = authorityTypes.indexOf(authorityType) >= 0;
    return hasThisAuthorityType || hasAllAuthorityTypes;
  }

  toggleAuthority(authorityType: BuildingAuthorityType) {
    if (!angular.isObject(this.token)) {
      return;
    }

    const authorityTypeIndex = this.token.authorityTypes.indexOf(authorityType);

    if (
      authorityType.toString() === BuildingAuthorityType.ALL_TOKEN.toString()
    ) {
      this.token.authorityTypes.splice(0, this.token.authorityTypes.length);
    }

    if (authorityTypeIndex < 0) {
      this.token.authorityTypes.push(authorityType);
    } else if (
      authorityType.toString() !== BuildingAuthorityType.ALL_TOKEN.toString()
    ) {
      this.token.authorityTypes.splice(authorityTypeIndex, 1);
    }

    this.updateAuthorityMap();
    this.form.$setDirty();
  }

  changeTenant() {
    if (!angular.isObject(this.token)) {
      return;
    }

    if (!this.selectedTenant) {
      this.token.tenantIds = [];
    } else {
      this.token.tenantIds = [this.selectedTenant.id];
    }
  }

  backupInitialToken() {
    this.initialToken = angular.copy(this.token);
    this.updateAuthorityMap();
  }

  restoreInitialToken() {
    this.token = angular.copy(this.initialToken);
    this.ipAddresses = this.token.allowedIpAddresses != null ?
        this.token.allowedIpAddresses.map((addressObj) => addressObj.ipAddress).join(', ') :
        null;
    this.selectedTenant = this.token.tenantIds.length > 0 ?
        this.tenants.find((t) => t.id === this.token.tenantIds[0]) :
        null;
    this.updateAuthorityMap();
    this.validateIpAddresses();
  }

  updateAuthorityMap() {
    this.authorityMap = this.produceAuthorityMap();
  }

  resetToken() {
    const msg =
      'Reset token' + (this.token.token ? ' ' + this.token.token : '?');
    if (!confirm(msg)) {
      return;
    }
    this.restoreInitialToken();
    this.resetFormValidationState();
  }

  resetFormValidationState() {
    this.form.$setPristine();
    this.form.$setUntouched();
  }

  addToken() {
    this.resetFormValidationState();
    this.onAddToken();
    this.token.authorityTypes.splice(0, this.token.authorityTypes.length);
    this.updateAuthorityMap();
    this.clearIpAddressInput();
  }

  clearIpAddressInput() {
    this.ipAddresses = '';
  }

  updateIpAddresses() {
    const ipAddressesArray = this.ipAddresses.split(',').map((ip) => ip.trim());
    let allValid = true;

    if (this.ipAddresses.length > 0) {
      for (const ip of ipAddressesArray) {
        if (!(this.ipv4Regex.test(ip) || this.ipv6Regex.test(ip))) {
          allValid = false;
          break;
        }
      }
    }

    if (allValid) {
      this.token.allowedIpAddresses = ipAddressesArray
          .filter((ip) => ip.length > 0)
          .map((ip, index) => new TokenIPAddress(index, ip, this.token.id));
      this.form.ipAddresses.$setValidity('invalidIp', true);
    } else {
      this.form.ipAddresses.$setValidity('invalidIp', false);
    }
  }

  validateIpAddresses() {
    if (this.ipAddresses) {
      this.updateIpAddresses();
    }
    this.form.ipAddresses.$setValidity('invalidIp', true);
  }
}
