import { ISimpleService } from '../api/ISimpleService';
import { SavedEntity } from '../api/SavedEntity';
import { IResource } from '../api/IResource';
import { BeaconSetting } from '../api/building/BeaconSetting';
import { SensorNode } from '../api/building/SensorNode';
import { SensorNodeIdsBatch } from '../api/building/SensorNodeBatch';
import { TagIdsBatch } from '../api/building/TagBatch';
import { FloorplanSensorNode } from '@angularjs/or/api/building/FloorplanSensorNode';

export class BeaconSettingService {
  public setting: BeaconSetting;
  public updateBeaconSettingPanel;

  private endpoints = {
    checkStatus: '/api/v1/beacon-setting/check-status',
    beacon: '/api/v1/beacon-setting/send',
    tag: '/api/v1/beacon-setting/send/tags',
    beaconSettings: '/api/v1/beacon-setting',
    beaconSettingsByFloor: '/api/v1/beacon-setting/floor'
  };

  constructor(
    private beaconSettingResource: IResource<BeaconSetting, number>,
    private simpleService: ISimpleService<BeaconSetting[], SavedEntity<BeaconSetting, number>, BeaconSetting[], void>,
    private generateApiUrl: (endpoint: string) => string
  ) {}

  public activate(sensorNodes: SensorNode[], enabled: boolean, buildingId: number) {
    const batch = this.makeBatch(sensorNodes);
    return this.simpleService.put(this.generateApiUrl(`${this.endpoints.beacon}/${buildingId}?enabled=${enabled}`), {
      nodeIds: batch.nodeIds
    });
  }

  public updatePowerLevel(sensorNodes: SensorNode[], powerLevel: number, buildingId: number) {
    const batch = this.makeBatch(sensorNodes);
    return this.simpleService.put(
      this.generateApiUrl(`${this.endpoints.beacon}/${buildingId}?powerLevel=${powerLevel}`),
      { nodeIds: batch.nodeIds }
    );
  }

  public updateContent(sensorNodes: FloorplanSensorNode[], content: number, buildingId: number) {
    const batch = this.makeBatch(sensorNodes);
    return this.simpleService.put(this.generateApiUrl(`${this.endpoints.beacon}/${buildingId}?content=${content}`), {
      nodeIds: batch.nodeIds
    });
  }

  public updateInterval(sensorNodes: SensorNode[], interval: number, buildingId: number) {
    const batch = this.makeBatch(sensorNodes);
    return this.simpleService.put(this.generateApiUrl(`${this.endpoints.beacon}/${buildingId}?interval=${interval}`), {
      nodeIds: batch.nodeIds
    });
  }

  public activateByTag(tagIds: number[], enabled: boolean, buildingId: number, floorId: number) {
    const batch = this.makeBatchForTag(tagIds);
    return this.simpleService.put(
      this.generateApiUrl(`${this.endpoints.tag}/${buildingId}/${floorId}?enabled=${enabled}`),
      { tagIds: batch.tagIds }
    );
  }

  public updatePowerLevelByTag(
    tagIds: number[],
    powerLevel: number,
    buildingId: number,
    floorId: number
  ): Promise<BeaconSetting[]> {
    const batch = this.makeBatchForTag(tagIds);
    return this.simpleService.put(
      this.generateApiUrl(`${this.endpoints.tag}/${buildingId}/${floorId}?powerLevel=${powerLevel}`),
      { tagIds: batch.tagIds }
    );
  }

  public updateContentByTag(
    tagIds: number[],
    content: number,
    buildingId: number,
    floorId: number
  ): Promise<BeaconSetting[]> {
    const batch = this.makeBatchForTag(tagIds);
    return this.simpleService.put(
      this.generateApiUrl(`${this.endpoints.tag}/${buildingId}/${floorId}?content=${content}`),
      { tagIds: batch.tagIds }
    );
  }

  public updateIntervalByTag(
    tagIds: number[],
    interval: number,
    buildingId: number,
    floorId: number
  ): Promise<BeaconSetting[]> {
    const batch = this.makeBatchForTag(tagIds);
    return this.simpleService.put(
      this.generateApiUrl(`${this.endpoints.tag}/${buildingId}/${floorId}?interval=${interval}`),
      { tagIds: batch.tagIds }
    );
  }

  private makeBatch(sensorNodes: SensorNode[]): SensorNodeIdsBatch {
    return new SensorNodeIdsBatch(sensorNodes.map((node) => node.id));
  }

  private makeBatchForTag(tagIds: number[]): TagIdsBatch {
    return new TagIdsBatch(tagIds);
  }

  public getBeaconSettingsForFloor(floorId: number): Promise<BeaconSetting[]> {
    return this.simpleService.get(this.generateApiUrl(`${this.endpoints.beaconSettingsByFloor}/${floorId}`));
  }
  public getBeaconSettingsForFloorAndNodeIds(floorId: number, nodeIds: number[]): Promise<BeaconSetting[]> {
    return this.simpleService.get(
      this.generateApiUrl(`${this.endpoints.beaconSettingsByFloor}/${floorId}?nodeIds=${nodeIds}`)
    );
  }

  public getBeaconSettingsByNodeId(nodeId: number): Promise<BeaconSetting[]> {
    return this.simpleService.get(this.generateApiUrl(`${this.endpoints.beaconSettings}/${nodeId}`));
  }

  public checkStatusForUpdatingNodes(floorId: number, timeoutInSeconds: number): Promise<BeaconSetting[]> {
    return this.simpleService.put(this.generateApiUrl(`${this.endpoints.checkStatus}/${floorId}/${timeoutInSeconds}`));
  }

  public updateUuid(sensorNodes: SensorNode[], uuid: string, buildingId: number): Promise<BeaconSetting[]> {
    const batch = this.makeBatch(sensorNodes);
    return this.simpleService.put(this.generateApiUrl(`${this.endpoints.beacon}/${buildingId}?uuid=${uuid}`), {
      nodeIds: batch.nodeIds
    });
  }

  public updateUuidByTag(
    tagIds: number[],
    uuid: string,
    buildingId: number,
    floorId: number
  ): Promise<BeaconSetting[]> {
    const batch = this.makeBatchForTag(tagIds);
    return this.simpleService.put(this.generateApiUrl(`${this.endpoints.tag}/${buildingId}/${floorId}?uuid=${uuid}`), {
      tagIds: batch.tagIds
    });
  }

  public updateMajor(sensorNodes: SensorNode[], major: string, buildingId: number): Promise<BeaconSetting[]> {
    const batch = this.makeBatch(sensorNodes);
    return this.simpleService.put(this.generateApiUrl(`${this.endpoints.beacon}/${buildingId}?major=${major}`), {
      nodeIds: batch.nodeIds
    });
  }

  public updateMajorByTag(
    tagIds: number[],
    major: string,
    buildingId: number,
    floorId: number
  ): Promise<BeaconSetting[]> {
    const batch = this.makeBatchForTag(tagIds);
    return this.simpleService.put(
      this.generateApiUrl(`${this.endpoints.tag}/${buildingId}/${floorId}?major=${major}`),
      { tagIds: batch.tagIds }
    );
  }

  public updateMinor(sensorNodes: SensorNode[], minor: number, buildingId: number): Promise<BeaconSetting[]> {
    const batch = this.makeBatch(sensorNodes);
    return this.simpleService.put(this.generateApiUrl(`${this.endpoints.beacon}/${buildingId}?minor=${minor}`), {
      nodeIds: batch.nodeIds
    });
  }

  public updateMinorByTag(
    tagIds: number[],
    minor: number,
    buildingId: number,
    floorId: number
  ): Promise<BeaconSetting[]> {
    const batch = this.makeBatchForTag(tagIds);
    return this.simpleService.put(
      this.generateApiUrl(`${this.endpoints.tag}/${buildingId}/${floorId}?minor=${minor}`),
      { tagIds: batch.tagIds }
    );
  }

  public async querySensorNodes(
    sensorNodes: SensorNode[],
    buildingId: number
  ): Promise<SavedEntity<BeaconSetting, number>> {
    const batch = this.makeBatch(sensorNodes);
    return this.simpleService.post(this.generateApiUrl(`${this.endpoints.beacon}?buildingId=${buildingId}`), {
      nodeIds: batch.nodeIds
    });
  }
  public async queryMultipleSensorNodes(
    nodeIds: SensorNode[],
    buildingId: number
  ): Promise<SavedEntity<BeaconSetting, number>> {
    return this.simpleService.post(this.generateApiUrl(`${this.endpoints.beacon}?buildingId=${buildingId}`), {
      nodeIds
    });
  }
}
