import { BuildingAuthorityType } from '@app/shared/models/building-authority-type';
import { User } from '@app/shared/models/user.interface';
import { Building } from '@app/shared/models/building.interface';

export class SecurityUtils {
  static hasBuildingAuthority(authority: BuildingAuthorityType): (user: User, building: Building) => boolean {
    return (user: User, building: Building) => {
      return user.buildingAuthorizations.some((ba) => {
        if (ba.buildingId === building.id) {
          return ba.authorities.some((a) => a.toString() === authority.toString());
        }
        return false;
      });
    };
  }
}
