import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { OtapUpgradeService } from '@services/otap-upgrade/otap.upgrade.service';
import { OtapUpgradeGatewayDTO } from '@app/shared/models/otap-upgrade-gateway.inerface';
import { OtapUpgradeTaskDTO } from '@app/shared/models/otap-upgrade-task.interface';
import { OtapUpgradeDTO } from '@app/shared/models/otap.upgrade.interface';
import { Observable, combineLatest, of, Subject, BehaviorSubject, Subscription } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Location } from '@angular/common';
import { OtapWebsocketService } from '@services/otap-upgrade/otap-websocket-service';
import { GatewayHealthDTO, GatewayMetricDTO, JobNotification } from '@app/shared/models/gateway-health-dto.interface';

class OtapNotification {
  timestamp: string;
  gateway: string;
  message: string;
}

@Component({
  selector: 'app-job-information',
  templateUrl: './job-information.component.html',
  styleUrls: ['./job-information.component.scss']
})
export class JobInformationComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  private gatewayDetailsSubscription: any;
  private subscriptions: Subscription[] = [];
  job$: Observable<OtapUpgradeDTO>;
  @Output()
  tasks: OtapUpgradeTaskDTO[];
  @Output() jobStartEnabled = true;
  @Output() exportCompleteEvent = new EventEmitter<{ exportId: string; complete: boolean }>();
  private gatewaysSubject$ = new BehaviorSubject<OtapUpgradeGatewayDTO[]>([]);
  gateways$ = this.gatewaysSubject$.asObservable();
  private jobRunnable = false;
  private exportId: string | null = null;

  otapNotifications: OtapNotification[] = [];

  public exportStatus: { exportId: string; complete: boolean } | null = null;

  constructor(
    private otapUpgradeService: OtapUpgradeService,
    private location: Location,
    private otapWebsocketService: OtapWebsocketService
  ) {}

  ngOnInit(): void {
    this.job$ = this.otapUpgradeService.selectedJob$;
    this.subscriptions.push(
      this.job$
        .pipe(
          switchMap((job) => {
            if (job) {
              switch (job.status) {
                case 'CREATED':
                case 'STARTED':
                  this.jobRunnable = true;
              }
              return combineLatest([
                this.otapUpgradeService.getAllGatewaysForAJob(job.id),
                this.otapUpgradeService.getAllTasksForAJob(job.id)
              ]);
            }
            this.location.back();
            return of([[], []]);
          }),
          takeUntil(this.destroy$)
        )
        .subscribe(([gateways, tasks]) => {
          this.gatewaysSubject$.next(gateways);
          this.tasks = tasks;
          this.setStartButtonStatus(tasks);
        })
    );

    if (!this.otapWebsocketService.isConnected()) {
      this.otapWebsocketService.startConnection();
    }

    this.initializeWebsocketListener();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());

    if (this.gatewayDetailsSubscription && this.gatewayDetailsSubscription.unsubscribe) {
      this.gatewayDetailsSubscription.unsubscribe();
    }
    this.otapWebsocketService.disconnect();
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setStartButtonStatus(tasks: any) {
    let enableStartButtons = this.jobRunnable;
    if (tasks !== null) {
      for (const task of tasks) {
        if (task.statuses) {
          if (task.statuses.length > 0) {
            if (this.checkIfAnyTaskIsRunning(task.statuses)) {
              enableStartButtons = false;
              break;
            }
          }
        }
      }
    }
    this.onJobStartStateChanged(enableStartButtons);
  }

  onJobStartStateChanged(updatedJobStartState: boolean) {
    this.jobStartEnabled = updatedJobStartState;
  }

  onOtapNotification(message: string): void {
    const json = JSON.parse(message);

    const otapNotification = new OtapNotification();
    otapNotification.timestamp = new Date(json.otapResponseNotification.receivedTime).toLocaleString();
    otapNotification.gateway = json.otapResponseNotification.gatewayAddress;
    let otapMessage = '';
    for (const x in json.otapResponseNotification) {
      if (x !== 'gatewayAddress' && x !== 'receivedTime') {
        otapMessage = x;
      }
    }
    otapNotification.message =
      '{"' + otapMessage + '": ' + JSON.stringify(json.otapResponseNotification[otapMessage]) + '}';

    this.otapNotifications.unshift(otapNotification);
    this.otapNotifications = this.otapNotifications.slice(0, 1000);
  }

  clearOtapNotifications(): void {
    this.otapNotifications = [];
  }

  private initializeWebsocketListener(): void {
    this.subscriptions.push(
      this.job$.subscribe((job) => {
        if (job && job.id) {
          this.otapWebsocketService
            .listenToJob(job.id, (packet) => {
              const parsedData: JobNotification = JSON.parse(packet.body);
              switch (parsedData['notification-type']) {
                case 'gateway':
                  this.updateGatewayData(parsedData, 'gateway');
                  break;
                case 'gateway-health':
                  this.updateGatewayHealthOrMetricData(parsedData as GatewayHealthDTO, 'gatewayHealth');
                  break;
                case 'gateway-metric':
                  this.updateGatewayHealthOrMetricData(parsedData as GatewayMetricDTO, 'gatewayMetric');
                  break;
                case 'job-task':
                  this.updateTaskData(parsedData, 'jobTask');
                  break;
                case 'gateway-response':
                  // Ignore for now
                  break;
                case 'export-complete':
                  this.handleExportComplete(parsedData);
                  break;
                case 'otap-response-notification':
                  this.onOtapNotification(packet.body);
                  break;
                default:
                  console.warn('Unknown notification type: ', parsedData);
              }
            })
            .subscribe((subscription) => {
              this.gatewayDetailsSubscription = subscription;
            });
        }
      })
    );
  }

  private updateGatewayData(gatewayData: any, notificationType: 'gateway'): void {
    const gateways = this.gatewaysSubject$.value;
    if (gateways && gateways.length) {
      gateways.forEach((gateway) => {
        if (gateway.id === gatewayData.gatewayId) {
          gateway.sensorNodeCount = gatewayData.sensorNodeCount;
        }
      });
    }
  }

  private updateGatewayHealthOrMetricData(gatewayData: any, notificationType: 'gatewayHealth' | 'gatewayMetric'): void {
    const gateways = this.gatewaysSubject$.value;
    if (gateways && gateways.length) {
      gateways.forEach((gateway) => {
        if (gateway.id === gatewayData.gatewayId) {
          delete gatewayData['notification-type'];
          gateway[notificationType] = gatewayData;
        }
      });
    }
  }

  private updateTaskData(taskData: any, notificationType: 'jobTask'): void {
    const newTasks = [];
    if (this.tasks && this.tasks.length) {
      this.tasks.forEach((task) => {
        if (task.id === taskData.id) {
          newTasks.push(taskData);
          if (taskData.statuses) {
            this.checkTaskEndDate(taskData.statuses);
          }
        } else {
          newTasks.push(task);
        }
      });
    }
    this.tasks = newTasks;
  }

  checkTaskEndDate(taskStatuses: any) {
    let allTasksEnded = true;
    for (const status of taskStatuses) {
      if (!status.endedDateTime || status.endedDateTime === null) {
        allTasksEnded = false;
        break;
      }
    }
    this.onJobStartStateChanged(allTasksEnded);
  }

  checkIfAnyTaskIsRunning(taskStatuses: any): boolean {
    let taskIsRunning = false;
    for (const status of taskStatuses) {
      if (!status.endedDateTime || status.endedDateTime === null) {
        taskIsRunning = true;
        break;
      }
    }
    return taskIsRunning;
  }

  private handleExportComplete(notification: any): void {
    const exportId = notification.exportId;
    this.exportCompleteEvent.emit({ exportId, complete: true });
    this.exportStatus = { exportId, complete: true };
  }
}
