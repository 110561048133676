import * as ng from 'angular';
import { MappingService as NewMappingService } from '@services/mapping.service';
import { OrBuildingsService } from './buildings-service/OrBuildingsService';
import { UserService as NewUserService } from '@services/user/user.service';
import { OrMetricsService } from './or-metrics-service/OrMetricsService';
import { OrTintDirective } from './or-tint/OrTintDirective';
import { OrSessionMenuDirective } from './session-menu/OrSessionMenuDirective';
import { OrSiteMenuDirective } from './site-menu/OrSiteMenuDirective';
import { OrUserMenuDirective } from './user-menu/OrUserMenuDirective';
import { OrHeaderDirective } from './header/OrHeaderDirective';
import { OrFooterDirective } from './footer/OrFooterDirective';
import { OrFloorsMenuDirective } from './floors-menu/OrFloorsMenuDirective';
import { OrBuildingsMenuDirective } from './buildings-menu/OrBuildingsMenuDirective';
import { OrBuildingsListDirective } from './buildings-list/OrBuildingsListDirective';
import { OrBannerNoBuildingsDirective } from './banner/or-banner-no-buildings/OrBannerNoBuildingsDirective';
import { OrBannerNoGatewaysDirective } from './banner/or-banner-no-gateways/OrBannerNoGatewaysDirective';
import { OrBannerNoFloorsDirective } from './banner/or-banner-no-floors/OrBannerNoFloorsDirective';
import { OrBannerNoSchedulesDirective } from './banner/or-banner-no-schedules/OrBannerNoSchedulesDirective';
import { OrBannerNoRulesDirective } from './banner/or-banner-no-rules/OrBannerNoRulesDirective';
import { OrBannerNoGroupsDirective } from './banner/or-banner-no-groups/OrBannerNoGroupsDirective';
import { OrBannerNoAreasDirective } from './banner/or-banner-no-areas/OrBannerNoAreasDirective';
import { OrPanelDirective } from './or-panel/OrPanelDirective';
import { OrPanelToggleDirective } from './or-panel/or-panel-toggle/OrPanelToggleDirective';
import { OrPanelService } from './or-panel/or-panel-service/OrPanelService';
import { OrMetricWidgetDirective } from './or-metric-widget/OrMetricWidgetDirective';
import { OrMetricSelectorDirective } from './or-metric-selector/OrMetricSelectorDirective';
import { OrFloorplanDirective } from './or-floorplan/OrFloorplanDirective';
import { OrPubSubConnectionsDirective } from './or-pub-sub-connections/OrPubSubConnectionsDirective';
import { OrMappedNodesCounterDirective } from './or-mapped-nodes-counter/OrMappedNodesCounterDirective';
import { OrNodesListDirective } from './or-nodes-list/OrNodesListDirective';
import { OrNodeTileDirective } from './or-node-tile/OrNodeTileDirective';
import { OrLuminaireDriverTileDirective } from './or-node-tile/or-luminaire-driver-tile/OrLuminaireDriverTileDirective';
import { OrEmDriverTileDirective } from './or-node-tile/or-em-driver-tile/OrEmDriverTileDirective';
import { OrCheckboxDirective } from './checkbox/OrCheckboxDirective';
import { OrSearchInputDirective } from './or-search-input/OrSearchInputDirective';
import { OrTagsDirective } from './or-tags/OrTagsDirective';
import { OrBeaconSettingsDirective } from './or-beacon-settings/OrBeaconSettingsDirective';
import { QueryExecutor } from '@angularjs/or/angular/QueryExecutor';
import { OrHeatmapDirective } from './or-heatmap/OrHeatmapDirective';
import { FloorService } from '@angularjs/or/services/FloorService';
import { QueryOutlineBuilder } from '@angularjs/or/api/query/outline/QueryOutlineBuilder';
import { TagService } from '@angularjs/or/services/TagService';
import { TagService as NewTagService } from '@services/tag.service';
import { UserService } from '@angularjs/or/services/UserService';
import { OrSensorNodeDirective } from './or-sensor-node/OrSensorNodeDirective';
import { SensorNodeService } from '@angularjs/or/services/SensorNodeService';
import { SensorNodeService as NewSensorNodeService } from '@services/sensor-node.service';
import { QueryService } from '@angularjs/or/services/QueryService';
import { UserResource } from '@angularjs/or/angular/resources/UserResource';
import { FloorResource } from '@angularjs/or/angular/resources/FloorResource';
import { TagResource } from '@angularjs/or/angular/resources/TagResource';
import { SensorNodeResource } from '@angularjs/or/angular/resources/SensorNodeResource';
import { SessionService } from '@angularjs/or/services/SessionService';
import { CountryService } from '@angularjs/or/services/CountryService';
import { TimezoneService } from '@angularjs/or/services/TimezoneService';
import { GatewayService } from '@angularjs/or/services/GatewayService';
import { NavigationService } from '@app/shared/services/navigation/navigation.service';
import { OrFloorplanZoomControlDirective } from './or-floorplan-zoom-control/OrFloorplanZoomControlDirective';
import { OrImgOnLoadDirective } from './or-img-on-load/OrImgOnLoadDirective';
import { OrImageUploadDirective } from './or-image-upload/OrImageUploadDirective';
import { OrTabsDirective } from './or-tabs/OrTabsDirective';
import { Observable, ProxiedObservable } from '@angularjs/or/util/Observable';
import { OrFormBuildingDetailsDirective } from './or-form-building-details/OrFormBuildingDetailsDirective';
import { OrFormGatewayDetailsDirective } from './or-form-gateway-details/OrFormGatewayDetailsDirective';
import { OrFormFloorDetailsDirective } from './or-form-floor-details/OrFormFloorDetailsDirective';
import { OrFloorplanActionsDirective } from './or-floorplan-actions/OrFloorplanActionsDirective';
import { SimpleService } from '@angularjs/or/angular/SimpleService';
import { ImageCachingService } from '@angularjs/or/services/ImageCachingService';
import { BuildingResource } from '@angularjs/or/angular/resources/BuildingResource';
import { UIRefreshService } from '@angularjs/or/services/UIRefreshService';
import { TimeNavigatorFactory } from '@angularjs/or/time/TimeNavigatorFactory';
import { OrFormEditTagDirective } from './or-form-edit-tag/OrFormEditTag';
import { OrChartDirective } from './or-chart/OrChartDirective';
import { OrAudioDirective } from './or-audio/OrAudioDirective';
import { MappingResource } from '@angularjs/or/angular/resources/MappingResource';
import { MappingService } from '@angularjs/or/services/MappingService';
import { OrFloorplanActionsTrayDirective } from './or-floorplan-actions-tray/OrFloorplanActionsTrayDirective';
import { MultiTagResource } from '@angularjs/or/angular/resources/MultiTagResource';
import { UserRoleResource } from '@angularjs/or/angular/resources/UserRoleResource';
import { OrFormUserDetailsDirective } from './or-form-user-details/OrFormUserDetailsDirective';
import { RoleResource } from '@angularjs/or/angular/resources/RoleResource';
import { RoleService } from '@angularjs/or/services/RoleService';
import { OrMultiselectDirective } from './or-multiselect/OrMultiselectDirective';
import { OrInlineTagsDirective } from './or-inline-tags/OrInlineTagsDirective';
import { OrInlineFloorsDirective } from './or-inline-floors/OrInlineFloorsDirective';
import { OrFormInviteUserDirective } from './or-form-invite-user/OrFormInviteUserDirective';
import { UserInvitationResource } from '@angularjs/or/angular/resources/UserInvitationResource';
import { OrFormRoleDetailsDirective } from './or-form-role-details/OrFormRoleDetailsDirective';
import { OrFormWeekdayScheduleDirective } from './or-form-weekday-schedule/OrFormWeekdayScheduleDirective';
import { OrFormRuleDetailsDirective } from './or-form-rule-details/OrFormRuleDetailsDirective';
import { ScheduleResource } from '@angularjs/or/angular/resources/ScheduleResource';
import { ScheduleService } from '@angularjs/or/services/ScheduleService';
import { OrScheduleSummaryDirective } from './or-schedule-summary/OrScheduleSummaryDirective';
import { OrSchedulesListDirective } from './or-schedules-list/OrSchedulesListDirective';
import { OrScheduleRuleTileDirective } from './or-schedule-rule-tile/OrScheduleRuleTileDirective';
import { LampTypeResource } from '@angularjs/or/angular/resources/LampTypeResource';
import { OrFormLampTypeDirective } from './or-form-lamp-type/OrFormLampType';
import { LampTypeService } from '@angularjs/or/services/LampTypeService';
import { SecurityService } from '@angularjs/or/angular/services/SecurityService';
import { ConvertToNumberDirective } from './convert-to-number/ConvertToNumberDirective';
import { ImageZoomService } from '@angularjs/or/services/ImageZoomService';
import { EmergencyLightingResource } from '@angularjs/or/angular/resources/EmergencyLightingResource';
import { PromiseRejectionHandler } from '@angularjs/or/promises/PromiseRejectionHandler';
import { IQueryContext } from '@angularjs/or/api/query/outline/context/IContext';
import { IQueryOutlineBuilder } from '@angularjs/or/api/query/outline/IQueryOutlineBuilder';
import { UrlQueryContext } from '@angularjs/or/api/query/outline/context/UrlQueryContext';
import { EmergencyLightingTestResultsDirective } from './emergency-lighting-test-results/EmergencyLightingTestResultsDirective';
import { IsAuthorizedDirective } from './is-authorized/IsAuthorizedDirective';
import { CachingQueryExecutor } from '@angularjs/or/angular/CachingQueryExecutor';
import { AutomaticInterpolationRange } from '@angularjs/or/view/heatmap/interpolator/InterpolationRange';
import { PolynomialInterpolationFunction } from '@angularjs/or/view/heatmap/interpolator/InterpolationFunction';
import { DataInterpolator } from '@angularjs/or/view/heatmap/interpolator/DataInterpolator';
import { HoneycombGridProvider } from '@angularjs/or/view/heatmap/Grid';
import { HeatmapJsGradient } from '@angularjs/or/view/heatmap/renderer/Gradient';
import { Hexagon, ShapesRenderer } from '@angularjs/or/view/heatmap/renderer/ShapesRenderer';
import { ColoringRenderer } from '@angularjs/or/view/heatmap/renderer/ColoringRenderer';
import { GridRenderer } from '@angularjs/or/view/heatmap/renderer/GridRenderer';
import { OrFormUserInfoDirective } from './or-form-user-info/OrFormUserInfoDirective';
import { DescriptiveStateChangeMessage, StateChangeMessage } from '@angularjs/or/messaging/StateChangeMessage';
import { FloorplanChangeMessage } from '@angularjs/or/messaging/FloorplanChangeMessage';
import { Channel, TransformingMessageSender } from '@angularjs/or/messaging/Channel';
import { CopyToClipboardDirective } from './copy-to-clipboard/CopyToClipboardDirective';
import { CopyToClipboardService } from '@angularjs/or/angular/services/CopyToClipboardService';
import { FilterService } from '@angularjs/or/services/FilterService';
import { BuildingNotificationResource } from '@angularjs/or/angular/resources/BuildingNotificationResource';
import { LoginMethodsService } from '@angularjs/or/services/LoginMethodsService';
import { LuminaireManufacturerResource } from '@angularjs/or/angular/resources/LuminaireManufacturerResource';
import { OrGatewayTagsDirective } from './or-gateway-tags/OrGatewayTagsDirective';
import { DropdownService } from '@angularjs/or/services/DropdownService';
import { GatewayResource } from '@angularjs/or/angular/resources/GatewayResource';
import { DdlSettingResource } from '@angularjs/or/angular/resources/DdlResource';
import { LightingGroupService } from '@angularjs/or/services/LightingGroupService/LightingGroupService';
import { LightingTestReportService } from '@angularjs/or/services/LightingTestReportService/LightingTestReportService';
import { LightingTestSchedulingService } from '@angularjs/or/services/LightingTestSchedulingService/LightingTestSchedulingService';
import { BacnetAreaService } from '@angularjs/or/services/BacnetAreaService/BacnetAreaService';
import { OrLightGroupListDirective } from '../../modules/layout/or-light-group-list/OrLightGroupListDirective';
import { OrBacnetAreaTileDirective } from './or-bacnet-area-tile/OrBacnetAreaTileDirective';
import { OrLightingTestScheduleTileDirective } from '../../modules/layout/or-lighting-test-schedule-tile/OrLightingTestScheduleTileDirective';
import { OrLightingTestReportDirective } from '../../modules/layout/or-lighting-test-report/OrLightingTestReportDirective';
import { OrSimpleWeekdayListDirective } from '../../modules/layout/or-simple-weekday-list/OrSimpleWeekdayListDirective';
import { OrTimerangeWidgetDirective } from '../../modules/layout/or-timerange-widget/OrTimerangeWidgetDirective';
import { EmergencyLightingTestResultResource } from '@angularjs/or/angular/resources/EmergencyLightingTestResultResource';
import { OrFailedTestsCounterDirective } from './or-failed-tests-counter/OrFailedTestsCounterDirective';
import { OrDatepickerDirective } from './or-datepicker/OrDatepickerDirective';
import { OrPrintDirective } from './or-print/OrPrintDirective';
import { GatewayHealthService } from '@angularjs/or/services/GatewayHealthService';
import { PropertiesService } from '@angularjs/or/services/PropertiesService';
import { TokenResource } from '@angularjs/or/angular/resources/TokenResource';
import { MobileTokenResource } from '@angularjs/or/angular/resources/MobileTokenResource';
import { BleKeyResource } from '@angularjs/or/angular/resources/BleKeyResource';
import { BeaconSettingResource } from '@angularjs/or/angular/resources/BeaconSettingResource';
import { LightingConfigurationResource } from '@angularjs/or/angular/resources/LightingConfigurationResource';
import { TokenService } from '@angularjs/or/services/TokenService';
import { MobileTokenService } from '@angularjs/or/services/MobileTokenService';
import { DdlSettingService } from '@angularjs/or/services/Ddl/DdlSettingService';
import { DdlModeService } from '@angularjs/or/services/Ddl/DdlModeService';
import { DdlNodeService } from '@angularjs/or/services/Ddl/DdlNodeService';
import { DdlTagService } from '@angularjs/or/services/Ddl/DdlTagService';
import { BleKeyService } from '@angularjs/or/services/BleKeyService';
import { BeaconSettingService } from '@angularjs/or/services/BeaconSettingService';
import { LightingConfigurationService } from '@angularjs/or/services/LightingConfigurationService';

import { OrFormTokenDetailsDirective } from './or-form-token-details/OrFormTokenDetailsDirective';
import { OrFormMobileTokenDetailsDirective } from './or-form-mobile-token-details/OrFormMobileTokenDetailsDirective';
import { OrFormBleKeyDetailsDirective } from './or-form-ble-key-details/OrFormBleKeyDetailsDirective';
import { OrBeaconsNodesListDirective } from './or-beacons-nodes-list/OrBeaconsNodesListDirective';
import { OrLightingConfigurationNodesListDirective } from './or-lighting-configuration-nodes-list/OrLightingConfigurationNodesListDirective';
import { OrBeaconsNodeTileDirective } from './or-beacons-node-tile/OrBeaconsNodeTileDirective';
import { OrLightingConfigurationNodeTileDirective } from './or-lighting-configuration-node-tile/OrLightingConfigurationNodeTileDirective';
import { OrLightingConfigurationDirective } from './or-lighting-configuration/OrLightingConfigurationDirective';
import { downgradeInjectable } from '@angular/upgrade/static';
import { EventBridge } from '@common/event-bridge';
import { OrFormCircadianCurveDirective } from '@angularjs/angular/modules/layout/or-form-circadian-curve/OrFormCircadianCurveDirective';
import { OrCircadianCurveListDirective } from '@angularjs/angular/modules/layout/or-circadian-curve-list/OrCircadianCurveListDirective';
import { CircadianCurveService } from '@angularjs/or/services/CircadianCurveService';
import { CircadianCurveResource } from '@angularjs/or/angular/resources/CircadianCurveResource';
import { OrCircadianCurveSummaryDirective } from '@angularjs/angular/modules/layout/or-circadian-curve-summary/OrCircadianCurveSummaryDirective';
import { OrBannerNoCircadianCurvesDirective } from '@angularjs/angular/modules/layout/banner/or-banner-no-circadian-curves/OrBannerNoCircadianCurvesDirective';
import { OrCircadianCurveTileDirective } from '@angularjs/angular/modules/layout/or-circadian-curve-tile/OrCircadianCurveTileDirective';
import { OrFormDatedScheduleDirective } from '@angularjs/angular/modules/layout/or-form-dated-schedule/OrFormDatedScheduleDirective';
import { TenantService } from '@app/shared/services/building/tenant.service';
import { FeatureFlagDirective } from '@angularjs/angular/modules/layout/feature-flag/featureFlagDirective';
import { PassiveNodeService } from '@app/shared/services/passive-node.service';
import { PassiveNodeResource } from '@app/shared/resources/passive-node.resource';
import { EmDriverResource } from '@angularjs/or/angular/resources/EmDriverResource';
import { EmDriverService } from '@angularjs/or/services/EmDriverService';
import { LuminaireDriverService } from '@angularjs/or/services/LuminaireDriverService';
import { LuminaireDriverResource } from '@angularjs/or/angular/resources/LuminaireDriverResource';
import { OrEmDriverDirective } from '@angularjs/angular/modules/layout/or-sensor-node/or-em-driver/OrEmDriverDirective';
import { OrLuminaireDriverDirective } from '@angularjs/angular/modules/layout/or-sensor-node/or-luminaire-driver/OrLuminaireDriverDirective';
import { DuplicateMappingResource } from '@angularjs/or/angular/resources/DuplicateMappingResource';
import { CookieService } from '@app/shared/services/cookie.service';
import { TagTenantBridgeService } from '@app/shared/services/tag-tenant-bridge.service';
import { SensorNodeBleResource } from '@angularjs/or/angular/resources/SensorNodeBleResource';
import { FloorplanService } from '@services/floorplan.service';
import { SensorNodeChangeHistoryService } from '@angularjs/or/services/SensorNodeChangeHistoryService';

export class PageModuleConfig {
  static configure() {
    const module = ng.module('orLayout', []);

    PageModuleConfig.configureComponents(module);
    PageModuleConfig.configureResources(module);
    PageModuleConfig.configureServices(module);
    PageModuleConfig.configureDirectives(module);
  }

  static configureComponents(module: ng.IModule) {
    module
      .service('QueryContext', [
        '$location',
        '$routeParams',
        'Channel',
        function (location, route, bus) {
          return new Observable<IQueryContext>(new UrlQueryContext(location, route), bus);
        }
      ])
      .service('QueryOutlineBuilder', [
        'QueryContext',
        function (context) {
          return new ProxiedObservable<IQueryOutlineBuilder, IQueryContext>(
            new QueryOutlineBuilder(context.value()),
            context
          );
        }
      ])
      .service('RejectionHandler', ['ErrorHandler', PromiseRejectionHandler])
      .service('BasicQueryExecutor', [
        '$http',
        'QueryExecutorConfiguration',
        'RejectionHandler',
        'FeatureService',
        QueryExecutor
      ])
      .service('QueryExecutor', ['BasicQueryExecutor', 'QueryCacheTtl', '$rootScope', CachingQueryExecutor])
      .service('TimeNavigator', [
        'SecurityService',
        'BuildingService',
        function (securityService, buildingService) {
          return TimeNavigatorFactory.create(securityService, buildingService);
        }
      ])
      .service('Channel', [
        function () {
          return new Channel<StateChangeMessage>();
        }
      ])
      .service('FloorplanUpdater', [
        'Channel',
        function (underlying) {
          return new TransformingMessageSender<DescriptiveStateChangeMessage<IQueryContext>, FloorplanChangeMessage>(
            underlying,
            (value) => {
              const isSame = ng.equals(value.oldState.dates, value.newState.dates);
              if (!isSame) {
                return new FloorplanChangeMessage(!isSame);
              }
            }
          );
        }
      ])
      .service('HeatmapRenderer', [
        'PropertiesService',
        function (propertiesService) {
          return new GridRenderer(
            new ColoringRenderer(
              new ShapesRenderer(new Hexagon()),
              0.0,
              1.0,
              new HeatmapJsGradient(HeatmapJsGradient.DEFAULT_GRADIENT)
            ),
            new HoneycombGridProvider(20, 20000, HoneycombGridProvider.Y_OFFSET_HEXAGON_MULTIPLIER),
            (radius: number) =>
              new DataInterpolator(
                radius,
                new PolynomialInterpolationFunction(5.0),
                new AutomaticInterpolationRange(1.3)
              )
          );
        }
      ]);
  }

  static configureResources(module: ng.IModule) {
    module
      .service('UserResource', ['$http', 'UserResourceBaseUrl', 'RejectionHandler', UserResource])
      .service('UserRoleResource', ['$http', 'UserRoleResourceBaseUrl', 'RejectionHandler', UserRoleResource])
      .service('RoleResource', ['$http', 'RoleResourceBaseUrl', 'RejectionHandler', RoleResource])
      .service('TokenResource', ['$http', 'TokenResourceBaseUrl', 'RejectionHandler', TokenResource])
      .service('MobileTokenResource', ['$http', 'MobileTokenResourceBaseUrl', 'RejectionHandler', MobileTokenResource])
      .service('BleKeyResource', ['$http', 'BleKeyResourceBaseUrl', 'RejectionHandler', BleKeyResource])
      .service('BeaconSettingResource', [
        '$http',
        'BeaconSettingResourceBaseUrl',
        'RejectionHandler',
        BeaconSettingResource
      ])
      .service('LightingConfigurationResource', [
        '$http',
        'LightingConfigurationResourceBaseUrl',
        'RejectionHandler',
        LightingConfigurationResource
      ])
      .service('BuildingResource', ['$http', 'BuildingResourceBaseUrl', 'RejectionHandler', BuildingResource])
      .service('GatewayResource', ['$http', 'GatewayResourceBaseUrl', 'RejectionHandler', GatewayResource])
      .service('DdlSettingResource', ['$http', 'DdlSettingResourceBaseUrl', 'RejectionHandler', DdlSettingResource])
      .service('FloorResource', [
        '$http',
        'FloorResourceBaseUrl',
        'RejectionHandler',
        'AddNodesUrlGenerator',
        FloorResource
      ])
      .service('SensorNodeResource', [
        '$http',
        'NodeResourceBaseUrl',
        'RejectionHandler',
        'AddNodesUrlGenerator',
        SensorNodeResource
      ])
      .service('EmDriverResource', ['$http', 'EmDriverResourceBaseUrl', 'RejectionHandler', EmDriverResource])
      .service('LuminaireDriverResource', [
        '$http',
        'LuminaireDriverResourceBaseUrl',
        'RejectionHandler',
        LuminaireDriverResource
      ])
      .service('PassiveNodeResource', [
        '$http',
        'NodeResourceBaseUrl',
        'RejectionHandler',
        'AddNodesUrlGenerator',
        PassiveNodeResource
      ])
      .service('DuplicateMappingResource', [
        '$http',
        'DuplicateMappingResourceBaseUrl',
        'RejectionHandler',
        DuplicateMappingResource
      ])
      .service('TagResource', ['$http', 'TagResourceBaseUrl', 'RejectionHandler', TagResource])
      .service('MultiTagResource', ['$http', 'MultiTagResourceBaseUrl', 'RejectionHandler', MultiTagResource])
      .service('MappingResource', ['$http', 'MappingResourceBaseUrl', 'RejectionHandler', MappingResource])
      .service('UserInvitationResource', [
        '$http',
        'UserInvitationResourceBaseUrl',
        'RejectionHandler',
        UserInvitationResource
      ])
      .service('ScheduleResource', ['$http', 'ScheduleResourceBaseUrl', 'RejectionHandler', ScheduleResource])
      .service('CircadianCurveResource', [
        '$http',
        'CircadianCurveResourceBaseUrl',
        'RejectionHandler',
        CircadianCurveResource
      ])
      .service('LampTypeResource', ['$http', 'LampTypeResourceUrlGenerator', 'RejectionHandler', LampTypeResource])
      .service('EmergencyLightingResource', [
        '$http',
        'EmergencyLightingResourceBaseUrl',
        'RejectionHandler',
        EmergencyLightingResource
      ])
      .service('EmergencyLightingTestResultResource', [
        '$http',
        'EmergencyLightingTestResultsResourceBaseUrl',
        'RejectionHandler',
        EmergencyLightingTestResultResource
      ])
      .service('BuildingNotificationResource', [
        '$http',
        'BuildingNotificationBaseUrl',
        'RejectionHandler',
        BuildingNotificationResource
      ])
      .service('LuminaireManufacturerResource', [
        '$http',
        'LuminaireManufacturerBaseUrl',
        'RejectionHandler',
        LuminaireManufacturerResource
      ])
      .service('SensorNodeBleResource', [
        '$http',
        'SensorNodeBleResourceBaseUrl',
        'RejectionHandler',
        SensorNodeBleResource
      ]);
  }

  static configureServices(module: ng.IModule) {
    module
      .service('EventBridge', downgradeInjectable(EventBridge))
      .service('TenantService', downgradeInjectable(TenantService))
      .service('DowngradedTagService', downgradeInjectable(TagTenantBridgeService))
      .service('NewTagService', downgradeInjectable(NewTagService))
      .service('CookieService', downgradeInjectable(CookieService))
      .service('NewSensorNodeService', downgradeInjectable(NewSensorNodeService))
      .service('NewUserService', downgradeInjectable(NewUserService))
      .service('NewMappingService', downgradeInjectable(NewMappingService))
      .service('FloorplanService', downgradeInjectable(FloorplanService))
      .service('MappingService', ['MappingResource', 'MappingAudioUrlGenerator', 'SensorNodeService', MappingService])
      .service('SessionService', [
        '$window',
        '$location',
        'SimpleService',
        'AuthenticateBaseUrl',
        'LogoutBaseUrl',
        'PostLogoutRedirectBaseUrl',
        SessionService
      ])
      .service('SimpleService', ['$http', SimpleService])
      .service('CountryService', ['SimpleService', CountryService])
      .service('TimezoneService', ['SimpleService', TimezoneService])
      .service('GatewayService', ['SimpleService', 'GatewayResource', 'GenerateApiUrl', GatewayService])
      .service('GatewayHealthService', ['SimpleService', 'GenerateApiUrl', GatewayHealthService])
      .service('LoginMethodsService', ['SimpleService', 'LoginMethodsBaseUrl', LoginMethodsService])
      .service('NavigationService', ['$location', NavigationService])
      .service('OrBuildingsService', ['$http', OrBuildingsService])
      .service('OrMetricsService', ['$http', OrMetricsService])
      .service('BuildingService', [
        'EventBridge',
        'UserResource',
        'BuildingResource',
        'UserRoleResource',
        'UserInvitationResource',
        'BuildingNotificationResource',
        'NavigationService',
        UserService
      ])
      .service('RoleService', ['RoleResource', 'BuildingService', RoleService])
      .service('TokenService', ['TokenResource', 'SimpleService', 'GenerateApiUrl', TokenService])
      .service('MobileTokenService', ['MobileTokenResource', 'SimpleService', 'GenerateApiUrl', MobileTokenService])
      .service('DdlSettingService', ['SimpleService', 'DdlSettingResource', 'GenerateApiUrl', DdlSettingService])
      .service('DdlModeService', ['SimpleService', 'GenerateApiUrl', DdlModeService])
      .service('DdlNodeService', ['SimpleService', 'GenerateApiUrl', DdlNodeService])
      .service('DdlTagService', ['SimpleService', 'GenerateApiUrl', DdlTagService])
      .service('BleKeyService', ['BleKeyResource', 'SimpleService', 'GenerateApiUrl', BleKeyService])
      .service('BeaconSettingService', [
        'BeaconSettingResource',
        'SimpleService',
        'GenerateApiUrl',
        BeaconSettingService
      ])
      .service('LightingConfigurationService', [
        'LightingConfigurationResource',
        'SimpleService',
        'GenerateApiUrl',
        LightingConfigurationService
      ])
      .service('FloorService', [
        'FloorResource',
        'FloorplanUrlGenerator',
        'BuildingService',
        'NavigationService',
        'NewUserService',
        FloorService
      ])
      .service('TagService', [
        'BuildingService',
        'BuildingService',
        'TagResource',
        'MultiTagResource',
        'SensorNodeService',
        'NewSensorNodeService',
        TagService
      ])
      .service('EmDriverService', ['EmDriverResource', EmDriverService])
      .service('LuminaireDriverService', ['LuminaireDriverResource', LuminaireDriverService])
      .service('LampTypeService', [
        'LampTypeResource',
        'LampTypeResource',
        'LuminaireManufacturerResource',
        LampTypeService
      ])
      .service('SensorNodeService', [
        'FloorResource',
        'SensorNodeResource',
        'MappingResource',
        'FloorService',
        'BuildingService',
        'BuildingService',
        'EmergencyLightingResource',
        'EmergencyLightingTestResultResource',
        'BeaconSettingService',
        'LightingConfigurationService',
        'FeatureService',
        'EmDriverService',
        'LuminaireDriverService',
        'LampTypeService',
        'UIRefreshService',
        'DuplicateMappingResource',
        'SensorNodeBleResource',
        SensorNodeService
      ])
      .service('PassiveNodeService', ['PassiveNodeResource', PassiveNodeService])
      .service('SensorNodeChangeHistoryService', [SensorNodeChangeHistoryService])
      .service('ScheduleService', ['ScheduleResource', 'BuildingService', ScheduleService])
      .service('CircadianCurveService', ['CircadianCurveResource', 'BuildingService', CircadianCurveService])
      .service('ImageCachingService', [ImageCachingService])
      .service('UIRefreshService', [UIRefreshService])
      .service('OrPanelService', [OrPanelService])
      .service('SecurityService', ['BuildingService', '$routeParams', 'Debug', SecurityService])
      .service('FloorplanZoomService', [
        function () {
          return new Observable<ImageZoomService>(new ImageZoomService(1.0, 0.05, 3.0, 1.2));
        }
      ])
      .service('QueryService', ['BaseUrl', QueryService])
      .service('CopyToClipboardService', [CopyToClipboardService])
      .service('FilterService', [FilterService])
      .service('DropdownService', [DropdownService])
      .service('LightingGroupService', [
        '$http',
        'BuildingService',
        'FloorService',
        'GenerateApiUrl',
        LightingGroupService
      ])
      .service('LightingTestReportService', [
        'EmergencyLightingTestResultResource',
        'FloorService',
        'BuildingService',
        LightingTestReportService
      ])
      .service('LightingTestSchedulingService', [
        '$http',
        'FloorService',
        'GenerateApiUrl',
        LightingTestSchedulingService
      ])
      .service('BacnetAreaService', ['SimpleService', 'GatewayService', 'GenerateApiUrl', BacnetAreaService])
      .service('PropertiesService', ['SimpleService', 'EnvBaseUrl', PropertiesService]);
  }

  static configureDirectives(module: ng.IModule) {
    module
      .directive('orTint', [
        function () {
          return new OrTintDirective();
        }
      ])
      .directive('orSessionMenu', [
        function () {
          return new OrSessionMenuDirective();
        }
      ])
      .directive('orSiteMenu', [
        function () {
          return new OrSiteMenuDirective();
        }
      ])
      .directive('orUserMenu', [
        function () {
          return new OrUserMenuDirective();
        }
      ])
      .directive('orHeader', [
        function () {
          return new OrHeaderDirective();
        }
      ])
      .directive('orFooter', [
        function () {
          return new OrFooterDirective();
        }
      ])
      .directive('orFloorsMenu', [
        function () {
          return new OrFloorsMenuDirective();
        }
      ])
      .directive('orBuildingsMenu', [
        function () {
          return new OrBuildingsMenuDirective();
        }
      ])
      .directive('orBuildingsList', [
        function () {
          return new OrBuildingsListDirective();
        }
      ])
      .directive('orBannerNoBuildings', [
        function () {
          return new OrBannerNoBuildingsDirective();
        }
      ])
      .directive('orBannerNoGateways', [
        function () {
          return new OrBannerNoGatewaysDirective();
        }
      ])
      .directive('orBannerNoFloors', [
        function () {
          return new OrBannerNoFloorsDirective();
        }
      ])
      .directive('orPanelToggle', [
        function () {
          return new OrPanelToggleDirective();
        }
      ])
      .directive('orBannerNoSchedules', [
        function () {
          return new OrBannerNoSchedulesDirective();
        }
      ])
      .directive('orBannerNoCircadianCurves', [
        function () {
          return new OrBannerNoCircadianCurvesDirective();
        }
      ])
      .directive('orBannerNoRules', [
        function () {
          return new OrBannerNoRulesDirective();
        }
      ])
      .directive('orBannerNoGroups', [
        function () {
          return new OrBannerNoGroupsDirective();
        }
      ])
      .directive('orBannerNoAreas', [
        function () {
          return new OrBannerNoAreasDirective();
        }
      ])
      .directive('orPanel', [
        function () {
          return new OrPanelDirective();
        }
      ])
      .directive('orMetricWidget', [
        function () {
          return new OrMetricWidgetDirective();
        }
      ])
      .directive('orMetricSelector', [
        function () {
          return new OrMetricSelectorDirective();
        }
      ])
      .directive('orTags', [
        function () {
          return new OrTagsDirective();
        }
      ])
      .directive('orBeaconSettings', [
        function () {
          return new OrBeaconSettingsDirective();
        }
      ])
      .directive('orLightingConfiguration', [
        function () {
          return new OrLightingConfigurationDirective();
        }
      ])
      .directive('orGatewayTags', [
        function () {
          return new OrGatewayTagsDirective();
        }
      ])
      .directive('orFormEditTag', [
        function () {
          return new OrFormEditTagDirective();
        }
      ])
      .directive('orSensorNode', [
        function () {
          return new OrSensorNodeDirective();
        }
      ])
      .directive('orEmDriver', [
        function () {
          return new OrEmDriverDirective();
        }
      ])
      .directive('orLuminaireDriver', [
        function () {
          return new OrLuminaireDriverDirective();
        }
      ])
      .directive('orFormBuildingDetails', [
        function () {
          return new OrFormBuildingDetailsDirective();
        }
      ])
      .directive('orFormGatewayDetails', [
        function () {
          return new OrFormGatewayDetailsDirective();
        }
      ])
      .directive('orFormFloorDetails', [
        function () {
          return new OrFormFloorDetailsDirective();
        }
      ])
      .directive('orFormUserDetails', [
        function () {
          return new OrFormUserDetailsDirective();
        }
      ])
      .directive('orFormInviteUser', [
        function () {
          return new OrFormInviteUserDirective();
        }
      ])
      .directive('orFormRoleDetails', [
        function () {
          return new OrFormRoleDetailsDirective();
        }
      ])
      .directive('orFormTokenDetails', [
        function () {
          return new OrFormTokenDetailsDirective();
        }
      ])
      .directive('orFormMobileTokenDetails', [
        function () {
          return new OrFormMobileTokenDetailsDirective();
        }
      ])
      .directive('orFormBleKeyDetails', [
        function () {
          return new OrFormBleKeyDetailsDirective();
        }
      ])
      .directive('orFormWeekdaySchedule', [
        function () {
          return new OrFormWeekdayScheduleDirective();
        }
      ])
      .directive('orFormDatedSchedule', [
        function () {
          return new OrFormDatedScheduleDirective();
        }
      ])
      .directive('orFormCircadianCurve', [
        function () {
          return new OrFormCircadianCurveDirective();
        }
      ])
      .directive('orCircadianCurveList', [
        function () {
          return new OrCircadianCurveListDirective();
        }
      ])
      .directive('orCircadianCurveSummary', [
        function () {
          return new OrCircadianCurveSummaryDirective();
        }
      ])
      .directive('orFormRuleDetails', [
        function () {
          return new OrFormRuleDetailsDirective();
        }
      ])
      .directive('orSchedulesList', [
        function () {
          return new OrSchedulesListDirective();
        }
      ])
      .directive('orScheduleSummary', [
        function () {
          return new OrScheduleSummaryDirective();
        }
      ])
      .directive('orScheduleRuleTile', [
        function () {
          return new OrScheduleRuleTileDirective();
        }
      ])
      .directive('orCircadianCurveTile', [
        function () {
          return new OrCircadianCurveTileDirective();
        }
      ])
      .directive('orFormLampType', [
        function () {
          return new OrFormLampTypeDirective();
        }
      ])
      .directive('orFloorplanHeatmap', [
        function () {
          return new OrHeatmapDirective();
        }
      ])
      .directive('orFloorplan', [
        function () {
          return new OrFloorplanDirective();
        }
      ])
      .directive('orPubSubConnections', [
        function () {
          return new OrPubSubConnectionsDirective();
        }
      ])
      .directive('orMappedNodesCounter', [
        function () {
          return new OrMappedNodesCounterDirective();
        }
      ])
      .directive('orFailedTestsCounter', [
        function () {
          return new OrFailedTestsCounterDirective();
        }
      ])
      .directive('orNodesList', [
        function () {
          return new OrNodesListDirective();
        }
      ])
      .directive('orNodeTile', [
        function () {
          return new OrNodeTileDirective();
        }
      ])
      .directive('orLuminaireDriverTile', [
        function () {
          return new OrLuminaireDriverTileDirective();
        }
      ])
      .directive('orEmDriverTile', [
        function () {
          return new OrEmDriverTileDirective();
        }
      ])
      .directive('orCheckbox', [
        function () {
          return new OrCheckboxDirective();
        }
      ])
      .directive('orSearchInput', [
        function () {
          return new OrSearchInputDirective();
        }
      ])
      .directive('orFloorplanZoomControl', [
        function () {
          return new OrFloorplanZoomControlDirective();
        }
      ])
      .directive('orFloorplanActions', [
        function () {
          return new OrFloorplanActionsDirective();
        }
      ])
      .directive('orFloorplanActionsTray', [
        function () {
          return new OrFloorplanActionsTrayDirective();
        }
      ])
      .directive('orImgOnLoad', [
        function () {
          return new OrImgOnLoadDirective();
        }
      ])
      .directive('orImageUpload', [
        function () {
          return new OrImageUploadDirective();
        }
      ])
      .directive('orAudio', [
        function () {
          return new OrAudioDirective();
        }
      ])
      .directive('orTabs', [
        function () {
          return new OrTabsDirective();
        }
      ])
      .directive('orChart', [
        function () {
          return new OrChartDirective();
        }
      ])
      .directive('orMultiselect', [
        function () {
          return new OrMultiselectDirective();
        }
      ])
      .directive('convertToNumber', [
        function () {
          return new ConvertToNumberDirective();
        }
      ])
      .directive('orInlineTags', [
        function () {
          return new OrInlineTagsDirective();
        }
      ])
      .directive('orInlineFloors', [
        function () {
          return new OrInlineFloorsDirective();
        }
      ])
      .directive('emergencyLightingTestResults', [
        function () {
          return new EmergencyLightingTestResultsDirective();
        }
      ])
      .directive('orFormUserInfo', [
        function () {
          return new OrFormUserInfoDirective();
        }
      ])
      .directive('copyToClipboard', [
        'CopyToClipboardService',
        function (CopyToClipboardService) {
          return new CopyToClipboardDirective(CopyToClipboardService);
        }
      ])
      .directive('isAuthorized', [
        'SecurityService',
        'BuildingService',
        'FeatureService',
        'TenantService',
        function (securityService, buildingService, featureService, tenantService) {
          return IsAuthorizedDirective.create(securityService, buildingService, featureService, tenantService);
        }
      ])
      .directive('featureFlag', [
        'FeatureService',
        function (featureService) {
          return FeatureFlagDirective.create(featureService);
        }
      ])
      .directive('orLightGroupList', [
        function () {
          return new OrLightGroupListDirective();
        }
      ])
      .directive('orBacnetAreaTile', [
        function () {
          return new OrBacnetAreaTileDirective();
        }
      ])
      .directive('orBeaconsNodesList', [
        function () {
          return new OrBeaconsNodesListDirective();
        }
      ])
      .directive('orLightingConfigurationNodesList', [
        function () {
          return new OrLightingConfigurationNodesListDirective();
        }
      ])
      .directive('orBeaconsNodeTile', [
        function () {
          return new OrBeaconsNodeTileDirective();
        }
      ])
      .directive('orLightingConfigurationNodeTile', [
        function () {
          return new OrLightingConfigurationNodeTileDirective();
        }
      ])
      .directive('orLightingTestScheduleTile', [
        function () {
          return new OrLightingTestScheduleTileDirective();
        }
      ])
      .directive('orLightingTestReport', [
        function () {
          return new OrLightingTestReportDirective();
        }
      ])
      .directive('orSimpleWeekdayList', [
        function () {
          return new OrSimpleWeekdayListDirective();
        }
      ])
      .directive('orTimerangeWidget', [
        'PropertiesService',
        function (PropertiesService) {
          return new OrTimerangeWidgetDirective();
        }
      ])
      .directive('orDatepicker', [
        function () {
          return new OrDatepickerDirective();
        }
      ])
      .directive('orPrint', [
        function () {
          return new OrPrintDirective();
        }
      ]);
  }
}
