import { OrFormDatedScheduleController } from './OrFormDatedScheduleController';

export class OrFormDatedScheduleDirective {
  constructor() {
    return {
      bindToController: true,
      controller: ['$scope', 'ScheduleService', 'TagService', 'BuildingService', OrFormDatedScheduleController],
      controllerAs: 'schedule',
      replace: true,
      restrict: 'E',
      scope: {
        details: '=',
        onSaveNewSchedule: '&?',
        onDeleteSchedule: '&?',
        onClose: '&?',
        isNew: '=?'
      },
      template: require('raw-loader!./or-form-dated-schedule.html').default
    };
  }
}
