import { DisplayGrid, GridsterConfig, GridType } from 'angular-gridster2';
import { Tab } from '@components/tabs/tab.interface';
import { BuildingAuthorityType } from '@app/shared/models/building-authority-type';
import { SecurityUtils } from '@app/shared/utils/security.utils';

export const DashboardGridOptions: GridsterConfig = {
  gridType: GridType.Fit,
  displayGrid: DisplayGrid.OnDragAndResize,
  pushItems: false,
  swap: false,
  draggable: {
    delayStart: 0,
    enabled: false,
    ignoreContent: false,
    dragHandleClass: 'drag-handler',
    dropOverItems: false
  },
  resizable: {
    enabled: false
  },
  disableScrollHorizontal: true,
  disableScrollVertical: true,
  maxCols: 8,
  maxRows: 8,
  minItemRows: 2
};

export const DashboardTabs: Tab[] = [
  {
    name: 'General',
    path: '/buildings/{buildingId}/dashboard/general',
    dataCy: 'dashboard-general-tab',
    isAuthorized: SecurityUtils.hasBuildingAuthority(BuildingAuthorityType.VIEW_DASHBOARD_INSIGHTS)
  },
  {
    name: 'Energy',
    path: '/buildings/{buildingId}/dashboard/energy',
    dataCy: 'dashboard-energy-tab',
    isAuthorized: SecurityUtils.hasBuildingAuthority(BuildingAuthorityType.VIEW_DASHBOARD_INSIGHTS)
  },
  {
    name: 'Occupancy',
    path: '/buildings/{buildingId}/dashboard/occupancy',
    dataCy: 'dashboard-occupancy-tab',
    isAuthorized: SecurityUtils.hasBuildingAuthority(BuildingAuthorityType.VIEW_DASHBOARD_INSIGHTS)
  },
  {
    name: 'Maintenance',
    path: '/buildings/{buildingId}/dashboard/maintenance',
    dataCy: 'dashboard-maintenance-tab',
    isAuthorized: SecurityUtils.hasBuildingAuthority(BuildingAuthorityType.VIEW_DASHBOARD_INSIGHTS)
  },
  {
    name: 'Metrics',
    path: '/buildings/{buildingId}/dashboard/metrics',
    dataCy: 'dashboard-metrics-tab',
    isAuthorized: SecurityUtils.hasBuildingAuthority(BuildingAuthorityType.VIEW_DASHBOARD_ANALYTICS)
  }
];
