import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FloorplanTag } from '@app/shared/models/tag.interface';
import { TagType } from '@angularjs/or/api/building/Tag';
import { ColorUtils } from '@app/shared/utils/color.utils';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthorizationModule } from '@app/shared/directives/authorization.module';
import { SharedComponentsModule } from '@app/shared/shared-components.module';

@Component({
  standalone: true,
  selector: 'app-edit-tag-form',
  templateUrl: './edit-tag-form.component.html',
  imports: [SharedComponentsModule, AuthorizationModule],
  styleUrls: ['./edit-tag-form.component.scss']
})
export class EditTagFormComponent implements OnInit {
  tagForm: FormGroup;
  cloneOriginalTag: FloorplanTag;

  @Output() tagChanged = new EventEmitter<FloorplanTag>();
  @Output() tagReset = new EventEmitter<FloorplanTag>();
  @Output() tagUpdate = new EventEmitter<FloorplanTag>();

  @Input() tag: FloorplanTag;
  @Input() buildingId: number;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    if (this.tag) {
      this.cloneOriginalTag = JSON.parse(JSON.stringify(this.tag));
      if (this.tagForm) {
        this.tagForm.patchValue(this.tag);
      } else {
        this.tagForm = this.fb.group({
          name: [{ value: this.tag.name, disabled: this.tag.tagType === TagType.TENANT }],
          color: [this.tag.color]
        });
      }
    }
  }

  public isOfTenantType(tag: FloorplanTag): boolean {
    return tag.tagType === TagType.TENANT;
  }

  updateNodesForTag(tag: FloorplanTag): FloorplanTag {
    tag.name = this.tagForm.getRawValue().name;
    tag.color = this.tagForm.value.color;
    this.tagChanged.emit(tag);
    return tag;
  }

  cycleColor(tag: FloorplanTag): string {
    const color = ColorUtils.generateColor();
    this.tagForm.patchValue({ color });
    this.tagForm.markAsDirty();
    this.updateNodesForTag(tag);
    return color;
  }

  reset(tag: FloorplanTag): void {
    this.tagForm.reset(this.cloneOriginalTag);
    this.tagReset.emit(tag);
  }
  save(tag: FloorplanTag): void {
    this.updateNodesForTag(tag);
    this.tagUpdate.emit(tag);
  }
}
