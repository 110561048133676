import { Inject, Injectable } from '@angular/core';
import { Resource } from '@app/shared/resources/resource';
import { IResource } from '@app/shared/resources/resource.interface';
import { Token } from '@app/shared/models/token.interface';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Environment, environmentToken } from '@environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TokenResource extends Resource<Token, number> implements IResource<Token, number> {
  constructor(protected httpClient: HttpClient, @Inject(environmentToken) environment: Environment) {
    super(httpClient, `${environment.apiUrl + environment.apiPath}/token`);
  }

  getAllTokensForBuilding(buildingId: number, shouldIncludeDeactivated: boolean = false): Observable<Token[]> {
    return this.httpClient.get<any[]>(`${this.baseUrl}/${buildingId}`, {
      params: new HttpParams().set('shouldIncludeDeactivated', shouldIncludeDeactivated)
    });
  }

  create(buildingId: number, token: Token): Observable<Token> {
    return this.httpClient.post<Token>(`${this.baseUrl}/create/${buildingId}`, token);
  }

  update(buildingId: number, token: Token): Observable<Token> {
    return this.httpClient.put<Token>(`${this.baseUrl}/${buildingId}/${token.id}`, token);
  }

  deactivate(buildingId: number, tokenId: number): Observable<any> {
    return this.httpClient.put<any>(`${this.baseUrl}/deactivate/${buildingId}/${tokenId}`, {});
  }
}
