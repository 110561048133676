import { OrPubSubConnectionsController } from './OrPubSubConnectionsController';

export class OrPubSubConnectionsDirective {
  constructor() {
    return {
      bindToController: true,
      controller: ['$scope', OrPubSubConnectionsController],
      controllerAs: 'pubSubConnections',
      replace: true,
      restrict: 'E',
      scope: {
        nodes: '='
      },
      template: require('raw-loader!./or-pub-sub-connections.html').default
    };
  }
}
