<form *ngIf='floorForm' [formGroup]='floorForm'
      class='or-form or-form-floor-details'
      novalidate='novalidate'
      [attr.data-cy]="isNew ? 'new-floor-form' : 'existing-floor-form'"
>
  <div class='or-form-header' *ngIf='isNew'>
    <h2 class='or-form-title' data-cy='new-floor-title'>New Floor</h2>
  </div>
  <div class='or-form-body'>
    <ol class='or-form-items'>
      <li class='or-form-item or-form-floor-details-number'>
        <input
          class='or-input'
          type='text'
          maxlength='3'
          name='floorNumber'
          formControlName='floorNumber'
          data-cy='floor-number'
        />
      </li>
      <li class='or-form-item or-form-floor-details-name'>
        <input
          class='or-input'
          type='text'
          maxlength='64'
          name='name'
          formControlName='name'
          placeholder='Name'
          data-cy='floor-name'
        />
      </li>
      <li class='or-form-item or-form-floor-details-note'>
        <input
          class='or-input'
          type='text'
          maxlength='128'
          name='note'
          formControlName='note'
          placeholder='Description'
          data-cy='floor-description'
        />
      </li>
      <li class='or-form-item or-form-floor-details-floorplan'>
        <app-image-upload
          [width]="80"
          [height]="80"
          [imageUrl$]='imageUrl$'
          [error$]='error$'
          (onUpload)='onImageUpload($event)'
          [caption]="'Upload building photo'"
          data-cy='floor-plan-image'
        >
        </app-image-upload>
      </li>
      <li class='or-form-item'>
        <button
          *ngIf='!isNew'
          mat-raised-button
          class='or-button or-primary or-positive'
          type='button'
          (click)='updateFloor()'
          [disabled]='floorForm.invalid || floorForm.pristine || isBusy ? true : null'
          data-cy='update-floor'
        >
          <span class='or-icon or-small or-inverse or-icon-checkmark'></span><span class='or-button-label'>Update</span>
        </button>
      </li>
      <li class='or-form-item'>
        <button
          *ngIf='isNew'
          mat-raised-button
          class='or-button or-positive'
          type='button'
          (click)='addFloor()'
          data-cy='add-floor'
          [disabled]='floorForm.invalid || isBusy ? true : null'
        >
          <span class='or-icon or-small or-inverse or-icon-plus'></span><span class='or-button-label'>Add floor</span>
        </button>
      </li>
      <li class='or-form-item'>
        <button
          mat-raised-button
          class='or-button'
          type='button'
          color='accent'
          (click)='resetFloor()'
          [disabled]='floorForm.pristine || isBusy? true : null'
          data-cy='reset-floor'
        >
          <span class='or-icon or-small or-icon-reload'></span><span class='or-button-label'>Reset</span>
        </button>
      </li>
      <li class='or-form-item' is-authorized='is-authorized'>
        <button
          *ngIf='!isNew && isRemovable'
          mat-raised-button
          color='warn'
          class='or-button or-negative'
          type='button'
          (click)='deleteFloor()'
          [disabled]='isBusy ? true : null'
          data-cy='delete-floor'
        >
          <span class='or-icon or-tiny or-inverse or-icon-trash'></span><span class='or-button-label'>Delete</span>
        </button>
      </li>
    </ol>
  </div>
</form>
