import { HeatmapDataPoint, HeatmapRenderer } from './HeatmapRenderer';
import { Gradient } from './Gradient';

export class ColoringRenderer implements HeatmapRenderer {
  private alphaCanvas: HTMLCanvasElement;
  private alphaCtx: CanvasRenderingContext2D;
  private colorPalette: Uint8ClampedArray;

  constructor(
    private underlying: HeatmapRenderer,
    private minOpacity: number = 0.0,
    private maxOpacity: number = 1.0,
    private gradient: Gradient
  ) {
    this.alphaCanvas = document.createElement('canvas');
    this.alphaCtx = this.alphaCanvas.getContext('2d', {
      willReadFrequently: true,
      alpha: true
    });
    this.colorPalette = ColoringRenderer.createColorPalette(gradient);
  }

  private static createColorPalette(inputGradient: Gradient) {
    const paletteCanvas = document.createElement('canvas');
    const paletteCtx = paletteCanvas.getContext('2d');

    paletteCanvas.width = 256;
    paletteCanvas.height = 1;

    const gradient = paletteCtx.createLinearGradient(0, 0, 256, 1);
    for (const stop of inputGradient.colorStops()) {
      gradient.addColorStop(stop[0], stop[1]);
    }

    paletteCtx.fillStyle = gradient;
    paletteCtx.fillRect(0, 0, 256, 1);

    return paletteCtx.getImageData(0, 0, 256, 1).data;
  }

  render(
    points: HeatmapDataPoint[],
    canvas: HTMLCanvasElement,
    ctx: CanvasRenderingContext2D,
    max: number,
    width: number,
    height: number
  ) {
    this.alphaCanvas.width = width;
    this.alphaCanvas.height = height;
    this.underlying.render(
      points,
      this.alphaCanvas,
      this.alphaCtx,
      max,
      width,
      height
    );

    const imageData = this.alphaCtx.getImageData(0, 0, width, height);
    const imgDataArray = imageData.data;
    const len = imgDataArray.length;

    for (let i = 3; i < len; i += 4) {
      const alpha = imgDataArray[i];
      const alphaFraction = alpha / 255.0;
      const paletteOffset = alpha * 4;

      if (!paletteOffset) {
        continue;
      }

      const finalAlpha =
        (this.minOpacity +
          (this.maxOpacity - this.minOpacity) * alphaFraction) *
        255.0;
      imgDataArray[i - 3] = this.colorPalette[paletteOffset];
      imgDataArray[i - 2] = this.colorPalette[paletteOffset + 1];
      imgDataArray[i - 1] = this.colorPalette[paletteOffset + 2];
      imgDataArray[i] = finalAlpha;
    }

    ctx.putImageData(imageData, 0, 0);
  }
}
