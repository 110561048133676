import { Injectable } from '@angular/core';
import { TenantResource } from '@app/shared/resources/tenant.resource';
import { ITenant } from '@app/shared/models/tenant.interface';
import { Observable } from 'rxjs';
import { Pageable } from '@app/shared/models/Pageable.interface';

@Injectable({
  providedIn: 'root'
})
export class TenantService {
  constructor(private tenantResource: TenantResource) {}

  public getTenants(buildingId: number): Observable<Pageable<ITenant>> {
    return this.tenantResource.getTenants(buildingId);
  }

  public getAllTenants(buildingId: number): Observable<ITenant[]> {
    return this.tenantResource.getAllTenants(buildingId);
  }

  public addTenant(tenant: ITenant): Observable<ITenant> {
    return this.tenantResource.addTenant(tenant);
  }

  public updateTenant(tenant: ITenant): Observable<ITenant> {
    return this.tenantResource.updateTenant(tenant);
  }

  public deleteUsersFromTenant(tenantId: number): Observable<{}> {
    return this.tenantResource.deleteUsersFromTenant(tenantId);
  }

  public getUserTenants(buildingId: number): Observable<ITenant[]> {
    return this.tenantResource.getUserTenants(buildingId);
  }
}
