import { RuleType } from '@angularjs/or/data/RuleType';
import { CircadianCurveService } from '@angularjs/or/services/CircadianCurveService';
import { CircadianCurve } from '@angularjs/or/data/CircadianCurve';

export class OrCircadianCurveSummaryController {
  public details: CircadianCurve;
  public curvesList: CircadianCurve[];
  public selectedRuleType: RuleType;
  public hours: string[] = [];
  public isCollapsed = true;
  public schedulesList;
  public onSaveNewCurve: () => {};
  public onDeleteCurve: () => {};
  public onClose: () => {};
  public isNew: boolean;
  public isControlActionsMenuActive: boolean;
  public isBusy: boolean;
  public status: string;
  public promptToDisable = false;

  constructor(
    private $scope: ng.IScope,
    private circadianCurveService: CircadianCurveService,
    private $timeout: ng.ITimeoutService
  ) {}

  public $onInit() {
    if (!this.details || this.isNew) {
      this.details = new CircadianCurve();
      this.isNew = true;
    }

    this.$scope.$watch('circadianCurveSummary.isBusy', () =>
      this.updateStatus()
    );
    this.$scope.$watch('circadianCurveSummary.details.active', () =>
      this.updateStatus()
    );
    this.$scope.$watch('circadianCurveSummary.details.enabled', () =>
      this.updateStatus()
    );
  }

  private updateStatus() {
    this.isCollapsed = true;
    if (this.isBusy) {
      this.status = 'Pending';
    } else {
      if (this.details.active) {
        this.status = 'Active';
      } else if (this.details.enabled) {
        this.status = 'Inactive';
      } else {
        this.status = 'Paused';
      }
    }
  }

  toggleControlActionsMenu(isActive) {
    this.isControlActionsMenuActive =
      typeof isActive === 'boolean'
        ? isActive
        : !this.isControlActionsMenuActive;
  }

  getActiveActionIndex(): number {
    if (this.details.enabled) {
      return 0;
    }
    return 1;
  }

  getControlActionsClass(): string {
    return 'action-' + this.getActiveActionIndex();
  }

  getControlActionClass(index: number): string {
    return this.isControlActionsMenuActive ||
      this.getActiveActionIndex() == index
      ? 'active'
      : '';
  }

  public toggleCollapse() {
    this.isCollapsed = this.details.active ? true : !this.isCollapsed;
    if (this.details.active && !this.isBusy) {
      this.promptToDisable = true;
    }
  }

  public toggleEnabledState(setEnabled) {
    if (!setEnabled) {
      this.promptToDisable = false;
    }
    const curve = CircadianCurve.clone(this.details);
    curve.enabled =
      typeof setEnabled === 'boolean' ? setEnabled : !curve.enabled;

    if (!curve.enabled) {
      curve.active = false;
    }
    this.updateCurve(curve);
    this.isControlActionsMenuActive = false;
  }

  private updateCurve(curve: CircadianCurve) {
    this.isBusy = true;
    this.promptToDisable = false;
    this.circadianCurveService.updateCurve(curve).then((curve) => {
      this.$scope.$apply(() => {
        this.details = CircadianCurve.clone(curve);
        this.isBusy = false;
      });
    });
  }

  public getPauseLabel(): string {
    if (this.isNew) {
      return null;
    }
    if (this.details.enabled) {
      return 'Disable Schedule';
    } else {
      return 'Enable Schedule';
    }
  }

  public getPauseClass(): string {
    if (this.isNew) {
      return null;
    }
    if (this.details.enabled) {
      return 'or-icon-minus';
    } else {
      return 'or-icon-plus';
    }
  }

  public getSummaryStateClass(): string {
    let style = '';
    if (this.details.active) {
      style = 'or-schedule-summary-header-running';
    }
    return style;
  }

  public getStatusLabelClass(): string {
    if (this.isBusy) {
      return 'or-pulsate';
    } else {
      return '';
    }
  }
}
