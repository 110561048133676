// import * as ng from "angular";
import { OrBacnetAreaTileController } from './OrBacnetAreaTileController';

export class OrBacnetAreaTileDirective {
  constructor() {
    return {
      bindToController: true,
      controller: [
        '$routeParams',
        '$scope',
        '$location',
        '$timeout',
        'BacnetAreaService',
        'BuildingService',
        'FloorService',
        'NavigationService',
        'GatewayService',
        'IntegrationsAreasUrlGenerator',
        OrBacnetAreaTileController
      ],
      controllerAs: 'bacnetAreaDetails',
      replace: true,
      restrict: 'E',
      scope: {
        enableLabels: '=',
        isNew: '=',
        area: '=',
        gateways: '=',
        onLoadAreas: '&'
      },
      template: require('raw-loader!./or-bacnet-area-tile.html').default
    };
  }
}
