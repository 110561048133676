import { Resource } from '../Resource';
import { SensorNode } from '../../api/building/SensorNode';
import { SensorNodeIdsBatch } from '../../api/building/SensorNodeBatch';
import { PromiseRejectionHandler } from '../../promises/PromiseRejectionHandler';
import { PromiseUtils } from '../promises/PromiseUtils';
import { IResource } from '../../api/IResource';

export class SensorNodeResource
  extends Resource<SensorNode, number>
  implements IResource<SensorNode, number>
{
  constructor(
    $http: ng.IHttpService,
    baseUrl: string,
    rejectionHandler: PromiseRejectionHandler,
    baseUrlGenerator: (id: number) => string
  ) {
    super($http, baseUrl, rejectionHandler, baseUrlGenerator);
  }

  public retrieveAllByParentId(floorId: number): Promise<SensorNode[]> {
    return PromiseUtils.wrapSingle(
      this.$http.get(this.baseUrl + '/floor/' + floorId),
      this.rejectionHandler
    );
  }

  public retrieveNodesById(ids: number[]): Promise<SensorNode[]> {
    return PromiseUtils.wrapSingle(
        this.$http.post(this.baseUrl + '/by_ids', ids),
        this.rejectionHandler
    );
  }

  public deleteMany(ids: number[]): Promise<{}> {
    return PromiseUtils.wrapSingle(
      this.$http.delete(
        this.baseUrl,
        PromiseUtils.wrapData(new SensorNodeIdsBatch(ids))
      ),
      this.rejectionHandler
    );
  }

  public updateCurrent(node: SensorNode): Promise<{}[]> {
    return PromiseUtils.wrapSingle(
      this.$http.put(this.baseUrl + '/' + node.id, node),
      this.rejectionHandler
    );
  }

  public getTagsForNodes(nodes: SensorNode[]): Promise<{}> {
    const httpParams = [];
    nodes.forEach((node) => {
      httpParams.push(node.id);
    });
    return PromiseUtils.wrapSingle(
      this.$http.post(this.baseUrl + '/tagsForNodes', httpParams),
      this.rejectionHandler
    );
  }

  public clearDriversForNodes(ids: number[]): Promise<number> {
    return PromiseUtils.wrapSingle(
      this.$http.put(
        this.baseUrl + '/clear-drivers',
        new SensorNodeIdsBatch(ids)
      ),
      this.rejectionHandler
    );
  }
}
