import { OrCircadianCurveListController } from './OrCircadianCurveListController';

export class OrCircadianCurveListDirective {
  constructor() {
    return {
      bindToController: true,
      controller: ['$scope', 'CircadianCurveService', OrCircadianCurveListController],
      controllerAs: 'circadianCurveList',
      replace: true,
      restrict: 'E',
      scope: {
        isBusy: '='
      },
      template: require('raw-loader!./or-circadian-curve-list.html').default
    };
  }
}
