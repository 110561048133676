import { Inject, Injectable } from '@angular/core';
import { Resource } from '@app/shared/resources/resource';
import { HttpClient } from '@angular/common/http';
import { Environment, environmentToken } from '@environment';
import { Observable } from 'rxjs';
import { CalendarEvent } from 'angular-calendar';
import {IEmergencyTest} from "@app/shared/resources/emergency-lighting-test.resource";

@Injectable({
    providedIn: 'root'
})
export class EmergencyCalendarResource extends Resource<IEmergencyCalendarEvent, number>{
    constructor(private http: HttpClient, @Inject(environmentToken) private environment: Environment) {
        super(http, `${environment.apiUrl}${environment.apiPath}/emergency-lighting-calendar`);
    }

    getScheduledRunsForBuildingBetween(buildingIds: number[], start: number, end: number): Observable<IEmergencyScheduleRun[]> {
        return this.http.post<IEmergencyScheduleRun[]>(`${this.baseUrl}/runs-between`, {
            buildingIds,
            start,
            end
        });
    }

    getDistinctBuildingIdsWithSchedules(): Observable<number[]> {
        return this.http.get<number[]>(`${this.baseUrl}/distinct-building-ids-with-schedules`);
    }
}

export interface IEmergencyScheduleRun {
    buildingId: number;
    buildingName?: string;
    scheduleId: number;
    scheduleName: string;
    startTime: number;
    estimatedEndTime: number;
    actualEndTime: number;
    testsType: string;
    elmtTests?: IEmergencyTest[];
}

export interface IEmergencyCalendarEvent extends Partial<CalendarEvent> {
    start: Date;
    end: Date;
    title: string;
    buildingId: number;
    scheduleId: number;
}

export class EmergencyCalendarEvent implements IEmergencyCalendarEvent {
    end: Date;
    start: Date;
    title: string;
    buildingId: number;
    scheduleId: number;

    constructor(emergencyCalendarEvent: IEmergencyCalendarEvent) {
        const { end, start, title, buildingId, scheduleId } = emergencyCalendarEvent;
        this.end = end;
        this.start = start;
        this.title = title;
        this.buildingId = buildingId;
        this.scheduleId = scheduleId;
    }

    static from(emergencyScheduleRun: IEmergencyScheduleRun): IEmergencyCalendarEvent {
        return new EmergencyCalendarEvent({
            start: new Date(emergencyScheduleRun.startTime * 1000),
            end: new Date(emergencyScheduleRun.estimatedEndTime * 1000),
            title: `${emergencyScheduleRun.buildingName} - ${emergencyScheduleRun.scheduleName} (${emergencyScheduleRun.testsType.charAt(0)})`,
            buildingId: emergencyScheduleRun.buildingId,
            scheduleId: emergencyScheduleRun.scheduleId
        });
    }
}
