import { IUserService } from '@angularjs/or/services/IUserService';
import { User } from '@angularjs/or/api/auth/User';
import { IBuildingService } from '@angularjs/or/services/IBuildingService';
import { Address, Building } from '@angularjs/or/api/building/Building';
import { BuildingAuthorization } from '@app/shared/models/building-authorization.interface';
import { ArrayUtils } from '@angularjs/or/util/ArrayUtils';
import { BuildingAuthorityType } from '@app/shared/models/building-authority-type';
import { Map } from '@angularjs/or/util/Map';
import { ImageCachingService } from '@angularjs/or/services/ImageCachingService';
import {
  Entity,
  UIRefreshService
} from '@angularjs/or/services/UIRefreshService';
import { NavigationService } from '@app/shared/services/navigation/navigation.service';
import { Observable } from '@angularjs/or/util/Observable';
import { FloorService } from '@angularjs/or/services/FloorService';
import { IDropdown } from '@angularjs/or/services/IDropdown';
import { DropdownService } from '@angularjs/or/services/DropdownService';
import { ObservableBool } from '@angularjs/or/util/ObservableBool';
import { Floor } from '@app/shared/models/floor.interface';

export class OrBuildingsMenuController implements IDropdown {
  private isActiveObservable: Observable<ObservableBool>;
  private thumbnailImage: string;
  public name: string;
  private building: Building;
  public buildingAuthorizations: BuildingAuthorization[];
  public user: User;

  constructor(
    private $scope: ng.IScope,
    private buildingService: IBuildingService,
    private floorService: FloorService,
    private userService: IUserService,
    private thumbnailUrlGenerator: (building: Building) => string,
    private buildingUrlGenerator: (
      buildingId: number,
      floorId: number
    ) => string,
    private buildingAdministrationUrlGenerator: (buildingId: number) => string,
    private imageCachingService: ImageCachingService,
    private uiRefreshService: UIRefreshService,
    private navigationService: NavigationService,
    private dropdownService: DropdownService
  ) {}

  public $onInit() {
    this.refresh();
    this.uiRefreshService.onChange(Entity.USER, () => {
      this.refresh();
    });
    this.uiRefreshService.onChange(Entity.BUILDING, () => {
      this.refresh();
    });
    this.isActiveObservable = new Observable<ObservableBool>(
      new ObservableBool(false)
    );
    this.dropdownService.add(this);
  }

  public refresh() {
    this.userService.getCurrentUser().then((user: User) => {
      this.user = user;
      this.buildingService.getCurrentBuilding().then((building: Building) => {
        this.init(user, building);
      });
    });
  }

  navigateToBuilding(building: Building, floor: Floor) {
    this.toggle();
    this.navigationService.buildingId = building.id;
    this.navigationService.floorId = floor.id;
    this.navigationService.navigateToActiveSection(
      this.user,
      building?.id,
      floor?.id
    );
  }

  navigateToBuildingAdmin(building: Building) {
    this.toggle();
    this.navigationService.buildingId = building.id;
    this.navigationService.floorId = building.floors[0]
      ? building.floors[0].id
      : null;
    this.navigationService.navigateToSection(
      this.navigationService.getSectionById('building'),
      building.id
    );
  }

  produceBuildingAdministrationUrl(buildingId: number) {
    return this.buildingAdministrationUrlGenerator(buildingId);
  }

  produceBuildingThumbnailImageStyle(building: Building): Map<string> {
    if (!building) {
      return;
    }
    const style: Map<string> = {};
    const url = this.thumbnailUrlGenerator(building);
    // url = this.imageCachingService.updateUrl(url);
    style['background-image'] = url ? 'url(' + url + ')' : 'none';
    return style;
  }

  public canManageBuilding(
    buildingAuthorization: BuildingAuthorization
  ): boolean {
    for (const role of buildingAuthorization.roles) {
      if (
        ArrayUtils.contains(
          role.authorityTypes,
          BuildingAuthorityType.MANAGE_BUILDING
        )
      ) {
        return true;
      }
    }
    return false;
  }

  public getStringFromAddress(address: Address): string {
    const fields = [];
    let addressText = '';
    if (address.addressLine1) {
      fields.push(address.addressLine1);
    }
    if (address.addressLine2) {
      fields.push(address.addressLine2);
    }
    if (address.addressLine3) {
      fields.push(address.addressLine3);
    }
    if (address.postcode) {
      fields.push(address.postcode);
    }
    if (address.country) {
      fields.push(address.country);
    }

    for (const i in fields) {
      addressText += fields[i];
      if ((i as any as number) < fields.length - 1) {
        addressText += ' ';
      }
    }

    return addressText;
  }

  toggle() {
    this.isActiveObservable.change((observableBool: ObservableBool) =>
      observableBool.toggle()
    );
    if (this.isActive()) {
      this.dropdownService.notify(this.getDropdownName());
    }
  }

  isActive() {
    return this.isActiveObservable.value().getBool();
  }

  collapse() {
    this.isActiveObservable.change((val) => val.setBool(false));
  }

  collapseWithApply() {
    this.$scope.$apply(() => {
      this.collapse();
    });
  }

  getDropdownName() {
    return 'buildings';
  }

  init(user: User, building: Building) {
    this.$scope.$apply(() => {
      this.buildingAuthorizations = user.buildingAuthorizations;
      this.thumbnailImage = building.thumbnailImage;
      this.name = building.name;
      this.building = building;
      this.isActiveObservable.change((val) => val.setBool(false));
    });
  }
}
