import { Component, OnInit } from '@angular/core';
import { combineLatestWith, Observable } from 'rxjs';
import { Building } from '@app/shared/models/building.interface';
import { HeaderService } from '@app/shared/services/header.service';
import { UserService } from '@app/shared/services/user/user.service';
import { DisplayTags } from '@app/shared/models/tag.interface';
import { TagService } from '@app/shared/services/tag.service';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { EmergencyLightingScheduleService } from '@services/emergency-lighting/emergency-lighting-schedule.service';

@Component({
  selector: 'app-emergency-lighting-schedules',
  templateUrl: './emergency-lighting-schedules.component.html',
  styleUrls: ['./emergency-lighting-schedules.component.scss']
})
export class EmergencyLightingSchedulesComponent implements OnInit {
  public building$: Observable<Building>;
  public tags$: Observable<DisplayTags>;
  constructor(
    private readonly header: HeaderService,
    private readonly user: UserService,
    private tagService: TagService,
    private route: ActivatedRoute,
    private scheduleService: EmergencyLightingScheduleService
  ) {}

  ngOnInit(): void {
    this.header.hideFloorsMenu();
    this.setBuildingId();
  }

  setBuildingId(): void {
    // FIXME: calling GET building endpoint because of router.navigate in global elmt calendar
    //  as it removes the params object of ActivatedRoute
    this.route.params.subscribe((paramMap) => {
      const buildingId = paramMap.buildingId;
      this.building$ = this.user.getBuilding(buildingId);
      this.scheduleService.fetchSchedules(buildingId);
      this.tags$ = this.tagService.getTagsForBuildingId(buildingId, true, true).pipe(
        combineLatestWith(this.tagService.getTagsForBuildingId(buildingId, false, true)),
        map((tags) => {
          return {
            displayTags: tags[1],
            tags: tags[0]
          };
        })
      );
    });
  }
}
