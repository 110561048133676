import * as angular from 'angular'; // Automatically added
import { ISchedulesParamsService } from './SchedulesConfig';
import { IBuildingService } from '@angularjs/or/services/IBuildingService';
import { NavigationService } from '@app/shared/services/navigation/navigation.service';
import { NavigationSectionInfo } from '@app/shared/services/navigation/navigation-section-info';
import { Building } from '@angularjs/or/api/building/Building';
import { TimeUtils } from '@angularjs/or/util/TimeUtils';
import { ScheduleService } from '@angularjs/or/services/ScheduleService';

export class SchedulesController {
  public isBusy: boolean;
  public disableSiteMenu = false;
  public disableUserMenu = false;
  public disableFloorsMenu = true;
  public disableBuildingsMenu = false;
  public disableSessionMenu = false;
  public buildingId: number;

  constructor(
    private route: ISchedulesParamsService,
    private $scope: angular.IScope,
    private buildingService: IBuildingService,
    private scheduleService: ScheduleService,
    private navigationService: NavigationService,
    private sectionInfo: NavigationSectionInfo
  ) {
    this.isBusy = true;
    // TODO Move the call to get active section to route controller for other pages as well.
    const sectionId = navigationService.getActiveSection().info.Id;
    this.initializeValues();
    buildingService.getCurrentBuilding().then((building) => {
      this.initNavigation(building);
      this.init();
    });
  }

  private initializeValues(): void {
    this.buildingId = parseInt(this.route.buildingId, 10);
    this.buildingService.setCurrentBuildingId(this.buildingId);
  }

  private initNavigation(building: Building): void {
    const routeParams: { [p: string]: any } = {};
    if (building != null) {
      routeParams.buildingId = building.id;
    }
    if (building.floors != null && building.floors[0] != null) {
      routeParams.floorId = building.floors[0].id;
    }
    this.navigationService.applyContext(window.location.href, routeParams);
  }

  init(): void {
    this.$scope.$apply(() => {
      this.isBusy = false;
    });
  }

  getTimes(): string[] {
    return TimeUtils.getIntervalsInDay();
  }
}
