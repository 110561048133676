import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Building } from '@app/shared/models/building.interface';
import { IEmergencyLightingSchedule } from '@app/shared/models/emergency-lighting-schedule.interface';
import { EmergencyLightingScheduleService } from '@app/shared/services/emergency-lighting/emergency-lighting-schedule.service';
import { DisplayTags } from '@app/shared/models/tag.interface';
import { Observable, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { HotToastService } from '@ngneat/hot-toast';

@Component({
  selector: 'app-emergency-lighting-schedules-list',
  templateUrl: './emergency-lighting-schedules-list.component.html',
  styleUrls: ['./emergency-lighting-schedules-list.component.scss']
})
export class EmergencyLightingSchedulesListComponent implements OnInit {
  @Input() public building$: Observable<Building>;
  @Input() public tags$: Observable<DisplayTags>;
  public schedules$: Observable<IEmergencyLightingSchedule[]> = of([]);
  public newlyAddedScheduleIds: Set<number> = new Set();
  public showNewForm = false;
  public shouldShowDisabledSchedules = false;

  @ViewChild('loadingTemplate', { static: true })
  loadingTemplate: TemplateRef<any>;
  @ViewChild('successTemplate', { static: true })
  successTemplate: TemplateRef<any>;
  @ViewChild('errorTemplate', { static: true })
  errorTemplate: TemplateRef<any>;

  constructor(
    private scheduleService: EmergencyLightingScheduleService,
    private route: ActivatedRoute,
    private router: Router,
    private toast: HotToastService
  ) {}

  ngOnInit(): void {
    this.loadSchedules();
  }

  private loadSchedules(): void {
    this.schedules$ = this.scheduleService.buildingELMTSchedules$.pipe(
      this.toast.observe({
        loading: this.loadingTemplate,
        success: this.successTemplate,
        error: this.errorTemplate
      })
    );
  }

  private scrollWithinView(fragment: string): void {
    this.router
      .navigate([], {
        relativeTo: this.route,
        fragment
      })
      .catch((err) => console.error(err));
  }

  public createNew(): void {
    this.showNewForm = true;
    this.scrollWithinView('schedule-new');
  }

  public closeNew(): void {
    this.showNewForm = false;
    this.scrollWithinView('');
  }

  public refreshList(): void {
    this.showNewForm = false;
    this.loadSchedules();
  }

  onNewScheduleCreated(scheduleId: number): void {
    this.newlyAddedScheduleIds.add(scheduleId);
  }

  toggleShowDisabledSchedules(): void {
    this.shouldShowDisabledSchedules = !this.shouldShowDisabledSchedules;
  }
}
