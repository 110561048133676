import { FloorService } from '@angularjs/or/services/FloorService';
import { IBuildingService } from '@angularjs/or/services/IBuildingService';
import { DataType } from '@angularjs/or/data/SensorNodeDataType';
import { IDataTypeContext, ILocationContext, ITagContext } from '@angularjs/or/api/query/outline/context/IContext';
import { NavigationService } from '@app/shared/services/navigation/navigation.service';
import { NavigationSectionInfo } from '@app/shared/services/navigation/navigation-section-info';
import { IQueryOutlineBuilder } from '@angularjs/or/api/query/outline/IQueryOutlineBuilder';
import { IBuildingFloorRouteService } from '@angularjs/or/angular/routing/IFloorRouteService';
import { IObservable, IObservableModifiable } from '@angularjs/or/util/IObservable';
import { HeatmapJsGradient } from '@angularjs/or/view/heatmap/renderer/Gradient';
import { Map } from '@angularjs/or/util/Map';
import { SensorNodeService } from '@angularjs/or/services/SensorNodeService';
import { Subject } from 'rxjs';
import { Tag } from '@angularjs/or/api/building/Tag';
import { TagChangeEvent } from '@components/tags/tags.component';
import { QueryExecutor } from '@angularjs/or/angular/QueryExecutor';
import { Building } from '@angularjs/or/api/building/Building';
import { SecurityService } from '@angularjs/or/angular/services/SecurityService';

type ViewMode = 'HEATMAP' | 'CHART';

export class AnalyticsController {
  private isBusy = true;

  public buildingId: number;
  public floorId: number;
  public dataType: DataType;
  public building: Building;

  public areNodesEnabled = false;
  public areDriversEnabled = false;
  public normalizeScale = false;
  public markFaultyNodes = false;
  public areUnmappedNodesEnabled = false;
  public showNodeValues = true;
  public isMetricsPanelActive = true;
  public isFloorplanPanelActive = true;
  public isTagsPanelActive = true;
  public viewMode: ViewMode = 'HEATMAP' as ViewMode;
  public accumulateSelection = false;

  private gradient = HeatmapJsGradient.DEFAULT_GRADIENT;
  public gradientStyle: Map<string>;
  public tags$ = new Subject<Tag[]>();

  constructor(
    private $routeParams: IBuildingFloorRouteService,
    private scope: angular.IScope,
    private outlineBuilder: IObservable<IQueryOutlineBuilder>,
    private locationContext: IObservableModifiable<ILocationContext>,
    private dataTypeContext: IObservableModifiable<IDataTypeContext>,
    private floorService: FloorService,
    private buildingService: IBuildingService,
    private navigationService: NavigationService,
    private mysectionInfo: NavigationSectionInfo,
    private nodeService: SensorNodeService,
    private tagContext: IObservableModifiable<ITagContext>,
    private queryExecutor: QueryExecutor,
    private securityService: SecurityService
  ) {
    this.initializeValues();
    this.initNavigation();

    this.buildingService.getCurrentBuilding().then((currentBuilding: Building) => {
      this.building = currentBuilding;
      this.scope.$apply(() => (this.isBusy = false));
    });
    this.scope.$on('$destroy', () => this.destroyController());
    this.gradientStyle = this.produceGradientStyle();
  }

  private initNavigation(): void {
    this.securityService.isAuthorizedForBuildingId('ANALYTICS', this.buildingId).then((isAuthorized) => {
      if (!isAuthorized) {
        window.location.href = '/buildings';
      } else {
        this.navigationService.applyContext(window.location.href, {
          buildingId: this.buildingId,
          floorId: this.floorId
        });
      }
    });
  }

  private initializeValues(): void {
    this.buildingId = parseInt(this.$routeParams.buildingId);
    this.floorId = parseInt(this.$routeParams.floorId);

    this.outlineBuilder.deregisterAll();

    this.dataType = this.dataTypeContext.value().dataType;
    this.locationContext.change((value) => {
      value.buildingId = this.buildingId;
      value.floorIds = [this.floorId];
    });

    this.floorService.setCurrentFloorId(this.floorId);
    this.buildingService.setCurrentBuildingId(this.buildingId);

    this.addNodeChangeSubscription();
  }

  private destroyController(): void {
    this.outlineBuilder.deregisterAll();
  }
  public getGradientStyle(): Map<string> {
    return this.gradientStyle;
  }

  public produceGradientStyle(): Map<string> {
    const style: Map<string> = {};
    const stops = [];
    let idx;
    // Start with first colour in Gradient
    let colour = this.gradient[Object.keys(this.gradient).sort()[0]];
    for (let i = 0; i < 100; i++) {
      idx = i / 100;
      colour = this.getColourForIndex(idx);
      if (i > 75) {
        stops.push(colour);
      } else {
        stops.push(
          colour +
            Math.min(Math.round(i * 1.8), 255)
              .toString(16)
              .padStart(2, '0')
        ); // Add opacity based on position in index
      }
    }
    style['background-image'] = 'linear-gradient(' + stops.join(', ') + ')';
    return style;
  }

  private getColourForIndex(index: number): string {
    let colour = null;
    if (this.gradient[index] != null) {
      colour = this.gradient[index];
    } else {
      let adjust;
      let nIdx;
      // Go plus or minus 20 to find the nearest colour in gradient
      for (let i = 1; index + i < 20; i++) {
        adjust = i / 100;
        nIdx = Math.round((index + adjust + Number.EPSILON) * 100) / 100;
        colour = this.gradient[nIdx];
        if (colour == null) {
          // If not found plus check minus
          nIdx = Math.round((index - adjust + Number.EPSILON) * 100) / 100;
          colour = this.gradient[nIdx];
        }
        // Stop loop early if found
        if (colour != null) {
          break;
        }
      }
      // If no colour within 20 steps get first colour
      if (colour == null) {
        colour = this.gradient[Object.keys(this.gradient).sort()[0]];
      }
    }
    return colour;
  }

  /* === Tag related changes === */
  addNodeChangeSubscription(): void {
    this.nodeService.refreshNodes.subscribe((nodes) => {
      const tagMap = {};
      nodes?.forEach((node) => {
        if (node.tags) {
          node.tags.forEach((nodeTag) => {
            tagMap[nodeTag.id] = nodeTag;
          });
        }
      });
      const tagArr = [];
      for (const p in tagMap) {
        tagArr.push(tagMap[p]);
      }
      this.tagContext.change((value) => {
        tagArr.forEach((tag) => (tag.isActive = value.tagIds.indexOf(tag.id) >= 0));
      });
      this.tags$.next(tagArr);
    });
  }

  tagToggle(event: TagChangeEvent): void {
    const tag = event.tag;
    tag.isActive = event.isActive;
    this.tagContext.change((value) => {
      if (tag.isActive) {
        if (value.tagIds.indexOf(tag.id) < 0) {
          const values = value.tagIds;
          values.push(tag.id);
          value.tagIds = values;
        }
      } else {
        const values = value.tagIds;
        values.splice(value.tagIds.indexOf(tag.id), 1);
        value.tagIds = values;
      }
    });
  }
  /* === Tag related changes ends === */
}
