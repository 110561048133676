import { inject, NgModule } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  createUrlTreeFromSnapshot,
  Router,
  RouterModule,
  RouterStateSnapshot,
  Routes,
  UrlHandlingStrategy,
  UrlTree
} from '@angular/router';
import { AdministrationBuildingComponent } from './administration/building/building.component';
import { AdministrationGatewaysComponent } from './administration/gateway/gateway.component';
import { AdministrationComponent } from './administration/layout/administration.component';
import { CustomHandlingStrategy } from './custom-handling.strategy';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { AppNewBuildingComponent } from '@app/buildings/new/new-building.component';
import { CircadianCurveComponent } from '@app/circadian-curve/circadian-curve.component';
import { AdministrationUsersComponent } from '@app/administration/user/user.component';
import { GlobalAdministrationUsersComponent } from '@app/global-administration/user-information/user/user.component';
import { GlobalAdministrationComponent } from '@app/global-administration/layout/global-administration.component';
import { GlobalAdministrationManagingCompanyComponent } from '@app/global-administration/managing-company/managing-company.component';
import { TenantsComponent } from '@app/administration/tenants/tenants.component';
import { GlobalAdministrationPacketStreamerComponent } from '@app/global-administration/packet-streamer/packet-streamer.component';
import { AnalyticsComponent } from '@app/analytics/analytics.component';
import { EmergencyLightingComponent } from '@app/emergency-lighting/layout/emergency-lighting.component';
import { EmergencyLightingManualTestsComponent } from '@app/emergency-lighting/manual-tests/emergency-lighting-manual-tests.component';
import { EmergencyLightingSchedulesComponent } from '@app/emergency-lighting/schedules/emergency-lighting-schedules.component';
import { EmergencyLightingReportsComponent } from '@app/emergency-lighting/reports/emergency-lighting-reports.component';
import { GlobalAdministrationEmergencyLightingCalendarComponent } from '@app/global-administration/emergency-lighting-calendar/emergency-lighting-calendar.component';
import { AdministrationCalendarComponent } from '@app/administration/calendar/calendar.component';
import { AdministrationEmergencyLightingComponent } from '@app/administration/emergency-lighting/emergency-lighting.component';
import { GlobalAdministrationOtapUpgradesComponent } from '@app/global-administration/otap-upgrades/otap-upgrades.component';
import { JobInformationComponent } from '@app/global-administration/otap-upgrades/job-information/job-information.component';
import { FloorsComponent } from '@app/administration/floors/floors.component';
import { TokensComponent } from '@app/administration/tokens/tokens.component';
import { MobileTokenComponent } from '@app/administration/mobile-token/mobile-token.component';
import { RolesComponent } from '@app/administration/roles/roles.component';
import { NavigationService } from '@services/navigation/navigation.service';
import { UserService } from '@services/user/user.service';
import { BuildingAuthorityType } from '@app/shared/models/building-authority-type';
import { MapNodesComponent } from '@app/mapping-nodes/map-nodes.component';
import { BuildingsComponent } from '@app/buildings/buildings.component';
import { SensorNodesComponent } from '@app/sensor-nodes/sensor-nodes.component';
import { DashboardComponent } from '@app/dashboard/dashboard.component';
import { EmailSchedulesComponent } from '@app/emergency-lighting/email-schedules/email-schedules.component';
import { GlobalAuthority } from '@app/shared/models/global-authority';
import { map } from 'rxjs/operators';
import { GeneralComponent } from '@app/dashboard/general/general.component';
import { EnergyComponent } from '@app/dashboard/energy/energy.component';
import { OccupancyComponent } from '@app/dashboard/occupancy/occupancy.component';
import { MaintenanceComponent } from '@app/dashboard/maintenance/maintenance.component';
import { SecurityService } from '@services/security.service';
import { MetricsComponent } from '@app/dashboard/metrics/metrics.component';
import { Observable } from 'rxjs';
import { UserInformationComponent } from '@app/global-administration/user-information/user-information.component';
import { GlobalAuthorityRequestsComponent } from '@app/global-administration/user-information/global-authority-requests/global-authority-requests.component';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: '',
        redirectTo: 'global-administration',
        pathMatch: 'full'
      },
      {
        path: 'global-administration',
        component: GlobalAdministrationComponent,
        children: [
          {
            path: '',
            redirectTo: 'user-information',
            pathMatch: 'full'
          },
          {
            path: 'user-information',
            component: UserInformationComponent,
            children: [
              { path: 'users', component: GlobalAdministrationUsersComponent },
              { path: 'authority-requests', component: GlobalAuthorityRequestsComponent }
            ]
          },
          {
            path: 'managing-company',
            component: GlobalAdministrationManagingCompanyComponent
          },
          {
            path: 'emergency-lighting-calendar',
            component: GlobalAdministrationEmergencyLightingCalendarComponent
          },
          {
            path: 'packet-stream',
            component: GlobalAdministrationPacketStreamerComponent
          },
          {
            path: 'otap-upgrades',
            component: GlobalAdministrationOtapUpgradesComponent
          },
          {
            path: 'otap-upgrades/job-information',
            component: JobInformationComponent
          }
        ]
      }
    ]
  },
  {
    path: 'buildings',
    component: MainLayoutComponent,
    children: [{ path: '', component: BuildingsComponent }]
  },
  {
    path: 'buildings/new',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        component: AppNewBuildingComponent
      }
    ],
    canActivate: [
      () => {
        const userService = inject(UserService);
        return userService
          .getCurrentUser()
          .pipe(
            map((user): boolean =>
              user.globalAuthorities.some(
                (authority) => authority?.toString() === GlobalAuthority.ADD_BUILDINGS.toString()
              )
            )
          );
      }
    ]
  },
  {
    path: 'buildings/:buildingId',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'administration',
        pathMatch: 'full'
      },
      {
        path: 'administration',
        component: AdministrationComponent,
        canActivateChild: [
          (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
            const buildingId = parseInt(route.params.buildingId, 10);
            const userService = inject(UserService);
            if (route.routeConfig.path === 'building') {
              return userService.getCurrentUser().pipe(
                map((user): boolean | UrlTree => {
                  const buildingAuthorizations = user.buildingAuthorizations.filter(
                    (ba) => ba.buildingId === buildingId
                  );
                  const hasBuildingAuth = buildingAuthorizations.some((a) =>
                    a.authorities.some(
                      (bat: BuildingAuthorityType) =>
                        bat.toString() === BuildingAuthorityType.MANAGE_BUILDING.toString()
                    )
                  );

                  const hasUserAuth = buildingAuthorizations.some((a) =>
                    a.authorities.some(
                      (bat: BuildingAuthorityType) => bat.toString() === BuildingAuthorityType.MANAGE_USERS.toString()
                    )
                  );

                  if (hasBuildingAuth) {
                    return true;
                  } else if (hasUserAuth) {
                    return createUrlTreeFromSnapshot(route, ['../users']);
                  } else {
                    return false;
                  }
                })
              );
            } else {
              return true;
            }
          }
        ],
        children: [
          {
            path: '',
            redirectTo: 'building',
            pathMatch: 'full'
          },
          { path: 'building', component: AdministrationBuildingComponent },
          { path: 'gateways', component: AdministrationGatewaysComponent },
          { path: 'floors', component: FloorsComponent },
          { path: 'users', component: AdministrationUsersComponent },
          { path: 'tokens', component: TokensComponent },
          { path: 'mobile-tokens', component: MobileTokenComponent },
          { path: 'roles', component: RolesComponent },
          { path: 'tenants', component: TenantsComponent },
          { path: 'calendar', component: AdministrationCalendarComponent },
          {
            path: 'emergency-lighting',
            component: AdministrationEmergencyLightingComponent
          }
        ]
      },
      {
        path: 'emergency-lighting',
        component: EmergencyLightingComponent,
        children: [
          {
            path: '',
            redirectTo: 'schedules',
            pathMatch: 'full'
          },
          { path: 'schedules', component: EmergencyLightingSchedulesComponent },
          { path: 'reports', component: EmergencyLightingReportsComponent },
          { path: 'email-schedules', component: EmailSchedulesComponent },
          { path: ':floorId/manual-tests', component: EmergencyLightingManualTestsComponent }
        ]
      },
      {
        path: 'circadian-curves',
        component: CircadianCurveComponent,
        children: []
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [
          (route: ActivatedRouteSnapshot) => {
            const buildingId = Number(route.params.buildingId);
            const securityService = inject(SecurityService);
            return securityService.isRoleAuthorizedForBuilding(
              [BuildingAuthorityType.VIEW_DASHBOARD_INSIGHTS, BuildingAuthorityType.VIEW_DASHBOARD_ANALYTICS],
              buildingId
            );
          }
        ],
        canActivateChild: [metricActivationGuard()],
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'general'
          },
          {
            path: 'general',
            component: GeneralComponent
          },
          {
            path: 'energy',
            component: EnergyComponent
          },
          {
            path: 'occupancy',
            component: OccupancyComponent
          },
          {
            path: 'maintenance',
            component: MaintenanceComponent
          },
          {
            path: 'metrics',
            component: MetricsComponent
          }
        ]
      },
      {
        path: ':floorId',
        children: [
          {
            path: 'sensor-nodes',
            component: SensorNodesComponent,
            children: []
          },
          {
            path: 'heatmap',
            component: AnalyticsComponent,
            canActivate: [
              (route: ActivatedRouteSnapshot) => {
                const buildingId = Number(route.params.buildingId);
                const securityService = inject(SecurityService);
                return securityService.isAuthorizedForBuilding(BuildingAuthorityType.ANALYTICS.value, buildingId);
              }
            ],
            children: []
          },
          {
            path: 'map-nodes',
            component: MapNodesComponent,
            children: []
          }
        ]
      }
    ]
  }
];

function metricActivationGuard(): (route: ActivatedRouteSnapshot) => Observable<true | UrlTree> | true {
  return (route: ActivatedRouteSnapshot) => {
    const router = inject(Router);
    const securityService = inject(SecurityService);
    const buildingId = Number(route.params.buildingId);
    if (route.routeConfig.path !== 'metrics') {
      return securityService
        .hasOnlyAnalyticsViewCapability(buildingId)
        .pipe(
          map((hasOnlyAnalyticsViewCapability) =>
            !hasOnlyAnalyticsViewCapability
              ? true
              : router.createUrlTree([`/buildings/${buildingId}/dashboard/metrics`])
          )
        );
    } else {
      return true;
    }
  };
}

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' })],
  exports: [RouterModule],
  providers: [{ provide: UrlHandlingStrategy, useClass: CustomHandlingStrategy }, NavigationService, UserService]
})
export class AppRoutingModule {}
