import { NgModule } from '@angular/core';
import { SharedComponentsModule } from '@app/shared/shared-components.module';
import { CommonModule } from '@angular/common';
import { EmergencyLightingManualTestsComponent } from '@app/emergency-lighting/manual-tests/emergency-lighting-manual-tests.component';
import { EmergencyLightingSchedulesComponent } from '@app/emergency-lighting/schedules/emergency-lighting-schedules.component';
import { EmergencyLightingReportsComponent } from '@app/emergency-lighting/reports/emergency-lighting-reports.component';
import { EmergencyLightingSchedulesListComponent } from '@app/emergency-lighting/schedules/emergency-lighting-schedules-list/emergency-lighting-schedules-list.component';
import { EmergencyLightingSchedulesSummaryComponent } from '@app/emergency-lighting/schedules/emergency-lighting-schedules-summary/emergency-lighting-schedules-summary.component';
import { EmergencyLightingSchedulesTileComponent } from '@app/emergency-lighting/schedules/emergency-lighting-schedules-tile/emergency-lighting-schedules-tile.component';
import { FormEmergencyLightingScheduleComponent } from '@app/emergency-lighting/schedules/form-emergency-lighting-schedule/form-emergency-lighting-schedule.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { ScheduleRecurrenceDialogComponent } from '@app/emergency-lighting/schedules/schedule-recurrence-dialog/schedule-recurrence-dialog.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { MatBadgeModule } from '@angular/material/badge';
import { AuthorizationModule } from '@app/shared/directives/authorization.module';
import { CustomDatePipe } from '@app/shared/pipes/custom-date.pipe';
import { FloorplanModule } from '@components/floorplan/floorplan.module';
import { PanelToggleComponent } from '@components/panel-toggle/panel-toggle.component';
import { ElmtTestsFilterComponent } from '@app/emergency-lighting/elmt-tests-filter/elmt-tests-filter.component';
import { NotificationBlockComponent } from '@components/notification-block/notification-block.component';
import { ElmtReportsTableComponent } from '@app/emergency-lighting/reports/elmt-reports-table/elmt-reports-table.component';
import { ElmtReportsOptionsDialogComponent } from '@app/emergency-lighting/reports/elmt-reports-options-dialog/elmt-reports-options-dialog.component';
import { ElmtSelectedFiltersService } from '@app/emergency-lighting/elmt-tests-filter/elmt-selected-filters.service';
import { TagsComponent } from '@components/tags/tags.component';

@NgModule({
  declarations: [
    EmergencyLightingReportsComponent,
    EmergencyLightingManualTestsComponent,
    EmergencyLightingSchedulesComponent,
    EmergencyLightingSchedulesListComponent,
    EmergencyLightingSchedulesSummaryComponent,
    EmergencyLightingSchedulesTileComponent,
    FormEmergencyLightingScheduleComponent,
    ScheduleRecurrenceDialogComponent
  ],
  imports: [
    AuthorizationModule,
    CommonModule,
    CustomDatePipe,
    FloorplanModule,
    FormsModule,
    MatBadgeModule,
    MatCardModule,
    MatDatepickerModule,
    MatDialogModule,
    MatRadioModule,
    PanelToggleComponent,
    ReactiveFormsModule,
    SharedComponentsModule,
    ElmtTestsFilterComponent,
    NotificationBlockComponent,
    ElmtReportsTableComponent,
    ElmtReportsOptionsDialogComponent,
    TagsComponent
  ],
  exports: [],
  providers: [ElmtSelectedFiltersService]
})
export class EmergencyLightingModule {}
