import { IBuildingService } from '@angularjs/or/services/IBuildingService';
import { FloorService } from '@angularjs/or/services/FloorService';
import { SensorNodeService } from '@angularjs/or/services/SensorNodeService';
import { NavigationService } from '@app/shared/services/navigation/navigation.service';
import { INodesParamsService } from './NodesConfig';
import { ILocationContext, ITagContext } from '@angularjs/or/api/query/outline/context/IContext';
import { NavigationSectionInfo } from '@app/shared/services/navigation/navigation-section-info';
import { IQueryOutlineBuilder } from '@angularjs/or/api/query/outline/IQueryOutlineBuilder';
import { UIRefreshService } from '@angularjs/or/services/UIRefreshService';
import { IObservable, IObservableModifiable } from '@angularjs/or/util/IObservable';
import { Tenant } from '@app/shared/models/tenant.interface';
import { TenantService } from '@app/shared/services/building/tenant.service';
import { FeatureService } from '@app/shared/services/feature.service';
import { TagService } from '@angularjs/or/services/TagService';
import { FloorplanTag, Tag, TagType } from '@angularjs/or/api/building/Tag';
import { Subject } from 'rxjs';
import { TagChangeEvent } from '@components/tags/tags.component';
import { CheckboxState } from '@app/shared/models/tag.interface';
import { SensorNode } from '@angularjs/or/api/building/SensorNode';
import { ArrayUtils } from '@angularjs/or/util/ArrayUtils';
import { BuildingAuthorityType } from '@app/shared/models/building-authority-type';
import { SecurityService } from '@angularjs/or/angular/services/SecurityService';

export class NodesController {
  public isBusy = true;
  public areNodesEnabled = true;
  public areDriversEnabled = true;
  public areUnmappedNodesEnabled = false;
  public showNodeValues = false;
  public addModeActive = false;
  public mappingModeActive = false;
  public accumulateSelection = false;
  public buildingId: number;
  public floorId: number;
  public isFloorplanPanelActive = true;
  public isNodesListPanelActive = true;
  public isTagsPanelActive = true;
  public isTenantsPanelActive = false;
  public activeNodeListNode;
  public tenants: Partial<Tenant>[];
  public hasManageTenantForBuilding = false;
  tags$ = new Subject<FloorplanTag[]>();

  constructor(
    private $routeParams: INodesParamsService,
    private $scope: angular.IScope,
    protected buildingService: IBuildingService,
    private floorService: FloorService,
    protected nodesService: SensorNodeService,
    private tenantService: TenantService,
    private navigationService: NavigationService,
    private locationContext: IObservableModifiable<ILocationContext>,
    private outlineBuilder: IObservable<IQueryOutlineBuilder>,
    private mysectionInfo: NavigationSectionInfo,
    private uiRefreshService: UIRefreshService,
    protected featureService: FeatureService,
    private tagService: TagService,
    private tagContext: IObservableModifiable<ITagContext>,
    private securityService: SecurityService
  ) {
    this.initializeValues();
    this.initNavigation();
    buildingService.getCurrentBuilding().then((building) => {
      this.init();
    });
  }

  init(): void {
    this.$scope.$apply(() => {
      this.isBusy = false;
    });
  }

  private initNavigation(): void {
    this.navigationService.applyContext(window.location.href, {
      buildingId: this.buildingId,
      floorId: this.floorId
    });
  }

  private initializeValues(): void {
    this.buildingId = parseInt(this.$routeParams.buildingId, 10);
    this.floorId = parseInt(this.$routeParams.floorId, 10);

    this.outlineBuilder.deregisterAll();

    this.floorService.setCurrentFloorId(this.floorId);
    this.buildingService.setCurrentBuildingId(this.buildingId);

    this.locationContext.change((value) => {
      value.buildingId = this.buildingId;
      value.floorIds = [this.floorId];
    });
    this.loadTags();
    this.addTagRefreshListSubscriptionForTagChange();
  }

  public toggleMappingMode(isActive: boolean): void {
    if (isActive) {
      this.maximizeFloorplan();
    } else {
      this.restorePanels();
    }
    this.areUnmappedNodesEnabled = isActive;
  }

  public maximizeFloorplan(): void {
    this.isFloorplanPanelActive = true;
    this.isNodesListPanelActive = false;
    this.isTagsPanelActive = false;
    this.isTenantsPanelActive = false;
  }

  public restorePanels(): void {
    this.isFloorplanPanelActive = true;
    this.isNodesListPanelActive = true;
    this.isTagsPanelActive = true;
    this.isTenantsPanelActive = true;
  }

  protected get sensorNodeService(): SensorNodeService {
    return this.nodesService;
  }

  protected get scope() {
    return this.$scope;
  }

  /* === Tag related changes === */
  loadTags(): void {
    this.buildingService.getCurrentBuilding().then((building) => {
      this.securityService
        .isAuthorizedForBuildingId(BuildingAuthorityType.MANAGE_TENANT.value, building.id)
        .then((result) => {
          this.hasManageTenantForBuilding = result;
        });
      this.tagService.getTags(building, true, true).then((tags) => {
        const floorPlanTags = FloorplanTag.fromArray(tags);
        this.tagService.updateCheckboxesForTags(
          floorPlanTags,
          this.nodesService.selectedNodes.value().filter((n) => n instanceof SensorNode)
        );
        this.nodesService.selectedNodes.onChange((value) => {
          this.tagService.updateCheckboxesForTags(
            floorPlanTags,
            value.filter((n) => n instanceof SensorNode)
          );
          this.tags$.next(floorPlanTags);
        });
        this.tags$.next(floorPlanTags);
      });
    });
  }

  toggleTag(event: TagChangeEvent): void {
    const tag = event.tag;
    if (
      event.isNodeTaggingModeActive &&
      (tag.tagType !== TagType.TENANT || (tag.tagType === TagType.TENANT && this.hasManageTenantForBuilding))
    ) {
      let isAdd = event.checked == CheckboxState.UNCHECKED;
      this.nodesService.selectedNodes.change((nodes: SensorNode[]) => {
        const nodesToCheck = nodes.filter((n) => n instanceof SensorNode);
        if (nodesToCheck.length === 0) {
          return;
        } else {
          tag.checked = event.checked;
          let filterNodes = nodesToCheck.filter(
            (n) => !ArrayUtils.contains(n.tags, tag, (tag1, tag2) => tag1.id === tag2.id)
          );
          if (event.checked === CheckboxState.MIXED && filterNodes.length !== 0) {
            isAdd = true;
          }
          if (isAdd) {
            filterNodes = nodesToCheck.filter(
              (n) => !ArrayUtils.contains(n.tags, tag, (tag1, tag2) => tag1.id === tag2.id)
            );
            filterNodes.forEach((n) => {
              if (!n.tags) {
                n.tags = [];
              }
              n.tags.push(tag);
            });
            this.tagService.tagNodes({ tagIds: [tag.id], nodeIds: filterNodes.map((v) => v.id) });
          } else {
            filterNodes = nodesToCheck.filter((n) =>
              ArrayUtils.contains(n.tags, tag, (tag1, tag2) => tag1.id === tag2.id)
            );
            this.tagService.unTagNodes({ tagIds: [tag.id], nodeIds: filterNodes.map((v) => v.id) });
            filterNodes.forEach((n) => ArrayUtils.removeElement(n.tags, tag, (tag1, tag2) => tag1.id === tag2.id));
          }
        }
      });
    }
  }

  tagCreate(tag: FloorplanTag): void {
    this.tagService.addTag(tag).then(() => {});
  }

  tagEditChange(tag: FloorplanTag): void {
    this.tagService.updateNodesForTag(tag);
  }

  tagUpdate(tag: FloorplanTag): void {
    this.tagService.updateTag(tag.id, tag).then(() => {
      this.tagService.updateNodesForTag(tag);
      tag.isDirty = false;
    });
  }

  tagReset(tag: FloorplanTag): void {
    this.tagService.getTag(tag.id).then((tagOld: Tag) => {
      tag.name = tagOld.name;
      tag.color = tagOld.color;
      this.tagService.updateNodesForTag(tag);
      tag.isDirty = false;
    });
  }

  deleteTag(tag: FloorplanTag): void {
    this.tagService.deleteTag(tag.id).then(() => {
      this.tagService.updateNodesForTag(tag, true);
    });
  }

  /* This will refresh the tag list after creation, deletion and update*/
  addTagRefreshListSubscriptionForTagChange(): void {
    this.tagService.shouldReloadTags.subscribe((force) => {
      this.loadTags();
    });
  }

  /* === Tag related changes end === */
}
