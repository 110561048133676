import { OrSessionMenuController } from './OrSessionMenuController';

export class OrSessionMenuDirective {
  constructor() {
    return {
      bindToController: true,
      controller: [
        '$scope',
        'SessionService',
        'BuildingService',
        'UserInfoBaseUrl',
        'GlobalAdministrationBaseUrl',
        'DropdownService',
        OrSessionMenuController
      ],
      controllerAs: 'sessionMenu',
      replace: true,
      restrict: 'E',
      scope: {
        isActive: '=?'
      },
      template: require('raw-loader!./or-session-menu.html').default
    };
  }
}
