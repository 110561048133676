import { LampTypeResource } from '../angular/resources/LampTypeResource';
import { LampTypeTemplate } from '../api/building/ILampTypeTemplate';
import { SimpleLampType } from '../api/building/LampType';
import { LuminaireManufacturer } from '../api/building/LuminaireManufacturer';
import { ILampTypeTemplateResource } from '../api/ILampTypeTemplateResource';
import { ILuminaireManufacturerResource } from '../api/resources/ILuminaireManufacturerResource';

export class LampTypeService {
  public lampTypes: SimpleLampType[] = [];
  private lampTypesPromise: Promise<SimpleLampType[]> = null;
  private prevBuilding: number;

  constructor(
    private lampTypeResource: LampTypeResource,
    private lampTypeTemplateResource: ILampTypeTemplateResource,
    private luminaireManufacturerResource: ILuminaireManufacturerResource
  ) {}

  public getTemplates(buildingId: number): Promise<LampTypeTemplate[]> {
    return this.lampTypeTemplateResource.getTemplates(buildingId);
  }

  public saveLampType(
    lampType: SimpleLampType,
    buildingId
  ): Promise<SimpleLampType> {
    return new Promise((resolve) => {
      this.lampTypeResource
        .addByBuildingId(lampType, buildingId)
        .then((saved) => {
          resolve(saved);
        });
    });
  }

  public getLampTypes(buildingId: number): Promise<SimpleLampType[]> {
    if (buildingId === this.prevBuilding) {
      if (!this.lampTypes && this.lampTypesPromise) {
        return this.lampTypesPromise;
      }
    }
    this.prevBuilding = buildingId;
    const promise = this.lampTypeResource.retrieveAllByParentId(buildingId);
    this.lampTypesPromise = promise;
    promise.then((lampTypes) => (this.lampTypes = lampTypes));
    return promise;
  }

  public getLuminaireManufacturers(): Promise<LuminaireManufacturer[]> {
    return this.luminaireManufacturerResource.getAll();
  }
}
