import * as angular from 'angular'; // Automatically added
import { ArrayUtils } from '@angularjs/or/util/ArrayUtils';
import { MultiselectOption } from './MultiselectOption';
import { ObjectUtils } from '@angularjs/or/util/ObjectUtils';
import IScope = angular.IScope;
import IFormController = angular.IFormController;

export class OrMultiselectController {
  public form: IFormController;

  public isEditMode: boolean;
  public originalOptions: MultiselectOption<any>[];
  public options: MultiselectOption<any>[];
  public values: any[];
  public selection: any;
  public unselectedCount: number;
  public selectedCount: number;
  public areEqual: (a: any, b: any) => boolean;
  public isMandatory: boolean;

  constructor(private scope: IScope) {}

  public $onInit() {
    this.isMandatory = ObjectUtils.coalesce(this.isMandatory, false);

    this.scope.$watch('multiselect.isEditMode', () =>
      this.processOptionsAndValues()
    );
    this.scope.$watch('multiselect.originalOptions', () =>
      this.processOptionsAndValues()
    );
    this.scope.$watch('multiselect.values', () =>
      this.processOptionsAndValues()
    );
    this.scope.$watch('multiselect.form', () => this.validateForm());

    if (!this.isEditMode) {
      this.options = angular.copy(this.originalOptions);
    }
  }

  private processOptionsAndValues() {
    this.options = angular.copy(this.originalOptions);
    if (this.values != null && this.options != null) {
      this.options.forEach((option) => {
        if (this.areEqual) {
          option.isSelected = ArrayUtils.contains(
            this.values,
            option.value,
            this.areEqual
          );
        } else {
          option.isSelected = this.values.indexOf(option.value) >= 0;
        }
      });
      this.selectedCount = this.values.length;
      this.unselectedCount = this.options.length - this.selectedCount;
    }
    this.selection = '';
  }

  public change() {
    if (this.selection != null) {
      this.setSelected(this.selection, true);
    }
  }

  public setSelected(option: MultiselectOption<any>, isSelected: boolean) {
    option.isSelected = isSelected;
    this.values = this.options
      .filter((option) => option.isSelected)
      .map((option) => option.value);
    this.selectedCount = this.values.length;
    this.unselectedCount = this.options.length - this.selectedCount;
    this.validateForm();
  }

  private validateForm() {
    if (this.form) {
      this.form.$setValidity(
        'No values selected.',
        !this.isMandatory || this.selectedCount > 0,
        this.form
      );
    }
  }
}
