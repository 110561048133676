import IScope = angular.IScope;

export class OrImgOnLoadController {
  public callback: Function;
  public target: any;

  constructor(private scope: IScope, private element) {}

  public $onInit() {
    this.element.bind('load', () => {
      this.scope.$apply(this.callback.bind(this.target));
    });
  }
}
