import { OrFloorsMenuController } from './OrFloorsMenuController';

export class OrFloorsMenuDirective {
  constructor() {
    return {
      bindToController: true,
      controller: [
        '$scope',
        'BuildingService',
        'BuildingService',
        'FloorService',
        'UIRefreshService',
        'FloorAdministrationUrlGenerator',
        'NavigationService',
        'DropdownService',
        OrFloorsMenuController
      ],
      controllerAs: 'floorsMenu',
      replace: true,
      restrict: 'E',
      scope: {
        isActive: '=?'
      },
      template: require('raw-loader!./or-floors-menu.html').default
    };
  }
}
