import { FloorplanSensorNode } from '@angularjs/or/api/building/FloorplanSensorNode';
import { IAngularEvent } from 'angular';
import { OrFloorplanController } from '@angularjs/angular/modules/layout/or-floorplan/OrFloorplanController';

export class OrPubSubConnectionsController {
  public pubSubLines: { x1: number; x2: number; y1: number; y2: number }[];
  public isReadyToDraw = false;
  constructor(private scope: ng.IScope, public nodes: FloorplanSensorNode[]) {}
  public $onInit() {
    this.formPubSubConnections(this.nodes);
    this.scope.$on(
      OrFloorplanController.NODES_ARE_UPDATED,
      (event: IAngularEvent, data: FloorplanSensorNode[]) =>
        this.formPubSubConnections(data)
    );
  }

  public formPubSubConnections(nodes: FloorplanSensorNode[]): void {
    this.isReadyToDraw = false;
    this.pubSubLines = [];
    const groupMap: Map<number, PubSubGroup> = new Map<number, PubSubGroup>();
    if (nodes === null) {
      return;
    }

    nodes
      .filter((node) => node.groupId !== null && node.subscriber !== null)
      .forEach((node) => {
        if (!groupMap.has(node.groupId)) {
          groupMap.set(node.groupId, new PubSubGroup());
        }
        if (node.subscriber === true) {
          groupMap.get(node.groupId).subscriberNodes.push(node);
        } else {
          groupMap.get(node.groupId).publisherNodes.push(node);
        }
      });

    for (const pubSubGroup of groupMap.values()) {
      pubSubGroup.publisherNodes.forEach((publisherNode) => {
        pubSubGroup.subscriberNodes.forEach((subscriberNode) => {
          this.pubSubLines.push({
            x1: subscriberNode.x,
            y1: subscriberNode.y,
            x2: publisherNode.x,
            y2: publisherNode.y
          });
        });
      });
    }
    this.isReadyToDraw = true;
  }
}

export class PubSubGroup {
  publisherNodes: FloorplanSensorNode[] = [];
  subscriberNodes: FloorplanSensorNode[] = [];
}
