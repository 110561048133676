import { Injectable } from '@angular/core';
import { ElmtEmailTableData } from '@app/shared/models/elmt-email-table-datasource';
import { Observable } from 'rxjs';
import { ElmtEmailDataResource } from '@app/shared/resources/elmt-email-data.resource';
import { Pageable } from '@app/shared/models/Pageable.interface';
import { Filter } from '@app/shared/models/filter.interface';

@Injectable({
  providedIn: 'root'
})
export class ElmtEmailDataService {
  constructor(private resource: ElmtEmailDataResource) {}

  getScheduledEmailsForBuilding(buildingId: number, filter: Filter): Observable<Pageable<ElmtEmailTableData>> {
    return this.resource.getAllResources(buildingId, filter);
  }

  saveEmailSchedule(buildingId: number, emailSchedule: ElmtEmailTableData): Observable<ElmtEmailTableData> {
    return this.resource.addResource(buildingId, emailSchedule);
  }

  updateEmailSchedule(buildingId: number, emailSchedule: ElmtEmailTableData): Observable<ElmtEmailTableData> {
    return this.resource.updateResource(buildingId, emailSchedule);
  }

  deleteEmailSchedule(buildingId: number, emailScheduleId: number): Observable<void> {
    return this.resource.deleteResource(buildingId, emailScheduleId);
  }
}
