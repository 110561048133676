@if (dataSource.isLoading$ | async) {
  <div class="spinner">
    <mat-spinner></mat-spinner>
  </div>
} 
@else if(!dataSource.hasData) {
  <app-notification-block
    [header]="'No scheduled emails found'"
    [statusClass]="StatusClass.WARNING">
    No emails scheduled for this building. Create a new schedule using the form and view the list here.
  </app-notification-block>
} 
<div class="email-table mat-elevation-z8" [ngClass]="{'invisible': ((dataSource.isLoading$ | async) || !dataSource.hasData)}">
  <mat-table [dataSource]="dataSource" matSort matSortActive="sendDate" (matSortChange)="onSortChanged($event)" >
    @for (column of columns; track $index) {
      <ng-container [matColumnDef]="column.columnDef">
        @if(column.columnDef === "sendDate"){
          <mat-header-cell *matHeaderCellDef mat-sort-header="sendDate" mat-sort-header>{{column.header}}</mat-header-cell>
        } @else {
          <mat-header-cell *matHeaderCellDef>{{column.header}}</mat-header-cell>
        }
        <mat-cell *matCellDef="let row">
          @if (column.cell) {
            {{column.cell(row)}}
          } @else {
            <mat-icon class="text-red-500" (click)="handleDeleteClick($event, row)" [attr.data-cy]="'delete-report-icon-' + row.id">delete</mat-icon>
          }
        </mat-cell>
      </ng-container>
    }
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row class="cursor-pointer" *matRowDef="let row; columns: displayedColumns;" (click)="handleRowClick(row)" [attr.data-cy]="'report-row-' + row.id"></mat-row>
  </mat-table>  

  <mat-paginator class="mat-elevation-z8 mat-paginator-sticky" #emaillogsPaginator [pageIndex]="pageIndex" [length]="dataSource.counter$ | async"
    [pageSize]="pageSize" (page)="onPageEvent($event)" showFirstLastButtons>
  </mat-paginator>
</div>

