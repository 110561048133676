import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { UpgradeModule } from '@angular/upgrade/static';
import { EventBridge } from '@common/event-bridge';
import { environment, environmentToken } from '@environment';
import { AdministrationModule } from './administration/administration.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { HeaderModule } from '@components/header/header.module';
import { Interceptor } from './shared/http-interceptor';
import { BuildingMetadataService } from '@services/building-metadata/building-metadata.service';
import { AppNewBuildingComponent } from '@app/buildings/new/new-building.component';
import { XsrfInterceptor } from '@app/shared/http-csrf-intercepter';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CircadianCurveModule } from '@app/circadian-curve/circadian-curve.module';
import { GlobalAdministrationModule } from '@app/global-administration/global-administration.module';
import { AnalyticsModule } from '@app/analytics/analytics.module';
import { AnalyticsComponent } from '@app/analytics/analytics.component';
import { SharedComponentsModule } from '@app/shared/shared-components.module';
import { ConfigService } from '@app/config-service';
import { EmergencyLightingModule } from '@app/emergency-lighting/emergency-lighting.module';
import { EmergencyLightingComponent } from '@app/emergency-lighting/layout/emergency-lighting.component';
import { provideHotToastConfig } from '@ngneat/hot-toast';
import { MapNodesComponent } from './mapping-nodes/map-nodes.component';
import { FloorplanModule } from '@components/floorplan/floorplan.module';
import { PanelToggleComponent } from '@components/panel-toggle/panel-toggle.component';
import { SensorNodesComponent } from '@app/sensor-nodes/sensor-nodes.component';
import { MatDateFnsModule } from '@angular/material-date-fns-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { enAU } from 'date-fns/locale';
import { TagsComponent } from '@components/tags/tags.component';

export const configFactory = (configService: ConfigService) => () => configService.loadConfig();

@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    AppNewBuildingComponent,
    AnalyticsComponent,
    EmergencyLightingComponent
  ],
  imports: [
    AdministrationModule,
    AnalyticsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CircadianCurveModule,
    CommonModule,
    EmergencyLightingModule,
    FloorplanModule,
    GlobalAdministrationModule,
    HeaderModule,
    MapNodesComponent,
    PanelToggleComponent,
    SensorNodesComponent,
    SharedComponentsModule,
    UpgradeModule,
    MatDateFnsModule,
    TagsComponent
  ],
  providers: [
    BuildingMetadataService,
    EventBridge,
    { provide: environmentToken, useValue: environment },
    { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: XsrfInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: configFactory,
      deps: [ConfigService],
      multi: true
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: enAU
    },
    provideHotToastConfig()
  ],
  exports: [AppNewBuildingComponent],
  bootstrap: [AppComponent]
})
export class AppModule {}
