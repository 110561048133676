import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractUnmappedNodesDataSource, UnmappedNode } from '@app/shared/models/unmapped-nodes-datasource';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { CommonModule } from '@angular/common';
import { CdkDrag, CdkDropList } from '@angular/cdk/drag-drop';
import { MatButtonModule } from '@angular/material/button';
import { MatSortModule, Sort } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-unmapped-nodes-list',
  templateUrl: './unmapped-nodes-list.component.html',
  styleUrls: ['./unmapped-nodes-list.component.scss'],
  standalone: true,
  imports: [
    CdkDrag,
    CdkDropList,
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule
  ]
})
export class UnmappedNodesListComponent implements OnInit {
  @Input() buildingId: number;
  @Output() onBlink = new EventEmitter<UnmappedNode>();
  @Output() onScroll = new EventEmitter<number>();
  tableColumns: string[] = ['address', 'nodeType', 'gatewayName', 'blinkButton', 'draggableNode'];
  shortenedTableColumns: string[] = ['address', 'gatewayName', 'draggableNode'];

  constructor(public dataSource: AbstractUnmappedNodesDataSource) {}

  ngOnInit(): void {
    this.dataSource.loadUnmappedNodes(this.buildingId);
  }

  blinkClicked(nodeInfo: UnmappedNode): void {
    this.onBlink.emit(nodeInfo);
  }

  getIconClass(nodeInfo: UnmappedNode): string {
    let classNames = this.isNodeDisabled(nodeInfo) ? 'dragging-disabled' : '';
    classNames += nodeInfo.nodeType === 'HIM84' ? ' or-icon-him84' : ' or-icon-node';
    return classNames;
  }

  getContainerClass(nodeInfo: UnmappedNode): string {
    const className = this.isNodeDisabled(nodeInfo) ? 'shadow-disabled ' : '';
    switch (nodeInfo.nodeType) {
      case 'SN3':
        return className + 'container-sn3';
      case 'PN':
        return className + 'container-passive-node';
      case 'HIM84':
        return className + 'container-highbay';
    }
  }

  getRowClass(nodeInfo: UnmappedNode): string {
    return this.isNodeDisabled(nodeInfo) ? 'row-disabled' : '';
  }

  isNodeDisabled(nodeInfo: UnmappedNode): boolean {
    return nodeInfo.gatewayId == null || nodeInfo.isConnected === false;
  }

  sortChanged($event: Sort): void {
    this.dataSource.sortUnderlyingData($event);
  }

  getTooltipText(nodeInfo: UnmappedNode): string {
    return nodeInfo.gatewayId == null ? 'Node is not associated to any gateway' : 'Node is not connected';
  }

  isBlinkDisabled(nodeInfo: UnmappedNode): boolean {
    return this.isNodeDisabled(nodeInfo) || nodeInfo.nodeType === 'PN';
  }

  handleScroll(event: WheelEvent): void {
    this.onScroll.emit(event.deltaY);
  }
}
