import { CircadianCurve } from '@angularjs/or/data/CircadianCurve';
import * as d3 from 'd3';
import * as angular from 'angular';

export class OrCircadianCurveTileController {
  public curve: CircadianCurve;
  public curvesList: CircadianCurve[];
  public currentColourTemp: number;
  public currentLightLevel: number;

  constructor() {}

  public $onInit() {
    this.initialiseCurve();
  }

  public initialiseCurve() {
    const self = this;
    // Wait for html to load before trying to select
    angular.element(document).ready(function () {
      const margin = { top: 5, right: 5, bottom: 5, left: 5 },
        width = 960 - margin.left - margin.right,
        height = 150 - margin.top - margin.bottom;

      const time = [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23
      ];
      const colourTemps = time.map((t, i) => {
        return { time: t, colour_temp: self.curve.colourTempValues[i] };
      });
      const lightLevels = time.map((t, i) => {
        return { time: t, light_level: self.curve.lightLevelValues[i] };
      });

      // set the ranges
      const x = d3.scaleLinear().range([0, width]);
      const y0 = d3.scaleLinear().range([height, 0]);

      // define the 1st line (Color Temperature)
      const colourTempLine = d3
        .line<{ time: number; colour_temp: number }>()
        .x((d) => {
          return x(d.time);
        })
        .y((d) => {
          return y0(d.colour_temp);
        });

      // appends a 'group' element to 'svg'
      // moves the 'group' element to the top left margin
      const svg = d3
        .select(
          '#circadian-curve-chart-collapsed-' +
            self.curvesList.indexOf(self.curve)
        )
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .attr('border', 1)
        .append('g')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

      // Scale the range of the data
      x.domain([0, 23]);
      y0.domain([
        0,
        d3.max(colourTemps, function (d) {
          return Math.max(d.colour_temp);
        })
      ]);

      // Add the colour temp path.
      svg
        .append('path')
        .data([colourTemps])
        .attr('class', 'line')
        .attr('id', 'colourTempLine')
        .attr('d', colourTempLine)
        .attr('background-color', 'pink');

      const dotRadius = 5;

      const hours = new Date().getHours();
      const currentTimeColourTempPair = colourTemps.filter((colourTemp) => {
        return colourTemp.time === hours;
      });
      const currentTimeLightLevelPair = lightLevels.filter((lightLevel) => {
        return lightLevel.time === hours;
      });
      self.currentColourTemp = currentTimeColourTempPair[0].colour_temp;
      self.currentLightLevel = currentTimeLightLevelPair[0].light_level;

      svg
        .selectAll('circle')
        .data(currentTimeColourTempPair)
        .enter()
        .append('circle')
        .attr('class', 'dot')
        .attr('r', dotRadius)
        .attr('cx', function (d) {
          return x(d.time);
        })
        .attr('cy', function (d) {
          return y0(d.colour_temp);
        });
    });
  }
}
