import moment from 'moment';

import { Resolution } from '../../../data/Resolution';
import { QueryType } from '../QueryType';
import { DataType } from '../../../data/SensorNodeDataType';
import { ComplexQueryOutline } from './ComplexQueryOutline';
import { IQueryOutlineBuilder } from './IQueryOutlineBuilder';
import { IQueryContext } from './context/IContext';
import { LiveQueryOutline } from './LiveQueryOutline';
import { SimpleQueryOutline } from './SimpleQueryOutline';

// TODO need to merge the outline builder and the query executor into one
export class QueryOutlineBuilder implements IQueryOutlineBuilder {
  constructor(public context: IQueryContext) {}

  private pad(value: number): string {
    return ('0' + value).slice(-2);
  }

  public buildNodeDetailOutline(dataType: DataType): SimpleQueryOutline {
    return new SimpleQueryOutline(
      QueryType.TIME_SERIES.toString(),
      dataType.toString(),
      this.context.buildingId,
      this.context.tagIds,
      this.context.sensorNodeIds,
      this.context.floorIds,
      Resolution.MONTHLY.toString(),
      moment().add(-12, 'months').toDate().getTime(),
      moment().toDate().getTime()
    );
  }

  public buildNavigationOutline(): ComplexQueryOutline {
    const outline = new ComplexQueryOutline(
      QueryType.TIME_SERIES.toString(),
      this.context.dataType.toString(),
      this.context.buildingId,
      this.context.tagIds,
      this.context.sensorNodeIds,
      this.context.floorIds
    );
    return this.buildComplexQueryOutline(outline, this.context.resolution);
  }

  public buildMainOutline(): ComplexQueryOutline {
    const outline = new ComplexQueryOutline(
      QueryType.PER_NODE.toString(),
      this.context.dataType.toString(),
      this.context.buildingId,
      this.context.tagIds,
      this.context.sensorNodeIds,
      this.context.floorIds
    );
    return this.buildComplexQueryOutline(outline);
  }

  private buildComplexQueryOutline(
    outline: ComplexQueryOutline,
    resolution?: Resolution
  ): ComplexQueryOutline {
    this.context.dates.years.forEach((year) => {
      if (
        (resolution == null &&
          (this.context.dates.months == null ||
            this.context.dates.months.length == 0)) ||
        resolution == Resolution.YEARLY
      ) {
        if (outline.years == null) {
          outline.years = [];
        }
        outline.years.push(year.toString());
        return;
      }

      this.context.dates.months.forEach((month) => {
        if (
          (resolution == null &&
            (this.context.dates.days == null ||
              this.context.dates.days.length == 0)) ||
          resolution == Resolution.MONTHLY
        ) {
          if (outline.months == null) {
            outline.months = [];
          }
          outline.months.push(year + '-' + this.pad(month));
          return;
        }

        this.context.dates.days.forEach((day) => {
          if (
            (resolution == null &&
              (this.context.dates.hours == null ||
                this.context.dates.hours.length == 0)) ||
            resolution == Resolution.DAILY
          ) {
            if (outline.days == null) {
              outline.days = [];
            }
            outline.days.push(
              year + '-' + this.pad(month) + '-' + this.pad(day)
            );
            return;
          }

          this.context.dates.hours.forEach((hour) => {
            if (
              (resolution == null &&
                (this.context.dates.minutes == null ||
                  this.context.dates.minutes.length == 0)) ||
              resolution == Resolution.HOURLY
            ) {
              if (outline.hours == null) {
                outline.hours = [];
              }
              outline.hours.push(
                year +
                  '-' +
                  this.pad(month) +
                  '-' +
                  this.pad(day) +
                  ' ' +
                  this.pad(hour)
              );
              return;
            }

            this.context.dates.minutes.forEach((minute) => {
              if (outline.minutes == null) {
                outline.minutes = [];
              }
              outline.minutes.push(
                year +
                  '-' +
                  this.pad(month) +
                  '-' +
                  this.pad(day) +
                  ' ' +
                  this.pad(hour) +
                  ':' +
                  this.pad(minute)
              );
            });
          });
        });
      });
    });

    return outline;
  }

  buildLiveOutline(): LiveQueryOutline {
    const outline = new LiveQueryOutline(
      this.context.dataType.toString(),
      this.context.buildingId,
      this.context.tagIds,
      this.context.sensorNodeIds,
      this.context.floorIds
    );
    outline.timezoneOffset = this.context.offsetDuration;
    return outline;
  }
}
