export class ColorUtils {
  private static wheelColors: string[] = [
    '076bb6',
    '15b470',
    'bc243c',
    'efc050',
    'e15d44',
    '855723',
    '4e6172',
    'c3447a',
    '5b5ea6',
    '507a00'
  ];

  public static generateColor(
    min = 8,
    max = 14,
    generateFraction: () => number = () => Math.random()
  ): string {
    let color = '';

    for (let i = 0; i < 6; i++) {
      const number = min + generateFraction() * (max - min);
      color += Math.round(number).toString(16);
    }

    return color;
  }

  public static getNextColorInWheel(index: number) {
    return this.wheelColors[index % this.wheelColors.length];
  }

  public static getConstrastingGrey(bgColor: string): string {
    return this.isDark(bgColor) ? '#FFFFFF' : '#555555';
  }

  public static isDark(bgColor: string): boolean {
    let dark = true;
    if (bgColor && bgColor.length == 3) {
      const r = parseInt(bgColor.substr(0, 1), 16);
      const g = parseInt(bgColor.substr(1, 1), 16);
      const b = parseInt(bgColor.substr(2, 1), 16);
      if (g > 12) {
        dark = false;
      }
    } else if (bgColor && bgColor.length == 6) {
      const r = parseInt(bgColor.substr(0, 2), 16);
      const g = parseInt(bgColor.substr(2, 2), 16);
      const b = parseInt(bgColor.substr(4, 2), 16);
      if (g > 189) {
        dark = false;
      }
    }

    return dark;
  }
}
