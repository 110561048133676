import { OrFormBuildingDetailsController } from './OrFormBuildingDetailsController';

export class OrFormBuildingDetailsDirective {
  constructor() {
    return {
      bindToController: true,
      controller: [
        '$scope',
        'LampTypeService',
        'ThumbnailUrlGenerator',
        'SecurityService',
        OrFormBuildingDetailsController
      ],
      controllerAs: 'buildingDetails',
      replace: true,
      restrict: 'E',
      scope: {
        isNew: '=?',
        isBusy: '=?',
        details: '=',
        countries: '=',
        timeZones: '=',
        onReset: '&',
        onSubmit: '&'
      },
      template: require('raw-loader!./or-form-building-details.html').default
    };
  }
}
