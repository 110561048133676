import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Environment, environmentToken } from '@environment';
import { Observable } from 'rxjs';
import { WidgetResult } from '@app/dashboard/model/widget-item';
import { InfluxQueryOutline } from '@app/dashboard/model/influx-data';

@Injectable({
  providedIn: 'root'
})
export class WidgetResource {
  private readonly baseUrl: string;
  constructor(private httpClient: HttpClient, @Inject(environmentToken) private environment: Environment) {
    this.baseUrl = `${this.environment.apiUrl + this.environment.apiPath}`;
  }

  getRequest(endpoint: string): Observable<WidgetResult> {
    return this.httpClient.get<WidgetResult>(`${this.baseUrl}${endpoint}`);
  }

  postRequest(endpoint: string, payload: InfluxQueryOutline): Observable<WidgetResult> {
    return this.httpClient.post<WidgetResult>(`${this.baseUrl}${endpoint}`, payload);
  }
}
