<div class="or-mapped-nodes-counter" [ngClass]="isActive ? 'or-active' : ''">
  @if (!isEverythingMapped()) {
    <div
      class="or-inline-item or-mapped-nodes-counter-body"
      [ngClass]='{"or-mapped-nodes-counter-mapped-with-duplicates": getDuplicateNodesCount() > 0}'>
    <span class="or-mapped-nodes-counter-label or-mapped-nodes-counter-mapped">
      {{getMappedNodesWithoutDriversCount()}}
    </span>
      <span class="or-mapped-nodes-counter-label">of</span>
      <span class="or-mapped-nodes-counter-label or-mapped-nodes-counter-total">{{getNodesWithoutDriversCount()}}</span>
      <span class="or-mapped-nodes-counter-label">nodes mapped</span>
      @if (getDuplicateNodesCount() > 0) {
        <span class="or-mapped-nodes-counter-label">with</span>
      }
      @if (getDuplicateNodesCount() > 0) {
        <span class="or-mapped-nodes-counter-label or-mapped-nodes-counter-duplicates">{{getDuplicateNodesCount()}}</span>
      }
      @if (getDuplicateNodesCount() > 0) {
        <span class="or-mapped-nodes-counter-label">duplicates!</span>
      }
    </div>
  } @else {
    <div class="or-inline-item or-mapped-nodes-counter-body or-mapped-nodes-counter-everything-mapped">
      <span class="or-mapped-nodes-counter-label">All mapped correctly!</span>
    </div>
  }
</div>
