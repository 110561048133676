import { ICircadianCurve } from '@app/shared/models/circadian-curve.interface';
import { Resource } from './resource';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Environment, environmentToken } from '@environment';
import { Observable } from 'rxjs';
import { ICircadianCurveOutline } from '@app/shared/models/circadian-curve-outline.interface';

@Injectable({
  providedIn: 'root'
})
export class CircadianCurveResource extends Resource<ICircadianCurve, number> {
  constructor(protected httpClient: HttpClient, @Inject(environmentToken) environment: Environment) {
    super(httpClient, `${environment.apiUrl + environment.apiPath}/circadian-curve`);
  }

  public addResource(buildingId: number, item: ICircadianCurve): Observable<ICircadianCurve> {
    return this.httpClient.post<ICircadianCurve>(`${this.baseUrl}/${buildingId}`, item);
  }

  public updateResource(id: number, item: ICircadianCurve): Observable<ICircadianCurve> {
    return this.httpClient.put<ICircadianCurve>(`${this.baseUrl}/${id}`, item);
  }

  public reorderResource(buildingId: number, item: ICircadianCurveOutline): Observable<ICircadianCurveOutline> {
    return this.httpClient.put<ICircadianCurveOutline>(`${this.baseUrl}/order/${buildingId}`, item);
  }

  public moveUp(id: number): Observable<void> {
    return this.httpClient.post<void>(`${this.baseUrl}/up/${id}`, null);
  }

  public moveDown(id: number): Observable<void> {
    return this.httpClient.post<void>(`${this.baseUrl}/down/${id}`, null);
  }
}
