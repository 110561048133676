import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatestWith, Observable, of, switchMap } from 'rxjs';
import { IEmergencyLightingSchedule } from '@app/shared/models/emergency-lighting-schedule.interface';
import { EmergencyLightingScheduleResource } from '@app/shared/resources/emergency-lighting-schedule.resource';
import { IElmtScheduleDto } from '@app/shared/models/elmt-schedule-dto.interface';
import { EmergencyLightingSchedule } from '@app/shared/models/emergency-lighting-schedule';
import { NodeSelector } from '@app/shared/models/node-selector';
import { catchError, map } from 'rxjs/operators';
import { SensorNodeService } from '@services/sensor-node.service';
import { TimezoneUtils } from '@app/shared/utils/timezoneUtils';
import { UserService } from '@services/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class EmergencyLightingScheduleService {
  private buildingELMTScheduleSource = new BehaviorSubject<IEmergencyLightingSchedule[]>([]);

  constructor(
    private readonly userService: UserService,
    private sensorNodeService: SensorNodeService,
    private emergencyLightingScheduleResource: EmergencyLightingScheduleResource
  ) {}

  get buildingELMTSchedules$(): Observable<IEmergencyLightingSchedule[]> {
    return this.buildingELMTScheduleSource.asObservable();
  }

  get currentBuildingSchedules(): EmergencyLightingSchedule[] {
    return this.buildingELMTScheduleSource.getValue() as EmergencyLightingSchedule[];
  }

  public saveSchedule(buildingId: number, schedule: IElmtScheduleDto): Observable<IEmergencyLightingSchedule> {
    return this.emergencyLightingScheduleResource.addResource(buildingId, schedule);
  }

  public getSchedules(buildingId: number): Observable<IElmtScheduleDto[]> {
    return this.emergencyLightingScheduleResource.retrieveAllByParentId(buildingId);
  }

  public updateSchedule(schedule: IElmtScheduleDto): Observable<IEmergencyLightingSchedule> {
    return this.emergencyLightingScheduleResource.updateResource(schedule.id, schedule);
  }

  validateSchedule(buildingId: number, payload: IElmtScheduleDto): Observable<void> {
    return this.emergencyLightingScheduleResource.validateSchedule(buildingId, payload);
  }

  public deleteSchedule(buildingId: number, scheduleId: number): Observable<{}> {
    return this.emergencyLightingScheduleResource.deleteSchedule(buildingId, scheduleId);
  }

  public getImpactedEmailReportsByDeletion(buildingId: number, scheduleId: number): Observable<string[]> {
    return this.emergencyLightingScheduleResource.getImpactedEmailReportsByDeletion(buildingId, scheduleId);
  }

  fetchSchedules(buildingId: number): void {
    this.getSchedules(buildingId)
      .pipe(
        combineLatestWith(this.userService.getBuilding(buildingId)),
        switchMap((data) => {
          const schedules = data[0];
          const building = data[1];
          if (schedules.length > 0) {
            const nodeIdSelectors = schedules.map((schedule) => {
              const clonedSchedule = { ...schedule };
              if (schedule.floorIds.length === 0) {
                clonedSchedule.floorIds = building.floors.map((f) => f.id); // to avoid getting count of all the em-driver in the table when no filter pass for nodeSelector
              }
              return {
                id: clonedSchedule.id,
                selector: NodeSelector.fromElmtScheduleDto(clonedSchedule)
              };
            });
            return this.sensorNodeService.getNodeCountsForElmtSchedules(nodeIdSelectors).pipe(
              map((scheduleIdsToNodeCountMap) => {
                return schedules.map((schedule) => {
                  schedule.nodeCount = scheduleIdsToNodeCountMap[schedule.id];
                  return schedule;
                });
              }),
              catchError(() => of(schedules.map((s) => ({ ...s, nodeCount: 'N/A' }))))
            );
          } else {
            return of([]);
          }
        })
      )
      .subscribe({
        next: (schedules) => {
          const collapsedSchedules = schedules.map((schedule) => {
            schedule.timezone = TimezoneUtils.mapUtcToKnownTimezone(schedule.timezone);
            const augmentedSchedule = EmergencyLightingSchedule.fromDto(schedule);
            augmentedSchedule.isCollapsed = true;
            return augmentedSchedule;
          });

          collapsedSchedules.sort((s1, s2) => s1.name.localeCompare(s2.name));
          this.buildingELMTScheduleSource.next(collapsedSchedules);
        }
      });
  }
}
