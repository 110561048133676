<div class="or-lighting-test-schedule-tile-content">
  <ul class="or-simple-floor-list" *ngIf="selectedFloors?.length > 0">
    <li *ngFor="let floor of selectedFloors">
      <span class="or-simple-floor-list-number" [attr.data-cy]="schedule?.id+'-banner-schedule-floor-number'">{{floor.floorNumber}}</span>
      <span class="or-simple-floor-list-name" [attr.data-cy]="schedule?.id+'-banner-schedule-floor-name'">{{floor.name}}</span>
    </li>
  </ul>
  <ul class="or-simple-tag-list" *ngIf="selectedTags?.length > 0">
    <li *ngFor="let tag of selectedTags" [ngStyle]="decorateTag(tag)">
      <span class="or-simple-tag-list-name" [attr.data-cy]="schedule?.id+'-banner-schedule-tag'">{{tag.name}}</span>
    </li>
  </ul>
  <ul class="or-simple-weekday-list">
    <li class='calendar-center'>
      <mat-icon>calendar_today</mat-icon>
      <span [attr.data-cy]="schedule?.id+'-schedule-scheduled-date'">{{getStartDate() | date:'fullDate'}}</span>
    </li>
  </ul>
  <ul class="or-simple-weekday-list">
    <li class='calendar-center'>
      <span [attr.data-cy]="schedule?.id+'-schedule-reoccurrence'">{{schedule.repeat?.displayName}}</span>
    </li>
  </ul>
  <div>
    <app-timerange-widget
      [startTime]="getStartTime()"
      [endTime]="getEndTime()"
      [minHours]="1"
      [isValidRange]="isValidTimeRange"
    ></app-timerange-widget>
  </div>
  <div class="or-lighting-test-schedule-tile-report">
    <div class="or-lighting-test-schedule-tile-report-icon">
      <span
        class="or-icon or-inverse or-tiny or-icon-crash-test or-lighting-test-schedule-tile-report-icon-type"
      ></span>
      <span
        class="or-icon or-inverse or-tiny or-icon-cross or-lighting-test-schedule-tile-report-icon-status or-lighting-test-schedule-tile-report-icon-status-fail"
      ></span>
      <span
        class="or-icon or-inverse or-tiny or-icon-checkmark or-lighting-test-schedule-tile-report-icon-status or-lighting-test-schedule-tile-report-icon-status or-lighting-test-schedule-tile-report-icon-status-ok"
      ></span>
    </div>
    <div class="or-lighting-test-schedule-tile-report-summary">
      <p class="or-lighting-test-schedule-tile-report-summary-last-run">
        <span>Last <span [attr.data-cy]="schedule?.id+'-schedule-test-type'">{{schedule.testsType?.displayValue}}</span>
          test run:&nbsp;<span [attr.data-cy]="schedule?.id+'-schedule-last-run-time'">{{schedule.lastRun | date:'MMMM d, y, h:mm:ss a'}}</span></span>
      </p>
      <p
        class="or-lighting-test-schedule-tile-report-summary-current-run"
        *ngIf="schedule.currentRunStartTime">
        <span>Currently running since:&nbsp;<span [attr.data-cy]="schedule?.id+'-schedule-currently-running-since'">{{schedule.currentRunStartTime | date:'MMMM d, y, h:mm:ss a'}}</span></span>
        <span>, estimated to finish at:&nbsp;<span [attr.data-cy]="schedule?.id+'-schedule-currently-run-finish-time'">{{schedule.currentRunEstimatedEndTime | date:'MMMM d, y, h:mm:ss a'}}</span></span>
      </p>
      <p class="or-lighting-test-schedule-tile-report-summary-next-run"
         *ngIf="schedule.nextRunStartTime">
        <span>Next run starts on:&nbsp;<span [attr.data-cy]="schedule?.id+'-schedule-next-run-start-time'">{{schedule.nextRunStartTime | date:'MMMM d, y, h:mm:ss a'}}</span></span>
        <span>, estimated to finish at: <span [attr.data-cy]="schedule?.id+'-schedule-next-run-finish-time'">{{schedule.nextRunEstimatedEndTime | date:'MMMM d, y, h:mm:ss a'}}</span></span>
      </p>
      <p class="or-lighting-test-schedule-tile-report-summary-next-run"
         *ngIf="!schedule.nextRunStartTime">
        <span [attr.data-cy]="schedule?.id+'-no-future-runs-banner'">There are currently no future runs scheduled for this schedule</span>
      </p>
    </div>
  </div>
  <div class="or-lighting-test-schedule-tile-report">
    <div class="or-lighting-test-schedule-tile-report-icon">
      <span
        class="or-icon or-inverse or-tiny or-icon-stopwatch or-lighting-test-schedule-tile-report-icon-type"
      ></span>
    </div>
    <div class="or-lighting-test-schedule-tile-report-summary">
      <p>
        <span [attr.data-cy]="schedule?.id+'-schedule-test-strategy'">{{schedule.schedulingStrategy}}</span><span> scheduling strategy</span>
      </p>
    </div>
  </div>
</div>
