import { OrNodesListController } from '@angularjs/angular/modules/layout/or-nodes-list/OrNodesListController';
import { SensorNode } from '@angularjs/or/api/building/SensorNode';
import { Map } from '@angularjs/or/util/Map';
import { ISecurityService } from '@angularjs/or/services/ISecurityService';
import { CopyToClipboardService } from '@angularjs/or/angular/services/CopyToClipboardService';
import { FilterService } from '@angularjs/or/services/FilterService';
import { UIRefreshService } from '@angularjs/or/services/UIRefreshService';
import { LightingConfigurationService } from '@angularjs/or/services/LightingConfigurationService';
import { SensorNodeService } from '@angularjs/or/services/SensorNodeService';

export class OrLightingConfigurationNodesListController extends OrNodesListController {
  public fields: Map<string> =
    SensorNode.getLightingConfigurationOrderableProperties();
  private floorId: number;
  private updatingTimeoutInSeconds = 60;
  constructor(
    protected $scope: angular.IScope,
    protected $element,
    protected securityService: ISecurityService,
    protected copyToClipboardService: CopyToClipboardService,
    protected filterService: FilterService,
    protected uiRefreshService: UIRefreshService,
    protected lightingConfigurationService: LightingConfigurationService,
    protected nodeService: SensorNodeService
  ) {
    super(
      $scope,
      $element,
      securityService,
      copyToClipboardService,
      filterService,
      uiRefreshService,
      nodeService
    );
  }

  protected getNodesAsCsv() {
    const headerData = 'Id, Address(Portal)\n';
    const nodesData = this.nodes
      .map((node) => {
        return node.id + ',' + node.address16;
      })
      .join('\n');
    return headerData + nodesData;
  }
}
