import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FeatureResource } from '@app/shared/resources/feature.resource';
import { NavigationService } from '@services/navigation/navigation.service';

@Injectable({
  providedIn: 'root'
})
/*
  this service can be used either as a directive to remove elements
  or via the service directly.

  ng+ Directive: isFeatureAvailable="Key of Feature in string"
  AngularJS Directive: is-feature-available="Key of Feature in string" (Is part of the IsAuthorized Directive)
 */
export class FeatureService {
  constructor(private featureResource: FeatureResource, private navService: NavigationService) {}

  isAvailable(key: string): Observable<boolean> {
    const buildingId = this.navService.buildingId;
    if (buildingId != null) {
      return this.featureResource.getFeatureForBuilding(key, buildingId);
    } else {
      return this.featureResource.getFeature(key);
    }
  }
}
