
<div id="main">

    <div class="initContainer">
        <div class="left">
            <textarea spellcheck="false"  id="textarealeft" placeholder="Enter JSON to compare, enter an URL to JSON" tabindex="1"
                      [innerHTML]="leftTree | json">
            </textarea>
            <pre id="errorLeft" class="error"></pre>
        </div>

        <div class="right">
            <textarea spellcheck="false" class="right" id="textarearight" placeholder="Enter JSON to compare, enter an URL to JSON" tabindex="2"
                      [innerHTML]="rightTree | json">
            </textarea>
            <pre id="errorRight" class="error"></pre>
        </div>
    </div>
    <div class="or-flex-container">
        <div class="dialog-pane-title">
            <span>Portal Configuration</span>
        </div>
        <div class="dialog-pane-title">
            <span>Rigado Configuration</span>
        </div>
    </div>
    <div class="diffcontainer">
        <pre id="out" class="left codeBlock"></pre>
        <pre id="out2" class="right codeBlock"></pre>
        <ul style="display: none;" id="toolbar" class="toolbar"></ul>
    </div>
</div>

