<form #circadianForm="ngForm" name="circadianForm" class='or-form form-circadian-curve' novalidate>
  <div class='or-form-body'>
    <ol class='or-form-items form-circadian-curve-details'>
      <li class='or-form-item form-circadian-curve-details-name' >
        <label class='or-label or-label-above'>Circadian Curve Name</label>
        <label *ngIf="details.active">{{details.name}}</label>
        <input
          class="or-input"
          type="text"
          maxlength="64"
          [(ngModel)]="details.name"
          required
          #curveName="ngModel"
          name="curveName"
          [hidden]="details.active"
          [attr.data-cy]="'form-circadian-curve-name-' + curveId"
        />
        <div *ngIf="curveName.invalid && (curveName.dirty || curveName.touched)">
          <span class='form-alert' *ngIf="curveName.errors?.required">Name is required</span>
        </div>
      </li>
      <li class='or-form-item or-form-rule-details-floors'>
        <label class='or-label or-form-rule-details-label'>If no floors or tags are selected, the curve will be applied to the whole building</label>
      </li>
      <li class='or-form-item or-form-rule-details-tags' *ngIf="tags$ | async as tags">
        <label class='or-label or-form-rule-details-label'>For tags (Optional)</label>
        <app-inline-tags
          [isEditable]="!details.active"
          [tags]="tags.tags"
          [displayTags]='tags.displayTags'
          [selectedTagIds]="getTagIds()"
          [isSelectionDisabled]="isBuildingLevelChecked"
          (updatedSelection)="selectedTagsChanged($event)"
        ></app-inline-tags>
      </li>
      <li class='or-form-item or-form-rule-details-floors'>
        <label class='or-label or-form-rule-details-label'>On specific floor (Optional)</label>
        <app-inline-floors
          [isEditable]="!details.active"
          [floors]="floors"
          [selectedFloorIds]="getFloorIds()"
          [isSelectionDisabled]="isBuildingLevelChecked"
          (updatedSelection)="selectedFloorsChanged($event)"
        ></app-inline-floors>
      </li>
    </ol>
  </div>

  <div class='or-form-body' #circadianCharts>
    <div class='or-flex-container space-around'>
      <div class='circadian-color-chart'>
        <div class='or-form-header'>
          <div class='or-flex-container space-around'>
            <h1 class='or-form-title'>Colour Temperature Curve</h1>
          </div>
        </div>
        <svg [attr.id]="'circadian-color-chart-' + curvesList.indexOf(details)"
             [attr.width]="width + margin.left + margin.right"
             [attr.height]="height + margin.top + margin.bottom">
          <g [attr.transform]="'translate(' + margin.left + ',' + margin.top + ')'">
            <g>
              <rect *ngFor="let r of colourRects"
                    [attr.x]="r.x"
                    [attr.y]="r.y"
                    [attr.height]="r.height"
                    [attr.width]="r.width"
                    [attr.fill]="r.fill">
              </rect>
            </g>
            <g id="x-axis"></g>
            <g id="y0-axis"></g>
            <path [attr.d]="colourTempLine" class='line' id="colourTempLine"></path>
            <circle *ngFor="let colourTempCircle of colourTemps" r='5' class='dot colour-level'
                    [attr.cx]="x(colourTempCircle.time)"
                    [attr.cy]="y0(colourTempCircle.colour_temp)"
                    [attr.data-cy]="'curve-' + curveId + '-colour-level-' + colourTempCircle.time"
                    [matTooltip]="'ColourTemp.: ' + colourTempCircle.colour_temp +'K&#013;' +
                  'Hour: ' + colourTempCircle.time + ':00'"
                    [matTooltipClass]="'curve-tooltip'"
                    (mousedown)="onMouseDown(1, colourTempCircle, $event)">
            </circle>
          </g>
        </svg>
      </div>
      <div class='circadian-light-chart'>
        <div class='or-form-header'>
          <div class='or-flex-container space-around'>
            <h1 class='or-form-title'>Light Level Curve</h1>
          </div>
        </div>
        <svg [attr.id]='"circadian-light-chart-" + curvesList.indexOf(details)'
             [attr.width]='width + margin.left + margin.right'
             [attr.height]='height + margin.top + margin.bottom'>
          <g [attr.transform]='"translate(" + margin.left + "," + margin.top + ")"'>
            <g>
              <rect *ngFor="let r of greyRects"
                    [attr.x]="r.x"
                    [attr.y]="r.y"
                    [attr.height]="r.height"
                    [attr.width]="r.width"
                    [attr.fill]="r.fill">
              </rect>
              <g id="x-axis"></g>
              <g id="y1-axis"></g>
              <path [attr.d]="lightLevelLine" class='line' style='stroke: red' id='lightLevelLine'></path>
              <circle *ngFor="let lightLevelCircle of lightLevels" r="5" class='dot light-level'
                      [attr.cx]="x(lightLevelCircle.time)"
                      [attr.cy]="y1(lightLevelCircle.light_level)"
                      [attr.data-cy]="'curve-' + curveId + '-light-level-' + lightLevelCircle.time"
                      [matTooltip]="'LightLevel: ' + lightLevelCircle.light_level +'%&#013;' +
                    'Hour: ' + lightLevelCircle.time + ':00'"
                      [matTooltipClass]="'curve-tooltip'"
                      (mousedown)="onMouseDown(2, lightLevelCircle, $event)">
              </circle>
            </g>
          </g>
        </svg>
      </div>
    </div>
  </div>
  <div class='or-form-body or-flex-container center'>
    <ul class='or-inline-items form-circadian-curve-actions'>
      <li class='or-inline-item form-circadian-curve-action'>
        <button
          class='or-button or-positive'
          (click)="save()"
          [disabled]="!circadianForm.valid"
          [attr.data-cy]="'save-circadian-curve-' + curveId"
          type="button">
          <span class='or-icon or-small or-inverse or-icon-checkmark'></span>
          <span class='or-button-label'>Save</span>
        </button>
      </li>
      <li class='or-inline-item form-circadian-curve-action'>
        <button
          class='or-button'
          (click)="close()"
          type="button"
          [attr.data-cy]="'reset-circadian-curve-' + curveId">
          <span class='or-icon or-small or-icon-reload'></span>
          <span class='or-button-label' [textContent]="isNew ? 'Close' : 'Reset and close' "></span>
        </button>
      </li>
      <li *ngIf="!isNew" class='or-inline-item or-form-schedule-action'>
        <button
          class='or-button or-negative'
          (click)="delete()"
          type="button"
          [attr.data-cy]="'delete-circadian-curve-' + curveId"
          [disabled] = "details.active">
          <span class='or-icon or-small or-inverse or-icon-trash'></span>
          <span class='or-button-label'>Delete circadian curve</span>
        </button>
      </li>
    </ul>
  </div>
</form>
