import * as angular from 'angular'; // Automatically added
import { ChartData } from '@angularjs/or/view/ChartData';
import { RenderableChart } from '@angularjs/or/view/RenderableChart';

export class OrChartController {
  public isBusy: boolean;
  public value: ChartData;

  constructor(private scope, private element) {}

  public $onInit() {
    this.scope.$watch('chart.value', () => {
      this.refresh();
    });
  }

  private refresh() {
    const container = angular.element(this.element).find('svg')[0];
    const renderableChart = new RenderableChart(this.value, container);
    renderableChart.render();
  }
}
