<form
  class="or-form or-form-role-details"
  [formGroup]="formRole"
  [ngClass]='{ "or-form-role-details-enable-labels": index === 0 }'
  novalidate="novalidate"
  [attr.data-cy]="isNew ? 'new-role-form': 'existing-roles-form'"
>
  <ul class="or-form-items">
    <li class="or-form-item or-form-role-details-name">
      <span class="or-label or-label-above">Role name</span
      ><input
      class="or-input"
      maxlength="256"
      formControlName="name"
      placeholder="Role name"
      type="text"
      data-cy="role-name"
    />
    </li>
    <ng-container *ngFor='let authTypeControl of authorityTypeControls.controls; let i=index'>
      <li class="or-form-item or-form-role-details-authority">
        <span class="or-label or-label-above">{{authorityTypes[i].getName()}}</span>
        <app-checkbox
          [formControl]='authTypeControl'
          (valueChange)='authorityTypeChange(authTypeControl.value, authorityTypes[i].value)'
          [dataCy]="'chkbox-authority-type-permission-'+ authorityTypes[i].value.toString()">
        </app-checkbox>
      </li>
    </ng-container>
    <li class="or-form-item or-form-role-details-actions">
      <ul class="or-inline-items">
        <li class="or-inline-item" *ngIf="isNew">
          <button
            mat-raised-button
            class="or-button or-iconic or-primary or-positive"
            type="button"
            (click)="addRole()"
            [disabled]="!(hasRolePermission() | async) || isBusy || formRole.pristine || formRole.invalid ? true : null"
            title="Add Role"
            data-cy="btn-add-role"
          >
            <span class="or-icon or-small or-inverse or-icon-plus"></span>
          </button>
        </li>
        <li class="or-inline-item" *ngIf="!isNew">
          <button
            mat-raised-button
            class="or-button or-iconic or-primary or-positive"
            type="button"
            (click)="updateRole()"
            [disabled]="!(hasRolePermission() | async) || isBusy || formRole.pristine || formRole.invalid"
            title="Update Role"
            data-cy='btn-update-role'
          >
            <span class="or-icon or-small or-inverse or-icon-checkmark"></span>
          </button>
        </li>
        <li class="or-inline-item">
          <button
            mat-raised-button
            class="or-button or-iconic"
            type="button"
            color='accent'
            (click)="resetRole()"
            [disabled]="!(hasRolePermission() | async) || isBusy || formRole.pristine"
            title="Reset Changes"
            data-cy='btn-reset-role'
          >
            <span class="or-icon or-small or-icon-reload"></span>
          </button>
        </li>
        <li class="or-inline-item" *ngIf="!isNew">
          <button
            mat-raised-button
            class="or-button or-iconic"
            type="button"
            color='warn'
            (click)="deleteRole()"
            [disabled]="!(hasRolePermission() | async) || isBusy"
            title="Delete Role"
            data-cy='btn-delete-role'
          >
            <span class="or-icon or-small or-inverse or-icon-trash"></span>
          </button>
        </li>
      </ul>
    </li>
  </ul>
</form>
