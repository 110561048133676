enum AuthMethodType {
  LINKEDIN,
  GLUU,
  TOKEN,
  SAML,
  AUTH0
}

export class AuthMethod {
  constructor(public value: AuthMethodType, public displayName: string, public isAdvertised: boolean = true) {}

  static readonly AUTH0 = new AuthMethod(AuthMethodType.AUTH0, 'Auth0');
  static readonly LINKEDIN = new AuthMethod(AuthMethodType.LINKEDIN, 'LinkedIn');
  static readonly GLUU = new AuthMethod(AuthMethodType.GLUU, 'Local Provider');
  static readonly TOKEN = new AuthMethod(AuthMethodType.TOKEN, 'Token', false);
  static readonly SAML = new AuthMethod(AuthMethodType.SAML, 'SAML', false);

  static fromType(value: AuthMethodType) {
    switch (value) {
      case AuthMethodType.AUTH0:
        return AuthMethod.AUTH0;
      case AuthMethodType.LINKEDIN:
        return AuthMethod.LINKEDIN;
      case AuthMethodType.GLUU:
        return AuthMethod.GLUU;
      case AuthMethodType.TOKEN:
        return AuthMethod.TOKEN;
      case AuthMethodType.SAML:
        return AuthMethod.SAML;
    }
  }

  static fromString(value:string) {
    switch (value) {
      case "AUTH0":
        return AuthMethod.AUTH0;
      case "LINKEDIN":
        return AuthMethod.LINKEDIN;
      case "GLUU":
        return AuthMethod.GLUU;
      case "TOKEN":
        return AuthMethod.TOKEN;
      case "SAML":
        return AuthMethod.SAML;
    }
  }

  toString() {
    switch (this.value) {
      case AuthMethodType.AUTH0:
        return "AUTH0";
      case AuthMethodType.LINKEDIN:
        return "LINKEDIN";
      case AuthMethodType.GLUU:
        return "GLUU";
      case AuthMethodType.TOKEN:
        return "TOKEN";
      case AuthMethodType.SAML:
        return "SAML";
      default:
        return "AUTH0";
    }
  }
}
