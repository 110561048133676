export class DdlMode {
  enabled: boolean;
  buildingId: number;
  id: number;
}

export class DdlNode {
  address: number;
  buildingId: number;

  constructor(address: number, buildingId: number) {
    this.address = address;
    this.buildingId = buildingId;
  }
}

export class DdlTag {
  id: number;
  name: string;
  color: string;
}

export class DdlSetting {
  public id?: number;
  public outdoorSensorNodeId: number;
  public luminaireTagId: number;
  public address: number;
  public buildingId: number;
}
