export interface Floor {
  buildingId?: number;
  floorNumber?: number;
  name?: string;
  note?: string;
  floorplan?: string;
  id?: number;
}

export function floorCompare(f1: Floor, f2: Floor): number {
  if (f1.floorNumber == null) {
    return -1;
  } else if (f2.floorNumber == null) {
    return 1;
  } else {
    return f1.floorNumber - f2.floorNumber;
  }
}
