<div class="dialog" [ngClass]="type">
  <div class="mt-2 mr-2 float-end hover:cursor-pointer" (click)="ok()">
    <mat-icon class="close-icon" [ngClass]="type">close</mat-icon>
  </div>
  <mat-dialog-content class="no-overflow-dialog">
    <div class="flex flex-row">
      <span class="dialog-icon" [ngClass]="type">
            <mat-icon class="info-icon" fontIcon="error" [ngClass]="type"></mat-icon>
      </span>
      <div class="flex flex-col mx-4 ">
        <span class="mt-2 text-xl font-medium title" [ngClass]="type">{{title}}</span>
        <span class="text-base content" [ngClass]="type" data-cy="dialog-content-span">{{ content }}</span>
      </div>
    </div>
  </mat-dialog-content>
</div>
