@if (buildingId) {
<div id='orAnalytics'>
    <div class='or-content-expanded'>
      <div class='or-content-body or-flex-container'>
        <div class="or-panel-metrics or-panel or-active">
          <app-panel-toggle label="Data Set" icon="chart"></app-panel-toggle>
          <mat-slide-toggle (click)="toggleInflux()" [ngModel]="isInflux" color="primary" [attr.data-cy]="'analytics-influx'">
            Using influx
          </mat-slide-toggle>
          <div class="or-panel-content">
            <app-metric-selector></app-metric-selector>
          </div>
        </div>
        <div class="or-panel-floorplan or-panel-main or-panel or-active or-solid">
          <app-panel-toggle label="Floor Plan" icon="plan"></app-panel-toggle>
          <div class="or-panel-content">
            <app-floorplan [buildingId]="buildingId"></app-floorplan>
          </div>
        </div>
        <div class="or-panel-tags or-panel or-active">
          <app-panel-toggle label="Tags" icon="tag"></app-panel-toggle>
          <div class="or-panel-content">
            <app-tags></app-tags>
          </div>
        </div>
      </div>
    </div>
  </div>
}
