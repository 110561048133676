export class NumericUtils {
  public static roundFloatingPoint(value: number, decimalPoints: number): number {
    return parseFloat(value.toFixed(decimalPoints));
  }

  public static random(min = 0, max = 1, generateFraction: () => number = () => Math.random()): number {
    return min + generateFraction() * (max - min);
  }

  static fixDigitsInValue(value: string | number, decimalPlaces = 2): string {
    return String(value)
      .split(' ')
      .map((item) => {
        const matched = item.match(/\d+\.\d+/g);
        if (matched != null) {
          return Number(matched[0]).toFixed(decimalPlaces);
        }
        return item;
      })
      .join(' ');
  }
}
