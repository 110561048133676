<mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
  <mat-tab label='Process'>
    <mat-card class='mat-elevation-z8 job-information-card'>
      <ng-container *ngIf="job$ | async as job">
        <fieldset class='job-information-title'>
          <legend>{{job.name}} - {{job.jobTaskTemplateName}}</legend>
        </fieldset>
        <app-gateway-details
          [gateways]='gateways$ | async'
          [job]='job$ | async'
          [exportStatus]="exportStatus"
          (jobStartEnabled)='onJobStartStateChanged($event)'
          (exportCompleteEvent)='exportCompleteEvent.emit($event)'>
        </app-gateway-details>
        <app-job-tasks
          [job]='job$ | async'
          [tasks]='tasks'
          [jobStartEnabled]='jobStartEnabled'>
        </app-job-tasks>
      </ng-container>
    </mat-card>
  </mat-tab>
  <mat-tab label='Notifications'>
    <mat-card class='mat-elevation-z8 job-information-card'>
      <div class="job-information-notifications">
        <button
          class='job-information-clear-otap-notifications-button'
          mat-raised-button
          (click)='clearOtapNotifications()'
          color='accent'
          type='button'
          [attr.data-cy]="'clear-otap-notifications'">
          <span class='or-button-label'>Clear</span>
        </button>
        <mat-accordion class='otap-notification-headers-align' multi>
          <div *ngFor='let otapNotification of otapNotifications'>
            <mat-expansion-panel [expanded]='true' hideToggle='true'>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ otapNotification.timestamp }}
                </mat-panel-title>
                <mat-panel-description>
                  {{ otapNotification.gateway }}
                </mat-panel-description>
              </mat-expansion-panel-header>
              <code>
                <pre>{{ otapNotification.message }}</pre>
              </code>
            </mat-expansion-panel>
          </div>
        </mat-accordion>
      </div>
    </mat-card>
  </mat-tab>
</mat-tab-group>

