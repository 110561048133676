import { OrFormCircadianCurveController } from '@angularjs/angular/modules/layout/or-form-circadian-curve/OrFormCircadianCurveController';

export class OrFormCircadianCurveDirective {
  constructor() {
    return {
      bindToController: true,
      controller: ['$scope', 'CircadianCurveService', 'TagService', 'BuildingService', OrFormCircadianCurveController],
      controllerAs: 'circadianCurve',
      replace: true,
      restrict: 'E',
      scope: {
        details: '=',
        curvesList: '=?',
        onSaveNewCurve: '&?',
        onDeleteCurve: '&?',
        onClose: '&?',
        isNew: '=?'
      },
      template: require('raw-loader!./or-form-circadian-curve.html').default
    };
  }
}
