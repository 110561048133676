import { EmergencyLightingTestState as NewTestState } from '@app/shared/models/emergency-lighting-test-state';

export class EmergencyLightingTestState {
  constructor(private value: EmergencyLightingTestState_, private displayValue: string) {}

  public static SCHEDULED = new EmergencyLightingTestState('SCHEDULED', 'Scheduled');
  public static INITIATED = new EmergencyLightingTestState('INITIATED', 'Initiated');
  public static IN_PROGRESS = new EmergencyLightingTestState('IN_PROGRESS', 'In progress');
  public static SUCCEEDED = new EmergencyLightingTestState('SUCCEEDED', 'Succeeded');
  public static FAILED = new EmergencyLightingTestState('FAILED', 'Failed');
  public static TIMED_OUT = new EmergencyLightingTestState('TIMED_OUT', 'Timed Out');
  public static CANCELLED = new EmergencyLightingTestState('CANCELLED', 'Cancelled');

  public static all(): EmergencyLightingTestState[] {
    return [
      EmergencyLightingTestState.SCHEDULED,
      EmergencyLightingTestState.INITIATED,
      EmergencyLightingTestState.IN_PROGRESS,
      EmergencyLightingTestState.SUCCEEDED,
      EmergencyLightingTestState.FAILED,
      EmergencyLightingTestState.TIMED_OUT,
      EmergencyLightingTestState.CANCELLED
    ];
  }

  public static fromValue(value: string): EmergencyLightingTestState {
    switch (value) {
      case EmergencyLightingTestState.SCHEDULED.value:
        return EmergencyLightingTestState.SCHEDULED;
      case EmergencyLightingTestState.INITIATED.value:
        return EmergencyLightingTestState.INITIATED;
      case EmergencyLightingTestState.IN_PROGRESS.value:
        return EmergencyLightingTestState.IN_PROGRESS;
      case EmergencyLightingTestState.SUCCEEDED.value:
        return EmergencyLightingTestState.SUCCEEDED;
      case EmergencyLightingTestState.FAILED.value:
        return EmergencyLightingTestState.FAILED;
      case EmergencyLightingTestState.TIMED_OUT.value:
        return EmergencyLightingTestState.TIMED_OUT;
      case EmergencyLightingTestState.CANCELLED.value:
        return EmergencyLightingTestState.CANCELLED;
    }
  }

  public static fromNewTestState(state: NewTestState): EmergencyLightingTestState {
    return state ? new EmergencyLightingTestState(state.val, state.display) : null;
  }

  public equals(other: EmergencyLightingTestState): boolean {
    return other && this.equalsString(other.value);
  }

  public equalsString(other: string): boolean {
    return other !== null && other === this.value;
  }

  public get display(): string {
    return this.displayValue;
  }

  public get val(): EmergencyLightingTestState_ {
    return this.value;
  }
}

type EmergencyLightingTestState_ =
  | 'SCHEDULED'
  | 'INITIATED'
  | 'IN_PROGRESS'
  | 'RESULT_PENDING'
  | 'SUCCEEDED'
  | 'FAILED'
  | 'TIMED_OUT'
  | 'CANCELLED';
