import { Inject, Injectable } from '@angular/core';
import { Resource } from '@app/shared/resources/resource';
import { IResource } from '@app/shared/resources/resource.interface';
import { HttpClient } from '@angular/common/http';
import { Environment, environmentToken } from '@environment';
import { SensorNode } from '@app/shared/models/sensor-node';
import { Observable } from 'rxjs';
import { NodeSelectorType } from '@app/shared/models/node-selector';
import { Tag } from '@app/shared/models/tag.interface';
import { NodePoint } from '@app/shared/models/unmapped-nodes-datasource';

@Injectable({
  providedIn: 'root'
})
export class SensorNodeResource extends Resource<SensorNode, number> implements IResource<SensorNode, number> {
  constructor(protected httpClient: HttpClient, @Inject(environmentToken) environment: Environment) {
    super(httpClient, `${environment.apiUrl + environment.apiPath + '/node'}`);
  }
  getNodesForFloor(floorId: number): Observable<SensorNode[]> {
    return this.httpClient.get<SensorNode[]>(`${this.baseUrl}/floor/${floorId}`);
  }

  getNodesByIds(nodeIds: number[]): Observable<SensorNode[]> {
    return this.httpClient.post<SensorNode[]>(`${this.baseUrl}/by_ids`, nodeIds);
  }

  getNodesForBuilding(buildingId: number): Observable<SensorNode[]> {
    return this.httpClient.get<SensorNode[]>(`${this.baseUrl}/building/${buildingId}`);
  }
  getNodeCountsForElmtSchedules(
    nodeSelectors: { id: number; selector: NodeSelectorType }[]
  ): Observable<Record<number, number>> {
    return this.httpClient.post<Record<number, number>>(`${this.baseUrl}/count/selectorElmt`, nodeSelectors);
  }
  getNodeDataForElmtLogs(nodeSelector: NodeSelectorType): Observable<SensorNode[]> {
    return this.httpClient.post<SensorNode[]>(`${this.baseUrl}/selectorElmt`, nodeSelector);
  }
  getTagsForNodes(nodeIds: number[]): Observable<Record<number, Tag[]>> {
    return this.httpClient.post<Record<number, Tag[]>>(`${this.baseUrl}/tagsForNodes`, nodeIds);
  }

  issueBlinkCommand(nodeId: number, buildingId: number): Observable<{}> {
    return this.httpClient.post(`${this.baseUrl}/${nodeId}/building/${buildingId}/blink`, null);
  }

  mapGenericNode(nodeToMap: NodePoint): Observable<{}> {
    return this.httpClient.post(`${this.baseUrl}/map-generic`, { nodeToMap });
  }

  deleteNodes(nodeIds: number[]): Observable<{}> {
    return this.httpClient.delete(this.baseUrl, { body: { nodeIds } });
  }

  updateNode(node: SensorNode): Observable<SensorNode> {
    return this.httpClient.put<SensorNode>(`${this.baseUrl}/${node.id}`, node);
  }

  tagNodes(multiTag: { tagIds: number[]; nodeIds: number[] }): Observable<{}> {
    return this.httpClient.post(`${this.baseUrl}s/tags`, multiTag);
  }

  unTagNodes(multiTag: { tagIds: number[]; nodeIds: number[] }): Observable<{}> {
    return this.httpClient.delete(`${this.baseUrl}s/tags`, { body: multiTag });
  }

  clearDriversForNodes(nodeIds: number[]): Observable<number> {
    return this.httpClient.put<number>(`${this.baseUrl}/clear-drivers`, { nodeIds });
  }
}
