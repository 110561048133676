import { EmergencyLightingTestOutline } from '@angularjs/or/api/building/EmergencyLightingTest';
import { Inject, Injectable } from '@angular/core';
import { Resource } from '@app/shared/resources/resource';
import { HttpClient } from '@angular/common/http';
import { Environment, environmentToken } from '@environment';
import { Observable } from 'rxjs';
import { EmergencyLightingTestState } from '@app/shared/models/emergency-lighting-test-state';
import { EmergencyLightingTestType } from '@app/shared/models/emergency-lighting-test-type';

@Injectable({
  providedIn: 'root'
})
export class EmergencyLightingTestResource extends Resource<IEmergencyTest, number> {
  constructor(protected httpClient: HttpClient, @Inject(environmentToken) environment: Environment) {
    super(httpClient, `${environment.apiUrl + environment.apiPath}/emergency_lighting`);
  }

  public startTest(buildingId: number, nodeId: number, type: EmergencyLightingTestType): Observable<{}> {
    return this.httpClient.get(`${this.baseUrl}/start/${buildingId}/${nodeId}?type=${type.toString()}`);
  }

  public startBatchTest(buildingId: number, nodes: number[], type: EmergencyLightingTestType): Observable<{}> {
    return this.httpClient.post(`${this.baseUrl}/start/${buildingId}?type=${type.toString()}`, nodes);
  }

  public getTestsForBuilding(buildingId: number): Observable<IEmergencyTest[]> {
    return this.httpClient.get<IEmergencyTest[]>(`${this.baseUrl}/${buildingId}`);
  }

  public getTestsForBuildingAndDrivers(buildingId: number, drivers: number[]): Observable<IEmergencyTest[]> {
    return this.httpClient.post<IEmergencyTest[]>(`${this.baseUrl}/${buildingId}/results-by-drivers`, drivers);
  }

  public updateTest(testId: number, test: EmergencyLightingTestOutline): Observable<{}> {
    return this.httpClient.put(`${this.baseUrl}/${testId}`, test);
  }

  public cancelTest(buildingId: number, driverId: number): Observable<{}> {
    return this.httpClient.put(`${this.baseUrl}/cancel/${buildingId}/${driverId}`, null);
  }

  public cancelBatchTest(buildingId: number, driverIds: number[]): Observable<{}> {
    return this.httpClient.put(`${this.baseUrl}/cancel/${buildingId}`, driverIds, { responseType: 'text' });
  }
}

export interface IEmergencyTest {
  id: number;
  buildingId: number;
  sensorNodeId: number;
  lastUpdated: number;
  driverId: number;
  timeStarted: Date;
  type: EmergencyLightingTestType;
  state: EmergencyLightingTestState;
  message: string;
  archived: boolean;
  nodeId?: number;
}
