import { Component, NgZone, OnInit } from '@angular/core';
import { Building } from '@app/shared/models/building.interface';
import { Floor } from '@app/shared/models/floor.interface';
import { User } from '@app/shared/models/user.interface';
import { NavigationService } from '@app/shared/services/navigation/navigation.service';
import { UserService } from '@app/shared/services/user/user.service';
import { BehaviorSubject, combineLatest, Observable, switchMap } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { FloorService } from '@services/floor.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-floors-menu',
  templateUrl: './floors-menu.component.html',
  styleUrls: ['./floors-menu.component.scss']
})
export class FloorsMenuComponent implements OnInit {
  isActive$ = new BehaviorSubject<boolean>(false);

  building$: Observable<Building>;
  building: Building;
  floors: Floor[];
  currentFloor: Floor;
  private user: User;

  constructor(
    private zone: NgZone,
    private userService: UserService,
    private floorService: FloorService,
    private navigationService: NavigationService,
    private route: ActivatedRoute
  ) {
    this.isActive$.next(false);
  }

  ngOnInit(): void {
    this.building$ = this.route.params.pipe(
      switchMap((params) =>
        combineLatest([
          this.userService.getBuilding(params.buildingId),
          this.userService.getCurrentUser(),
          this.floorService.getCurrentFloor()
        ])
      ),
      tap(([currentBuilding, currentUser, currentFloor]) => {
        this.building = currentBuilding;
        this.user = currentUser;
        this.floors = currentBuilding.floors;
        this.currentFloor = currentFloor;
      }),
      map(([currentBuilding]) => currentBuilding)
    );
  }

  public getLabelForFloor(floor: Floor): string {
    if (floor) {
      return floor.floorNumber + (floor.name ? ' - ' + floor.name : '');
    } else {
      return '';
    }
  }

  navigateToFloor(floorId: number): void {
    this.navigationService.navigateToActiveSection(this.user, this.building.id, floorId);
    this.toggle();
  }

  navigateToFloorAdministration(): void {
    this.navigationService.navigateToSection(this.navigationService.getSectionById('floors'), this.building.id);
    this.toggle();
  }

  produceFloorThumbnailImageStyle(floor: Floor): Record<'background-image', string> {
    const url = floor ? this.floorService.getFloorImageUrl(floor) : '';
    return { 'background-image': url ? 'url(' + url + ')' : 'none' };
  }

  toggle(): void {
    this.zone.run(() => {
      this.isActive$.next(!this.isActive$.getValue());
    });
  }

  isActive(): Observable<boolean> {
    return this.isActive$;
  }

  setActive(state: boolean = true): void {
    this.isActive$.next(state);
  }
}
