import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImageCachingService {
  private suffix = '?version=';
  private version = 1;

  public update() {
    this.version++;
  }

  public updateUrl(url: string) {
    return url + this.suffix + this.version;
  }

  public getSuffix(): string {
    return this.suffix + this.version;
  }
}
