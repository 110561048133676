import { EmDriver } from '@angularjs/or/api/building/EmDriver';
import { SensorNodeService } from '@angularjs/or/services/SensorNodeService';
import { FloorplanSensorNode } from '@angularjs/or/api/building/FloorplanSensorNode';
import { EmergencyLightingTestType } from '@angularjs/or/data/EmergencyLightingTestType';
import { Map } from '@angularjs/or/util/Map';
import { SensorNodeAlert } from '@angularjs/angular/modules/layout/or-sensor-node/SensorNodeAlert';
import { IObservableModifiable } from '@angularjs/or/util/IObservable';
import { ISelectableContext } from '@angularjs/or/api/query/outline/context/IContext';

export class OrEmDriverController {
  public driver: EmDriver;
  public node: FloorplanSensorNode;
  public isEmergencyLightingTestModeActive: boolean;
  public isManualFunctionalTestModeActive: boolean;
  public isManualDurationTestModeActive: boolean;
  public isCancelTestModeActive: boolean;
  public disableSelection: boolean;
  public isCumulativeSelectionActive: boolean;
  public markFaulty: boolean;
  public markEmergencyLight: boolean;
  public buildingId: number;
  public hasTestBeenActivated = false;
  public showNodeProperties: boolean;
  public notificationMode: boolean;
  public alerts: SensorNodeAlert[];
  public zoomLevel: number;

  constructor(
    private scope: ng.IScope,
    private nodesService: SensorNodeService,
    private selectableContext: IObservableModifiable<ISelectableContext>
  ) {}

  public $onInit(): void {
    this.alerts = this.driver.alerts;
  }

  public isDriverSelected(): boolean {
    return (
      this.nodesService.isEmDriverSelected(this.driver.id) ||
      this.nodesService.isNodeSelected(this.driver.nodeId)
    );
  }

  private shouldRunTest(testType: string): boolean {
    // TODO: Remove/Fix it when EM test bug been fixed.
    if (this.driver.inInitiatedTest) {
      alert(
        "Please wait until test progresses into 'running' state before starting a new test."
      );
      return false;
    }
    // TODO: Remove/Fix it when EM test bug been fixed.
    if (this.node.isDriverEmergencyLightingTestStarted) {
      alert(
        'Another driver in this setup has already started an emergency lighting test. Please wait ' +
          "until it progresses into 'running' state before starting a new test."
      );
      return false;
    }
    // TODO: Remove/Fix it when EM test bug been fixed.
    if (
      !this.hasRunningTest() &&
      this.node.isTooManyDriverEmergencyLightingTest
    ) {
      alert(
        'Emergency lighting test for all drivers cannot be run at the same time.'
      );
      return false;
    }

    if (
      confirm(
        'Are you sure you wish to run a ' + testType + ' test for this node?'
      )
    ) {
      if (this.hasRunningTest()) {
        return confirm(
          'A test is already running on this node.\r\n Running a new test cancels the current one. Continue Anyway?'
        );
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  private shouldCancelTest(): boolean {
    if (!this.driver.activeTest) {
      alert(
        'No functional or duration test is currently running for this node.'
      );
      return false;
    }
    // TODO: Remove/Fix it when EM test bug been fixed.
    if (!this.driver.inProgressTest) {
      alert(
        'It is not possible to cancel a test unless it is ‘running’.' +
          ' If the test does not transition to ‘running’ it will time out in approximately 2 minutes.'
      );
      return false;
    }
    // TODO: Remove/Fix it when EM test bug been fixed.
    if (this.node.isDriverEmergencyLightingTestStarted) {
      alert(
        'Another driver in this setup has already started an emergency lighting test. Please wait ' +
          "until it progresses into 'running' state before starting or canceling a new test."
      );
      return false;
    }

    return confirm(
      'Are you sure you wish to cancel the running test for this node?'
    );
  }

  private hasRunningTest(): boolean {
    return this.driver.inProgressTest || this.driver.inInitiatedTest;
  }

  private isAnyTestModeActive(): boolean {
    return (
      this.isManualFunctionalTestModeActive ||
      this.isManualDurationTestModeActive ||
      this.isCancelTestModeActive
    );
  }

  public onClick(): void {
    if (this.isManualFunctionalTestModeActive) {
      if (this.shouldRunTest('functional')) {
        this.nodesService
          .startEmergencyLightingTest(
            this.buildingId,
            this.driver,
            EmergencyLightingTestType.FUNCTION
          )
          .then(() => {
            this.scope.$apply(() => (this.hasTestBeenActivated = true));
          });
      }
    } else if (this.isManualDurationTestModeActive) {
      if (this.shouldRunTest('duration')) {
        this.nodesService
          .startEmergencyLightingTest(
            this.buildingId,
            this.driver,
            EmergencyLightingTestType.DURATION
          )
          .then(() => {
            this.scope.$apply(() => (this.hasTestBeenActivated = true));
          });
      }
    } else if (this.isCancelTestModeActive) {
      if (this.shouldCancelTest()) {
        this.nodesService.cancelEmergencyLightingTest(
          this.buildingId,
          this.driver
        );
      }
    } else {
      this.updateSelection(this.driver);
    }
  }

  public onMouseLeave($event: MouseEvent): void {
    this.showNodeProperties = false;
  }

  public onMouseEnter($event: MouseEvent): void {
    if ($event.buttons === 0) {
      this.showNodeProperties = true;
    }
  }

  public produceClassForEmDriver(): {} {
    const styleClass: { [p: string]: any } = {};

    styleClass.selected = !this.disableSelection && this.isDriverSelected();
    styleClass.faulty =
      this.node.properlyMapped &&
      this.driver.isFaulty &&
      this.markFaulty &&
      !this.driver.connected &&
      !this.node.connected;
    styleClass['test-initiated'] =
      this.isEmergencyLightingTestModeActive && this.driver.activeTest;
    styleClass['test-failed'] =
      this.isEmergencyLightingTestModeActive && this.driver.failedTest;
    styleClass['test-passed'] =
      this.isEmergencyLightingTestModeActive && this.driver.passedTest;
    styleClass.disconnected =
      this.markFaulty && (!this.driver.connected || !this.node.connected);
    styleClass['is-highlighted'] = this.node.isHighlighted;
    styleClass['is-changed'] = this.node.isChanged;
    if (this.markEmergencyLight) {
      styleClass.marked = true;
      styleClass.enabled = true;
    }

    return styleClass;
  }

  public produceDriverStyle(x: number, y: number): Map<string> {
    const style: Map<string> = {};
    style.left = this.driver.x + 'px';
    style.top = this.driver.y + 'px';
    return style;
  }

  private updateSelection(targetDriver): void {
    if (this.disableSelection) {
      return;
    }
    if (targetDriver.id >= 0) {
      if (!this.isCumulativeSelectionActive) {
        this.nodesService.clearSelection();
      }
      this.nodesService.updateSelection(
        targetDriver,
        this.isEmergencyLightingTestModeActive
      );

      this.selectableContext.change((value) => {
        const driverIds = this.nodesService.selectedEmDrivers
          .value()
          .map((driver) => driver.id);
        value.selectedEmDrivers = driverIds;
      });
    }
  }

  public produceClassForEmDriverIcon(): {} {
    const styleClass = {};
    styleClass['or-icon-node'] = true;
    styleClass['or-icon-emergency'] = true;

    return styleClass;
  }

  public getDriverIdText(): string {
    let text = 'Emergency Device';
    text += ' #' + this.driver.id;
    return text;
  }

  public get propertiesStyle(): Map<string> {
    const style: Map<string> = {};
    const transform = 'translate(-50%, -8px) scale(' + 1 / this.zoomLevel + ')';
    style.transform = transform;
    style['-webkit-transform'] = transform;

    return style;
  }
}
