<div class="or-dropdown or-floors-menu" [ngClass]="{ 'or-active': (isActive() | async) }" *ngIf="building$ | async">
  <button  [matMenuTriggerFor]="menu" data-cy="floor-drop-down-button"
           class="or-button or-rounded or-dropdown-trigger or-dropdown-trigger-inverse"
           (click)="toggle()" (menuClosed)='setActive(false)'>
    <span class="or-button-thumbnail" [ngStyle]="produceFloorThumbnailImageStyle(currentFloor)"></span>
    <span class="or-button-label" data-cy="current-floor-label">{{getLabelForFloor(currentFloor)}}</span>
  </button>
</div>

<mat-menu #menu="matMenu" class='mt-2 max-h-[70dvh]'>
  @for (floor of floors; track floor.id) {
    <div mat-menu-item>
      <div class='flex flex-row px-2 py-2 h-[75px]'>
<!--        <div [attr.data-cy]="'floor-thumbnail-' + floor.id" class='w-[65px] relative' (click)="navigateToFloor(floor.id)">-->
<!--          <div class="or-button-thumbnail or-buildings-menu-item-thumbnail" [ngStyle]="produceFloorThumbnailImageStyle(floor)"></div>-->
<!--        </div>-->
        <a [attr.data-cy]="'floor-name-link-' + floor.id" class="or-floors-menu-link px-4 " (click)="navigateToFloor(floor.id)">
          <div>
            <span class="floor-name" [textContent]="getLabelForFloor(floor)"></span>
            <span class="floor-description" [textContent]="floor.note"></span>
          </div>
        </a>
      </div>
    </div>
  }
  <div class='px-4 py-2'>
    <button data-cy="manage-floor-button" class="or-button or-primary or-floors-menu-manage" (click)="navigateToFloorAdministration()">
      <span class="or-button-label">Manage Floors</span>
    </button>
  </div>
</mat-menu>
