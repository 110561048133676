import { SecurityService } from '@angularjs/or/angular/services/SecurityService';
import { Building } from '@angularjs/or/api/building/Building';
import { IBuildingService } from '@angularjs/or/services/IBuildingService';
import { FeatureService } from '@app/shared/services/feature.service';
import { TenantService } from '@services/building/tenant.service';
import IScope = angular.IScope;
import IAugmentedJQuery = angular.IAugmentedJQuery;
import IAttributes = angular.IAttributes;

export class IsAuthorizedDirective {
  public static create(
    securityService: SecurityService,
    buildingService: IBuildingService,
    featureService: FeatureService,
    tenantService: TenantService
  ) {
    const linkController = new IsAuthorizedLinkController(
      securityService,
      buildingService,
      featureService,
      tenantService
    );

    return {
      link: (s, e, a) => linkController.link(s, e, a),
      bindToController: true,
      controllerAs: 'security',
      controller: () => {},
      scope: {},
      restrict: 'EA'
    };
  }
}

export class IsAuthorizedLinkController {
  private static inProgressCount = 0;

  constructor(
    private securityService: SecurityService,
    private buildingService: IBuildingService,
    private featureService: FeatureService,
    private tenantService: TenantService
  ) {}

  public link(
    scope: IScope,
    element: IAugmentedJQuery,
    attributes: IAttributes
  ) {
    element.toggleClass('unauthorized', true);
    element.toggleClass('authorized', false);
    let promises = [];

    if (attributes.isAuthorized != null && attributes.isAuthorized != '') {
      const globalAuth = this.getPromises(
        element,
        attributes,
        (a) => a.isAuthorized,
        (type) => this.securityService.isAuthorized(type)
      );
      promises = promises.concat(globalAuth);
    }

    if (attributes.isFeatureAvailable != null) {
      element.toggleClass('unauthorized', false);
      element.toggleClass('authorized', true);
      this.featureService
        .isAvailable(attributes.isFeatureAvailable)
        .subscribe((isAvailable) => {
          if (!isAvailable) {
            element.remove();
          }
        });
    }

    if (attributes.isTenantUserForBuilding != null) {
      this.tenantService.getUserTenants(attributes.isTenantUserForBuilding).subscribe((tenants) => {
        if (tenants.length > 0) {
          element.remove();
        }
      });
    }

    if (attributes.isAuthorizedForBuilding == null) {
      this.evaluatePromises(scope, element, promises, this.isAuthorizedForResults);
      return;
    }

    this.getBuilding(element, attributes).then((building) => {
      const buildingPromises = this.getPromises(
        element,
        attributes,
        (a) => a.isAuthorizedForBuilding,
        (type) => this.securityService.isAuthorizedForBuilding(type, building)
      );
      promises = promises.concat(buildingPromises);

      this.evaluatePromises(scope, element, promises, this.isAuthorizedForResults);
    });

    this.getBuilding(element, attributes).then((building) => {
      const buildingPromises = this.getPromises(
        element,
        attributes,
        (a) => a.isAuthorizedForBuildingOr,
        (type) => this.securityService.isAuthorizedForBuilding(type, building)
      );
      promises = promises.concat(buildingPromises);

      this.evaluatePromises(scope, element, promises, this.isAuthorizedForAnyResults);
    });
  }

  private evaluatePromises(
    scope: IScope,
    element: IAugmentedJQuery,
    promises: Promise<boolean>[],
    isAuthedForResults: (results) => boolean
  ) {
    IsAuthorizedLinkController.inProgressCount++;

    Promise.all(promises).then((results) => {
      IsAuthorizedLinkController.inProgressCount--;

      const isAuthorized = isAuthedForResults(results);
      element.toggleClass('authorized', isAuthorized);
      element.toggleClass('unauthorized', !isAuthorized);

      if (IsAuthorizedLinkController.inProgressCount == 0) {
        scope.$apply();
      }
    });
  }


  private isAuthorizedForResults(results: boolean[]): boolean {
    return results.filter((result) => !result).length === 0;
  }

  private isAuthorizedForAnyResults(results: boolean[]): boolean {
    return results.filter((result) => result).length !== 0;
  }

  private getPromises(
    element: IAugmentedJQuery,
    attributes: IAttributes,
    getValue: (Object) => string,
    getPromise: (string) => Promise<boolean>
  ): Promise<boolean>[] {
    const promises = [];
    const value = getValue(attributes);
    const types =
      value != null && element != null && element.scope() != null
        ? element.scope().$eval(value)
        : null;
    if (value == 'is-authorized') {
      return [];
    }

    if (types instanceof Object) {
      for (const key in types) {
        if (types[key]) {
          promises.push(getPromise(key));
        }
      }
    } else if (value != null) {
      promises.push(getPromise(value));
    }

    return promises;
  }

  private getBuilding(
    element: IAugmentedJQuery,
    attributes: IAttributes
  ): Promise<Building> {
    const building =
      attributes.building != null
        ? element.scope().$eval(attributes.building)
        : null;

    if (building == null) {
      return this.buildingService.getCurrentBuilding();
    }

    return Promise.resolve(building);
  }
}
