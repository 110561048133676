import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ManagingCompanyResource} from '../resources/managing-company.resource';
import {ManagingCompany} from "@app/shared/models/building.interface";
import {SavedEntity} from "@app/shared/models/saved-entity.interface";

@Injectable({
    providedIn: 'root'
})
export class ManagingCompanyService {
    constructor(
        private managingCompanyResource: ManagingCompanyResource
    ) {}

    public getManagingCompanies(): Observable<ManagingCompany[]> {
        return this.managingCompanyResource.getAll();
    }

    public saveManagingCompany(managingCompany: ManagingCompany): Observable<SavedEntity<ManagingCompany, number>> {
        return this.managingCompanyResource.add(managingCompany);
    }

    public updateManagingCompany(managingCompany: ManagingCompany) {
        return this.managingCompanyResource.update(managingCompany.id, managingCompany);
    }

    public deleteManagingCompany(id: number) {
        return this.managingCompanyResource.delete(id);
    }

}
