import { Component, OnInit } from '@angular/core';
import { HeaderService } from '@app/shared/services/header.service';
import { UserService } from '@app/shared/services/user/user.service';
import { NavigationService } from '@app/shared/services/navigation/navigation.service';
import { concatMap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {
  buildingId: number;
  isInflux = true;
  constructor(
    private readonly header: HeaderService,
    private readonly userService: UserService,
    private readonly navigationService: NavigationService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.header.showSiteMenu();
    this.header.showBuildingsMenu();
    this.header.showFloorsMenu();
    this.header.showUserMenu();
    this.header.showSessionMenu();
    this.route.params
      .pipe(
        concatMap((params) => {
          const { buildingId } = params;
          this.buildingId = buildingId;
          return this.userService.getBuilding(buildingId);
        })
      )
      .subscribe((building) => {
        this.navigationService.initNavigation(window.location.href, building);
      });
  }

  public toggleInflux(): void {
    this.isInflux = !this.isInflux;
  }
}
