import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpXsrfTokenExtractor
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environment';
import { filter, map } from 'rxjs/operators';
import { CookieService } from '@app/shared/services/cookie.service';

@Injectable()
export class XsrfInterceptor implements HttpInterceptor {
  constructor(
    private tokenExtractor: HttpXsrfTokenExtractor,
    private cookieService: CookieService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let requestToForward = req;
    const token = this.tokenExtractor.getToken();
    if (token !== null && req.url.startsWith(environment.apiUrl)) {
      requestToForward = req.clone({ setHeaders: { 'X-XSRF-TOKEN': token } });
    }
    return next.handle(requestToForward).pipe(
      filter((event) => event instanceof HttpResponse),
      map((event: HttpResponse<any>) => {
        const newEvent = event.clone();
        if (
          req.url.startsWith(environment.apiUrl) &&
          event.headers.get('X-AUTH-TOKEN') !== null
        ) {
          this.cookieService.setCookie(
            'XSRF-TOKEN',
            event.headers.get('X-AUTH-TOKEN'),
            1,
            '/'
          );
          newEvent.headers.set('XSRF-TOKEN', event.headers.get('X-AUTH-TOKEN'));
        }
        return newEvent;
      })
    );
  }
}
