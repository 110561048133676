import { Schedule } from '@angularjs/or/api/building/Schedule';
import { RuleType } from '@angularjs/or/data/RuleType';
import { ScheduleService } from '@angularjs/or/services/ScheduleService';
import { TimeUtils } from '@angularjs/or/util/TimeUtils';
import { DayOfTheWeek } from '@angularjs/or/util/DayOfTheWeek';
import { StringUtils } from '@angularjs/or/util/StringUtils';
import { WeekdaySelection } from '@angularjs/or/util/WeekdaySelection';
import angular from 'angular';

export class OrScheduleSummaryController {
  public details: Schedule;
  public selectedRuleType: RuleType;
  public hours: string[] = [];
  public isCollapsed = true;
  public onMoveUp: () => (rank: number) => {};
  public onMoveDown: () => (rank: number) => {};
  public schedulesList;
  public onSaveNewSchedule: () => {};
  public onDeleteSchedule: () => {};
  public onClose: () => {};
  public isNew: boolean;
  public isControlActionsMenuActive: boolean;
  public isBusy: boolean;
  public status: string;
  public promptToDisable = false;
  public classForHour: string[] = [];
  public classForHourTillMidnight: string[] = [];
  public classForHourFromMidnight: string[] = [];
  public datedSchedule = false;
  private oldDetails: Schedule;
  public startOfDay = '00:00';
  public endOfDay = '24:00';

  constructor(
    private $scope: ng.IScope,
    private scheduleService: ScheduleService,
    private $timeout: ng.ITimeoutService
  ) {}

  public $onInit() {
    this.hours = TimeUtils.getTimesInDay(10);
    if (!this.details || this.isNew) {
      this.details = new Schedule();
      this.details.editable = true;
      this.isNew = true;
    } else if (!this.details.days) {
      this.datedSchedule = true;
    }
    this.oldDetails = angular.copy(this.details);

    this.$scope.$watch('scheduleSummary.isBusy', () => this.updateStatus());
    this.$scope.$watch('scheduleSummary.details.alwaysActive', () => this.updateStatus());
    this.$scope.$watch('scheduleSummary.details.active', () => this.updateStatus());
    this.$scope.$watch('scheduleSummary.details.enabled', () => this.updateStatus());
    this.$scope.$watch('scheduleSummary.details.startTime', () => this.updateClassForHour());
    this.$scope.$watch('scheduleSummary.details.endTime', () => this.updateClassForHour());
    this.$scope.$watch('scheduleSummary.details.startTime', () => this.updateClassForHourTillMidnight());
    this.$scope.$watch('scheduleSummary.details.endTime', () => this.updateClassForHourFromMidnight());
  }

  private updateStatus() {
    this.isCollapsed = true;
    if (this.isBusy) {
      this.status = 'Pending';
    } else {
      if (this.details.alwaysActive) {
        this.status = 'Always Active';
      } else if (this.details.active) {
        this.status = 'Active';
      } else if (this.details.enabled) {
        this.status = 'Inactive';
      } else {
        this.status = 'Paused';
      }
    }
  }

  public toggleDatedSchedule(datedSchedule) {
    this.datedSchedule = datedSchedule;
    if (this.datedSchedule) {
      this.details.days = null;
    } else if (this.oldDetails.days) {
      this.details.days = this.oldDetails.days;
    } else {
      this.details.days = new WeekdaySelection();
    }
  }

  toggleControlActionsMenu(isActive) {
    this.isControlActionsMenuActive = typeof isActive === 'boolean' ? isActive : !this.isControlActionsMenuActive;
  }

  getActiveActionIndex(): number {
    if (this.details.alwaysActive) {
      return 2;
    }

    if (this.details.enabled) {
      return 0;
    }

    return 1;
  }

  getControlActionsClass(): string {
    return 'action-' + this.getActiveActionIndex();
  }

  getControlActionClass(index: number): string {
    return this.isControlActionsMenuActive || this.getActiveActionIndex() == index ? 'active' : '';
  }

  updateClassForHour() {
    for (let i = 0; i < this.hours.length; i++) {
      this.classForHour[i] = this.getClassForHour(i, this.details.startTime, this.details.endTime);
    }
  }

  updateClassForHourTillMidnight() {
    for (let i = 0; i < this.hours.length; i++) {
      this.classForHourTillMidnight[i] = this.getClassForHour(i, this.details.startTime, this.endOfDay);
    }
  }

  updateClassForHourFromMidnight() {
    for (let i = 0; i < this.hours.length; i++) {
      this.classForHourFromMidnight[i] = this.getClassForHour(i, this.startOfDay, this.details.endTime);
    }
  }

  getClassForHour(index: number, start: string, end: string) {
    const moment = this.hours[index];
    const filled = TimeUtils.isBetween(start, end, moment);
    const nextMoment = this.hours[index == this.hours.length - 1 ? 0 : index + 1];
    const previousMoment = this.hours[index == 0 ? this.hours.length - 1 : index - 1];
    let nextFilled = TimeUtils.isBetween(start, end, nextMoment);
    let previousFilled = TimeUtils.isBetween(start, end, previousMoment);
    const startEqualsEnd = TimeUtils.isSame(start, end);
    let startCloseToEnd = TimeUtils.isBefore(start, end) && TimeUtils.isStartCloseToEnd(start, end, 60);

    const hourBehindMoment1 = this.hours[index - 1 > -1 ? index - 1 : index - 1 + this.hours.length];
    const hourBehindMoment2 = this.hours[index - 2 > -1 ? index - 2 : index - 2 + this.hours.length];
    const hourBehindMoment3 = this.hours[index - 3 > -1 ? index - 3 : index - 3 + this.hours.length];
    const hourAheadMoment1 = this.hours[index + 1 < this.hours.length ? index + 1 : index + 1 - this.hours.length];
    const hourAheadMoment2 = this.hours[index + 2 < this.hours.length ? index + 2 : index + 2 - this.hours.length];
    const hourAheadMoment3 = this.hours[index + 3 < this.hours.length ? index + 3 : index + 3 - this.hours.length];
    let hourBehindFilled =
      TimeUtils.isBetween(start, end, hourBehindMoment1) &&
      TimeUtils.isBetween(start, end, hourBehindMoment2) &&
      TimeUtils.isBetween(start, end, hourBehindMoment3);
    let hourAheadFilled =
      TimeUtils.isBetween(start, end, hourAheadMoment1) &&
      TimeUtils.isBetween(start, end, hourAheadMoment2) &&
      TimeUtils.isBetween(start, end, hourAheadMoment3);

    let endAfterMidnight = TimeUtils.isStartCloseToEnd('00:00', end, 30);
    let startBeforeMidnight = TimeUtils.isStartCloseToEnd(start, '24:00', 30);

    if (startEqualsEnd) {
      if (index == 0) {
        previousFilled = false;
        nextFilled = true;
        hourBehindFilled = false;
        hourAheadFilled = false;
        startCloseToEnd = false;
        startBeforeMidnight = false;
        endAfterMidnight = false;
      } else if (index == 1 || index == 2) {
        hourBehindFilled = false;
        hourAheadFilled = false;
        startCloseToEnd = false;
        startBeforeMidnight = false;
        endAfterMidnight = false;
      }
      if (index == this.hours.length - 1) {
        previousFilled = true;
        nextFilled = false;
        hourBehindFilled = false;
        hourAheadFilled = false;
        startCloseToEnd = false;
        startBeforeMidnight = false;
        endAfterMidnight = false;
      } else if (index == this.hours.length - 2 || index == this.hours.length - 3) {
        hourBehindFilled = false;
        hourAheadFilled = false;
        startCloseToEnd = false;
        startBeforeMidnight = false;
        endAfterMidnight = false;
      }
    }

    return this.generateClassForHour(
      filled,
      previousFilled,
      nextFilled,
      hourBehindFilled,
      hourAheadFilled,
      startEqualsEnd,
      startCloseToEnd,
      startBeforeMidnight,
      endAfterMidnight
    );
  }

  private generateClassForHour(
    filled: boolean,
    previousFilled?: boolean,
    nextFilled?: boolean,
    hourBehindFilled?: boolean,
    hourAheadFilled?: boolean,
    startEqualsEnd?: boolean,
    startCloseToEnd?: boolean,
    startNearMidnight?: boolean,
    endNearMidnight?: boolean
  ): any {
    const returnValue = {};
    if (filled) {
      returnValue['or-active'] = true;
      returnValue['or-first-active'] = !previousFilled;
      returnValue['or-last-active'] = !nextFilled;
      returnValue['or-near-active-start'] = !hourBehindFilled;
      returnValue['or-near-active-end'] = !hourAheadFilled;
    } else {
      returnValue['or-inactive'] = true;
      returnValue['or-first-inactive'] = previousFilled;
      returnValue['or-last-inactive'] = nextFilled;
      returnValue['or-near-active-end'] = hourBehindFilled;
      returnValue['or-near-active-start'] = hourAheadFilled;
    }
    returnValue['end-near-midnight'] = endNearMidnight;
    returnValue['start-near-midnight'] = startNearMidnight;
    returnValue['or-start-equals-end'] = startEqualsEnd;
    returnValue['or-start-close-to-end'] = startCloseToEnd;
    return returnValue;
  }

  public toggleCollapse() {
    if (this.details.editable) {
      this.isCollapsed = this.details.active ? true : !this.isCollapsed;
      if (this.details.active && !this.isBusy) {
        this.promptToDisable = true;
      }
    }
  }

  public toggleEnabledState(setEnabled) {
    if (!setEnabled) {
      this.promptToDisable = false;
    }
    const schedule = Schedule.clone(this.details);
    schedule.enabled = typeof setEnabled === 'boolean' ? setEnabled : !schedule.enabled;
    schedule.alwaysActive = false;

    if (!schedule.enabled) {
      schedule.active = false;
    }
    this.updateSchedule(schedule);
    this.isControlActionsMenuActive = false;
  }

  public toggleAlwaysActiveState(isActive) {
    this.promptToDisable = false;
    this.details.alwaysActive = typeof isActive === 'boolean' ? isActive : !this.details.alwaysActive;
    if (this.details.alwaysActive) {
      this.details.enabled = true;
      this.details.active = true;
    }
    if (!this.isNew) {
      this.updateSchedule(this.details);
    }
    this.isControlActionsMenuActive = false;
  }

  private updateSchedule(schedule: Schedule) {
    this.isBusy = true;
    this.promptToDisable = false;
    if (this.datedSchedule) {
      this.details.days = null;
    } else {
      this.details.startDate = null;
      this.details.endDate = null;
    }
    this.scheduleService
      .updateSchedule(schedule)
      .then((newSchedule) => {
        this.$scope.$apply(() => {
          this.details = Schedule.clone(newSchedule);
          this.details.editable = true;
          this.isBusy = false;
        });
      })
      .catch((e) => {
        this.isBusy = false;
        this.updateStatus();
      });
  }

  public getPauseLabel(): string {
    if (this.isNew) {
      return null;
    }
    if (this.details.enabled) {
      return 'Disable Schedule';
    } else {
      return 'Enable Schedule';
    }
  }

  public getPauseClass(): string {
    if (this.isNew) {
      return null;
    }
    if (this.details.enabled) {
      return 'or-icon-minus';
    } else {
      return 'or-icon-plus';
    }
  }

  public getSummaryStateClass(): string {
    let style = '';
    if (this.details.active) {
      style = 'or-schedule-summary-header-running';
    }
    return style;
  }

  public getStatusLabelClass(): string {
    if (this.isBusy) {
      return 'or-pulsate';
    } else {
      return '';
    }
  }

  public getDayOfWeekLabel(day: DayOfTheWeek): string {
    const dayString = StringUtils.toSentenceCase(day.toString());
    this.checkDays();
    if (this.details.days.isActive(day.ordinal)) {
      return `Enabled on ${dayString}`;
    } else {
      return `Disabled on ${dayString}`;
    }
  }

  public checkDays() {
    if (!this.details.days) {
      this.details.days = new WeekdaySelection();
    }
  }

  public getDaysOfTheWeek(): DayOfTheWeek[] {
    return TimeUtils.getDaysOfTheWeek();
  }

  public getCircleClass(day: number): string {
    if (this.details.days.isActive(day)) {
      return 'or-filled';
    } else {
      return null;
    }
  }

  public moveUp() {
    this.onMoveUp().bind(this.schedulesList)(this.details);
  }

  public moveDown() {
    this.onMoveDown().bind(this.schedulesList)(this.details);
  }

  public isSameDay(): boolean {
    return (
      this.details.startDate == null || this.details.endDate == null || this.details.startDate == this.details.endDate
    );
  }

  public extendHoursToMidnight(): string[] {
    return this.hours;
  }

  public extendHoursFromMidnight(): string[] {
    return this.hours;
  }
}
