import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
  standalone: true,
  imports: [MatButtonModule, MatDialogModule]
})
export class ConfirmComponent implements OnInit {
  message: string;
  title: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: MatDialogData, private dialogRef: MatDialogRef<ConfirmComponent>) {
    this.message = data.message;
    this.title = data.title;
  }

  ngOnInit(): void {
    // intentionally left blank
  }

  get dialogTitle(): string {
    return this.title ? this.title : 'Confirm this action';
  }

  get content(): string {
    return this.message;
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  confirm(): void {
    this.dialogRef.close(true);
  }
}

export interface MatDialogData {
  title?: string;
  type?: DialogType;
  message: string;
  showConfirm: boolean;
}

export enum DialogType {
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error'
}

export class CancelOnlyDialogData implements MatDialogData {
  title?: string;
  type: DialogType;
  message: string;
  showConfirm = false;

  constructor(message: string, title?: string, type?: DialogType) {
    this.title = title;
    this.message = message;
    this.type = type || DialogType.INFO;
  }
}

export class ConfirmDialogData implements MatDialogData {
  title?: string;
  message: string;
  showConfirm = true;
  constructor(message: string, title?: string) {
    this.title = title;
    this.message = message;
  }
}
