import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Environment, environmentToken } from 'src/environments/environment.interface';
import { LuminaireManufacturer } from '../models/luminaire-manufacturer.interface';
import { ILuminaireManufacturerResource } from './luminaire-manufacturer.interface';

@Injectable({
  providedIn: 'root'
})
export class LuminaireManufacturerResource implements ILuminaireManufacturerResource {
  constructor(private http: HttpClient, @Inject(environmentToken) private environment: Environment) {}

  public getAll(): Observable<LuminaireManufacturer[]> {
    return this.http.get<LuminaireManufacturer[]>(`${this.environment.apiUrl + this.environment.apiPath}/luminaire-manufacturer`);
  }
}
