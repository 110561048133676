<div class="calendar-event-dialog">

  <h2 mat-dialog-title>{{ title }}</h2>

  <mat-dialog-content>
    <form [formGroup]="calendarEventForm" autocomplete="off" data-cy="create-calendar-event-form">
      <mat-form-field *ngIf='mode === 1'>
        <mat-label>Name</mat-label>
        <input matInput formControlName="name">
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>DD/MM/YYYY</mat-label>
        <input matInput [matDatepicker]="from_date_picker" (dateChange)='selectDate($event)'>
        <mat-hint>Date</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="from_date_picker"></mat-datepicker-toggle>
        <mat-datepicker #from_date_picker></mat-datepicker>
      </mat-form-field>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-raised-button (click)="close()">Close</button>
    <button mat-raised-button color="primary" (click)="save()">Save</button>
  </mat-dialog-actions>

</div>
