import { SensorNodeService } from '@angularjs/or/services/SensorNodeService';
import { FloorplanSensorNode } from '@angularjs/or/api/building/FloorplanSensorNode';
import { LuminaireDriver } from '@angularjs/or/api/building/LuminaireDriver';
import { Map } from '@angularjs/or/util/Map';
import { SensorNodeAlert } from '@angularjs/angular/modules/layout/or-sensor-node/SensorNodeAlert';

export class OrLuminaireDriverController {
  public driver: LuminaireDriver;
  public node: FloorplanSensorNode;
  public isEmergencyLightingTestModeActive: boolean;
  public isManualFunctionalTestModeActive: boolean;
  public isManualDurationTestModeActive: boolean;
  public isCancelTestModeActive: boolean;
  public disableSelection: boolean;
  public markFaulty: boolean;
  public markEmergencyLight: boolean;
  public buildingId: number;
  public hasTestBeenActivated = false;
  public showNodeProperties: boolean;
  public notificationMode: boolean;
  public alerts: SensorNodeAlert[];
  public zoomLevel: number;

  constructor(private scope: ng.IScope, private nodesService: SensorNodeService) {}

  public $onInit(): void {
    this.alerts = this.driver.alerts;
  }

  public isDriverSelected() {
    // If the luminaire driver should be possible to select, please see how it's done in EmDriver case.
    return false;
  }

  public onMouseLeave($event: MouseEvent) {
    this.showNodeProperties = false;
  }

  public onMouseEnter($event: MouseEvent) {
    if ($event.buttons == 0) {
      this.showNodeProperties = true;
    }
  }

  public produceClassForLuminaireDriver(): {} {
    const styleClass: { [p: string]: any } = {};
    styleClass.selected = !this.disableSelection && this.isDriverSelected();
    styleClass.faulty =
      this.node.properlyMapped &&
      this.driver.isFaulty &&
      this.markFaulty &&
      !this.driver.connected &&
      !this.node.connected;
    styleClass.disconnected = this.markFaulty && (!this.driver.connected || !this.node.connected);
    styleClass['is-highlighted'] = this.node.isHighlighted;
    styleClass['is-changed'] = this.node.isChanged;
    if (this.markEmergencyLight) {
      styleClass.muted = true;
    }

    return styleClass;
  }

  public produceDriverStyle(x: number, y: number): Map<string> {
    const style: Map<string> = {};
    style.left = this.driver.x + 'px';
    style.top = this.driver.y + 'px';
    return style;
  }

  public produceClassForLuminaireDriverIcon(): {} {
    const styleClass = {};
    styleClass['or-icon-node'] = true;
    styleClass['or-icon-node-dt8'] = this.driver.isDT8Driver;

    return styleClass;
  }

  public getDriverIdText(): string {
    let text = '';
    text += this.driver.isDT8Driver ? 'DT8' : 'DT6';
    text += ' Driver';
    text += ' #' + this.driver.id;
    return text;
  }

  public getLampTypeName(): string {
    // FIXME: use power consumption and lamp type name correctly here instead of hard-coding
    return 'Default (40W)';
  }

  public get propertiesStyle(): Map<string> {
    const style: Map<string> = {};
    const transform = 'translate(-50%, -8px) scale(' + 1 / this.zoomLevel + ')';
    style.transform = transform;
    style['-webkit-transform'] = transform;

    return style;
  }
}
