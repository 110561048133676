import { User } from '@app/shared/models/user.interface';

export class NavigationSectionInfo {
  constructor(
    private id: string,
    public name: string,
    private route: string,
    private routeRegex?: string,
    private iconClass?: string,
    public isAuthorized: (user: User, buildingId: number) => boolean = () => true
  ) {}

  get Id(): string {
    return this.id;
  }

  get Name(): string {
    return this.name;
  }

  get Route(): string {
    return this.route;
  }

  get IconClass(): string {
    return this.iconClass;
  }

  get IsAuthorized(): (user: User, buildingId: number) => boolean {
    return this.isAuthorized;
  }

  get RouteRegex(): string {
    return this.routeRegex;
  }
}
