import { Day, format, getWeekOfMonth } from 'date-fns';
import { RepeatUnit } from '@app/shared/models/schedule-recurrence/repeat-unit';
import { Weekday } from '@app/shared/models/weekday';

export class ScheduleRecurrence {
  constructor(
    private startDate: Date,
    private repeatEvery: number,
    private repeatUnit: RepeatUnit,
    private repeatOn?: Weekday[],
    private endsOn?: Date,
    private endsAfter?: number
  ) {}

  private readonly nth = ['th', 'st', 'nd', 'rd'];

  get repeatOccurrence(): number {
    return this.repeatEvery;
  }

  get repetitionUnit(): RepeatUnit {
    return this.repeatUnit;
  }

  get repeatOnDays(): Weekday[] {
    return this.repeatOn;
  }

  get endsOnDate(): Date {
    return this.endsOn;
  }

  get endsAfterOccurrences(): number {
    return this.endsAfter;
  }

  get displayName(): string {
    if (this.repeatEvery > 0) {
      return this.getRecurrenceString(this.getRepeatEveryString(), this.getEndsOnString());
    } else {
      return `Doesn't repeat`;
    }
  }

  private getRepeatEveryString(): string {
    if (this.repeatEvery === 1) {
      return `${this.repeatUnit.getUnitString().recurringName}`;
    } else if (this.repeatEvery > 1) {
      return `Every ${this.repeatEvery} ${this.repeatUnit.getUnitString().displayNamePlural}`;
    }
  }

  private getRecurrenceString(repeatEveryString: string, endsOnString: string): string {
    switch (this.repeatUnit) {
      case RepeatUnit.NONE:
        return `Doesn't repeat`;
      case RepeatUnit.DAILY:
        return `${repeatEveryString}${endsOnString}`;
      case RepeatUnit.WEEKLY:
        if (this.repeatOn != null && this.repeatOn.length > 1) {
          return `${repeatEveryString} on ${this.repeatOn.flatMap((day) => ' ' + day.value)}${endsOnString}`;
        } else if (this.repeatOn != null && this.repeatOn.length > 0) {
          return `${repeatEveryString} on ${this.repeatOn.flatMap((day) => ' ' + day.fullName)}${endsOnString}`;
        } else {
          return `${repeatEveryString} on ${format(this.startDate, 'EEEE')}${endsOnString}`;
        }
      case RepeatUnit.MONTHLY_WEEK_DATE:
        return `${repeatEveryString} on the ${this.getDayInMonth()} ${format(this.startDate, 'EEEE')}${endsOnString}`;
      case RepeatUnit.MONTHLY_CALENDAR_DATE:
        return `${repeatEveryString} on the ${this.getNumberWithOrdinal(this.startDate.getDate())}${endsOnString}`;
      case RepeatUnit.YEARLY:
        return `${repeatEveryString} on the ${this.getNumberWithOrdinal(this.startDate.getDate())} of ${format(
          this.startDate,
          'LLLL'
        )}${endsOnString}`;
    }
  }

  private getDayInMonth(): string {
    const firstDayOfMonth = this.getFirstDayOfMonth(this.startDate);
    const weekOfMonth = getWeekOfMonth(this.startDate, { weekStartsOn: firstDayOfMonth });
    if (weekOfMonth > 4) {
      return 'Last';
    } else {
      return this.getNumberWithOrdinal(weekOfMonth);
    }
  }

  /**
   * Retrieves the first weekday of the month based on any date of that month.
   *
   * @return the day of the week
   */
  private getFirstDayOfMonth(date: Date): Day {
    const firstDateOfMonth = new Date(new Date(date).setDate(1));
    return firstDateOfMonth.getDay() as Day;
  }

  private getEndsOnString(): string {
    let endsOnString = '';
    if (this.endsOn) {
      const dateToFormat = Array.isArray(this.endsOn) ? new Date(this.endsOn) : this.endsOn;
      endsOnString = `, until ${format(dateToFormat, 'eeee, do, MMMM, yyyy')}`;
    } else if (this.endsAfter && this.endsAfter > 0) {
      endsOnString = `, ${this.endsAfter} times`;
    }
    return endsOnString;
  }

  private getNumberWithOrdinal(n): string {
    const v = n % 100;
    return n + (this.nth[(v - 20) % 10] || this.nth[v] || this.nth[0]);
  }

  public equals(scheduleRecurrence: ScheduleRecurrence): boolean {
    return (
      this.startDate === scheduleRecurrence.startDate &&
      this.repeatEvery === scheduleRecurrence.repeatEvery &&
      this.repeatUnit === scheduleRecurrence.repeatUnit &&
      this.repeatOn === scheduleRecurrence.repeatOn &&
      this.endsOn === scheduleRecurrence.endsOn &&
      this.endsAfter === scheduleRecurrence.endsAfter
    );
  }
}

export class ScheduleRecurrenceCustom extends ScheduleRecurrence {
  constructor() {
    super(null, 1, RepeatUnit.NONE);
  }

  // Override
  get displayName(): string {
    return 'Custom...';
  }
}

export const CUSTOM = new ScheduleRecurrenceCustom();
