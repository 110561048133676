import { PropertiesService } from '@angularjs/or/services/PropertiesService';

export class OrTimerangeWidgetController {
  public scale;
  public startTime: string;
  public endTime: string;
  public minHours: number;
  public isValidRange: boolean;

  private isOverlapping: boolean;
  private startHours: number;
  private endHours: number;
  private duration: number;

  private scaleElement;
  private fillElement;
  private fillOverlapElement;
  private labelStartElement;
  private labelEndElement;

  constructor(
    private $scope,
    private $element,
    private propertiesService: PropertiesService
  ) {}

  public $onInit() {
    this.init();
    this.propertiesService
      .getProperty('scheduler.durationTestDurationMinutes')
      .then((durationTestDurationMinutes) => {
        this.minHours = parseInt(durationTestDurationMinutes) / 60;
      });
    this.$scope.$watch('[timerange.startTime, timerange.endTime]', (_) => {
      this.updateChart();
    });
    window.addEventListener('resize', (_) => {
      this.updateChart();
    });
  }

  private init() {
    const container = this.$element[0];
    this.scale = this.produceScale();
    this.scaleElement = container.querySelector('.or-timerange-widget-scale');
    this.fillElement = container.querySelector('.or-timerange-widget-fill');
    this.fillOverlapElement = container.querySelector(
      '.or-timerange-widget-fill-overlap'
    );
    this.labelStartElement = container.querySelector(
      '.or-timerange-widget-fill-label-start'
    );
    this.labelEndElement = container.querySelector(
      '.or-timerange-widget-fill-label-end'
    );
    this.updateChart();
  }

  private updateChart() {
    this.updateValues();
    this.renderFill();
  }

  private renderFill() {
    if (this.isOverlapping) {
      this.renderOverlappingFill();
      return;
    }
    this.renderRegularFill();
  }

  private renderRegularFill() {
    const hourWidth = this.scaleElement.clientWidth / 24;
    const fillWidth = this.duration * hourWidth;
    const fillOffset = this.startHours * hourWidth;
    const fillStyle = this.fillElement.style;
    fillStyle.width = fillWidth + 'px';
    fillStyle.left = fillOffset + 'px';

    this.labelStartElement.style.left = fillOffset + 'px';
    this.labelEndElement.style.left = fillOffset + fillWidth + 'px';
  }

  private renderOverlappingFill() {
    const hourWidth = this.scaleElement.clientWidth / 24;
    const fillWidth = (this.duration - this.endHours) * hourWidth;
    const fillOffset = this.startHours * hourWidth;
    const fillStyle = this.fillElement.style;
    fillStyle.width = fillWidth + 'px';
    fillStyle.left = fillOffset + 'px';

    const fillOverlapWidth = this.endHours * hourWidth;
    const fillOverlapOffset = 0;
    const fillOverlapStyle = this.fillOverlapElement.style;
    fillOverlapStyle.width = fillOverlapWidth + 'px';
    fillOverlapStyle.left = fillOverlapOffset + 'px';

    this.labelStartElement.style.left = fillOffset + 'px';
    this.labelEndElement.style.left = fillOverlapWidth + 'px';
  }

  private updateValues() {
    this.startHours = this.labelToHours(this.startTime);
    this.endHours = this.labelToHours(this.endTime);
    this.isOverlapping = this.endHours - this.startHours < 0;
    this.duration = this.getDuration();
    this.isValidRange = this.duration >= this.minHours;
  }

  private getDuration() {
    const startHours = this.startHours;
    const endHours = this.endHours;
    if (!this.isOverlapping) {
      return endHours - startHours;
    }
    return endHours - startHours + 24;
  }

  public produceScale() {
    const scale = [];
    for (let hours = 0; hours < 24; hours += 1) {
      scale.push(this.hoursToLabel(hours));
    }
    return scale;
  }

  private hoursToLabel(hours) {
    const parsedHours = Math.floor(hours);
    const parsedMinutes = 60 * (hours - parsedHours);
    return [
      ('0' + parsedHours).slice(-2),
      ('0' + parsedMinutes).slice(-2)
    ].join(':');
  }

  private labelToHours(label) {
    const split = label.split(':');
    const parsedHours = parseInt(split[0]);
    const parsedMinutes = parseInt(split[1]);
    let hours = parsedHours + parsedMinutes / 60;
    hours = parseFloat(hours.toFixed(2));
    return hours < 24 ? hours : hours - parsedHours;
  }
}
