import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { OtapUpgradeResource } from '@app/shared/resources/otap-upgrade.resource';
import { AddJobDTO, IOtapUpgradeDTO, JobTaskTemplateMap } from '@app/shared/models/otap.upgrade.interface';
import { IOtapUpgradeGatewayDTO, OtapUpgradeGatewayDTO } from '@app/shared/models/otap-upgrade-gateway.inerface';
import { OtapUpgradeTaskDTO, OtapUpgradeTaskInputParametersDTO } from '@app/shared/models/otap-upgrade-task.interface';

@Injectable({
  providedIn: 'root'
})
export class OtapUpgradeService {
  private selectedJobSubject = new BehaviorSubject<IOtapUpgradeDTO>(null);
  selectedJob$ = this.selectedJobSubject.asObservable();

  constructor(private otapUpgradeResource: OtapUpgradeResource) {}

  public updateSelectedJob(job: IOtapUpgradeDTO): void {
    this.selectedJobSubject.next(job);
  }

  public getJobs(statusses?: string): Observable<IOtapUpgradeDTO[]> {
    return this.otapUpgradeResource.getJobs(statusses);
  }

  public getJob(jobId: number): Observable<IOtapUpgradeDTO> {
    return this.otapUpgradeResource.getJob(jobId);
  }

  public addJob(payload: AddJobDTO): Observable<IOtapUpgradeDTO> {
    return this.otapUpgradeResource.addJob(payload);
  }

  public getJobTemplates(): Observable<JobTaskTemplateMap> {
    return this.otapUpgradeResource.getJobTemplates();
  }

  public getAllGatewaysForAJob(jobId: number): Observable<OtapUpgradeGatewayDTO[]> {
    return this.otapUpgradeResource.getAllGatewaysForAJob(jobId);
  }

  public getAllTasksForAJob(jobId: number): Observable<OtapUpgradeTaskDTO[]> {
    return this.otapUpgradeResource.getAllTasksForAJob(jobId);
  }

  public startTask(
    jobId: number,
    taskId: number,
    parameters: OtapUpgradeTaskInputParametersDTO[]
  ): Observable<OtapUpgradeTaskDTO> {
    return this.otapUpgradeResource.startTask(jobId, taskId, parameters);
  }

  public deleteGatewayFromJob(jobId: number, gatewayId: number): Observable<{}> {
    return this.otapUpgradeResource.deleteGatewayFromJob(jobId, gatewayId);
  }

  public addGatewayToJob(jobId: number, gateway: OtapUpgradeGatewayDTO): Observable<IOtapUpgradeGatewayDTO> {
    return this.otapUpgradeResource.addGatewayToJob(jobId, gateway);
  }

  public rebootGateway(jobId: number, gatewayId: number): Observable<boolean> {
    return this.otapUpgradeResource.rebootGateway(jobId, gatewayId);
  }

  public rebootAllGateways(jobId: number): Observable<boolean> {
    return this.otapUpgradeResource.rebootAllGateways(jobId);
  }

  public downloadExport(jobId: number, exportId: string): Observable<Blob> {
    return this.otapUpgradeResource.downloadExport(jobId, exportId);
  }

  public startExport(jobId: number): Observable<string> {
    return this.otapUpgradeResource.startExport(jobId);
  }

  public stopJob(jobId: number): Observable<IOtapUpgradeDTO> {
    return this.otapUpgradeResource.stopJob(jobId);
  }

  public reopenJob(jobId: number): Observable<IOtapUpgradeDTO> {
    return this.otapUpgradeResource.reopenJob(jobId);
  }
}
