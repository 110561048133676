export class NumericUtils {
  public static roundFloatingPoint(
    value: number,
    decimalPoints: number
  ): number {
    return parseFloat(value.toFixed(decimalPoints));
  }

  public static random(
    min = 0,
    max = 1,
    generateFraction: () => number = () => Math.random()
  ): number {
    return min + generateFraction() * (max - min);
  }
}
