import { Tab } from '@app/shared/components/tabs/tab.interface';
import { BuildingAuthorityType } from '@app/shared/models/building-authority-type';
import { Building } from '@app/shared/models/building.interface';
import { GlobalAuthority } from '@app/shared/models/global-authority';
import { User } from '@app/shared/models/user.interface';

export const administrationTabs: Tab[] = [
  {
    name: 'Building',
    path: './building',
    dataCy: 'administration-building-tab',
    isAuthorized: hasBuildingAuthority(BuildingAuthorityType.MANAGE_BUILDING)
  },
  {
    name: 'Gateways',
    path: '../administration/gateways',
    dataCy: 'administration-gateways-tab',
    isAuthorized: hasBuildingAuthority(BuildingAuthorityType.MANAGE_GATEWAYS)
  },
  {
    name: 'Floors',
    path: '../administration/floors',
    dataCy: 'administration-floors-tab',
    isAuthorized: hasBuildingAuthority(BuildingAuthorityType.MANAGE_FLOORS)
  },
  {
    name: 'Users',
    path: '../administration/users',
    dataCy: 'administration-users-tab',
    isAuthorized: hasBuildingAuthority(BuildingAuthorityType.MANAGE_USERS)
  },
  {
    name: 'Roles',
    path: '../administration/roles',
    dataCy: 'administration-roles-tab',
    isAuthorized: hasOneOfBuildingAuthority([BuildingAuthorityType.MANAGE_ROLES, BuildingAuthorityType.MANAGE_USERS])
  },
  {
    name: 'Tokens',
    path: '../administration/tokens',
    dataCy: 'administration-tokens-tab',
    isAuthorized: hasAuthority(GlobalAuthority.MANAGE_TOKEN_USERS)
  },
  {
    name: 'Mobile Tokens',
    path: '../administration/mobile-tokens',
    dataCy: 'administration-mobile-tokens-tab',
    isAuthorized: hasAuthority(GlobalAuthority.MANAGE_MOBILE_TOKEN)
  },
  {
    name: 'Emergency Lighting',
    path: '../administration/emergency-lighting',
    dataCy: 'administration-emergency-lighting-tab',
    isAuthorized: hasBuildingAuthority(BuildingAuthorityType.MANAGE_BUILDING)
  },
  {
    name: 'Tenants',
    path: '../administration/tenants',
    dataCy: 'administration-tenants-tab',
    isAuthorized: hasBuildingAuthority(BuildingAuthorityType.MANAGE_TENANT)
  },
  {
    name: 'DDL',
    path: '../administration/ddl',
    dataCy: 'administration-ddl-tab',
    isAuthorized: hasBuildingAuthority(BuildingAuthorityType.MANAGE_DDL)
  }
];

function hasBuildingAuthority(authority: BuildingAuthorityType): (user: User, building: Building) => boolean {
  return (user: User, building: Building) =>
    user.buildingAuthorizations.some((ba) => {
      if (ba.buildingId === building.id) {
        return ba.authorities.some((a) => a.toString() === authority.toString());
      }
      return false;
    });
}

function hasOneOfBuildingAuthority(authority: BuildingAuthorityType[]): (user: User, building: Building) => boolean {
  return (user: User, building: Building) =>
    user.buildingAuthorizations.some((ba) => {
      if (ba.buildingId === building.id) {
        return ba.authorities.some((a) => authority.filter((auth) => auth.toString() === a.toString()).length > 0);
      }
      return false;
    });
}

function hasAuthority(authority: GlobalAuthority): (user: User) => boolean {
  return (user: User) =>
    user.globalAuthorities.some((ga) => {
      return ga.toString() === authority.toString();
    });
}
