import * as angular from 'angular'; // Automatically added
import { OrNodeTileController } from '../or-node-tile/OrNodeTileController';
import { ISecurityService } from '@angularjs/or/services/ISecurityService';
import { SensorNodeService } from '@angularjs/or/services/SensorNodeService';
import { FilterService } from '@angularjs/or/services/FilterService';
import { UIRefreshService } from '@angularjs/or/services/UIRefreshService';
import { FeatureService } from '@app/shared/services/feature.service';

export class OrBeaconsNodeTileController extends OrNodeTileController {
  private isMouseOverInterval: boolean;
  private isMouseOverContent: boolean;
  private isMouseOverUUID: boolean;
  public scrollTo;
  public isExpanded = false;

  constructor(
    $scope: angular.IScope,
    $element,
    securityService: ISecurityService,
    nodesService: SensorNodeService,
    filterService: FilterService,
    uiRefreshService: UIRefreshService,
    featureService: FeatureService
  ) {
    super(
      $scope,
      $element,
      securityService,
      nodesService,
      filterService,
      uiRefreshService,
      featureService
    );
  }

  public $onInit() {
    super.$onInit();
    this.isMouseOverInterval = false;
    this.isMouseOverContent = false;
    this.isMouseOverUUID = false;
    this.sensorNodeService.registerToListedNodes(this.details);
  }
  public getClassValueColor(): string {
    let className = 'or-red';
    if (this.details.beaconEnabled) {
      className = 'or-green';
    }
    return className;
  }
  public getClassValueColorForPowerLevel(): string {
    let className = 'or-red';
    if (this.details.beaconEnabled) {
      className = 'or-green';
    }
    return className;
  }
  public getClassValueColorForContent(): string {
    let className = 'or-red';
    if (this.details.beaconEnabled) {
      className = 'or-green';
    }
    if (this.isMouseOverContent) {
      if (this.details.beaconContent.value == 'N/A') {
        className = 'or-mouseover-not-available';
      } else {
        className = 'or-mouseover-content';
      }
    }
    return className;
  }
  public getClassValueColorForInterval(): string {
    let className = 'or-red';
    if (this.details.beaconEnabled) {
      className = 'or-green';
    }
    if (this.isMouseOverInterval) {
      if (this.details.beaconInterval.value == 'N/A') {
        className = 'or-mouseover-not-available';
      } else {
        className = 'or-mouseover-interval';
      }
    }
    return className;
  }
  public getClassValueColorForUUID(): string {
    let className = 'or-red';
    if (this.details.beaconEnabled) {
      className = 'or-green';
    }
    if (this.isMouseOverUUID) {
      if (this.details.beaconUUID.value == 'N/A') {
        className = 'or-mouseover-not-available';
      } else {
        className = 'or-mouseover-content';
      }
    }
    return className;
  }

  public selectNode() {
    if (!this.isExpanded && angular.isFunction(this.onSelect)) {
      this.onSelect();
    }
    if (this.details.tags != null && this.details.tags.length > 0) {
      this.isExpanded = !this.isExpanded;
    }
  }

  public mouseOverInterval() {
    this.isMouseOverInterval = true;
  }

  public mouseLeaveInterval() {
    this.isMouseOverInterval = false;
  }

  public mouseOverContent() {
    this.isMouseOverContent = true;
  }

  public mouseLeaveContent() {
    this.isMouseOverContent = false;
  }
  public mouseOverUUID() {
    this.isMouseOverUUID = true;
  }

  public mouseLeaveUUID() {
    this.isMouseOverUUID = false;
  }
}
