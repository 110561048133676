import { ISimpleService } from '../api/ISimpleService';
import { SavedEntity } from '../api/SavedEntity';
import { IResource } from '../api/IResource';
import { BleKey } from '../api/building/BleKey';

export class BleKeyService {
  public keys: BleKey[];

  private endpoints = {
    ble: '/api/v1/ble-key'
  };

  constructor(
    private bleKeyResource: IResource<BleKey, number>,
    private simpleService: ISimpleService<
      BleKey,
      SavedEntity<BleKey, number>,
      void,
      void
    >,
    private generateApiUrl: (endpoint: string) => string
  ) {}

  public getAllKeysForBuilding(buildingId: number): Promise<BleKey[]> {
    return this.bleKeyResource.retrieveAllByParentId(buildingId);
  }

  public getKey(tokenId: number): Promise<BleKey> {
    return this.bleKeyResource.retrieve(tokenId);
  }

  public deleteKey(tokenId): Promise<{}> {
    return this.bleKeyResource.delete(tokenId);
  }

  public addKey(
    buildingId: number,
    keyDetails: BleKey
  ): Promise<SavedEntity<BleKey, number>> {
    return this.simpleService.post(
      this.generateApiUrl(`${this.endpoints.ble}/create/${buildingId}`),
      keyDetails.description
    );
  }

  public updateKey(buildingId: number, keyDetails: BleKey) {
    return this.simpleService.put(
      this.generateApiUrl(
        `${this.endpoints.ble}/${buildingId}/${keyDetails.id}`
      ),
      keyDetails.description
    );
  }

  public sendKey(buildingId: number, keyId: number) {
    return this.simpleService.put(
      this.generateApiUrl(`${this.endpoints.ble}/send/${buildingId}/${keyId}`)
    );
  }
}

export class BleKeys {
  constructor(public bleKey: BleKey[]) {}
}
