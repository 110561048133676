import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-panel-toggle',
  templateUrl: './panel-toggle.component.html',
  styleUrls: ['./panel-toggle.component.scss'],
  imports: [CommonModule],
  standalone: true
})
export class PanelToggleComponent {
  @Input() readonly icon: string;
  @Input() readonly label: string;
  @Input() toggleState: boolean;
  @Output() togglePanel = new EventEmitter<boolean>();

  constructor() {}

  onToggleClick(): void {
    this.toggleState = !this.toggleState;
    this.togglePanel.emit(this.toggleState);
  }
}
