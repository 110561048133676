import { OrNodesListController } from './OrNodesListController';
import { FilterService } from '@angularjs/or/services/FilterService';

export class OrNodesListDirective {
  constructor() {
    return {
      bindToController: true,
      controller: [
        '$scope',
        '$element',
        'SecurityService',
        'CopyToClipboardService',
        'FilterService',
        'UIRefreshService',
        'SensorNodeService',
        OrNodesListController
      ],
      controllerAs: 'nodesList',
      replace: true,
      restrict: 'E',
      scope: {
        activeNode: '=',
        isCompact: '=',
        selectedNode: '='
      },
      template: require('raw-loader!./or-nodes-list.html').default
    };
  }
}
