import * as angular from 'angular'; // Automatically added
import { TagService } from '@angularjs/or/services/TagService';
import {
  EmergencyLightingTestScheduleOutline,
  SchedulingStrategy
} from '@angularjs/or/api/building/EmergencyLightingTestSchedule';
import moment from 'moment';
import { SensorNodeService } from '@angularjs/or/services/SensorNodeService';
import { WeekdaySelection } from '@angularjs/or/util/WeekdaySelection';
import { PropertiesService } from '@angularjs/or/services/PropertiesService';
import { Floor } from '@angularjs/or/api/building/Floor';
import { FloorService } from '@angularjs/or/services/FloorService';
import { LightingTestSchedulingService } from '@angularjs/or/services/LightingTestSchedulingService/LightingTestSchedulingService';

export class OrLightingTestScheduleTileController {
  public details: EmergencyLightingTestScheduleOutline;
  public originalDetails: EmergencyLightingTestScheduleOutline;

  public form;
  public onSubmit;
  public isEditModeActive = false;
  public isValidTimerange = false;

  public datedSchedule = false;
  public currentFloor: Floor;
  public groupTags;
  public humanizedLastFunctionalTestRun: string;
  public humanizedCurrentFunctionalTestRun: string;
  public humanizedCurrentFunctionalTestRunEnd: string;
  public humanizedNextFunctionalTestRun: string;
  public humanizedNextFunctionalTestRunEnd: string;
  public humanizedLastDurationTestRun: string;
  public humanizedCurrentDurationTestRun: string;
  public humanizedCurrentDurationTestRunEnd: string;
  public humanizedNextDurationTestRun: string;
  public humanizedNextDurationTestRunEnd: string;
  public nextDurationTestRunEstimatedDuration: string;
  public timeOptions;
  public runsPerMonthOptions;
  public runsPerYearOptions;
  public schedulingStrategyOptions;
  public isFunctionalTest = false;
  public isDurationTest = false;
  public minDate: string;

  constructor(
    private $scope,
    private floorService: FloorService,
    private propertiesService: PropertiesService,
    private nodesService: SensorNodeService,
    private tagService: TagService,
    private lightingTestSchedulingService: LightingTestSchedulingService
  ) {}

  public $onInit() {
    this.timeOptions = this.produceTimeOptions();
    this.runsPerMonthOptions = this.produceRunsOptions(2);
    this.runsPerYearOptions = this.produceRunsOptions(2);
    this.schedulingStrategyOptions = Object.keys(SchedulingStrategy).filter(
      (k) => isNaN(Number(k))
    );
    this.updateHumanizedLabels();
    this.updateTestTypeForDatedSchedule();
    this.$scope.$watch('scheduleTile.details', () => {
      this.updateHumanizedLabels();
      this.updateTestTypeForDatedSchedule();
      this.datedSchedule =
        Object.assign(
          new WeekdaySelection(),
          this.details.days
        ).numberOfDaysActive() == 0 && this.details.startDate != null;
    });
    this.floorService.getCurrentFloor().then(
      (floor) => {
        this.currentFloor = floor;
      },
      (reason) => console.warn(reason)
    );
    this.minDate = new Date().toDateString();
  }

  public submit() {
    this.propertiesService
      .getProperty('scheduler.durationTestDurationMinutes')
      .then((durationTestDurationMinutes) => {
        if (
          !this.hasSufficientWindow(
            this.details.schedulingStrategy,
            durationTestDurationMinutes
          )
        ) {
          alert(
            'The test window is not large enough to complete the tests given the selected scheduling strategy. Please select additional days and/or a larger time window.'
          );
        } else {
          this.toggleEditMode(false);
          if (!angular.isFunction(this.onSubmit)) {
            return;
          }
          this.onSubmit();
        }
      });
  }

  public hasSufficientWindow(
    schedulingStrategy,
    durationTestDurationMinutes
  ): boolean {
    // TODO: use the schedulingStrategy here
    return this.dailyWindow() >= durationTestDurationMinutes / 60;
  }

  public dailyWindow() {
    const start = moment(this.details.startTime, 'HH:mm');
    const end = moment(this.details.endTime, 'HH:mm');
    let dailyWindow = moment.duration(end.diff(start)).asHours();
    if (dailyWindow < 0) {
      dailyWindow += 24;
    }
    return dailyWindow;
  }

  public discard() {
    if (!this.form.$pristine && !confirm('All changes will be lost')) {
      return;
    }
    this.toggleEditMode(false);
    this.lightingTestSchedulingService
      .getSchedule(this.details.id)
      .then((result) => {
        this.details = result;
        this.originalDetails = result;
      });
  }

  public isValidScheduleForm() {
    const isValid = !this.form.$pristine && this.isValidScheduleModel();
    return isValid;
  }

  public isValidScheduleModel() {
    if (this.datedSchedule) {
      return this.isValidDatedScheduleModel();
    } else {
      return this.isValidWeekdayScheduleModel();
    }
  }

  private isValidWeekdayScheduleModel() {
    const isValid =
      !!this.details.startTime &&
      !!this.details.endTime &&
      this.isValidWeekdaySelection(this.details.days) &&
      this.isValidTimerange &&
      this.details.functionalTest.runsPerMonth <= 2 &&
      this.details.functionalTest.runsPerMonth > 0 &&
      this.details.durationTest.runsPerYear <= 2 &&
      this.details.durationTest.runsPerYear > 0;
    return isValid;
  }

  private isValidDatedScheduleModel(): boolean {
    return (
      !!this.details.startTime &&
      !!this.details.endTime &&
      !!this.details.startDate &&
      moment().isSameOrBefore(
        moment(this.details.startDate, 'DD.MM.YYYY'),
        'day'
      ) &&
      this.isValidTimerange &&
      (this.isFunctionalTest || this.isDurationTest)
    ); // a dated schedule should be for either functional test or duration test or both
  }

  public toggleFunctionalTest() {
    this.details.functionalTest.runsPerMonth = this.isFunctionalTest ? 1 : 0;
  }

  public toggleDurationTest() {
    this.details.durationTest.runsPerYear = this.isDurationTest ? 1 : 0;
  }

  public isValidWeekdaySelection(days) {
    return Object.keys(days).some((day) => {
      return !!days[day];
    });
  }

  private produceRunsOptions(runsCount) {
    const runsOptions = [];
    for (let idx = 0; idx < runsCount; idx += 1) {
      runsOptions.push(idx + 1);
    }
    return runsOptions;
  }

  private updateTestTypeForDatedSchedule() {
    this.isFunctionalTest = this.details.functionalTest.runsPerMonth === 1;
    this.isDurationTest = this.details.durationTest.runsPerYear === 1;
  }

  private updateHumanizedLabels() {
    this.humanizedLastFunctionalTestRun = this.humanizeTimestamp(
      this.details.functionalTest.lastRun
    );
    this.humanizedCurrentFunctionalTestRun = this.humanizeTimestamp(
      this.details.functionalTest.currentRun
    );
    this.humanizedCurrentFunctionalTestRunEnd = this.humanizeTimestamp(
      this.details.functionalTest.currentRunEnd
    );
    this.humanizedNextFunctionalTestRun = this.humanizeTimestamp(
      this.details.functionalTest.nextRun
    );
    this.humanizedNextFunctionalTestRunEnd = this.humanizeTimestamp(
      this.details.functionalTest.nextRunEnd
    );
    this.humanizedLastDurationTestRun = this.humanizeTimestamp(
      this.details.durationTest.lastRun
    );
    this.humanizedCurrentDurationTestRun = this.humanizeTimestamp(
      this.details.durationTest.currentRun
    );
    this.humanizedCurrentDurationTestRunEnd = this.humanizeTimestamp(
      this.details.durationTest.currentRunEnd
    );
    this.humanizedNextDurationTestRun = this.humanizeTimestamp(
      this.details.durationTest.nextRun
    );
    this.humanizedNextDurationTestRunEnd = this.humanizeTimestamp(
      this.details.durationTest.nextRunEnd
    );
  }

  private produceTimeOptions() {
    const timeOptions = [];
    for (let hours = 0; hours < 24; hours += 1) {
      for (let minutes = 0; minutes < 60; minutes += 15) {
        timeOptions.push(
          [('0' + hours).slice(-2), ('0' + minutes).slice(-2)].join(':')
        );
      }
    }
    return timeOptions;
  }

  public toggleEditMode(isActive) {
    if (this.isFunctionalTestRunning() || this.isDurationTestRunning()) {
      alert(
        'This schedule is currently running and can not be edited until it has finished.'
      );
    } else {
      this.isEditModeActive = !!isActive;
      if (this.isEditModeActive) {
        this.originalDetails = angular.copy(this.details);
      }
    }
  }

  public toggleEnabledState(isEnabled: boolean) {
    if (
      isEnabled === false &&
      !confirm(
        'This schedule is currently running! Are you sure you wish to disable it (all tests will be cancelled)?'
      )
    ) {
      return;
    }
    this.details.isEnabled = !!isEnabled;
    if (!angular.isFunction(this.onSubmit)) {
      return;
    }
    this.onSubmit();
  }

  public toggleDatedSchedule(datedSchedule: boolean) {
    if (datedSchedule) {
      this.details.days = new WeekdaySelection();
      this.details.startDate = this.originalDetails.startDate;
    } else {
      this.details.startDate = null;
      this.details.days = this.originalDetails.days;
    }
    this.datedSchedule = datedSchedule;
  }

  public decorateTag(tag) {
    if (!tag) {
      return;
    }
    if (!tag.color) {
      return;
    }
    return this.tagService.decorateTag(tag);
  }

  public humanizeTimestamp(unixTime: number): string {
    if (!unixTime) {
      return 'N/A';
    }
    return new Date(unixTime * 1000).toString();
  }

  public isDurationTestRunning(): boolean {
    return this.details.durationTest.running;
  }

  public isFunctionalTestRunning(): boolean {
    return this.details.functionalTest.running;
  }
}
