export type Action = () => void;

export type Producer<O> = () => O;

export type Consumer<I> = (I) => void;

export type DoubleConsumer<A, B> = (A, B?) => void;

export type Func<I, O> = (I) => O;

export type Predicate<I> = (I) => boolean;

export type Comparator<A, B> = (A, B) => boolean;

export const EmptyAction = () => {};
export const EmptyConsumer = (i) => {};
export const AlwaysTrue = (i) => true;
export const AlwaysFalse = (i) => false;
