import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { DialogType, MatDialogData } from '../confirm/confirm.component';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-info-dialog',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatDialogModule, MatIconModule],
  templateUrl: './info-dialog.component.html',
  styleUrl: './info-dialog.component.scss'
})
export class InfoDialogComponent {
  message: string;
  title: string;
  type: DialogType;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MatDialogData,
    private dialogRef: MatDialogRef<InfoDialogComponent>
  ) {
    this.message = data.message;
    this.title = data.title;
    this.type = data.type;
  }

  ngOnInit(): void {
    // intentionally left blank
  }

  ok() {
    this.dialogRef.close(true);
  }

  get dialogTitle(): string {
    return this.title ? this.title : 'Confirm this action';
  }

  get content(): string {
    return this.message;
  }
}
