import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Environment, environmentToken } from '@environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SensorNodeBleResource {
  private readonly baseUrl: string;

  constructor(protected http: HttpClient, @Inject(environmentToken) private environment: Environment) {
    this.baseUrl = `${environment.apiUrl}${environment.apiPath}/node-config`;
  }

  public enableBleScanning(nodeIds: number[], buildingId: number): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/${buildingId}/ble-scanning/enable`, nodeIds);
  }

  public enableBleScanningForBuilding(buildingId: number): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/${buildingId}/ble-scanning/enable`, null);
  }

  public disableBleScanning(nodeIds: number[], buildingId: number): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/${buildingId}/ble-scanning/disable`, nodeIds);
  }

  public disableBleScanningForBuilding(buildingId: number): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/${buildingId}/ble-scanning/disable`, null);
  }

  public queryBleScanningForBuilding(buildingId: number): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/${buildingId}/ble-scanning/query`, null);
  }

  public queryBleScanningForNodesAndBuilding(building: number, nodeIds: number[]): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/${building}/ble-scanning/query`, nodeIds);
  }
}
