import { NavigationService } from '@app/shared/services/navigation/navigation.service';
import { NavigationSection } from '@app/shared/services/navigation/navigation-section';
import { Entity, UIRefreshService } from '@angularjs/or/services/UIRefreshService';
import { UserService } from '@services/user/user.service';

export class OrSiteMenuController {
  isTenantUser = false;

  constructor(
    public navigationService: NavigationService,
    private uiRefreshService: UIRefreshService,
    private userService: UserService
  ) {
    this.userService.getCurrentUser().subscribe((user) => {
      this.isTenantUser = user?.tenantIds?.length > 0;
    });
  }

  public getNavigationSections(): NavigationSection[] {
    this.navigationService.getSiteMenu().forEach((nav) => {
      nav.url = nav.getRoute(this.navigationService.buildingId, this.navigationService.floorId);
    });
    return this.navigationService.getSiteMenu();
  }

  public navigatingTo(section: NavigationSection) {
    if (!section.isActive()) {
      this.uiRefreshService.change(Entity.PAGE);
    }
  }
}
