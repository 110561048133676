@if (schedule && building) {
  <div id="elmt-schedule-summary" [ngClass]="{
  'schedule-summary-collapsed': schedule.isCollapsed,
  'schedule-summary-paused': !schedule.enabled && !isNew,
  'schedule-summary-active': schedule.active,
  'or-disabled': !schedule.editable
  }">
    <mat-card class="tellus-mat-card">
      <mat-card-header class="tellus-mat-card-header" [id]="'schedule-' + (schedule.id || 'new')" [attr.data-cy]="'expand-or-collapse-' + (schedule.name || 'new')" (click)="toggleCollapse()">
        <div class="title-group">
          <mat-card-title [attr.data-cy]="schedule?.id+'-schedule-name-header'">
            {{schedule.name}}
          </mat-card-title>
          <mat-card-title [attr.data-cy]="schedule?.id+'-em-device-count'"
                          class='node-icon'
                          matBadge='{{schedule.nodeCount}}'
                          matBadgeColor='accent'
                          matBadgeOverlap='true'
                          matBadgePosition='after'
                          matBadgeDescription='Number of nodes part of this schedule'
                          title='Number of nodes part of this schedule'>
            <span class='or-icon or-small or-inverse or-icon-node'></span>
          </mat-card-title>
          <div class="toggle-container" appIsAuthorizedForBuilding="MANAGE_EMERGENCY_LIGHTING" [appBuildingIdToAuthorize]="building.id">
            <mat-slide-toggle
              [attr.data-cy]="schedule?.id+'-schedule-enabled-slider'"
              (click)="preventClickPropagation($event)"
              (change)="toggleScheduleEnableState($event)"
              [disabled]='!schedule.editable'
              [hideIcon]="true"
              [ngModel]="schedule.enabled">
            </mat-slide-toggle>
          </div>
        </div>
      </mat-card-header>
      <mat-card-content class="no-padding">
        @if(loadingTests) {
          <div class="spinner-container">
            <mat-spinner [diameter]="80" [strokeWidth]="6"></mat-spinner>
          </div>
        } @else {
          @if(!isNew && schedule.isCollapsed) {
            <div>
              <app-emergency-lighting-schedules-tile
                [schedule]="schedule"
                [building$]="building$"
                [tags$]="tags$">
              </app-emergency-lighting-schedules-tile>
            </div>
          }
          @if (isNew || !schedule.isCollapsed) {
            <div>
              <app-form-emergency-lighting-schedule
                [details]="schedule"
                [isNew]="isNew"
                [tags$]="tags$"
                [building$]='building$'
                (closeNewSchedule)="closeNewForm()"
                (resetAndCloseSchedule)="resetAndCollapse()"
                (deleteSchedule)="deleteSchedule()"
                (reloadScheduleEmitter)="saveSchedule()"
                (newScheduleCreationEmitter)="handleNewScheduleCreation($event)"
              ></app-form-emergency-lighting-schedule>
            </div>
          }
        }
      </mat-card-content>
    </mat-card>
  </div>
}

