import { ScheduleService } from '@angularjs/or/services/ScheduleService';
import { Schedule } from '@angularjs/or/api/building/Schedule';
import { TimeUtils } from '@angularjs/or/util/TimeUtils';

export class OrSchedulesListController {
  public schedules: Schedule[];
  public showNewForm = false;
  public isBusy: boolean;

  constructor(
    private $scope: ng.IScope,
    private scheduleService: ScheduleService
  ) {}

  public $onInit() {
    this.loadSchedules();
  }

  private loadSchedules() {
    this.isBusy = true;
    this.scheduleService.getSchedules().then((schedules) => {
      this.scheduleService.getEditableIds().then((ids) => {
        schedules.forEach((sched) => {
          sched.editable = ids.includes(sched.id);
        });
        this.init(schedules);
        this.isBusy = false;
      });
    });
  }

  private init(schedules: Schedule[]) {
    this.$scope.$apply(() => {
      this.schedules = schedules;
      this.isBusy = false;
    });
  }

  getTimes(): string[] {
    return TimeUtils.getIntervalsInDay();
  }

  createNew() {
    this.showNewForm = true;
  }

  onMoveUp(schedule: Schedule) {
    this.scheduleService
      .moveUp(schedule)
      .then(() => {
        this.loadSchedules();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  onMoveDown(schedule: Schedule) {
    this.scheduleService
      .moveDown(schedule)
      .then(() => {
        this.loadSchedules();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  onSaveNewSchedule() {
    this.showNewForm = false;
    this.loadSchedules();
  }

  onDeleteSchedule() {
    this.loadSchedules();
  }

  onClose() {
    this.showNewForm = false;
    this.loadSchedules();
  }
}
