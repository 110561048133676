import { Resource } from '../Resource';
import { PromiseRejectionHandler } from '../../promises/PromiseRejectionHandler';
import { PromiseUtils } from '../promises/PromiseUtils';
import { CircadianCurve } from '@angularjs/or/data/CircadianCurve';
import { ICircadianCurveResource } from '@angularjs/or/api/ICircadianCurveResource';

export class CircadianCurveResource
  extends Resource<CircadianCurve, number>
  implements ICircadianCurveResource
{
  constructor(
    $http: ng.IHttpService,
    baseUrl: string,
    rejectionHandler: PromiseRejectionHandler
  ) {
    super($http, baseUrl, rejectionHandler);
  }

  public addResource(
    buildingId: number,
    item: CircadianCurve
  ): Promise<CircadianCurve> {
    return PromiseUtils.wrapSingle(
      this.$http.post(this.baseUrl + '/' + buildingId, item),
      this.rejectionHandler
    );
  }

  public updateResource(
    id: number,
    item: CircadianCurve
  ): Promise<CircadianCurve> {
    return PromiseUtils.wrapSingle(
      this.$http.put(this.baseUrl + '/' + id, item),
      this.rejectionHandler
    );
  }
}
