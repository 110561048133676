import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Building } from '@app/shared/models/building.interface';
import { Floor } from '@app/shared/models/floor.interface';
import { Tag } from '@app/shared/models/tag.interface';
import { EmergencyLightingTestType } from '@app/shared/models/emergency-lighting-test-type';
import { EmergencyLightingTestState } from '@app/shared/models/emergency-lighting-test-state';
import { MatDatepickerInputEvent, MatDatepickerModule } from '@angular/material/datepicker';
import { TimeUtils } from '@app/shared/utils/time.utils';
import { ToastService } from '@services/toast/toast.service';
import { TimezoneUtils } from '@app/shared/utils/timezoneUtils';
import { IEmergencyLightingSchedule } from '@app/shared/models/emergency-lighting-schedule.interface';
import { DateType, FilterConfig, FilterType } from '@app/emergency-lighting/elmt-tests-filter/filter.config';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';
import { ElmtSelectedFiltersService } from '@app/emergency-lighting/elmt-tests-filter/elmt-selected-filters.service';

@Component({
  selector: 'app-elmt-tests-filter',
  templateUrl: './elmt-tests-filter.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatSelectModule,
    MatCheckboxModule,
    MatButtonModule,
    MatChipsModule,
    MatIconModule,
    MatDividerModule,
    MatListModule,
    MatDatepickerModule,
    MatInputModule,
    ReactiveFormsModule
  ],
  styleUrls: ['./elmt-tests-filter.component.scss']
})
export class ElmtTestsFilterComponent implements OnInit {
  @Input() building: Building;
  @Input() schedules: IEmergencyLightingSchedule[];
  @Input() floors: Floor[];
  @Input() tags: Tag[];
  @Output() filterSelections = new EventEmitter<void>();

  form: FormGroup;
  selectedOption: FilterType;

  maxFromDate: Date;
  minToDate: Date;
  maxToDate: Date;
  isFilterSelected = false;
  isFilterApplied = false;

  protected readonly FilterConfig = FilterConfig;
  protected readonly FilterType = FilterType;

  constructor(
    private fb: FormBuilder,
    private toast: ToastService,
    private elmtSelectedFiltersService: ElmtSelectedFiltersService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      schedule: this.fb.array(this.schedules.map((schedule) => this.getInnerFormGroup(schedule))),
      floor: this.fb.array(this.floors.map((floor) => this.getInnerFormGroup(floor))),
      tag: this.fb.array(this.tags.map((tag) => this.getInnerFormGroup(tag))),
      testType: this.fb.array(
        EmergencyLightingTestType.all().map((t) => this.fb.group({ id: t.value, name: t.displayValue, checked: false }))
      ),
      testStatus: this.fb.array(
        EmergencyLightingTestState.all().map((t) => this.fb.group({ id: t.val, name: t.display, checked: false }))
      ),
      startDate: [null],
      endDate: [null]
    });

    this.selectItem(FilterType.SCHEDULE);
    this.maxFromDate = TimeUtils.convertTimezone(
      new Date(),
      TimezoneUtils.mapUtcToKnownTimezone(this.building?.timeZone)
    );
    this.maxToDate = new Date(this.maxFromDate);
    this.filterFormValueChangeListener();
    this.applySavedFilters();
  }

  filterFormValueChangeListener(): void {
    this.form.valueChanges.subscribe((newFormValue) => {
      this.isFilterSelected = Object.keys(newFormValue)
        .filter((key) => key !== 'startDate' && key !== 'endDate') // filter all form properties except startDate and endDate
        .some((key) => newFormValue[key].some(({ checked }) => checked)); // check if any of them is checked
      this.isFilterSelected = this.isFilterSelected || !!newFormValue.startDate || !!newFormValue.endDate;

      if (!this.isFilterSelected && this.isFilterApplied) {
        this.filter();
      }
    });
  }

  getInnerFormGroup(property: IEmergencyLightingSchedule | Floor | Tag): FormGroup {
    return this.fb.group({ id: property.id, name: property.name, checked: false });
  }

  selectedItemArray(controlName: string): any[] {
    return controlName ? this.formArray(controlName).value.filter((s) => s.checked) : [];
  }

  formArray(controlName: string): FormArray {
    return this.form.get(controlName) as FormArray;
  }

  removeSelection(formArray: FormArray, option: any): void {
    formArray.controls
      .filter((control) => control.get('id').value === option.id)
      .forEach((control) => control.get('checked').setValue(false));
  }

  selectItem(option: FilterType): void {
    this.selectedOption = option;
  }

  getIds(controlName: string): any[] {
    return this.selectedItemArray(controlName).map((s) => s.id);
  }

  filter(): void {
    const filterCriteria = {
      schedule: this.getIds(FilterType.SCHEDULE.toString()),
      floor: this.getIds(FilterType.FLOOR.toString()),
      tag: this.getIds(FilterType.TAG.toString()),
      testType: this.getIds(FilterType.TEST_TYPE.toString()),
      testStatus: this.getIds(FilterType.TEST_STATUS.toString()),
      startDateUTC: TimeUtils.getDateInUTC(this.startDate.value, DateType.START_DATE, this.building.timeZone),
      endDateUTC: TimeUtils.getDateInUTC(this.endDate.value, DateType.END_DATE, this.building.timeZone)
    };
    this.elmtSelectedFiltersService.setFilters(filterCriteria);
    this.filterSelections.emit();
    this.isFilterApplied = Object.values(filterCriteria).some((value) => !this.isEmptyValue(value));
  }

  isEmptyValue(value: any[] | Date): boolean {
    return Array.isArray(value) ? value.length === 0 : value == null;
  }

  clearDateFilter(): void {
    this.startDate.reset();
    this.endDate.reset();
  }

  resetFilters(): void {
    FilterConfig.forEach((config) => {
      if (config.type !== FilterType.DATE) {
        this.formArray(config.formKey).controls.forEach((control) => {
          control.get('checked').setValue(false);
        });
      }
    });
    this.clearDateFilter();
    this.elmtSelectedFiltersService.clearFilters();
  }

  selectStartDate($event: MatDatepickerInputEvent<Date, Date | null>): void {
    this.minToDate = $event.value;
    if (this.startDate.value > this.endDate.value || !this.endDate.value) {
      this.endDate.setValue(
        TimeUtils.convertTimezone(new Date(), TimezoneUtils.mapUtcToKnownTimezone(this.building?.timeZone))
      );
      this.toast.warning({
        message: 'Changed the "To Date" to current date.',
        dataCy: 'warning-toast'
      });
    }
  }

  selectEndDate($event: MatDatepickerInputEvent<Date, Date | null>): void {
    const utcEndDate = TimeUtils.getDateInUTC($event.value, DateType.END_DATE, this.building.timeZone);
    this.endDate.setValue(utcEndDate);
  }

  get startDate(): FormControl {
    return this.form.get('startDate') as FormControl;
  }

  get endDate(): FormControl {
    return this.form.get('endDate') as FormControl;
  }

  public applySavedFilters(): void {
    const savedFilters = this.elmtSelectedFiltersService.getFilters();
    this.startDate.setValue(savedFilters.startDateUTC);
    this.endDate.setValue(savedFilters.endDateUTC);

    FilterConfig.forEach((config) => {
      if (config.type !== FilterType.DATE) {
        this.formArray(config.formKey).controls.forEach((control) => {
          const idSet = new Set(savedFilters[config.formKey]);
          if (idSet.has(control.get('id').value)) {
            control.get('checked').setValue(true);
          }
        });
      }
    });
    // This only selects the saved filters in the filter component UI
    // Applying saved filters happens and reloading logs table happens in emergency-lighting-logs.component.ts,
    // which prevents the logs from being loaded twice,
    // and prevents error where the applied filters are not reflected in the logs
  }
}

export { DateType };
