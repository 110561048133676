import * as d3 from 'd3';

import BaseEvent = d3.BaseEvent;
import { AlwaysFalse, Consumer, EmptyConsumer, Predicate } from '@app/shared/utils/Delegates';
import { DataPoint } from '@app/analytics/metric-widget/data-objects/data-point';
import { ChartData } from '@app/analytics/metric-widget/data-objects/chart-data';

export class RenderableChart {
  private static HTML_PREFIX = 'or-metric-widget-chart-bar';

  private chartWidth: number;
  private marginLeft: number;
  private marginRight: number;
  private barThickness: number;
  private scaleX: (x: number) => number;
  private clickCount = 0;
  private timeout;

  constructor(
    private chart: ChartData,
    private container: any,
    private canSelect: () => boolean = () => false,
    private onSelect: (points: DataPoint[]) => void = () => {},
    private canZoom: Predicate<number> = AlwaysFalse,
    private onZoom: Consumer<number> = EmptyConsumer
  ) {
    this.marginLeft = 40;
    this.marginRight = 60;
    this.barThickness = 14;
  }

  public render(): void {
    this.container.style.height = this.chart.values.length * this.barThickness;

    this.chartWidth = this.container.clientWidth - (this.marginLeft + this.marginRight);
    const domainX = [
      0,
      d3.max(this.chart.values, (point: DataPoint) => {
        return point.y;
      })
    ];
    this.scaleX = d3.scaleLinear().range([0, this.chartWidth]).domain(domainX).nice();

    const d3Chart = d3.select(this.container);
    d3Chart.selectAll('*').remove();

    const self = this;
    const bar: d3.Selection<SVGGElement, DataPoint, any, unknown> = d3Chart
      .selectAll('svg')
      .data(this.chart.values)
      .enter()
      .append('g')
      .attr('class', RenderableChart.HTML_PREFIX)
      .classed(RenderableChart.HTML_PREFIX + '-selected', (entry) => entry.isActive)
      .on('mousedown', (dp) => self.onMouseDown(dp as unknown as Event));

    this.renderBar(bar);
  }

  private onMouseDown(event: Event | BaseEvent): void {
    this.clickCount++;

    if (this.clickCount === 1) {
      this.timeout = setTimeout(() => {
        this.clickCount = 0;
        this.singleClick(event);
      }, 300);
    } else {
      clearTimeout(this.timeout);
      this.clickCount = 0;
      this.doubleClick(event);
    }
  }

  private singleClick(event: Event | BaseEvent): void {
    const target = ((event as Event).target as Node).parentNode;
    const point = (event as any).target.__data__;

    (event as Event).stopPropagation();

    const isZoomOut = (event as MouseEvent).which === 3;
    const keyboardEvent = event as KeyboardEvent;

    if (isZoomOut && this.canZoom(-1)) {
      this.onZoom(-1);
      return;
    }

    this.select(target, point, keyboardEvent.shiftKey);
  }

  private select(target, point, isMultiSelect: boolean, isActive?: boolean): void {
    if (this.canSelect()) {
      const wasActive = point.isActive;

      if (!isMultiSelect) {
        d3.selectAll('svg g')
          .classed(RenderableChart.HTML_PREFIX + '-selected', false)
          .data()
          .filter((p) => p != null)
          .forEach((p) => ((p as any).isActive = false));
      }

      point.isActive = isActive || !wasActive;
      d3.select(target).classed(RenderableChart.HTML_PREFIX + '-selected', point.isActive);

      const active = d3.selectAll('svg g.' + RenderableChart.HTML_PREFIX + '-selected').data();
      this.onSelect(active as DataPoint[]);
    }
  }

  private doubleClick(event: Event | BaseEvent): void {
    const target = ((event as Event).target as Node).parentNode;
    const point = (event as any).target.__data__;
    const keyboardEvent = event as KeyboardEvent;
    this.select(target, point, keyboardEvent.shiftKey, true);
    this.onZoom(1);
  }

  private renderBar(bar: d3.Selection<SVGGElement, DataPoint, any, unknown>): void {
    this.renderBarContainer(bar);
    this.renderBarValue(bar);
    this.renderBarTip(bar);
    this.renderBarTimestamp(bar);
    this.renderBarLabel(bar);
  }

  private renderBarContainer(bar: d3.Selection<SVGGElement, DataPoint, any, unknown>): void {
    bar
      .append('rect')
      .attr('class', RenderableChart.HTML_PREFIX + '-container')
      .attr('height', this.barThickness - 1)
      .attr('width', this.chartWidth)
      .attr('x', this.marginLeft)
      .attr('y', (_e, i) => i * this.barThickness);
  }

  private renderBarValue(bar: d3.Selection<SVGGElement, DataPoint, any, unknown>): void {
    bar
      .append('rect')
      .attr('class', RenderableChart.HTML_PREFIX + '-fill')
      .attr('height', this.barThickness - 1)
      .attr('width', (e) => Math.max(0, this.scaleX(e.y) - 2))
      .attr('x', this.marginLeft)
      .attr('y', (_e, i) => i * this.barThickness);
  }

  private renderBarTip(bar: d3.Selection<SVGGElement, DataPoint, any, unknown>): void {
    bar
      .append('rect')
      .attr('class', RenderableChart.HTML_PREFIX + '-tip')
      .attr('height', this.barThickness - 1)
      .attr('width', 1)
      .attr('x', (e) => Math.max(0, this.marginLeft + this.scaleX(e.y) - 1))
      .attr('y', (_e, i) => i * this.barThickness);
  }

  private renderBarTimestamp(bar: d3.Selection<SVGGElement, DataPoint, any, unknown>): void {
    bar
      .append('text')
      .attr('class', RenderableChart.HTML_PREFIX + '-label ' + RenderableChart.HTML_PREFIX + '-timestamp')
      .attr('fill', 'white')
      .attr('x', this.marginLeft - 4)
      .attr('y', (_e, i) => i * this.barThickness + this.barThickness / 2 + 3)
      .text((entry: DataPoint) => entry.xLabel);
  }

  private renderBarLabel(bar: d3.Selection<SVGGElement, DataPoint, any, unknown>): void {
    bar
      .append('text')
      .attr('class', RenderableChart.HTML_PREFIX + '-label ' + RenderableChart.HTML_PREFIX + '-value')
      .attr('x', (e) => this.marginLeft + this.scaleX(e.y) + 4)
      .attr('y', (_e, i) => i * this.barThickness + this.barThickness / 2 + 3)
      .text((entry: DataPoint) => entry.yLabel);
  }
}
