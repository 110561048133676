<div class='custom-recurrence-dialog'>
  <h2 mat-dialog-title>Custom recurrence</h2>
  <mat-dialog-content class='overflow-visible'>
    <form [formGroup]='customRecurrenceForm' autocomplete='off' data-cy='custom-recurrence-form' (ngSubmit)="save()">
      <div class='schedule-dialog-column'>
        <div class='schedule-dialog-row'>
          <span>Repeat every</span>
          <mat-form-field class='repeat-every-fields' appearance='fill'>
            <mat-label>Count</mat-label>
            <input matInput formControlName='repeatEvery' type='number' min='1' max='30' step='1' [required]="true" data-cy='custom-recurrence-count-input'>
          </mat-form-field>
          <mat-form-field class='repeat-every-fields' appearance='fill'>
            <mat-label>Repeat Unit</mat-label>
            <mat-select formControlName='repeatUnitString' [required]="true" data-cy='custom-recurrence-count-unit'>
              <mat-option *ngFor='let unit of repeatUnitStrings' [value]='unit'>
                {{ unit.displayNamePlural }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class='schedule-dialog-row' *ngIf='isWeekSelected()'>
          <span>Repeat On</span>
          <mat-button-toggle-group class="weekday-toggle-group" multiple formControlName="weekdaySelection" data-cy='custom-recurrence-weekday-select'>
            <mat-button-toggle *ngFor='let weekday of weekdays' [value]='weekday'>
              {{ weekday.getLabel(true) }}
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div class='schedule-dialog-row' *ngIf='isMonthSelected()'>
          <mat-form-field appearance='fill'>
            <mat-select formControlName="monthlyRepeatUnit" [required]="true" data-cy='custom-recurrence-month-select'>
              <mat-option *ngFor='let choice of monthlyChoices' [value]='choice.repetitionUnit'>
                {{ choice.displayName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <span>Ends</span>

          <mat-radio-group class='ends-radio-group' aria-label='Select an option' formControlName='repeatEndsOption' (change)="onEndsRadioButtonChange($event.value)">
            <div class='schedule-dialog-row'>
              <mat-radio-button color='primary' class='ends-radio-button' [value]='repeatEndsOptions[0]' data-cy='custom-recurrence-end-never-radio-btn'>
                {{repeatEndsOptions[0]}}
              </mat-radio-button>
            </div>
            <div class='schedule-dialog-row'>
              <mat-radio-button color='primary' class='ends-radio-button' [value]='repeatEndsOptions[1]' data-cy='custom-recurrence-ends-on-radio-btn'>
                {{repeatEndsOptions[1]}}
              </mat-radio-button>
              <mat-form-field appearance='fill' class='example-form-field' name='endsOn'>
                <mat-label>Date</mat-label>
                <input matInput [matDatepicker]='datepicker' formControlName='repeatEndsOn' [min]="startDate"
                       [required]="isRepeatEndsOptionSelected(repeatEndsOptions[1])" data-cy='custom-recurrence-end-on-date-picker'>
                <mat-datepicker-toggle matSuffix [for]='datepicker' data-cy='custom-recurrence-occurrence-ends-on-datepicker-toggle'></mat-datepicker-toggle>
                <mat-datepicker #datepicker>
                </mat-datepicker>
              </mat-form-field>
            </div>
            <div class='schedule-dialog-row'>
              <mat-radio-button color='primary' class='ends-radio-button' [value]='repeatEndsOptions[2]' data-cy='custom-recurrence-after-occurrence-radio-btn'>
                {{repeatEndsOptions[2]}}
              </mat-radio-button>
              <mat-form-field appearance='fill'>
                <mat-label>Occurrences</mat-label>
                <input matInput formControlName='repeatEndsAfter' type='number' min='1' max='30' step='1'
                       [required]="isRepeatEndsOptionSelected(repeatEndsOptions[2])" data-cy='custom-recurrence-after-occurrence-input'>
              </mat-form-field>
            </div>
          </mat-radio-group>
        </div>
      </div>
      <button mat-raised-button type="button" (click)='close()' data-cy='custom-recurrence-close-btn'>Close</button>
      <button mat-raised-button color='primary' type="submit" data-cy='custom-recurrence-save-btn'>Save</button>
    </form>
  </mat-dialog-content>
</div>
