<div id='circadianCurvesList'>
  <div class='or-content-header'>
    <ul class='or-inline-items or-pull-right'>
      <li class='or-inline-item'>
        <button class='or-button or-primary' type='button' [disabled]="showNewForm || editingCurveRanks" (click)="createNew()">
          <span class='or-icon or-small or-inverse or-icon-plus'></span>
          <span class='or-button-label'>Create new circadian curve</span>
        </button>
      </li>
      <li class='or-inline-item'>
        <button class="or-button or-positive" type="button" [disabled]="!editingCurveRanks" (click)="saveRankChanges()">
          <span class="or-icon or-small or-inverse or-icon-checkmark"></span
          ><span class="or-button-label">Save rank changes</span>
        </button>
      </li>
      <li class='or-inline-item'>
        <button class="or-button" type="button" [disabled]="!editingCurveRanks" (click)="refreshList()">
          <span class="or-icon or-small or-icon-reload"></span
          ><span class="or-button-label">Reset rank changes</span>
        </button>
      </li>
    </ul>
  </div>
  <div class='or-content-body' *ngIf="!showNewForm && (curves.length < 1 || isBusy)">
    <span>No circadian curve found</span> <!-- use banner component here -->
  </div>
  <div class='or-content-body circadian-curves-timescale-container' *ngIf="(curves.length > 0) || (showNewForm === true)">
    <div class='circadian-curves-timescale'>
      <ol class='circadian-curves-list'>
        <li class='circadian-curves-list-item' *ngIf="showNewForm">
          <app-circadian-curve-summary
            [isNew]="true"
            (refreshListEmitter)="refreshList()"
            (closeNewFormEmitter)="closeNew()"
            [curvesList]="curves"
          ></app-circadian-curve-summary>
        </li>
        <li class='circadian-curves-list-item' *ngFor="let curve of curves">
          <app-circadian-curve-summary
            [details]="curve"
            (refreshListEmitter)="refreshList()"
            (closeNewFormEmitter)="closeNew()"
            (editRankEmitter) = "editRanks()"
            (collapseEmitter) = "collapseCurves()"
            [curvesList]="curves"
          ></app-circadian-curve-summary>
        </li>
      </ol>
    </div>
  </div>
</div>
