import { ICreateResource } from '../api/IResource';
import { SensorNode } from '../api/building/SensorNode';
import { FloorService } from './FloorService';
import { Pair } from '../util/Pair';
import { MapUtils } from '../util/MapUtils';
import { IFloorResource } from '../api/IFloorResource';
import { UserService } from './UserService';
import { IBuildingService } from './IBuildingService';
import { VirtualNotification } from '../api/building/VirtualNotification';
import { FloorplanSensorNode } from '../api/building/FloorplanSensorNode';
import { Observable } from '../util/Observable';
import { ArrayUtils } from '../util/ArrayUtils';
import { Map } from '../util/Map';
import { IEmergencyLightingResource } from '../api/resources/IEmergencyLightingResource';
import { EmergencyLightingTestType } from '../data/EmergencyLightingTestType';
import { EmergencyLightingTest } from '../api/building/EmergencyLightingTest';
import { EmergencyLightingTestState } from '../data/EmergencyLightingTestState';
import { IEmergencyLightingTestResultResource } from '../api/resources/IEmergencyLightingTestResultResource';
import { EmergencyLightGroup } from '../api/building/EmergencyLightGroup';
import * as angular from 'angular';
import { BeaconSettingService } from '@angularjs/or/services/BeaconSettingService';
import { SensorNodeResource } from '@angularjs/or/angular/resources/SensorNodeResource';
import { SimpleLampType } from '@angularjs/or/api/building/LampType';
import { LightingConfigurationService } from '@angularjs/or/services/LightingConfigurationService';
import { FeatureService } from '@app/shared/services/feature.service';
import { EmDriverService } from '@angularjs/or/services/EmDriverService';
import { LuminaireDriverService } from '@angularjs/or/services/LuminaireDriverService';
import { EmDriver } from '@angularjs/or/api/building/EmDriver';
import { LuminaireDriver } from '@angularjs/or/api/building/LuminaireDriver';
import { LampTypeService } from '@angularjs/or/services/LampTypeService';
import { Subject } from 'rxjs';
import { Entity, UIRefreshService } from '@angularjs/or/services/UIRefreshService';
import { DuplicateMappingResource } from '@angularjs/or/angular/resources/DuplicateMappingResource';
import { ISelectable } from '@angularjs/or/api/building/ISelectable';
import { SensorNodeBleResource } from '@angularjs/or/angular/resources/SensorNodeBleResource';
import { DISCRIMINATOR } from '@app/shared/models/selectable.interface';

export class SensorNodeService {
  private readonly bleErrorMessage =
    'Ensure only connected nodes are selected, please note BLE Scanning messages are not supported on Passive Node devices';

  constructor(
    private floorResource: IFloorResource,
    private nodeResource: SensorNodeResource,
    private mappingResource: ICreateResource<VirtualNotification, number>,
    private floorService: FloorService,
    private userService: UserService,
    private buildingService: IBuildingService,
    private emergencyLightingResource: IEmergencyLightingResource,
    private emergencyLightingTestResultResource: IEmergencyLightingTestResultResource,
    private beaconSettingService: BeaconSettingService,
    private lightingConfigurationService: LightingConfigurationService,
    private featureService: FeatureService,
    private emDriverService: EmDriverService,
    private luminaireDriverService: LuminaireDriverService,
    private lampTypeService: LampTypeService,
    private uiRefreshService: UIRefreshService,
    private duplicateMappingResource: DuplicateMappingResource,
    private sensorNodeBleResource: SensorNodeBleResource
  ) {
    this.requestNodes.subscribe((force) => {
      if (force) {
        this.floorService.getCurrentFloor().then(
          (floor) => {
            if ((floor.id === this.currentFloorId || this.currentFloorId === undefined) && this.inFlight) {
              // do nothing yet as inflight already
            } else if ((floor.id !== this.currentFloorId || this.currentFloorId === undefined) && !this.inFlight) {
              this.currentFloorId = floor.id;
              this.getNodesWithDrivers();
            } else if (
              floor.id === this.currentFloorId &&
              this.latestResults != null &&
              Object.keys(this.latestResults).length > 0 &&
              !this.inFlight
            ) {
              this.getNodesWithDrivers();
            }
          },
          (reason) => console.warn(reason)
        );
      } else {
        this.floorService.getCurrentFloor().then(
          (floor) => {
            if (floor.id === this.currentFloorId) {
              this.refreshNodes.next(this.nodes);
            }
          },
          (reason) => console.warn(reason)
        );
      }
    });

    this.requestDuplicateMappings.subscribe((buildingId) => {
      this.updatingDuplicateMappingInProgress = true;
      const nodeIdsToFetch = [];
      this.nodes.forEach((node) => {
        nodeIdsToFetch.push(node.id);
        node.duplicateAddressMappings = [];
      });

      this.duplicateMappingResource
        .getDuplicateAddressMappingsByNodeIds(buildingId, nodeIdsToFetch)
        .then((floorDuplicateMappings) => {
          const floorDuplicateMappingNodes = [];
          for (const [nodeIdString, nodeDuplicateMappings] of Object.entries(floorDuplicateMappings)) {
            const nodeId = Number(nodeIdString);
            const index = this.nodes.findIndex((node) => node.id === nodeId);
            if (index !== -1) {
              this.nodes[index].duplicateAddressMappings = nodeDuplicateMappings;
              floorDuplicateMappingNodes.push(this.nodes[index]);
            }
          }
          this.refreshDuplicatesAfterMapping.next(floorDuplicateMappingNodes);
        })
        .catch((reason) => {
          this.updatingDuplicateMappingInProgress = false;
          console.error(reason);
        });
    });

    this.requestNodesById.subscribe((nodeIdsToFetch) => {
      this.updatingMappedNodeInProgress = true;
      this.fetchNodesById(nodeIdsToFetch)
        .then((nodes) => {
          this.refreshNewlyMappedNodes.next(nodes);
        })
        .catch((reason) => {
          this.updatingMappedNodeInProgress = false;
          console.error(reason);
        });
    });

    this.floorService.floorChange.subscribe((floorId) => {
      this.requestNodes.next(this.currentFloorId !== floorId);
    });

    this.uiRefreshService.onChange(Entity.NODE, () => {
      this.requestNodes.next(true);
    });

    this.uiRefreshService.onChange(Entity.TAG, () => {
      this.requestNodes.next(true);
    });

    this.refreshNodes.subscribe((nodes) => {
      this.inFlight = false;
      this.floorplanNodes = this.createFloorplanNodes(nodes);
      this.populateFloorNodeTestResults(this.floorplanNodes, this.emergencyResults);
      this.refreshFloorPlanNodes.next(this.floorplanNodes);
    });

    this.requestNodes.next(false);
  }

  public static readonly emDriverIdx = 0;
  public static readonly luminaireDriverIdx = 1;
  public static readonly beaconSettingIdx = 2;
  public static readonly lightingConfigIdx = 3;
  public static readonly tagDataIdx = 4;
  public static readonly lampTypeDataIdx = 6;
  public static readonly duplicateAddressMappingIdx = 7;
  public static readonly duplicateGroupMappingIdx = 8;
  public isBusy = false;
  public nodes: SensorNode[] = [];
  public floorplanNodes: FloorplanSensorNode[] = [];
  public selectedNodes: Observable<FloorplanSensorNode[]> = new Observable([]);
  public selectedEmDrivers: Observable<EmDriver[]> = new Observable([]);
  public isSelectionModeActive = false;
  private hasMappingBeenAttempted: Map<boolean> = {};
  private gatewayToNodesMapPromise: Promise<Map<SensorNode[]>> = null;
  private currentFloorId: number;
  private registeredListedNodes: FloorplanSensorNode[] = [];
  private registeredFloorplanNodes: FloorplanSensorNode[] = [];
  public updateBeaconSettingPanel;
  public updateLightingConfigPanel;
  public refreshNodes = new Subject<SensorNode[]>();

  public requestNodesById = new Subject<number[]>();
  public requestDuplicateMappings = new Subject<number>();
  public refreshNewlyMappedNodes = new Subject<SensorNode[]>();
  public refreshDuplicatesAfterMapping = new Subject<SensorNode[]>();
  public updatingDuplicateMappingInProgress = false;
  public updatingMappedNodeInProgress = false;

  public refreshFloorPlanNodes = new Subject<FloorplanSensorNode[]>();
  public requestNodes = new Subject<boolean>();
  private inFlight = false;
  private latestResults: {};
  private emergencyResults;

  private radius1 = 22; // (24 + 12) / 2 + 4
  private radius2 = this.radius1 + 16; // (12 / 2) + 10
  private radius3 = this.radius2 + 16; // (12 / 2) + 10;
  private radiusForPN = 65;

  private getResultsObject(defaultValue: any): Record<number, any> {
    const results = {};
    results[SensorNodeService.emDriverIdx] = defaultValue;
    results[SensorNodeService.luminaireDriverIdx] = defaultValue;
    results[SensorNodeService.beaconSettingIdx] = defaultValue;
    results[SensorNodeService.lightingConfigIdx] = defaultValue;
    results[SensorNodeService.tagDataIdx] = defaultValue;
    results[SensorNodeService.lampTypeDataIdx] = defaultValue;
    results[SensorNodeService.duplicateAddressMappingIdx] = defaultValue;
    results[SensorNodeService.duplicateGroupMappingIdx] = defaultValue;
    return results;
  }

  public setMappingAttempted(nodeId: number): void {
    this.hasMappingBeenAttempted[nodeId.toString()] = true;
  }

  public resetMappingAttempted(nodeId: number): void {
    this.hasMappingBeenAttempted[nodeId.toString()] = false;
  }

  public reloadSpecificData(dataIdxs: number[], dataPromises: Promise<any[]>[]): void {
    if (!this.latestResults) {
      this.latestResults = this.getResultsObject(false);
    }
    for (let i = 0; i < dataIdxs.length; i++) {
      this.latestResults[dataIdxs[i]] = null;
    }
    for (let i = 0; i < dataPromises.length; i++) {
      dataPromises[i]
        .then((nodeData) => {
          return (this.latestResults[dataIdxs[i]] = nodeData);
        })
        .catch(() => (this.latestResults[dataIdxs[i]] = true));
    }
    this.checkUpdate(this.latestResults, this.nodes);
  }

  public getTagData(): Promise<any> {
    return this.nodeResource.getTagsForNodes(this.nodes);
  }

  public getLampData(buildingId: number): Promise<any> {
    return this.lampTypeService.getLampTypes(buildingId);
  }

  public getLuminaireData(): Promise<any> {
    return this.luminaireDriverService.getByFloorId(this.currentFloorId);
  }

  private getNodesWithDrivers(): void {
    this.inFlight = true;
    const results = this.getResultsObject(null);
    this.beaconSettingService
      .getBeaconSettingsForFloor(this.currentFloorId)
      .then((beaconSettings) => (results[SensorNodeService.beaconSettingIdx] = beaconSettings))
      .catch(() => (results[SensorNodeService.beaconSettingIdx] = true));

    this.lightingConfigurationService
      .getLightingConfigsForFloor(this.currentFloorId)
      .then((lightingConfig) => (results[SensorNodeService.lightingConfigIdx] = lightingConfig))
      .catch(() => (results[SensorNodeService.lightingConfigIdx] = true));

    this.luminaireDriverService
      .getByFloorId(this.currentFloorId)
      .then((drivers) => (results[SensorNodeService.luminaireDriverIdx] = drivers))
      .catch(() => (results[SensorNodeService.luminaireDriverIdx] = true));

    this.getNodes().then((nodes) => {
      this.emDriverService
        .getByNodes(nodes)
        .then((drivers) => (results[SensorNodeService.emDriverIdx] = drivers))
        .catch(() => (results[SensorNodeService.emDriverIdx] = true));

      this.nodeResource
        .getTagsForNodes(nodes)
        .then((tags) => (results[SensorNodeService.tagDataIdx] = tags))
        .catch(() => (results[SensorNodeService.tagDataIdx] = true));

      this.buildingService.getCurrentBuilding().then((building) => {
        this.lampTypeService
          .getLampTypes(building.id)
          .then((lampTypes) => (results[SensorNodeService.lampTypeDataIdx] = lampTypes))
          .catch(() => (results[SensorNodeService.lampTypeDataIdx] = true));

        this.duplicateMappingResource
          .getDuplicateAddressMappings(building.id, nodes)
          .then((duplicateMappings) => {
            results[SensorNodeService.duplicateAddressMappingIdx] = duplicateMappings;
          })
          .catch(() => (results[SensorNodeService.duplicateAddressMappingIdx] = true));

        this.duplicateMappingResource
          .getDuplicateGroupMappings(building.id, nodes)
          .then((duplicateMappings) => {
            results[SensorNodeService.duplicateGroupMappingIdx] = duplicateMappings;
          })
          .catch(() => (results[SensorNodeService.duplicateGroupMappingIdx] = true));
      });

      this.checkUpdate(results, nodes);
    });
  }

  private checkUpdate(results: Record<number, any>, nodes: SensorNode[]): void {
    setTimeout(() => {
      let check = true;
      for (const prop in results) {
        if (results[prop] == null) {
          check = false;
        }
      }
      if (check) {
        this.latestResults = results;
        this.updateNodeData(results, nodes);
      } else {
        this.checkUpdate(results, nodes);
      }
    }, 200);
  }

  private updateNodeData(results: Record<number, any>, nodes: SensorNode[]): void {
    const emDrivers = results[SensorNodeService.emDriverIdx];
    const luminaireDrivers = results[SensorNodeService.luminaireDriverIdx];
    const beaconSettings = results[SensorNodeService.beaconSettingIdx];
    const lightingConfigs = results[SensorNodeService.lightingConfigIdx];
    const nodeIdToTagsMap = results[SensorNodeService.tagDataIdx];
    const lampTypes = results[SensorNodeService.lampTypeDataIdx];
    const duplicateAddressMappings = results[SensorNodeService.duplicateAddressMappingIdx];
    const duplicateGroupMappings = results[SensorNodeService.duplicateGroupMappingIdx];

    const beaconMap = {};
    if (Array.isArray(beaconSettings)) {
      beaconSettings.forEach((setting) => {
        beaconMap[setting.sensorNodeId] = setting;
      });
    }

    const lightingMap = {};
    if (Array.isArray(lightingConfigs)) {
      // Because we use 204 No-Content, that comes through as an empty string
      lightingConfigs.forEach((lightingConfig) => {
        lightingMap[lightingConfig.nodeId] = lightingConfig;
      });
    }

    const lampTypeMap = {};
    let defaultLampType;
    if (Array.isArray(lampTypes)) {
      lampTypes.forEach((lamp) => {
        if (lamp.name === 'Default') {
          defaultLampType = new SimpleLampType(
            lamp.powerConsumptionMax,
            null,
            null,
            null,
            lamp.name,
            lamp.lampTypeId,
            lamp.lampTypeId,
            lamp.lampTypeTemplateId,
            lamp.ratedPower
          );
        }
        lampTypeMap[lamp.lampTypeId] = new SimpleLampType(
          lamp.powerConsumptionMax,
          null,
          null,
          null,
          lamp.name,
          lamp.lampTypeId,
          lamp.lampTypeId,
          lamp.lampTypeTemplateId,
          lamp.ratedPower
        );
      });
    }

    let duplicateAddressMappingMap = {};
    if (duplicateAddressMappings && typeof duplicateAddressMappings !== 'boolean') {
      duplicateAddressMappingMap = duplicateAddressMappings;
    }
    let duplicateGroupMappingMap = {};
    if (duplicateGroupMappings && typeof duplicateGroupMappings !== 'boolean') {
      duplicateGroupMappingMap = duplicateGroupMappings;
    }

    const emDriverMap = {};
    if (Array.isArray(emDrivers)) {
      emDrivers.forEach((driver) => {
        if (!emDriverMap[driver.nodeId]) {
          emDriverMap[driver.nodeId] = [];
        }
        emDriverMap[driver.nodeId].push(EmDriver.from(driver));
      });
    }

    const luminaireDriverMap = {};
    if (Array.isArray(luminaireDrivers)) {
      luminaireDrivers.forEach((driver) => {
        driver.lampType = lampTypeMap[driver.lampTypeId];
        if (driver.lampTypeId === null) {
          driver.lampType = defaultLampType;
        }
        if (!luminaireDriverMap[driver.nodeId]) {
          luminaireDriverMap[driver.nodeId] = [];
        }
        luminaireDriverMap[driver.nodeId].push(LuminaireDriver.from(driver));
      });
    }

    let tags;
    nodes.forEach((node) => {
      if (emDriverMap != null && emDriverMap !== true && emDriverMap[node.id]) {
        node.emDrivers = emDriverMap[node.id];
      } else if (emDriverMap !== false) {
        node.emDrivers = [];
      }
      if (luminaireDriverMap != null && luminaireDriverMap !== true && luminaireDriverMap[node.id]) {
        node.luminaireDrivers = luminaireDriverMap[node.id];
      } else if (luminaireDriverMap !== false) {
        node.luminaireDrivers = [];
      }
      if (beaconMap != null && Object.keys(beaconMap).length > 0) {
        node.beaconSetting = beaconMap[node.id];
      }
      if (lightingMap != null && Object.keys(lightingMap).length > 0) {
        node.lightingConfiguration = this.lightingConfigurationService.convertTimestampsToDates(lightingMap[node.id]);
      }
      if (nodeIdToTagsMap != null && nodeIdToTagsMap !== true && nodeIdToTagsMap[node.id]) {
        tags = nodeIdToTagsMap[node.id];
        node.tags = tags;
      } else if (nodeIdToTagsMap !== false) {
        node.tags = [];
      }
      if (
        duplicateAddressMappingMap != null &&
        duplicateAddressMappingMap !== true &&
        duplicateAddressMappingMap[node.id]
      ) {
        node.duplicateAddressMappings = duplicateAddressMappingMap[node.id];
      } else if (duplicateAddressMappingMap !== false) {
        node.duplicateAddressMappings = [];
      }

      if (duplicateGroupMappingMap != null && duplicateGroupMappingMap !== true && duplicateGroupMappingMap[node.id]) {
        node.duplicateGroupMappings = duplicateGroupMappingMap[node.id];
      } else if (duplicateGroupMappingMap !== false) {
        node.duplicateGroupMappings = [];
      }
    });

    this.resolveNodes(nodes);
  }

  private getNodes(): Promise<SensorNode[]> {
    return new Promise((resolve, reject) => {
      this.floorService
        .getCurrentFloor()
        .then(
          (floor) => {
            this.currentFloorId = floor.id;
            this.nodeResource
              .retrieveAllByParentId(floor.id)
              .then((sensorNodes) => {
                resolve(sensorNodes);
              })
              .catch(() => {
                reject();
              });
          },
          (reason) => console.warn(reason)
        )
        .catch(() => {
          reject();
        });
    });
  }

  private fetchNodesById(nodeIds: number[]): Promise<SensorNode[]> {
    return this.nodeResource.retrieveNodesById(nodeIds);
  }

  private resolveNodes(nodesWithAllData: SensorNode[]): void {
    this.nodes = this.prepareNodes(nodesWithAllData);
    this.refreshNodes.next(this.nodes);
  }

  /**
   * Based on the total number of elements to be rendered we calculate the coordinates
   * of subscribers (driver/inverter/PN) around a publisher (SN3)
   * Complexity of current implementation
   * O(n): one run through all nodes to partition them into publishers and subscribers
   * O(x * y): x is number of publishers, y is number of subscribers
   * i.e. O(n) + O(x * y) for drawing SN3s and PNs on floor
   * @param {SensorNode[]} nodes
   * @returns {SensorNode[]}
   * @private
   */
  private prepareNodes(nodes: SensorNode[]): SensorNode[] {
    // Partition the nodes into publishers and subscribers
    const [mappedNodes, unmappedNodesWhichAreSubscribers] = nodes.reduce(
      (result: [SensorNode[], SensorNode[]], element) => {
        /**
         * draw the subscriber only when
         * - it is of type PN
         * - it doesn't have x and y
         */
        const isSubscriber = SensorNode.isPassiveNode(element) && (element.x == null || element.y == null);
        result[isSubscriber ? 1 : 0].push(element); // if subscriber, push result to second array, else into first array
        return result;
      },
      [[], []]
    );

    // iterate through each publisher to draw its drivers on floor
    mappedNodes.forEach((node) => {
      // A mapped node can be SN3 or a PN (publisher or a subscriber). We only want to draw PNs around SN3s
      // and not have a situation where PNs surround a PN
      const subscribersToThisNode = SensorNode.isPassiveNode(node)
        ? []
        : unmappedNodesWhichAreSubscribers.filter((sub) => sub.groupId === node.groupId);
      const currentDriverCount = 0; // keeping track of driver/inverter count, this variable gets updated in updateDriverXandY
      const currentSubscriberCount = 0; // keeping track of subscriber count, this variable gets updated in updateSubscriberXandY
      this.placeDriversAroundNode(node, currentDriverCount);
      // iterate through all subscribers to draw them around their publisher
      subscribersToThisNode.forEach(
        this.updateSubscriberXandY(currentSubscriberCount, subscribersToThisNode.length / 2, node)
      );
    });
    // draw drivers/em inverters around subscriber too
    unmappedNodesWhichAreSubscribers.forEach((subscriber) => {
      const currentDriverCount = 0; // keeping track of driver/inverter count, this variable gets updated in updateDriverXandY
      this.placeDriversAroundNode(subscriber, currentDriverCount);
    });
    return mappedNodes.concat(unmappedNodesWhichAreSubscribers);
  }

  private placeDriversAroundNode(node: SensorNode, currentDriverCount: number): void {
    const { radian1, radian2, radian3 } = this.generateRadiansForDrivers(node);
    node.emDrivers?.forEach(this.updateDriverXandY(currentDriverCount, radian1, radian2, radian3, node));
    node.luminaireDrivers?.forEach(
      this.updateDriverXandY(currentDriverCount + node.emDrivers?.length, radian1, radian2, radian3, node)
    );
  }

  /**
   * Generating 3 different angles based on the number of drivers that will be drawn
   * @param {SensorNode} node
   * @returns {{radian1: number, radian2: number, radian3: number}}
   * @private
   */
  private generateRadiansForDrivers(node: SensorNode): {
    radian1: number;
    radian2: number;
    radian3: number;
  } {
    const twoPi = 2 * Math.PI;
    let radian1 = twoPi / 8;
    let radian2 = radian1 / 2;
    let radian3 = radian2;
    const total =
      (node.emDrivers == null ? 0 : node.emDrivers.length) +
      (node.luminaireDrivers == null ? 0 : node.luminaireDrivers.length);
    // Max can support 8+16+16=40 nodes without overlap
    if (total < 8) {
      radian1 = twoPi / total;
    } else if (total < 24) {
      radian2 = twoPi / (total - 8);
    } else {
      radian3 = twoPi / (total - 24);
    }
    return {
      radian1,
      radian2,
      radian3
    };
  }

  /**
   * Render a subscriber around the passed publisher (SN3) only if the subscriber (PN) doesn't have x,y coordinates
   * i.e. the subscriber hasn't been manually mapped<br/>
   * Calculating the radian at which the PN should be drawn in clockwise fashion (-&pi;/2 phase)<br/>
   * Based on the calculation below, max number of PNs that can be drawn without overlap is 20<br/>
   * @param {number} currentCount - the current count of the subscriber being drawn
   * @param {number} factor - Higher the factor, lesser the gap between 2 consecutive subscribers. In current implementation the factor is set to total number of subscriber &divide; 2
   * @param {SensorNode} publisher - The publisher (SN3) around with the PN will be drawn
   * @private
   */
  private updateSubscriberXandY(
    currentCount: number,
    factor: number,
    publisher: SensorNode
  ): (subscriber: SensorNode) => void {
    return (subscriber) => {
      const radian = (Math.PI / factor) * currentCount - Math.PI / 2;
      subscriber.x = publisher.x + this.radiusForPN * Math.cos(radian);
      subscriber.y = publisher.y + this.radiusForPN * Math.sin(radian);
      currentCount++;
    };
  }

  private updateDriverXandY(count: number, radian1: number, radian2: number, radian3: number, node: SensorNode) {
    const piBy2 = Math.PI / 2;
    return (driver) => {
      let radius;
      let radian;
      if (count < 8) {
        radian = radian1 * count - piBy2;
        radius = this.radius1;
      } else if (count < 24) {
        radian = radian2 * (count - 8) - piBy2;
        radius = this.radius2;
      } else {
        radian = radian3 * (count - 24) - piBy2;
        radius = this.radius3;
      }

      driver.x = node.x + radius * Math.cos(radian);
      driver.y = node.y + radius * Math.sin(radian);
      count++;
    };
  }

  public getNumberOfEmergencyNodes(group: EmergencyLightGroup): Promise<number> {
    return new Promise<number>((resolve) => {
      let emergencyNodes = 0;
      this.nodes.forEach((node) => {
        if (node.tags != null && node.emDrivers != null && node.emDrivers.length > 0) {
          const inGroup = node.tags.some((tag) => {
            return ArrayUtils.contains(group.tagIds, tag.id);
          });
          if (inGroup) {
            emergencyNodes++;
          }
        }
      });
      resolve(emergencyNodes);
    });
  }

  public createFloorplanNodes(nodes: SensorNode[]): FloorplanSensorNode[] {
    return nodes?.map((node) => FloorplanSensorNode.from(node, this.hasMappingBeenAttempted[node.id.toString()]));
  }

  public populateFloorplanNodeValues(
    nodes: FloorplanSensorNode[],
    values: Pair<number, number>[],
    suffix: string,
    max: number
  ): void {
    const valueMap = MapUtils.toMap(values);
    if (nodes) {
      nodes.forEach((node) => {
        node.value = valueMap[node.address];
        node.valueSuffix = suffix;
        node.max = max;
      });
    }
  }

  public updateNodesLightLevel(nodes: SensorNode[], values: Pair<number, number>[]): void {
    const valueMap = MapUtils.toMap(values);
    nodes.forEach((node) => {
      node.lightLevel = valueMap[node.address];
    });
  }

  public updateNodesPresence(nodes: SensorNode[], values: Pair<number, number>[]): void {
    const valueMap = MapUtils.toMap(values);
    nodes.forEach((node) => {
      node.presence = valueMap[node.address];
    });
  }

  public getTestResultsMap(results: EmergencyLightingTest[]): Map<EmergencyLightingTestState> {
    return MapUtils.toMap(
      results.map((result) => new Pair<number, EmergencyLightingTestState>(result.driverId, result.state))
    );
  }

  public populateFloorNodeTestResults(nodes: FloorplanSensorNode[], results: EmergencyLightingTest[]): void {
    if (nodes == null || results == null) {
      return;
    }

    const [functionalTests, durationTests] = ArrayUtils.partition(results, (result) =>
      EmergencyLightingTestType.FUNCTION.equals(result.type)
    );
    const functionalTestResultsMap = this.getTestResultsMap(functionalTests);
    const durationTestResultsMap = this.getTestResultsMap(durationTests);
    const startedTestParentNodeIds: { [key: number]: boolean } = {};
    const noOfNotRunningTestPerParentNodeId: { [key: number]: number } = {};
    nodes.forEach((node) => {
      node.emDrivers?.forEach((driver) => {
        driver.functionalTestState = functionalTestResultsMap[driver.id];
        driver.durationTestState = durationTestResultsMap[driver.id];

        if (node.getTotalEMDriver === 1) {
          // Only 1 EM, don't worry about too many EM running
          noOfNotRunningTestPerParentNodeId[node.id] = 10;
        }
        node.isTooManyDriverEmergencyLightingTest = false;
        node.isDriverEmergencyLightingTestStarted = false;
        if (
          EmergencyLightingTestState.INITIATED.equals(driver.functionalTestState) ||
          EmergencyLightingTestState.INITIATED.equals(driver.durationTestState)
        ) {
          startedTestParentNodeIds[driver.nodeId] = true;
        }

        if (
          !EmergencyLightingTestState.IN_PROGRESS.equals(driver.functionalTestState) &&
          !EmergencyLightingTestState.IN_PROGRESS.equals(driver.durationTestState)
        ) {
          if (
            noOfNotRunningTestPerParentNodeId[driver.nodeId] == null ||
            noOfNotRunningTestPerParentNodeId[driver.nodeId] <= 0
          ) {
            noOfNotRunningTestPerParentNodeId[driver.nodeId] = 1;
          } else {
            noOfNotRunningTestPerParentNodeId[driver.nodeId] += 1;
          }
        }

        if (startedTestParentNodeIds[driver.nodeId] != null && startedTestParentNodeIds[driver.nodeId]) {
          node.isDriverEmergencyLightingTestStarted = true;
        }
        if (
          noOfNotRunningTestPerParentNodeId[driver.nodeId] != null &&
          noOfNotRunningTestPerParentNodeId[driver.nodeId] <= 1
        ) {
          node.isTooManyDriverEmergencyLightingTest = true;
        }
      });
    });
  }

  public getMaximumValue(nodes: FloorplanSensorNode[]): number {
    let maximum = 0;
    for (const element of nodes) {
      if (element.value != null) {
        maximum = Math.max(element.value, maximum);
      }
    }
    return maximum;
  }

  public getMaxScale(nodes: FloorplanSensorNode[]): number {
    return nodes[0].max ? nodes[0].max : 100;
  }

  public getNodesById(nodeIds, nodes): SensorNode[] {
    const selection = [];
    for (let nodeIdx = 0; nodeIdx < nodes.length; nodeIdx += 1) {
      for (let nodeIdIdx = 0; nodeIdIdx < nodeIds.length; nodeIdIdx += 1) {
        if (nodes[nodeIdx].id === nodeIds[nodeIdIdx]) {
          selection.push(nodes[nodeIdx]);
        }
      }
    }
    return selection;
  }

  public createTemporaryNode(nodeDetails?): SensorNode {
    const x = nodeDetails && angular.isDefined(nodeDetails.x) ? nodeDetails.x : null;
    const y = nodeDetails && angular.isDefined(nodeDetails.y) ? nodeDetails.y : null;
    return new SensorNode(null, x, y);
  }

  public removeNodeFromSelection(nodeId): void {
    this.selectedNodes.change((selection) => {
      selection.splice(this.getNodeIndex(nodeId, selection), 1);
    });
  }

  public addNodeToSelection(node: FloorplanSensorNode, isEmergencyLightingTestModeActive): void {
    this.selectedNodes.change((selection) => {
      selection.push(node);
    });
  }

  public removeEmDriverFromSelection(driverId: number): void {
    this.selectedEmDrivers.change((selection) => {
      selection.splice(this.getEmDriverIndex(driverId, selection), 1);
    });
  }

  public addEmDriverToSelection(emDriver: EmDriver): void {
    this.selectedEmDrivers.change((selection) => {
      selection.push(emDriver);
    });
  }

  public getGatewayToSensorNodesMap(): Promise<Map<SensorNode[]>> {
    if (this.gatewayToNodesMapPromise == null) {
      this.gatewayToNodesMapPromise = new Promise((resolve, reject) => {
        if (!this.nodes) {
          reject();
        }
        const gatewayToNodesMap: Map<SensorNode[]> = {};
        for (const node of this.nodes) {
          // TODO only place where this is used is for a Gateway Colour wheel which I don't know if its still used
          // if (node.gatewayAddress) {
          //     if (gatewayToNodesMap[node.gatewayAddress]) {
          //         gatewayToNodesMap[node.gatewayAddress].push(node);
          //     } else {
          //         const n: SensorNode[] = [];
          //         n.push(node);
          //         gatewayToNodesMap[node.gatewayAddress] = n;
          //     }
          // }
        }
        resolve(gatewayToNodesMap);
      });
    }
    return this.gatewayToNodesMapPromise;
  }

  public getNodeIds(nodes): number[] {
    const nodeIds = [];
    for (let idx = 0, len = nodes.length; idx < len; idx += 1) {
      nodeIds.push(nodes[idx].id);
    }
    return nodeIds;
  }

  public clearSelection(): void {
    this.selectedNodes.change((selection) => {
      selection.length = 0;
    });
    this.selectedEmDrivers.change((selection) => {
      selection.length = 0;
    });
    this.isSelectionModeActive = false;
  }

  public updateSelection(selected: ISelectable, isEmergencyLightingTestModeActive): void {
    if (selected instanceof FloorplanSensorNode) {
      if (this.isNodeSelected(selected.id)) {
        this.removeNodeFromSelection(selected.id);
      } else {
        this.addNodeToSelection(selected, isEmergencyLightingTestModeActive);
      }
    } else if (selected instanceof EmDriver) {
      if (this.isEmDriverSelected(selected.id)) {
        this.removeEmDriverFromSelection(selected.id);
      } else {
        this.addEmDriverToSelection(selected);
      }
    }
  }

  public getNodesInArea(nodes, area): any[] {
    const nodesInArea = [];
    let currentNode;
    let isInBoundsX;
    let isInBoundsY;
    for (let idx = 0, len = nodes.length; idx < len; idx += 1) {
      currentNode = nodes[idx];
      isInBoundsX = currentNode.x >= area.left && currentNode.x <= area.left + area.width;
      isInBoundsY = currentNode.y >= area.top && currentNode.y <= area.top + area.height;
      if (isInBoundsX && isInBoundsY) {
        currentNode.isSelected = this.isNodeSelected(currentNode.id);
        nodesInArea.push(currentNode);
      }
    }
    return nodesInArea;
  }

  public getSelectedNodesInArea(nodes, area): SensorNode[] {
    const nodesInArea = this.getNodesInArea(nodes, area);
    const selectedNodesInArea = [];
    for (let idx = 0, len = nodesInArea.length; idx < len; idx += 1) {
      if (nodesInArea[idx].isSelected) {
        selectedNodesInArea.push(nodesInArea[idx]);
      }
    }
    return selectedNodesInArea;
  }

  public selectNodesInArea(nodes, area, isEmergencyLightingTestModeActive, onlyEmergencyNodes?): void {
    const nodesInArea = this.getNodesInArea(nodes, area);
    for (let idx = 0, len = nodesInArea.length; idx < len; idx += 1) {
      if (!nodesInArea[idx].isSelected) {
        if (onlyEmergencyNodes) {
          if (nodesInArea[idx].hasEmergencyGear) {
            this.addNodeToSelection(nodesInArea[idx], isEmergencyLightingTestModeActive);
          }
        } else {
          this.addNodeToSelection(nodesInArea[idx], isEmergencyLightingTestModeActive);
        }
      }
    }
  }

  public getEmDriversInArea(emDrivers, area): any[] {
    const driversInArea = [];
    let currentDriver;
    let isInBoundsX;
    let isInBoundsY;
    for (let idx = 0, len = emDrivers.length; idx < len; idx += 1) {
      currentDriver = emDrivers[idx];
      isInBoundsX = currentDriver.x >= area.left && currentDriver.x <= area.left + area.width;
      isInBoundsY = currentDriver.y >= area.top && currentDriver.y <= area.top + area.height;
      if (isInBoundsX && isInBoundsY) {
        currentDriver.isSelected = this.isEmDriverSelected(currentDriver.id);
        driversInArea.push(currentDriver);
      }
    }
    return driversInArea;
  }

  public getSelectedEmDriversInArea(emDrivers, area): SensorNode[] {
    const emDriversInArea = this.getEmDriversInArea(emDrivers, area);
    const selectedEmDriversInArea = [];
    for (let idx = 0, len = emDriversInArea.length; idx < len; idx += 1) {
      if (emDriversInArea[idx].isSelected) {
        selectedEmDriversInArea.push(emDriversInArea[idx]);
      }
    }
    return selectedEmDriversInArea;
  }

  public selectEmDriversInArea(emDrivers, area, isEmergencyLightingTestModeActive, onlyEmergencyNodes?): void {
    const emDriversInArea = this.getEmDriversInArea(emDrivers, area);
    for (let idx = 0, len = emDriversInArea.length; idx < len; idx += 1) {
      if (!emDriversInArea[idx].isSelected) {
        if (onlyEmergencyNodes) {
          this.addEmDriverToSelection(emDriversInArea[idx]);
        } else {
          this.addNodeToSelection(emDriversInArea[idx], isEmergencyLightingTestModeActive);
        }
      }
    }
  }

  public unselectNodesInArea(nodes, area): void {
    const nodesInArea = this.getNodesInArea(nodes, area);
    for (let idx = 0, len = nodesInArea.length; idx < len; idx += 1) {
      if (nodesInArea[idx].isSelected) {
        this.removeNodeFromSelection(nodesInArea[idx].id);
      }
    }
  }

  public unselectEmDriversInArea(emDrivers, area): void {
    const emDriversInArea = this.getEmDriversInArea(emDrivers, area);
    for (let idx = 0, len = emDriversInArea.length; idx < len; idx += 1) {
      if (emDriversInArea[idx].isSelected) {
        this.removeEmDriverFromSelection(emDriversInArea[idx].id);
      }
    }
  }

  public isNodeSelected(nodeId): boolean {
    for (let idx = 0, len = this.selectedNodes.value().length; idx < len; idx += 1) {
      if (this.selectedNodes.value()[idx].id === nodeId) {
        return true;
      }
    }
    return false;
  }

  public isEmDriverSelected(driverId): boolean {
    let isSelected = false;
    for (let idx = 0, len = this.selectedEmDrivers.value().length; idx < len; idx += 1) {
      if (this.selectedEmDrivers.value()[idx].id === driverId) {
        isSelected = true;
      }
    }

    return isSelected;
  }

  public getNodeIndex(nodeId, nodes?): number {
    for (let idx = 0, len = nodes.length; idx < len; idx += 1) {
      if (nodes[idx].id === nodeId) {
        return idx;
      }
    }
    return -1;
  }

  public getEmDriverIndex(driverId, emDrivers?): number {
    for (let idx = 0, len = emDrivers.length; idx < len; idx += 1) {
      if (emDrivers[idx].id === driverId) {
        return idx;
      }
    }
    return -1;
  }

  public saveNodes(nodes: SensorNode[]): Promise<void> {
    return this.floorService.getCurrentFloor().then(
      (floor) => {
        return this.floorResource.addNodes(floor.id, nodes).then(() => this.requestNodes.next(true));
      },
      (reason) => console.warn(reason)
    );
  }

  public updateNode(node: SensorNode): Promise<any> {
    return this.nodeResource.updateCurrent(node).then();
  }

  public invalidateNodesForCurrentFloor(): void {
    this.floorService.invalidateCurrentFloor();
  }

  public deleteNodes(nodes: SensorNode[]): Promise<void> {
    if (
      !nodes.length ||
      !confirm(
        'Selected nodes and its drivers will be permanently deleted. Note: This will happen in the background, and may take some time!'
      )
    ) {
      return Promise.reject('USER_CANCELLED_DELETE');
    }

    const nodeIds = this.getNodeIds(nodes);
    return new Promise((resolve, reject) => {
      this.nodeResource
        .deleteMany(nodeIds)
        .then(() => {
          this.requestNodes.next(true);
          resolve();
        })
        .catch(() => {
          alert('Could not delete selected nodes.');
          reject();
        });
    });
  }

  public selectAll(onlyEmergencyNodes?: boolean): Promise<any> {
    return new Promise((resolve) => {
      let nodesToBeSelected = this.floorplanNodes;
      if (onlyEmergencyNodes) {
        const emergencyNodesToBeSelected = [];

        this.floorplanNodes
          .filter((node) => node.emDrivers != null && node.emDrivers.length > 0)
          .forEach((node) => {
            if (!emergencyNodesToBeSelected.map((n) => n.id).includes(node.id)) {
              emergencyNodesToBeSelected.push(node);
            }
          });
        nodesToBeSelected = emergencyNodesToBeSelected;
      }
      this.selectedNodes.change((selection) => {
        nodesToBeSelected.forEach((node) => {
          ArrayUtils.add(selection, node, (node1: SensorNode, node2: SensorNode) => {
            return node1.id === node2.id;
          });
        });
      });
      resolve(null);
    });
  }

  public startEmergencyLightingTest(
    buildingId: number,
    emDriver: EmDriver,
    type: EmergencyLightingTestType
  ): Promise<{}> {
    return this.emergencyLightingResource.startTest(buildingId, emDriver.id, type);
  }

  public startEmergencyLightingTestBatch(
    buildingId: number,
    driverIds: number[],
    type: EmergencyLightingTestType
  ): Promise<{}> {
    return this.emergencyLightingResource.startBatchTest(buildingId, driverIds, type);
  }

  public getEmergencyLightingTestResults(showArchived: boolean): Promise<EmergencyLightingTest[]> {
    return new Promise((resolve) => {
      this.floorService.getCurrentFloor().then(
        (floor) => {
          this.emergencyLightingTestResultResource.getLatestResultsByFloor(floor.id, showArchived).then((results) => {
            this.emergencyResults = results;
            resolve(results);
          });
        },
        (reason) => console.warn(reason)
      );
    });
  }

  public cancelLatestResults(): void {
    this.emergencyLightingTestResultResource.cancelLatestResults();
  }

  public cancelEmergencyLightingTest(buildingId: number, emDriver: EmDriver): Promise<{}> {
    return this.emergencyLightingResource.cancelTest(buildingId, emDriver.id);
  }

  public cancelEmergencyLightingTestBatch(buildingId: number, driverIds: number[]): Promise<{}> {
    return this.emergencyLightingResource.cancelBatchTest(buildingId, driverIds);
  }

  public getSensorNodesForFloor(floorId: number): Promise<SensorNode[]> {
    return this.nodeResource.retrieveAllByParentId(floorId);
  }

  public clearDriversForNodes(nodes: SensorNode[]): Promise<number> {
    const nodeIds = this.getNodeIds(nodes);
    return this.nodeResource.clearDriversForNodes(nodeIds);
  }

  public registerToListedNodes(node: FloorplanSensorNode): void {
    this.registeredListedNodes.push(node);
  }

  public getRegisteredListedNode(id: number): FloorplanSensorNode {
    const node = this.registeredListedNodes.filter((e) => e.id === id)[0];
    if (!node) {
      alert('A node with id ' + id + ' is not among the list nodes');
      return;
    }
    return node;
  }

  public registerToFloorplanNodes(node: FloorplanSensorNode): void {
    this.registeredFloorplanNodes.push(node);
  }

  public getRegisteredFloorplanNode(id: number): FloorplanSensorNode {
    const node = this.registeredFloorplanNodes.filter((e) => e.id === id)[0];
    if (!node) {
      alert('A node with id ' + id + ' is not among the plan nodes');
      return;
    }
    return node;
  }

  public updateLampTypes(lampType: SimpleLampType): void {
    if (this.nodes) {
      lampType.sensorNodes.forEach((node) => {
        this.nodes
          .filter((sn) => sn.id === node)
          .forEach((sn) => {
            sn.luminaireDrivers.forEach((driver) => {
              driver.lampTypeId = lampType.id;
              driver.lampType = lampType;
            });
          });
      });
    }
  }

  public get isPostMappingInProgress(): boolean {
    return this.updatingDuplicateMappingInProgress || this.updatingMappedNodeInProgress;
  }

  public enableBleScanning(needsNodes: boolean): Promise<{ message: string }> {
    return new Promise((resolve) => {
      this.buildingService.getCurrentBuilding().then((building) => {
        const nodeIds = this.getConnectedSN3Ids();
        if (needsNodes && nodeIds.length === 0) {
          resolve({
            message: this.bleErrorMessage
          });
        } else {
          this.sensorNodeBleResource
            .enableBle(building.id, nodeIds)
            .then(() => resolve({ message: 'BLE scanning enabled on all compatible selected devices' }));
        }
      });
    });
  }

  public disableBleScanning(needsNodes: boolean): Promise<{ message: string }> {
    return new Promise((resolve) => {
      this.buildingService.getCurrentBuilding().then((building) => {
        const nodeIds = this.getConnectedSN3Ids();
        if (needsNodes && nodeIds.length === 0) {
          resolve({
            message: this.bleErrorMessage
          });
        } else {
          this.sensorNodeBleResource
            .disableBle(building.id, nodeIds)
            .then(() => resolve({ message: 'BLE scanning disabled on all compatible selected devices' }));
        }
      });
    });
  }

  public queryBleScanning(needsNodes: boolean): Promise<{ message: string }> {
    return new Promise((resolve) => {
      this.buildingService.getCurrentBuilding().then((building) => {
        const nodeIds = this.getConnectedSN3Ids();
        if (needsNodes && nodeIds.length === 0) {
          resolve({
            message: this.bleErrorMessage
          });
        } else {
          this.sensorNodeBleResource.queryeBle(building.id, nodeIds).then(() => {
            resolve({ message: 'BLE scanning query sent to all compatible selected devices' });
            window.setTimeout(() => this.requestNodes.next(true), 100);
          });
        }
      });
    });
  }

  private getConnectedSN3Ids(): number[] {
    return this.selectedNodes
      .value()
      .filter((node) => node.connected)
      .filter(
        (node) => DISCRIMINATOR.SN3 === node.nodeType || DISCRIMINATOR.HIM84 === node.nodeType || node.nodeType == null
      )
      .map((node) => node.id);
  }
}
