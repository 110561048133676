import { OrFormUserInfoController } from './OrFormUserInfoController';

export class OrFormUserInfoDirective {
  constructor() {
    return {
      bindToController: true,
      controller: [
        '$scope',
        'ThumbnailUrlGenerator',
        'ImageCachingService',
        'BuildingService',
        OrFormUserInfoController
      ],
      controllerAs: 'userInfo',
      replace: true,
      restrict: 'E',
      scope: {
        parent: '=',
        details: '=',
        onSubmit: '=',
        onReset: '&'
      },
      template: require('raw-loader!./or-form-user-info.html').default
    };
  }
}
