@if(lampTypeForm && areNodesSelected && templates) {
  <div class='flex flex-row mt-6'>
    <form class='w-full' [formGroup]='lampTypeForm' data-cy='lamp-type-form'>
      <h1 class='text-xl font-medium mb-2'>Lamp Type</h1>
      @if (areLampTypesSelected && !isSingleTypeSelected) {
        <mat-error data-cy='multiple-lamp-type-error'>
          <span class="or-icon or-tiny or-icon-alert"></span>
          {{'The current selection contains multiple lamp types'}}
        </mat-error>
      }
      @if (templates.length === 0) {
        <mat-error data-cy='no-templates-error'>
          <span class="or-icon or-tiny or-icon-alert"></span>
          {{'There are no lamp types currently associated with this building.'}}
        </mat-error>
      }
      <mat-form-field class='w-full'>
        <mat-label data-cy='lamp-type-label'>Lamp Type</mat-label>
        <mat-select data-cy='lamp-type-template-select' formControlName="lampTypeTemplate">
          <mat-option data-cy='lamp-type-0' selected disabled>--- Choose lamp type ---</mat-option>
          @for (template of templates; track template.name) {
            <mat-option [attr.data-cy]="'lamp-type-' + template.id" [value]='template.id'>{{template.name}}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      @if (lampTypeForm.value?.lampTypeTemplate) {
        <mat-form-field class='w-full'>
          <mat-label data-cy='map-power-label'>Max Power(W)</mat-label>
          <input data-cy='max-power-input' matInput formControlName="powerConsumptionMax" type="number"/>
        </mat-form-field>
      }
      <button data-cy='lamp-type-submit' mat-raised-button class="w-full" color='primary' type="submit" (click)='submit()' >
        <span class="or-icon or-small or-inverse or-icon-checkmark"></span>
        <span class="or-button-label" data-cy='building-form-submit-label'>Save</span>
      </button>
    </form>
  </div>
}

