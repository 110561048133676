import * as angular from 'angular'; // Automatically added
import { FloorplanSensorNode } from '@angularjs/or/api/building/FloorplanSensorNode';

export class OrMappedNodesCounterController {
  public nodes: FloorplanSensorNode[];

  constructor() {}

  isEverythingMapped(): boolean {
    const unmappedNodes = this.getUnmappedNodesCount();
    const duplicates = this.getDuplicateNodesCount();
    return unmappedNodes === 0 && duplicates === 0;
  }

  getMappedNodesCount(): number {
    const mappedNodes = this.getMappedNodes();
    return mappedNodes.length;
  }

  getDuplicateNodesCount(): number {
    return this.getDuplicateNodes().length;
  }

  getTotalNodesCount(): number {
    const totalNodes = this.getTotalNodes();
    return totalNodes.length;
  }

  getUnmappedNodesCount(): number {
    const mappedNodesCount = this.getMappedNodesCount();
    const totalNodesCount = this.getTotalNodesCount();
    return totalNodesCount - mappedNodesCount;
  }

  getTotalNodes(): FloorplanSensorNode[] {
    return angular.isArray(this.nodes) ? this.nodes : [];
  }

  getMappedNodesWithoutDriversCount(): number {
    return this.getMappedNodesWithoutDrivers().length;
  }

  getNodesWithoutDriversCount(): number {
    return this.getNodesWithoutDrivers().length;
  }

  getNodesWithoutDrivers(): FloorplanSensorNode[] {
    return this.getTotalNodes();
  }

  getMappedNodes(): FloorplanSensorNode[] {
    return this.getTotalNodes().filter((node) => {
      return node.properlyMapped;
    });
  }

  getMappedNodesWithoutDrivers(): FloorplanSensorNode[] {
    return this.getNodesWithoutDrivers().filter((node) => {
      return node.properlyMapped;
    });
  }

  getDuplicateNodes(): FloorplanSensorNode[] {
    const nodes = this.getTotalNodes();
    return nodes.filter((node) => {
      return node.duplicateAddressMappings?.length > 0;
    });
  }

  getUnmappedNodes(): FloorplanSensorNode[] {
    const nodes = this.getTotalNodes();
    return nodes.filter((node) => {
      return !node.properlyMapped;
    });
  }
}
