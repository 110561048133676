import { ISimpleService } from '../api/ISimpleService';
import * as angular from 'angular';

export class PropertiesService {
  private applicationConfig: Promise<string[]> = null;

  constructor(
    private simpleService: ISimpleService<string, any, any, any>,
    private envBaseUrl: string
  ) {}

  public getProperty(property: string): Promise<string> {
    return this.getCachedProperties().then((applicationConfig) => {
      if (applicationConfig.hasOwnProperty(property)) {
        return Promise.resolve(applicationConfig[property]);
      } else {
        console.error('No such property: ' + property);
      }
    });
  }

  public getCachedProperties(): Promise<string[]> {
    if (this.applicationConfig == null) {
      this.applicationConfig = this.loadProperties().then((properties) => {
        const props = angular.fromJson(properties);
        return Promise.resolve(props);
      });
    }
    return this.applicationConfig;
  }

  public loadProperties(): Promise<string> {
    return this.simpleService.get(this.envBaseUrl);
  }
}
