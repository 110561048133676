import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ConfirmComponent, DialogType, MatDialogData } from '@components/dialogs/confirm/confirm.component';
import { InfoDialogComponent } from '@app/shared/components/dialogs/info-dialog/info-dialog.component';
import { ElmtReportsOptionsDialogComponent } from '@app/emergency-lighting/reports/elmt-reports-options-dialog/elmt-reports-options-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationDialogService {
  constructor(private dialog: MatDialog) {}

  open(data: MatDialogData, newConfig?: MatDialogConfig): Observable<any> {
    let config = new MatDialogConfig();
    if (newConfig) {
      config = newConfig;
    } else {
      config.autoFocus = true;
      config.data = data;
      config.width = '600px';
      config.panelClass = 'no-overflow-dialog';
    }

    const dialogRef = this.dialog.open(ConfirmComponent, config);
    return dialogRef.afterClosed();
  }

  emDialogOpen(data: any, newConfig?: MatDialogConfig): MatDialogRef<ElmtReportsOptionsDialogComponent> {
    let config = new MatDialogConfig();
    if (newConfig) {
      config = newConfig;
    } else {
      config.autoFocus = true;
      config.data = data;
      config.width = '600px';
      config.panelClass = 'no-overflow-dialog';
    }

    return this.dialog.open(ElmtReportsOptionsDialogComponent, config);
  }

  info(data: MatDialogData, newConfig?: MatDialogConfig): Observable<any> {
    data.type = DialogType.INFO;
    data.title = data?.title || 'Info';
    const config = this.getDialogConfig(data, newConfig);
    const dialogRef = this.dialog.open(InfoDialogComponent, config);
    return dialogRef.afterClosed();
  }

  warn(data: MatDialogData, newConfig?: MatDialogConfig): Observable<any> {
    data.type = DialogType.WARN;
    data.title = data?.title || 'Warning';
    const config = this.getDialogConfig(data, newConfig);
    const dialogRef = this.dialog.open(InfoDialogComponent, config);
    return dialogRef.afterClosed();
  }

  error(data: MatDialogData, newConfig?: MatDialogConfig): Observable<any> {
    data.type = DialogType.ERROR;
    data.title = data?.title || 'Error';
    const config = this.getDialogConfig(data, newConfig);
    const dialogRef = this.dialog.open(InfoDialogComponent, config);
    return dialogRef.afterClosed();
  }

  getDialogConfig(data: MatDialogData, newConfig?: MatDialogConfig): MatDialogConfig {
    return newConfig
      ? newConfig
      : {
          autoFocus: true,
          data,
          width: '600px',
          panelClass: 'no-overflow-dialog',
          disableClose: true
        };
  }
}
