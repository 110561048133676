export class BeaconSetting {
  public static uuids = [
    {
      name: 'Organic Response UUID',
      value: 'A7DEE2A8-8067-4B2B-ABC0-B76D98BD62E5'
    },
    { name: 'Sony Nimway UUID', value: '1673AABF-A422-4C4D-B1F5-A07C44031566' }
  ];
  public static contents = [
    { name: 'Apple/iBeacon', value: 76 },
    { name: 'OR Technologies', value: 1863 }
  ];
  public static intervals = [
    { name: 'Slow', value: 1000 },
    { name: 'Medium', value: 500 },
    { name: 'Fast', value: 100 }
  ];
  public sensorNodeId: number;
  public enabled: boolean;
  public powerLevel: number;
  public content: number;
  public beaconInterval: number;
  public updateStatus: string;
  public uuid: string;
  public major: number;
  public minor: number;

  constructor(
    sensorNodeId: number,
    enabled: boolean,
    powerLevel: number,
    content: number,
    beaconInterval: number,
    updateStatus: string,
    uuid: string,
    major: number,
    minor: number
  ) {
    this.sensorNodeId = sensorNodeId;
    this.enabled = enabled;
    this.powerLevel = powerLevel;
    this.content = content;
    this.beaconInterval = beaconInterval;
    this.updateStatus = updateStatus;
    this.uuid = uuid;
    this.major = major;
    this.minor = minor;
  }
}
