import * as angular from 'angular';

class CacheEntry<K, V> {
  constructor(public key: K, public value: V, public time: number) {}
}

export class Cache<K, V> {
  private cache: CacheEntry<K, V>[] = [];

  constructor(private cached: (key: K) => V, private ttl: number = 0) {}

  getValue(key: K, now: number = new Date().getTime()): V {
    if (this.ttl) {
      const expiry = now - this.ttl;
      this.cache = this.cache.filter((entry) => entry.time > expiry);
    }

    const cacheHit = this.cache.filter((entry) =>
      angular.equals(entry.key, key)
    );
    if (cacheHit.length) {
      return cacheHit[0].value;
    } else {
      const result = this.cached(key);
      this.cache.push(new CacheEntry(angular.copy(key), result, now));
      return result;
    }
  }

  clear() {
    this.cache = [];
  }
}
