import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment, environmentToken } from '@environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  private readonly url: string;

  constructor(private readonly http: HttpClient, @Inject(environmentToken) private readonly environment: Environment) {
    this.url = `${environment.apiUrl + environment.apiPath}/image`;
  }

  public uploadImage(imageFile: File, buildingId?: number): Observable<string> {
    const formData = new FormData();
    const fileName = imageFile.name.replace(/[^\w._-]/g, '-');
    formData.append('image', imageFile, fileName);
    if (buildingId) {
      formData.append('buildingId', buildingId.toString());
    }
    return this.http
      .post<{ key: string }>(this.url, formData, { withCredentials: true })
      .pipe(map((response) => response.key));
  }

  public getUploadedImageUrl(imageName: string): string {
    return `${this.url}/${imageName}`;
  }
}
