import { CheckboxState } from '@angularjs/or/api/building/Tag';
import { SensorNodeDTO } from '@app/shared/models/sensor-node';

export interface ITenant {
  buildingId: number;
  description: string;
  id: number;
  name: string;
  keyContact?: number;
  pictureUrl?: string;
  sensorNodes?: SensorNodeDTO[];
  tagIds?: number[];
}

export class Tenant implements ITenant {
  buildingId: number;
  description: string;
  id: number;
  name: string;
  sensorNodes: SensorNodeDTO[];
  tagIds?: number[];
  checked: CheckboxState;
  constructor(name: string, buildingId: number, sensorNodes: SensorNodeDTO[], description = '') {
    this.buildingId = buildingId;
    this.name = name;
    this.description = description;
    this.sensorNodes = sensorNodes;
  }
}
