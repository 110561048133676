import { DdlTag } from '../../api/building/Ddl';
import { ISimpleService } from '../../api/ISimpleService';

export class DdlTagService {
  private endpoints = {
    building: '/api/v1/building',
    tag: '/api/v1/tag/building'
  };

  constructor(
    private simpleService: ISimpleService<DdlTag[], void, void, void>,
    private generateApiUrl: (endpoint: string) => string
  ) {}

  public getDdlTags(buildingId: number): Promise<DdlTag[]> {
    return this.simpleService.get(
      this.generateApiUrl(`${this.endpoints.tag}/${buildingId}`)
    );
  }
}
