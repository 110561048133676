export class DimLevel {
  constructor(private value: number, public label: DimLevel_) {
    this.value = value;
  }

  public static PERCENT_0 = new DimLevel(0, '0%');
  public static PERCENT_10 = new DimLevel(10, '10%');
  public static PERCENT_20 = new DimLevel(20, '20%');
  public static PERCENT_30 = new DimLevel(32, '30%');
  public static PERCENT_40 = new DimLevel(40, '40%');
  public static PERCENT_50 = new DimLevel(50, '50%');
  public static PERCENT_60 = new DimLevel(60, '60%');
  public static PERCENT_70 = new DimLevel(70, '70%');
  public static PERCENT_80 = new DimLevel(80, '80%');
  public static PERCENT_90 = new DimLevel(90, '90%');
  public static PERCENT_100 = new DimLevel(100, '100%');

  public static all() {
    return [
      DimLevel.PERCENT_0,
      DimLevel.PERCENT_10,
      DimLevel.PERCENT_20,
      DimLevel.PERCENT_30,
      DimLevel.PERCENT_40,
      DimLevel.PERCENT_50,
      DimLevel.PERCENT_60,
      DimLevel.PERCENT_70,
      DimLevel.PERCENT_80,
      DimLevel.PERCENT_90,
      DimLevel.PERCENT_100
    ];
  }

  public static fromValue(value: number): DimLevel {
    for (const dimLevel of DimLevel.all()) {
      if (value === dimLevel.value) return dimLevel;
    }
  }

  public toString(): string {
    return this.label;
  }
}

type DimLevel_ =
  | '0%'
  | '10%'
  | '20%'
  | '30%'
  | '40%'
  | '50%'
  | '60%'
  | '70%'
  | '80%'
  | '90%'
  | '100%';
