<div id="orHeader">
  <span data-cy='or-portal-logo' class="or-portal-logo" [routerLink]="['/buildings']"></span>
  @if (siteMenuActive$ | async) {
    <app-site-menu [user]="user$ | async" [building]='building$ | async' [menuItems]="menuItems"></app-site-menu>
  }
  @if (userMenuActive$ | async) {
    <app-user-menu [user$]="user$"
                   [floorsMenuActive$]="floorsMenuActive$"
                   [buildingsMenuActive$]="buildingsMenuActive$"
                   [sessionMenuActive$]="sessionMenuActive$">
    </app-user-menu>
  }
</div>
