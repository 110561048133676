import { Injectable } from '@angular/core';
import { WidgetService } from '@services/widget.service';
import { WIDGET_TEXT, WidgetItem } from '@app/dashboard/model/widget-item';
import { format } from 'date-fns';
import { TimeUtils } from '../utils/time.utils';

const DATE_FORMAT = 'do LLLL yyyy';
@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  // maintain a widget id to keep track of the widgets
  private widgetId = -1;

  constructor(private widgetService: WidgetService) {}

  resetDashboard(): void {
    this.widgetId = -1;
  }

  getWidgetId(): number {
    return ++this.widgetId;
  }

  getDashboardForGeneral(): Array<WidgetItem> {
    return [
      {
        id: this.getWidgetId(),
        x: 2,
        y: 3,
        cols: 2,
        rows: 3,
        type: 'chart',
        chartType: 'pie',
        title: WIDGET_TEXT.LEAST_OCCUPIED_TAGS.title,
        helpText: WIDGET_TEXT.LEAST_OCCUPIED_TAGS.helpText,
        dataCy: WIDGET_TEXT.LEAST_OCCUPIED_TAGS.dataCy,
        getData: () => this.widgetService.getTagsOccupancy('LEAST')
      },
      {
        id: this.getWidgetId(),
        x: 2,
        y: 0,
        cols: 3,
        rows: 3,
        type: 'chart',
        chartType: 'bar',
        title: WIDGET_TEXT.AVERAGE_ENERGY_CONSUMPTION.title,
        helpText: WIDGET_TEXT.AVERAGE_ENERGY_CONSUMPTION.helpText,
        dataCy: WIDGET_TEXT.AVERAGE_ENERGY_CONSUMPTION.dataCy,
        getData: () => this.widgetService.getEnergyConsumptionForBuilding('bar')
      },
      {
        id: this.getWidgetId(),
        x: 4,
        y: 3,
        cols: 1,
        rows: 3,
        type: 'count',
        iconClass: 'or-icon-co2-produced2',
        title: WIDGET_TEXT.CO2_PRODUCED.title,
        helpText: WIDGET_TEXT.CO2_PRODUCED.helpText,
        dataCy: WIDGET_TEXT.CO2_PRODUCED.dataCy,
        getData: () => this.widgetService.getCo2Produced()
      },
      {
        id: this.getWidgetId(),
        x: 0,
        y: 3,
        cols: 2,
        rows: 3,
        type: 'chart',
        chartType: 'pie',
        title: WIDGET_TEXT.MOST_OCCUPIED_TAGS.title,
        helpText: WIDGET_TEXT.MOST_OCCUPIED_TAGS.helpText,
        dataCy: WIDGET_TEXT.MOST_OCCUPIED_TAGS.dataCy,
        getData: () => this.widgetService.getTagsOccupancy('MOST')
      },
      {
        id: this.getWidgetId(),
        x: 1,
        y: 0,
        cols: 1,
        rows: 3,
        type: 'count',
        iconClass: 'or-icon-energy-consumption2',
        title: WIDGET_TEXT.CURRENT_ENERGY_CONSUMPTION.title,
        helpText: WIDGET_TEXT.CURRENT_ENERGY_CONSUMPTION.helpText,
        dataCy: WIDGET_TEXT.CURRENT_ENERGY_CONSUMPTION.dataCy,
        getData: () => this.widgetService.getCurrentEnergyConsumption()
      },
      {
        id: this.getWidgetId(),
        x: 0,
        y: 0,
        cols: 1,
        rows: 3,
        type: 'count',
        iconClass: 'or-icon-energy-saving',
        title: WIDGET_TEXT.ENERGY_SAVING_GENERAL.title,
        helpText: WIDGET_TEXT.ENERGY_SAVING_GENERAL.helpText,
        dataCy: WIDGET_TEXT.ENERGY_SAVING_GENERAL.dataCy,
        getData: () => this.widgetService.getEnergySaved(false)
      }
    ];
  }

  getDashboardForEnergy(): Array<WidgetItem> {
    return [
      {
        id: this.getWidgetId(),
        x: 0,
        y: 2,
        cols: 2,
        rows: 2,
        type: 'chart',
        chartType: 'pie',
        title: WIDGET_TEXT.ENERGY_CONSUMPTION_BY_TAGS.title,
        helpText: WIDGET_TEXT.ENERGY_CONSUMPTION_BY_TAGS.helpText,
        dataCy: WIDGET_TEXT.ENERGY_CONSUMPTION_BY_TAGS.dataCy,
        getData: () => this.widgetService.getEnergyConsumptionForBuilding('pie')
      },
      {
        id: this.getWidgetId(),
        x: 2,
        y: 0,
        cols: 3,
        rows: 2,
        type: 'chart',
        chartType: 'bar-vertical-grouped',
        title: WIDGET_TEXT.TOTAL_ENERGY_CONSUMPTION.title,
        helpText: WIDGET_TEXT.TOTAL_ENERGY_CONSUMPTION.helpText,
        dataCy: WIDGET_TEXT.TOTAL_ENERGY_CONSUMPTION.dataCy,
        getData: () => this.widgetService.getTotalEnergyConsumption()
      },
      {
        id: this.getWidgetId(),
        x: 4,
        y: 2,
        cols: 1,
        rows: 2,
        type: 'count',
        iconClass: 'or-icon-energy-intensity',
        title: WIDGET_TEXT.ENERGY_INTENSITY.title,
        helpText: WIDGET_TEXT.ENERGY_INTENSITY.helpText,
        dataCy: WIDGET_TEXT.ENERGY_INTENSITY.dataCy,
        getData: () => this.widgetService.getEnergyIntensity()
      },
      {
        id: this.getWidgetId(),
        x: 1,
        y: 0,
        cols: 1,
        rows: 2,
        type: 'count',
        iconClass: 'or-icon-estimated-yearly-energy',
        title: 'Estimated Yearly Energy',
        helpText: WIDGET_TEXT.ENERGY_ESTIMATION.helpText,
        dataCy: WIDGET_TEXT.ENERGY_ESTIMATION.dataCy,
        getData: () => this.widgetService.getEnergyYearlyEstimation()
      },
      {
        id: this.getWidgetId(),
        x: 0,
        y: 0,
        cols: 1,
        rows: 2,
        type: 'count',
        iconClass: 'or-icon-energy-saving-calendar',
        title: WIDGET_TEXT.ENERGY_SAVING.title,
        helpText: WIDGET_TEXT.ENERGY_SAVING.helpText,
        dataCy: WIDGET_TEXT.ENERGY_SAVING.dataCy,
        getData: () => this.widgetService.getEnergySaved(true)
      },
      {
        id: this.getWidgetId(),
        x: 3,
        y: 2,
        cols: 2,
        rows: 2,
        iconClass: 'or-icon-energy-year-VS-last-year',
        hideIcon: true,
        type: 'count-compared',
        getData: () => this.widgetService.getBuildingConsumptionComparison(),
        title: WIDGET_TEXT.TOTAL_ANNUAL_ENERGY_COMPARISON.title,
        dataCy: WIDGET_TEXT.TOTAL_ANNUAL_ENERGY_COMPARISON.dataCy,
        helpText: WIDGET_TEXT.TOTAL_ANNUAL_ENERGY_COMPARISON.helpText
      }
    ];
  }

  getDashboardForOccupancy(): Array<WidgetItem> {
    return [
      {
        id: this.getWidgetId(),
        cols: 3,
        rows: 4,
        y: 0,
        x: 0,
        type: 'chart',
        chartType: 'line',
        xAxisLabel: 'Days in Week',
        yAxisLabel: 'Occupancy per Tag',
        title: WIDGET_TEXT.AVG_TAG_OCCUPANCY_PER_DAILY.title,
        helpText: WIDGET_TEXT.AVG_TAG_OCCUPANCY_PER_DAILY.helpText,
        dataCy: WIDGET_TEXT.AVG_TAG_OCCUPANCY_PER_DAILY.dataCy,
        getData: () => this.widgetService.getAvgTagOccupancyPerDay()
      },
      {
        id: this.getWidgetId(),
        cols: 3,
        rows: 2,
        y: 2,
        x: 3,
        type: 'chart',
        chartType: 'bar',
        title: WIDGET_TEXT.AVG_TAG_OCCUPANCY_RATE.title,
        helpText: WIDGET_TEXT.AVG_TAG_OCCUPANCY_RATE.helpText,
        dataCy: WIDGET_TEXT.AVG_TAG_OCCUPANCY_RATE.dataCy,
        getData: () => this.widgetService.getTagsOccupancy()
      },
      {
        id: this.getWidgetId(),
        x: 3,
        y: 0,
        cols: 3,
        rows: 2,
        type: 'chart',
        chartType: 'line',
        getData: () => this.widgetService.getAvgTagOccupancyPerPeriod(),
        title: WIDGET_TEXT.AVG_TAG_OCCUPANCY.title,
        helpText: WIDGET_TEXT.AVG_TAG_OCCUPANCY.helpText,
        dataCy: WIDGET_TEXT.AVG_TAG_OCCUPANCY.dataCy
      }
    ];
  }

  getDashboardForMaintenance(): Array<WidgetItem> {
    return [
      {
        id: this.getWidgetId(),
        cols: 1,
        rows: 2,
        y: 0,
        x: 0,
        type: 'chart',
        chartType: 'gauge',
        title: WIDGET_TEXT.OVERALL_SYSTEM_HEALTH.title,
        helpText: WIDGET_TEXT.OVERALL_SYSTEM_HEALTH.helpText,
        dataCy: WIDGET_TEXT.OVERALL_SYSTEM_HEALTH.dataCy,
        getData: () => this.widgetService.getOverallSystemHealth()
      },
      {
        id: this.getWidgetId(),
        cols: 1,
        rows: 2,
        y: 0,
        x: 1,
        type: 'count',
        iconClass: 'or-icon-unresponsive-node',
        helpText: WIDGET_TEXT.UNRESPONSIVE_NODES.helpText,
        title: WIDGET_TEXT.UNRESPONSIVE_NODES.title,
        dataCy: WIDGET_TEXT.UNRESPONSIVE_NODES.dataCy,
        getData: () => this.widgetService.getUnresponsiveNodes()
      },
      {
        id: this.getWidgetId(),
        cols: 1,
        rows: 2,
        y: 2,
        x: 0,
        type: 'count',
        iconClass: 'or-icon-nodes',
        helpText: WIDGET_TEXT.TOTAL_NODES.helpText,
        title: WIDGET_TEXT.TOTAL_NODES.title,
        dataCy: WIDGET_TEXT.TOTAL_NODES.dataCy,
        getData: () => this.widgetService.getTotalNodes()
      },
      {
        id: this.getWidgetId(),
        cols: 1,
        rows: 2,
        y: 2,
        x: 1,
        type: 'count',
        iconClass: 'or-icon-lamp',
        title: WIDGET_TEXT.NODES_WITH_FAULTS.title,
        helpText: WIDGET_TEXT.NODES_WITH_FAULTS.helpText,
        dataCy: WIDGET_TEXT.NODES_WITH_FAULTS.dataCy,
        getData: () => this.widgetService.getNodesRequiringMaintenance()
      }
    ];
  }

  getDashboardForMetrics(timeZone: string): Array<WidgetItem> {
    return [
      {
        id: this.getWidgetId(),
        cols: 2,
        rows: 2,
        y: 0,
        x: 0,
        type: 'chart',
        chartType: 'bar',
        subTitle: format(TimeUtils.adjustDateToTimezone(new Date(), timeZone), DATE_FORMAT),
        title: WIDGET_TEXT.LIGHT_LEVEL.title,
        helpText: WIDGET_TEXT.LIGHT_LEVEL.helpText,
        dataCy: WIDGET_TEXT.LIGHT_LEVEL.dataCy,
        getData: () => this.widgetService.getAnalyticsData('LIGHT_OUTPUT_LEVEL')
      },
      {
        id: this.getWidgetId(),
        cols: 2,
        rows: 2,
        y: 0,
        x: 2,
        type: 'chart',
        chartType: 'bar',
        subTitle: format(TimeUtils.adjustDateToTimezone(new Date(), timeZone), DATE_FORMAT),
        title: WIDGET_TEXT.ENERGY_CONSUMPTION.title,
        helpText: WIDGET_TEXT.ENERGY_CONSUMPTION.helpText,
        dataCy: WIDGET_TEXT.ENERGY_CONSUMPTION.dataCy,
        getData: () => this.widgetService.getAnalyticsData('ENERGY_CONSUMPTION')
      },
      {
        id: this.getWidgetId(),
        cols: 2,
        rows: 2,
        y: 0,
        x: 4,
        type: 'chart',
        chartType: 'bar',
        subTitle: format(TimeUtils.adjustDateToTimezone(new Date(), timeZone), DATE_FORMAT),
        title: WIDGET_TEXT.AMBIENT_LIGHT.title,
        helpText: WIDGET_TEXT.AMBIENT_LIGHT.helpText,
        dataCy: WIDGET_TEXT.AMBIENT_LIGHT.dataCy,
        getData: () => this.widgetService.getAnalyticsData('AMBIENT_LIGHT_LEVEL')
      },
      {
        id: this.getWidgetId(),
        cols: 2,
        rows: 2,
        y: 0,
        x: 6,
        type: 'chart',
        chartType: 'bar',
        subTitle: format(TimeUtils.adjustDateToTimezone(new Date(), timeZone), DATE_FORMAT),
        title: WIDGET_TEXT.CONNECTION_QUALITY.title,
        helpText: WIDGET_TEXT.CONNECTION_QUALITY.helpText,
        dataCy: WIDGET_TEXT.CONNECTION_QUALITY.dataCy,
        getData: () => this.widgetService.getAnalyticsData('CONNECTION_QUALITY')
      }
    ];
  }
}
