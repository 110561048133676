import { OrCircadianCurveSummaryController } from '@angularjs/angular/modules/layout/or-circadian-curve-summary/OrCircadianCurveSummaryController';

export class OrCircadianCurveSummaryDirective {
  constructor() {
    return {
      bindToController: true,
      controller: ['$scope', 'CircadianCurveService', '$timeout', OrCircadianCurveSummaryController],
      controllerAs: 'circadianCurveSummary',
      replace: true,
      restrict: 'E',
      scope: {
        details: '=?',
        curvesList: '=',
        isNew: '=?',
        onSaveNewCurve: '&?',
        onDeleteCurve: '&?',
        onClose: '&?'
      },
      template: require('raw-loader!./or-circadian-curve-summary.html').default
    };
  }
}
