import { Injectable } from '@angular/core';
import { EventBridge } from '@common/event-bridge';
import { BehaviorSubject, Observable, of, tap } from 'rxjs';
import { concatMap, map, shareReplay } from 'rxjs/operators';
import { AuthMethod } from '@app/shared/models/auth-method';
import { Building } from '@app/shared/models/building.interface';
import { Floor } from '@app/shared/models/floor.interface';
import { Tag } from '@app/shared/models/tag.interface';
import { IUserInvitation, MultiInvitationConfirmation } from '@app/shared/models/user-invitation.interface';
import { User } from '@app/shared/models/user.interface';
import { BuildingResource } from '@app/shared/resources/building.resource';
import { UserRoleResource } from '@app/shared/resources/user-role.resource';
import { UserResource } from '@app/shared/resources/user.resource';
import { IBuildingService } from './building.service.interface';
import { IUserService } from './user.service.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { MultiUserInvitationResource } from '@app/shared/resources/multi-user-invitation.resource';
import { MultiUserInvitation } from '@app/shared/models/user-invitation';
import { UserRole } from '@app/shared/models/user-role.interface';
import { BuildingValidationResponse } from '@app/shared/models/building-validation-response.interface';
import { ServiceLevelType } from '@app/buildings/model/service-level-type';

@Injectable({
  providedIn: 'root'
})
export class UserService implements IUserService, IBuildingService {
  private user: User;
  private user$: Observable<User>;
  private building$: Observable<Building>;
  private buildingIds: number[] = null;
  private updateCurrentUser$ = new BehaviorSubject(null);
  private updateCurrentBuilding$ = new BehaviorSubject<Building>(null);
  private buildingCache = {};

  constructor(
    private eventBridge: EventBridge,
    private userResource: UserResource,
    private multiUserInvitationResource: MultiUserInvitationResource,
    private buildingResource: BuildingResource,
    private userRoleResource: UserRoleResource
  ) {
    this.initializeUser();
  }

  initializeUser(): void {
    this.user$ = this.updateCurrentUser$.pipe(
      concatMap(() => this.userResource.retrieveCurrent()),
      shareReplay()
    );
  }

  retrieveCurrentUser(): void {
    this.updateCurrentUser$.next(null);
  }

  getCurrentUser(): Observable<User> {
    return this.user$;
  }

  getBuildingIds(): number[] {
    if (this.buildingIds == null) {
      this.revalidateBuildingIds();
    }
    return this.buildingIds;
  }

  getFloorIdsForBuilding(buildingId: number): Observable<number[]> {
    return this.getBuilding(buildingId).pipe(
      map((building: Building) => {
        const floorsIds = [];
        if (building != null) {
          building.floors.forEach((floor) => floorsIds.push(floor.id));
        }
        return floorsIds;
      })
    );
  }

  getFloorsForBuilding(buildingId: number): Observable<Floor[]> {
    return this.getBuilding(buildingId).pipe(
      map((building: Building) => {
        return building?.floors;
      })
    );
  }

  getTagsForBuilding(buildingId: number): Observable<Tag[]> {
    return this.getBuilding(buildingId).pipe(
      map((building: Building) => {
        return building?.tags;
      })
    );
  }

  updateBuilding(building: Building): Observable<{}> {
    return this.buildingResource.update(building.id, building).pipe(
      tap(() => {
        this.eventBridge.triggerUpdate();
        delete this.buildingCache[building.id];
      })
    );
  }

  addBuilding(building: Building): Observable<void> {
    const obs = this.buildingResource.add(building).pipe(map(() => this.retrieveCurrentUser()));
    this.eventBridge.triggerUpdate();
    return obs;
  }

  validateBuilding(building: Building): Observable<BuildingValidationResponse> {
    return this.buildingResource.validateBuilding(building);
  }

  deleteBuilding(buildingId: number): Observable<void> {
    return this.buildingResource.delete(buildingId).pipe(map(() => this.retrieveCurrentUser()));
  }

  private revalidateBuildingIds(): void {
    this.buildingIds = [];
    for (const buildingAuthorization of this.user.buildingAuthorizations) {
      this.buildingIds.push(buildingAuthorization.building.id);
    }
  }

  invalidateCurrentUser(): void {
    this.user = null;
    this.retrieveCurrentUser();
  }

  public getUserRolesForBuilding(
    buildingId: number,
    pageNumber = 0,
    pageSize = 10,
    viewForAdmin = false
  ): Observable<any> {
    return this.userRoleResource.getAllForBuilding(buildingId, pageNumber, pageSize, viewForAdmin);
  }

  // TODO: Revalidate/Update the User object when modifying the underlying.

  public removeUser(buildingId: number, userId: number): Observable<{}> {
    return this.userRoleResource.removeUserFromBuilding(buildingId, userId);
  }

  public updateUserRoles(buildingId: number, userRole: UserRole): Observable<void> {
    return this.userRoleResource.updateUserRoles(buildingId, userRole).pipe(
      map(() => {
        this.invalidateCurrentUser();
      })
    );
  }

  public inviteUsers(buildingId: number, invitations: IUserInvitation[]): Observable<MultiInvitationConfirmation> {
    return this.multiUserInvitationResource.inviteMultiple(new MultiUserInvitation(buildingId, invitations)) as any;
  }

  public isLocal(): Observable<boolean> {
    return this.getCurrentUser().pipe(map((user) => user.authMethod === AuthMethod.GLUU));
  }

  public join(): Observable<User> {
    return this.userResource.join();
  }

  public update(user: User): Observable<{}> {
    return this.userResource.update(user.id, user);
  }

  public delete(id: number): Observable<{}> {
    return this.userResource.delete(id);
  }

  public getBuilding(id: number): Observable<Building> {
    if (!this.buildingCache[id]) {
      this.buildingCache[id] = this.buildingResource.retrieve(id).pipe(shareReplay());
    }
    return this.buildingCache[id];
  }

  public refreshBuilding(id: number): void {
    delete this.buildingCache[id];
  }

  public getBuildings(ids: number[]): Observable<Building[]> {
    return this.buildingResource.getBuildings(ids).pipe(
      tap((buildings) => {
        this.buildingCache = {};
        for (const building of buildings) {
          this.buildingCache[building.id] = of(building);
        }
      }),
      shareReplay()
    );
  }

  public getBuildingIdsWithNames(): Observable<{ id: number; name: string }[]> {
    return this.buildingResource.getAllBuildingIdsWithNames();
  }

  public produceCleanBuildingModel(): Building {
    return {
      id: 0,
      floors: [],
      luminaireManufacturers: [],
      managingCompany: null,
      realTimeBuildingData: null,
      tags: [],
      tenants: [],
      name: null,
      address: {
        addressLine1: null,
        addressLine2: null,
        addressLine3: null,
        country: null,
        postcode: null
      },
      thumbnailImage: null,
      timeZone: null,
      latitude: null,
      longitude: null,
      statusFrequencySeconds: null,
      serviceLevel: null,
      buildingFloorAreaSqMeters: 0,
      gateways: []
    };
  }

  private handleError(error: HttpErrorResponse): void {
    if (error.status === 403) {
      window.alert('You are not permitted to change this data.');
    } else {
      console.error(error.message);
    }
  }

  findUsers(filterString = '', sortOrder = 'asc', pageNumber = 0, pageSize = 10): Observable<any> {
    return this.userResource.find(filterString, sortOrder, pageNumber, pageSize);
  }

  getUsersInBuilding(buildingId: number): Observable<User[]> {
    return this.userResource.getUsersInBuilding(buildingId);
  }
}
