import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'app-timerange-widget',
  templateUrl: './timerange-widget.component.html',
  styleUrls: ['./timerange-widget.component.scss']
})
export class TimerangeWidgetComponent implements OnInit, AfterViewInit, OnChanges {
  public scale;
  @Input() public startTime: string;
  @Input() public endTime: string;
  @Input() public minHours: number;
  @Input() public isValidRange: boolean;

  isOverlapping: boolean;
  private startHours: number;
  private endHours: number;
  private duration: number;

  @ViewChild('scaleElement') private scaleElement: ElementRef;
  @ViewChild('fill') private fillElement: ElementRef;
  @ViewChild('fillOverlap') private fillOverlapElement: ElementRef;
  @ViewChild('labelStart') private labelStartElement: ElementRef;
  @ViewChild('labelEnd') private labelEndElement: ElementRef;

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.startTime = this.startTime ?? '00:00';
    this.endTime = this.endTime ?? '00:00';
    this.scale = this.produceScale();
  }

  ngAfterViewInit(): void {
    // defer the updating of timeline widget to the next change detection tick, and then manually trigger the
    // change detection phase. This fixes the "ExpressionChangedAfterItHasBeenCheckedError" error
    setTimeout(() => {
      this.updateChart();
      this.cdr.detectChanges();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !changes['startTime']?.isFirstChange() ||
      !changes['endTime']?.isFirstChange() ||
      !changes['isValidRange']?.isFirstChange()
    ) {
      this.updateChart();
    }
  }

  @HostListener('window:resize')
  private updateChart(): void {
    this.updateValues();
    this.renderFill();
  }

  private renderFill(): void {
    if (this.isOverlapping) {
      this.renderOverlappingFill();
      return;
    }
    this.renderRegularFill();
  }

  private renderRegularFill(): void {
    const hourWidth = this.scaleElement.nativeElement.clientWidth / 24;
    const fillWidth = this.duration * hourWidth;
    const fillOffset = this.startHours * hourWidth;
    const fillStyle = this.fillElement.nativeElement.style;
    fillStyle.width = fillWidth + 'px';
    fillStyle.left = fillOffset + 'px';

    this.labelStartElement.nativeElement.style.left = fillOffset + 'px';
    this.labelEndElement.nativeElement.style.left = fillOffset + fillWidth + 'px';
  }

  private renderOverlappingFill(): void {
    const hourWidth = this.scaleElement.nativeElement.clientWidth / 24;
    const fillWidth = (this.duration - this.endHours) * hourWidth;
    const fillOffset = this.startHours * hourWidth;
    const fillStyle = this.fillElement.nativeElement.style;
    fillStyle.width = fillWidth + 'px';
    fillStyle.left = fillOffset + 'px';

    const fillOverlapWidth = this.endHours * hourWidth;
    const fillOverlapOffset = 0;
    const fillOverlapStyle = this.fillOverlapElement.nativeElement.style;
    fillOverlapStyle.width = fillOverlapWidth + 'px';
    fillOverlapStyle.left = fillOverlapOffset + 'px';

    this.labelStartElement.nativeElement.style.left = fillOffset + 'px';
    this.labelEndElement.nativeElement.style.left = fillOverlapWidth + 'px';
  }

  private updateValues(): void {
    this.startHours = this.labelToHours(this.startTime);
    this.endHours = this.labelToHours(this.endTime);
    this.isOverlapping = this.endHours - this.startHours < 0;
    this.duration = this.getDuration();
    //this.isValidRange = (this.duration >= this.minHours);
  }

  private getDuration(): number {
    const startHours = this.startHours;
    const endHours = this.endHours;
    if (!this.isOverlapping) {
      return endHours - startHours;
    }
    return endHours - startHours + 24;
  }

  public produceScale(): string[] {
    const scale = [];
    for (let hours = 0; hours < 24; hours += 1) {
      scale.push(this.hoursToLabel(hours));
    }
    return scale;
  }

  private hoursToLabel(hours): string {
    const parsedHours = Math.floor(hours);
    const parsedMinutes = 60 * (hours - parsedHours);
    return [('0' + parsedHours).slice(-2), ('0' + parsedMinutes).slice(-2)].join(':');
  }

  private labelToHours(label): number {
    const split = label.split(':');
    const parsedHours = parseInt(split[0]);
    const parsedMinutes = parseInt(split[1]);
    let hours = parsedHours + parsedMinutes / 60;
    hours = parseFloat(hours.toFixed(2));
    return hours < 24 ? hours : hours - parsedHours;
  }
}
