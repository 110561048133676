import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { setAngularJSGlobal } from '@angular/upgrade/static';
import * as angular from 'angular';
import 'reflect-metadata';
import 'zone.js';
import { AppModule } from './app/app.module';

setAngularJSGlobal(angular);

import '@angularjs/angular/app/portal/app'; // Load Angular JS app into memory
platformBrowserDynamic().bootstrapModule(AppModule);
