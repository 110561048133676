import moment from 'moment';

import { IQueryContext } from './IContext';
import { DataType } from '../../../../data/SensorNodeDataType';
import { Resolution } from '../../../../data/Resolution';
import { Dates } from './Dates';
import { IBuildingFloorRouteService } from '../../../../angular/routing/IFloorRouteService';
import { IOptionalRefreshLocationService } from '../../../../angular/IOptionalRefreshLocationService';
import { Coordinate } from '../../../../util/Coordinate';

export class UrlQueryContext implements IQueryContext {
  private currentFloorId: number;
  private currentBuildingId: number;

  constructor(
    private $location: IOptionalRefreshLocationService,
    private routeParams: IBuildingFloorRouteService
  ) {}

  get sensorNodeIds(): number[] {
    return this.getMulti('n').map((v) => Number(v));
  }

  set sensorNodeIds(value: number[]) {
    this.setMulti(
      'n',
      value.map((v) => v.toString())
    );
  }

  get emDriverIds(): number[] {
    return this.getMulti('em').map((v) => Number(v));
  }

  set emDriverIds(value: number[]) {
    this.setMulti(
      'em',
      value.map((v) => v.toString())
    );
  }

  get tagIds(): number[] {
    return this.getMulti('t').map((v) => Number(v));
  }

  set tagIds(value: number[]) {
    this.setMulti(
      't',
      value.map((v) => v.toString())
    );
  }

  get dataType(): DataType {
    const dataType = this.getSingle('type');
    return dataType != null
      ? DataType.fromString(dataType.toUpperCase())
      : DataType.PRESENCE;
  }

  set dataType(value: DataType) {
    this.setSingle('type', value.toString().toLowerCase());
  }

  get resolution(): Resolution {
    const resolution = this.getSingle('scale');
    return resolution != null
      ? Resolution.fromString(resolution.toUpperCase())
      : null;
  }

  set resolution(value: Resolution) {
    this.setSingle('scale', value.toString().toLowerCase());
  }

  get dates(): Dates {
    const dates = new Dates();
    dates.years = this.getMulti('year').map((v) => Number(v));
    dates.months = this.getMulti('month').map((v) => Number(v));
    dates.days = this.getMulti('day').map((v) => Number(v));
    dates.hours = this.getMulti('hour').map((v) => Number(v));
    dates.minutes = this.getMulti('minute').map((v) => Number(v));
    if (dates.years.length == 0) {
      dates.years = [moment().year()];
    }
    return dates;
  }

  set dates(value: Dates) {
    this.setMulti(
      'year',
      value.years.map((v) => v.toString())
    );
    this.setMulti(
      'month',
      value.months.map((v) => v.toString())
    );
    this.setMulti(
      'day',
      value.days.map((v) => v.toString())
    );
    this.setMulti(
      'hour',
      value.hours.map((v) => v.toString())
    );
    this.setMulti(
      'minute',
      value.minutes.map((v) => v.toString())
    );
  }

  get zone(): string {
    return this.getSingle('z');
  }

  set zone(value: string) {
    this.setSingle('z', value);
  }

  get offsetDuration(): string {
    return this.getSingle('offset');
  }

  set offsetDuration(value: string) {
    this.setSingle('offset', value);
  }

  get floorIds(): number[] {
    return [Number(this.routeParams.floorId)];
  }

  set floorIds(value: number[]) {
    if (this.currentFloorId != null && this.currentFloorId != value[0]) {
      this.reset();
    }
    this.currentFloorId = value[0];
  }

  get buildingId(): number {
    return Number(this.routeParams.buildingId);
  }

  set buildingId(value: number) {
    if (this.currentBuildingId != null && this.currentBuildingId != value) {
      this.reset();
    }
    this.currentBuildingId = value;
  }

  get zoom(): number {
    const zoom = Number(this.getSingle('zoom'));
    return !isNaN(zoom) ? zoom : null;
  }

  set zoom(value: number) {
    this.setSingle('zoom', value.toString());
  }

  get liveMode(): boolean {
    const liveMode = this.getSingle('liveMode');
    return liveMode != undefined && liveMode !== 'false';
  }

  set liveMode(value: boolean) {
    this.setSingle('liveMode', value.toString());
  }

  get coordinate(): Coordinate {
    const x = Number(this.getSingle('x'));
    const y = Number(this.getSingle('y'));
    return !isNaN(x) && !isNaN(y) ? new Coordinate(x, y) : null;
  }

  set coordinate(value: Coordinate) {
    this.setSingle('x', value != null ? value.x.toString() : null);
    this.setSingle('y', value != null ? value.y.toString() : null);
  }

  private getSingle(key: string): string {
    return this.$location.search()[key];
  }

  private setSingle(key: string, value: string) {
    this.$location.performSearch(key, value, false);
  }

  private getMulti(key: string): string[] {
    const value = this.$location.search()[key];
    if (value instanceof Array) {
      return value;
    }
    return value != null ? [value] : [];
  }

  private setMulti(key: string, value: string[]) {
    this.$location.performSearch(key, value, false);
  }

  public reset(): void {
    if (this.tagIds.length > 0) {
      this.tagIds = [];
    }
    if (this.sensorNodeIds.length > 0) {
      this.sensorNodeIds = [];
    }
  }
}
