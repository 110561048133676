import { Injectable } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { SensorNode } from '@app/shared/models/sensor-node';

@Injectable({
  providedIn: 'root'
})
export class CopyToClipboardService {
  constructor(private clipboard: Clipboard) {}

  copy(nodes: Partial<SensorNode>[]): void {
    this.clipboard.copy(this.getNodesAsCsv(nodes));
  }

  copyString(toCopy: string): void {
    this.clipboard.copy(toCopy);
  }

  protected getNodesAsCsv(nodes: Partial<SensorNode>[]): string {
    console.log(nodes);
    const headerData =
      'Id,Address(Portal),Node Type,Connection Status,Luminaire Drivers,EM Drivers,Driver Faults,Tags\n';
    const nodesData = nodes
      .map((node: any): string => {
        const nodeType = node.nodeType != null ? node.nodeType : 'SN3';
        const tags = node.tags ? node.tags.map((tag) => tag.name).join(';') : '';
        const lumeDrivers = node.luminaireDrivers ? node.luminaireDrivers.map((l) => l.address16).join(';') : '';
        const emDrivers = node.emDrivers ? node.emDrivers.map((e) => e.address16).join(';') : '';
        const nodeAlerts = node.alerts ? node.alerts.map((a) => a.value).join(';') : '';

        return (
          node.id +
          ',' +
          node.address16 +
          ',' +
          nodeType +
          ',' +
          node.connected +
          ',' +
          lumeDrivers +
          ',' +
          emDrivers +
          ',' +
          nodeAlerts +
          ',' +
          tags
        );
      })
      .join('\n');
    return headerData + nodesData;
  }
}
