<div id='orAdministrationFloors'>
  <div class='or-content'>
    <mat-spinner *ngIf='isBusy'></mat-spinner>
    <div class='or-content-header'>
      <ul class='or-inline-items or-pull-left'>
        <li class='or-inline-item'>
        </li>
      </ul>
    </div>
    <div class='or-content-body'>
      <div class='or-banner or-banner-no-floors' *ngIf='floors.length == 0' data-cy='no-floors-banner'>
        <div class='or-banner-header'><h1>This building has no floors</h1></div>
        <div class='or-banner-content'><p>Go ahead and add one.</p></div>
      </div>
      <ol class='or-floors-list'>
        <li class='or-floors-list-item' *ngFor='let floor of floors'>
          <app-form-floor-details [floor]='floor' [isBusy]='isBusy' [building]='building'
                                  (valueChanged)='loadFloors()'></app-form-floor-details>
        </li>
      </ol>
      <ol class='or-floors-list'>
        <li class='or-floors-list-item'>
          <app-form-floor-details [isNew]='true' [building]='building' [isBusy]='isBusy' [newFloorNum]='newFloorNum'
                                  (valueChanged)='loadFloors()'></app-form-floor-details>
        </li>
      </ol>
    </div>
  </div>
</div>
