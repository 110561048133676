import { OrImageUploadController } from './OrImageUploadController';

export class OrImageUploadDirective {
  constructor() {
    return {
      bindToController: true,
      controller: ['$http', '$element', '$scope', 'ImageCachingService', 'GenerateApiUrl', OrImageUploadController],
      controllerAs: 'imageUpload',
      replace: true,
      restrict: 'E',
      require: 'ngModel',
      scope: {
        isBusy: '=?',
        isDisabled: '=?',
        imageUrl: '=',
        uploadUrl: '@',
        caption: '@?',
        imageName: '=',
        onUpload: '&',
        form: '='
      },
      template: require('raw-loader!./or-image-upload.html').default
    };
  }
}
