export enum DuplicateType {
  ADDRESS = 'ADDRESS',
  GROUP = 'GROUP'
}
export class DuplicateMapping {
  constructor(
    public nodeId: number,
    public floorId: number,
    public buildingId: number,
    public value: number,
    public type: DuplicateType
  ) {}

  public getLink(): string {
    return `/buildings/${this.buildingId}/${this.floorId}/nodes?n=${this.nodeId}`;
  }
}
