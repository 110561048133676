import { OrFormGatewayDetailsController } from './OrFormGatewayDetailsController';

export class OrFormGatewayDetailsDirective {
  constructor() {
    return {
      bindToController: true,
      controller: ['$scope', 'GatewayService', 'GatewayHealthService', OrFormGatewayDetailsController],
      controllerAs: 'gatewayDetails',
      replace: true,
      restrict: 'E',
      scope: {
        isNew: '=',
        isBusy: '=',
        gatewayHealth: '=?',
        isDisabled: '=?',
        details: '=?',
        startBusy: '&',
        endBusy: '&',
        reload: '&',
        onDeleteGateway: '&',
        onReset: '&'
      },
      template: require('raw-loader!./or-form-gateway-details.html').default
    };
  }
}
