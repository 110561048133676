import { OrFormBleKeyDetailsController } from './OrFormBleKeyDetailsController';

export class OrFormBleKeyDetailsDirective {
  constructor() {
    return {
      bindToController: true,
      controller: ['$scope', OrFormBleKeyDetailsController],
      controllerAs: 'keyDetails',
      replace: true,
      restrict: 'E',
      scope: {
        enableLabels: '=',
        isBusy: '=',
        isNew: '=',
        newKey: '=?',
        onAddKey: '&',
        onUpdateKey: '&',
        onSendKey: '&',
        key: '=',
        isCurrent: '='
      },
      template: require('raw-loader!./or-form-ble-key-details.html').default
    };
  }
}
