import { OrFailedTestsCounterController } from './OrFailedTestsCounterController';

export class OrFailedTestsCounterDirective {
  constructor() {
    return {
      bindToController: true,
      controller: ['SensorNodeService', OrFailedTestsCounterController],
      controllerAs: 'failedTestsCounter',
      replace: true,
      restrict: 'E',
      scope: {
        isActive: '='
      },
      template: require('raw-loader!./or-failed-tests-counter.html').default
    };
  }
}
