import { Pair } from './Pair';
import { Consumer, DoubleConsumer } from '../delegates/Delegates';
import { IObservable, IObservableModifiable } from './IObservable';
import { DescriptiveStateChangeMessage } from '../messaging/StateChangeMessage';
import { IReceiver } from '../messaging/Channel';
import * as angular from 'angular';

export class Observable<T> implements IObservableModifiable<T> {
  private observers: Pair<string, DoubleConsumer<T, T>>[] = [];

  constructor(
    private _value: T,
    private updateReceiver?: IReceiver<DescriptiveStateChangeMessage<T>>
  ) {}

  public onChange(observer: DoubleConsumer<T, T>, key?: string) {
    this.observers.push(new Pair(key, observer));
  }

  public deregisterAll() {
    this.observers = [];
  }

  public change(modification: Consumer<T>, key?: string) {
    const oldValue = angular.copy(this._value);
    modification(this._value);
    const newValue = this.value();
    this.observers
      .filter((pair) => key == null || pair.key != key)
      .forEach((pair) => pair.value(newValue, oldValue));

    if (this.updateReceiver != null) {
      this.updateReceiver.send(
        new DescriptiveStateChangeMessage<T>(newValue, oldValue)
      );
    }
  }

  public value(): T {
    // TODO returning a copy would prevent modification outside of "change"
    return this._value;
  }
}

export class ProxiedObservable<A, B> implements IObservable<A> {
  constructor(private a: A, private b: IObservable<B>) {}

  public onChange(observer: (A) => void) {
    this.b.onChange((value) => observer(this.a));
  }

  public value(): A {
    return this.a;
  }

  public deregisterAll() {
    // TODO shouldn't deregister all on the underlying observable
    this.b.deregisterAll();
  }
}
