<div [ngStyle]="{ 'width.px': width, 'height.px': height}"
  class="or-image-upload or-button"
  [ngClass]="{ 'or-disabled': isDisabled }"
>
  @if (newImageUrl) {
    <div data-cy='image-preview' class="or-image-upload-preview" [style.background-image]="imagePreview()"></div>
  }
  <div class="or-image-upload-button" [hidden]="isBusy">
    <span class="or-image-upload-button-label">
      <span class="or-icon or-small or-inverse or-icon-camera"></span>
      @if (caption) {
        <span class="or-button-label" [textContent]="caption"></span>
      }
    </span>
    <input
      data-cy='image-upload-input'
      #fileInput
      class="or-image-upload-input"
      (change)="onFileChange($event.target.files)"
      type="file"
      accept="image/*"
      [disabled]="isDisabled"
    />
  </div>
</div>
