import * as angular from 'angular'; // Automatically added
import { BuildingAuthorityType } from '@app/shared/models/building-authority-type';
import { FormMobileToken } from '@angularjs/or/api/building/MobileToken';

export class OrFormMobileTokenDetailsController {
  public isNew: boolean;
  public isBusy: boolean;
  public token;
  public form;
  public initialToken;
  public authorityMap = {};
  public authorityNameMap = {};
  public newToken: FormMobileToken;
  public onAddToken;
  public onDeactivateToken;
  public onUpdateToken;

  constructor() {}

  public $onInit() {
    this.backupInitialToken();
    for (const buildingAuthority of BuildingAuthorityType.all()) {
      this.authorityNameMap[buildingAuthority.toString()] =
        buildingAuthority.getName();
    }
  }

  backupInitialToken() {
    this.initialToken = angular.copy(this.token);
  }

  restoreInitialToken() {
    this.token = angular.copy(this.initialToken);
  }

  resetToken() {
    const msg =
      'Reset mobile token' + (this.token.token ? ' ' + this.token.token : '?');
    if (!confirm(msg)) {
      return;
    }
    this.restoreInitialToken();
    this.resetFormValidationState();
  }

  resetFormValidationState() {
    this.form.$setPristine();
    this.form.$setUntouched();
  }

  addToken() {
    this.resetFormValidationState();
    this.onAddToken();
  }
}
