import { OrBeaconsNodesListController } from './OrBeaconsNodesListController';
import { FilterService } from '@angularjs/or/services/FilterService';

export class OrBeaconsNodesListDirective {
  constructor() {
    return {
      bindToController: true,
      controller: [
        '$scope',
        '$element',
        'SecurityService',
        'CopyToClipboardService',
        'FilterService',
        'UIRefreshService',
        'BeaconSettingService',
        'SensorNodeService',
        OrBeaconsNodesListController
      ],
      controllerAs: 'beaconsNodesList',
      replace: true,
      restrict: 'E',
      scope: {
        activeNode: '=',
        isCompact: '=',
        floorId: '=',
        reloadNodesSub: '=',
        selectedNode: '='
      },
      template: require('raw-loader!./or-beacons-nodes-list.html').default
    };
  }
}
