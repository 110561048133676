import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SensorNode } from '@app/shared/models/sensor-node';
import { FloorplanService } from '@services/floorplan.service';

@Component({
  selector: 'app-mapped-nodes-counter',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './mapped-nodes-counter.component.html',
  styleUrl: './mapped-nodes-counter.component.scss'
})
export class MappedNodesCounterComponent {
  @Input({ required: true }) nodes: SensorNode[];
  constructor(private floorplanService: FloorplanService) {}

  get isActive(): boolean {
    return this.floorplanService.isSensorNodeMappingModeActive;
  }

  isEverythingMapped(): boolean {
    const unmappedNodes = this.getTotalNodes().length - this.getMappedNodes().length;
    const duplicates = this.getDuplicateNodes().length;
    return unmappedNodes === 0 && duplicates === 0;
  }

  getDuplicateNodesCount(): number {
    return this.getDuplicateNodes().length;
  }

  getTotalNodes(): SensorNode[] {
    return this.nodes ?? [];
  }

  getMappedNodesWithoutDriversCount(): number {
    return this.getMappedNodesWithoutDrivers().length;
  }

  getNodesWithoutDriversCount(): number {
    return this.getNodesWithoutDrivers().length;
  }

  getNodesWithoutDrivers(): SensorNode[] {
    return this.getTotalNodes();
  }

  getMappedNodes(): SensorNode[] {
    return this.getTotalNodes().filter((node) => {
      return node.properlyMapped;
    });
  }

  getMappedNodesWithoutDrivers(): SensorNode[] {
    return this.getNodesWithoutDrivers().filter((node) => {
      return node.properlyMapped;
    });
  }

  getDuplicateNodes(): SensorNode[] {
    const nodes = this.getTotalNodes();
    return nodes.filter((node) => {
      return node.duplicateAddressMappings?.length > 0;
    });
  }

  getUnmappedNodes(): SensorNode[] {
    const nodes = this.getTotalNodes();
    return nodes.filter((node) => {
      return !node.properlyMapped;
    });
  }
}
