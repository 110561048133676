import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-floorplan-actions-tray',
  standalone: true,
  imports: [CommonModule, MatTooltipModule],
  templateUrl: './floorplan-actions-tray.component.html',
  styleUrls: ['./floorplan-actions-tray.component.scss']
})
export class FloorplanActionsTrayComponent implements OnChanges {
  showTrayByClick: boolean;

  @Input({ required: true }) trayButtons: TrayButton[];
  @Input({ required: true }) trayButtonIconClass: string;
  @Input({ required: true }) trayButtonToolTip: string;
  @Input() disableTrayButtons: boolean;
  @Input() disable: boolean;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disable?.currentValue === true) {
      this.showTrayByClick = false;
    }
  }

  toggleTray(): void {
    this.showTrayByClick = !this.showTrayByClick;
  }

  runButtonAction(button: TrayButton, event: MouseEvent | TouchEvent): void {
    button.onClick();
    event.stopPropagation(); // Prevent the click event from bubbling up to the parent element
    if (button.closeTrayAfter) {
      this.showTrayByClick = false;
    }
  }
}

export class TrayButton {
  constructor(
    public toolTip: string,
    public iconClass: string,
    public onClick: () => void,
    public onActive?: () => boolean,
    public closeTrayAfter: boolean = false,
    public id?: string,
    public checkTenancy: boolean = false
  ) {}
}
