import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BuildingAuthorityType } from '../models/building-authority-type';
import { SecurityService } from '@services/security.service';

@Directive({
  selector: '[appIsAuthorizedForBuilding]'
})
export class IsAuthorizedForBuildingDirective implements OnInit, OnDestroy {
  @Input('appIsAuthorizedForBuilding') public authority: keyof typeof BuildingAuthorityType;
  @Input('appBuildingIdToAuthorize') public buildingId: number;

  private readonly onDestroy$ = new Subject<void>();

  constructor(
    private readonly securityService: SecurityService,
    private readonly renderer: Renderer2,
    private readonly el: ElementRef
  ) {}

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngOnInit(): void {
    this.toggleUnauthorized();

    this.securityService
      .isAuthorizedForBuilding(this.authority, this.buildingId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((hasAuthority) => {
        if (hasAuthority) {
          this.toggleAuthorized();
        }
      });
  }

  toggleUnauthorized(): void {
    this.renderer.addClass(this.el.nativeElement, 'unauthorizedForBuilding');
    this.renderer.removeClass(this.el.nativeElement, 'authorizedForBuilding');
  }

  toggleAuthorized(): void {
    this.renderer.removeClass(this.el.nativeElement, 'unauthorizedForBuilding');
    this.renderer.addClass(this.el.nativeElement, 'authorizedForBuilding');
  }
}
