import { Component, Input, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { SensorNodeDTO } from '@app/shared/models/sensor-node';
import { SelectableLuminaire } from '@app/shared/models/luminaire-driver';
import { Observable, of } from 'rxjs';
import { SensorNodeService } from '@app/shared/services/sensor-node.service';
import { FloorplanService } from '@services/floorplan.service';
import { Tag } from '@app/shared/models/tag.interface';

@Component({
  selector: 'app-luminaire-driver',
  templateUrl: './luminaire-driver.component.html',
  styleUrls: ['./luminaire-driver.component.scss']
})
export class LuminaireDriverComponent implements OnInit {
  @Input()
  driver: SelectableLuminaire;

  @Input()
  node: SensorNodeDTO;

  @Input()
  zoomLevel$: Observable<number> = of(1);

  @Input()
  tags: Tag[] = [];

  propertiesStyle: Observable<Record<string, string>>;

  private disableSelection = false; // FIXME: This should be made available from parent component

  constructor(private readonly nodeService: SensorNodeService, private floorplanService: FloorplanService) {}

  ngOnInit(): void {
    this.propertiesStyle = this.zoomLevel$.pipe(map((zoomLevel) => this.producePropertiesStyle(zoomLevel)));
  }

  public produceClassForLuminaireDriver(): Record<string, boolean> {
    const isDriverHidden = (): boolean => {
      let result = false;
      if (!this.floorplanService.isEnableAllNodesSelected) {
        result = true;
      }
      if (!this.floorplanService.isShowFaultyNodesModeActive && this.node.isFaulty) {
        result = true;
      }
      return result;
    };
    return {
      selected: this.driver.selected,
      'is-changed': this.node.isChanged,
      hidden: isDriverHidden(),
      muted: this.floorplanService.isElmtPage
    };
  }

  onClick(event: MouseEvent): void {
    if (!this.floorplanService.isElmtPage && !this.floorplanService.isSensorNodesPage) {
      this.nodeService.toggleEntitySelection(this.driver, this.floorplanService.isCumulativeModeActive);
      this.nodeService.updateQueryParams();
    }
    event.stopPropagation();
  }

  // FIXME: use abstract class to implement this duplicated method
  get address16(): string {
    return this.driver.address.toString(16).toUpperCase();
  }

  public produceDriverStyle(x: number, y: number): Record<string, string> {
    const style: Record<string, string> = {};
    style.left = this.driver.x + 'px';
    style.top = this.driver.y + 'px';
    return style;
  }

  public produceClassForLuminaireDriverIcon(): {} {
    const styleClass = {};
    styleClass['or-icon-node'] = true;
    styleClass['or-icon-node-dt8'] = this.driver.daliType === 'DT8';

    return styleClass;
  }

  public getDriverIdText(): string {
    let text = '';
    text += this.driver.daliType === 'DT8' ? 'DT8' : 'DT6';
    text += ' Device';
    text += ' #' + this.driver.id;
    return text;
  }

  // public getLampTypeName(): string {
  //     return this.driver.lampType?.name + ' (' + this.driver.lampType?.powerConsumptionMax + 'W)';
  // }

  public producePropertiesStyle(zoomLevel: number): Record<string, string> {
    const style: Record<string, string> = {};
    const transform = 'translate(-50%, -8px) scale(' + 1 / zoomLevel + ')';
    style.transform = transform;
    style['-webkit-transform'] = transform;

    return style;
  }

  showMutedTooltip(): boolean {
    return this.floorplanService.isElmtPage;
  }
}
