<div
  class="em-driver em-driver-regular-shadow has-emergency-gear driver"
  [attr.data-cy]="'em-driver-' + driver.address16"
  [ngClass]="produceClassForEmDriver()"
  (click)="onClick($event)"
  [ngStyle]="generateDriverCoordinates()"
  (touchstart)="$event.stopPropagation()">
  <div>
    <svg
      tagOutline
      [isDriver]="true"
      [tags]="tags"
      class="test-in-progress-animate em-driver-tags"></svg>
    <div>
      <span class="test-in-progress-animate em-driver-icon or-icon or-small"
            [ngClass]="produceClassForEmDriverIcon()">
      </span>
    </div>
    <div class="em-driver-properties">
      <div class="em-driver-properties-header"><h1 class="em-driver-properties-id">{{getDriverIdText()}}</h1>
        <span class="em-driver-properties-address">{{address16}}</span>
        <span class="em-driver-properties-emergency-gear">Emergency Light</span>
      </div>
      <div class="em-driver-properties-tags" *ngIf="tags.length">
        <div class="em-driver-properties-tag" *ngFor="let tag of tags"><span
          class="em-driver-properties-tag-color" [ngStyle]="{ 'background-color': '#' + tag.color}"></span>
          <span class="em-driver-properties-tag-name">{{tag.name}}</span></div>
      </div>
      <div class="em-driver-properties-test-results" *ngIf="shouldResultsBeVisible">
        <div class="em-driver-properties-test-result" *ngIf="resultPairForDriver != null">
          <span class="em-driver-properties-test-result-icon icon tiny inverse icon-crash-test"></span>
          <span class="em-driver-properties-test-result-message">
          Functional Test Result: {{ showFunctionalTestResult() }}
        </span>
        </div>
        <div class="em-driver-properties-test-result" *ngIf="resultPairForDriver != null">
          <span class="em-driver-properties-test-result-icon icon tiny inverse icon-stopwatch"></span>
          <span class="em-driver-properties-test-result-message">
          Duration Test Result: {{ showDurationTestResult() }}
        </span>
        </div>
      </div>
    </div>
  </div>
</div>
