<div>
    <app-header [menuItems]="menuItems"></app-header>
    <router-outlet></router-outlet>
    <app-footer></app-footer>
</div>
<!--TODO: Convert main layout to flexbox-->
<!--<div class="flex flex-col min-h-screen">-->
<!--  <div class="grow-0 shrink-0">-->
<!--    <app-header [menuItems]="menuItems"></app-header>-->
<!--  </div>-->
<!--  <div class="grow shrink">-->
<!--    <router-outlet></router-outlet>-->
<!--  </div>-->
<!--  <div class="grow-0 shrink-0 mt-auto">-->
<!--    <app-footer></app-footer>-->
<!--  </div>-->
<!--</div>-->
