export class Transform {

    public scale: number;
    public translateX: number;
    public translateY: number;

    constructor(scale: number,
                translateX?: number,
                translateY?: number) {
        this.scale = scale;
        this.translateX = translateX;
        this.translateY = translateY;
    }

    toString(): string {
        let style = '';
        if (this.scale) {
            style += 'scale(' + this.scale + ') ';
        }
        if (this.translateX) {
            style += 'translateX(' + this.translateX + 'px) ';
        }
        if (this.translateY) {
            style += 'translateY(' + this.translateY + 'px) ';
        }
        if (!(this.scale || this.translateX || this.translateY)) {
            style = null;
        }
        return style;
    }
}
