export class StringUtils {
  public static truncate(text: string, maxLength = 20) {
    return text.length > maxLength ? text.substr(0, maxLength) + '...' : text;
  }

  public static humanizeConstant(text: string): string {
    if (text != null) {
      let string = text.split('_').join(' ').toLowerCase();
      string = string.charAt(0).toUpperCase() + string.slice(1);
      return string;
    }
  }

  public static toSentenceCase(text: string): string {
    return text.replace(new RegExp('[A-Z|a-z]', 'g'), (letter, offset) => {
      return offset == 0 ? letter.toUpperCase() : letter.toLowerCase();
    });
  }
}
