@if(schedules$ | async; as schedules) {
  <div id='emergencyLightingSchedulesList' data-cy="emergency-lighting-schedules-list">
    <div class='or-content-body' *ngIf="!showNewForm && schedules.length < 1">
      <span>No emergency lighting schedules found</span> <!-- use banner component here -->
    </div>
    @if ((schedules.length > 0) || (showNewForm === true)) {
      <div class='schedule-list'>
        <mat-slide-toggle
          [attr.data-cy]="'show-disabled-schedules-slider'"
          (click)="toggleShowDisabledSchedules()"
          [ngModel]="shouldShowDisabledSchedules">
          Show Disabled Schedules
        </mat-slide-toggle>
        @for (schedule of schedules; track schedule.id) {
          @if (newlyAddedScheduleIds.has(schedule.id)) {
            <app-emergency-lighting-schedules-summary
              [schedule]="schedule"
              (refreshListEmitter)="refreshList()"
              (closeNewFormEmitter)="closeNew()"
              [building$]="building$"
              [tags$]="tags$">
            </app-emergency-lighting-schedules-summary>
          }
        }
        <mat-divider></mat-divider>
        @for (schedule of schedules; track schedule.id) {
          @if (schedule.enabled === true && !newlyAddedScheduleIds.has(schedule.id)) {
            <app-emergency-lighting-schedules-summary
              [schedule]="schedule"
              (refreshListEmitter)="refreshList()"
              (closeNewFormEmitter)="closeNew()"
              [building$]="building$"
              [tags$]="tags$">
            </app-emergency-lighting-schedules-summary>
          }
        }
        @if (shouldShowDisabledSchedules === true) {
          <mat-divider></mat-divider>
          @for (schedule of schedules; track schedule.id) {
            @if (schedule.enabled === false && !newlyAddedScheduleIds.has(schedule.id)) {
              <app-emergency-lighting-schedules-summary
                [schedule]="schedule"
                (refreshListEmitter)="refreshList()"
                (closeNewFormEmitter)="closeNew()"
                [building$]="building$"
                [tags$]="tags$">
              </app-emergency-lighting-schedules-summary>
            }
          }
        }
        @if (showNewForm) {
          <app-emergency-lighting-schedules-summary
            [isNew]="true"
            (refreshListEmitter)="refreshList()"
            (closeNewFormEmitter)="closeNew()"
            (newScheduleCreatedEmitter)="onNewScheduleCreated($event)"
            [building$]="building$"
            [tags$]="tags$"
          ></app-emergency-lighting-schedules-summary>
        }
      </div>
    }
    <button
      matTooltip="Add new emergency lighting schedule"
      matTooltipClass="mat-tooltip font-15"
      matTooltipPosition="above"
      aria-label="Button to add new emergency lighting schedule"
      mat-fab
      (click)="createNew()"
      class="add-schedule-button"
      color="primary"
      data-cy="add-new-schedule-button">
      <mat-icon>add</mat-icon>
    </button>
  </div>
}

<ng-template #loadingTemplate>
  <p data-cy='load-pending-toast'>Loading the data, please wait</p>
</ng-template>
<ng-template #successTemplate>
  <i data-cy='load-success-toast'>Data ready!</i>
</ng-template>
<ng-template #errorTemplate>
  <b data-cy='load-error-toast'>There was an error loading data. Please reload the page</b>
</ng-template>
