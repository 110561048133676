import { Injectable } from '@angular/core';
import { Pageable } from '@app/shared/models/Pageable.interface';
import { Observable } from 'rxjs';
import {
  EmergencyLogPageFilter,
  EmergencyLogResource,
  IEmergencyLog
} from '@app/shared/resources/emergency-log.resource';

@Injectable({
  providedIn: 'root'
})
export class EmergencyLogService {
  constructor(private emLogResource: EmergencyLogResource) {}

  getLogsForBuilding(filterParams: EmergencyLogPageFilter): Observable<Pageable<IEmergencyLog>> {
    return this.emLogResource.getFilteredLogsForBuilding(filterParams);
  }
}
