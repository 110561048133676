import { Tag } from './Tag';
import { Floor } from './Floor';

export class Rule {
  constructor(
    public command?: string,
    public value?: number,
    public tags: Tag[] = [],
    public floors: Floor[] = []
  ) {}

  public static clone(other: Rule): Rule {
    return new Rule(other.command, other.value, other.tags, other.floors);
  }
}
