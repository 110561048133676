import { BuildingAuthorityType } from '@app/shared/models/building-authority-type';
import { GlobalAuthority } from '@app/shared/models/global-authority';
import { TokenIPAddress } from '@angularjs/or/api/building/TokenIPAddress';

export class TokenUser {
  public id: number;
  public token: string;
  public authorityTypes: BuildingAuthorityType[];
  public description: string;
  public tokenStatus: string;
  public tenantIds: number[];
  public allowedIpAddresses: TokenIPAddress[];

  constructor(
    id: number,
    token: string,
    authorityTypes: BuildingAuthorityType[],
    description: string,
    tokenStatus: string,
    tenantIds: number[],
    allowedIpAddresses: TokenIPAddress[]
  ) {
    this.id = id;
    this.token = token;
    this.authorityTypes = authorityTypes;
    this.description = description;
    this.tokenStatus = tokenStatus;
    this.tenantIds = tenantIds;
    this.allowedIpAddresses = allowedIpAddresses;
  }

  public static clone(other: TokenUser): TokenUser {
    return new TokenUser(
      other.id,
      other.token,
      other.authorityTypes,
      other.description,
      other.tokenStatus,
      other.tenantIds,
      other.allowedIpAddresses
    );
  }
}

export class TokenUserSerialised {
  public id: number;
  public description: string;
  public globalAuthorities: GlobalAuthority[] = [];
  public buildingAuthorityTypes: string[] = [];
  public tokenStatus: string;
  public tenantIds: number[] = [];
  public allowedIpAddresses: string[] = [];

  constructor(tokenUser: TokenUser) {
    this.id = tokenUser.id;
    this.description = tokenUser.description;
    tokenUser.authorityTypes.forEach((auth) => {
      this.buildingAuthorityTypes.push(auth.toString());
    });
    this.tokenStatus = tokenUser.tokenStatus;
    this.tenantIds = tokenUser.tenantIds;
    if (tokenUser.allowedIpAddresses) {
      tokenUser.allowedIpAddresses.map((tokenIp) => {
        this.allowedIpAddresses.push(tokenIp.ipAddress);
      });
    }
  }
}

export class FormToken extends TokenUser {
  public dirty: boolean;
  public usableToken: string;
  public deactivated: boolean;

  constructor(
    id: number,
    token: string,
    authorityTypes: BuildingAuthorityType[],
    description: string,
    tokenStatus: string,
    tenantIds: number[],
    usableToken: string,
    allowedIpAddresses: TokenIPAddress[]
  ) {
    super(
      id,
      token,
      authorityTypes,
      description,
      tokenStatus,
      tenantIds,
      allowedIpAddresses
    );
    this.deactivated = tokenStatus === 'DEACTIVATED';
    this.usableToken = usableToken;
  }

  static fromToken(token: TokenUser): FormToken {
    return new FormToken(
      token.id,
      token.token,
      token.authorityTypes,
      token.description,
      token.tokenStatus,
      token.tenantIds,
      token.id + '-' + token.token,
      token.allowedIpAddresses
    );
  }

  static fromTokens(tokens: TokenUser[]): FormToken[] {
    if (tokens == null) {
      return null as FormToken[];
    }
    const formTokens: FormToken[] = [];
    tokens.forEach((token) =>
      formTokens.push(
        new FormToken(
          token.id,
          token.token,
          token.authorityTypes,
          token.description,
          token.tokenStatus,
          token.tenantIds,
          token.id + '-' + token.token,
          token.allowedIpAddresses
        )
      )
    );
    return formTokens;
  }
}
