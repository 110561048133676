import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { EmergencyLightingTestType } from '@app/shared/models/emergency-lighting-test-type';
import { EmergencyLightingTestState } from '@app/shared/models/emergency-lighting-test-state';
import { CustomDatePipe } from '@app/shared/pipes/custom-date.pipe';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { SharedComponentsModule } from '@app/shared/shared-components.module';
import { IEmergencyLogGroup } from '@app/shared/resources/emergency-log.resource';
import { EMERGENCY_LIGHTING_SCHEDULES_ROUTE } from '@common/route-constants';
import { NotificationBlockComponent, StatusClass } from '@components/notification-block/notification-block.component';
import { Building } from '@app/shared/models/building.interface';
import { ElmtReportsOptionsDialogComponent } from '@app/emergency-lighting/reports/elmt-reports-options-dialog/elmt-reports-options-dialog.component';
import { MatDialogRef } from '@angular/material/dialog';
import { SensorNode } from '@app/shared/models/sensor-node';
import { SensorNodeService } from '@services/sensor-node.service';
import { CancelOnlyDialogData, ConfirmDialogData } from '@components/dialogs/confirm/confirm.component';
import { ConfirmationDialogService } from '@services/confirmation-dialog/confirmation-dialog.service';
import { EmDriverService } from '@services/em-driver.service';
import { ToastService } from '@services/toast/toast.service';
import { Router } from '@angular/router';
import { format } from 'date-fns';
import { AuthorizationModule } from '@app/shared/directives/authorization.module';
import {
  ElmtSelectedFiltersService,
  ReportQuery
} from '@app/emergency-lighting/elmt-tests-filter/elmt-selected-filters.service';
import { EmergencyLightingTestService } from '@services/emergency-lighting/emergency-lighting-test.service';
import { TimeUtils } from '@app/shared/utils/time.utils';
import { TimezoneUtils } from '@app/shared/utils/timezoneUtils';
import { AbstractEDataSource } from '@app/emergency-lighting/reports/emergency-reports-data-source';

type logObject = {
  selected: boolean;
  log: IEmergencyLogGroup;
  pageNumber: number;
};

@Component({
  selector: 'app-elmt-reports-table',
  standalone: true,
  imports: [CustomDatePipe, MatPaginatorModule, MatTableModule, SharedComponentsModule, NotificationBlockComponent],
  templateUrl: './elmt-reports-table.component.html',
  styleUrl: './elmt-reports-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElmtReportsTableComponent implements OnInit {
  logTableColumns = ['scheduleName', 'timeStarted', 'testsType', 'sensorNodeId', 'driverId', 'state', 'message'];
  selectionEnabled = false;
  constructor(
    private sensorNodeService: SensorNodeService,
    private confirmationDialogService: ConfirmationDialogService,
    private emDriverService: EmDriverService,
    private toast: ToastService,
    private router: Router,
    private filterService: ElmtSelectedFiltersService,
    private emergencyLightingTestService: EmergencyLightingTestService
  ) {}
  @Input({ required: true }) building: Building;
  @Input({ required: true }) logsDataSource: AbstractEDataSource<IEmergencyLogGroup>;

  @Input() set enableSelection(value: boolean) {
    this.selectionEnabled = value;
    if (value && !this.logTableColumns.includes('checkbox')) {
      this.logTableColumns.unshift('checkbox');
    }
  }

  @Output() filterLogs = new EventEmitter<{ pageNumber: number; pageSize: number }>();

  @ViewChild('logsPaginator') logsPaginator: MatPaginator;

  @Input() pageSize: number;
  @Input() pageNumber: number;
  @Input() isReport = false;
  @Output() pageNumberChange = new EventEmitter<number>();
  @Output() pageSizeChange = new EventEmitter<number>();

  private readonly DEFAULT_PAGE_NUMBER = 0;
  private readonly DEFAULT_PAGE_SIZE = 20;

  protected readonly StatusClass = StatusClass;
  logsMap = new Map<number, logObject>();
  allLogsSelectedByPage = new Map<number, boolean>();
  indeterminateState = new Map<number, boolean>();
  logOptionsDialogRef: MatDialogRef<ElmtReportsOptionsDialogComponent>;

  ngOnInit(): void {
    this.connectDataSourceAndPopulateHashmap();
  }

  formattedDate(instant: number): string {
    return format(instant, 'dd/MM/yyyy - h:mm a');
  }

  private connectDataSourceAndPopulateHashmap(): void {
    if (this.logsDataSource) {
      this.logsDataSource.connect().subscribe((data) => {
        data.forEach((log, index) => {
          const logMapValue = this.logsMap.get(log.testId) || {
            selected: false,
            log,
            pageNumber: this.pageNumber
          };
          logMapValue.log = log;
          logMapValue.pageNumber = this.pageNumber;
          this.logsMap.set(log.testId, logMapValue);
        });
      });
    }
  }

  isAnyLogSelected(): boolean {
    return Array.from(this.logsMap.values()).some((log) => log.selected);
  }

  protected toggleLogSelection(logId: number): void {
    this.logsMap.set(logId, { ...this.logsMap.get(logId), selected: !this.logsMap.get(logId).selected });

    const logsOnPage = Array.from(this.logsMap.values()).filter((log) => log.pageNumber === this.pageNumber);
    const allSelected = logsOnPage.every((log) => log.selected);
    const someSelected = logsOnPage.some((log) => log.selected);

    this.allLogsSelectedByPage.set(this.pageNumber, allSelected);
    this.indeterminateState.set(this.pageNumber, !allSelected && someSelected);
  }

  openDialog(): void {
    const selectedLogs = Array.from(this.logsMap.entries())
      .filter(([log, logObject]) => logObject.selected === true)
      .map(([log, isSelected]) => this.logsMap.get(log).log);

    const { nodeIds, driverIds } = this.getUniqueNodeIds(selectedLogs);
    const { nodesMap, floorplanLinksMap } = this.createFloorplanLinks(nodeIds);

    this.logOptionsDialogRef = this.confirmationDialogService.emDialogOpen({
      logs: selectedLogs,
      building: this.building,
      nodeIds,
      driverIds,
      nodesMap,
      floorplanLinksMap,
      initiateTest: this.initiateTest.bind(this),
      openFloorplan: this.openFloorplan.bind(this)
    });
  }

  loadLogsFromPaginator(event: PageEvent): void {
    this.pageNumberChange.emit(event.pageIndex);
    if (this.pageSize !== event.pageSize) {
      this.pageSizeChange.emit(event.pageSize);
      this.logsMap.clear();
      this.allLogsSelectedByPage.forEach((value, pageNumber) => {
        this.allLogsSelectedByPage.set(pageNumber, false);
        this.indeterminateState.set(pageNumber, false);
      });
    }
    this.filterLogs.emit({
      pageNumber: event.pageIndex || this.DEFAULT_PAGE_NUMBER,
      pageSize: event.pageSize || this.DEFAULT_PAGE_SIZE
    });
  }

  getStatusDisplay(status: string): string {
    return EmergencyLightingTestState?.fromValue(status)?.display;
  }

  isGroup(index, item): boolean {
    return item.group;
  }

  getElmtScheduleRoute(): string {
    return EMERGENCY_LIGHTING_SCHEDULES_ROUTE.path.replace(':buildingId', this.building?.id?.toString());
  }

  toggleSelectAllLogs(mode?: boolean): void {
    if (mode !== undefined) {
      this.logsMap.forEach((log, id) => {
        this.logsMap.set(id, {
          ...log,
          selected: mode
        });
      });
      this.allLogsSelectedByPage.forEach((value, pageNumber) => {
        this.allLogsSelectedByPage.set(pageNumber, mode);
        this.indeterminateState.set(pageNumber, false);
      });
    } else {
      this.logsMap.forEach((log, id) => {
        if (log.pageNumber === this.pageNumber) {
          this.logsMap.set(id, {
            ...log,
            selected: !this.allLogsSelectedByPage.get(this.pageNumber)
          });
        }
      });
      this.allLogsSelectedByPage.set(this.pageNumber, !this.allLogsSelectedByPage.get(this.pageNumber));
    }
  }

  getStatusColor(state: string): string {
    const status = EmergencyLightingTestState.fromValue(state);
    switch (status) {
      case EmergencyLightingTestState.FAILED:
        return 'red';
      case EmergencyLightingTestState.SCHEDULED:
        return 'purple';
      case EmergencyLightingTestState.SUCCEEDED:
        return 'green';
      case EmergencyLightingTestState.IN_PROGRESS:
        return 'gold';
      case EmergencyLightingTestState.CANCELLED:
        return 'darkorange';
      case EmergencyLightingTestState.TIMED_OUT:
        return 'red';
      case EmergencyLightingTestState.INITIATED:
        return 'blue';
      default:
        return 'black';
    }
  }

  getUniqueNodeIds(logs: IEmergencyLogGroup[]): { nodeIds: number[]; driverIds: number[] } {
    const nodeIds: number[] = [];
    const driverIds: number[] = [];
    logs?.forEach((log) => {
      if (!nodeIds.includes(log.sensorNodeId)) {
        nodeIds.push(log.sensorNodeId);
      }
      if (!driverIds.includes(log.driverId)) {
        driverIds.push(log.driverId);
      }
    });
    return { nodeIds, driverIds };
  }

  createFloorplanLinks(nodeIds: number[]): {
    nodesMap: Map<number, SensorNode>;
    floorplanLinksMap: Map<number, string>;
  } {
    const nodesMap = new Map<number, SensorNode>();
    const floorAndNodes = new Map<number, SensorNode[]>();
    const floorplanLinksMap = new Map<number, string>();

    this.sensorNodeService
      .getNodeDataForElmtLogs({
        buildingId: this.building?.id,
        elmt: true,
        includeUnmapped: false
      })
      .subscribe((nodes: SensorNode[]) => {
        nodes.forEach((node) => {
          if (nodeIds.includes(node.id)) {
            nodesMap.set(node.id, node);
            if (floorAndNodes.has(node.floorId)) {
              floorAndNodes.get(node.floorId).push(node);
            } else {
              floorAndNodes.set(node.floorId, [node]);
            }
          }
        });
        floorAndNodes.forEach((nodes, floorId) => {
          const nodeIds = nodes.map((node) => node.id).join('&n=');
          const link = `/buildings/${this.building?.id}/emergency-lighting/${floorId}/manual-tests?n=${nodeIds}`;
          floorplanLinksMap.set(floorId, link);
        });
      });

    return { nodesMap, floorplanLinksMap };
  }

  initiateTest(testType: string, driverIds: number[]): void {
    const selectedDriversCount = driverIds.length;
    const testEnum = EmergencyLightingTestType.fromValue(testType);
    if (selectedDriversCount > 0) {
      const dialogData = new ConfirmDialogData(
        `Start ${testType.toLowerCase()} ${selectedDriversCount > 1 ? 'tests' : 'test'} on ${selectedDriversCount} ${
          selectedDriversCount > 1 ? ' devices' : ' device'
        }?` +
          '           ' +
          driverIds.join(', '),
        'Confirm Test Start'
      );
      this.confirmationDialogService.open(dialogData).subscribe((confirmed) => {
        if (confirmed) {
          this.emDriverService.startEmergencyLightingTestBatch(this.building?.id, driverIds, testEnum).subscribe({
            next: () => {
              this.toast.info({
                message: 'Request for running tests sent successfully',
                dataCy: 'send-success-toast'
              });
            },
            error: (err) => {
              console.error(err);
              this.toast.error({
                message: `There was some problem in running ${testType.toLowerCase()} tests for selected nodes`,
                autoClose: false,
                dismissible: true,
                dataCy: `send-error-toast`
              });
            }
          });
        }
      });
    }
  }

  openFloorplan(link: string): void {
    this.router.navigateByUrl(link);
  }

  export(): void {
    const filter = this.filterService.getMappedFiltersToReportQuery(this.building?.id);
    if (this.isReport) {
      this.executeExport(filter);
    } else {
      if (filter.scheduleIds?.length !== 1) {
        this.confirmationDialogService.open(
          new CancelOnlyDialogData(
            'Please ensure that you select and apply exactly one schedule from the filters in order to export test result logs',
            'Export Logs'
          )
        );
        return;
      } else {
        if (filter.startDate == null || filter.endDate == null) {
          this.confirmationDialogService
            .open(
              new ConfirmDialogData(
                'No time span is selected. This will result in the entire history of test results for the selected filter to be exported. Do you want to continue?',
                'Export Logs'
              )
            )
            .subscribe({
              next: (confirmed) => {
                if (confirmed) {
                  this.executeExport(filter);
                }
              }
            });
        } else {
          this.executeExport(filter);
        }
      }
    }
  }

  private executeExport(filter: ReportQuery): void {
    if (filter.startDate != null) {
      filter.startDate = this.shiftToUserDate(filter.startDate);
    }

    if (filter.endDate != null) {
      filter.endDate = this.shiftToUserDate(filter.endDate);
    }
    if (this.isReport) {
      filter.latestLogs = true;
    }

    this.emergencyLightingTestService.downloadExport(filter).subscribe((data) => {
      // tslint:disable-next-line:max-line-length
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      const a = document.createElement('a');
      const url = window.URL.createObjectURL(blob);

      a.href = url;
      a.download = this.getFileName(filter.startDate, filter.endDate);
      a.click();
      URL.revokeObjectURL(url);
    });
  }

  private shiftToUserDate(date: Date): Date {
    const userDate = date.valueOf();
    const buildingDate = TimeUtils.convertTimezone(
      date,
      TimezoneUtils.mapUtcToKnownTimezone(this.building.timeZone)
    ).valueOf();
    const shiftedUserDate = userDate + (userDate - buildingDate);
    return new Date(shiftedUserDate);
  }

  private getFileName(startDate: Date, endDate: Date): string {
    let base = `${this.building.name} Emergency Lighting Test ${this.isReport ? 'Report' : 'Logs'} `;

    if (startDate && endDate) {
      const startDateFromBuilding = TimeUtils.convertTimezone(
        startDate,
        TimezoneUtils.mapUtcToKnownTimezone(this.building.timeZone)
      );
      const endDateFromBuilding = TimeUtils.convertTimezone(
        endDate,
        TimezoneUtils.mapUtcToKnownTimezone(this.building.timeZone)
      );
      base += `${format(startDateFromBuilding, 'dd-MM-yyyy')} : ${format(endDateFromBuilding, 'dd-MM-yyyy')}`;
    } else {
      const buildingDate = TimeUtils.convertTimezone(
        new Date(),
        TimezoneUtils.mapUtcToKnownTimezone(this.building.timeZone)
      );
      base += `${format(buildingDate, 'dd-MM-yyyy')}`;
    }
    return base;
  }
}
