@if (building) {
  <div id="map-nodes-view" class="container" cdkDropListGroup>
    <div class="unmapped-nodes-list">
      <app-unmapped-nodes-list
        [buildingId]="building.id"
        (onBlink)="issueBlinkCommand($event)"
        (onScroll)="onScroll($event)">
      </app-unmapped-nodes-list>
      <button
        mat-fab
        [@slideInOut]="showNodeListRefreshBtn"
        matTooltip="Refresh unmapped nodes list"
        matTooltipClass="mat-tooltip font-15"
        matTooltipPosition="above"
        aria-label="Button to refresh unmapped nodes list"
        data-cy="btn-refresh-unmapped-nodes"
        color="primary"
        (click)="refreshUnmappedList()"
        class="refresh-unmapped-list-button">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
    <div class="floorplan-container">
      <app-floorplan
        [isActionsTrayEnabled]="false"
        (onNodeDrop)="nodeDropped($event)"
        (onFloorplanRightClick)="handleFloorClick($event)"
        (onFloorplanDblClick)="handleFloorClick($event)"
        [buildingId]="building.id"
        (onNodeClick)="handleNodeClick($event)">
        @if (nodeClicked$ | async; as context) {
          <ng-container>
            <div class="context-menu-container" [ngStyle]="{ 'top.px': context.y, 'left.px': context.x }">
              <div class="menu-content">
                <div>
                  <button mat-mini-fab class="or-button or-warning" data-cy="unmap-button" (click)="unmapNode(context)">
                    <span class="or-icon or-small or-icon-node-unmap"></span>
                  </button>
                </div>
                @if(isConnected(context)) {
                  <div>
                    <button mat-mini-fab class='or-button or-primary'
                            data-cy='blink-button'
                            [disabled]='context.isPassiveNode'
                            (click)='blinkNode(context)'>
                      <span class='or-icon or-small or-icon-light-level'></span>
                    </button>
                  </div>
                }
              </div>
            </div>
          </ng-container>
        }
      </app-floorplan>
      <button mat-fab
              color="primary"
              matTooltip="Refresh nodes on floorplan"
              matTooltipClass="mat-tooltip font-15"
              matTooltipPosition="above"
              aria-label="Button to refresh nodes in the floorplan"
              (click)="refreshNodesOnFloorplan()"
              class="refresh-floorplan-button"
              data-cy="btn-refresh-nodes-floorplan">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
  </div>
} @else {
  <div class="absolute left-1/2 top-1/2">
    <mat-spinner></mat-spinner>
  </div>
}
