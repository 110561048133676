import { OrFormTokenDetailsController } from './OrFormTokenDetailsController';

export class OrFormTokenDetailsDirective {
  constructor() {
    return {
      bindToController: true,
      controller: ['$scope', OrFormTokenDetailsController],
      controllerAs: 'tokenDetails',
      replace: true,
      restrict: 'E',
      scope: {
        enableLabels: '=',
        isBusy: '=',
        isNew: '=',
        newToken: '=?',
        onAddToken: '&',
        onDeactivateToken: '&',
        onUpdateToken: '&',
        token: '=',
        tenants: '='
      },
      template: require('raw-loader!./or-form-token-details.html').default
    };
  }
}
