import { DdlSetting } from '../../api/building/Ddl';
import { ISimpleService } from '../../api/ISimpleService';
import { SavedEntity } from '../../api/SavedEntity';
import { IResource } from '../../api/IResource';

export class DdlSettingService {
  private endpoints = {
    building: '/api/v1/building',
    setting: '/api/v1/ddl-building-setting'
  };

  constructor(
    private simpleService: ISimpleService<
      DdlSetting,
      DdlSetting,
      DdlSetting,
      void
    >,
    private ddlResource: IResource<DdlSetting, number>,
    private generateApiUrl: (endpoint: string) => string
  ) {}

  public getDdlSetting(buildingId: number): Promise<DdlSetting> {
    return this.simpleService.get(
      this.generateApiUrl(
        `${this.endpoints.building}/${buildingId}/ddl-building-setting`
      )
    );
  }

  public createDdlSetting(ddlSetting: DdlSetting): Promise<DdlSetting> {
    return this.simpleService.post(
      this.generateApiUrl(`${this.endpoints.setting}`),
      ddlSetting
    );
  }

  public updateDdlSetting(
    ddlSetting: DdlSetting,
    settingId: number
  ): Promise<DdlSetting> {
    console.log(ddlSetting, 'ddlSetting', settingId);
    return this.simpleService.put(
      this.generateApiUrl(`${this.endpoints.setting}/${settingId}`),
      ddlSetting
    );
  }
}
