import { Component, Input, OnInit } from '@angular/core';
import { MatCard, MatCardContent, MatCardHeader } from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-notification-block',
  templateUrl: './notification-block.component.html',
  styleUrls: ['./notification-block.component.scss'],
  imports: [CommonModule, MatCard, MatCardHeader, MatCardContent, MatIcon],
  standalone: true
})
export class NotificationBlockComponent implements OnInit {
  @Input() header: string;
  @Input() content: string;
  @Input() width: number;
  @Input() height: number;
  @Input() statusClass: StatusClass = StatusClass.INFO;
  constructor() {}

  ngOnInit(): void {}

  getStatusClass(): Record<string, boolean> {
    switch (this.statusClass) {
      case StatusClass.INFO:
        return { info: true };
      case StatusClass.SUCCESS:
        return { success: true };
      case StatusClass.WARNING:
        return { warning: true };
      case StatusClass.ERROR:
        return { error: true };
    }
  }

  getIcon(): string {
    switch (this.statusClass) {
      case StatusClass.INFO:
        return 'info';
      case StatusClass.SUCCESS:
        return 'check_circle';
      case StatusClass.WARNING:
        return 'warning';
      case StatusClass.ERROR:
        return 'error';
    }
  }
}

export enum StatusClass {
  INFO,
  SUCCESS,
  WARNING,
  ERROR
}
