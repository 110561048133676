import { OrMultiselectController } from './OrMultiselectController';

export class OrMultiselectDirective {
  constructor() {
    return {
      bindToController: true,
      controller: ['$scope', OrMultiselectController],
      controllerAs: 'multiselect',
      replace: true,
      restrict: 'E',
      require: 'ngModel',
      link: (scope, element, attrs, ngModelCtrl) => {
        scope.$watch('multiselect.values', () => ngModelCtrl.$setViewValue(scope.multiselect.values));
      },
      scope: {
        isEditMode: '=',
        originalOptions: '=options',
        values: '=ngModel',
        areEqual: '=?',
        isMandatory: '=?'
      },
      template: require('raw-loader!./or-multiselect.html').default
    };
  }
}
