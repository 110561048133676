import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ManagingCompanyService } from '@app/shared/services/managing-company.service';
import { ManagingCompanyDatasource } from '@app/global-administration/managing-company/managing-company.datasource';
import { ManagingCompany } from '@app/shared/models/building.interface';
import { MatTable } from '@angular/material/table';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
    ManagingCompanyDialogComponent
} from '@app/global-administration/managing-company/managing-company-dialog/managing-company-dialog.component';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-managing-company',
    templateUrl: './managing-company.component.html',
    styleUrls: ['./managing-company.component.scss']
})
export class GlobalAdministrationManagingCompanyComponent implements OnInit, AfterViewInit {

    dataSource: ManagingCompanyDatasource;

    displayedColumns = ['id', 'companyName', 'actions'];

    @ViewChild(MatTable, { static: true }) table: MatTable<any>;

    constructor(private route: ActivatedRoute,
                private managingCompanyService: ManagingCompanyService,
                private dialog: MatDialog) {
    }

    ngAfterViewInit(): void {
    }

    ngOnInit(): void {
        this.dataSource = new ManagingCompanyDatasource(this.managingCompanyService);
        this.dataSource.loadManagingCompanies('asc');
    }

    addManagingCompany(companyName: string): void {
        this.managingCompanyService.saveManagingCompany({ id: null, companyName: companyName}).subscribe({
            next: data => {
                this.dataSource.loadManagingCompanies('asc');
                // TODO: clear the input
            },
            error: error => {
                console.error('There was an error saving managing company', error);
            }
        });
    }

    editManagingCompany(managingCompany: ManagingCompany): void {
        this.openEditManagingCompanyDialog(this.dialog, managingCompany).subscribe(val => {
            managingCompany.companyName = val.companyName;
            this.managingCompanyService.updateManagingCompany(managingCompany).subscribe({
                next: data => {
                    console.log('Update successful');
                },
                error: error => {
                    console.error('There was an error updating the managing company', error);
                }
            });
        });
    }

    deleteManagingCompany(managingCompany: ManagingCompany): void {
        if (!confirm(`Delete managing company named: '${managingCompany.companyName}'?`)) {
            return;
        }

        this.managingCompanyService.deleteManagingCompany(managingCompany.id).subscribe({
            next: data => {
                this.dataSource.loadManagingCompanies('asc');
                // TODO: clear the input
            },
            error: error => {
                console.error('There was an error deleting managing company', error);
            }
        });
    }

    openEditManagingCompanyDialog(dialog: MatDialog, managingCompany: ManagingCompany): Observable<any> {
        const config = new MatDialogConfig();
        config.autoFocus = true;
        config.data = {
            ...managingCompany
        };
        config.width = '600px';
        const dialogRef = dialog.open(ManagingCompanyDialogComponent, config);
        return dialogRef.afterClosed();
    }

}
