import { BuildingNotificatonPreferences } from '../../api/building/BuildingNotificatonPreferences';
import { IBuildingNotificatonResource } from '../../api/resources/IBuildingNotificatonResource';
import { PromiseRejectionHandler } from '../../promises/PromiseRejectionHandler';
import { PromiseUtils } from '../promises/PromiseUtils';

export class BuildingNotificationResource
  implements IBuildingNotificatonResource
{
  constructor(
    private $http: ng.IHttpService,
    private baseUrl: string,
    private rejectionHandler: PromiseRejectionHandler
  ) {}

  public updatePreferences(
    preferences: BuildingNotificatonPreferences
  ): Promise<{}> {
    return PromiseUtils.wrapSingle(
      this.$http.put(this.baseUrl, preferences),
      this.rejectionHandler
    );
  }
}
