import * as angular from 'angular'; // Automatically added
import { Map } from '@angularjs/or/util/Map';
import { ImageCachingService } from '@angularjs/or/services/ImageCachingService';

export class OrImageUploadController {
  public uploadUrl: string;
  public imageUrl: string;
  public fileInput;
  public previewStyle;
  public isBusy: boolean;
  public isDisabled: boolean;
  public imageName: string;
  public onUpload: () => Function;
  public form: any;

  constructor(
    private $http,
    private $element,
    private scope,
    private imageCachingService: ImageCachingService,
    private generateApiUrl: (endpoint: string) => string
  ) {}

  public $onInit(): void {
    this.fileInput = this.$element[0].querySelector('.or-image-upload-input');

    this.fileInput.addEventListener(
      'change',
      () => {
        this.onFileChange();
      },
      false
    );

    this.scope.$watch('imageUpload.imageUrl', () => {
      this.previewStyle = this.producePreviewStyle(this.imageUrl);
      angular.element(this.fileInput).val('');
    });

    // this.imageUrl = this.imageCachingService.updateUrl(this.imageUrl);
    this.previewStyle = this.producePreviewStyle(this.imageUrl);
  }

  private producePreviewStyle(imageUrl): Map<string> {
    const style: Map<string> = {};
    style['background-image'] = imageUrl ? 'url(' + imageUrl + ')' : 'none';
    return style;
  }

  private produceImageUrl(image): string {
    if (!image) {
      return null;
    }
    return `${this.uploadUrl}/${image}`;
  }

  private setImageUrl(image): void {
    this.imageUrl = this.produceImageUrl(image);
    this.previewStyle = this.producePreviewStyle(this.imageUrl);
  }

  private onFileChange(): void {
    if (!this.fileInput.files.length) {
      this.setImageUrl(null);
      return;
    }
    this.isBusy = true;
    this.uploadImage(this.fileInput.files[0])
      .then((response) => {
        this.imageCachingService.update();
        this.imageName = response.data.key;
        this.setImageUrl(this.imageName);
        this.isBusy = false;
        this.onUpload().bind(this.form)(this.imageName);
      })
      .catch((error) => {
        this.fileInput.value = '';
        this.isBusy = false;
        alert(
          'Invalid image: \n * must be of type jpg,png,jpeg\n * filesize must be < 5mb.'
        );
      });
  }

  private uploadImage(imageFile): any {
    let promise;
    const formData = new FormData();
    const fileName = imageFile.name.replace(/[^\w._-]/g, '-');
    formData.append('image', imageFile, fileName);
    promise = this.$http.post(this.generateApiUrl(this.uploadUrl), formData, {
      headers: {
        'Content-Type': undefined
      }
    });
    return promise;
  }
}
