<div class="text-red-500 self-center">
  <i class="material-icons image align-middle">autorenew</i>
  @if (timeLeft$ | async; as t) {
    Refreshing in
    @if(t.minutes > 0 ) {
      <span id="minutes"> {{t.minutes}} min</span>
    }
    @if(t.seconds > 0 ) {
      <span id="seconds"> {{t.seconds}} secs</span>
    }
  }
  @else {
    Refreshing...
  }
</div>