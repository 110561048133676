<div id='orAdministrationTokens'>
  <div class='or-content'>
    <mat-spinner *ngIf='isBusy'></mat-spinner>
    <div class='or-content-body'>
      <ul class='or-stacked-items or-tokens-list'>
        <li
          class='or-stacked-item or-tokens-list-item'
          *ngFor='let token of tokens; let i=index'
        >
          <app-form-token-details [isNew]='false' [building]='building' [token]='token' [roles]='roles' [tenants]='tenants'
                                  (valueUpdated)='loadValues()' [index]='i'></app-form-token-details>
        </li>
        <li class='or-stacked-item or-tokens-list-item'>
          <app-form-token-details [isNew]='true' [building]='building' [roles]='roles' [tenants]='tenants'
                                  (valueUpdated)='loadValues()' [index]='0'></app-form-token-details>
        </li>
      </ul>
      <br>
      <div>
        <app-checkbox [(ngModel)]='isShowDeactivatedTokens' ngDefaultControl
                      (valueChange)='toggleShowDeactivatedTokens()'
                      [dataCy]="'checkbox-show-deactivated-tokens'">
        </app-checkbox>
        <label class='margin-left-10'>Show deactivated tokens</label>
      </div>
      <ul
        class='or-stacked-items or-tokens-list'
        *ngIf='deactivatedTokens?.length > 0'>
        <li
          class='or-stacked-item or-tokens-list-item'
          *ngFor='let deactivatedToken of deactivatedTokens; let i=index'
        >
          <app-form-token-details [isNew]='false' [building]='building' [token]='deactivatedToken' [roles]='roles' [tenants]='tenants'
                                  [isDeactivated]='true' [index]='i'></app-form-token-details>
        </li>
      </ul>
    </div>
  </div>
</div>
