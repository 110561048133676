import * as angular from 'angular'; // Automatically added
export class OrCheckboxController {
  public isActive: boolean;
  public isDisabled: boolean;
  public label: string;
  public onChange: Function;

  public $onInit() {
    if (typeof this.isActive !== 'boolean') {
      this.toggle(false);
    }
  }

  toggle(isActive: boolean) {
    this.isActive = typeof isActive === 'boolean' ? isActive : !this.isActive;
    if (angular.isFunction(this.onChange)) {
      this.onChange();
    }
  }
}
