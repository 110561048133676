import { Component, Input, OnInit } from '@angular/core';
import { IEmergencyLightingSchedule } from '@app/shared/models/emergency-lighting-schedule.interface';
import { TagService } from '@app/shared/services/tag.service';
import { Building } from '@app/shared/models/building.interface';
import { Floor } from '@app/shared/models/floor.interface';
import { DisplayTags, Tag } from '@app/shared/models/tag.interface';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-emergency-lighting-schedules-tile',
  templateUrl: './emergency-lighting-schedules-tile.component.html',
  styleUrls: ['./emergency-lighting-schedules-tile.component.scss']
})
export class EmergencyLightingSchedulesTileComponent implements OnInit {
  @Input() schedule: IEmergencyLightingSchedule;
  @Input() building$: Observable<Building>;
  @Input() tags$: Observable<DisplayTags>;
  isValidTimeRange = true;
  selectedFloors: Floor[] = [];
  selectedTags: Tag[] = [];

  constructor(private tagService: TagService) {}

  ngOnInit(): void {
    this.findSelectedFloors();
    this.findSelectedTags();
  }

  public getStartDate(): number {
    return this.schedule.startDateTime * 1000;
  }

  public getStartTime(): string {
    const startDateTime = new Date(this.schedule.startDateTime * 1000);
    const startTimeHours = ('0' + startDateTime.getHours()).slice(-2);
    const startTimeMinutes = ('0' + startDateTime.getMinutes()).slice(-2);
    return startTimeHours + ':' + startTimeMinutes;
  }

  public getEndTime(): string {
    const endDateTime = new Date(this.schedule.endDateTime * 1000);
    const endTimeHours = ('0' + endDateTime.getHours()).slice(-2);
    const endTimeMinutes = ('0' + endDateTime.getMinutes()).slice(-2);
    return endTimeHours + ':' + endTimeMinutes;
  }

  public decorateTag(tag: Tag): { 'background-color': string } {
    if (!tag) {
      return;
    }
    if (!tag.color) {
      return;
    }
    return this.tagService.decorateTag(tag);
  }

  public findSelectedFloors(): void {
    this.building$.subscribe((building) => {
      building.floors.forEach((floor) => {
        if (this.schedule.floorIds.includes(floor.id)) {
          this.selectedFloors.push(floor);
        }
      });
    });
  }

  public findSelectedTags(): void {
    this.tags$.subscribe((tags) => {
      tags.displayTags.forEach((tag) => {
        if (this.schedule.tagIds.includes(tag.id)) {
          this.selectedTags.push(tag);
        }
      });
    });
  }
}
