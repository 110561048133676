import { InjectionToken } from '@angular/core';

import { ComplexQueryOutline } from './outline/ComplexQueryOutline';
import { LiveQueryOutline } from './outline/LiveQueryOutline';
import { SimpleQueryOutline } from './outline/SimpleQueryOutline';
import { Observable } from 'rxjs';
import { QueryResult } from '@app/analytics/metric-widget/data-objects/query-result';

export const QueryExecutorToken = new InjectionToken<IQueryExecutor>('query-executor');

export interface IQueryExecutor {
  doComplexQuery(outline: ComplexQueryOutline): Observable<QueryResult<number>>;
  doSimpleQuery(outline: SimpleQueryOutline): Observable<QueryResult<number>>;
  doLiveQuery(outline: LiveQueryOutline): Observable<QueryResult<number>>;
  cancelLiveQuery(): void;
}

export class QueryExecutorConfiguration {
  constructor(
    public simpleQueryUrl: string,
    public complexQueryUrl: string,
    public liveQueryUrl: string,
    public liveDataQueryUrl: string
  ) {}
}
