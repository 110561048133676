import { OrSiteMenuController } from './OrSiteMenuController';

export class OrSiteMenuDirective {
  constructor() {
    return {
      bindToController: true,
      controller: ['NavigationService', 'UIRefreshService', 'NewUserService', OrSiteMenuController],
      controllerAs: 'siteMenu',
      replace: true,
      restrict: 'E',
      scope: {},
      template: require('raw-loader!./or-site-menu.html').default
    };
  }
}
