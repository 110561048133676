import { NgModule } from '@angular/core';
import { CircadianCurveListComponent } from './circadian-curve-list/circadian-curve-list.component';
import { CircadianCurveTileComponent } from './circadian-curve-tile/circadian-curve-tile.component';
import { CircadianCurveSummaryComponent } from './circadian-curve-summary/circadian-curve-summary.component';
import { FormCircadianCurveComponent } from './form-circadian-curve/form-circadian-curve.component';
import { SharedComponentsModule } from '@app/shared/shared-components.module';
import { CircadianCurveComponent } from '@app/circadian-curve/circadian-curve.component';

@NgModule({
  imports: [SharedComponentsModule],
  exports: [CircadianCurveComponent],
  declarations: [
    CircadianCurveComponent,
    CircadianCurveListComponent,
    CircadianCurveTileComponent,
    CircadianCurveSummaryComponent,
    FormCircadianCurveComponent
  ]
})
export class CircadianCurveModule {}
