import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, combineLatestWith, Observable, of, tap } from 'rxjs';
import { EmergencyLogService } from '@services/emergency-log.service';
import { EmergencyLogPageFilter, IEmergencyLogGroup } from '@app/shared/resources/emergency-log.resource';
import { SensorNode } from '@app/shared/models/sensor-node';
import { shareReplay } from 'rxjs/operators';
import { UserService } from '@services/user/user.service';
import { mockLogData } from '@app/emergency-lighting/reports/elmt-reports-table/stories/elmt-reports-table.stories-data';

export abstract class AbstractEDataSource<T> implements DataSource<T> {
  protected logsSubject = new BehaviorSubject<IEmergencyLogGroup[]>([]);
  protected loadingSubject = new BehaviorSubject(false);
  protected countSubject = new BehaviorSubject(0);

  get isLoading$(): Observable<boolean> {
    return this.loadingSubject.asObservable();
  }

  get hasData(): boolean {
    return this.countSubject.value > 0;
  }

  get counter$(): Observable<number> {
    return this.countSubject.asObservable();
  }

  abstract connect(): Observable<T[]>;

  abstract disconnect(): void;
}

export class EmergencyReportsDataSource extends AbstractEDataSource<IEmergencyLogGroup> {
  private nodesObservable: Observable<SensorNode[]>;

  constructor(
    private logsService: EmergencyLogService,
    nodesObservable: Observable<SensorNode[]>,
    private userService: UserService
  ) {
    super();
    this.nodesObservable = nodesObservable.pipe(shareReplay({ refCount: true }));
    this.nodesObservable.subscribe(); // Preload the nodes
  }

  loadEmergencyLogs(filterParams: EmergencyLogPageFilter): void {
    this.loadingSubject.next(true);
    this.logsService
      .getLogsForBuilding(filterParams)
      .pipe(combineLatestWith(this.nodesObservable, this.userService.getBuilding(filterParams.filter.buildingId)))
      .subscribe({
        next: (data) => {
          const logs = data[0];
          const nodes = data[1];
          const building = data[2];
          const content = logs.content
            .flatMap(({ scheduleId, tests, scheduleName, timeStarted }) => [
              tests.map((test) => {
                const foundNode = nodes.find((node) => node.id === test.nodeId);
                let sensorNodeLink = '';
                if (foundNode) {
                  sensorNodeLink = `/buildings/${test.buildingId}/emergency-lighting/${foundNode.floorId}/manual-tests?n=${test.nodeId}`;
                }
                return {
                  testId: test.id,
                  timeStarted: timeStarted * 1000,
                  sensorNodeId: test.nodeId,
                  sensorNodeLink,
                  driverId: test.driverId,
                  testsType: test.type,
                  state: test.state,
                  message: test.message,
                  timeZone: building.timeZone,
                  scheduleName: scheduleId == null ? 'Manually Executed' : scheduleName
                };
              })
            ])
            .flat();
          this.logsSubject.next(content);
          this.loadingSubject.next(false);
          this.countSubject.next(logs.totalElements);
        },
        error: (err) => {
          this.loadingSubject.next(false);
          console.error(err);
        }
      });
  }

  connect(): Observable<IEmergencyLogGroup[]> {
    return this.logsSubject.asObservable();
  }

  disconnect(): void {
    this.logsSubject.complete();
    this.loadingSubject.complete();
  }
}

export class MockEmergencyLogDataSource extends AbstractEDataSource<any> {
  connect(): Observable<any> {
    return of(mockLogData);
  }
  disconnect(): void {
    console.log('disconnect');
  }

  get counter$(): Observable<number> {
    return of(mockLogData.length);
  }
}
