import { OrLightGroupListController } from './OrLightGroupListController';

export class OrLightGroupListDirective {
  constructor() {
    return {
      bindToController: true,
      controller: ['$timeout', '$element', 'TagService', 'SensorNodeService', OrLightGroupListController],
      controllerAs: 'lightGroupList',
      replace: true,
      restrict: 'E',
      scope: {
        selectedGroup: '=',
        selectedGroups: '=?',
        activeGroups: '=?',
        archivedGroups: '=?',
        tags: '=',
        floors: '=',
        onArchiveGroup: '&',
        onCreateGroup: '&',
        onClearHighlighting: '&',
        onUpdateGroup: '&',
        onHighlightGroup: '&',
        isBusy: '=',
        isEditable: '=?',
        isMultiselectModeActive: '=',
        showArchivedObservable: '=?'
      },
      template: require('raw-loader!./or-light-group-list.html').default
    };
  }
}
