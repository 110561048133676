<div data-cy="confirm-dialog" class='confirm-dialog'>
  <h2 mat-dialog-title data-cy='dialog-title'>{{ dialogTitle }}</h2>
  <mat-dialog-content class='no-overflow-dialog'>
    <span data-cy='dialog-content-span' [innerHTML]="content"></span>
  </mat-dialog-content>
  <mat-dialog-actions align='end'>
    <div class='flex flex-row-reverse w-1/2 mx-4 mb-2'>
      @if(data.showConfirm) {
        <button mat-raised-button class='w-1/2 mx-2' cdkfocusinitial color="primary" (click)="confirm()" data-cy='dialog-confirm-btn'>Confirm</button>
      }
      <button mat-raised-button class='w-1/2 mx-2' (click)="cancel()" data-cy='dialog-cancel-btn'>Cancel</button>
    </div>
  </mat-dialog-actions>
</div>
