import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BleKey } from '@app/shared/models/ble-key.interface';
import { BleKeyResource } from '@app/shared/resources/ble-key.resource';

@Injectable({
  providedIn: 'root'
})
export class BleKeysService {
  constructor(private bleKeyResource: BleKeyResource) {}

  getAllBleKeysForBuilding(buildingId: number): Observable<BleKey[]> {
    return this.bleKeyResource.getAllKeysForBuilding(buildingId);
  }

  createKey(buildingId: number, key: BleKey): Observable<BleKey> {
    return this.bleKeyResource.createKey(buildingId, key);
  }

  updateKey(buildingId: number, key: BleKey): Observable<void> {
    return this.bleKeyResource.updateKey(buildingId, key);
  }

  sendKey(buildingId: number, key: BleKey): Observable<void> {
    return this.bleKeyResource.sendKey(buildingId, key);
  }
}
