import {MultiInvitationConfirmation, IMultiUserInvitation} from "@app/shared/models/user-invitation.interface";
import {Inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Environment, environmentToken} from "@environment";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class MultiUserInvitationResource {
    private readonly baseUrl: string;

    constructor(private http: HttpClient, @Inject(environmentToken) private environment: Environment) {
        this.baseUrl = `${environment.apiUrl}/auth/invite`;
    }

    public inviteMultiple(multiUserInvitation: IMultiUserInvitation): Observable<MultiInvitationConfirmation> {
        return this.http.post<MultiInvitationConfirmation>(`${this.baseUrl}/multi`, multiUserInvitation);
    }

}