import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InlineTagsComponent } from '@components/inline-tags/inline-tags.component';
import { InlineFloorsComponent } from '@components/inline-floors/inline-floors.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TabsComponent } from '@components/tabs/tabs.component';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { FooterComponent } from '@components/footer/footer.component';
import { AuthorizationModule } from '@app/shared/directives/authorization.module';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { TimerangeWidgetComponent } from '@components/timerange-widget/timerange-widget.component';
import { SimpleWeekdayListComponent } from '@components/simple-weekday-list/simple-weekday-list.component';
import { ImageUploadComponent } from '@components/building/image-upload/image-upload.component';
import { MatCardModule } from '@angular/material/card';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CustomToastComponent } from '@components/toast/custom-toast/custom-toast.component';
import { CdkDrag, CdkDropList } from '@angular/cdk/drag-drop';
import { AbstractUnmappedNodesDataSource, UnmappedNodesDataSource } from '@app/shared/models/unmapped-nodes-datasource';
import { MappingService } from '@services/mapping.service';
import { AbstractNodeDataSource, NodeDataSource } from '@app/shared/models/node-data-source';
import { SensorNodeService } from '@services/sensor-node.service';
import { CopyToClipboardService } from '@services/copy-to-clipboard.service';
import { MatMenuModule } from '@angular/material/menu';
import { MatSortModule } from '@angular/material/sort';

/**
 * Use this module to export any shared components. Header component is already shared
 * in the entire app
 */
@NgModule({
  imports: [
    AuthorizationModule,
    CdkDrag,
    CdkDropList,
    CommonModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTableModule,
    MatTabsModule,
    MatTabsModule,
    MatTooltipModule,
    MatTooltipModule,
    MatMenuModule,
    MatSortModule,
    ReactiveFormsModule,
    RouterModule
  ],
  declarations: [
    CustomToastComponent,
    FooterComponent,
    ImageUploadComponent,
    InlineFloorsComponent,
    InlineTagsComponent,
    SimpleWeekdayListComponent,
    TabsComponent,
    TimerangeWidgetComponent
  ],
  exports: [
    CommonModule,
    FooterComponent,
    FormsModule,
    ImageUploadComponent,
    InlineFloorsComponent,
    InlineTagsComponent,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    MatMenuModule,
    MatSortModule,
    ReactiveFormsModule,
    SimpleWeekdayListComponent,
    TabsComponent,
    TimerangeWidgetComponent
  ],
  providers: [
    MatDatepickerModule,
    {
      provide: AbstractUnmappedNodesDataSource,
      useFactory: (mappingService: MappingService) => new UnmappedNodesDataSource(mappingService),
      deps: [MappingService]
    },
    {
      provide: AbstractNodeDataSource,
      useFactory: (snService: SensorNodeService, copyToClipboardService: CopyToClipboardService) =>
        new NodeDataSource(snService, copyToClipboardService),
      deps: [SensorNodeService, CopyToClipboardService]
    }
  ]
})
export class SharedComponentsModule {}
