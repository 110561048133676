import { EmergencyLightingTestState } from '../../data/EmergencyLightingTestState';
import { EmergencyLightingTestType } from '../../data/EmergencyLightingTestType';
import { EmergencyLightingTestPowerSupplyStatus } from '../../data/EmergencyLightingTestPowerSupplyStatus';

export class EmergencyLightingTest {
  constructor(
    public id: number,
    public type: EmergencyLightingTestType,
    public driverId: number,
    public sensorNodeId: number,
    public state: EmergencyLightingTestState,
    public powerSupplyStatus: EmergencyLightingTestPowerSupplyStatus,
    public message: string,
    public notes: string,
    public timeStarted: number,
    public timeFinished: number,
    public timeScheduled: number,
    public hasSiblings: boolean
  ) {}

  public static clone(other: EmergencyLightingTest): EmergencyLightingTest {
    return new EmergencyLightingTest(
      other.id,
      other.type
        ? EmergencyLightingTestType.fromValue(other.type.toString())
        : null,
      other.driverId,
      other.sensorNodeId,
      other.state
        ? EmergencyLightingTestState.fromValue(other.state.toString())
        : null,
      other.powerSupplyStatus
        ? EmergencyLightingTestPowerSupplyStatus.fromValue(
            other.powerSupplyStatus.toString()
          )
        : null,
      other.message,
      other.notes,
      other.timeStarted,
      other.timeFinished,
      other.timeScheduled,
      other.hasSiblings
    );
  }

  public static cloneArray(
    other: EmergencyLightingTest[]
  ): EmergencyLightingTest[] {
    return other.map((item) => EmergencyLightingTest.clone(item));
  }
}

export class EmergencyLightingTestOutline {
  constructor(public notes: string) {}
}
