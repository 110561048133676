import { Injectable, OnDestroy } from '@angular/core';
import { EmergencyLightingTestType } from '@app/shared/models/emergency-lighting-test-type';
import { EmergencyLightingTestState } from '@app/shared/models/emergency-lighting-test-state';

interface FilterCriteria {
  schedule: number[];
  floor: number[];
  tag: number[];
  testType: EmergencyLightingTestType[];
  testStatus: EmergencyLightingTestState[];
  startDateUTC: Date | null;
  endDateUTC: Date | null;
}

export interface ReportQuery {
  buildingId: number;
  scheduleIds: number[];
  floorIds: number[];
  tagIds: number[];
  testTypes: EmergencyLightingTestType[];
  testStates: EmergencyLightingTestState[];
  startDate: Date | null;
  endDate: Date | null;
  latestLogs: boolean | null;
}

@Injectable()
export class ElmtSelectedFiltersService implements OnDestroy {
  private selectedFilters: FilterCriteria = {
    schedule: [],
    floor: [],
    tag: [],
    testType: [],
    testStatus: [],
    startDateUTC: null,
    endDateUTC: null
  };

  ngOnDestroy(): void {
    this.clearFilters();
  }

  setFilters(filters: FilterCriteria): void {
    this.selectedFilters = filters;
  }

  clearFilters(): void {
    this.selectedFilters = {
      schedule: [],
      floor: [],
      tag: [],
      testType: [],
      testStatus: [],
      startDateUTC: null,
      endDateUTC: null
    };
  }

  getFilters(): FilterCriteria {
    return this.selectedFilters;
  }

  getMappedFiltersToReportQuery(buildingId?: number): ReportQuery {
    const { schedule, floor, tag, testType, testStatus, startDateUTC, endDateUTC } = this.selectedFilters;
    return {
      buildingId,
      scheduleIds: schedule,
      floorIds: floor,
      tagIds: tag,
      testTypes: testType,
      testStates: testStatus,
      startDate: startDateUTC,
      endDate: endDateUTC,
      latestLogs: false
    };
  }
}
