import { Component, Input, OnInit } from '@angular/core';
import { User } from '@app/shared/models/user.interface';
import { HeaderService } from '@app/shared/services/header.service';
import { NavigationSection } from '@app/shared/services/navigation/navigation-section';
import { UserService } from '@app/shared/services/user/user.service';
import { concatMap, Observable, of } from 'rxjs';
import { Building } from '@app/shared/models/building.interface';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() menuItems: NavigationSection[];

  public siteMenuActive$: Observable<boolean>;
  public userMenuActive$: Observable<boolean>;
  public floorsMenuActive$: Observable<boolean>;
  public buildingsMenuActive$: Observable<boolean>;
  public sessionMenuActive$: Observable<boolean>;

  public user$: Observable<User> = of(null);
  public building$: Observable<Building> = of(null);

  constructor(private headerService: HeaderService, private userService: UserService, private route: ActivatedRoute) {}

  public ngOnInit(): void {
    this.siteMenuActive$ = this.headerService.siteMenuActive$;
    this.userMenuActive$ = this.headerService.userMenuActive$;
    this.floorsMenuActive$ = this.headerService.floorsMenuActive$;
    this.buildingsMenuActive$ = this.headerService.buildingsMenuActive$;
    this.sessionMenuActive$ = this.headerService.sessionMenuActive$;

    this.user$ = this.userService.getCurrentUser();
    this.building$ = this.route.params.pipe(
      concatMap((params) => {
        const { buildingId } = params;
        return buildingId ? this.userService.getBuilding(buildingId) : of(null);
      })
    );
  }
}
