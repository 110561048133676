import { OrPanelService } from './or-panel-service/OrPanelService';

export class OrPanelController {
  public iconClass: string;
  public isActive: boolean;
  public isAlwaysActive: boolean;
  public isSolid: boolean;
  public panelId: string;
  public panelName: string;
  public isMain: boolean;
  public isPrintable: boolean;
  public reportToCsv: string;
  public isExclusive: boolean;

  constructor(private orPanelService: OrPanelService) {}

  public $onInit(): void {
    if (this.isExclusive == null || this.isExclusive == false) {
      this.isActive = this.isActive || true;
      this.isExclusive = false;
    } else if (this.isActive == null) {
      this.isActive = true;
    }
    this.orPanelService.setPanel(this);
  }
}
