<div class='overflow-scroll max-h-svh'>
  <div class="p-6 text-lg text-gray-700">
    <div class='flex flex-row justify-between'>
      <h1 class='text-2xl font-bold my-4'>Privacy Notice</h1>
      @if(!showActions) {
        <button mat-icon-button (click)='close()'><mat-icon>close</mat-icon></button>
      }
    </div>
    <p class='my-4'>
      As many aspects of lighting are becoming increasingly digital, the
      processing of personal data is often essential in order to deliver our
      services to our customers and to administrate user accounts. Within the
      Fagerhult Group, we endeavor to protect your privacy and it is important
      for us to ensure that your personal data is being processed in a
      transparent, secure and lawful manner. This privacy notice is developed to
      handle the connected lighting control solutions sold within the Fagerhult
      Group and your rights as data subject under Data Protection Regulation
      2016/679 (“GDPR”).
    </p>
    <h2 class='text-xl font-bold my-4'>Accountable company</h2>
    <p class='my-4'>
      The company accountable for processing your personal data according to
      this Privacy Notice is the sales company within the Fagerhult Group that
      delivers the service to you. Any reference in this Privacy Notice to
      Fagerhult Group is a reference to the sales company providing the service.
      Since there are many sales companies providing our services in different
      markets, please contact your local sales representative if you have any
      questions or concerns regarding this information, or if you want to use
      any of your rights as registered.
    </p>
    <h2 class='text-xl font-bold  my-4'>Purpose and legal ground</h2>
    <p class='my-4'>
      The purpose of the processing of personal data is to administrate user
      accounts and to limit access to appointed users. The processing involves
      both the registration of data connected to your account but also
      processing, to the extent it is necessary when providing service support.
    </p>
    <p class='my-4'>
      The processing is based on our legitimate interest of us to fulfill our
      commitments to our customers who have ordered the service and appointed
      you as a user.<br/>
      For Organic Response solutions, the account information is
      collected from LinkedIn. For that transfer, LinkedIn has specifically
      asked for your permission which is administrated separately when you log
      into the service for the first time.
    </p>
    <p class='my-4'>
      The accountable company (Fagerhult Group Sales Company) is hereby
      authorized by the Customer (end-user) to use and process collected data
      (anonymously) for analysis and further development of the service.
    </p>
    <h2 class='text-xl font-bold my-4'>Recipients of personal data</h2>
    <p class='my-4'>
      Your personal data will only be transferred to companies within the
      Fagerhult Group and to cooperation partners that supply IT services such
      as cloud services or other support services for your connected lighting
      control solution. These cooperation partners are only to receive the data
      necessary in order to fulfill our obligations towards our customers.
    </p>
    <h2 class='text-xl font-bold my-4'>Cookie Usage</h2>
    <p class='my-4'>
      We use cookies and similar technologies to improve your experience and
      enhance the functionality of our website. These cookies are essential
      for the proper functioning of our site.
    </p>
    <p class='my-4'>
      By continuing, you consent to the use of cookies and similar technologies
      as described herein. Please note that you cannot manage cookie preferences at this time.
    </p>
    <h2 class='text-xl font-bold my-4'>Transfer to third countries</h2>
    <p class='my-4'>
      The Fagerhult Group has a global presence. This means that even though we
      endeavor to avoid unnecessary transfer of data to companies or countries
      outside EU/EEA, it is sometimes necessary in order for us to fulfill our
      commitments to our customers. When such transfers have been deemed
      necessary, such transfers are to be carried out in compliance with
      applicable data protection laws and we have taken appropriate safeguards
      to protect your personal data. For that purpose, Fagerhult Group has
      signed Standard contractual clauses with sub-processors. In order to get
      a copy, please contact your local sales representative for the solution
      you use.
    </p>
    <h2 class='text-xl font-bold my-4'>Retention and storage</h2>
    <p class='my-4'>
      Your personal data are stored as long as you are acting as a user of the
      service. Your personal data may thereafter be kept for a limited time
      after you terminated our services, but never longer than applicable
      legislation or case law allows for.
    </p>
    <h2 class='text-xl font-bold my-4'>Your rights as registered</h2>
    <p class='my-4'>
      When we process your personal data you have certain rights as a data
      subject. Depending on the circumstances in the specific case, you may have
      the right to:
    </p>
    <ul class='list-disc mx-8'>
      <li>Rectify inaccurate personal data.</li>
      <li>Obtain a copy of the personal data undergoing processing.</li>
      <li>
        Receive collected personal data, structured in a commonly used and
        machine-readable format.
      </li>
      <li>Require to restrict the processing of personal data.</li>
      <li>
        Erasure of personal data does only apply if we do not have any other
        legal right to continue to process the personal data.
      </li>
    </ul>
    <p class='my-4'>
      You may at any time have the right to lodge a complaint with a supervisory
      authority.
    </p>
    <p class='my-4'>
      This privacy notice was last updated on July 2023, and the most current
      version of this notice will govern our use of your data.
    </p>
  </div>
  <div class='flex flex-col sm:flex-row-reverse justify-center'>
    @if (showActions) {
      <button mat-flat-button class="m-2 sm:basis-1/4" color='primary' (click)="accept()">Accept</button>
      <button mat-flat-button class="m-2 sm:basis-1/4" color='warn' (click)="decline()">Decline</button>
    }
  </div>
</div>
