import { Component, OnInit } from '@angular/core';
import { RoleService } from '@services/role.service';
import { concatMap, Observable } from 'rxjs';
import { Building } from '@app/shared/models/building.interface';
import { UserService } from '@services/user/user.service';
import { ActivatedRoute } from '@angular/router';
import { Role } from '@app/shared/models/role.interface';
import { ToastService } from '@services/toast/toast.service';
import { BuildingAuthorityType } from '@app/shared/models/building-authority-type';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {
  roles: Role[] = [];
  authorityTypesForBuildingServiceLevel: BuildingAuthorityType[] = [];
  building: Building;
  isBusy = true;
  // randomNum;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private roleService: RoleService,
    private toast: ToastService
  ) {}

  ngOnInit(): void {
    this.loadDetails();
  }

  loadDetails(): void {
    // this.randomNum = Math.random();
    this.getSelectedBuilding().subscribe({
      next: (selectedBuilding: Building) => {
        this.building = selectedBuilding;
        this.loadAuthorityTypesForBuildingServiceLevel(selectedBuilding);
        this.loadRoles(selectedBuilding);
      },
      error: () =>
        this.toast.error({
          message: 'Failed to load the building details',
          dataCy: 'load-error-toast'
        })
    });
  }

  loadRoles(selectedBuilding: Building): void {
    this.roleService.getAllForBuilding(selectedBuilding.id).subscribe({
      next: (res) => {
        this.roles = res;
        this.isBusy = false;
      },
      error: () =>
        this.toast.error({
          message: `Failed to load roles for the building: ${selectedBuilding.name}`,
          dataCy: 'load-error-toast'
        })
    });
  }

  loadAuthorityTypesForBuildingServiceLevel(selectedBuilding: Building): void {
    this.roleService.getAuthorityTypesForBuildingServiceLevel(selectedBuilding.id).subscribe({
      next: (res) => {
        this.authorityTypesForBuildingServiceLevel = res;
        this.isBusy = false;
      },
      error: () =>
        this.toast.error({
          message: `Failed to load (service level) authority types for the building: ${selectedBuilding.name}`,
          dataCy: 'load-error-toast'
        })
    });
  }

  getSelectedBuilding(): Observable<Building> {
    return this.route.params.pipe(
      concatMap((params: any) => {
        return this.userService.getBuilding(params.buildingId);
      })
    );
  }
}
