import { SensorNode } from '@app/shared/models/sensor-node';
import { SensorNodeChangeHistory } from '@app/shared/models/sensor-node-change-history';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SensorNodeChangeHistoryService {
  private nodeChangeHistories: Map<number, SensorNodeChangeHistory>[] = [];

  constructor() {}

  startRecSensorNodeChangeHistory(nodes: SensorNode[]): void {
    const historyList: Map<number, SensorNodeChangeHistory> = new Map();
    nodes.forEach((node) => {
      historyList.set(node.id, new SensorNodeChangeHistory(node));
    });

    if (historyList.size > 0 && historyList !== this.getLast()) {
      this.nodeChangeHistories.push(historyList);
    }
  }

  stopRecSensorNodeChangeHistory(): void {
    const node = this.getLast();
    if (node && node.size < 0) {
      this.nodeChangeHistories.pop();
    }
  }

  clearSensorNodeChangeHistory(): void {
    this.nodeChangeHistories.length = 0;
  }

  getLast(): Map<number, SensorNodeChangeHistory> {
    if (this.nodeChangeHistories.length === 0) {
      return null;
    }
    return this.nodeChangeHistories[this.nodeChangeHistories.length - 1];
  }

  undo(): void {
    const map = this.getLast();
    if (!map || map.size === 0) {
      return;
    }
    map.forEach((item) => {
      item.undo();
    });
    this.nodeChangeHistories.pop();
  }

  undoAll(): void {
    while (this.nodeChangeHistories.length > 0) {
      this.undo();
    }
  }

  total(): number {
    return this.nodeChangeHistories.length;
  }
}
