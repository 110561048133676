import { Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedComponentsModule } from '@app/shared/shared-components.module';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { AbstractNodeDataSource } from '@app/shared/models/node-data-source';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NodeFilter, NodesFilterComponent } from '@components/node-list/nodes-filter/nodes-filter.component';
import { SensorNode } from '@app/shared/models/sensor-node';
import { HttpClientModule } from '@angular/common/http';
import { SensorNodeService } from '@services/sensor-node.service';
import { ColorUtils } from '@app/shared/utils/color.utils';

interface SelectableNode extends SensorNode {
  selected: boolean;
  expanded: boolean;
}

@Component({
  selector: 'app-node-list',
  templateUrl: './node-list.component.html',
  styleUrl: './node-list.component.scss',
  standalone: true,
  imports: [CommonModule, SharedComponentsModule, MatSortModule, NodesFilterComponent, HttpClientModule],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class NodeListComponent implements OnInit, OnDestroy {
  tableColumns: string[] = [
    'nodeStatus',
    'id',
    'groupId',
    'address',
    'burnHours',
    'energyConsumption',
    'bleScanning',
    'devices'
  ];
  subTableColumns: string[] = ['type', 'id', 'address', 'burnHours', 'energyConsumption'];
  tagColumns: string[] = ['sam'];
  expandedElement: SelectableNode;

  public filter: Record<string, string> = {};

  @ViewChildren('innerTables') innerTables: QueryList<MatTable<any>>;

  @ViewChildren('mainTableRows', { read: ElementRef }) mainTableRows!: QueryList<ElementRef<HTMLTableRowElement>>;

  constructor(
    protected fb: FormBuilder,
    protected dataSource: AbstractNodeDataSource,
    protected nodeService: SensorNodeService
  ) {}
  showHidden = true;

  @Input() buildingId: number;
  @Input() floorId: number;

  nodes: any[] = [];

  @Input()
  sortByFields: Record<string, string> = {
    id: 'Id',
    address: 'Address',
    installedAt: 'Time Since Installation'
  };

  filterForm: FormGroup;

  ngOnInit(): void {
    this.filterForm = this.fb.group({
      id: '',
      address: '',
      sortBy: Object.keys(this.sortByFields)[0],
      sortAsc: true,
      nodeType: ''
    });
    this.dataSource.loadSensorNodes();
    this.enableScrollTo();
  }

  ngOnDestroy(): void {
    this.dataSource.collapseAll();
  }

  getPropValForLuminaire(nodeInfo: SelectableNode, property: string): string {
    if (Array.isArray(nodeInfo.luminaireDrivers) && nodeInfo.luminaireDrivers.length > 0) {
      if (nodeInfo.luminaireDrivers[0][property] == null) {
        return 'N/A';
      }
      return nodeInfo.luminaireDrivers[0][property].toFixed(0).toString();
    }
    return 'N/A';
  }

  copyToClipboard(): void {
    this.dataSource.copyNodes();
  }

  toggleRow(row: SelectableNode): void {
    const currentRowRef = this.mainTableRows.find((r) => r.nativeElement.id === row.id.toString());
    currentRowRef.nativeElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
    this.expandedElement = this.expandedElement === row ? null : row;
    this.dataSource.toggleExpand(row);
    this.nodeService.toggleEntitySelection(row);
  }

  getNodeStatusAndTypeClass(node: SelectableNode): string {
    const styleClass = 'or-node-tile-status-body or-node-tile-status-body-' + (node.status || 'unknown');
    switch (node.nodeType) {
      case 'SN3':
        return styleClass;
      case 'HIM84':
        return styleClass + ' or-icon-him84 p-2';
      case 'PN':
        return styleClass + ' passive-node';
      default:
        return styleClass;
    }
  }

  onFilterChanged(nodeFilter: NodeFilter): void {
    this.dataSource.loadSensorNodes(nodeFilter.filterCriteria, nodeFilter.sortCriteria);
  }

  refreshNodeList(): void {
    this.dataSource.collapseAll();
    this.dataSource.fetchSensorNodes(this.floorId, this.buildingId);
  }

  public getForegroundColor(bgColor: string): any {
    return {
      'background-color': '#' + bgColor,
      color: ColorUtils.getConstrastingGrey(bgColor)
    };
  }

  getProperValue(column: string, input: any): any {
    return input == null ? 'N/A' : column === 'energyConsumption' ? input.toFixed(0).toString() + 'KWh' : input;
  }

  getDataCy(column: string, input: any): string {
    if (column === 'id') {
      return 'subnode-id-' + input;
    } else if (column === 'address') {
      return 'subnode-address-' + input;
    }
  }

  enableScrollTo(): void {
    this.nodeService.getCurrentFloorSelectedEntities$().subscribe((sNodes) => {
      if (sNodes && sNodes.length > 0) {
        const selectedElement = this.mainTableRows.find((r) => r.nativeElement.id === sNodes[0].id.toString());
        if (selectedElement) {
          selectedElement.nativeElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }
      }
    });
  }

  bleScanningClass(node: SelectableNode): string {
    const styleClass = 'or-node-tile-value or-tiny ';
    if (node.bleScanning === 'ON' || node.nodeType === 'PN') {
      return styleClass + 'or-icon-ble-status-on';
    } else if (node.bleScanning == null) {
      return styleClass + 'or-icon-ble-status-unknown';
    } else {
      return styleClass + 'or-icon-ble-status-off';
    }
  }
}
