<ul
  data-cy="floorplan-zoom-control-container"
  class="or-floorplan-zoom-control"
  (mouseenter)="toggleSuppressFloorplan(true)"
  (mouseleave)="toggleSuppressFloorplan(false)"
>
  <li>
    <button class="or-button" data-cy="increase" (click)="increase()" (touchend)="increase()" [disabled]="!(canIncrease$ | async)">
      <span class="or-icon or-small or-icon-plus"></span>
    </button>
  </li>
  <li>
    <button class="or-button"
      data-cy="resetAndRecenter"
      (click)="resetAndRecenter()"
      (touchend)="resetAndRecenter()"
      [disabled]="isResetDisabled$() | async"
    >
      <span class="or-icon or-small or-icon-fit"></span>
    </button>
  </li>
  <li>
    <button class="or-button" data-cy="decrease" (click)="decrease()" (touchend)="decrease()" [disabled]="!(canDecrease$ | async)">
      <span class="or-icon or-small or-icon-minus"></span>
    </button>
  </li>
</ul>
