import * as ng from 'angular';
import { UserController } from './UserController';
import { NavigationService } from '@app/shared/services/navigation/navigation.service';
import { NavigationSectionInfo } from '@app/shared/services/navigation/navigation-section-info';
import { NavigationSection } from '@app/shared/services/navigation/navigation-section';

export class UserConfig {
  private static ROUTE: string;

  private static routeProvider($routeProvider: ng.route.IRouteProvider) {
    $routeProvider.when(UserConfig.ROUTE, {
      template: require('raw-loader!./user.html').default,
      controller: ['BuildingService', UserController],
      controllerAs: 'user'
    });
  }

  static run(
    navigationService: NavigationService,
    navigationServiceInfo: NavigationSectionInfo
  ) {
    navigationService.addSectionToSiteMap(
      new NavigationSection(navigationServiceInfo, () => '/user')
    );
  }

  static configure(route: string) {
    this.ROUTE = route;
    ng.module('portal')
      .config(['$routeProvider', this.routeProvider])
      .run(['NavigationService', 'UserNavigationInfo', this.run]);
  }
}
