import { OrFormRoleDetailsController } from './OrFormRoleDetailsController';

export class OrFormRoleDetailsDirective {
  constructor() {
    return {
      bindToController: true,
      controller: ['$scope', OrFormRoleDetailsController],
      controllerAs: 'roleDetails',
      replace: true,
      restrict: 'E',
      scope: {
        enableLabels: '=',
        isBusy: '=',
        isNew: '=',
        newRole: '=?',
        onAddRole: '&',
        onDeleteRole: '&',
        onUpdateRole: '&',
        role: '='
      },
      template: require('raw-loader!./or-form-role-details.html').default
    };
  }
}
