import { Component, OnInit, Input } from '@angular/core';
import { OtapUpgradeTaskDTO } from '@app/shared/models/otap-upgrade-task.interface';
import { OtapUpgradeDTO } from '@app/shared/models/otap.upgrade.interface';

@Component({
  selector: 'app-job-tasks',
  templateUrl: './job-tasks.component.html',
  styleUrls: ['./job-tasks.component.scss']
})
export class JobTasksComponent {
  @Input() tasks: OtapUpgradeTaskDTO[];
  @Input() job: OtapUpgradeDTO;
  @Input() jobStartEnabled: boolean;

  constructor() {}
}
