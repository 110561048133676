export class OrSimpleWeekdayListController {
  public isEditable: boolean;
  public shortLabels: boolean;
  public weekdays;

  private weekdayLabels = {
    mon: { long: 'Monday', short: 'Mon' },
    tue: { long: 'Tuesday', short: 'Tue' },
    wed: { long: 'Wednesday', short: 'Wed' },
    thu: { long: 'Thursday', short: 'Thu' },
    fri: { long: 'Friday', short: 'Fri' },
    sat: { long: 'Saturday', short: 'Sat' },
    sun: { long: 'Sunday', short: 'Sun' }
  };

  constructor() {}

  public produceLabel(weekday) {
    const labelFormat = this.shortLabels ? 'short' : 'long';
    return this.weekdayLabels[weekday][labelFormat];
  }

  public toggleDay(weekday) {
    if (!this.isEditable) {
      return;
    }
    this.weekdays[weekday] = !this.weekdays[weekday];
  }
}
