import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CalendarView } from 'angular-calendar';
import { combineLatestWith, Subject, switchMap } from 'rxjs';
import {
    endOfDay,
    endOfMonth,
    endOfWeek,
    isSameDay,
    isSameMonth,
    startOfDay,
    startOfMonth,
    startOfWeek
} from 'date-fns';
import { HttpClient } from '@angular/common/http';
import { EmergencyCalendarService } from '@app/shared/services/emergency-calendar.service';
import {
    EmergencyCalendarEvent,
    IEmergencyCalendarEvent
} from '@app/shared/resources/emergency-calendar-event.resource';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UserService } from '@app/shared/services/user/user.service';

@Component({
    selector: 'app-emergency-lighting-calendar',
    templateUrl: './emergency-lighting-calendar.component.html',
    styleUrls: ['./emergency-lighting-calendar.component.scss']
})
export class GlobalAdministrationEmergencyLightingCalendarComponent implements OnInit {

    view: CalendarView = CalendarView.Month;

    CalendarView = CalendarView;

    viewDate: Date = new Date();

    refresh = new Subject<void>();

    events: IEmergencyCalendarEvent[] = [];

    activeDayIsOpen = false;
    isToday = true;

    constructor(private http: HttpClient,
                private router: Router,
                private cdr: ChangeDetectorRef,
                private buildingService: UserService,
                private emergencyCalendarService: EmergencyCalendarService) {}

    ngOnInit(): void {
        this.fetchScheduleRuns();
    }

    private fetchScheduleRuns(): void {
        let start;
        let end;
        if (this.view === CalendarView.Month) {
            start = startOfMonth(this.viewDate);
            end = endOfMonth(this.viewDate);
        } else if (this.view === CalendarView.Week) {
            start = startOfWeek(this.viewDate);
            end = endOfWeek(this.viewDate);
        } else if (this.view === CalendarView.Day) {
            start = startOfDay(this.viewDate);
            end = endOfDay(this.viewDate);
        }

        this.emergencyCalendarService.getDistinctBuildingIdsWithSchedules().pipe(
            switchMap(buildingIds => {
                return this.buildingService.getBuildings(buildingIds).pipe(
                    map(buildings => buildings.reduce((acc, currentValue) => {
                        acc[currentValue.id] = currentValue;
                        return acc;
                    }, {})),
                    combineLatestWith(this.emergencyCalendarService.getScheduleRunsForBuildingBetween
                    (buildingIds, start.getTime(), end.getTime())),
                    map(([buildingsMap, scheduleRuns]) => {
                        return scheduleRuns.map(sr => {
                                return EmergencyCalendarEvent
                                    .from({...sr, buildingName: buildingsMap[sr.buildingId].name});
                            });
                    })
                );
            })
        ).subscribe({
            next: events => this.events = events,
            error: (err) => {
                console.error(err);
            }
        });
    }

    setView(view: CalendarView): void {
        this.view = view;
        this.fetchScheduleRuns();
    }

    viewDateChanged(event): void {
        this.isToday = new Date().toDateString() === event.toDateString();

        this.fetchScheduleRuns();
    }

    dayClicked({ date, events }: { date: Date; events: IEmergencyCalendarEvent[] }): void {
        if (isSameMonth(date, this.viewDate)) {
            this.viewDate = date;
        }

        if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0) {
            this.activeDayIsOpen = false;
        } else {
            this.activeDayIsOpen = true;
        }

        this.isToday = new Date().toDateString() === date.toDateString();
    }

    handleEvent(calendarEvent: IEmergencyCalendarEvent): void {
        this.router.navigateByUrl(`/buildings/${calendarEvent.buildingId}/emergency-lighting/schedules?scheduleId=${calendarEvent.scheduleId}`);
        // this.router.navigate([`/buildings/${calendarEvent.buildingId}/emergency-lighting/schedules`],
        //   {
        //       replaceUrl: true,
        //       queryParams: {scheduleId: calendarEvent.scheduleId }
        //   }).then(_ => console.log('navigation was successful'));
    }
}
