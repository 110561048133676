import { Inject, Injectable } from '@angular/core';
import { Resource } from '@app/shared/resources/resource';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Environment, environmentToken } from '@environment';
import { Observable } from 'rxjs';
import { IUserInvitation } from '@app/shared/models/user-invitation.interface';
import { IUserInvitationResource } from '@app/shared/resources/user-invitation.interface';

@Injectable({
    providedIn: 'root'
})
export class UserInvitationResource extends Resource<IUserInvitation, number> implements IUserInvitationResource {
    constructor(private http: HttpClient, @Inject(environmentToken) private environment: Environment) {
        super(http, `${environment.apiUrl}/auth/invite`);
    }

    getAll(buildingId, filter, pageNumber, pageSize): Observable<any> {
        return this.http.get(`${this.baseUrl}/${buildingId}/findPaginated`, {
            params: new HttpParams()
              .set('filter', filter)
              .set('page', pageNumber.toString())
              .set('size', pageSize.toString())
        });
    }

    refreshInviteForBuilding(buildingId: number, invitationToken: string): Observable<IUserInvitation> {
        return this.http.put<IUserInvitation>(`${this.baseUrl}/${buildingId}/refresh/${invitationToken}`, {});
    }

    deleteInvitationForBuilding(buildingId: number, invitationToken: string): Observable<{}> {
        return this.http.delete(`${this.baseUrl}/${buildingId}/delete/${invitationToken}`);
    }
}
