import * as angular from 'angular'; // Automatically added
import {
  Gateway,
  GatewayType,
  LogLevel,
  NetworkType,
  WirepasVersion
} from '@angularjs/or/api/building/Gateway';
import { GatewayService } from '@angularjs/or/services/GatewayService';
import { Action } from '@angularjs/or/delegates/Delegates';
import {
  GatewayHealth,
  GatewayHealthService
} from '@angularjs/or/services/GatewayHealthService';
import IScope = angular.IScope;
import IFormController = angular.IFormController;

export class OrFormGatewayDetailsController {
  public isNew: boolean;
  public isBusy: boolean;
  public isViewEdit = false;
  public gatewayHealth: GatewayHealth;
  public details: Gateway;
  public startBusy: Action;
  public endBusy: Action;
  public reload: Action;
  public onReset: () => {};
  public form: IFormController;

  public pattern = new RegExp(
    '^([a-fA-F0-9]{8})[-]?([a-fA-F0-9]{4})[-]?([a-fA-F0-9]{4})[-]?([a-fA-F0-9]{4})[-]?([a-fA-F0-9]{12})$'
  );
  public namePattern = new RegExp('^[0-9a-zA-z-_\\s]{0,256}$');
  public ipAddressPattern = new RegExp('^(?:[0-9]{1,3}[.]){3}[0-9]{1,3}$');
  public dnsServersPattern = new RegExp(
    '^(\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3})(\\s*,\\s*(\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3})){0,1}$'
  );

  private originalAddress: string;
  private originalNetworkType: string;
  private usesDefaultDns = true;

  private isReload = false;

  constructor(
    private $scope: IScope,
    private gatewayService: GatewayService,
    private gatewayHealthService: GatewayHealthService
  ) {}

  public $onInit() {
    const unbind = this.$scope.$watch(
      'gatewayDetails.details',
      () => {
        this.setPristineState(unbind);
        if (this.isNew && this.isReload) {
          unbind();
        }
        this.isReload = true;
      },
      true
    );
  }

  private setPristineState(unbind?: () => void) {
    if (this.details) {
      this.originalAddress = this.details.address;
      this.originalNetworkType = this.details.networkType;
      this.usesDefaultDns = !this.details.dnsServers;
    }
    this.form.$setPristine();
    if (!this.isNew && this.details && unbind) {
      unbind();
    }
  }

  public onAddressChange() {
    const groups = this.pattern.exec(this.details.address);
    if (groups != null && groups.length > 5) {
      this.details.address =
        groups[1].toLowerCase() +
        '-' +
        groups[2].toLowerCase() +
        '-' +
        groups[3].toLowerCase() +
        '-' +
        groups[4].toLowerCase() +
        '-' +
        groups[5].toLowerCase();
    }
  }

  public addGateway() {
    this.startBusy();
    this.gatewayService
      .addGateway(this.details)
      .then(() => {
        this.$scope.$apply(() => {
          this.endBusy();
          this.reload();
        });
      })
      .catch((reason) => {
        if (reason == 400) {
          alert(
            'The gateway address you provided could not be added to this building. Please make sure that it is entered correctly and does not duplicate another entry.'
          );
          this.$scope.$apply(() => {
            this.endBusy();
          });
        }
      });
  }

  public updateGateway() {
    this.startBusy();

    if (this.originalAddress) {
      if (this.originalAddress !== this.details.address) {
        if (!confirm('Proceed with updating the gateway address?')) {
          this.endBusy();
          return;
        }
      }
      if (this.originalNetworkType !== this.details.networkType) {
        if (
          !confirm(
            'Changing the network type of a gateway may affect mapping of already mapped nodes. Proceed with the update?'
          )
        ) {
          this.endBusy();
          return;
        }
      }
    }

    this.gatewayService
      .updateGateway(this.details.id, this.details)
      .then(() => {
        this.$scope.$apply(() => {
          alert('The update and configuration for the gateway succeeded!');
          this.setPristineState();
          this.endBusy();
        });
      })
      .catch((reason) => {
        if (reason == 400) {
          alert(
            'The update for the gateway failed. Please ensure the gateway address is entered correctly and does not duplicate another entry.'
          );
          this.$scope.$apply(() => {
            this.endBusy();
          });
        }
      });
  }

  public reloadGateway() {
    if (!confirm('Reset changes?')) {
      return;
    }
    this.startBusy();
    this.gatewayService
      .getGateway(this.details.id)
      .then((gateway) => {
        this.$scope.$apply(() => {
          angular.copy(gateway, this.details);
          this.setPristineState();
          this.endBusy();
        });
      })
      .catch((reason) => {
        if (reason == 404) {
          alert(
            'The gateway does not exist! It may have already been deleted.'
          );
          this.$scope.$apply(() => {
            this.endBusy();
          });
        }
      });
  }

  public restartGateway() {
    if (
      !confirm(
        'Are you sure you want to restart the gateway? (Note: a 30 minute cooldown period applies)'
      )
    ) {
      return;
    }
    this.startBusy();
    this.gatewayHealthService
      .requestGatewayRestart(
        this.details.address,
        this.gatewayHealthService.produceCleanGatewayHealth(
          this.details.address
        )
      )
      .then(() => {
        this.$scope.$apply(() => {
          this.endBusy();
        });
      })
      .catch((reason) => {
        if (reason == 400) {
          alert('The gateway could not be restarted. Please try again later.');
          this.$scope.$apply(() => {
            this.endBusy();
          });
        }
      });
  }

  public hasKeys(): boolean {
    return this.details.hasKeys;
  }

  public generateKeys() {
    if (!confirm(`Generate keys for gateway ${this.details.address}?`)) {
      return;
    }
    this.startBusy();
    this.gatewayService
      .generateKeys(this.details.id)
      .then((gateway) => {
        this.$scope.$apply(() => {
          this.details.hasKeys = true;
          this.endBusy();
        });
      })
      .catch((reason) => {
        if (reason == 400) {
          alert(
            'The gateway has keys already generated for it. Keys may only be generated once!'
          );
          this.$scope.$apply(() => {
            this.endBusy();
          });
        }
      });
  }

  public getAllNetworkTypes(): NetworkType[] {
    return NetworkType.all();
  }

  public getAllWirepasVersions(): WirepasVersion[] {
    return WirepasVersion.all();
  }

  public getAllGatewayTypes(): GatewayType[] {
    return GatewayType.all();
  }

  public getAllLogLevels(): LogLevel[] {
    return LogLevel.all();
  }
}
