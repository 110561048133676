@if (tabs$ | async) {
  <app-dashboard-filter
    [buildingId]="building.id"
    [timeZone]="building.timeZone"></app-dashboard-filter>
  <div class="dashboard-tabs-container p-4">
    <div class="pb-[20px]">
      <app-tabs [tabs$]="tabs$" data-cy="dashboard-tabs"></app-tabs>
    </div>
    <div class="h-[82vh] p-2">
      <router-outlet></router-outlet>
    </div>
  </div>
}
