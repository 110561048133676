import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LampType, SimpleLampType, SimpleLampTypeOutline } from '../models/lamp-type.interface';
import { LuminaireManufacturer } from '../models/luminaire-manufacturer.interface';
import { LampTypeResource } from '../resources/lamp-type.resource';
import { LuminaireManufacturerResource } from '../resources/luminaire-manufacturer.resource';
import { LampTypeTemplate } from '@app/shared/models/lamp-type-template.interface';

@Injectable({
  providedIn: 'root'
})
export class LampTypeService {
  constructor(
    private lampTypeResource: LampTypeResource,
    private luminaireManufacturerResource: LuminaireManufacturerResource
  ) {}

  public saveLampType(lampType: SimpleLampTypeOutline, buildingId): Observable<SimpleLampType> {
    return this.lampTypeResource.addByParentId(lampType, buildingId);
  }

  public getLampTypes(buildingId: number): Observable<LampType[]> {
    return this.lampTypeResource.retrieveAllByParentId(buildingId);
  }

  public getLuminaireManufacturers(): Observable<LuminaireManufacturer[]> {
    return this.luminaireManufacturerResource.getAll();
  }

  getTemplates(buildingId: number): Observable<LampTypeTemplate[]> {
    return this.lampTypeResource.getTemplates(buildingId);
  }
}
