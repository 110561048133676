export class OrBannerNoBuildingsController {
  public message: string;
  public canAddBuildings: boolean;
  constructor() {}

  public $onInit() {
    if (this.canAddBuildings) {
      this.message = 'All the buildings you have access to will appear here.';
    } else {
      this.message =
        "You don't have any buildings linked to your account. " +
        "Please contact your Building's Admin or your Organic Response representative.";
    }
  }
}
