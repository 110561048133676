import { PromiseRejectionHandler } from '@angularjs/or/promises/PromiseRejectionHandler';
import { EmDriver } from '@angularjs/or/api/building/EmDriver';
import { PromiseUtils } from '@angularjs/or/angular/promises/PromiseUtils';
import { SensorNodeIdsBatch } from '@angularjs/or/api/building/SensorNodeBatch';

export class EmDriverResource {
  constructor(
    private $http: ng.IHttpService,
    private baseUrl: string,
    private rejectionHandler: PromiseRejectionHandler
  ) {}

  public getByNodeIds(nodeIds: number[]): Promise<EmDriver[]> {
    return PromiseUtils.wrapSingle(
      this.$http.post(this.baseUrl + '/node', new SensorNodeIdsBatch(nodeIds)),
      this.rejectionHandler
    );
  }

  public getByNodeId(nodeId: number): Promise<EmDriver[]> {
    return PromiseUtils.wrapSingle(
      this.$http.get(this.baseUrl + '/node/' + nodeId),
      this.rejectionHandler
    );
  }
}
