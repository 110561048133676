import { Resource } from '../Resource';
import { Floor } from '../../api/building/Floor';
import { IFloorResource } from '../../api/IFloorResource';
import { SensorNode } from '../../api/building/SensorNode';
import { SavedEntity } from '../../api/SavedEntity';
import { SensorNodeBatch } from '../../api/building/SensorNodeBatch';
import { PromiseRejectionHandler } from '../../promises/PromiseRejectionHandler';
import { Func } from '../../delegates/Delegates';
import { PromiseUtils } from '../promises/PromiseUtils';

export class FloorResource
  extends Resource<Floor, number>
  implements IFloorResource
{
  constructor(
    $http: ng.IHttpService,
    baseUrl: string,
    rejectionHandler: PromiseRejectionHandler,
    private addNodesUrlGenerator: Func<number, string>
  ) {
    super($http, baseUrl, rejectionHandler);
  }

  public addNodes(
    floorId: number,
    nodes: SensorNode[]
  ): Promise<SavedEntity<SensorNodeBatch, number>[]> {
    return PromiseUtils.wrapSingle(
      this.$http.post(
        this.addNodesUrlGenerator(floorId),
        new SensorNodeBatch(nodes)
      ),
      this.rejectionHandler
    );
  }
}
