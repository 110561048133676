import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Tab } from './tab.interface';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent {
  @Input() public tabs$: Observable<Tab[]>;
}
