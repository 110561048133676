import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  ViewChild
} from '@angular/core';
import { ICircadianCurve } from '@app/shared/models/circadian-curve.interface';
import * as d3 from 'd3';

@Component({
  selector: 'app-circadian-curve-tile',
  templateUrl: './circadian-curve-tile.component.html',
  styleUrls: ['./circadian-curve-tile.component.scss']
})
export class CircadianCurveTileComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() public curve: ICircadianCurve;
  @Input() public curvesList: ICircadianCurve[];

  @ViewChild('circadianCharts') chartRef: ElementRef;

  public currentColourTemp: number;
  public currentLightLevel: number;

  private readonly margin: { top: number; right: number; bottom: number; left: number };
  private width = 0;
  private height = 0;
  private colourTempSvgSelector: string;
  private lightLevelSvgSelector: string;
  private colourTempSvg: d3.Selection<d3.BaseType, unknown, HTMLElement, any>;
  private lightLevelSvg: d3.Selection<d3.BaseType, unknown, HTMLElement, any>;
  private x: d3.ScaleLinear<number, number>;
  private y0: d3.ScaleLinear<number, number>;
  private y1: d3.ScaleLinear<number, number>;
  private colourTemps: { time: number; colour_temp: number }[];
  private lightLevels: { time: number; light_level: number }[];
  private currentTimeColourTempPair: { time: number; colour_temp: number }[];
  private currentTimeLightLevelPair: { time: number; light_level: number }[];

  constructor(private cdr: ChangeDetectorRef) {
    this.margin = { top: 5, right: 5, bottom: 5, left: 5 };
    this.height = 150 - this.margin.top - this.margin.bottom;
  }

  ngOnInit(): void {
    this.colourTempSvgSelector = 'svg#colour-temp-collapsed-' + this.curvesList.indexOf(this.curve);
    this.lightLevelSvgSelector = 'svg#light-level-collapsed-' + this.curvesList.indexOf(this.curve);
    this.initialiseCurveValues();
    this.initialiseCurrentTimeValues();
  }

  ngOnChanges(): void {
    setTimeout(() => this.drawCurves(), 10);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const divElement = this.chartRef.nativeElement;
      this.setupChart(divElement.clientWidth);
      this.cdr.detectChanges();
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(resizeEvent: Event): void {
    this.clearSvg();
    const target = resizeEvent.target as Window;
    this.setupChart(target.innerWidth);
  }

  clearSvg(): void {
    d3.selectAll(`${this.colourTempSvgSelector} g`).remove();
    d3.selectAll(`${this.lightLevelSvgSelector} g`).remove();
  }

  private setupChart(width: number): void {
    this.width = this.getGraphWidth(width);
    this.drawCurves();
  }

  private getGraphWidth(windowWidth: number): number {
    if (windowWidth > 720) {
      return windowWidth / 2 - windowWidth * 0.04 - this.margin.left - this.margin.right; // 0.04 factor is to avoid svg moving out from the base container scope
    } else {
      return windowWidth - windowWidth * 0.15 - this.margin.left - this.margin.right; // 0.15 factor is to avoid svg moving out from the base container scope
    }
  }

  private drawCurves(): void {
    this.initSvg();
    this.initAxes();
    this.fillColourTempCurve();
    this.fillLightLevelCurve();
  }

  private static get hoursInDay(): number[] {
    return new Array(24).fill(0).map((_, i) => i);
  }

  private initialiseCurrentTimeValues(): void {
    const hours = new Date().getHours();
    this.currentTimeColourTempPair = this.colourTemps.filter((colourTemp) => {
      return colourTemp.time === hours;
    });
    this.currentTimeLightLevelPair = this.lightLevels.filter((lightLevel) => {
      return lightLevel.time === hours;
    });
    this.currentColourTemp = this.currentTimeColourTempPair[0].colour_temp;
    this.currentLightLevel = this.currentTimeLightLevelPair[0].light_level;
  }

  private initialiseCurveValues = (): void => {
    this.colourTemps = CircadianCurveTileComponent.hoursInDay.map((t, i) => ({
      time: t,
      colour_temp: this.curve.colourTempValues[i]
    }));
    this.lightLevels = CircadianCurveTileComponent.hoursInDay.map((t, i) => ({
      time: t,
      light_level: this.curve.lightLevelValues[i]
    }));
  };

  private initAxes(): void {
    // set the ranges
    this.x = d3.scaleLinear().range([0, this.width]);
    this.y0 = d3.scaleLinear().range([this.height, 0]);
    this.y1 = d3.scaleLinear().range([this.height, 0]);
    // Scale the range of the data
    this.x.domain([0, 23]);
    this.y0.domain([
      0,
      d3.max(this.colourTemps, (d) => {
        return Math.max(d.colour_temp);
      })
    ]);
    this.y1.domain([
      0,
      d3.max(this.lightLevels, (d) => {
        return Math.max(d.light_level);
      })
    ]);
  }

  private initSvg(): void {
    // appends a 'group' element to 'svg'
    // moves the 'group' element to the top left margin
    this.colourTempSvg = d3
      .select(this.colourTempSvgSelector)
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .attr('border', 1)
      .append('g')
      .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');

    this.lightLevelSvg = d3
      .select(this.lightLevelSvgSelector)
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .attr('border', 1)
      .append('g')
      .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');
  }

  private fillColourTempCurve = (): void => {
    // define the 1st line (Color Temperature)
    const colourTempLine = d3
      .line<{ time: number; colour_temp: number }>()
      .x((d) => {
        return this.x(d.time);
      })
      .y((d) => {
        return this.y0(d.colour_temp);
      });
    // Add the colour temp path.
    this.colourTempSvg
      .append('path')
      .data([this.colourTemps])
      .attr('class', 'line')
      .attr('id', 'colourTempLine')
      .attr('d', colourTempLine)
      .attr('background-color', 'pink');

    const dotRadius = 5;
    this.colourTempSvg
      .selectAll('circle')
      .data(this.currentTimeColourTempPair)
      .enter()
      .append('circle')
      .attr('class', 'dot colour-level')
      .attr('r', dotRadius)
      .attr('cx', (d) => {
        return this.x(d.time);
      })
      .attr('cy', (d) => {
        return this.y0(d.colour_temp);
      });
  };

  private fillLightLevelCurve = (): void => {
    // define the 1st line (Color Temperature)
    const lightLevelLine = d3
      .line<{ time: number; light_level: number }>()
      .x((d) => {
        return this.x(d.time);
      })
      .y((d) => {
        return this.y1(d.light_level);
      });
    // Add the colour temp path.
    this.lightLevelSvg
      .append('path')
      .data([this.lightLevels])
      .attr('class', 'line')
      .attr('id', 'lightLevelLine')
      .attr('d', lightLevelLine)
      .attr('background-color', 'pink');

    const dotRadius = 5;
    this.lightLevelSvg
      .selectAll('circle')
      .data(this.currentTimeLightLevelPair)
      .enter()
      .append('circle')
      .attr('class', 'dot colour-level')
      .attr('r', dotRadius)
      .attr('cx', (d) => {
        return this.x(d.time);
      })
      .attr('cy', (d) => {
        return this.y1(d.light_level);
      });
  };

  // TODO should be optimised
  public getFloorIds(): number[] {
    return this.curve.floors.map((floor) => floor.id);
  }

  // TODO should be optimised
  public getTagIds(): number[] {
    return this.curve.tags.map((tag) => tag.id);
  }
}
