import { Directive, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Tag } from '@app/shared/models/tag.interface';
import * as d3 from 'd3';

@Directive({
  selector: '[tagOutline]',
  standalone: true
})
export class TagOutlineDirective implements OnInit, OnChanges {
  @Input()
  tags: Tag[] = [];

  @Input()
  isPassiveNode = false;

  @Input()
  isDriver = false;

  constructor(private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    const newTags = changes.tags.currentValue;
    const oldTags = changes.tags.previousValue;
    if (newTags !== oldTags) {
      this.drawTagOutline();
    }
  }

  ngOnInit(): void {
    this.drawTagOutline();
  }

  private drawTagOutline(): void {
    let outerRadius: number;
    let innerRadius: number;
    let tagClass: string;
    let transformValue: string;
    if (this.isDriver) {
      outerRadius = 4.86;
      innerRadius = 3.86;
      tagClass = 'driver-tag';
      transformValue = 'translate(6,6)';
    } else {
      outerRadius = this.isPassiveNode ? 10.5 : 12;
      innerRadius = this.isPassiveNode ? 21 / 2.7 : 8;
      tagClass = 'sensor-node-tag';
      transformValue = 'translate(12,12)';
    }
    const arc = d3.arc<d3.PieArcDatum<Tag>>().outerRadius(outerRadius).innerRadius(innerRadius);
    const pie = d3.pie<Tag>().value(() => 1);
    const svg = d3.select(this.el.nativeElement);
    svg.selectAll('*').remove();
    svg
      .append('g')
      .attr('transform', transformValue)
      .selectAll('.' + tagClass)
      .data(pie(this.tags))
      .enter()
      .append('path')
      .attr('class', tagClass)
      .attr('d', arc)
      .attr('fill', (d) => '#' + d.data.color)
      .attr('title', (d) => d.data.name);
  }
}
