<div class="or-dropdown or-floors-menu" [ngClass]="{ 'or-active': (isActive() | async) }">
  <button  [matMenuTriggerFor]="menu" data-cy="session-menu-drop-down-button" class="or-button or-rounded or-dropdown-trigger or-dropdown-trigger-inverse"
           (click)="toggle()" (menuClosed)='setActive(false)'>
    <span class="or-button-thumbnail"
          [ngStyle]="producePictureUrlStyle(user$ | async)"
          [ngClass]="{ 'or-icon-userpic': produceUserClass(user$ | async), 'or-inverse': !(this.isActive$ | async) }">
    </span>
    <span class="or-button-label" data-cy="current-user-label" [textContent]="(this.user$ | async)?.name"></span>
  </button>
</div>

<mat-menu #menu="matMenu" class='mt-2'>
  <div class="min-w-[200px]">
    <span appIsAuthorized="GLOBAL_ADMIN">
     <a data-cy='global-admin-menu-item' class="px-4 py-2" mat-menu-item [href]="globalAdministrationBaseUrl">
        <mat-icon class='opacity-80'>badge</mat-icon>
        <span class="or-button-label ">Global Admin</span>
    </a>
    </span>
    <a data-cy='user-info-menu-item' class="px-4 py-2" mat-menu-item [href]="userInfoBaseUrl">
        <mat-icon class='opacity-80'>person</mat-icon>
        <span class="or-button-label">User Info</span>
    </a>
    <button data-cy='logout-menu-item' class="px-4 py-2" mat-menu-item (click)="logout()">
      <mat-icon class='opacity-80'>logout</mat-icon>
      Log out
    </button>
  </div>
</mat-menu>
