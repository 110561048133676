import { Tag } from '@angularjs/or/api/building/Tag';
import { MultiselectOption } from '../or-multiselect/MultiselectOption';
import { DimLevel } from '@angularjs/or/data/DimLevel';
import { RuleType } from '@angularjs/or/data/RuleType';
import { Rule } from '@angularjs/or/api/building/Rule';
import { DwellTime } from '@angularjs/or/data/DwellTime';
import { Personality } from '@angularjs/or/data/Personality';
import { Scene } from '@angularjs/or/data/Scene';

export class OrFormRuleDetailsController {
  public isTagListEditable = true;
  public isFloorListEditable = true;
  public tagOptions: MultiselectOption<Tag>[];
  public options: any[];
  public onChange: () => {};
  public rule: Rule;
  public formatter: (val: number) => any;
  public hasValue = false;

  constructor(private $scope: ng.IScope) {}

  public $onInit() {
    this.loadValueOptions();
  }

  onChangeRuleType() {
    this.rule.value = null;
    this.loadValueOptions();
    this.onChange();
  }

  private loadValueOptions() {
    this.hasValue = true;
    if (
      this.rule.command === RuleType.LOWLIGHT.toString() ||
      this.rule.command === RuleType.MAXLIGHT.toString() ||
      this.rule.command === RuleType.MINLIGHT.toString()
    ) {
      this.options = DimLevel.all();
      this.formatter = (val: number) => DimLevel.fromValue(val);
    } else if (
      this.rule.command === RuleType.DWELL.toString() ||
      this.rule.command === RuleType.LOWLIGHT_TIME.toString()
    ) {
      this.options = DwellTime.all();
      this.formatter = (val: number) => DwellTime.fromValue(val);
    } else if (this.rule.command === RuleType.PERSONALITY.toString()) {
      this.options = Personality.all();
      this.formatter = (val: number) => Personality.fromValue(val);
    } else if (this.rule.command === RuleType.SCENE.toString()) {
      this.options = Scene.all();
      this.formatter = (val: number) => Scene.fromValue(val);
    }
  }

  onChangeValue() {
    this.onChange();
  }
}
