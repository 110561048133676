import { Coordinate } from '@app/shared/utils/coordinate';
import { Transform } from '@app/shared/components/floorplan/transform';

export class Style {
  private visibility: string;
  private top: string;
  private left: string;
  private width: string;
  private height: string;
  private transform: string;
  private transition: string;

  constructor(
    visibility: boolean,
    transform?: Transform,
    transition?: string,
    top?: number,
    left?: number,
    width?: number,
    height?: number
  ) {
    this.setVisibility(visibility);
    if (transform) {
      this.setTransform(transform);
    }
    if (transition) {
      this.setTransition(transition);
    }
    if (top) {
      this.setTop(top);
    }
    if (left) {
      this.setLeft(left);
    }
    if (width) {
      this.setWidth(width);
    }
    if (height) {
      this.setHeight(height);
    }
  }

  setVisibility(visibility: boolean): void {
    this.visibility = visibility ? 'visible' : 'hidden';
  }

  setTop(top: number): void {
    this.top = top + 'px';
  }

  setLeft(left: number): void {
    this.left = left + 'px';
  }

  setWidth(width: number): void {
    this.width = width + 'px';
  }

  setHeight(height: number): void {
    this.height = height + 'px';
  }

  setTransform(transform: Transform): void {
    this.transform = transform.toString();
  }

  setTransition(transition: string): void {
    this.transition = transition;
  }

  setTransformOrigin(position: Coordinate): void {
    this['transform-origin'] = position ? `${position.x}px ${position.y}px 0` : null;
  }
}
