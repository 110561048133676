import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-ble-dialog',
  templateUrl: './ble-dialog.component.html',
  styleUrls: ['./ble-dialog.component.scss']
})
export class BleDialogComponent {
  constructor(private dialogRef: MatDialogRef<BleDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: string) {}

  get queryType(): string {
    return this.data;
  }

  cancel(): void {
    this.dialogRef.close({ event: 'Cancel' });
  }

  send(): void {
    this.dialogRef.close({ event: 'Send' });
  }
}
