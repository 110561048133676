import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { GlobalAuthorityRequestService } from '@services/user-global-authority-request/global-authroity-request.service';
import { GlobalAuthorityRequest, Status } from '@app/shared/models/user-global-authority-request-interface';
import { catchError, finalize } from 'rxjs/operators';
import { MatSort } from '@angular/material/sort';

export class GlobalAuthorityRequestsDatasource implements DataSource<GlobalAuthorityRequest> {
  private globalAuthorityRequestSubject = new BehaviorSubject<GlobalAuthorityRequest[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  private countSubject = new BehaviorSubject<number>(0);
  public counter$ = this.countSubject.asObservable();
  private allRequests: GlobalAuthorityRequest[] = [];
  sort: MatSort;
  constructor(private globalAuthorityRequestService: GlobalAuthorityRequestService) {}

  loadGlobalAuthorityRequests(pageIndex: number = 0, pageSize: number = 10): void {
    this.loadingSubject.next(true);

    this.globalAuthorityRequestService
      .getGlobalAuthorityRequestsByStatus(Status.PENDING)
      .pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((requests: GlobalAuthorityRequest[]) => {
        this.allRequests = requests;
        this.countSubject.next(requests.length);
        this.globalAuthorityRequestSubject.next(this.paginateRequests(pageIndex, pageSize));
      });
  }

  paginateRequests(pageIndex: number, pageSize: number): GlobalAuthorityRequest[] {
    const startIndex = pageIndex * pageSize;
    const endIndex = startIndex + pageSize;
    return this.allRequests.slice(startIndex, endIndex);
  }

  connect(collectionViewer: CollectionViewer): Observable<GlobalAuthorityRequest[]> {
    return this.globalAuthorityRequestSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.globalAuthorityRequestSubject.complete();
    this.loadingSubject.complete();
  }
}
