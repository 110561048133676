import { SensorNodeDTO } from './sensor-node';

export interface SensorNodeBatch {
  nodes: SensorNodeDTO[];
}

export interface IdsBatch {
  nodeIds: number[];
}

export class SensorNodeIdsBatch implements IdsBatch {
  constructor(public nodeIds: number[]) {}
}
