import { Component, Input, OnChanges } from '@angular/core';
import { CircadianCurveService } from '@app/shared/services/circadian-curve.service';
import { ICircadianCurve } from '@app/shared/models/circadian-curve.interface';
import { ICircadianCurveOutline } from '@app/shared/models/circadian-curve-outline.interface';
import { Building } from '@app/shared/models/building.interface';

@Component({
  selector: 'app-circadian-curve-list',
  templateUrl: './circadian-curve-list.component.html',
  styleUrls: ['./circadian-curve-list.component.scss']
})
export class CircadianCurveListComponent implements OnChanges {
  public curves: ICircadianCurve[] = [];
  public curvesOutline: ICircadianCurveOutline = { rankOrder: [] };
  public showNewForm = false;
  public editingCurveRanks = false;
  public isBusy = true;
  @Input() public building: Building;
  constructor(private circadianCurveService: CircadianCurveService) {}

  ngOnChanges(): void {
    setTimeout(() => {
      this.refreshList();
    }, 100);
  }

  private loadCurves(): void {
    this.circadianCurveService.getCurves(this.building.id).subscribe({
      next: (curves) => {
        this.curves = curves;
        this.curves.forEach((curve) => {
          curve.isCollapsed = true;
          curve.editingRank = false;
        });
        this.curves.sort((c1, c2) => c1.rank - c2.rank);
        this.isBusy = false;
        this.editingCurveRanks = false;
      },
      error: (error) => {
        console.error(error);
        this.isBusy = false;
        this.editingCurveRanks = false;
      }
    });
  }

  public createNew(): void {
    this.showNewForm = true;
  }

  public saveRankChanges(): void {
    this.curves.forEach((activeCurve) => {
      this.curvesOutline.rankOrder.push({
        rank: activeCurve.rank,
        id: activeCurve.id
      });
    });

    this.circadianCurveService.updateRankOrder(this.building.id, this.curvesOutline).subscribe({
      next: () => {
        this.refreshList();

        this.curves.forEach((activeCurve) => (activeCurve.editingRank = false));
        this.editingCurveRanks = false;
      },
      error: (error) => {
        console.error(error);
        alert('An error occurred while updating the circadian curve rank order');
      }
    });
    this.curvesOutline = { rankOrder: [] };
    this.editingCurveRanks = false;
  }

  public editRanks(): void {
    this.curves.forEach((activeCurve) => (activeCurve.editingRank = true));
    this.editingCurveRanks = true;
  }

  public collapseCurves(): void {
    this.curves.forEach((curve) => (curve.isCollapsed = true));
  }

  public closeNew(): void {
    this.showNewForm = false;
  }

  public refreshList(): void {
    this.showNewForm = false;
    this.loadCurves();
  }
}
