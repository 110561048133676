import { EmergencyLightingTestResult } from '../../api/building/EmergencyLightingTestResult';
import { FloorService } from '../FloorService';
import { IEmergencyLightingTestResultResource } from '../../api/resources/IEmergencyLightingTestResultResource';
import { ReportQueryOutline } from '../../angular/resources/EmergencyLightingTestResultResource';
import { IBuildingService } from '@angularjs/or/services/IBuildingService';

export class LightingTestReportService {
  constructor(
    private emergencyLightingTestResultResource: IEmergencyLightingTestResultResource,
    private floorService: FloorService,
    private buildingService: IBuildingService
  ) {}

  public loadReports(
    outline: ReportQueryOutline
  ): Promise<EmergencyLightingTestResult[]> {
    return new Promise((resolve) => {
      this.floorService.getCurrentFloor().then(
        (floor) => {
          this.buildingService.getCurrentBuilding().then((building) =>
            this.emergencyLightingTestResultResource
              .getResultsByFloor(building.id, floor.id, outline)
              .then((results) => {
                resolve(results);
              })
          );
        },
        (reason) => console.warn(reason)
      );
    });
  }
}
