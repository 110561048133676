import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RefreshPageService {
  private refreshingDone = false;
  private onPageRefresh = new Subject<void>();
  private onAutoRefreshDone = new Subject<boolean>();

  get pageRefresh$(): Observable<void> {
    return this.onPageRefresh.asObservable();
  }

  get autoRefreshDone$(): Observable<boolean> {
    return this.onAutoRefreshDone.asObservable();
  }

  onRefresh(): void {
    this.onPageRefresh.next();
    this.refreshingDone = true;
  }

  onAutoRefreshComplete(): void {
    this.onAutoRefreshDone.next(this.refreshingDone);
    this.refreshingDone = false;
  }
}
