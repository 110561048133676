import { Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import * as d3 from 'd3';
import { SelectableNode } from '@app/shared/models/sensor-node';

@Directive({
  selector: '[pubSubConnections]',
  standalone: true
})
export class PubSubConnectionsDirective implements OnInit, OnDestroy, OnChanges {
  @Input({ required: true })
  node: SelectableNode;

  @Input({ required: true })
  nodes: SelectableNode[] = [];

  constructor(private element: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    const currentNode = changes.node?.currentValue;
    const previousNode = changes.node?.previousValue;
    const currentNodes = changes.nodes?.currentValue;

    if (currentNode?.x !== previousNode?.x || currentNode?.y !== previousNode?.y) {
      if (this.isPublisher(currentNode)) {
        const publisher = currentNode;
        const subscribers = currentNodes.filter((n) => n.groupId === publisher.groupId && n.subscriber === true);
        this.changeSelectedLinePositions(subscribers, publisher);
      } else if (this.isSubscriber(currentNode)) {
        const subscribers = [currentNode];
        const publisher = currentNodes.find((n) => n.groupId === currentNode.groupId && n.subscriber === false);
        this.changeSelectedLinePositions(subscribers, publisher);
      }
    }
  }

  changeSelectedLinePositions(subscribers: SelectableNode[], publisher: SelectableNode): void {
    const svg = d3.select('#pub-sub-container');
    subscribers.forEach((subscriber) => {
      const selection = svg.select(`#line-${publisher.id}-${subscriber.id}`);
      selection.attr('x1', publisher.x).attr('y1', publisher.y).attr('x2', subscriber.x).attr('y2', subscriber.y);
    });
  }

  ngOnInit(): void {
    if (!this.isPublisher(this.node)) {
      return;
    }

    const publisher = this.node;
    const subscribers = this.nodes.filter((n) => n.groupId === publisher.groupId && n.subscriber === true);
    const svg = d3.select('#pub-sub-container');

    subscribers.forEach((subscriber) => {
      if (!publisher) {
        console.error('Publisher can not be null/undefined');
        return;
      }
      svg
        .append('line')
        .attr('id', `line-${publisher.id}-${subscriber.id}`)
        .attr('x1', publisher.x)
        .attr('y1', publisher.y)
        .attr('x2', subscriber.x)
        .attr('y2', subscriber.y)
        .attr('stroke', '#09d')
        .attr('stroke-width', 1)
        .attr('stroke-dasharray', '5,3');
    });
  }

  ngOnDestroy(): void {
    if (this.isPublisher(this.node)) {
      const publisher = this.node;
      const subscribers = this.nodes.filter((n) => n.groupId === publisher.groupId && n.subscriber === true);
      subscribers.forEach((subscriber) => {
        const selection = d3.select(`#line-${publisher.id}-${subscriber.id}`);
        selection.remove();
      });
    } else if (this.isSubscriber(this.node)) {
      const subscriber = this.node;
      const publisher = this.nodes.find((n) => n.groupId === subscriber.groupId && n.subscriber === false);
      const selection = d3.select(`#line-${publisher.id}-${subscriber.id}`);
      selection.remove();
    }
  }

  private isPublisher(node: SelectableNode): boolean {
    return node?.groupId != null && node?.subscriber === false;
  }

  private isSubscriber(node: SelectableNode): boolean {
    return node?.groupId != null && node?.subscriber === true;
  }
}
