import { OrBannerNoCircadianCurvesController } from './OrBannerNoCircadianCurvesController';

export class OrBannerNoCircadianCurvesDirective {
  constructor() {
    return {
      bindToController: true,
      controller: OrBannerNoCircadianCurvesController,
      controllerAs: 'banner',
      replace: true,
      restrict: 'E',
      scope: {},
      template: require('raw-loader!./or-banner-no-circadian-curves.html').default
    };
  }
}
