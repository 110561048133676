import { Rule } from './Rule';
import { WeekdaySelection } from '../../util/WeekdaySelection';

export class Schedule {
  constructor(
    public name?: string,
    public startTime: string = '00:00',
    public endTime: string = '00:00',
    public days?: WeekdaySelection,
    public rules: Rule[] = [],
    public enabled: boolean = true,
    public rank: number = null,
    public id?: number,
    public active: boolean = false,
    public alwaysActive: boolean = false,
    public startDate?: string,
    public endDate?: string,
    public editable?: boolean
  ) {}

  public static clone(other: Schedule): Schedule {
    return new Schedule(
      other.name,
      other.startTime,
      other.endTime,
      WeekdaySelection.clone(other.days),
      other.rules.map((rule) => Rule.clone(rule)),
      other.enabled,
      other.rank,
      other.id,
      other.active,
      other.alwaysActive,
      other.startDate,
      other.endDate,
      other.editable
    );
  }
}
