import { EmergencyLightingTestState } from '@angularjs/or/data/EmergencyLightingTestState';
import { EmergencyLightingTestPowerSupplyStatus } from '@angularjs/or/data/EmergencyLightingTestPowerSupplyStatus';
import { NavigationService } from '@app/shared/services/navigation/navigation.service';
import { IBuildingService } from '@angularjs/or/services/IBuildingService';
import { FloorService } from '@angularjs/or/services/FloorService';
import { IEmergencyLightingResource } from '@angularjs/or/api/resources/IEmergencyLightingResource';
import {
  EmergencyLightingTest,
  EmergencyLightingTestOutline
} from '@angularjs/or/api/building/EmergencyLightingTest';
import { EmergencyLightingTestResult } from '@angularjs/or/api/building/EmergencyLightingTestResult';
import moment from 'moment';
import IFormController = angular.IFormController;

export class OrLightingTestReportController {
  public details: EmergencyLightingTestResult;
  private buildingId: number;
  public floorId: number;
  public form: IFormController;

  constructor(
    private buildingService: IBuildingService,
    private floorService: FloorService,
    private navigationService: NavigationService,
    private emergencyLightingResource: IEmergencyLightingResource
  ) {}

  public $onInit() {
    this.buildingService.getCurrentBuilding().then((building) => {
      this.floorService.getCurrentFloor().then(
        (floor) => {
          this.buildingId = building.id;
          this.floorId = floor.id;
        },
        (reason) => console.warn(reason)
      );
    });
  }

  public produceSupplyStatusClass(node) {
    const powerSupplyStatus = node.powerSupplyStatus;
    const reportStatus = node.state;
    if (
      EmergencyLightingTestPowerSupplyStatus.FAILED.equals(powerSupplyStatus) ||
      EmergencyLightingTestState.FAILED.equals(reportStatus)
    ) {
      return 'or-elt-report-node-failed';
    } else if (EmergencyLightingTestState.CANCELLED.equals(reportStatus)) {
      return 'or-elt-report-node-cancelled';
    } else if (EmergencyLightingTestState.TIMED_OUT.equals(reportStatus)) {
      return 'or-elt-report-node-timed-out';
    } else if (EmergencyLightingTestState.SCHEDULED.equals(reportStatus)) {
      return 'or-elt-report-node-scheduled';
    } else {
      return 'or-elt-report-node-active';
    }
  }

  public produceReportIconClass() {
    const status = this.details.status;
    if (
      status.equals(EmergencyLightingTestState.TIMED_OUT) ||
      status.equals(EmergencyLightingTestState.FAILED) ||
      status.equals(EmergencyLightingTestState.CANCELLED)
    ) {
      return 'or-icon-cross';
    } else if (status.equals(EmergencyLightingTestState.SUCCEEDED)) {
      return 'or-icon-checkmark';
    } else if (status.equals(EmergencyLightingTestState.SCHEDULED)) {
      return 'or-icon-stopwatch';
    } else if (status.equals(EmergencyLightingTestState.IN_PROGRESS)) {
      return 'or-icon-play';
    }
  }

  public showNodeOnFloorplan(nodeId: number) {
    this.navigationService.navigateToSectionWithQueryString(
      this.navigationService.getSectionById('emergency-lighting-groups'),
      this.buildingId,
      this.floorId,
      `n=${nodeId}`
    );
  }

  public saveReport(test) {
    this.emergencyLightingResource
      .saveTest(test.id, new EmergencyLightingTestOutline(test.notes))
      .then(() => {
        test.form.$setPristine();
      });
  }

  public getNodeId(node: EmergencyLightingTest): number {
    if (node.sensorNodeId) {
      return node.sensorNodeId;
    } else {
      return node.driverId;
    }
  }

  public getDriverIdText(node: EmergencyLightingTest): string {
    if (node.hasSiblings) {
      return node.driverId.toString();
    } else {
      return '';
    }
  }

  public humanizeTimestamp(unixTime: number): string {
    if (!unixTime) {
      return 'N/A';
    }
    return moment(new Date(unixTime)).format('ddd MMM DD yyyy HH:mm:ss'); // using 24-hour format for better clarity
  }
}
