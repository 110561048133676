import { OrFooterController } from './OrFooterController';

export class OrFooterDirective {
  constructor() {
    return {
      bindToController: true,
      controller: ['ngDialog', 'SessionService', 'BuildingService', 'ConfigurationService', OrFooterController],
      controllerAs: 'footer',
      replace: true,
      restrict: 'E',
      scope: {},
      template: require('raw-loader!./or-footer.html').default
    };
  }
}
