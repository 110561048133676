import { ISimpleService } from '../api/ISimpleService';

export class GatewayHealthService {
  private endpoints = {
    gatewayHealth: '/api/v1/gateway_health'
  };

  constructor(
    private simpleService: ISimpleService<GatewayHealth, void, void, void>,
    private generateApiUrl: (endpoint: string) => string
  ) {}

  public getGatewayHealth(gatewayAddress: string): Promise<GatewayHealth> {
    return this.simpleService.get(
      this.generateApiUrl(
        `${this.endpoints.gatewayHealth}/${gatewayAddress}?forFrontend=true`
      )
    );
  }

  public requestGatewayRestart(
    gatewayAddress: string,
    gatewayHealth: GatewayHealth
  ): Promise<void> {
    return this.simpleService.post(
      this.generateApiUrl(`${this.endpoints.gatewayHealth}/${gatewayAddress}`),
      gatewayHealth
    );
  }

  public produceCleanGatewayHealth(gatewayAddress: string): GatewayHealth {
    return new GatewayHealth(
      true,
      true,
      true,
      true,
      true,
      null,
      gatewayAddress
    );
  }
}

export class GatewayHealth {
  constructor(
    public restartRequired: boolean,
    public healthy: boolean,
    public awsIotHealthy: boolean,
    public meshHealthy: boolean,
    public internetHealthy: boolean,
    public lastRestarted: Date,
    public gatewayAddress: string
  ) {}
}
