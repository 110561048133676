<div id="orGlobalAdministrationAuthorityRequests">
  <div class="spinner-container" *ngIf="dataSource.loading$ | async">
    <mat-spinner class="mx-auto" mode="indeterminate"></mat-spinner>
  </div>

  <mat-table class="users-table mat-elevation-z8" [dataSource]="dataSource"
             matSort matSortActive="id" matSortDirection="asc" matSortDisableClear>

    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef mat-sort-header="id">#</mat-header-cell>
      <mat-cell *matCellDef="let request">{{request.userId}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="avatar">
      <mat-header-cell *matHeaderCellDef>Avatar</mat-header-cell>
      <mat-cell class="avatar-cell" *matCellDef="let request">
        <img *ngIf="request.user.pictureUrl" class="or-icon-userpic or-rounded" src="{{request.user.pictureUrl}}" />
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header="name">Name</mat-header-cell>
      <mat-cell class="name-cell" *matCellDef="let request">{{request.user.name}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="emailAddress">
      <mat-header-cell *matHeaderCellDef mat-sort-header="emailAddress">Email Address</mat-header-cell>
      <mat-cell class="emailAddress-cell" *matCellDef="let request">{{request.user.emailAddress}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="requestGlobalAuthority">
      <mat-header-cell *matHeaderCellDef>Requested Global Authority</mat-header-cell>
      <mat-cell *matCellDef="let request">{{request.globalAuthority}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="reason">
      <mat-header-cell *matHeaderCellDef>Reason</mat-header-cell>
      <mat-cell *matCellDef="let request">{{request.message}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="projectName">
      <mat-header-cell *matHeaderCellDef>Project</mat-header-cell>
      <mat-cell *matCellDef="let request">{{request.projectName}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="managingCompanyName">
      <mat-header-cell *matHeaderCellDef>Managing Company</mat-header-cell>
      <mat-cell *matCellDef="let request">{{request.managingCompanyName}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="requestDate">
      <mat-header-cell *matHeaderCellDef>Request Date</mat-header-cell>
      <mat-cell *matCellDef="let request">{{request.requestTimestamp}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
      <mat-cell class="actions-cell" *matCellDef="let request">
        <div class="flex space-x-2">
          <button mat-raised-button class="or-button or-iconic or-primary or-positive" (click)="acceptRequest(request)">
            Accept
          </button>
          <button mat-raised-button color="warn" class="or-button or-iconic" (click)="declineRequest(request)">
            Decline
          </button>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

  </mat-table>

  <mat-paginator
    [length]="dataSource.counter$ | async"
    [pageSize]="10"
    [pageSizeOptions]="[10, 25, 100]">
  </mat-paginator>
</div>
