import { FeatureService } from '@app/shared/services/feature.service';
import { IAttributes, IAugmentedJQuery, IScope, IDirective } from 'angular';

export class FeatureFlagDirective {
  public static create(featureService: FeatureService): IDirective {
    const linkDirective = (
      _scope: IScope,
      element: IAugmentedJQuery,
      attributes: IAttributes
    ) => {
      if (attributes.featureFlag != null) {
        featureService
          .isAvailable(attributes.featureFlag)
          .subscribe((isAvailable) => {
            if (!isAvailable) {
              element.remove();
            }
          });
      }
    };

    return {
      link: linkDirective,
      bindToController: false,
      controllerAs: 'featureFlag',
      scope: false,
      restrict: 'A'
    };
  }
}
