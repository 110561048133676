import { OrAudioController } from './OrAudioController';

export class OrAudioDirective {
  constructor() {
    return {
      bindToController: true,
      controller: ['$element', 'NewMappingService', OrAudioController],
      controllerAs: 'audio',
      replace: true,
      restrict: 'E',
      scope: {
        isActive: '='
      },
      template: require('raw-loader!./or-audio.html').default
    };
  }
}
