import * as moment from 'moment';
import { TimeScale } from '@app/shared/time/time-scale';
import { Resolution } from '@app/analytics/metric-widget/data-objects/resolution';

export function createAllScales(): TimeScale[] {
  return [
    new TimeScale(
      Resolution.YEARLY,
      (value) => moment(value.toString(), 'M').format('MMM'),
      'years',
      'YYYY',
      'YYYY',
      (momentObj, value) => momentObj.year(value),
      (momentObj) => momentObj.year(),
      (dates) => dates.years,
      (dates, values) => (dates.years = values),
      true
    ),
    new TimeScale(
      Resolution.MONTHLY,
      (value) => moment('1-' + value.toString(), 'M-D').format('Do'),
      'months',
      'MMM',
      'MMMM YYYY',
      (momentObj, value) => momentObj.month(value - 1),
      (momentObj) => momentObj.month() + 1,
      (dates) => dates.months,
      (dates, values) => (dates.months = values),
      true
    ),
    new TimeScale(
      Resolution.DAILY,
      (value) => moment(value.toString(), 'H').format('hA'),
      'days',
      'Do',
      'Do MMM YYYY',
      (momentObj, value) => momentObj.date(value),
      (momentObj) => momentObj.date(),
      (dates) => dates.days,
      (dates, values) => (dates.days = values),
      true
    ),
    new TimeScale(
      Resolution.HOURLY,
      (value) => moment(value.toString(), 'mm').format('mm'),
      'hours',
      'hA',
      'Do MMM hA',
      (momentObj, value) => momentObj.hour(value),
      (momentObj) => momentObj.hour(),
      (dates) => dates.hours,
      (dates, values) => (dates.hours = values)
    ),
    new TimeScale(
      Resolution.FIVE_MINUTES,
      () => '',
      'minutes',
      'hA:mm',
      'Do MMM hA:mm',
      (momentObj, value) => momentObj.minute(value),
      (momentObj) => momentObj.minute(),
      (dates) => dates.minutes,
      (dates, values) => (dates.minutes = values)
    )
  ];
}
