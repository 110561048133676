import { Injectable } from '@angular/core';
import { IDropdown } from '../models/dropdown.interface';

@Injectable({
  providedIn: 'root'
})
export class DropdownService {
  private readonly dropdowns: IDropdown[];

  constructor() {
    this.dropdowns = [];
  }

  public add(dropdown: IDropdown) {
    this.dropdowns.push(dropdown);
  }

  public notify(id: string) {
    for (const dropdown of this.dropdowns) {
      if (dropdown.getDropdownName() !== id) {
        dropdown.isActive().subscribe(() => {
          dropdown.collapse();
        });
      }
    }
  }

  public notifyMousedown(target: EventTarget) {
    if (this.dropdowns.filter((elem) => elem.isActive()).length > 0) {
      if (this.findAncestor(target, 'or-dropdown') == null) {
        this.dropdowns.filter((elem) => elem.isActive()).forEach((elem) => elem.collapseWithApply());
      }
    }
  }

  private findAncestor(elem, clazz) {
    while ((elem = elem.parentElement) && !elem.classList.contains(clazz)) {}
    return elem;
  }
}
