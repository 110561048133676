export class Floor {
  constructor(
    public buildingId?: number,
    public floorNumber: number = null,
    public name: string = null,
    public note: string = null,
    public floorplan?: string,
    public id?: number
  ) {}

  public static toOutline(other: Floor): Floor {
    return new Floor(
      other.buildingId,
      other.floorNumber,
      other.name,
      other.note,
      other.floorplan,
      other.id
    );
  }
}
