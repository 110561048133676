import { OrLuminaireDriverController } from '@angularjs/angular/modules/layout/or-sensor-node/or-luminaire-driver/OrLuminaireDriverController';
import * as d3 from 'd3';
import * as angular from 'angular';

export class OrLuminaireDriverDirective {
  constructor() {
    return {
      bindToController: true,
      link: (scope, element) => new OrLumiDriverTagLink(scope, element),
      controller: ['$scope', 'SensorNodeService', OrLuminaireDriverController],
      controllerAs: 'lumiDriver',
      replace: true,
      restrict: 'E',
      scope: {
        driver: '=',
        node: '=',
        disableSelection: '=',
        markFaulty: '=',
        markEmergencyLight: '=',
        buildingId: '<',
        notificationMode: '=',
        zoomLevel: '='
      },
      template: require('raw-loader!./or-luminaire-driver.html').default
    };
  }
}

export class OrLumiDriverTagLink {
  private defaultSize = 24;
  public tagsContainer: any;
  public size: any;

  constructor(private scope, private element) {
    this.scope.$watchCollection('[lumiDriver.node.tags]', () => this.renderPieChart(), true);
    this.scope.$watch('lumiDriver.node', () => this.renderPieChart(), true);
  }

  private loadSizes() {
    const ratio = 2;
    this.tagsContainer = d3.select(this.element.find('svg')[0]);
    this.size = {
      width: this.defaultSize / ratio,
      height: this.defaultSize / ratio,
      outerRadius: this.defaultSize / (2 * ratio),
      innerRadius: this.defaultSize / (3 * ratio)
    };
  }

  public renderPieChart() {
    this.loadSizes();
    const arc = d3.arc().outerRadius(this.size.outerRadius).innerRadius(this.size.innerRadius);
    const pie = d3.pie().value(function () {
      return 1;
    }); // 360 / node.details.tags.length; });

    this.tagsContainer.selectAll('*').remove();
    this.tagsContainer
      .append('g')
      .attr('transform', this.translate(this.size.outerRadius, this.size.outerRadius))
      .selectAll('.luminaire-driver-tag')
      .data(pie(this.scope.lumiDriver.node.tags || []))
      .enter()
      .append('path')
      .attr('class', 'luminaire-driver-tag')
      .attr('d', arc)
      .attr('fill', (d) => '#' + d.data.color)
      .attr('title', (d) => d.data.name);
  }

  public translate(x: number, y: number): string {
    if (!angular.isDefined(y)) {
      y = x;
    }
    return 'translate(' + x + ',' + y + ')';
  }
}
