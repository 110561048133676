import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Observable, of, tap } from 'rxjs';
import { catchError } from 'rxjs/operators';

export interface IConfig {
  commitHash: string;
}

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private httpClient: HttpClient;
  private configuration: IConfig;
  private defaultConfig: IConfig = {
    commitHash: ''
  };
  constructor(private httpBackend: HttpBackend) {
    // We do not want the custom http interceptor to intercept the http requests
    // made from configuration service. So we pass `HttpBackend` which will bypass our interceptor
    this.httpClient = new HttpClient(httpBackend);
  }

  loadConfig(): Observable<IConfig> {
    return this.httpClient.get<IConfig>('./assets/config.json').pipe(
      tap({
        next: (value) => {
          this.configuration = value;
        },
        error: (_) => {
          this.configuration = this.defaultConfig;
        }
      }),
      catchError((error) => {
        console.error(error.message);
        return of(null);
      })
    );
  }

  get config(): IConfig {
    return this.configuration;
  }
}
