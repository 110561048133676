import { Inject, Injectable } from '@angular/core';
import { Resource } from '@app/shared/resources/resource';
import { MobileToken } from '@app/shared/models/mobile-token.interface';
import { HttpClient } from '@angular/common/http';
import { Environment, environmentToken } from '@environment';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MobileTokenResource extends Resource<MobileToken, number> {
  constructor(protected http: HttpClient, @Inject(environmentToken) environment: Environment) {
    super(http, `${environment.apiUrl}${environment.apiPath}/mobile-token`);
  }

  getAllTokensForBuilding(buildingId: number): Observable<MobileToken[]> {
    return this.http.get<MobileToken[]>(`${this.baseUrl}/${buildingId}`);
  }

  createToken(buildingId: number, token: MobileToken): Observable<MobileToken> {
    return this.http.post<MobileToken>(`${this.baseUrl}/create/${buildingId}`, token.description);
  }

  updateToken(buildingId: number, token: MobileToken): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/${buildingId}/${token.id}`, token.description);
  }

  deactivateToken(buildingId: number, tokenId: number): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/deactivate/${buildingId}/${tokenId}`, null);
  }
}
