import { NumericUtils } from '../util/NumericUtils';

export enum ZoomOperation {
  ZOOM_IN,
  ZOOM_OUT
}
export class ImageZoomService {
  private scale: number;
  private attemptToRecenter: boolean;
  private centered: boolean;

  constructor(
    private initialScale: number = 1.0,
    private minimumScale: number = 0.1,
    private maximumScale: number = 2.0,
    private scaleFactor: number = 1.2
  ) {
    this.reset();
  }

  public reset() {
    this.scale = this.initialScale;
  }

  public resetAndRecenter() {
    this.reset();
    this.attemptToRecenter = true;
  }

  public setCentered() {
    this.attemptToRecenter = false;
    this.centered = true;
  }

  public setUnCentered() {
    this.centered = false;
  }

  public shouldAttemptToRecenter(): boolean {
    return this.attemptToRecenter;
  }

  public isCentered(): boolean {
    return this.centered;
  }

  public zoom(operation: ZoomOperation) {
    if (this.canZoom(operation)) {
      this.scale = this.calculateNewScale(operation);
    }
  }

  public isScaled(): boolean {
    return this.scale != this.initialScale;
  }

  public canZoom(operation: ZoomOperation): boolean {
    const newScale = this.calculateNewScale(operation);
    return (
      newScale != this.scale &&
      (newScale >= this.minimumScale || operation == ZoomOperation.ZOOM_IN) &&
      (newScale <= this.maximumScale || operation == ZoomOperation.ZOOM_OUT)
    );
  }

  private calculateNewScale(operation: ZoomOperation): number {
    let val;
    if (operation == ZoomOperation.ZOOM_IN) {
      val = NumericUtils.roundFloatingPoint(this.scale * this.scaleFactor, 2);
      return val > this.maximumScale ? this.maximumScale : val;
    }
    if (operation == ZoomOperation.ZOOM_OUT) {
      val = NumericUtils.roundFloatingPoint(this.scale / this.scaleFactor, 2);
      return val < this.minimumScale ? this.minimumScale : val;
    }
  }

  public getScale(): number {
    return this.scale;
  }

  public setScale(scale: number) {
    this.scale = scale;
  }

  public setDefaultScale(scale: number) {
    this.initialScale = scale;
  }
}
