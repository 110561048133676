import { OrLuminaireDriverTileController } from './OrLuminaireDriverTileController';

export class OrLuminaireDriverTileDirective {
  constructor() {
    return {
      bindToController: true,
      controller: ['$scope', '$element', 'SecurityService', 'FilterService', OrLuminaireDriverTileController],
      controllerAs: 'driver',
      replace: true,
      restrict: 'E',
      scope: {
        activeNode: '=',
        isCompact: '=',
        details: '=',
        node: '=',
        showHidden: '=',
        onSelect: '&',
        scrollTo: '='
      },
      template: require('raw-loader!./or-luminaire-driver-tile.html').default
    };
  }
}
