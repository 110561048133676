import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ColorUtils } from '@app/shared/utils/color.utils';
import { CheckboxState, FloorplanTag, TagType } from '@app/shared/models/tag.interface';
import { SharedComponentsModule } from '@app/shared/shared-components.module';

@Component({
  standalone: true,
  selector: 'app-create-tag-form',
  templateUrl: './create-tag-form.component.html',
  imports: [
    SharedComponentsModule
  ],
  styleUrls: ['./create-tag-form.component.scss']
})
export class CreateTagFormComponent implements OnInit {
  @Input() buildingId: number;
  @Output() createTag = new EventEmitter<FloorplanTag>();

  tagForm: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.tagForm = this.fb.group({
      name: ['', Validators.required],
      color: ['', Validators.required]
    });
  }

  cycleColor(): string {
    const color = ColorUtils.generateColor();
    this.tagForm.patchValue({ color });
    this.tagForm.markAsDirty();
    return color;
  }

  reset(): void {
    this.tagForm.reset();
  }

  create(): FloorplanTag {
    const tag: FloorplanTag = {
      buildingId: this.buildingId,
      name: this.tagForm.value.name,
      color: this.tagForm.value.color,
      tagType: TagType.STANDARD,
      isActive: true,
      isDirty: false,
      checked: CheckboxState.UNCHECKED
    };
    this.createTag.emit(tag);
    this.tagForm.reset();
    return tag;
  }
}
