import { OrGatewayTagsController } from './OrGatewayTagsController';

export class OrGatewayTagsDirective {
  constructor() {
    return {
      bindToController: true,
      controller: [
        'QueryContext',
        'BuildingService',
        'SensorNodeService',
        'UIRefreshService',
        '$scope',
        OrGatewayTagsController
      ],
      controllerAs: 'gatewayTags',
      replace: true,
      restrict: 'E',
      scope: {},
      template: require('raw-loader!./or-gateway-tags.html').default
    };
  }
}
