import { OrLightingConfigurationNodeTileController } from './OrLightingConfigurationNodeTileController';

export class OrLightingConfigurationNodeTileDirective {
  constructor() {
    return {
      bindToController: true,
      controller: [
        '$scope',
        '$element',
        'SecurityService',
        'SensorNodeService',
        'FilterService',
        'UIRefreshService',
        'LightingConfigurationService',
        'FeatureService',
        OrLightingConfigurationNodeTileController
      ],
      controllerAs: 'lightingConfigurationNodeTile',
      replace: true,
      restrict: 'E',
      scope: {
        activeNode: '=',
        isCompact: '=',
        details: '=',
        onSelect: '&',
        scrollTo: '='
      },
      template: require('raw-loader!./or-lighting-configuration-node-tile.html').default
    };
  }
}
