import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, delay, Observable, of, switchMap } from 'rxjs';
import { UserService } from '@app/shared/services/user/user.service';
import { catchError } from 'rxjs/operators';
import { UserRole } from '@app/shared/models/user-role.interface';

interface ISecurityService {
    isAdminRequested: () => Observable<boolean>;
}

export class UserDatasource implements DataSource<UserRole> {

    private usersSubject = new BehaviorSubject<UserRole[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    private countSubject = new BehaviorSubject<number>(0);
    public counter$ = this.countSubject.asObservable();
    public loading$ = this.loadingSubject.asObservable();

    constructor(private usersService: UserService,
                private buildingId: number,
                private securityService: ISecurityService) {}

    loadUsers(pageIndex: number, pageSize: number): void {
        this.loadingSubject.next(true);

        this.securityService.isAdminRequested().pipe(
            switchMap((isAdmin) => {
               return this.usersService.getUserRolesForBuilding(this.buildingId, pageIndex, pageSize, isAdmin).pipe(
                   catchError(() => of([])),
                   delay(500),
               );
            }),
        ).subscribe((userRoles) => {
            this.usersSubject.next(userRoles.content);
            this.countSubject.next(userRoles.totalElements);
            this.loadingSubject.next(false);
        });

    }

    connect(_collectionViewer: CollectionViewer): Observable<UserRole[]> {
        return this.usersSubject.asObservable();
    }

    disconnect(_collectionViewer: CollectionViewer): void {
        this.usersSubject.complete();
        this.loadingSubject.complete();
    }

}