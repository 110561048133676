import { Component, OnInit } from '@angular/core';
import { ConfigService } from '@app/config-service';
import { MatDialog } from '@angular/material/dialog';
import { PrivacyNoticeComponent } from '@components/footer/privacy-notice/privacy-notice.component';
import { UserService } from '@services/user/user.service';
import { User } from '@app/shared/models/user.interface';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  constructor(private configService: ConfigService, private userService: UserService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.userService.getCurrentUser().subscribe((user: User) => {
      if (user.joinedAt == null) {
        this.showPrivacyPolicy(true);
      }
    });
  }

  public getCommitHash(): string {
    return this.configService.config.commitHash;
  }

  showPrivacyPolicy(showActions: boolean): void {
    this.dialog.open(PrivacyNoticeComponent, { data: showActions, disableClose: true });
  }
}
