export class EmergencyLightingSchedulingStrategy {

    constructor(
        public value: EmergencyLightingSchedulingStrategy_,
        public displayValue: string,
        public timeMultiplier: number
    ) {}

    public get display(): string {
        return this.displayValue;
    }

    public static ODDS_AND_EVENS = new EmergencyLightingSchedulingStrategy('ODDS_AND_EVENS', 'Odds and Evens', 2);
    public static SIMULTANEOUS = new EmergencyLightingSchedulingStrategy('SIMULTANEOUS', 'Simultaneous', 1);

    public static all(): EmergencyLightingSchedulingStrategy[] {
        return [
            EmergencyLightingSchedulingStrategy.ODDS_AND_EVENS,
            EmergencyLightingSchedulingStrategy.SIMULTANEOUS
        ];
    }

    public static fromValue(value: string): EmergencyLightingSchedulingStrategy {
        switch (value) {
            case EmergencyLightingSchedulingStrategy.ODDS_AND_EVENS.value:
                return EmergencyLightingSchedulingStrategy.ODDS_AND_EVENS;
            case EmergencyLightingSchedulingStrategy.SIMULTANEOUS.value:
                return EmergencyLightingSchedulingStrategy.SIMULTANEOUS;
        }
    }

    public toString(): string {
        return this.value;
    }

    public equals(other: EmergencyLightingSchedulingStrategy): boolean {
        return this.equalsString(other.value);
    }

    public equalsString(other: string): boolean {
        return other !== null && other === this.value;
    }
}

type EmergencyLightingSchedulingStrategy_ = 'ODDS_AND_EVENS' | 'SIMULTANEOUS';
