import { SessionService } from '@angularjs/or/services/SessionService';
import { Redirect } from '@angularjs/or/util/Redirect';
import { ILoginRouteParamsService } from './LoginConfig';
import { NavigationSectionInfo } from '@app/shared/services/navigation/navigation-section-info';
import { LoginMethodsService } from '@angularjs/or/services/LoginMethodsService';
import { AuthMethod } from '@app/shared/models/auth-method';

import IScope = angular.IScope;

export class LoginController {
  public redirectUrl: string;
  public loginMethods: AuthMethod[] = [AuthMethod.AUTH0];

  constructor(
    private $scope: IScope,
    private sessionService: SessionService,
    private $routeParams: ILoginRouteParamsService,
    private mysectionInfo: NavigationSectionInfo,
    private loginMethodsService: LoginMethodsService
  ) {
    this.redirectUrl = Redirect.readRedirectUrl($routeParams);
    this.loginMethodsService.getSupportedLoginMethods().then((loginMethods) => {
      this.$scope.$apply(() => {
        if (loginMethods) {
          this.loginMethods = loginMethods;
          if (
            loginMethods.length == 1 &&
            !this.$routeParams.alreadyHandledAuthError
          ) {
            this.authenticate(loginMethods[0]);
          }
        }
      });
    });
  }

  public authenticate(loginMethod: AuthMethod) {
    if (this.redirectUrl) {
      this.sessionService.authenticate(loginMethod, this.redirectUrl);
    } else {
      this.sessionService.authenticate(loginMethod);
    }
  }

  public getLoginButtonClass(loginMethod: AuthMethod): string {
    if (AuthMethod.LINKEDIN.toString() === loginMethod.toString()) {
      return 'or-icon-linkedin';
    } else {
      return 'or-icon-or';
    }
  }

  public getLoginButtonLabel(loginMethod: AuthMethod): string {
    if (AuthMethod.LINKEDIN.toString() === loginMethod.toString()) {
      return 'Sign in with ' + AuthMethod.LINKEDIN.displayName;
    } else {
      return 'Custom authentication';
    }
  }
}
