import { Component, Inject, OnInit, Optional } from '@angular/core';
import { HotToastRef } from '@ngneat/hot-toast';
import { ToastData } from '@services/toast/toast.service';

@Component({
  selector: 'app-custom-toast',
  templateUrl: './custom-toast.component.html',
  styleUrls: ['./custom-toast.component.scss']
})
export class CustomToastComponent implements OnInit {
  message: string;
  dataCy = '';

  constructor(@Optional() @Inject(HotToastRef) public toastRef: HotToastRef<ToastData>) {
    this.message = toastRef.data.message;
    this.dataCy = toastRef.data.dataCy;
  }

  ngOnInit(): void {}
}
