import { Component, Input, OnInit } from '@angular/core';
import { IDropdown } from '@app/shared/models/dropdown.interface';
import { User } from '@app/shared/models/user.interface';
import { DropdownService } from '@app/shared/services/dropdown.service';
import { SessionService } from '@app/shared/services/session.service';
import { UserService } from '@app/shared/services/user/user.service';
import { GLOBAL_ADMIN_ROUTE, USER_ROUTE } from '@common/route-constants';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-session-menu',
  templateUrl: './session-menu.component.html',
  styleUrls: ['../dropdown/dropdown.scss', './session-menu.component.scss']
})
export class SessionMenuComponent implements IDropdown, OnInit {
  @Input() public user$: Observable<User>;

  public readonly isActive$ = new BehaviorSubject<boolean>(false);
  public readonly userInfoBaseUrl = USER_ROUTE.path;
  public readonly globalAdministrationBaseUrl = GLOBAL_ADMIN_ROUTE.path;

  constructor(
    public sessionService: SessionService,
    public userService: UserService,
    private dropdownService: DropdownService
  ) {
    this.dropdownService.add(this);
  }

  ngOnInit(): void {}

  logout(): void {
    this.sessionService.logout();
  }

  producePictureUrlStyle(user: User): { [key: string]: string } {
    return !!user && user.pictureUrl ? { 'background-image': `url(${user.pictureUrl})` } : {};
  }

  produceUserClass(user: User): { [key: string]: boolean } {
    const classes = {};
    if (!!user && !user.pictureUrl) {
      classes['or-icon-userpic'] = true;
      if (!this.isActive) {
        classes['or-inverse'] = true;
      }
    }

    return classes;
  }

  toggle(): void {
    this.isActive$.next(!this.isActive$.getValue());
  }

  isActive(): Observable<boolean> {
    return this.isActive$;
  }

  collapse(): void {
    this.isActive$.next(false);
  }

  collapseWithApply(): void {
    this.collapse();
  }

  getDropdownName(): string {
    return 'sessions';
  }

  setActive(state: boolean = true): void {
    this.isActive$.next(state);
  }
}
