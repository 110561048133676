import { OrBannerNoGatewaysController } from './OrBannerNoGatewaysController';

export class OrBannerNoGatewaysDirective {
  constructor() {
    return {
      bindToController: true,
      controller: OrBannerNoGatewaysController,
      controllerAs: 'banner',
      replace: true,
      restrict: 'E',
      scope: {},
      template: require('raw-loader!./or-banner-no-gateways.html').default
    };
  }
}
