<div id="orAdministrationGateways">
  <div class="or-content-body" *ngIf="!isBusy">
    <ol class="or-gateways-list">
      <li class="or-gateways-list-item" *ngFor="let gateway of gateways$ | async">
        <app-form-gateway-details
          [isNew]="false"
          [isBusy]="isGatewayListBusy"
          [gateway]="gateway"
          [afterUpdate$]="afterUpdate$"
          [error$]="onError$"

          (addGateway)="onAddGateway($event)"
          (updateGateway)="onUpdateGateway($event)"
          (compareGatewayStates)="onCompareGateways($event)"
          (deleteGateway)="deleteGateway($event)"
          (generateKeys)="generateKeys($event)"
          (restartGateway)="restartGateway($event)"
          (resetGateway)="resetGateway($event)"
        >
        </app-form-gateway-details>
      </li>
    </ol>
    <ol class="or-gateways-list">
      <li class="or-gateways-list-item" *ngIf="newGatewayDetails">
        <div class="or-form-header">
          <h2 class="or-form-title">New Gateway</h2>
        </div>
        <app-form-gateway-details
          [isNew]="true"
          [isBusy]="isGatewayListBusy"
          [gateway]="newGatewayDetails"
          [afterUpdate$]="afterUpdate$"
          [error$]="onError$"

          (addGateway)="onAddGateway($event)"
          (deleteGateway)="deleteGateway($event)"
          (generateKeys)="generateKeys($event)"
          (restartGateway)="restartGateway($event)"
          (resetGateway)="resetGateway($event)"
        >
        </app-form-gateway-details>
      </li>
    </ol>
    <button
            mat-raised-button
            color='primary'
            (click)="export()"
            type="button"
            data-cy='export-report'>
      <span class='or-icon or-small or-inverse or-icon-report'></span>
      <span class='or-button-label'>Extract Gateway Report</span>
    </button>
  </div>
</div>
