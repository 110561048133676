import { ISimpleService } from '../api/ISimpleService';
import { AuthMethod } from '@app/shared/models/auth-method';

export class SessionService {
  constructor(
    private $window,
    private $location,
    private loginService: ISimpleService<any, any, any, any>,
    private authenticateBaseUrl: (
      loginMethod: string,
      authUrl?: string
    ) => string,
    private logoutBaseUrl: string,
    private postLogoutRedirectBaseUrl: string
  ) {
    this.$window = $window;
    this.$location = $location;
  }

  authenticate(loginMethod?: AuthMethod, redirectUrl?: string) {
    console.debug(
      'Session Service is setting the window location.',
      redirectUrl
    );
    const loginMethodString = loginMethod
      ? loginMethod.toString().toLocaleLowerCase()
      : AuthMethod.LINKEDIN.toString().toLowerCase();

    let url;
    if (redirectUrl?.startsWith('/auth/invite/')) {
      url = this.authenticateBaseUrl(
        loginMethodString,
        encodeURIComponent(redirectUrl)
      );
    } else {
      url =
        this.authenticateBaseUrl(loginMethodString) +
        (redirectUrl != null ? encodeURIComponent(redirectUrl) : '/buildings');
    }
    this.$window.location = url;
  }

  logout() {
    this.loginService.post(this.logoutBaseUrl).then((response) => {
      this.$window.location = response;
    });
  }

  handleFailedRequest(response) {
    if (response.status === 401) {
      this.$window.location =
        this.postLogoutRedirectBaseUrl +
        `?redirectUrl=${this.$location.path()}`;
    }
  }
}
