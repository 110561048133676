import { OrDatepickerController } from './OrDatepickerController';

export class OrDatepickerDirective {
  constructor() {
    return {
      bindToController: true,
      controller: [OrDatepickerController],
      controllerAs: 'datepicker',
      replace: true,
      restrict: 'E',
      scope: {
        label: '@',
        date: '=ngModel',
        dateFormat: '@',
        min: '@',
        max: '@',
        change: '=ngChange'
      },
      template: require('raw-loader!./or-datepicker.html').default
    };
  }
}
