import { OrInlineTagsController } from './OrInlineTagsController';

export class OrInlineTagsDirective {
  constructor() {
    return {
      bindToController: true,
      controller: ['BuildingService', 'SecurityService', '$scope', OrInlineTagsController],
      controllerAs: 'inlineTags',
      replace: true,
      restrict: 'E',
      require: 'ngModel',
      scope: {
        isEditable: '=',
        onChange: '&',
        options: '=',
        selection: '=ngModel'
      },
      template: require('raw-loader!./or-inline-tags.html').default
    };
  }
}
