import { OrNodeTileController } from '../or-node-tile/OrNodeTileController';
import { ISecurityService } from '@angularjs/or/services/ISecurityService';
import { SensorNodeService } from '@angularjs/or/services/SensorNodeService';
import { FilterService } from '@angularjs/or/services/FilterService';
import { UIRefreshService } from '@angularjs/or/services/UIRefreshService';
import { LightingConfigurationService } from '@angularjs/or/services/LightingConfigurationService';
import angular from 'angular';
import { FeatureService } from '@app/shared/services/feature.service';

export class OrLightingConfigurationNodeTileController extends OrNodeTileController {
  private mouseOverZoneText: string;
  private mouseLeaveZoneText: string;
  public zoneTextToShow: string;
  private isMouseOverZone: boolean;

  public updateStatusTextToShow: string;
  private isMouseOverUpdateStatus: boolean;
  public scrollTo;
  public isExpanded = false;
  constructor(
    $scope: ng.IScope,
    $element,
    securityService: ISecurityService,
    nodesService: SensorNodeService,
    filterService: FilterService,
    uiRefreshService: UIRefreshService,
    public lightingConfigurationService: LightingConfigurationService,
    featureService: FeatureService
  ) {
    super(
      $scope,
      $element,
      securityService,
      nodesService,
      filterService,
      uiRefreshService,
      featureService
    );
  }

  $onInit() {
    super.$onInit();
    this.mouseOverZoneText = this.details.lightingConfigurationZone;
    this.mouseLeaveZoneText =
      this.details.lightingConfiguration == null ||
      this.details.lightingConfiguration.zone == null
        ? 'N / A'
        : this.lightingConfigurationService.convertZoneIdToName(
            this.details.lightingConfiguration.zone
          );
    this.zoneTextToShow = this.mouseLeaveZoneText;
    this.isMouseOverZone = false;
    this.sensorNodeService.registerToListedNodes(this.details);
  }

  public getClassValueColorForUpdateStatus(): string {
    let className = 'or-red';
    if (this.isMouseOverUpdateStatus) {
      if (this.details.lightingConfigurationUpdateStatus == 'N/A') {
        className = 'or-mouseover-not-available';
      } else {
        className = 'or-mouseover-update-status';
      }
    }
    return className;
  }
  public getClassValueColorForZone(): string {
    let className = 'or-red';
    if (this.isMouseOverZone) {
      if (this.details.lightingConfigurationZone == 'N/A') {
        className = 'or-mouseover-not-available';
      } else {
        className = 'or-mouseover-zone';
      }
    }
    return className;
  }

  public selectNode() {
    if (!this.isExpanded && angular.isFunction(this.onSelect)) {
      this.onSelect();
    }
    if (this.details.tags != null && this.details.tags.length > 0) {
      this.isExpanded = !this.isExpanded;
    }
  }

  public mouseOverZone() {
    this.zoneTextToShow = this.mouseOverZoneText;
    this.isMouseOverZone = true;
  }

  public mouseLeaveZone() {
    this.zoneTextToShow = this.mouseLeaveZoneText;
    this.isMouseOverZone = false;
  }
}
