import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { BehaviorSubject, merge, Observable, of, Subject, switchMap } from 'rxjs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { finalize, takeUntil } from 'rxjs/operators';
import { WidgetItem } from '@app/dashboard/model/widget-item';
import { RefreshPageService } from '@app/shared/services/refresh-page/refresh-page.service';
import { NumericUtils } from '@app/shared/utils/numeric';

@Component({
  selector: 'app-count-widget',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.Emulated,
  imports: [CommonModule, MatProgressSpinnerModule],
  templateUrl: './count-widget.component.html',
  styleUrl: './count-widget.component.scss'
})
export class CountWidgetComponent implements OnInit, OnDestroy {
  @Input({ required: true }) item: WidgetItem;
  @Input({ required: true }) refreshEvent: EventEmitter<WidgetItem>;
  body: string;
  isLoadingSource = new BehaviorSubject(true);
  private destroy$ = new Subject<void>();

  constructor(private refreshPageService: RefreshPageService) {}

  ngOnInit(): void {
    merge(
      this.item.getData().pipe(finalize(() => this.isLoadingSource.next(false))),
      this.refreshEvent.pipe(
        switchMap((item) => {
          if (!item || item.id === this.item.id) {
            this.isLoadingSource.next(true);
            return this.item.getData().pipe(finalize(() => this.isLoadingSource.next(false)));
          }
          return of();
        })
      ),
      this.refreshPageService.pageRefresh$.pipe(
        switchMap(() => {
          this.isLoadingSource.next(true);
          return this.item.getData().pipe(finalize(() => this.isLoadingSource.next(false)));
        })
      )
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data: any) => {
          this.body = NumericUtils.fixDigitsInValue(data);
        },
        error: (err) => console.log(err)
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get isLoading$(): Observable<boolean> {
    return this.isLoadingSource.asObservable();
  }
}
