import { Injectable } from '@angular/core';
import { HotToastService, ToastOptions } from '@ngneat/hot-toast';
import { CreateHotToastRef } from '@ngneat/hot-toast/lib/hot-toast.model';
import { CustomToastComponent } from '@components/toast/custom-toast/custom-toast.component';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  constructor(private toastService: HotToastService) {}

  public show(data: ToastData): CreateHotToastRef<ToastData | {}> {
    return this.toastService.show<ToastData>(CustomToastComponent, { data });
  }

  public info(data: ToastData): CreateHotToastRef<ToastData | {}> {
    return this.toastService.info<ToastData>(CustomToastComponent, { data });
  }
  public success(data: ToastData): CreateHotToastRef<ToastData | {}> {
    return this.toastService.success<ToastData>(CustomToastComponent, { data });
  }

  public error(data: ToastData): CreateHotToastRef<ToastData | {}> {
    return this.toastService.error<ToastData>(CustomToastComponent, { data });
  }

  public warning(data: ToastData): CreateHotToastRef<ToastData | {}> {
    return this.toastService.warning<ToastData>(CustomToastComponent, { data });
  }
}

export interface ToastData extends ToastOptions<any> {
  message: string;
  dataCy: string;
}
