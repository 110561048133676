import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { BuildingAuthorityType } from '../models/building-authority-type';
import { GlobalAuthority } from '../models/global-authority';
import { User } from '../models/user.interface';
import { UserService } from './user/user.service';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {
  constructor(private userService: UserService, private route: ActivatedRoute) {}

  public isAuthorizedForBuilding(target: keyof typeof BuildingAuthorityType, buildingId: number): Observable<boolean> {
    return this.userService.getCurrentUser().pipe(map((user) => this.hasBuildingAuthority(user, target, buildingId)));
  }

  public isRoleAuthorizedForBuilding(target: BuildingAuthorityType[], buildingId: number): Observable<boolean> {
    return this.userService
      .getCurrentUser()
      .pipe(map((user) => this.hasAnyBuildingAuthority(user, target, buildingId)));
  }

  public canSeeTenantMadeTags(buildingId: number): Observable<boolean> {
    return this.userService.getCurrentUser().pipe(
      map((user) => {
        if (user.tenantIds.length > 0) {
          return true;
        } else {
          return this.hasBuildingAuthority(user, BuildingAuthorityType.MANAGE_TENANT.value, buildingId);
        }
      })
    );
  }

  private hasBuildingAuthority(user: User, target: keyof typeof BuildingAuthorityType, buildingId: number): boolean {
    return (
      user.buildingAuthorizations.filter(
        (authorization) =>
          authorization.buildingId == buildingId &&
          authorization.authorities.filter((authority) => authority.toString() === target).length > 0
      ).length > 0
    );
  }

  private hasAnyBuildingAuthority(user: User, target: BuildingAuthorityType[], buildingId: number): boolean {
    return (
      user.buildingAuthorizations.filter(
        (authorization) =>
          authorization.buildingId === buildingId &&
          authorization.authorities.filter(
            (authority) => target.filter((auth) => auth.value === authority.toString()).length > 0
          ).length > 0
      ).length > 0
    );
  }

  public hasOnlyAnalyticsViewCapability(buildingId: number): Observable<boolean> {
    return this.userService
      .getCurrentUser()
      .pipe(
        map((user) =>
          user.buildingAuthorizations.some(
            (authorization) =>
              authorization.buildingId === buildingId &&
              authorization.authorities.some(
                (authority) => authority.toString() === BuildingAuthorityType.VIEW_DASHBOARD_ANALYTICS.value
              ) &&
              !authorization.authorities.some(
                (authority) => authority.toString() === BuildingAuthorityType.VIEW_DASHBOARD_INSIGHTS.value
              )
          )
        )
      );
  }

  /**
   * This helper method is useful to know if `admin` flag has been passed as a query param
   */
  public isAdminRequested(): Observable<boolean> {
    return this.route.queryParams.pipe(map((queryParams) => queryParams.admin != null));
  }

  public isAuthorized(authority: string): Observable<boolean> {
    if (authority == GlobalAuthority.VIEW_HIDDEN_COMPONENTS.toString()) {
      return this.route.queryParams.pipe(map((queryParams) => queryParams.admin != null));
    }

    return this.userService
      .getCurrentUser()
      .pipe(map((user) => this.hasGlobalAuthority(user, GlobalAuthority.fromString(authority))));
  }

  private hasGlobalAuthority(user: User, requestedAuthority: GlobalAuthority): boolean {
    return user.globalAuthorities.filter((authority) => authority == requestedAuthority).length > 0;
  }
}
