import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IEmergencyLightingSchedule } from '@app/shared/models/emergency-lighting-schedule.interface';
import { EmergencyLightingScheduleService } from '@app/shared/services/emergency-lighting/emergency-lighting-schedule.service';
import { EmergencyLightingSchedule } from '@app/shared/models/emergency-lighting-schedule';
import { Building } from '@app/shared/models/building.interface';
import { DisplayTags } from '@app/shared/models/tag.interface';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastService } from '@services/toast/toast.service';
import { TimeUtils } from '@app/shared/utils/time.utils';
import { TimezoneUtils } from '@app/shared/utils/timezoneUtils';

@Component({
  selector: 'app-emergency-lighting-schedules-summary',
  templateUrl: './emergency-lighting-schedules-summary.component.html',
  styleUrls: ['./emergency-lighting-schedules-summary.component.scss']
})
export class EmergencyLightingSchedulesSummaryComponent implements OnInit, OnDestroy {
  @Input() isNew = false;
  @Input() schedule: IEmergencyLightingSchedule;
  @Input() building$: Observable<Building>;
  @Input() tags$: Observable<DisplayTags>;
  @Output() refreshListEmitter = new EventEmitter<IEmergencyLightingSchedule>();
  @Output() closeNewFormEmitter = new EventEmitter<{}>();
  @Output() editRankEmitter = new EventEmitter<{}>();
  @Output() newScheduleCreatedEmitter = new EventEmitter<number>();
  loadingTests = false;
  building: Building;
  private originalSchedule: IEmergencyLightingSchedule;
  private onDestroy$ = new Subject<void>();

  constructor(private scheduleService: EmergencyLightingScheduleService, private readonly toast: ToastService) {}

  ngOnInit(): void {
    this.building$.subscribe((building) => {
      this.building = building;
      if (this.isNew) {
        this.schedule = new EmergencyLightingSchedule({
          name: null,
          testsType: null,
          active: false,
          enabled: false,
          buildingId: building.id,
          tags: [],
          floors: [],
          timezone: TimezoneUtils.mapUtcToKnownTimezone(building.timeZone),
          startDateTime: null,
          endDateTime: null,
          startDate: TimeUtils.convertTimezone(new Date(), TimezoneUtils.mapUtcToKnownTimezone(building.timeZone)),
          startTime: null,
          endTime: null,
          schedulingStrategy: null,
          nodeCount: 0,
          hasRan: false,
          editable: true
        });
        this.schedule.startDate.setMilliseconds(0);
        this.schedule.startDate.setSeconds(0);
        this.schedule.startDate.setMinutes(0);
        this.schedule.startDate.setHours(0);
      }
      this.originalSchedule = EmergencyLightingSchedule.clone(this.schedule);
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  public deleteSchedule(): void {
    this.refreshListEmitter.emit();
  }

  public saveSchedule(): void {
    this.refreshListEmitter.emit(this.schedule);
  }

  public toggleCollapse(): void {
    if (this.schedule.editable) {
      this.schedule.isCollapsed = !this.schedule.isCollapsed;
    }
  }

  public resetAndCollapse(): void {
    this.schedule = EmergencyLightingSchedule.clone(this.originalSchedule);
    this.toggleCollapse();
  }

  public closeNewForm(): void {
    this.closeNewFormEmitter.emit();
  }

  public toggleScheduleEnableState(event: MatSlideToggleChange): void {
    const schedule = { ...this.schedule };
    schedule.enabled = event.checked;
    this.updateSchedule(schedule);
  }

  public preventClickPropagation(e: MouseEvent): void {
    e.stopImmediatePropagation();
  }

  private updateSchedule(schedule: IEmergencyLightingSchedule): void {
    this.scheduleService
      .updateSchedule(EmergencyLightingSchedule.toDto(schedule))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: (updatedSchedule) => {
          this.schedule = updatedSchedule;
          this.scheduleService.fetchSchedules(this.schedule.buildingId);
          this.refreshListEmitter.emit();
        },
        error: (err) => {
          this.toast.error({
            message: 'Schedule could not be updated, please try again',
            autoClose: false,
            dismissible: true,
            dataCy: `update-error-toast`
          });
          console.error(err);
          this.refreshListEmitter.emit();
        }
      });
  }

  handleNewScheduleCreation(scheduleId: number): void {
    this.newScheduleCreatedEmitter.emit(scheduleId);
  }
}
