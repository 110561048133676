import { IUserService } from '@angularjs/or/services/IUserService';
import { User } from '@angularjs/or/api/auth/User';
import { BuildingAuthorityType } from '@app/shared/models/building-authority-type';
import { Building } from '@angularjs/or/api/building/Building';
import { ImageCachingService } from '@angularjs/or/services/ImageCachingService';
import { Map } from '@angularjs/or/util/Map';
import { ArrayUtils } from '@angularjs/or/util/ArrayUtils';
import { BuildingAuthorization } from '@app/shared/models/building-authorization.interface';
import IFormController = angular.IFormController;

export class OrFormUserInfoController {
  public form: IFormController;
  public details: User;
  public onSubmit;
  public onReset;
  public parent;
  public isEditable: boolean;
  public isBusy: boolean;

  constructor(
    private $scope: ng.IScope,
    private thumbnailUrlGenerator: (building: Building) => string,
    private imageCachingService: ImageCachingService,
    private userService: IUserService
  ) {}

  public $onInit() {
    this.userService.isLocal().then((isLocal) => (this.isEditable = !isLocal));
  }

  public humanize(text: string): string {
    const type = BuildingAuthorityType.fromString(text);
    return type != null ? type.getName() : '';
  }

  public humanizeAuthorities(types: string[]): string[] {
    return types
      .map((t) => BuildingAuthorityType.fromString(t))
      .filter((t) => t != null)
      .map((t) => t.getName().toString())
      .sort();
  }

  public produceBuildingThumbnailImageStyle(building: Building): Map<string> {
    const style: Map<string> = {};
    const url = this.thumbnailUrlGenerator(building);
    // url = this.imageCachingService.updateUrl(url);
    style['background-image'] = url ? 'url(' + url + ')' : 'none';
    return style;
  }

  public getClass(authority: string): string {
    if (authority === 'None') {
      return 'or-no-authority';
    }
    return '';
  }

  public submit(): void {
    this.isBusy = true;
    this.onSubmit.bind(this.parent)(this.setPristine, this);
  }

  public reset(): void {
    this.setPristine();
    this.onReset();
  }

  private setPristine(): void {
    this.isBusy = false;
    this.form.$setPristine();
    this.form.$setUntouched();
  }

  public showNotificationWarning(permission: BuildingAuthorization): boolean {
    const hasMaintenanceUpdateRole = this.hasBuildingAuthority(
      permission,
      BuildingAuthorityType.MANAGE_MAINTENANCE_UPDATES
    );
    const hasEmergencyLightingRole = this.hasBuildingAuthority(
      permission,
      BuildingAuthorityType.MANAGE_EMERGENCY_LIGHTING
    );

    return (
      (!hasMaintenanceUpdateRole || !permission.maintenanceUpdates) &&
      hasEmergencyLightingRole
    );
  }

  private hasBuildingAuthority(
    permission: BuildingAuthorization,
    authority: BuildingAuthorityType
  ): boolean {
    return ArrayUtils.contains(
      permission.authorities,
      authority,
      (auth1: BuildingAuthorityType, auth2: BuildingAuthorityType) =>
        auth1.toString() === auth2.toString()
    );
  }
}
