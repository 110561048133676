import { Resource } from '../Resource';
import { Building } from '../../api/building/Building';
import { IResource } from '../../api/IResource';
import { PromiseUtils } from '@angularjs/or/angular/promises/PromiseUtils';

export class BuildingResource
  extends Resource<Building, number>
  implements IResource<Building, number>
{
  public getBuildings(ids: number[]): Promise<Building[]> {
    const httpParams = { ids: [] };
    ids.forEach((id) => httpParams.ids.push(String(id)));
    return PromiseUtils.wrapSingle(
      this.$http.get(this.baseUrl, { params: httpParams }),
      this.rejectionHandler
    );
  }
}
