import { IUserService } from './IUserService';
import { IBuildingService } from './IBuildingService';
import { User } from '../api/auth/User';
import { Building } from '../api/building/Building';
import { IResource } from '../api/IResource';
import { Tag } from '../api/building/Tag';
import { Floor } from '../api/building/Floor';
import { Gateway } from '../api/building/Gateway';
import { SavedEntity } from '../api/SavedEntity';
import { UserRole } from '../api/auth/UserRole';
import { IUserRoleResource } from '../api/IUserRoleResource';
import {
  MultiInvitationConfirmation,
  MultiUserInvitation,
  UserInvitation
} from '../api/building/UserInvitation';
import { AuthMethod } from '@app/shared/models/auth-method';
import {
  BuildingNotificationPreference,
  BuildingNotificatonPreferences
} from '../api/building/BuildingNotificatonPreferences';
import { IBuildingNotificatonResource } from '../api/resources/IBuildingNotificatonResource';
import { BuildingAuthorityType } from '@app/shared/models/building-authority-type';
import { PromiseUtils } from '../angular/promises/PromiseUtils';
import { IUserResource } from '../api/IUserResource';
import { EventBridge } from '@common/event-bridge';
import { BuildingAuthorization } from '@app/shared/models/building-authorization.interface';
import { IBuildingResource } from '@angularjs/or/api/IBuildingResource';
import { NavigationService } from '@app/shared/services/navigation/navigation.service';

export class UserService implements IUserService, IBuildingService {
  constructor(
    private eventBridge: EventBridge,
    private userResource: IUserResource,
    private buildingResource: IBuildingResource,
    private userRoleResource: IUserRoleResource,
    private userInvitationResource: IResource<MultiUserInvitation, number>,
    private buildingNotificationResource: IBuildingNotificatonResource,
    private navigationService: NavigationService
  ) {
    this.retrieveCurrentUser();
    this.eventBridge.getTrigger().subscribe((v) => {
      this.invalidateCurrentUser();
      this.invalidateCurrentBuilding();
    });
  }

  private userPromise: Promise<User>;
  private user: User;
  private userId: number;
  private buildingIds: number[] = null;
  private currentBuildingId: number;
  private currentBuilding: Building;
  private buildingPromise: Promise<Building>;
  private gateways: Gateway[];
  private floorsIds: number[];
  private tags: Tag[];

  retrieveCurrentUser(): void {
    this.userPromise = new Promise<User>((resolveUser) => {
      this.userResource
        .retrieveCurrent()
        .then((user: User) => {
          const buildingAuthMap = {};
          const buildingIds = user.buildingAuthorizations.map(
            (buildingAuthorization) => {
              buildingAuthMap[buildingAuthorization.buildingId] =
                buildingAuthorization;
              return buildingAuthorization.buildingId;
            }
          );
          const buildingsPromise =
            this.buildingResource.getBuildings(buildingIds);
          const buildingAuthorizations: BuildingAuthorization[] = [];
          buildingsPromise.then((buildings) => {
            buildings.forEach((building) => {
              buildingAuthorizations.push({
                ...buildingAuthMap[building.id],
                building
              });
            });
            this.setupUser(buildingAuthorizations, user, resolveUser);
          });
        })
        .catch((e) => console.error('Error when getting User', e));
    });
  }

  setupUser(
    buildingAuthorizations: BuildingAuthorization[],
    user: User,
    resolveUser
  ): void {
    buildingAuthorizations.sort((a, b) => a.building.name.localeCompare(b.building.name));
    this.userId = user.id;
    this.user = { ...user, buildingAuthorizations };
    resolveUser(this.user);
  }

  setCurrentUserId(id: number): void {
    this.userId = id;
    this.invalidateCurrentUser();
  }

  getCurrentUser(): Promise<User> {
    if (this.user != null) {
      return Promise.resolve(this.user);
    }

    if (this.userPromise != null) {
      return this.userPromise;
    }

    this.retrieveCurrentUser();
    return this.userPromise;
  }

  setCurrentBuildingId(id: number): void {
    this.currentBuildingId = id;
    this.navigationService.buildingId = this.currentBuildingId;
    this.invalidateCurrentBuilding();
  }

  getCurrentBuilding(refresh = false): Promise<Building> {
    if (!refresh) {
      if (this.currentBuilding != null) {
        return Promise.resolve(this.currentBuilding);
      }

      if (this.buildingPromise != null) {
        return this.buildingPromise;
      }
    }
    const promise = this.buildingResource.retrieve(this.currentBuildingId);

    this.buildingPromise = promise;
    return promise;
  }

  getBuildingIds(): number[] {
    if (this.buildingIds == null) {
      this.revalidateBuildingIds();
    }
    return this.buildingIds;
  }

  getFloorIdsForCurrentBuilding(refresh = false): Promise<number[]> {
    return new Promise<number[]>((resolve) => {
      this.getCurrentBuilding(refresh).then((building: Building) => {
        if (building != null) {
          this.floorsIds = [];
          building.floors.forEach((floor) => this.floorsIds.push(floor.id));
        }
        resolve(this.floorsIds);
      });
    });
  }

  getFloorsForCurrentBuilding(): Promise<Floor[]> {
    return new Promise<Floor[]>((resolve) => {
      this.getCurrentBuilding().then((building: Building) => {
        if (building != null) {
          resolve(building.floors);
        }
      });
    });
  }

  getTagsForCurrentBuilding(): Promise<Tag[]> {
    return new Promise<Tag[]>((resolve) => {
      this.getCurrentBuilding().then((building: Building) => {
        if (building != null) {
          this.tags = building.tags;
        }
        resolve(this.tags);
      });
    });
  }

  updateUser(user: User): Promise<{}[]> {
    this.invalidateCurrentUser();

    const preferences = new BuildingNotificatonPreferences(
      user.buildingAuthorizations
        .filter(
          (ba) =>
            ba.authorities.filter(
              (authority) =>
                authority == BuildingAuthorityType.MANAGE_MAINTENANCE_UPDATES
            ).length > 0
        )
        .map(
          (ba) =>
            new BuildingNotificationPreference(
              ba.building.id,
              ba.maintenanceUpdates
            )
        )
    );

    const promises = [];
    promises[0] =
      this.buildingNotificationResource.updatePreferences(preferences);
    promises[1] = this.userResource.updateCurrent(user);
    const promise = PromiseUtils.resolveMultiple(promises);
    promise.then((results) => this.retrieveCurrentUser());
    return promise;
  }

  updateBuilding(building: Building): Promise<{}> {
    this.invalidateCurrentUser();
    this.invalidateCurrentBuilding();
    const promise = this.buildingResource.update(building.id, building);
    promise.then(() => {
      this.retrieveCurrentUser();
    });
    return promise;
  }

  addBuilding(building: Building): Promise<SavedEntity<Building, number>> {
    this.invalidateCurrentUser();
    const promise = this.buildingResource.add(building);
    promise.then(() => {
      this.retrieveCurrentUser();
    });
    return promise;
  }

  deleteBuilding(buildingId: number): Promise<{}> {
    const promise = this.buildingResource.delete(buildingId);
    promise.then(() => this.retrieveCurrentUser());
    return promise;
  }

  private revalidateBuildingIds(): void {
    this.buildingIds = [];
    for (const buildingAuthorization of this.user.buildingAuthorizations) {
      this.buildingIds.push(buildingAuthorization.building.id);
    }
  }

  invalidateCurrentUser(): void {
    this.userPromise = null;
    this.user = null;
  }

  invalidateCurrentBuilding(): void {
    this.buildingPromise = null;
    this.currentBuilding = null;
    this.gateways = null;
    this.floorsIds = null;
    this.tags = null;
  }

  // TODO: When adding or removing buildings invalidate buildingIds, call invalidateAllBuildings
  public invalidateAllBuildings(): void {
    this.buildingIds = null;
    this.invalidateCurrentBuilding();
  }

  public getUserRolesForBuilding(buildingId: number): Promise<UserRole[]> {
    return this.userRoleResource.getAllForBuilding(buildingId);
  }

  // TODO: Revalidate/Update the User object when modifying the underlying.

  public removeUser(userId: number): Promise<{}> {
    return this.userRoleResource.removeUserFromBuilding(
      this.currentBuildingId,
      userId
    );
  }

  public updateUserRoles(userId: number, roleIds: number[]): Promise<{}> {
    return new Promise((resolve, reject) => {
      this.userRoleResource
        .updateUserRoles(this.currentBuildingId, new UserRole(userId, roleIds))
        .then((result) => {
          this.invalidateCurrentUser();
          resolve(result);
        })
        .catch((error) => reject(error));
    });
  }

  public inviteUsers(
    invitations: UserInvitation[]
  ): Promise<MultiInvitationConfirmation> {
    return this.userInvitationResource.add(
      new MultiUserInvitation(this.currentBuildingId, invitations)
    ) as any;
  }

  public isLocal(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.getCurrentUser().then((user) => {
        resolve(
          AuthMethod.fromString(user.authMethod.toString()) === AuthMethod.GLUU
        );
      });
    });
  }

  public join(): Promise<User> {
    return this.userResource.join();
  }

  public delete(id: number): Promise<{}> {
    return this.userResource.delete(id);
  }

  getBuildings(ids: number[]): Promise<Building[]> {
    return this.buildingResource.getBuildings(ids);
  }
}
