import { Observable } from '../util/Observable';
import { ITenant } from '@app/shared/models/tenant.interface';
import { EventEmitter } from '@angular/core';

export class UIRefreshService {
  private observables: { [entity: string]: Observable<any> } = {};
  public activatedTenant = new EventEmitter<ITenant>();
  public floorsVisibleToTenant = new EventEmitter<number[]>();

  public onChange(entity: Entity, observer: (T) => void): void {
    if (this.observables[entity.toString()] == null) {
      this.observables[entity.toString()] = new Observable<any>(null);
    }

    this.observables[entity.toString()].onChange(observer);
  }

  public change(entity: Entity): void {
    if (this.observables[entity.toString()]) {
      this.observables[entity.toString()].change(() => {});
    }
  }
}

export class Entity {
  constructor(public value: Entity_) {}

  static USER = new Entity('USER');
  static BUILDING = new Entity('BUILDING');
  static FLOOR = new Entity('FLOOR');
  static NODE = new Entity('NODE');
  static TAG = new Entity('TAG');
  static PAGE = new Entity('PAGE');
  static TENANT = new Entity('TENANT');

  toString(): string {
    return this.value;
  }
}

type Entity_ =
  | 'USER'
  | 'BUILDING'
  | 'FLOOR'
  | 'NODE'
  | 'TAG'
  | 'PAGE'
  | 'TENANT';
