import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Environment, environmentToken } from 'src/environments/environment.interface';
import { UserRole } from '../models/user-role.interface';
import { Resource } from './resource';
import { IUserRoleResource } from './user-role.interface';

@Injectable({
  providedIn: 'root'
})
export class UserRoleResource extends Resource<UserRole, number> implements IUserRoleResource {
  constructor(private http: HttpClient, @Inject(environmentToken) environment: Environment) {
    super(http, `${environment.apiUrl + environment.apiPath}/user-roles`);
  }

  public getAllForBuilding(
    buildingId: number,
    pageNumber: number,
    pageSize: number,
    viewForAdmin: boolean
  ): Observable<any> {
    return this.http.get<UserRole[]>(`${this.baseUrl}/${buildingId}`, {
      params: new HttpParams()
        .set('page', pageNumber.toString())
        .set('size', pageSize.toString())
        .set('admin', viewForAdmin)
    });
  }

  public removeUserFromBuilding(buildingId: number, userId: number): Observable<{}> {
    return this.http.delete<UserRole>(`${this.baseUrl}/${buildingId}/${userId}`);
  }

  public updateUserRoles(buildingId: number, userRole: UserRole): Observable<{}> {
    return this.http.put<UserRole>(`${this.baseUrl}/${buildingId}`, userRole);
  }
}
