export class EmergencyLightingTestType {
  constructor(
    public value: EmergencyLightingTestType_,
    public displayValue: string,
    public expectedDurationHours: number
  ) {}

  public get display(): string {
    return this.displayValue;
  }

  public get val(): EmergencyLightingTestType_ {
    return this.value;
  }

  public static FUNCTION = new EmergencyLightingTestType('FUNCTION', 'Functional', 1);
  public static DURATION = new EmergencyLightingTestType('DURATION', 'Duration', 5.5);

  public static all(): EmergencyLightingTestType[] {
    return [EmergencyLightingTestType.FUNCTION, EmergencyLightingTestType.DURATION];
  }

  public static fromValue(value: string): EmergencyLightingTestType {
    switch (value) {
      case EmergencyLightingTestType.FUNCTION.value:
        return EmergencyLightingTestType.FUNCTION;
      case EmergencyLightingTestType.DURATION.value:
        return EmergencyLightingTestType.DURATION;
    }
  }

  static isFunctionalTest(test: string | EmergencyLightingTestType): boolean {
    if (typeof test === 'string' && this.isOfTypeTestType(test)) {
      return test === 'FUNCTION';
    }
    if (test instanceof EmergencyLightingTestType) {
      return test.value === 'FUNCTION';
    }
  }

  private static isOfTypeTestType(keyInput: string): keyInput is EmergencyLightingTestType_ {
    return ['FUNCTION', 'DURATION'].includes(keyInput);
  }

  public toString(): string {
    return this.value;
  }

  public equals(other: EmergencyLightingTestType): boolean {
    return this.equalsString(other.value);
  }

  public equalsString(other: string): boolean {
    return other !== null && other === this.value;
  }
}

type EmergencyLightingTestType_ = 'FUNCTION' | 'DURATION';
