import * as angular from 'angular';
import { NavigationService } from '@app/shared/services/navigation/navigation.service';
import { FloorService } from '@angularjs/or/services/FloorService';
import { IBuildingService } from '@angularjs/or/services/IBuildingService';
import { Building } from '@angularjs/or/api/building/Building';
import {
  Entity,
  UIRefreshService
} from '@angularjs/or/services/UIRefreshService';
import { UserService } from '@angularjs/or/services/UserService';
import { BuildingAuthorization } from '@app/shared/models/building-authorization.interface';
import { Floor } from '@angularjs/or/api/building/Floor';
import { ArrayUtils } from '@angularjs/or/util/ArrayUtils';
import { BuildingAuthorityType } from '@app/shared/models/building-authority-type';
import { Observable } from '@angularjs/or/util/Observable';
import { DropdownService } from '@angularjs/or/services/DropdownService';
import { IDropdown } from '@angularjs/or/services/IDropdown';
import { ObservableBool } from '@angularjs/or/util/ObservableBool';
import { User } from '@angularjs/or/api/auth/User';

export class OrFloorsMenuController implements IDropdown {
  private isActiveObservable: Observable<ObservableBool>;
  private buildingId: number;
  public floors: Floor[];
  public currentFloor: Floor;
  public canManageFloors: boolean;
  private user: User;

  constructor(
    private $scope: angular.IScope,
    private userService: UserService,
    private buildingService: IBuildingService,
    private floorService: FloorService,
    private uiRefreshService: UIRefreshService,
    private floorAdministrationUrlGenerator: (
      buildingId: number,
      floorId: number
    ) => string,
    private navigationService: NavigationService,
    private dropdownService: DropdownService
  ) {}

  public $onInit(): void {
    this.refresh();
    this.uiRefreshService.onChange(Entity.BUILDING, () => {
      this.refresh();
    });
    this.uiRefreshService.onChange(Entity.FLOOR, () => {
      this.refresh();
    });
    this.isActiveObservable = new Observable<ObservableBool>(
      new ObservableBool(false)
    );
    this.dropdownService.add(this);
  }

  refresh(): void {
    this.userService.getCurrentUser().then((user) => {
      this.user = user;
      this.buildingService.getCurrentBuilding().then((building) => {
        this.floorService.getCurrentFloor().then(
          (floor) => {
            this.init(building, floor, user.buildingAuthorizations);
          },
          (reason) => console.warn(reason)
        );
      });
    });
  }

  init(
    building: Building,
    floor: Floor,
    authorizations: BuildingAuthorization[]
  ): void {
    this.$scope.$apply(() => {
      this.buildingId = building.id;
      this.floors = building.floors;
      this.currentFloor = floor;
      this.isActiveObservable.change((val) => val.setBool(false));

      for (const authorization of authorizations) {
        if (authorization.building.id === this.buildingId) {
          for (const role of authorization.roles) {
            if (
              ArrayUtils.contains(
                role.authorityTypes,
                BuildingAuthorityType.MANAGE_FLOORS
              )
            ) {
              this.canManageFloors = true;
              return;
            }
          }
          return;
        }
      }
    });
  }

  public getLabelForFloor(floor: Floor): string {
    if (floor) {
      return floor.floorNumber + (floor.name ? ' - ' + floor.name : '');
    } else if (this.currentFloor) {
      return (
        'Floor: ' +
        this.currentFloor.floorNumber +
        (this.currentFloor.name ? ' - ' + this.currentFloor.name : '')
      );
    }
    return null;
  }

  navigateToFloor(floorId: number): void {
    if (!this.isFloorDisabled(floorId)) {
      this.navigationService.navigateToActiveSection(
        this.user,
        this.buildingId,
        floorId
      );
    }
  }

  isFloorDisabled(floorId: number): boolean {
    return false;
  }

  navigateToFloorAdministration(): void {
    this.navigationService.navigateToSection(
      this.navigationService.getSectionById('floors'),
      this.buildingId
    );
  }

  produceFloorAdministrationUrl(floor: Floor): string {
    return this.floorAdministrationUrlGenerator(this.buildingId, floor.id);
  }

  toggle(): void {
    this.isActiveObservable.change((observableBool: ObservableBool) =>
      observableBool.toggle()
    );
    if (this.isActive()) {
      this.dropdownService.notify(this.getDropdownName());
    }
  }

  isActive(): boolean {
    return this.isActiveObservable.value().getBool();
  }

  collapse(): void {
    this.isActiveObservable.change((val) => val.setBool(false));
  }

  collapseWithApply(): void {
    this.$scope.$apply(() => {
      this.collapse();
    });
  }

  getDropdownName(): string {
    return 'floors';
  }
}
