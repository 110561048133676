import { OrSimpleWeekdayListController } from './OrSimpleWeekdayListController';

export class OrSimpleWeekdayListDirective {
  constructor() {
    return {
      bindToController: true,
      controller: [OrSimpleWeekdayListController],
      controllerAs: 'weekdayList',
      replace: true,
      restrict: 'E',
      scope: {
        weekdays: '=',
        isEditable: '=',
        shortLabels: '='
      },
      template: require('raw-loader!./or-simple-weekday-list.html').default
    };
  }
}
