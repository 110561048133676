import { Inject, Injectable } from '@angular/core';
import { Resource } from '@app/shared/resources/resource';
import { BleKey } from '@app/shared/models/ble-key.interface';
import { HttpClient } from '@angular/common/http';
import { Environment, environmentToken } from '@environment';
import { Observable } from 'rxjs';
import { MobileToken } from '@app/shared/models/mobile-token.interface';

@Injectable({
  providedIn: 'root'
})
export class BleKeyResource extends Resource<BleKey, number> {
  constructor(protected http: HttpClient, @Inject(environmentToken) environment: Environment) {
    super(http, `${environment.apiUrl}${environment.apiPath}/ble-key`);
  }

  getAllKeysForBuilding(buildingId: number): Observable<BleKey[]> {
    return this.http.get<BleKey[]>(`${this.baseUrl}/${buildingId}`);
  }

  createKey(buildingId: number, key: BleKey): Observable<BleKey> {
    return this.http.post<BleKey>(`${this.baseUrl}/create/${buildingId}`, key.description);
  }

  updateKey(buildingId: number, key: BleKey): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/${buildingId}/${key.id}`, key.description);
  }

  sendKey(buildingId: number, key: BleKey): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/send/${buildingId}/${key.id}`, null);
  }
}
