import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ICalendarEvent } from '@app/shared/models/calendar-event.interface';

export type CalendarEventDialogData = {
    buildingId: number,
    mode: FORM_TYPE,
    calendarEvent?: ICalendarEvent,
};

export type DialogResult = {
    formValue: { buildingId: number, name: string, date: Date },
};

@Component({
    selector: 'app-calendar-event-dialog',
    templateUrl: './calendar-event-dialog.component.html',
    styleUrls: ['./calendar-event-dialog.component.scss']
})
export class CalendarEventDialogComponent implements OnInit {

    calendarEventForm: FormGroup;
    readonly mode: FORM_TYPE;
    private readonly buildingId: number;
    private readonly calendarEventName: string;


    constructor(private fb: FormBuilder,
                @Inject(MAT_DIALOG_DATA) public data: CalendarEventDialogData,
                private dialogRef: MatDialogRef<CalendarEventDialogComponent>) {

        this.buildingId = data.buildingId;
        this.mode = data.mode;
        this.calendarEventName = data.calendarEvent.name ? data.calendarEvent.name : '';

        this.calendarEventForm = this.fb.group({
            emailAddress: this.mode === FORM_TYPE.CREATE ?
              ['', [Validators.required]] :
              // in user edit form, email address will not be present, so "required" validation can be removed
              ['', [Validators.email]],
        });

    }

    get title(): string {
        return this.mode === FORM_TYPE.CREATE ? 'Create calendar event' : `Edit calendar event - ${this.calendarEventName}`;
    }

    ngOnInit(): void {
        // intentionally left blank
    }

    close(): void {
        this.dialogRef.close();
    }

    save(): void {
        if (this.calendarEventForm.invalid) {
            return;
        }
        this.dialogRef.close({ formValue: this.calendarEventForm.value });
    }

    selectDate($event: MatDatepickerInputEvent<unknown, unknown | null>) {

    }
}

export enum FORM_TYPE {
  EDIT,
  CREATE
}
