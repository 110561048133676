<div id='elmt-manual-tests'>
  <div class='flex flex-row h-full'>
    <div class="or-panel-floorplan or-panel or-solid">
      <app-panel-toggle
        label="Floor Plan"
        icon="plan"
        (togglePanel)="toggleFloorPanel($event)"
        [toggleState]="floorPanelToggleState">
      </app-panel-toggle>
      <div class="or-panel-content" [ngClass]="{ 'hide-panel': floorPanelToggleState === false }">
        <app-floorplan [buildingId]="buildingId" (onNodeClick)="handleNodeClick($event)"></app-floorplan>
        <app-failed-tests-counter [showCounter]="shouldShowCounter" [testsSummary]="latestEmResults$ | async"></app-failed-tests-counter>
      </div>
    </div>
    <div class="or-panel-tags">
      <app-panel-toggle
        (togglePanel)="toggleTagPanel($event)"
        [toggleState]="tagPanelToggleState"
        label="Tags"
        icon="tag">
      </app-panel-toggle>
      <div class="or-panel-content" [ngClass]="{ 'hide-panel': tagPanelToggleState === false }">
        <app-tags *ngIf='tags$' [buildingId]='buildingId' [tags$]='tags$' (tagSelectionChange)='toggleTag($event)'></app-tags>
      </div>
    </div>
  </div>
</div>
