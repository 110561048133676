import { VirtualNotification } from '../../api/building/VirtualNotification';
import { IMappingResource } from '../../api/IMappingResource';
import { Resource } from '../Resource';
import { SensorNodeIdsBatch } from '../../api/building/SensorNodeBatch';
import { PromiseRejectionHandler } from '../../promises/PromiseRejectionHandler';
import { PromiseUtils } from '../promises/PromiseUtils';

export class MappingResource
  extends Resource<VirtualNotification, number>
  implements IMappingResource
{
  constructor(
    $http: ng.IHttpService,
    baseUrl: string,
    rejectionHandler: PromiseRejectionHandler
  ) {
    super($http, baseUrl, rejectionHandler);
  }

  public map(notification: VirtualNotification): Promise<{}> {
    return PromiseUtils.wrapSingle(
      this.$http.post(this.baseUrl + '/virtual', notification),
      this.rejectionHandler
    );
  }

  public unmap(sensorNodeIdsBatch: SensorNodeIdsBatch): Promise<{}> {
    return PromiseUtils.wrapSingle(
      this.$http.delete(
        this.baseUrl,
        PromiseUtils.wrapData(sensorNodeIdsBatch)
      ),
      this.rejectionHandler
    );
  }
}
