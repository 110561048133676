import { DdlMode } from '../../api/building/Ddl';
import { SavedEntity } from '../../api/SavedEntity';
import { ISimpleService } from '../../api/ISimpleService';

export class DdlModeService {
  private endpoints = {
    building: '/api/v1/building',
    mode: '/api/v1/ddl-mode'
  };

  constructor(
    private simpleService: ISimpleService<DdlMode, DdlMode, DdlMode, void>,
    private generateApiUrl: (endpoint: string) => string
  ) {}

  public getDdlMode(buildingId: number): Promise<DdlMode> {
    return this.simpleService.get(
      this.generateApiUrl(`${this.endpoints.building}/${buildingId}/ddl-mode`)
    );
  }

  public createDdlMode(buildingId: number): Promise<DdlMode> {
    return this.simpleService.post(
      this.generateApiUrl(`${this.endpoints.mode}`),
      { enabled: false, buildingId }
    );
  }

  public updateDdlMode(
    mode: boolean,
    ddlModeId: number,
    buildingId: number
  ): Promise<DdlMode> {
    return this.simpleService.put(
      this.generateApiUrl(`${this.endpoints.mode}/${ddlModeId}`),
      { enabled: mode, buildingId }
    );
  }
}
