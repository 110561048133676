<div class='add-jobs-dialog'>

  <h2 mat-dialog-title>Create Job</h2>

  <mat-dialog-content>
    <form [formGroup]='addJobForm' autocomplete='off' data-cy='add-job-form'>
      <mat-form-field *ngIf='mode === 0'>
        <mat-label>Name</mat-label>
        <input matInput formControlName='name' maxlength='255'>
        <mat-error *ngIf="addJobForm.controls['name']?.errors?.required">
          Name is required
        </mat-error>
        <mat-error *ngIf="addJobForm.controls['name']?.errors?.minlength">
          Name must be at least 3 characters
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Job Type</mat-label>
        <mat-select formControlName='jobTaskTemplateName'>
          <mat-option *ngFor='let jobTaskTemplateName of jobTaskTemplateNameArray' [value]='jobTaskTemplateName'>
            {{ jobTaskTemplateName }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="addJobForm.controls['jobTaskTemplateName']?.errors?.required">
          Job type is required
        </mat-error>
      </mat-form-field>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-raised-button (click)='close()'>Close</button>
    <button mat-raised-button color='primary' (click)='save()'>Save</button>
  </mat-dialog-actions>

</div>
