<div class="edit-user-dialog">

  <h2 mat-dialog-title>#{{ user.id }} - {{ user.name }}</h2>

  <mat-dialog-content [formGroup]="form">
    <mat-form-field>
      <mat-label>Email Address</mat-label>
      <input matInput formControlName="emailAddress" value="{{user.emailAddress}}">
    </mat-form-field>
    <br/>
    <mat-form-field>
      <mat-label>Auth Method</mat-label>
      <input matInput formControlName="authMethod" value="{{user.authMethod}}">
    </mat-form-field>
    <br/>
    <mat-form-field>
      <mat-label class="or-label or-label-above">Managing Company</mat-label>
      <mat-select [id]='"managingCompany"' formControlName="managingCompany" data-test="form-building-details-managing-company">
        <mat-option disabled>--- Choose a managing company ---</mat-option>
        <mat-option *ngFor="let managingCompany of managingCompanies$ | async" [value]="managingCompany.id">
          {{ managingCompany.companyName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <br/>
    <mat-form-field>
      <mat-label>Global Authorities</mat-label>
      <mat-select [id]='"globalAuthorities"' formControlName="globalAuthorities" multiple>
        <mat-option disabled>--- Choose global authority/s ---</mat-option>
        <mat-option *ngFor="let globalAuthority of globalAuthorities" [value]="globalAuthority.value">
          {{ globalAuthority.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-raised-button (click)="close()">Close</button>
    <button mat-raised-button color="primary" (click)="save()">Save</button>
  </mat-dialog-actions>

</div>