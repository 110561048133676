import { SensorNodeAlert } from '@angularjs/angular/modules/layout/or-sensor-node/SensorNodeAlert';
import { StringUtils } from '@angularjs/or/util/StringUtils';
import { SimpleLampType } from '@angularjs/or/api/building/LampType';
import { Driver } from '@angularjs/or/api/building/Driver';

export class LuminaireDriver extends Driver {
  constructor(
    public id?: number,
    public x?: number,
    public y?: number,
    public address?: number,
    public nodeId?: number,
    public lampTypeId?: number,
    public burnInHours?: number,
    public energyConsumption?: number,
    public conflictingAddress?: boolean,
    public daliTypeId?: number,
    public daliType?: string,
    public updatedAt?: Date,
    public daliStatus?: number,
    public scene?: number,
    public daliStates?: string[],
    public connected?: boolean,
    public lampType?: SimpleLampType
  ) {
    super(address, connected);
  }

  public static from(other: LuminaireDriver): LuminaireDriver {
    return new LuminaireDriver(
      other.id,
      other.x,
      other.y,
      other.address,
      other.nodeId,
      other.lampTypeId,
      other.burnInHours,
      other.energyConsumption,
      other.conflictingAddress,
      other.daliTypeId,
      other.daliType,
      other.updatedAt,
      other.daliStatus,
      other.scene,
      other.daliStates,
      other.connected,
      other.lampType
    );
  }

  // for testing purpose
  public static createFromIdAndNodeId(
    id: number,
    nodeId: number
  ): LuminaireDriver {
    return this.from({
      id,
      nodeId,
      address: id,
      connected: true
    } as LuminaireDriver);
  }

  public get isDT8Driver(): boolean {
    return this.daliType === 'DT8';
  }

  public get isFaulty(): boolean {
    return this.daliStates != null && this.daliStates.length > 0;
  }

  public get address16(): string {
    return this.address != null
      ? this.address.toString(16).toUpperCase()
      : 'Unmapped';
  }

  public get alerts(): SensorNodeAlert[] {
    const alerts = [];

    if (!this.connected) {
      alerts.push(new SensorNodeAlert('Driver disconnected', null));
    }

    if (this.daliStates != null) {
      this.daliStates.forEach((state) =>
        alerts.push(
          new SensorNodeAlert(
            'Dali Driver address:' +
              this.address16 +
              ' - ' +
              StringUtils.humanizeConstant(state)
          )
        )
      );
    }

    return alerts;
  }
}
