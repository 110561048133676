import { OrPanelController } from './OrPanelController';

export class OrPanelDirective {
  constructor() {
    return {
      bindToController: true,
      controller: ['OrPanelService', OrPanelController],
      controllerAs: 'panel',
      replace: true,
      restrict: 'E',
      scope: {
        iconClass: '@',
        isActive: '=',
        isAlwaysActive: '=?',
        isSolid: '=',
        panelId: '@',
        panelName: '@',
        isMain: '=',
        isPrintable: '@',
        reportToCsv: '=',
        isExclusive: '=?',
        timezone: '=?'
      },
      template: require('raw-loader!./or-panel.html').default,
      transclude: true
    };
  }
}
