<div class='circadian-curve-tile' #circadianCharts>
  <ol class='or-inline-items'>
    <li>
      <div class='or-flex-container space-between'>
        <div class='circadian-color-chart'>
          <svg [attr.id]="'colour-temp-collapsed-' + curvesList.indexOf(curve)"></svg>
        </div>
        <div class='circadian-light-chart'>
          <svg [attr.id]="'light-level-collapsed-' + curvesList.indexOf(curve)"></svg>
        </div>
      </div>
    </li>
    <li class='or-inline-item'>
      <span class='circadian-curve-tile-setting'>
        <span class='circadian-curve-setting-tile-name'>Colour Temperature</span>
        <span *ngIf='currentColourTemp'> to </span>
        <span class='circadian-curve-setting-tile-value' [textContent]="currentColourTemp + ' K'">
        </span>
        <span *ngIf="currentLightLevel"> and </span>
        <span class='circadian-curve-setting-tile-name'>Light Level</span>
        <span *ngIf="currentLightLevel"> to </span>
        <span class='circadian-curve-setting-tile-value' [textContent]="currentLightLevel + '%'">
        </span>
      </span>
    </li>
    <li class='or-inline-item' *ngIf="curve.tags.length">
      <label class='or-label'>for:</label>
      <app-inline-tags [isEditable]="false" [displayTags]='curve.tags' [tags]="curve.tags" [selectedTagIds]="getTagIds()"></app-inline-tags>
    </li>
    <li class='or-inline-item' *ngIf="curve.floors.length">
      <label class='or-label'>on:</label>
      <app-inline-floors [isEditable]="false" [floors]="curve.floors" [selectedFloorIds]="getFloorIds()"></app-inline-floors>
    </li>
  </ol>
</div>

