import { Tag } from './tag.interface';
import { Tenant } from '@app/shared/models/tenant.interface';
import { DuplicateMapping, DuplicateType } from '@app/shared/models/duplicate-mapping';
import { LuminaireDriver, LuminaireDriverDTO, SelectableLuminaire } from '@app/shared/models/luminaire-driver';
import { EmDriver, EmDriverDTO, SelectableEmDriver } from '@app/shared/models/em-driver';
import { DISCRIMINATOR, Selectable } from '@app/shared/models/selectable.interface';
import { StringUtils } from '@angularjs/or/util/StringUtils';
import { SensorNodeStatus } from '@app/shared/models/sensor-node-status';

export interface SensorNodeDTO {
  id?: number;
  x: number;
  y: number;
  address: number;
  tags: Tag[];
  tenants: Tenant[];
  connected: boolean;
  timeSinceInstall: number;
  duplicateAddressMappings: DuplicateMapping[];
  duplicateGroupMappings: DuplicateMapping[];
  isChanged: boolean;
  isNotifying: boolean;
  floorId: number;
  bleKey: number;
  installedAt: Date;
  nodeType: string;
  properlyMapped: boolean;
  updatedAt: Date;
  scene: number;
  emDrivers: EmDriverDTO[] | SelectableEmDriver[];
  luminaireDrivers: LuminaireDriverDTO[] | SelectableLuminaire[];
  isDriverEmergencyLightingTestStarted: boolean;
  isTooManyDriverEmergencyLightingTest: boolean;
  groupId: number;
  subscriber: boolean;
  hasMappingBeenAttempted: boolean;
  bleScanning: string;
  valueSuffix?: string;
  isFaulty: boolean;
}

export interface SelectableNode extends SensorNodeDTO, Selectable, h337.HeatmapDataPoint {
  isSN3: boolean;
  isPassiveNode: boolean;
  isHIM84: boolean;
}

export interface EnhancedSelectable extends Selectable {
  groupId?: number;
  address?: number;
  properlyMapped?: boolean;
  hasMappingBeenAttempted?: boolean;
}

export class SensorNodeAlert {
  constructor(public value: string, public link?: string) {}
}

export class SensorNode implements SelectableNode {
  id?: number;
  address: number;
  bleKey: number;
  connected: boolean;
  duplicateAddressMappings: DuplicateMapping[];
  duplicateGroupMappings: DuplicateMapping[];
  emDrivers: EmDriver[];
  floorId: number;
  groupId: number;
  installedAt: Date;
  isChanged: boolean;
  isNotifying: boolean;
  isDriverEmergencyLightingTestStarted: boolean;
  isTooManyDriverEmergencyLightingTest: boolean;
  luminaireDrivers: LuminaireDriver[];
  nodeType: string;
  properlyMapped: boolean;
  scene: number;
  subscriber: boolean;
  tags: Tag[];
  tenants: Tenant[];
  timeSinceInstall: number;
  updatedAt: Date;
  x: number;
  y: number;
  presence: number;
  lightLevel: number;
  value = 0;
  radius = 0;
  hasMappingBeenAttempted = false;
  bleScanning = null;
  valueSuffix?: string;
  max = 100;
  constructor(dto: SensorNodeDTO) {
    this.id = dto.id;
    this.address = dto.address;
    this.bleKey = dto.bleKey;
    this.connected = dto.connected;
    this.duplicateAddressMappings = dto.duplicateAddressMappings || [];
    this.duplicateGroupMappings = dto.duplicateGroupMappings || [];
    this.emDrivers = EmDriver.ofMultiple(dto.emDrivers);
    this.floorId = dto.floorId;
    this.groupId = dto.groupId;
    this.installedAt = dto.installedAt;
    this.isChanged = dto.isChanged;
    this.isDriverEmergencyLightingTestStarted = dto.isDriverEmergencyLightingTestStarted;
    this.isTooManyDriverEmergencyLightingTest = dto.isTooManyDriverEmergencyLightingTest;
    this.luminaireDrivers = LuminaireDriver.ofMultiple(dto.luminaireDrivers);
    this.nodeType = dto.nodeType;
    this.properlyMapped = dto.properlyMapped;
    this.scene = dto.scene;
    this.subscriber = dto.subscriber;
    this.tags = dto.tags || [];
    this.tenants = dto.tenants;
    this.timeSinceInstall = dto.timeSinceInstall;
    this.updatedAt = dto.updatedAt;
    this.x = dto.x;
    this.y = dto.y;
    this.bleScanning = dto.bleScanning;
    this.valueSuffix = dto.valueSuffix;
    this.isNotifying = dto.isNotifying || false;
  }

  get discriminator(): DISCRIMINATOR {
    return this.nodeType === 'PN' ? DISCRIMINATOR.PN : DISCRIMINATOR.SN3;
  }

  get isPassiveNode(): boolean {
    return this.nodeType === 'PN';
  }

  get isHIM84(): boolean {
    return this.nodeType === 'HIM84';
  }

  get isSN3(): boolean {
    return this.nodeType === 'SN3';
  }

  get bleScanningState(): string {
    if (this.isPassiveNode) {
      return 'ON';
    } else {
      return this.bleScanning ?? 'Unknown, please query';
    }
  }

  get alerts(): SensorNodeAlert[] {
    const alerts: SensorNodeAlert[] = [];

    if (!this.connected && this.properlyMapped) {
      alerts.push(new SensorNodeAlert('Sensor node disconnected'));
    }

    this.luminaireDrivers?.forEach((driver) => {
      driver.daliStates?.forEach((state) =>
        alerts.push(
          new SensorNodeAlert(`Dali Driver address: ${driver.address16} - ${StringUtils.humanizeConstant(state)}`)
        )
      );
    });

    this.emDrivers?.forEach((driver) => {
      driver.emergencyLightingFailureStates?.forEach((state) =>
        alerts.push(
          new SensorNodeAlert(`EM Driver address: ${driver.address16} - ${StringUtils.humanizeConstant(state)}`)
        )
      );
    });

    this.duplicateAddressMappings?.forEach((dam) => {
      const duplicateAddressMapping = new DuplicateMapping(
        dam.nodeId,
        dam.floorId,
        dam.buildingId,
        dam.value,
        DuplicateType.ADDRESS
      );
      alerts.push(
        new SensorNodeAlert(
          `Duplicate Mapping Node Id ${duplicateAddressMapping.nodeId}`,
          duplicateAddressMapping.getLink()
        )
      );
    });

    this.duplicateGroupMappings?.forEach((dgm) => {
      const duplicateGroupMapping = new DuplicateMapping(
        dgm.nodeId,
        dgm.floorId,
        dgm.buildingId,
        dgm.value,
        DuplicateType.GROUP
      );
      alerts.push(
        new SensorNodeAlert(
          `Duplicate Grouping (${duplicateGroupMapping.value}) Node Id ${duplicateGroupMapping.nodeId}`,
          duplicateGroupMapping.getLink()
        )
      );
    });

    return alerts;
  }

  public get status(): SensorNodeStatus {
    if (!this.properlyMapped) {
      return SensorNodeStatus.UNKNOWN;
    }

    if (!this.connected) {
      return SensorNodeStatus.DISCONNECTED;
    }

    if (this.isFaulty) {
      return SensorNodeStatus.FAULTY;
    }

    return SensorNodeStatus.OK;
  }

  public get isFaulty(): boolean {
    return (
      this.luminaireDrivers?.some((driver) => driver.daliStates != null && driver.daliStates.length > 0) ||
      this.emDrivers?.some(
        (driver) => driver.emergencyLightingFailureStates != null && driver.emergencyLightingFailureStates.length > 0
      )
    );
  }

  static from(dto: SensorNodeDTO): SensorNode {
    return new SensorNode({ ...dto });
  }

  static fromPosition(position: { x: number; y: number }): SensorNode {
    return new SensorNode({
      address: null,
      bleKey: null,
      connected: false,
      duplicateAddressMappings: [],
      duplicateGroupMappings: [],
      emDrivers: undefined,
      floorId: null,
      groupId: null,
      installedAt: undefined,
      isChanged: false,
      isDriverEmergencyLightingTestStarted: false,
      isTooManyDriverEmergencyLightingTest: false,
      luminaireDrivers: undefined,
      nodeType: 'SN3',
      properlyMapped: false,
      scene: null,
      subscriber: false,
      tags: [],
      tenants: [],
      timeSinceInstall: null,
      updatedAt: undefined,
      x: position.x,
      y: position.y,
      hasMappingBeenAttempted: false,
      bleScanning: null,
      isNotifying: false,
      isFaulty: false
    });
  }

  update(newX: number, newY: number): void {
    this.x = newX;
    this.y = newY;
    this.isChanged = true;
  }

  get address16(): string {
    return this.properlyMapped ? this.address.toString(16).toUpperCase() : 'Unmapped';
  }
}
