<div class="p-2 h-full" #chartContainer>
  @if (isLoading$ | async) {
    <div class="h-full flex justify-center items-center">
      <mat-spinner color="primary" />
    </div>
  } @else {
    <div class="header">
      <div class="title">
        <span class="text-base sm:text-xl md:text-xl lg:text-xl xl:text-2xl 2xl:text-3xl font-semibold" [attr.data-cy]="item.dataCy + '-title'" >{{item.title}}</span>
        <div class="sub-title">
          @if (item.subTitle) {
            <span [attr.data-cy]="item.dataCy + '-subtitle'" class="text-sm sm:text-xl">{{item.subTitle}}</span>
          }
        </div>
      </div>
    </div>
    <div class="body">
      @switch (chartType) {
        @case ('bar') {
          <div class="overflow-y-auto overflow-x-hidden h-[95%] w-full">
            <ngx-charts-bar-horizontal [view]="view" [results]="chartData" [xAxis]="true" [yAxis]="true" [scheme]="horizontalBarScheme" [gradient]="true" [attr.data-cy]="item.dataCy + '-chart'">
              <ng-template #tooltipTemplate let-tag="model">
                <div class="p-2">
                  @if(!tag.extra?.hideName) {
                    <p>{{tag.name}}</p>
                  }
                  <p class="text-lg">{{formatValue(tag.value, tag.extra)}}</p>
                </div>
              </ng-template>
            </ngx-charts-bar-horizontal>
          </div>
        }
        @case ('bar-vertical-grouped') {
            <ngx-charts-bar-vertical-2d [results]="chartData" [xAxis]="true" [yAxis]="true" [groupPadding]='8' [barPadding]='4'
                                        [scheme]="verticalGroupedBarScheme" [gradient]="true" [attr.data-cy]="item.dataCy + '-chart'"
                                        [showXAxisLabel]='true' [showYAxisLabel]='true'
                                        [xAxisLabel]='item.xAxisLabel' [yAxisLabel]='item.yAxisLabel'>
              <ng-template #tooltipTemplate let-data="model">
                <div class="p-2">
                  <p>{{data.series + ' ('+ comparisonYearLabel(data) + ')'}}</p>
                  <p class="text-lg">{{formatValue(data.value, {unit: 'kWh'})}}</p>
                </div>
              </ng-template>
            </ngx-charts-bar-vertical-2d>
        }
        @case ('pie') {
          <ngx-charts-pie-chart [results]="chartData" [scheme]="colorScheme" [legend]="true" [attr.data-cy]="item.dataCy + '-chart'">
            <ng-template #tooltipTemplate let-tag="model">
              <div class="p-2">
                <p>{{tag.name}}</p>
                <p class="text-lg">{{formatValue(tag.value, tag.extra)}}</p>
              </div>
            </ng-template>
          </ngx-charts-pie-chart>
        }
        @case ('stacked') {
          <ngx-charts-bar-vertical-stacked [results]="chartData" [xAxis]="true" [legend]="true" [scheme]="colorScheme" [attr.data-cy]="item.dataCy + '-chart'"/>
        }
        @case ('gauge') {
          <ngx-charts-gauge [results]="chartData" [scheme]="gaugeScheme" [attr.data-cy]="item.dataCy + '-chart'"/>
        }
        @case ('line') {
          <ngx-charts-line-chart [customColors]="tagColors"
                                 [legend]="true"
                                 [showXAxisLabel]="true"
                                 [showYAxisLabel]="true"
                                 [xAxis]="true"
                                 [yAxis]="true"
                                 [xAxisLabel]="item.xAxisLabel"
                                 [yAxisLabel]="item.yAxisLabel"
                                 [results]="chartData"[attr.data-cy]="item.dataCy + '-chart'">

            <ng-template #tooltipTemplate let-tag="model">
              <div class="p-2">
                <p>{{tag.name}}</p>
                <p class="text-lg">{{tag.value}}</p>
              </div>
            </ng-template>
          </ngx-charts-line-chart>
        }
        @default {}
      }
    </div>
  }
</div>
