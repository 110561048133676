import { BehaviorSubject, Observable } from 'rxjs';
import { EventEmitter, Inject, Injectable, InjectionToken } from '@angular/core';
import { NumericUtils } from '@app/shared/utils/numeric';
import { map } from 'rxjs/operators';

export enum ZoomOperation {
  ZOOM_IN,
  ZOOM_OUT
}

export const INITIAL_SCALE = new InjectionToken<number>('initial_scale');
export const MINIMUM_SCALE = new InjectionToken<number>('minimum_scale');
export const MAXIMUM_SCALE = new InjectionToken<number>('maximum_scale');
export const SCALE_FACTOR = new InjectionToken<number>('scale_factor');

@Injectable({
  providedIn: 'root'
})
export class ImageZoomService {
  private currentScale: number;
  private scale$: BehaviorSubject<number>;
  private attemptToRecenter: boolean;
  private centered$: BehaviorSubject<boolean>;
  recenterEvent = new EventEmitter<void>();

  constructor(
    @Inject(INITIAL_SCALE) private initialScale: number,
    @Inject(MINIMUM_SCALE) private minimumScale: number,
    @Inject(MAXIMUM_SCALE) private maximumScale: number,
    @Inject(SCALE_FACTOR) private scaleFactor: number
  ) {
    this.scale$ = new BehaviorSubject<number>(initialScale);
    this.centered$ = new BehaviorSubject<boolean>(true);
    this.scale$.subscribe((latestVal) => (this.currentScale = latestVal));
  }

  public reset(): void {
    this.scale$.next(this.initialScale);
  }

  public resetAndRecenter(): void {
    this.reset();
    this.recenterEvent.emit();
    this.attemptToRecenter = true;
  }

  public setCentered(): void {
    this.attemptToRecenter = false;
    this.centered$.next(true);
  }

  public setUnCentered(): void {
    this.centered$.next(false);
  }

  public shouldAttemptToRecenter(): boolean {
    return this.attemptToRecenter;
  }

  public isCentered(): Observable<boolean> {
    return this.centered$.asObservable();
  }

  public zoom(operation: ZoomOperation): void {
    if (this.canZoom(this.currentScale, operation)) {
      this.scale$.next(this.calculateNewScale(this.currentScale, operation));
    }
  }

  public isScaled(): Observable<boolean> {
    return this.scale$.pipe(map((currentScale: number) => currentScale !== this.initialScale));
  }

  public canZoom(currentScale: number, operation: ZoomOperation): boolean {
    const newScale = this.calculateNewScale(currentScale, operation);
    return (
      newScale !== currentScale &&
      (newScale >= this.minimumScale || operation === ZoomOperation.ZOOM_IN) &&
      (newScale <= this.maximumScale || operation === ZoomOperation.ZOOM_OUT)
    );
  }

  public canZoomIn(): Observable<boolean> {
    return this.scale$.pipe(
      map((currentScale: number) => {
        return this.canZoom(currentScale, ZoomOperation.ZOOM_IN);
      })
    );
  }

  public canZoomOut(): Observable<boolean> {
    return this.scale$.pipe(
      map((currentScale: number) => {
        return this.canZoom(currentScale, ZoomOperation.ZOOM_OUT);
      })
    );
  }

  private calculateNewScale(currentScale: number, operation: ZoomOperation): number {
    let val;
    if (operation === ZoomOperation.ZOOM_IN) {
      val = NumericUtils.roundFloatingPoint(currentScale * this.scaleFactor, 2);
      return val > this.maximumScale ? this.maximumScale : val;
    }
    if (operation === ZoomOperation.ZOOM_OUT) {
      val = NumericUtils.roundFloatingPoint(currentScale / this.scaleFactor, 2);
      return val < this.minimumScale ? this.minimumScale : val;
    }
  }

  public getScale(): Observable<number> {
    return this.scale$.asObservable();
  }

  public setScale(scale: number): void {
    this.scale$.next(scale);
  }

  public setDefaultScale(scale: number): void {
    this.initialScale = scale;
  }
}
