import { AuthMethod } from '@app/shared/models/auth-method';
import { BuildingAuthorization } from '@app/shared/models/building-authorization.interface';
import { GlobalAuthority } from '@app/shared/models/global-authority';
import { User as PlusUser } from '@app/shared/models/user.interface';

export class User implements PlusUser {
  constructor(
    public id: number,
    public name: string,
    public pictureUrl: string,
    public emailAddress: string,
    public authKey: string,
    public authMethod: AuthMethod,
    public managingCompanyId: number,
    public globalAuthorities: GlobalAuthority[],
    public buildingAuthorizations: BuildingAuthorization[],
    public headline: string,
    public joinedAt: string,
    public tenantIds: number[]
  ) {}

}
