import { EmergencyLightingTestType } from '../../data/EmergencyLightingTestType';
import { EmergencyLightingTest } from './EmergencyLightingTest';
import { EmergencyLightingTestState } from '../../data/EmergencyLightingTestState';

export class EmergencyLightingTestResult {
  constructor(
    public groupName: string,
    public timeStarted: number,
    public testsType: EmergencyLightingTestType,
    public tests: EmergencyLightingTest[],
    public status: EmergencyLightingTestState
  ) {}

  public static clone(
    other: EmergencyLightingTestResult
  ): EmergencyLightingTestResult {
    return new EmergencyLightingTestResult(
      other.groupName,
      this.humanizeTimestamp(other.timeStarted),
      EmergencyLightingTestType.fromValue(other.testsType.toString()),
      EmergencyLightingTest.cloneArray(other.tests),
      EmergencyLightingTestState.fromValue(other.status.toString())
    );
  }

  public static cloneArray(
    other: EmergencyLightingTestResult[]
  ): EmergencyLightingTestResult[] {
    return other.map((item) => EmergencyLightingTestResult.clone(item));
  }

  private static dateFromSecondsFromEpoch(seconds) {
    const dateTime = new Date(Date.UTC(1970, 0, 1));
    dateTime.setUTCSeconds(seconds);
    return dateTime;
  }

  public static humanizeTimestamp(unixtime): any {
    if (!unixtime) {
      return 'N/A';
    }
    return this.dateFromSecondsFromEpoch(unixtime);
  }
}
