<div
  class='circadian-curve-summary'
  [ngClass]="{
  'circadian-curve-summary-collapsed': details.isCollapsed,
  'circadian-curve-summary-paused': !details.enabled,
  'circadian-curve-summary-always-active': details.alwaysActive,
  'or-disabled': !details.editable
  }">
  <div class='circadian-curve-summary-header' [ngClass]="getSummaryStateClass()">
    <ul class='or-inline-items circadian-curve-summary-actions'>
      <li
        class='or-inline-item circadian-curve-summary-action'
        *ngIf="!isNew && details.editable"
        (mouseenter)="toggleControlActionsMenu(true)"
        (mouseleave)="toggleControlActionsMenu(false)">
        <ul class='or-stacked-items circadian-curve-summary-control-actions' [ngClass]="getControlActionsClass()">
          <li class='or-stacked-item circadian-curve-summary-control-action' [ngClass]="getControlActionClass(0)"

          >
            <span class='circadian-curve-summary-control-action-hint' [hidden]="getActiveActionIndex() == 0">
              Resume circadian-curve
            </span>
            <button [disabled]="getEditingStatus() || isNew"
            class='or-button or-iconic or-small'
            (click)="toggleActiveState(true)"
            type='button'
            [attr.data-cy]="'PLAY-circadian-curve-' + curveId">
            <span class='or-icon or-tiny or-icon-play-alternate'></span>
          </button>
          </li>
          <li class='or-stacked-item circadian-curve-summary-control-action' [ngClass]="getControlActionClass(1)">
            <span class='circadian-curve-summary-control-action-hint' [hidden]="getActiveActionIndex() == 1">
              Pause circadian-curve
            </span>
            <button [disabled]="getEditingStatus() || isNew"
            class='or-button or-iconic or-small'
            (click)="toggleActiveState(false)"
            type='button'
            [attr.data-cy]="'PAUSE-circadian-curve-' + curveId">
            <span class='or-icon or-tiny or-icon-pause-alternate'></span>
          </button>
          </li>
          <li class='or-stacked-item  circadian-curve-summary-control-action' [ngClass]="getControlActionClass(1)">
            <span class='circadian-curve-summary-control-action-hint' [hidden]="getActiveActionIndex() == 1">
              Move up
            </span>
            <button
                    class='or-button or-iconic or-small or-inline-item'
                    (click)="moveUp()"
                    type='button'
                    [attr.data-cy]="'MOVEUP-circadian-curve-' + curveId"
                    [disabled]="isNew || isFirst()">
              <span class='or-icon or-tiny or-icon-arrow-up'></span>
            </button>
          </li>
          <li class='or-stacked-item  circadian-curve-summary-control-action' [ngClass]="getControlActionClass(1)">
            <span class='circadian-curve-summary-control-action-hint' [hidden]="getActiveActionIndex() == 1">
              Move down
            </span>
            <button
                    class='or-button or-iconic or-small or-inline-item'
                    (click)="moveDown()"
                    type='button'
                    [attr.data-cy]="'MOVEDOWN-circadian-curve-' + curveId"
                    [disabled]="isNew || isLast()">
              <span class='or-icon or-tiny or-icon-arrow-down'></span>
            </button>
          </li>
        </ul>
      </li>
    </ul>
    <div class='circadian-curve-summary-header-description' (click)="toggleCollapse()" [attr.data-cy]="'circadian-curve-summary-header-' + curveId">
      <ul class='or-inline-items or-pull-left circadian-curve-summary-title'>
        <li class='or-inline-item'>
          <span class='circadian-curve-summary-name' [textContent]="details.name || 'Unnamed curve'"></span>
        </li>
      </ul>
    </div>
  </div>
  <div
    class='or-schedule-summary-content or-schedule-summary-content-overview'
    *ngIf="(!isNew && details.isCollapsed)"
    (click)="toggleCollapse()">
    <app-circadian-curve-tile
      [curve]="details"
      [curvesList]="curvesList">
    </app-circadian-curve-tile>
  </div>
  <div class='circadian-curve-summary-content circadian-curve-summary-content-form' *ngIf="(isNew || !details.isCollapsed)">
    <app-form-circadian-curve
      [details]="details"
      [curvesList]="curvesList"
      [isNew]="isNew"
      (saveNewCurve)="saveCurve()"
      (closeNewCurve)="closeNewForm()"
      (resetAndCloseCurve)="toggleCollapse()"
      (deleteCurve)="deleteCurve()"
    ></app-form-circadian-curve>
  </div>
</div>
