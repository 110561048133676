import { OrFormFloorDetailsController } from './OrFormFloorDetailsController';

export class OrFormFloorDetailsDirective {
  constructor() {
    return {
      bindToController: true,
      controller: ['FloorplanUrlGenerator', '$scope', 'SensorNodeService', OrFormFloorDetailsController],
      controllerAs: 'floorDetails',
      replace: true,
      restrict: 'E',
      scope: {
        isNew: '=',
        isBusy: '=',
        details: '=?',
        onAddFloor: '&',
        onUpdateFloor: '&',
        onDeleteFloor: '&',
        onResetFloor: '&'
      },
      template: require('raw-loader!./or-form-floor-details.html').default
    };
  }
}
