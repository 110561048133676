import { Rule } from '@angularjs/or/api/building/Rule';
import { MultiselectOption } from '../or-multiselect/MultiselectOption';
import { Tag } from '@angularjs/or/api/building/Tag';
import { Floor } from '@angularjs/or/api/building/Floor';
import { RuleType } from '@angularjs/or/data/RuleType';
import { DimLevel } from '@angularjs/or/data/DimLevel';
import { DwellTime } from '@angularjs/or/data/DwellTime';
import { Personality } from '@angularjs/or/data/Personality';
import { Scene } from '@angularjs/or/data/Scene';

export class OrScheduleRuleTileController {
  public rule: Rule;
  public tagOptions: MultiselectOption<Tag>[];
  public floorOptions: MultiselectOption<Floor>[];
  public hasValue = true;

  constructor() {}

  public $onInit() {
    this.updateTagOptions();
    this.updateFloorOptions();
  }

  private updateTagOptions() {
    this.tagOptions = this.rule.tags.map(
      (tag) => new MultiselectOption(tag.name, tag, true)
    );
  }

  private updateFloorOptions() {
    this.floorOptions = this.rule.floors.map(
      (floor) =>
        new MultiselectOption(floor.floorNumber.toString(), floor, true)
    );
  }

  public getRuleName(): string {
    return RuleType.fromValue(this.rule.command).name;
  }

  public getValue(): string {
    if (
      this.rule.command === RuleType.LOWLIGHT.toString() ||
      this.rule.command === RuleType.MAXLIGHT.toString() ||
      this.rule.command === RuleType.MINLIGHT.toString()
    ) {
      return DimLevel.fromValue(this.rule.value).label;
    } else if (
      this.rule.command === RuleType.DWELL.toString() ||
      this.rule.command === RuleType.LOWLIGHT_TIME.toString()
    ) {
      return DwellTime.fromValue(this.rule.value).label;
    } else if (this.rule.command === RuleType.PERSONALITY.toString()) {
      return Personality.fromValue(this.rule.value).label;
    } else if (this.rule.command === RuleType.SCENE.toString()) {
      return Scene.fromValue(this.rule.value).label;
    } else {
      return this.rule.value.toString();
    }
  }
}
