<div id='job-tasks-summary'>
  <mat-card class='tellus-mat-card'>
    <mat-card-header class='tellus-mat-card-header tasks-mat-card-header' [id]="'task-' + task.id">
      <div class='job-title-group'>
        <mat-card-title class='tasks-mat-card-title'>
         #{{index +1}} {{task.name}}
        </mat-card-title>
        <mat-card-title>
          <button
            [disabled]="!jobStartEnabled"
            class='tasks-start-button'
            mat-raised-button
            (click)='onSend(task)'
            color='accent'
            type='button'
            [attr.data-cy]="'start-job-task-' + task.id">
            <span class='or-button-label'>Start</span>
          </button>
        </mat-card-title>
      </div>
    </mat-card-header>
    <mat-card-content>
      <div>
        <app-job-tasks-tile
          [task]='task'>
        </app-job-tasks-tile>
        <div class='or-job-tasks-tile-report'></div>
        <app-form-job-task
          [task]='task'
          (taskDataChanged)='onTaskDataChanged($event)'>
        </app-form-job-task>
      </div>
    </mat-card-content>
  </mat-card>
</div>
