import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { RefreshPageService } from '@app/shared/services/refresh-page/refresh-page.service';
import { intervalToDuration } from 'date-fns';
import { Observable, Subject, map, of, takeUntil, timer } from 'rxjs';

@Component({
  selector: 'app-refresh-timer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './refresh-timer.component.html',
  styleUrl: './refresh-timer.component.scss'
})
export class RefreshTimerComponent implements OnInit, OnDestroy {
  @Input({ required: true }) private elapsedTimeInSeconds;
  @Output() timerExpired: EventEmitter<void> = new EventEmitter<void>();

  timeLeft$: Observable<Duration>;
  private endTime = new Date();
  private destroy$ = new Subject<void>();

  constructor(private refreshPageService: RefreshPageService) {}

  ngOnDestroy(): void {
    this.clearTimer();
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    let timeEnd = this.elapsedTimeInSeconds * 1000;
    this.timeLeft$ = timer(0, 1000).pipe(
      map((x) => {
        const timer = intervalToDuration({ start: x * 1000, end: timeEnd });
        if (!timer.minutes && !timer.seconds) {
          timeEnd += this.elapsedTimeInSeconds * 1000 + 1000;
          this.timerExpired.emit();
          return null;
        }
        return timer;
      })
    );

    this.refreshPageService.autoRefreshDone$.pipe(takeUntil(this.destroy$)).subscribe((refreshDone) => {
      if (refreshDone) {
        this.clearTimer();
      }
    });
  }

  clearTimer(): void {
    this.timeLeft$ = of(null);
  }
}
