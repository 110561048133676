import { Component, OnInit } from '@angular/core';
import { Building } from '@app/shared/models/building.interface';
import { UserService } from '@app/shared/services/user/user.service';
import { concatMap, Observable, Subject } from 'rxjs';
import { NavigationService } from '@app/shared/services/navigation/navigation.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-administration-building',
  templateUrl: './building.component.html'
})
export class AdministrationBuildingComponent implements OnInit {
  public buildingDetails$: Observable<Building>;
  public success$: Subject<void> = new Subject();

  constructor(
    private readonly userService: UserService,
    private navigationService: NavigationService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.buildingDetails$ = this.route.params.pipe(
      concatMap((params) => {
        const { buildingId } = params;
        return this.userService.getBuilding(buildingId);
      })
    );

    this.buildingDetails$.subscribe((building) => {
      this.navigationService.initNavigation(window.location.href, building);
    });
  }
}
