import { Tab } from '@app/shared/components/tabs/tab.interface';
import { GlobalAuthority } from '@app/shared/models/global-authority';
import { User } from '@app/shared/models/user.interface';

export const globalAdministrationTabs: Tab[] = [
  {
    name: 'Users',
    path: '../global-administration/user-information',
    dataCy: 'global-administration-users-tab',
    isAuthorized: hasAuthority(GlobalAuthority.MANAGE_USERS)
  },
  {
    name: 'Managing Companies',
    path: '../global-administration/managing-company',
    dataCy: 'global-administration-managing-company-tab',
    isAuthorized: hasAuthority(GlobalAuthority.MANAGE_USERS)
  },
  {
    name: 'Emergency Lighting Calendar',
    path: '../global-administration/emergency-lighting-calendar',
    dataCy: 'global-administration-emergency-lighting-calendar-tab',
    isAuthorized: () => true
  },
  {
    name: 'Observe Packet Stream',
    path: '../global-administration/packet-stream',
    dataCy: 'global-administration-packet-stream-tab',
    isAuthorized: () => true
  },
  {
    name: 'OTAP Upgrades',
    path: '../global-administration/otap-upgrades',
    dataCy: 'global-administration-otap-upgrades-tab',
    isAuthorized: () => true
  }
];

function hasAuthority(authority: GlobalAuthority): (User) => boolean {
  return (user: User) => {
    return user.globalAuthorities.some((ga) => {
      return ga.toString() === authority.toString();
    });
  };
}
