import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ManagingCompany } from '@app/shared/models/building.interface';

@Component({
    selector: 'app-managing-company-dialog',
    templateUrl: './managing-company-dialog.component.html',
    styleUrls: ['./managing-company-dialog.component.scss']
})
export class ManagingCompanyDialogComponent implements OnInit {

    form: FormGroup;
    managingCompany: ManagingCompany;

    constructor(private fb: FormBuilder,
                @Inject(MAT_DIALOG_DATA) managingCompany: ManagingCompany,
                private dialogRef: MatDialogRef<ManagingCompanyDialogComponent>) {

        this.managingCompany = managingCompany;

        this.form = this.fb.group({
            companyName: [managingCompany.companyName],
        });
    }

    ngOnInit(): void {

    }

    close(): void {
        this.dialogRef.close();
    }

    save(): void {
        this.dialogRef.close(this.form.value);
    }
}
