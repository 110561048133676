import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { ITenant } from '@app/shared/models/tenant.interface';
import { BehaviorSubject, delay, Observable, of } from 'rxjs';
import { TenantResource } from '@app/shared/resources/tenant.resource';
import { catchError } from 'rxjs/operators';
import { EmptyPage } from '@app/shared/models/Pageable.interface';

export class TenantsDatasource implements DataSource<ITenant> {
  private tenantsSubject = new BehaviorSubject<ITenant[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private countSubject = new BehaviorSubject<number>(0);
  public counter$ = this.countSubject.asObservable();
  public loading$ = this.loadingSubject.asObservable();
  constructor(private tenantResource: TenantResource, private buildingId: number) {}

  loadTenants(name: string | null = null, pageIndex = 0, pageSize = 10): void {
    this.loadingSubject.next(true);
    this.tenantsSubject.next([]);
    this.tenantResource
      .getTenants(this.buildingId, name, pageIndex, pageSize)
      .pipe(
        catchError((err) => {
          console.error(err);
          this.loadingSubject.next(false);
          return of(new EmptyPage<ITenant>());
        }),
        delay(500)
      )
      .subscribe((tenants) => {
        this.tenantsSubject.next(tenants.content);
        this.countSubject.next(tenants.totalElements);
        this.loadingSubject.next(false);
      });
  }

  connect(_collectionViewer: CollectionViewer): Observable<ITenant[]> {
    return this.tenantsSubject.asObservable();
  }

  disconnect(_collectionViewer: CollectionViewer): void {
    return this.tenantsSubject.complete();
  }
}
