export class ConvertToNumberDirective {
  constructor() {
    return {
      bindToController: true,
      require: 'ngModel',
      restrict: 'A',
      controllerAs: 'converter',
      controller: ['$element', ConvertToNumberController],
      scope: {
        ngModel: '=',
        formatter: '='
      }
    };
  }
}
export class ConvertToNumberController {
  public formatter;

  constructor(element) {
    const ngModel: ng.INgModelController = element.controller('ngModel');
    this.formatter = element.scope().ruleDetails.formatter;
    if (this.formatter != null) {
      ngModel.$parsers.push((val) => parseInt(val, 10));
      ngModel.$formatters.push(this.formatter);
    }
  }
}
