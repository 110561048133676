import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Floor } from '@app/shared/models/floor.interface';
import { BehaviorSubject, Subject } from 'rxjs';
import { FloorService } from '@app/shared/services/floor.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Building } from '@app/shared/models/building.interface';
import { ConfirmDialogData } from '@app/shared/components/dialogs/confirm/confirm.component';
import { SensorNodeService } from '@services/sensor-node.service';
import { ImageService } from '@services/image.service';
import { ConfirmationDialogService } from '@services/confirmation-dialog/confirmation-dialog.service';
import { ToastService } from '@services/toast/toast.service';
import { UserService } from '@services/user/user.service';

@Component({
  selector: 'app-form-floor-details',
  templateUrl: './form-floor-details.component.html',
  styleUrls: ['./form-floor-details.component.scss']
})
export class FormFloorDetailsComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() floor: Floor;
  @Input() isNew = false;
  @Input({ required: true }) building: Building;
  @Input() isBusy = true;
  @Input() newFloorNum;

  @Output() valueChanged = new EventEmitter<void>();

  isRemovable = false;
  floorForm: FormGroup;
  public readonly error$ = new Subject<void>();
  public readonly imageUrl$ = new BehaviorSubject<string>(null);

  constructor(
    private floorService: FloorService,
    private nodeService: SensorNodeService,
    private imageService: ImageService,
    private confirmationDialogService: ConfirmationDialogService,
    private fb: FormBuilder,
    private toast: ToastService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.initForm(this.floor);
    this.initImage(this.floor);
  }

  ngAfterViewInit(): void {
    this.checkNodeAvailability();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isNew && changes) {
      this.initForm(this.floor);
      this.initImage(this.floor);
    }
  }

  initForm(incomingFloor: Floor): void {
    this.floorForm = this.fb.group({
      id: [incomingFloor ? incomingFloor.id : null],
      buildingId: [incomingFloor ? incomingFloor.buildingId : null],
      floorNumber: [this.getFloorNumber(), Validators.required],
      name: [incomingFloor ? incomingFloor.name : '', Validators.required],
      note: [incomingFloor ? incomingFloor.note : ''],
      floorplan: [incomingFloor ? incomingFloor.floorplan : '', Validators.required]
    });
  }

  getFloorNumber(): number {
    if (this.isNew) {
      return this.newFloorNum;
    } else if (this.floor) {
      return this.floor.floorNumber;
    } else {
      return 1;
    }
  }

  updateFloor(): void {
    const floorDetails = this.floorForm.value;
    this.floorService.updateFloor(floorDetails).subscribe({
      next: () => {
        this.userService.refreshBuilding(this.building.id);
        this.toast.success({
          message: `Successfully updated the floor details (floor-name: ${floorDetails.name}).`,
          dataCy: `update-success-toast`
        });
        this.valueChanged.emit();
      },
      error: () => this.handleError('Failed to update the floor. Please try again.')
    });
  }

  addFloor(): void {
    const floorDetails = this.floorForm.value;
    floorDetails.buildingId = this.building.id;
    this.floorService.addFloor(floorDetails).subscribe({
      next: () => {
        this.userService.refreshBuilding(this.building.id);
        this.toast.success({
          message: `Successfully created the floor details (floor-name: ${floorDetails.name}).`,
          dataCy: `add-success-toast`
        });
        this.valueChanged.emit();
      },
      error: () => this.handleError('Failed to add the floor. Please try again.')
    });
  }

  resetFloor(): void {
    const data: ConfirmDialogData = new ConfirmDialogData(`Changes made in the selected floor will be discarded.`);
    this.confirmationDialogService.open(data).subscribe((confirm: boolean) => {
      if (confirm) {
        this.imageUrl$.next(this.isNew ? '' : this.floorService.getFloorImageUrl(this.floor));
        this.initForm(this.floor);
      }
    });
  }

  deleteFloor(): void {
    const data = new ConfirmDialogData(`Are you sure you want to delete floor?`);
    this.confirmationDialogService.open(data).subscribe((confirm: boolean) => {
      if (confirm) {
        this.floorService.deleteFloor(this.floor.id).subscribe({
          next: () => {
            this.userService.refreshBuilding(this.building.id);
            this.toast.success({
              message: `Successfully deleted the floor details (floor-name: ${this.floor.name}).`,
              dataCy: `delete-success-toast`
            });
            this.valueChanged.emit();
          },
          error: () => this.handleError('Failed to delete the floor. Please try again.')
        });
      }
    });
  }

  onImageUpload(event): void {
    this.imageService.uploadImage(event, this.building?.id).subscribe({
      next: (fileName: string) => {
        this.floorForm.get('floorplan').setValue(fileName);
        this.floorForm.markAsDirty();
        this.imageUrl$.next(this.imageService.getUploadedImageUrl(fileName));
      },
      error: () => this.handleError('Failed to upload the image. Please try again.')
    });
  }

  private initImage(floor: Floor): void {
    if (floor && floor.floorplan) {
      this.imageUrl$.next(this.floorService.getFloorImageUrl(floor));
    } else if (this.isNew) {
      this.imageUrl$.next('');
    }
  }

  checkNodeAvailability(): void {
    if (this.floor) {
      this.nodeService.isNodesAvailableForFloor(this.floor.id).subscribe({
        next: (isNodesAvailable: boolean) => (this.isRemovable = !isNodesAvailable),
        error: () => this.handleError(`Failed fetch nodes for ${this.floor.name} floor`)
      });
    }
  }

  handleError(message): void {
    this.toast.error({
      message,
      dataCy: `load-error-toast`
    });
  }
}
