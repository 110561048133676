// TODO import moment.js
import * as angular from 'angular';

export class QueryService {
  public timeSeries = {};
  public nodeData = {};
  public averages = {};
  public metrics = {}; // TODO add metrics

  constructor(private baseUrl: string) {}

  public distributeAllNodeData(nodeData) {
    let metric;
    for (metric in nodeData) {
      if (
        nodeData.hasOwnProperty(metric) &&
        this.metrics.hasOwnProperty(metric)
      ) {
        this.metrics[metric].datasets.nodeData = nodeData[metric];
      }
    }
  }

  public getNodeDataEntryValue(nodeAddress, metricName) {
    if (
      !angular.isDefined(nodeAddress) ||
      !angular.isDefined(this.metrics[metricName].datasets.nodeData)
    ) {
      return;
    }
    let idx;
    const nodeData = this.metrics[metricName].datasets.nodeData;
    const len = nodeData.length;
    for (idx = 0; idx < len; idx += 1) {
      if (nodeData[idx].id === nodeAddress) {
        return nodeData[idx].value;
      }
    }
  }

  public decorateValue(value, postfix, roundFractions, checkPlural) {
    let decoratedValue = 'N/A';
    if (!isNaN(value)) {
      decoratedValue =
        value >= 1000 ? (value / 1000).toFixed(1) + 'k' : value.toFixed(2);
      decoratedValue = (
        roundFractions ? Math.round(parseInt(decoratedValue)) : decoratedValue
      ).toString();
      return postfix
        ? decoratedValue +
            '' +
            postfix +
            (!!checkPlural && value != 1 ? 's' : '')
        : decoratedValue;
    }
    return decoratedValue;
  }

  public decorateDurationValue(value) {
    if (!isNaN(value)) {
      let scaledValue = value;
      let unit = 'hour';

      if (value > 23) {
        scaledValue = Math.floor(value / 24);
        unit = 'day';
      }

      return scaledValue + ' ' + unit + (scaledValue != 1 ? 's ago' : ' ago');
    }

    return 'N/A';
  }

  public produceLegend(metricName) {
    const gradient = [];
    const legend = [];
    let step;
    let idx;
    const metric = this.metrics[metricName];
    if (!metric || !metric.gradient) {
      return;
    }
    for (step in metric.gradient) {
      if (metric.gradient.hasOwnProperty(step)) {
        gradient.push([metric.gradient[step], step * 100]);
      }
    }
    gradient.sort((a, b) => a[1] - b[1]);
    for (idx = 0; idx < gradient.length; idx += 1) {
      legend.push(gradient[idx][0] + ' ' + gradient[idx][1] + '%');
    }
    return legend.join(',');
  }

  public getAverage(values) {
    let average = 0;
    if (values.length) {
      for (let idx = 0, len = values.length; idx < len; idx += 1) {
        average += values[idx].value;
      }
      average = average / values.length;
    }
    return average.toFixed(2);
  }

  public prepareRatioQuery(query) {
    if (
      !angular.isObject(query.dataType) &&
      query.dataType.indexOf('RATIO') !== -1
    ) {
      return query.withDataType({
        nominator: 'ENERGY_CONSUMPTION',
        denominator: 'OCCUPANCY'
      });
    }
    return query;
  }
}
