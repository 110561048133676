<div class="dialog-header" data-cy='initiate-tests-dialog'>
  <h1 mat-dialog-title data-cy='initiate-tests-dialog-title'>Perform actions for the following {{this.data?.nodeIds?.length}} node addresses:</h1>
  <button data-cy="initiate-tests-dialog-close-button" class="close-button" (click)="dialogRef.close()">
    <mat-icon class="close-icon" data-cy='close-dialog-button'>close</mat-icon>
  </button>
</div>
<div mat-dialog-content>
  <ul data-cy='initiate-tests-dialog-selected-node-list'>
    @for (nodeId of this.data?.nodeIds; track nodeId; ) {
      @if (this.data?.nodesMap?.get(nodeId); as node){
        <li>{{node.discriminator}} #{{node.address.toString(16).toUpperCase()}}</li>
      }
    }
<!--    <li *ngFor="let log of data.logs">{{ log }}</li>-->
  </ul>
</div>
<div mat-dialog-actions>
  <button mat-button class="primaryButton" (click)="initateFunctionalTest()" data-cy='run-functional-test-button'>Run Functional Test</button>
  <button mat-button class="primaryButton" (click)="initiateDurationTest()" data-cy='run-duration-test-button'>Run Duration Test</button>
  <button mat-button [matMenuTriggerFor]="floorplanMenu" data-cy='go-floorplan-button'>Go to Floorplan</button>
  <mat-menu #floorplanMenu="matMenu">
    @for(floor of this.data?.floorplanLinksMap?.keys(); track floor){
      <button mat-menu-item (click)="openFloorplan(this.data?.floorplanLinksMap?.get(floor))" [attr.data-cy]="'floorplan-list-button-' + floor">
        Floor {{getFloorName(floor)}}
      </button>
    }
  </mat-menu>
</div>