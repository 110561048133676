import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OtapUpgradeTaskDTO, OtapUpgradeTaskInputParametersDTO } from '@app/shared/models/otap-upgrade-task.interface';

@Component({
  selector: 'app-form-job-task',
  templateUrl: './form-job-task.component.html',
  styleUrls: ['./form-job-task.component.scss']
})
export class FormJobTaskComponent implements OnInit {
  @Input() task: OtapUpgradeTaskDTO;
  @Output() taskDataChanged = new EventEmitter<OtapUpgradeTaskInputParametersDTO[]>();
  public taskForm: FormGroup;

  constructor(private formBuilder: FormBuilder) {
    this.taskForm = this.formBuilder.group({});
  }

  ngOnInit(): void {
    this.task.inputParameters.forEach((parameter) => {
      this.taskForm.addControl(parameter.name, this.formBuilder.control(parameter.value, Validators.required));
    });

    this.taskForm.valueChanges.subscribe((changedForm) => {
      const changedParameters = this.task.inputParameters.map((param) => {
        const formValue = changedForm[param.name];
        return { ...param, value: formValue };
      });
      this.taskDataChanged.emit(changedParameters);
    });
  }
}
