import { OrPrintController } from './OrPrintController';

export class OrPrintDirective {
  constructor() {
    return {
      bindToController: true,
      controller: OrPrintController,
      controllerAs: 'print',
      replace: true,
      restrict: 'E',
      scope: {},
      template: require('raw-loader!./or-print.html').default
    };
  }
}
