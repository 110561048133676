@if (emailScheduleViewModel$ | async; as vm) {
  <div class="email-schedules-container" [ngClass]="{ 'overlay': isLoading() }">
    @if (isLoading() === true) {
      <div class="spinner">
        <mat-spinner />
      </div>
    }
    <div id="email-form-container" class="mb-4">
      <app-elmt-email-schedule-form
        (submitForm)="handleSubmitForm($event)"
        [schedules]="vm.schedules"
        [users]="vm.users"
        [building]="vm.building" />
    </div>
    <div id="email-table-container" class="h-[60%] max-h-[60%]">
      <app-elmt-email-schedule-table
        (onRowClicked)="handleRowClicked($event)"
        (onDeleteClicked)="handleDeleteRow($event)"
        [building]="vm.building"
        [users]="vm.users"
        [schedules]="vm.schedules" />
    </div>
  </div>
}