export interface IOtapUpgradeDTO {
  id?: number;
  createdDateTime: Date;
  createdByUser: number;
  name: string;
  status: string;
  jobTaskTemplate: string;
  jobTaskTemplateName: string;
}

export class OtapUpgradeDTO implements IOtapUpgradeDTO {
  public createdDateTime: Date;
  public createdByUser: number;
  public name: string;
  public status: string;
  public jobTaskTemplate: string;
  public jobTaskTemplateName: string;
  public id?: number;

  constructor(upgradeJob: IOtapUpgradeDTO) {
    this.createdDateTime = upgradeJob.createdDateTime;
    this.createdByUser = upgradeJob.createdByUser;
    this.name = upgradeJob.name;
    this.status = upgradeJob.status;
    this.jobTaskTemplate = upgradeJob.jobTaskTemplate;
    this.jobTaskTemplateName = upgradeJob.jobTaskTemplateName;
    this.id = upgradeJob.id;
  }
}

export interface AddJobDTO {
  name: string;
  jobTaskTemplate: JobTaskTemplateMap;
}

export type JobTaskTemplateMap = {
  [key: string]: string;
};
