<div class="p-2 h-full">
  @if (isLoading$ | async) {
    <div class="h-full flex justify-center items-center">
      <mat-spinner [diameter]='50' color="primary" />
    </div>
  } @else {
    <div class="header">
      <div class="title">
        <span class="text-base sm:text-xl md:text-xl lg:text-xl xl:text-2xl 2xl:text-3xl font-semibold" [attr.data-cy]="item.dataCy + '-title'" [innerHTML]="item.title"></span>
        <div class="sub-title">
          @if (item.subTitle) {
            <span [attr.data-cy]="item.dataCy + '-subtitle'" class="text-sm sm:text-xl">{{item.subTitle}}</span>
          }
        </div>
      </div>
    </div>
    <div class="body">
      @if(item.iconClass) {
        <div class="h-30">
          <span [attr.data-cy]="item.dataCy + '-icon'" [ngClass]="item.iconClass" class="w-10 h-10 md:w-16 md:h-16 lg:w-16 lg:h-16 xl:w-20 xl:h-20 2xl:w-24 2xl:h-24"></span>
        </div>
      }
      <div [attr.data-cy]="item.dataCy + '-amount'" class="text-2xl md:text-2xl lg:text-4xl xl:text-6xl text-gray-500">
        {{body}}
      </div>
    </div>
  }
</div>
