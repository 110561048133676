import { LuminaireManufacturer } from '../../api/building/LuminaireManufacturer';
import { ILuminaireManufacturerResource } from '../../api/resources/ILuminaireManufacturerResource';
import { PromiseRejectionHandler } from '../../promises/PromiseRejectionHandler';
import { PromiseUtils } from '../promises/PromiseUtils';

export class LuminaireManufacturerResource
  implements ILuminaireManufacturerResource
{
  constructor(
    private $http: ng.IHttpService,
    private baseUrl: string,
    private rejectionHandler: PromiseRejectionHandler
  ) {}

  public getAll(): Promise<LuminaireManufacturer[]> {
    return PromiseUtils.wrapSingle(
      this.$http.get(this.baseUrl),
      this.rejectionHandler
    );
  }
}
