import { Component, OnInit } from '@angular/core';
import { Floor } from '@app/shared/models/floor.interface';
import { UserService } from '@app/shared/services/user/user.service';
import { Building } from '@app/shared/models/building.interface';
import { concatMap, Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { FloorService } from '@services/floor.service';
import { ToastService } from '@services/toast/toast.service';

@Component({
  selector: 'app-floors',
  templateUrl: './floors.component.html',
  styleUrls: ['./floors.component.scss']
})
export class FloorsComponent implements OnInit {
  floors: Floor[] = [];
  building: Building;
  isBusy = true;
  newFloorNum: number;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private floorService: FloorService,
    private toast: ToastService
  ) {}

  ngOnInit(): void {
    this.loadFloors();
  }

  loadFloors(): void {
    this.isBusy = true;
    this.getSelectedBuilding().subscribe((selectedBuilding) => {
      this.building = selectedBuilding;
      this.floorService.getAllFloorsForBuildingId(selectedBuilding.id).subscribe({
        next: (res: Floor[]) => this.handleFloorResponse(res),
        error: () =>
          this.handleError(`Failed to load floors for ${selectedBuilding.name} (${selectedBuilding.id}) building`)
      });
    });
  }

  getSelectedBuilding(): Observable<Building> {
    return this.route.params.pipe(
      concatMap((params: any) => {
        return this.userService.getBuilding(params.buildingId);
      })
    );
  }

  handleFloorResponse(res: Floor[]): void {
    this.newFloorNum = 1;
    this.floors = res.map((fl: Floor) => {
      fl.buildingId = this.building.id;
      if (fl.floorNumber >= this.newFloorNum) {
        this.newFloorNum = fl.floorNumber + 1;
      }
      return fl;
    });
    this.isBusy = false;
  }

  handleError(message): void {
    this.toast.error({ message, dataCy: 'load-error-toast' });
  }
}
