import { BehaviorSubject, delay, Observable, of, Subscription } from 'rxjs';
import { CollectionViewer } from '@angular/cdk/collections';
import { SelectableNode, SensorNode } from '@app/shared/models/sensor-node';
import { ColorUtils } from '@app/shared/utils/color.utils';
import { SensorNodeService } from '@services/sensor-node.service';
import { CopyToClipboardService } from '@services/copy-to-clipboard.service';

export abstract class AbstractNodeDataSource {
  protected nodesSubject = new BehaviorSubject<Partial<SensorNode>[]>([]);
  protected loadingSubject = new BehaviorSubject(false);
  protected countSubject = new BehaviorSubject(0);

  connect(collectionViewer: CollectionViewer): Observable<readonly Partial<SensorNode>[]> {
    return this.nodesSubject.asObservable();
  }
  disconnect(collectionViewer: CollectionViewer): void {
    this.nodesSubject.next([]);
    this.countSubject.next(0);
  }

  get isLoading$(): Observable<boolean> {
    return this.loadingSubject.asObservable();
  }

  abstract copyNodes(): void;

  abstract toggleExpand(row: SelectableNode): void;

  abstract collapseAll(): void;

  abstract loadSensorNodes(filter?: Record<string, string>, sort?: { field: string; asc: boolean }): void;

  abstract fetchSensorNodes(floorId: number, buildingId: number): void;
}

export class NodeDataSource extends AbstractNodeDataSource {
  private MIN_DELAY_FOR_LOADING_SPINNER = 750;
  private filterSubscription: Subscription;
  private toggleSubscription: Subscription;
  private collapeSubscription: Subscription;
  constructor(
    private readonly snService: SensorNodeService,
    private readonly copyToClipboardService: CopyToClipboardService
  ) {
    super();
  }

  loadSensorNodes(filter?: Record<string, string>, sort?: { field: string; asc: boolean }): void {
    this.loadingSubject.next(true);
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }

    this.filterSubscription = this.snService
      .getCurrentFloorNodes$()
      .pipe(delay(this.MIN_DELAY_FOR_LOADING_SPINNER))
      .subscribe((nodes) => {
        if (filter) {
          nodes = nodes.filter((node) => {
            return Object.keys(filter).every((key) => this.doNodeMatchFilter(node, key, filter[key]));
          });
        }
        nodes = this.doNodeListSort(nodes, sort);
        this.nodesSubject.next(nodes);
        this.loadingSubject.next(false);
      });
  }

  fetchSensorNodes(floorId: number, buildingId: number): void {
    this.snService.fetchNodes(floorId, buildingId);
  }

  toggleExpand(row: SelectableNode): void {
    if (this.toggleSubscription) {
      this.toggleSubscription.unsubscribe();
    }
    this.toggleSubscription = this.snService.getCurrentFloorNodes$().subscribe({
      next: (nodes: SelectableNode[]) => {
        nodes.forEach((n) => (n.expanded = n.id === row.id ? !n.expanded : n.expanded));
      }
    });
  }

  collapseAll(): void {
    if (this.collapeSubscription) {
      this.collapeSubscription.unsubscribe();
    }
    this.collapeSubscription = this.snService.getCurrentFloorNodes$().subscribe({
      next: (nodes: SelectableNode[]) => {
        nodes.forEach((n) => (n.expanded = false));
      }
    });
  }

  copyNodes(): void {
    this.copyToClipboardService.copy(this.nodesSubject.getValue());
  }

  /**
   * Logic will return all records if there is no value set in the filter inputs
   * For address filter, the address in hex is first converted to string and then compared to the value in filter
   * For any other filters, the comparison criteria is the same, except for "nodeType" filter, where a node having
   * null nodeType means it should be considered an SN3 node
   */
  private doNodeMatchFilter(node: Partial<SensorNode>, key: string, value: string): boolean {
    const lowerCaseValue = value.toLowerCase();
    if (!lowerCaseValue) {
      return true;
    }

    let nodeValue = '';
    nodeValue =
      key === 'address' && node[key] != null
        ? node[key].toString(16).toLowerCase()
        : (node[key] || 'sn3').toString().toLowerCase();
    return nodeValue.includes(lowerCaseValue);
  }

  private doNodeListSort(nodes: SensorNode[], sort?: { field: string; asc: boolean }): SensorNode[] {
    if (sort) {
      nodes = nodes.sort((next, current) => {
        // ignore null element check and keep the element end of the list
        if (next[sort.field] === null) {
          return 1;
        }
        if (current[sort.field] === null) {
          return -1;
        }

        // check for same element
        if (next[sort.field] === current[sort.field]) {
          return 0;
        }

        if (sort.field !== 'installedAt') {
          return sort.asc ? next[sort.field] - current[sort.field] : -(next[sort.field] - current[sort.field]);
        } else {
          const diff = new Date(next[sort.field]).getTime() - new Date(current[sort.field]).getTime();
          return sort.asc ? diff : -diff;
        }
      });
    }
    return nodes;
  }
}

export class MockDataSource extends AbstractNodeDataSource {
  private MIN_DELAY_FOR_LOADING_SPINNER = 700;
  constructor(private copyToClipboardService: CopyToClipboardService) {
    super();
  }

  private generateNodes(count: number): Partial<SensorNode>[] {
    const result: Partial<SensorNode>[] = [];
    const nodeTypes = ['SN3', 'PN', 'HIM84'];
    const tagColors = [];
    while (count > 0) {
      const id = [...Array(9).keys()].reduce((p, c) => p + Math.floor(Math.random() * 10) + '', '');
      const nodeType = nodeTypes[Math.floor(Math.random() * 3)];
      const address = Math.floor(Math.random() * 1200000);
      const energyConsumption = Math.floor(Math.random() * 500);
      const burnHours = Math.floor(Math.random() * 100);
      const connected = Math.floor(Math.random() * 100) % 2 === 0;
      const tags = [...Array(Math.floor(Math.random() * 3)).keys(), 1].map((n) => {
        return { name: 'Test Tag ' + n, color: ColorUtils.generateColor(), isActive: true };
      });
      const luminaireDrivers: any[] = [...Array(Math.floor(Math.random() * 5)).keys(), 1].map((n) => {
        return {
          burnHours,
          energyConsumption
        };
      });
      const emDrivers: any[] = [...Array(Math.floor(Math.random() * 5)).keys(), 1].map((n) => {
        return { id: n };
      });
      result.push({ id: Number(id), nodeType, address, connected, tags, luminaireDrivers, emDrivers });
      count--;
    }
    return result;
  }

  public copyNodes(): void {
    this.copyToClipboardService.copy(this.nodesSubject.getValue());
  }

  toggleExpand(row: SelectableNode): void {}

  loadSensorNodes(filter?: Record<string, string>): void {
    this.loadingSubject.next(true);
    const delayedObservable = of(this.generateNodes(10)).pipe(delay(this.MIN_DELAY_FOR_LOADING_SPINNER));
    delayedObservable.subscribe((result) => {
      this.loadingSubject.next(false);
      this.nodesSubject.next(result);
      this.countSubject.next(result.length);
    });
  }

  fetchSensorNodes(): void {
    throw new Error('Method not implemented.');
  }

  collapseAll(): void {
    throw new Error('Method not implemented.');
  }
}
