import { Inject, Injectable } from '@angular/core';
import { Environment, environmentToken } from '@environment';
import { FeatureService } from '@services/feature.service';

@Injectable({
  providedIn: 'root'
})
export class QueryExecutorUrlProvider {
  private path;
  constructor(@Inject(environmentToken) private environment: Environment, private featureService: FeatureService) {
    this.path = 'query';
    if (this.featureService.isAvailable('influx')) {
      this.path = 'analytics';
    }
  }
  get simpleQueryUrl(): string {
    return `${this.environment.apiUrl}${this.environment.apiPath}/${this.path}/simple`;
  }
  get complexQueryUrl(): string {
    return `${this.environment.apiUrl}${this.environment.apiPath}/${this.path}`;
  }
  get liveQueryUrl(): string {
    return `${this.environment.apiUrl}${this.environment.apiPath}/${this.path}/live`;
  }
  get liveDataQueryUrl(): string {
    return `${this.environment.apiUrl}${this.environment.apiPath}/${this.path}/live/node-data`;
  }
}
