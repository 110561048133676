import { PromiseRejectionHandler } from '@angularjs/or/promises/PromiseRejectionHandler';
import { PromiseUtils } from '@angularjs/or/angular/promises/PromiseUtils';
import { SensorNodeIdsBatch } from '@angularjs/or/api/building/SensorNodeBatch';
import { LuminaireDriver } from '@angularjs/or/api/building/LuminaireDriver';

export class SensorNodeBleResource {
  constructor(
    private $http: ng.IHttpService,
    private baseUrl: string,
    private rejectionHandler: PromiseRejectionHandler
  ) {}

  public enableBle(buildingId: number, nodeIds?: number[]): Promise<LuminaireDriver[]> {
    return PromiseUtils.wrapSingle(
      this.$http.put(`${this.baseUrl}/${buildingId}/ble-scanning/enable`, nodeIds),
      this.rejectionHandler
    );
  }

  public disableBle(buildingId: number, nodeIds?: number[]): Promise<LuminaireDriver[]> {
    return PromiseUtils.wrapSingle(
      this.$http.put(`${this.baseUrl}/${buildingId}/ble-scanning/disable`, nodeIds),
      this.rejectionHandler
    );
  }

  public queryeBle(buildingId: number, nodeIds?: number[]): Promise<LuminaireDriver[]> {
    return PromiseUtils.wrapSingle(
      this.$http.put(`${this.baseUrl}/${buildingId}/ble-scanning/query`, nodeIds),
      this.rejectionHandler
    );
  }
}
