import * as ng from 'angular';
import { NavigationService } from '@app/shared/services/navigation/navigation.service';
import { NavigationSection } from '@app/shared/services/navigation/navigation-section';
import { NavigationSectionInfo } from '@app/shared/services/navigation/navigation-section-info';
import { SchedulesController } from './SchedulesController';
import IRouteParamsService = ng.route.IRouteParamsService;

export class SchedulesConfig {
  private static ROUTE: string;

  private static routeProvider($routeProvider: ng.route.IRouteProvider) {
    $routeProvider.when(SchedulesConfig.ROUTE, {
      template: require('raw-loader!./schedules.html').default,
      controller: [
        '$routeParams',
        '$scope',
        'BuildingService',
        'ScheduleService',
        'NavigationService',
        'SchedulesNavigationInfo',
        SchedulesController
      ],
      controllerAs: 'schedules'
    });
  }

  static run(
    navigationService: NavigationService,
    navigationServiceInfo: NavigationSectionInfo,
    urlGenerator: (buildingId?: number) => string
  ) {
    navigationService.addSectionToSiteMap(
      new NavigationSection(navigationServiceInfo, urlGenerator)
    );
  }

  static configure(route: string) {
    this.ROUTE = route;
    ng.module('portal')
      .config(['$routeProvider', this.routeProvider])
      .run([
        'NavigationService',
        'SchedulesNavigationInfo',
        'SchedulesUrlGenerator',
        this.run
      ]);
  }
}

export interface ISchedulesParamsService extends IRouteParamsService {
  buildingId: string;
}
