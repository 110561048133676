import { Inject, Injectable } from '@angular/core';
import { Environment, environmentToken } from '@environment';
import { AuthMethod } from '../models/auth-method';
import { WindowRefService } from './window.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationUrlsService {
  public readonly postLogoutRedirectBaseUrl = 'http://organicresponse.com';
  
  private readonly apiUrl: string;
  private readonly baseUrl: string;

  constructor(@Inject(environmentToken) environment: Environment, private windowRef: WindowRefService) {
    const { apiUrl } = environment;
    this.baseUrl = this.windowRef.window.location.origin;
    this.apiUrl = apiUrl;
  }

  get logoutBaseUrl() {
    return `${this.apiUrl}/auth/perform_logout`;
  }

  authenticateBaseUrl(authMethod: AuthMethod) {
    const method = authMethod.toString().toLowerCase();
    return `${this.apiUrl}/auth/${method}?target=${this.baseUrl}/buildings`;
  }
}
