<div class='or-flex-container'>
    <h1 *ngIf='data.isEqual' mat-dialog-title>
        <span class="gateway-status synced">Configuration of this gateway is in sync with Rigado</span>
    </h1>
    <h1 *ngIf='!data.isEqual' mat-dialog-title>
        <span class="gateway-status not-synced">Please review the configuration for this gateway</span>
    </h1>
</div>
<div mat-dialog-content>
    <app-json-comparison [leftTree]="data.left" [rightTree]="data.right"></app-json-comparison>
</div>
<div mat-dialog-actions>
    <button mat-raised-button (click)="onCloseClick()">Close</button>
    <button mat-raised-button color='primary' (click)="onConfirmClick()">Confirm</button>
</div>
