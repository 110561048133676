import { Schedule } from '../../../../or/api/building/Schedule';
import { DayOfTheWeek } from '../../../../or/util/DayOfTheWeek';
import { RuleType } from '../../../../or/data/RuleType';
import { Rule } from '../../../../or/api/building/Rule';
import { ScheduleService } from '../../../../or/services/ScheduleService';
import { MultiselectOption } from '../or-multiselect/MultiselectOption';
import { TagService } from '../../../../or/services/TagService';
import { Tag } from '../../../../or/api/building/Tag';
import { Floor } from '../../../../or/api/building/Floor';
import { IKeyedValue } from '../../../../or/api/IKeyedValue';
import { TimeUtils } from '../../../../or/util/TimeUtils';
import { IBuildingService } from '../../../../or/services/IBuildingService';
import * as Moment from 'moment';
import angular from 'angular';
import IScope = angular.IScope;
import IFormController = angular.IFormController;

export class OrFormDatedScheduleController {
  public form: IFormController;
  public details: Schedule;
  public startDate: string;
  public startHour: string;
  public startMinute: string;
  public endDate: string;
  public endHour: string;
  public endMinute: string;
  public ruleOptions;
  public tagOptions: MultiselectOption<Tag>[];
  public floorOptions: MultiselectOption<Floor>[];
  public onSaveNewSchedule: () => {};
  public onDeleteSchedule: () => {};
  public onClose: () => {};
  public isNew: boolean;
  public saveLabel: string;
  public minEndDate: string;

  constructor(
    private scope: IScope,
    private scheduleService: ScheduleService,
    private tagService: TagService,
    private buildingService: IBuildingService
  ) {}

  public $onInit() {
    if (!this.details.id) {
      this.isNew = true;
    }
    this.updateTagOptions();
    this.updateFloorOptions();
    this.ruleOptions = this.getRuleOptions();
    this.updateTimes();
    this.saveLabel = 'Save schedule';
  }

  public setMinEndDate() {
    this.minEndDate = Moment(this.details.startDate).add(-1, 'days').format('YYYY-MM-DD');
  }

  public updateTimes() {
    this.startHour = this.details.startTime.split(':')[0];
    this.startMinute = this.details.startTime.split(':')[1];
    this.endHour = this.details.endTime.split(':')[0];
    this.endMinute = this.details.endTime.split(':')[1];
    this.startDate = this.details.startDate;
    this.endDate = this.details.endDate;
  }

  public getRuleTypesForRule(rule: Rule): RuleType[] {
    // TODO:Uncomment filter on rule-type to allow only one rule type rule per schedule.
    return RuleType.all();
  }

  public getRuleOptions() {
    return RuleType.all();
  }

  // TODO Not sure why we would get all schedules and overwrite our current one with the first in the array?
  public updateSchedule() {
    this.scheduleService.getSchedules().then((schedules) => {
      this.scope.$apply(() => {
        this.details = schedules.length > 0 ? Schedule.clone(schedules[0]) : new Schedule();
      });
    });
  }

  public updateTagOptions() {
    this.buildingService.getCurrentBuilding().then((building) => {
      this.tagService.getTags(building, true, true).then((tags) => {
        this.scope.$apply(() => {
          this.tagOptions = angular.copy(tags.map((tag) => new MultiselectOption(tag.name, tag)));
        });
      });
    });
  }

  public updateFloorOptions() {
    this.buildingService.getFloorsForCurrentBuilding().then((floors) => {
      this.scope.$apply(() => {
        this.floorOptions = angular.copy(
          floors.map((floor) => new MultiselectOption(floor.floorNumber.toString(), Floor.toOutline(floor)))
        );
      });
    });
  }

  public areEqualById<T extends IKeyedValue<V>, V>(a: T, b: T): boolean {
    return a.id == b.id;
  }

  public getDaysOfTheWeek(): DayOfTheWeek[] {
    return TimeUtils.getDaysOfTheWeek();
  }

  public getHours(): string[] {
    return TimeUtils.getHoursInDay();
  }

  public getMinutes(): string[] {
    return TimeUtils.getMinutesInHour(10);
  }

  public updateStartTime() {
    this.details.startTime = this.startHour + ':' + this.startMinute;
  }

  public updateEndTime() {
    this.details.endTime = this.endHour + ':' + this.endMinute;
  }

  public getTimeOptionsBefore(timeOption) {
    const options = TimeUtils.getHalfHoursInDay();
    const timeOptionIndex = options.indexOf(timeOption);
    const availableOptions = angular.copy(options);
    if (timeOptionIndex >= 0) {
      availableOptions.length = timeOptionIndex;
    }
    return availableOptions;
  }

  public getTimeOptionsAfter(timeOption) {
    const options = TimeUtils.getHalfHoursInDay();
    const timeOptionIndex = options.indexOf(timeOption);
    const availableOptions = angular.copy(options);
    if (timeOptionIndex >= 0) {
      return availableOptions.splice(timeOptionIndex, availableOptions.length - timeOptionIndex - 1);
    }
    return availableOptions;
  }

  public validateRules() {
    if (!this.details.rules.length) {
      return false;
    }
    return this.details.rules.every((rule) => {
      const command = rule.command;
      const value = rule.value;
      return angular.isString(command) && angular.isNumber(value);
    });
  }

  public addRule() {
    this.details.rules.push(new Rule());
    this.ruleOptions = this.getRuleOptions();
  }

  public removeRule(index: number) {
    this.details.rules.splice(index, 1);
  }

  public save() {
    if (!this.form.$valid) {
      return;
    }

    this.saveLabel = 'Saving...';

    if (this.details.id != null) {
      this.scheduleService.updateSchedule(this.details).then((schedule) => {
        this.scope.$apply(() => (this.details = Schedule.clone(schedule)));
        this.close();
        this.saveLabel = 'Save schedule';
      });
    } else {
      this.scheduleService.saveSchedule(this.details).then((schedule) => {
        this.scope.$apply(() => (this.details = Schedule.clone(schedule)));
        if (this.onSaveNewSchedule) {
          this.onSaveNewSchedule();
        }
        this.saveLabel = 'Save schedule';
      });
    }
  }

  public delete() {
    if (!confirm(`Delete schedule '${this.details.name}?'`)) {
      return;
    }

    if (this.details.id != null) {
      this.scheduleService.deleteSchedule(this.details).then(() => {
        if (this.onDeleteSchedule) {
          this.onDeleteSchedule();
        }
      });
    }
  }

  public close() {
    if (this.onClose) {
      this.onClose();
    }
  }

  public validateDates() {
    return (
      Moment(this.details.startDate, 'YYYY-MM-DD', true).isValid() &&
      Moment(this.details.endDate, 'YYYY-MM-DD', true).isValid() &&
      this.validateDuration()
    );
  }

  private validateDuration(): boolean {
    if (this.details.endDate === this.details.startDate) {
      return TimeUtils.isAfter(this.details.endTime, this.details.startTime);
    }
    return Moment(this.details.endDate).isAfter(this.details.startDate);
  }
}
