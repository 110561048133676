@if (vm$ | async; as viewModel) {
  <div id="emergencyLightingLogs">
    <div class="content">
      <div class="filter-panel">
        @if(viewModel) {
          <div class="filter-content" *ngIf="viewModel">
            <app-elmt-tests-filter
              [building]="viewModel.building"
              [floors]="floors"
              [schedules]="viewModel.schedules"
              [tags]="viewModel.tags.tags"
              (filterSelections)="onFilterClick()"
            ></app-elmt-tests-filter>
          </div>
        }
      </div>
      <div class='flex justify-center'>
        <mat-tab-group mat-stretch-tabs='false' mat-align-tabs='start' (selectedTabChange)='tabChange($event)'>
          <mat-tab label="Report">
            <div class="logs-panel">
              <div class="log-content">
                <app-elmt-reports-table #elmtLogsTable
                                        [building]="viewModel.building"
                                        [pageNumber]="pageNumber"
                                        [pageSize]="pageSize"
                                        (pageNumberChange)="pageNumber = $event"
                                        (pageSizeChange)="pageSize = $event"
                                        (filterLogs)="filterLogs($event.pageNumber, $event.pageSize)"
                                        [logsDataSource]="logsDataSource" [enableSelection]='true' [isReport]='true'>
                </app-elmt-reports-table>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Logs">
            <div class="logs-panel">
              <div class="log-content">
                <app-elmt-reports-table #elmtLogsTable
                                        [building]="viewModel.building"
                                        [pageNumber]="pageNumber"
                                        [pageSize]="pageSize"
                                        (pageNumberChange)="pageNumber = $event"
                                        (pageSizeChange)="pageSize = $event"
                                        (filterLogs)="filterLogs($event.pageNumber, $event.pageSize, false)"
                                        [logsDataSource]="logsDataSource" [enableSelection]='false' [isReport]='false'>
                </app-elmt-reports-table>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
}

<ng-template #loadingTemplate>
  <p data-cy='load-pending-toast'>Loading the data, please wait</p>
</ng-template>
<ng-template #successTemplate>
  <i data-cy='load-success-toast'>Data ready!</i>
</ng-template>
<ng-template #errorTemplate>
  <b data-cy='load-error-toast'>There was an error loading data. Please reload the page</b>
</ng-template>
