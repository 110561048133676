import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthorizationModule } from '@app/shared/directives/authorization.module';
import { HeaderService } from '@app/shared/services/header.service';
import { WindowRefService } from '@app/shared/services/window.service';
import { BuildingsMenuComponent } from './buildings-menu/buildings-menu.component';
import { FloorsMenuComponent } from './floors-menu/floors-menu.component';
import { HeaderComponent } from './header.component';
import { SessionMenuComponent } from './session-menu/session-menu.component';
import { SiteMenuComponent } from './site-menu/site-menu.component';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { SharedComponentsModule } from '@app/shared/shared-components.module';

@NgModule({
  declarations: [
    BuildingsMenuComponent,
    FloorsMenuComponent,
    HeaderComponent,
    SessionMenuComponent,
    SiteMenuComponent,
    UserMenuComponent
  ],
  imports: [CommonModule, RouterModule, AuthorizationModule, SharedComponentsModule],
  exports: [HeaderComponent],
  providers: [HeaderService, WindowRefService]
})
export class HeaderModule {}
