import { DISCRIMINATOR, Selectable } from '@app/shared/models/selectable.interface';
import { EmergencyLightingTestState } from '@app/shared/models/emergency-lighting-test-state';

export interface EmDriverDTO {
  id?: number;
  x: number;
  y: number;
  address: number;
  nodeId: number;
  durationTestResult: number;
  emergencyFailureState: number;
  emergencyMode: number;
  emergencyStatus: number;
  ratedDuration: number;
  conflictingAddress: boolean;
  updatedAt: Date;
  emergencyLightingFailureStates?: string[];
  functionalTestState: EmergencyLightingTestState;
  durationTestState: EmergencyLightingTestState;
  inInitiatedTest: boolean;
  inProgressTest: boolean;
  activeTest: boolean;
  passedTest: boolean;
  failedTest: boolean;
  address16: string;
}

export interface SelectableEmDriver extends EmDriverDTO, Selectable {}
export class EmDriver implements EmDriverDTO, SelectableEmDriver {
  public id?: number;
  public x: number;
  public y: number;
  public address: number;
  public nodeId: number;
  public durationTestResult: number;
  public emergencyFailureState: number;
  public emergencyMode: number;
  public emergencyStatus: number;
  public ratedDuration: number;
  public conflictingAddress: boolean;
  public updatedAt: Date;
  public emergencyLightingFailureStates?: string[];
  public functionalTestState: EmergencyLightingTestState;
  public durationTestState: EmergencyLightingTestState;
  public discriminator: DISCRIMINATOR;
  constructor(dto: EmDriverDTO) {
    this.id = dto.id;
    this.x = dto.x;
    this.y = dto.y;
    this.address = dto.address;
    this.nodeId = dto.nodeId;
    this.durationTestResult = dto.durationTestResult;
    this.emergencyFailureState = dto.emergencyFailureState;
    this.emergencyMode = dto.emergencyMode;
    this.emergencyStatus = dto.emergencyStatus;
    this.ratedDuration = dto.ratedDuration;
    this.conflictingAddress = dto.conflictingAddress;
    this.updatedAt = dto.updatedAt;
    this.emergencyLightingFailureStates = dto.emergencyLightingFailureStates;
    this.functionalTestState = dto.functionalTestState;
    this.durationTestState = dto.durationTestState;
    this.discriminator = DISCRIMINATOR.EM_DRIVER;
  }

  static of(dto: EmDriverDTO): EmDriver {
    return new EmDriver({ ...dto });
  }

  static ofMultiple(dto: EmDriverDTO[]): EmDriver[] {
    const dtos = [];
    if (dto) {
      dto.forEach((item) => dtos.push(new EmDriver({ ...item })));
    }
    return dtos;
  }

  get address16(): string {
    return this.address != null ? this.address.toString(16).toUpperCase() : 'Unmapped';
  }

  get failedTest(): boolean {
    return (
      (this.functionalTestState != null && EmergencyLightingTestState.FAILED.equals(this.functionalTestState)) ||
      (this.durationTestState != null && EmergencyLightingTestState.FAILED.equals(this.durationTestState)) ||
      (this.functionalTestState != null && EmergencyLightingTestState.TIMED_OUT.equals(this.functionalTestState)) ||
      (this.durationTestState != null && EmergencyLightingTestState.TIMED_OUT.equals(this.durationTestState))
    );
  }

  get passedTest(): boolean {
    return (
      !this.failedTest &&
      ((this.functionalTestState != null && EmergencyLightingTestState.SUCCEEDED.equals(this.functionalTestState)) ||
        (this.durationTestState != null && EmergencyLightingTestState.SUCCEEDED.equals(this.durationTestState)))
    );
  }

  static isStatePassed(state: string): boolean {
    return EmergencyLightingTestState.SUCCEEDED.equalsString(state);
  }

  static isStateInProgress(state: string): boolean {
    return (
      EmergencyLightingTestState.INITIATED.equalsString(state) ||
      EmergencyLightingTestState.IN_PROGRESS.equalsString(state)
    );
  }

  static isStateCancelled(state: string): boolean {
    return EmergencyLightingTestState.CANCELLED.equalsString(state);
  }

  static isStateTimedOut(state: string): boolean {
    return EmergencyLightingTestState.TIMED_OUT.equalsString(state);
  }

  static isStateFailed(state: string): boolean {
    return EmergencyLightingTestState.FAILED.equalsString(state);
  }

  static isStateFailedAndNotCancelled(state: string): boolean {
    return this.isStateTimedOut(state) || this.isStateFailed(state);
  }

  get activeTest(): boolean {
    return (
      EmergencyLightingTestState.INITIATED.equals(this.functionalTestState) ||
      EmergencyLightingTestState.IN_PROGRESS.equals(this.functionalTestState) ||
      EmergencyLightingTestState.INITIATED.equals(this.durationTestState) ||
      EmergencyLightingTestState.IN_PROGRESS.equals(this.durationTestState)
    );
  }

  get inProgressTest(): boolean {
    return (
      EmergencyLightingTestState.IN_PROGRESS.equals(this.functionalTestState) ||
      EmergencyLightingTestState.IN_PROGRESS.equals(this.durationTestState)
    );
  }

  get inInitiatedTest(): boolean {
    return (
      EmergencyLightingTestState.INITIATED.equals(this.functionalTestState) ||
      EmergencyLightingTestState.INITIATED.equals(this.durationTestState)
    );
  }

  public get status(): string {
    if (this.address == null) {
      return 'UNKNOWN';
    }
    if (this.isFaulty) {
      return 'FAULTY';
    }
    return 'OK';
  }

  public get isFaulty(): boolean {
    return this.emergencyLightingFailureStates != null && this.emergencyLightingFailureStates.length > 0;
  }
}
