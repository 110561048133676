import * as ng from 'angular';
import { NavigationService } from '@app/shared/services/navigation/navigation.service';
import { NavigationSection } from '@app/shared/services/navigation/navigation-section';
import { NavigationSectionInfo } from '@app/shared/services/navigation/navigation-section-info';
import { IntegrationsAreasController } from './IntegrationsAreasController';

export class IntegrationsAreasConfig {
  private static ROUTE: string;

  private static routeProvider($routeProvider) {
    $routeProvider.when(IntegrationsAreasConfig.ROUTE, {
      template: require('raw-loader!./integrations-areas.html').default,
      controller: [
        '$routeParams',
        '$scope',
        '$location',
        '$timeout',
        'BacnetAreaService',
        'BuildingService',
        'FloorService',
        'GatewayService',
        'NavigationService',
        'IntegrationsAreasNavigationInfo',
        'IntegrationsAreasUrlGenerator',
        IntegrationsAreasController
      ],
      controllerAs: 'integrationsAreas'
    });
  }

  static run(
    navigationService: NavigationService,
    navigationSectionInfo: NavigationSectionInfo,
    urlGenerator: (buildingId?: number, floorId?: number) => string
  ) {
    navigationService.addSectionToSiteMap(
      new NavigationSection(navigationSectionInfo, urlGenerator)
    );
  }

  static configure(route: string) {
    this.ROUTE = route;
    ng.module('portal')
      .config(['$routeProvider', this.routeProvider])
      .run([
        'NavigationService',
        'IntegrationsAreasNavigationInfo',
        'IntegrationsAreasUrlGenerator',
        this.run
      ]);
  }
}
