import { Inject, Injectable } from '@angular/core';
import { Resource } from '@app/shared/resources/resource';
import { HttpClient } from '@angular/common/http';
import { Environment, environmentToken } from '@environment';
import { Observable } from 'rxjs';
import { EmDriver } from '@app/shared/models/em-driver';
import { SensorNodeIdsBatch } from '@app/shared/models/sensor-node-batch.interface';

@Injectable({
  providedIn: 'root'
})
export class EmDriverResource extends Resource<EmDriver, number> {
  constructor(private http: HttpClient, @Inject(environmentToken) private environment: Environment) {
    super(http, `${environment.apiUrl}${environment.apiPath}/em-driver`);
  }

  public getByNodeIds(nodeIds: number[]): Observable<EmDriver[]> {
    return this.http.post<EmDriver[]>(`${this.baseUrl}/node`, new SensorNodeIdsBatch(nodeIds));
  }

  public getByNodeId(nodeId: number): Observable<EmDriver[]> {
    return this.http.get<EmDriver[]>(`${this.baseUrl}/node/${nodeId}`);
  }
}
