import { BuildingAuthorityType } from '@app/shared/models/building-authority-type';

export class Role implements IRole {
  constructor(
    public id: number,
    public name: string,
    public authorityTypes: BuildingAuthorityType[],
    public description: string
  ) {}
}

export interface IRole {
  id: number;
  name: string;
  authorityTypes: any[];
  description: string;
}

export class RoleSerialised implements IRole {
  public id: number;
  public name: string;
  public description: string;
  public authorityTypes: string[] = [];

  constructor(role: Role) {
    this.id = role.id;
    this.name = role.name;
    this.description = role.description;
    role.authorityTypes.forEach((auth) => {
      this.authorityTypes.push(auth.toString());
    });
  }
}

export class FormRole extends Role {
  public dirty: boolean;

  constructor(
    id: number,
    name: string,
    authorityTypes: BuildingAuthorityType[],
    description: string
  ) {
    super(id, name, authorityTypes, description);
  }

  static fromRole(role: Role): FormRole {
    return new FormRole(
      role.id,
      role.name,
      role.authorityTypes,
      role.description
    );
  }

  static fromRoles(roles: Role[]): FormRole[] {
    if (roles == null) {
      return null as FormRole[];
    }
    const formRoles: FormRole[] = [];
    roles.forEach((role) =>
      formRoles.push(
        new FormRole(role.id, role.name, role.authorityTypes, role.description)
      )
    );
    return formRoles;
  }
}
