export class Dates {
  constructor(
    public years: number[] = [],
    public months: number[] = [],
    public days: number[] = [],
    public hours: number[] = [],
    public minutes: number[] = []
  ) {}

  public static fromString(value: string) {
    const types: string[] = value.split(':');

    return new Dates(
      types[0].split(',').map((v) => Number(v)),
      types[1].split(',').map((v) => Number(v)),
      types[2].split(',').map((v) => Number(v)),
      types[3].split(',').map((v) => Number(v)),
      types[4].split(',').map((v) => Number(v))
    );
  }

  public toString(): string {
    return (
      this.years.join(',') +
      ':' +
      this.months.join(',') +
      ':' +
      this.days.join(',') +
      ':' +
      this.hours.join(',') +
      ':' +
      this.minutes.join(',')
    );
  }
}
