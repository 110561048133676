import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderService } from '@services/header.service';
import { combineLatestWith, Observable, switchMap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '@services/user/user.service';
import { Building } from '@app/shared/models/building.interface';
import { NavigationService } from '@services/navigation/navigation.service';
import { NodeListComponent } from '@components/node-list/node-list.component';
import { FloorplanModule } from '@components/floorplan/floorplan.module';
import {
  CheckboxState,
  FloorplanTag,
  orderByTagName,
  SelectableWithTags,
  TagType
} from '@app/shared/models/tag.interface';
import { TagService } from '@services/tag.service';
import { map } from 'rxjs/operators';
import { SelectableNode, SensorNode } from '@app/shared/models/sensor-node';
import { SensorNodeService } from '@services/sensor-node.service';
import { FloorplanService } from '@services/floorplan.service';
import { MatButtonModule } from '@angular/material/button';
import { BuildingAuthorityType } from '@app/shared/models/building-authority-type';
import { SecurityService } from '@services/security.service';
import { TagChangeEvent, TagsComponent } from '@components/tags/tags.component';
import { ConfirmationDialogService } from '@services/confirmation-dialog/confirmation-dialog.service';
import { ConfirmDialogData } from '@components/dialogs/confirm/confirm.component';
import { PanelToggleComponent } from '@components/panel-toggle/panel-toggle.component';
import { MappedNodesCounterComponent } from '@components/mapped-nodes-counter/mapped-nodes-counter.component';
import { MappingService } from '@services/mapping.service';
import { ToastService } from '@services/toast/toast.service';
import { LampTypeFormComponent } from '@components/lamp-type-form/lamp-type-form.component';

@Component({
  selector: 'app-sensor-nodes',
  standalone: true,
  imports: [
    CommonModule,
    NodeListComponent,
    FloorplanModule,
    MatButtonModule,
    PanelToggleComponent,
    MappedNodesCounterComponent,
    LampTypeFormComponent,
    TagsComponent
  ],
  templateUrl: './sensor-nodes.component.html',
  styleUrl: './sensor-nodes.component.scss'
})
export class SensorNodesComponent implements OnInit, OnDestroy {
  building: Building;
  floorId: number;
  tags$: Observable<FloorplanTag[]>;
  isLeftColHidden = false;
  isRightColHidden = false;
  hasManageTenantForBuilding = false;
  private refreshNodesAfterMap: string | number | NodeJS.Timeout;
  private activityTimeout: string | number | NodeJS.Timeout;
  private waitingTimeForPostMappingPause = 200;
  private isPollingForMappingUpdates = false;
  private mappingTimeout: string | number | NodeJS.Timeout;
  private readonly UNMAP_NODE_MSG = 'Do you want to unmap selected node(s)?';
  private readonly UNMAP_PN_NOT_ALLOWED = "Can't unmap PN when in SN3 mapping mode";
  private readonly UNMAPPING_SUCCESSFUL = 'Node unmapped successfully';
  private readonly TAG_CREATE_SUCCESSFUL = 'Tag created successfully';
  private readonly TAG_UPDATE_SUCCESSFUL = 'Tag updated successfully';
  private readonly TAG_DELETE_SUCCESSFUL = 'Tag deleted successfully';

  constructor(
    private header: HeaderService,
    private route: ActivatedRoute,
    private userService: UserService,
    private navService: NavigationService,
    private tagService: TagService,
    private snService: SensorNodeService,
    private floorplanService: FloorplanService,
    private securityService: SecurityService,
    private dialogService: ConfirmationDialogService,
    private mappingService: MappingService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.header.showBuildingsMenu();
    this.header.showSiteMenu();
    this.header.showUserMenu();
    this.header.showSessionMenu();
    this.header.showFloorsMenu();
    this.setBuildingIdAndFloorId();
    this.setupMappingMode();
  }

  ngOnDestroy(): void {
    this.mappingService.clearMappingFlags();
  }

  get currentFloorNodes$(): Observable<SensorNode[]> {
    return this.snService.getCurrentFloorNodes$();
  }

  private setBuildingIdAndFloorId(): void {
    this.route.params
      .pipe(
        switchMap((params) => {
          this.floorId = params.floorId;
          this.loadTags(params.buildingId);
          return this.userService.getBuilding(params.buildingId);
        })
      )
      .subscribe((building) => {
        this.navService.initNavigation(window.location.href, building);
        this.building = building;
      });
  }

  private setupMappingMode(): void {
    this.mappingService.setPostMappingActivity(() => {
      if (!this.isPollingForMappingUpdates) {
        this.isPollingForMappingUpdates = true;
        this.pollForMappingUpdates();
      }
    });
  }

  private checkAndUpdateNodesAfterMapping(): void {
    this.snService.updateNodesAfterMapping(this.building.id);
    this.mappingService.setPostActivityInProgress(false);
  }

  private pollForMappingUpdates(maxRetries = 4): void {
    this.mappingTimeout = setTimeout(() => {
      if (this.mappingService.isActivityWaiting) {
        this.mappingService.setPostActivityInProgress(false);
      }
      // the condition is used to prevent the UI from fetching the nodes from backend if a process is in progress
      if (!this.mappingService.isPostActivityInProgress) {
        this.mappingService.setPostActivityInProgress(true);
        this.checkAndUpdateNodesAfterMapping();
      }

      if (this.isPollingForMappingUpdates && maxRetries > 0) {
        this.pollForMappingUpdates(maxRetries - 1);
      } else {
        this.stopPollingForMappingUpdates();
        this.mappingService.setPostActivityInProgress(false);
      }
    }, 5000);
  }

  private stopPollingForMappingUpdates(): void {
    if (this.mappingTimeout) {
      clearTimeout(this.mappingTimeout);
    }
    this.isPollingForMappingUpdates = false;
  }

  nodeClick(node: SelectableNode): void {
    if (this.floorplanService.isSensorNodeMappingModeActive) {
      node.properlyMapped ? this.unmapMappedNode(node) : this.mapUnmappedNode(node);
    } else {
      this.updateSelection(node);
    }
  }

  private unmapMappedNode(node: SelectableNode): void {
    if (node.isPassiveNode) {
      this.toastService.error({ message: this.UNMAP_PN_NOT_ALLOWED, dataCy: 'send-error-toast' });
    } else {
      this.dialogService.open(new ConfirmDialogData(this.UNMAP_NODE_MSG)).subscribe((result) => result && unmapNode());

      const unmapNode = () => {
        this.mappingService.unmapGenericNode(node).subscribe(() => {
          this.toastService.success({ message: this.UNMAPPING_SUCCESSFUL, dataCy: 'send-success-toast' });
          // tslint:disable-next-line:no-unused-expression
          this.refreshNodesAfterMap && clearTimeout(this.refreshNodesAfterMap);
          this.refreshNodesAfterMap = setTimeout(() => this.snService.fetchNodes(node.floorId, this.building.id), 3000);
        });
      };
    }
  }

  private mapUnmappedNode(node: SelectableNode): void {
    if (
      !node.isNotifying &&
      node.address == null &&
      !this.mappingService.isActivityInProgress &&
      !this.mappingService.isActivityWaiting
    ) {
      this.mappingService.setMappingTried(true);
      node.isNotifying = true;
      this.mappingService.setActivityWaiting(true);
      this.performMappingActivity(node);
    }
  }

  private updateSelection(node: SelectableNode): void {
    if (this.floorplanService.isCumulativeModeActive) {
      this.snService.toggleEntitySelection(node, this.floorplanService.isCumulativeModeActive);
    } else {
      this.snService.selectEntitiesInArea([node], this.floorplanService.isCumulativeModeActive);
    }
    this.snService.updateQueryParams();
  }

  private performMappingActivity(node: SelectableNode): void {
    this.activityTimeout = setTimeout(() => {
      if (!this.mappingService.isPostActivityInProgress) {
        this.mappingService.doMapping(node.id, this.building.id).subscribe({
          complete: () => {
            node.isNotifying = false;
            this.stopMappingActivity();
            node.hasMappingBeenAttempted = true;
          },
          error: () => {
            node.isNotifying = false;
            this.stopMappingActivity();
          }
        });
        this.mappingService.setActivityWaiting(false);
      } else {
        this.performMappingActivity(node);
      }
    }, this.waitingTimeForPostMappingPause);
  }

  private stopMappingActivity(): void {
    if (this.activityTimeout) {
      clearTimeout(this.activityTimeout);
    }
  }

  toggleLeft(val: boolean): void {
    this.isLeftColHidden = val;
  }

  toggleRight(val: boolean): void {
    this.isRightColHidden = val;
  }

  toggleBoth(val: boolean): void {
    this.isLeftColHidden = val;
    this.isRightColHidden = val;
  }

  loadTags(buildingId: number): void {
    this.securityService
      .isAuthorizedForBuilding(BuildingAuthorityType.MANAGE_TENANT.value, buildingId)
      .subscribe((result) => {
        this.hasManageTenantForBuilding = result;
      });
    this.tagService
      .getTagsForBuildingId(buildingId, true)
      .pipe(combineLatestWith(this.snService.getCurrentFloorSelectedEntities$()))
      .subscribe(([_, nodes]) => {
        this.tags$ = this.tagService.tagsForFloor$.pipe(
          map((tags) => {
            if (nodes.length === 0) {
              tags?.forEach((tag) => (tag.checked = CheckboxState.UNCHECKED));
              return tags;
            }
            tags.sort(orderByTagName).forEach((tag) => {
              const checked = nodes.every((node: SelectableWithTags) => node.tags?.some((t) => t.id === tag.id));
              const mixed = nodes.some((node: SelectableWithTags) => node.tags?.some((t) => t.id === tag.id));
              if (checked) {
                tag.checked = CheckboxState.CHECKED;
              } else if (mixed) {
                tag.checked = CheckboxState.MIXED;
              } else {
                tag.checked = CheckboxState.UNCHECKED;
              }
            });
            return tags;
          })
        );
      });
  }

  toggleTag(event: TagChangeEvent): void {
    const { tag, checked } = event;
    const isTagAddOp = checked === CheckboxState.CHECKED;
    const selectedNodes = this.snService.selectedEntities;

    if (!selectedNodes.length || (tag.tagType === TagType.TENANT && !this.hasManageTenantForBuilding)) return;

    selectedNodes.forEach((node: SelectableWithTags) => {
      node.tags = Array.isArray(node.tags) ? node.tags : [];
      const isTagAlreadyPresent = node.tags.some((t) => t.id === tag.id);

      if (isTagAddOp && !isTagAlreadyPresent) {
        node.tags = [...node.tags, tag];
        this.snService.tagNodes({ tagIds: [tag.id], nodeIds: [node.id] }).subscribe();
      } else if (!isTagAddOp && isTagAlreadyPresent) {
        const tagsRemoved = node.tags.filter((t) => t.id !== tag.id);
        node.tags = [...tagsRemoved];
        this.snService.unTagNodes({ tagIds: [tag.id], nodeIds: [node.id] }).subscribe();
      }
    });
  }
  tagCreate(tag: FloorplanTag): void {
    this.tagService.createTag(tag).subscribe({
      next: () => {
        this.loadTags(tag.buildingId);
        this.toastService.success({ message: this.TAG_CREATE_SUCCESSFUL, dataCy: 'tag-create-success-toast' });
      },
      error: (err) => {
        this.toastService.error({ message: err.error?.message, dataCy: 'tag-create-failed-toast' });
      }
    });
  }

  tagUpdate(tag: FloorplanTag): void {
    this.tagService.updateTag(tag.id, tag).subscribe({
      next: () => {
        this.loadTags(tag.buildingId);
        this.snService.fetchNodes(this.floorId, this.building.id);
        this.toastService.success({ message: this.TAG_UPDATE_SUCCESSFUL, dataCy: 'tag-update-success-toast' });
      },
      error: (err) => {
        this.toastService.error({ message: err.error?.message, dataCy: 'tag-update-failed-toast' });
      }
    });
  }

  deleteTag(tag: FloorplanTag): void {
    const data = new ConfirmDialogData(`Are you sure you want to remove the tag "${tag.name}"?`, 'Delete Tag');
    this.dialogService.open(data).subscribe((result) => {
      if (result) {
        this.tagService.deleteTag(tag.id).subscribe({
          next: () => {
            this.loadTags(tag.buildingId);
            this.snService.fetchNodes(this.floorId, this.building.id);
            this.toastService.success({ message: this.TAG_DELETE_SUCCESSFUL, dataCy: 'tag-update-success-toast' });
          },
          error: (err) => {
            this.toastService.error({ message: err.error?.message, dataCy: 'tag-delete-failed-toast' });
          }
        });
      }
    });
  }

  get floorPanelClass(): string {
    if (this.isLeftColHidden && this.isRightColHidden) {
      return 'col-span-12';
    } else if (this.isLeftColHidden) {
      return 'col-span-10';
    } else if (this.isRightColHidden) {
      return 'col-span-7';
    } else {
      return 'col-span-6';
    }
  }

  get nodeListPanelClass(): string {
    if (this.isLeftColHidden) {
      return 'hidden';
    } else if (this.isRightColHidden) {
      return 'col-span-5';
    } else {
      return 'col-span-4';
    }
  }

  // TODO: below commented code is for reference, we will need it if floorplan toggle button is required
  // get toggleNodeListPanelClass(): string {
  //   if (this.isLeftColHidden) {
  //     return 'col-end-1';
  //   } else if (this.isRightColHidden) {
  //     return 'col-span-5';
  //   }
  //   return 'col-span-4';
  // }

  // <app-panel-toggle [ngClass]="toggleNodeListPanelClass" label="Nodes" icon="node" (togglePanel)="toggleLeft($event)" [toggleState]="isLeftColHidden"></app-panel-toggle>
  //   <div [ngClass]="floorPanelClass">
  //   <button class="or-panel-toggle-button">
  //   <span class="or-panel-toggle-icon or-icon-plan">
  //   <span class="or-icon or-tiny or-icon-plan or-inverse"></span>
  //     </span>
  //     <span class="or-button-label or-panel-toggle-label ng-binding">Floorplan</span>
  //     </button>
  //     </div>
  //     <div class="flex col-span-2" [ngClass]="isRightColHidden ? 'justify-end col-start-[-1]' : ''">
}
