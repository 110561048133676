import { Floor } from '../api/building/Floor';
import { IResource } from '../api/IResource';
import { IBuildingService } from './IBuildingService';
import { SavedEntity } from '../api/SavedEntity';
import { NavigationService } from '@app/shared/services/navigation/navigation.service';
import { Subject } from 'rxjs';
import { UserService } from '@services/user/user.service';

export class FloorService {
  constructor(
    private floorResource: IResource<Floor, number>,
    private floorplanUrlGenerator: (floor: Floor) => string,
    private buildingService: IBuildingService,
    private navigationService: NavigationService,
    private newUserService: UserService
  ) {}

  private currentFloorId: number;
  private currentFloor: Floor;
  private floorPromise: Promise<Floor>;
  public floorChange = new Subject<number>();

  public setCurrentFloorId(floorId: number): void {
    this.currentFloorId = floorId;
    this.navigationService.floorId = this.currentFloorId;
    this.invalidateCurrentFloor();
  }

  public getCurrentFloor(): Promise<Floor> {
    if (this.currentFloor != null) {
      return Promise.resolve(this.currentFloor);
    }

    if (this.currentFloorId == null || isNaN(this.currentFloorId)) {
      return Promise.reject('No floor set');
    }

    if (this.floorPromise != null) {
      return this.floorPromise;
    }

    const promise = new Promise<Floor>((resolve, reject) => {
      this.floorResource
        .retrieve(this.currentFloorId)
        .then((floor) => {
          this.currentFloor = floor;
          this.floorChange.next(floor.id);
          this.floorPromise = null;
          resolve(floor);
        })
        .catch(() => {
          this.floorPromise = null;
          reject();
        });
    });

    this.floorPromise = promise;
    return promise;
  }

  public getAllFloorsForCurrentBuilding(): Promise<Floor[]> {
    return new Promise((resolve) => {
      this.buildingService
        .getFloorIdsForCurrentBuilding(true)
        .then((ids: number[]) => {
          this.floorResource
            .retrieveMany(ids)
            .then((floors: Floor[]) => {
              resolve(floors);
            })
            .catch((err) => console.error(err));
        })
        .catch((err) => console.error(err));
    });
  }

  public getFloorImageUrl(floor: Floor): string {
    return this.floorplanUrlGenerator(floor);
  }

  public invalidateCurrentFloor(): void {
    this.currentFloor = null;
    this.floorPromise = null;
    this.floorChange.next(this.currentFloorId);
  }

  public produceCleanFloor(buildingId): Floor {
    return new Floor(buildingId);
  }

  public updateFloor(floor: Floor): Promise<{}> {
    return this.floorResource.update(floor.id, floor).then((item) => {
      this.newUserService.refreshBuilding(floor.buildingId);
      return item;
    });
  }

  public addFloor(floor: Floor): Promise<SavedEntity<Floor, number>> {
    return this.floorResource.add(floor).then((item) => {
      this.newUserService.refreshBuilding(floor.buildingId);
      return item;
    });
  }

  public deleteFloor(floor: Floor): Promise<{}> {
    return this.floorResource.delete(floor.id).then((item) => {
      this.newUserService.refreshBuilding(floor.buildingId);
      return item;
    });
  }
}
