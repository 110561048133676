import { Injectable } from '@angular/core';
import { EmergencyLightingTestResource, IEmergencyTest } from '@app/shared/resources/emergency-lighting-test.resource';
import { Observable } from 'rxjs';
import { EmDriverDTO } from '@app/shared/models/em-driver';
import { EmergencyLightingTestType } from '@app/shared/models/emergency-lighting-test-type';
import { EmergencyLightingTestState } from '@app/shared/models/emergency-lighting-test-state';

@Injectable({
  providedIn: 'root'
})
export class EmDriverService {
  constructor(private readonly emergencyLightingTestResource: EmergencyLightingTestResource) {}
  getEmergencyLightingTestResultsForDrivers(buildingId: number, drivers: number[]): Observable<IEmergencyTest[]> {
    return this.emergencyLightingTestResource.getTestsForBuildingAndDrivers(buildingId, drivers);
  }

  cancelEmergencyLightingTest(buildingId: number, emDriver: EmDriverDTO): Observable<{}> {
    return this.emergencyLightingTestResource.cancelTest(buildingId, emDriver.id);
  }

  cancelEmergencyLightingTestBatch(buildingId: number, driverIds: number[]): Observable<{}> {
    return this.emergencyLightingTestResource.cancelBatchTest(buildingId, driverIds);
  }

  startEmergencyLightingTest(
    buildingId: number,
    emDriver: EmDriverDTO,
    type: EmergencyLightingTestType
  ): Observable<{}> {
    return this.emergencyLightingTestResource.startTest(buildingId, emDriver.id, type);
  }

  startEmergencyLightingTestBatch(
    buildingId: number,
    driverIds: number[],
    type: EmergencyLightingTestType
  ): Observable<{}> {
    return this.emergencyLightingTestResource.startBatchTest(buildingId, driverIds, type);
  }

  /**
   * Based on the 2 latest results for a given EM inverter, one for Function test and one for Duration
   * get the result which shows the current valid state of the inverter that should be shown on UI
   * Any failure = Failed, Timed out, Cancelled
   * ------------------------------------------------------------
   * |[FT: Any failure, DT: Passed] => Show the failed state    |
   * |[FT: Passed, DT: Any failure] => Show the failed state    |
   * |[FT: Passed, DT: Passed] => Show as Passed                |
   * |[FT: In progress, DT: Any status] => Show as In progress  |
   * |[FT: Any status, DT: In progress] => Show as In progress  |
   * |[FT: Any failure, DT: In progress] => Show as In progress |
   * ------------------------------------------------------------
   * @param results - Results grouped by type (One function test and one duration test)
   * @returns The latest test which is valid and to be shown on UI
   */
  getValidLatestResult(results: [IEmergencyTest, IEmergencyTest] | [IEmergencyTest] | []): IEmergencyTest | null {
    if (results.length === 0) return null;
    if (results.length === 1) return results[0];
    const statusesString = results[0].state.toString() + '|' + results[1].state.toString();
    switch (statusesString) {
      // FAILED takes precedence over other failure states
      case 'FAILED|SUCCEEDED':
      case 'FAILED|TIMED_OUT':
      case 'FAILED|CANCELLED':
      // IN_PROGRESS/INITIATED take precedence over other states
      case 'IN_PROGRESS|TIMED_OUT':
      case 'IN_PROGRESS|CANCELLED':
      case 'IN_PROGRESS|FAILED':
      case 'IN_PROGRESS|SUCCEEDED':
      case 'INITIATED|TIMED_OUT':
      case 'INITIATED|CANCELLED':
      case 'INITIATED|FAILED':
      case 'INITIATED|SUCCEEDED':
      // TIMED_OUT takes precedence over CANCELLED and SUCCEEDED
      case 'TIMED_OUT|CANCELLED':
      case 'TIMED_OUT|SUCCEEDED':
      // CANCELLED takes precedence over SUCCEEDED
      case 'CANCELLED|SUCCEEDED':
        return results[0];
      // FAILED takes precedence over other failure states
      case 'SUCCEEDED|FAILED':
      case 'TIMED_OUT|FAILED':
      case 'CANCELLED|FAILED':
      // IN_PROGRESS/INITIATED take precedence over other states
      case 'TIMED_OUT|IN_PROGRESS':
      case 'CANCELLED|IN_PROGRESS':
      case 'FAILED|IN_PROGRESS':
      case 'SUCCEEDED|IN_PROGRESS':
      case 'TIMED_OUT|INITIATED':
      case 'CANCELLED|INITIATED':
      case 'FAILED|INITIATED':
      case 'SUCCEEDED|INITIATED':
      // TIMED_OUT takes precedence over CANCELLED and SUCCEEDED
      case 'CANCELLED|TIMED_OUT':
      case 'SUCCEEDED|TIMED_OUT':
      // CANCELLED takes precedence over SUCCEEDED
      case 'SUCCEEDED|CANCELLED':
        return results[1];
      default:
        return results[0];
    }
  }
}
