import { IEmergencyLightingTestResultResource } from '../../api/resources/IEmergencyLightingTestResultResource';
import { PromiseRejectionHandler } from '../../promises/PromiseRejectionHandler';
import { PromiseUtils } from '../promises/PromiseUtils';
import { Resource } from '../Resource';
import { EmergencyLightingTest } from '../../api/building/EmergencyLightingTest';
import { EmergencyLightingTestResult } from '../../api/building/EmergencyLightingTestResult';

const Deferred = function () {
  this.promise = new Promise(
    function (resolve, reject) {
      this.resolve = resolve;
      this.reject = reject;
    }.bind(this)
  );
  this.then = this.promise.then.bind(this.promise);
  this.catch = this.promise.catch.bind(this.promise);
};

export class EmergencyLightingTestResultResource
  extends Resource<EmergencyLightingTest, number>
  implements IEmergencyLightingTestResultResource
{
  constructor(
    $http: ng.IHttpService,
    baseUrl: string,
    rejectionHandler: PromiseRejectionHandler
  ) {
    super($http, baseUrl, rejectionHandler);
  }

  private latestResultsCanceller;

  public getResultsByFloor(
    buildingId: number,
    floorId: number,
    outline: ReportQueryOutline
  ): Promise<EmergencyLightingTestResult[]> {
    return PromiseUtils.wrapSingleAndTransform(
      this.$http.post(
        `${this.baseUrl}/${buildingId}/by-floor/${floorId}`,
        outline
      ),
      this.rejectionHandler,
      EmergencyLightingTestResult.cloneArray
    );
  }

  public cancelLatestResults(): void {
    this.latestResultsCanceller?.resolve();
  }

  public getLatestResultsByFloor(
    floorId: number,
    showArchived: boolean
  ): Promise<EmergencyLightingTest[]> {
    this.cancelLatestResults();
    this.latestResultsCanceller = new Deferred();
    const config = {
      params: {
        showArchived
      },
      timeout: this.latestResultsCanceller.promise
    };
    return PromiseUtils.wrapSingleAndTransform(
      this.$http.get(this.baseUrl + '/latest-by-floor/' + floorId, config),
      this.rejectionHandler,
      EmergencyLightingTest.cloneArray
    );
  }
}

export class ReportQueryOutline {
  constructor(
    public groupIds: number[],
    public start: number,
    public end: number,
    public showArchived: boolean,
    public showManualTests: boolean,
    public showFunctionalTests: boolean,
    public showDurationTests: boolean,
    public showQueuedTests: boolean,
    public showCancelledTests: boolean
  ) {}
}
