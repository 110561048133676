import { Inject, Injectable } from '@angular/core';
import { Resource } from '@app/shared/resources/resource';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Environment, environmentToken } from '@environment';
import { NodePoint, UnmappedNode } from '@app/shared/models/unmapped-nodes-datasource';
import { IResource } from '@app/shared/resources/resource.interface';
import { Observable } from 'rxjs';
import { SelectableNode, SensorNodeDTO } from '@app/shared/models/sensor-node';
import { NodeErrorMessage } from '@services/mapping.service';
import { SensorNodeIdsBatch } from '@app/shared/models/sensor-node-batch.interface';
import { VirtualNotification } from '@app/shared/models/virtual-notification-dto.interface';

@Injectable({
  providedIn: 'root'
})
export class MappingResource extends Resource<UnmappedNode, number> implements IResource<UnmappedNode, number> {
  constructor(protected httpClient: HttpClient, @Inject(environmentToken) environment: Environment) {
    super(httpClient, `${environment.apiUrl + environment.apiPath}/map`);
  }

  getUnmappedNodesList(buildingId: number): Observable<UnmappedNode[]> {
    return this.httpClient.get<UnmappedNode[]>(`${this.baseUrl}/building/${buildingId}/unmapped-nodes`);
  }

  mapGenericNode(target: NodePoint, floorId: number): Observable<SensorNodeDTO> {
    return this.httpClient.post<SensorNodeDTO>(`${this.baseUrl}/generic/building/${target.buildingId}`, null, {
      params: new HttpParams()
        .set('nodeId', target.nodeId.toString())
        .set('floorId', floorId.toString())
        .set('x', target.dropPoint.x.toString())
        .set('y', target.dropPoint.y.toString())
    });
  }

  map(virtualNotification: VirtualNotification): Observable<void> {
    return this.httpClient.post<void>(`${this.baseUrl}/virtual`, virtualNotification);
  }

  unmapGenericNode(target: SelectableNode): Observable<NodeErrorMessage[]> {
    return this.httpClient.delete<NodeErrorMessage[]>(`${this.baseUrl}/generic`, {
      body: { nodeIds: [target.id] }
    });
  }

  unmap(sensorNodeIdsBatch: SensorNodeIdsBatch): Observable<{}> {
    return this.httpClient.delete<{}>(`${this.baseUrl}`, {
      body: sensorNodeIdsBatch
    });
  }
}
