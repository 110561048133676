import { OrInlineFloorsController } from './OrInlineFloorsController';

export class OrInlineFloorsDirective {
  constructor() {
    return {
      bindToController: true,
      controller: ['$scope', OrInlineFloorsController],
      controllerAs: 'inlineFloors',
      replace: true,
      restrict: 'E',
      require: 'ngModel',
      scope: {
        isEditable: '=',
        onChange: '&',
        options: '=',
        selection: '=ngModel'
      },
      template: require('raw-loader!./or-inline-floors.html').default
    };
  }
}
