import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    EmergencyCalendarResource,
    IEmergencyCalendarEvent, IEmergencyScheduleRun
} from '@app/shared/resources/emergency-calendar-event.resource';

@Injectable({
    providedIn: 'root'
})
export class EmergencyCalendarService {

    constructor(private emCalendarResource: EmergencyCalendarResource) { }

    getScheduleRunsForBuildingBetween(buildingIds: number[], start: number, end: number): Observable<IEmergencyScheduleRun[]> {
        return this.emCalendarResource.getScheduledRunsForBuildingBetween(buildingIds, start, end);
    }

    getDistinctBuildingIdsWithSchedules(): Observable<number[]> {
        return this.emCalendarResource.getDistinctBuildingIdsWithSchedules();
    }
}
