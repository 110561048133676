import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  public siteMenuActive$ = new BehaviorSubject<boolean>(false);
  public userMenuActive$ = new BehaviorSubject<boolean>(false);
  public floorsMenuActive$ = new BehaviorSubject<boolean>(false);
  public buildingsMenuActive$ = new BehaviorSubject<boolean>(false);
  public sessionMenuActive$ = new BehaviorSubject<boolean>(false);

  showSiteMenu(): void {
    this.siteMenuActive$.next(true);
  }

  hideSiteMenu(): void {
    this.siteMenuActive$.next(false);
  }

  showUserMenu(): void {
    this.userMenuActive$.next(true);
  }

  hideUserMenu(): void {
    this.userMenuActive$.next(false);
  }

  showFloorsMenu(): void {
    this.floorsMenuActive$.next(true);
  }

  hideFloorsMenu(): void {
    this.floorsMenuActive$.next(false);
  }

  showBuildingsMenu(): void {
    this.buildingsMenuActive$.next(true);
  }

  hideBuildingsMenu(): void {
    this.buildingsMenuActive$.next(false);
  }

  showSessionMenu(): void {
    this.sessionMenuActive$.next(true);
  }

  hideSessionMenu(): void {
    this.sessionMenuActive$.next(false);
  }
}
