<div id="orGlobalAdministrationEmergencyLightingCalendar">
  <mat-grid-list cols="3" rowHeight="5:1">
    <mat-grid-tile>
      <mat-button-toggle-group name="calendarView" aria-label="Calendar View">
        <mat-button-toggle mwlCalendarPreviousView
                           [view]="view"
                           [(viewDate)]="viewDate"
                           (viewDateChange)='viewDateChanged($event)'
        >
          Previous
        </mat-button-toggle>
        <mat-button-toggle mwlCalendarToday [(viewDate)]="viewDate" [class.mat-button-toggle-checked]="isToday" (viewDateChange)='viewDateChanged($event)'>Today</mat-button-toggle>
        <mat-button-toggle mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)='viewDateChanged($event)'>Next</mat-button-toggle>
      </mat-button-toggle-group>
    </mat-grid-tile>
    <mat-grid-tile>
      <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
    </mat-grid-tile>
    <mat-grid-tile>
      <mat-button-toggle-group name="calendarView" aria-label="Calendar View">
        <mat-button-toggle (click)="setView(CalendarView.Month)" [class.mat-button-toggle-checked]="view === CalendarView.Month">Month</mat-button-toggle>
        <mat-button-toggle (click)="setView(CalendarView.Week)" [class.mat-button-toggle-checked]="view === CalendarView.Week">Week</mat-button-toggle>
        <mat-button-toggle (click)="setView(CalendarView.Day)" [class.mat-button-toggle-checked]="view === CalendarView.Day">Day</mat-button-toggle>
      </mat-button-toggle-group>
    </mat-grid-tile>
  </mat-grid-list>
  <br />
  <div [ngSwitch]="view">
    <mwl-calendar-month-view
      *ngSwitchCase="CalendarView.Month"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      [activeDayIsOpen]="activeDayIsOpen"
      (dayClicked)="dayClicked($event.day)"
      (eventClicked)="handleEvent($event.event)"
    >
    </mwl-calendar-month-view>
    <mwl-calendar-week-view
      *ngSwitchCase="CalendarView.Week"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      (eventClicked)="handleEvent($event.event)"
    >
    </mwl-calendar-week-view>
    <mwl-calendar-day-view
      *ngSwitchCase="CalendarView.Day"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      (eventClicked)="handleEvent($event.event)"
    >
    </mwl-calendar-day-view>
  </div>
</div>
