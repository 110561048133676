<div>
  <form [formGroup]='gatewayForm' class='or-form or-form-gateway-details' name='gatewayForm' novalidate='novalidate'>
    <div class='or-form-body' *ngIf='gateways'>
      <ol class='or-form-items' *ngFor='let gateway of gateways; let i = index'>
        <li class='or-form-item or-form-item-status or-form-gateway-details-keys or-gateways'
            *ngIf='!gateway.gatewayHealth?.health && gateway.gatewayHealth?.health !== false'>
          <span class='or-button or-status-icon or-iconic or-primary or-unknown'
                title='The health of this gateway is currently unknown.' data-test='gateway-details-health'></span>
        </li>
        <li class='or-form-item or-form-item-status or-form-gateway-details-keys or-gateways'
            *ngIf='gateway.gatewayHealth?.health === true'>
          <span class='or-button or-status-icon or-iconic or-primary or-positive'
                title='AWS IoT, LE Mesh and Internet connections are good.'
                data-test='form-gateway-details-health'></span>
        </li>
        <li class='or-form-item or-form-item-status or-form-gateway-details-keys or-gateways'
            *ngIf='gateway.gatewayHealth?.health === false'>
          <span class='or-button or-status-icon or-iconic or-primary or-negative'
                title='Some error message.' data-test='form-gateway-details-health'></span>
        </li>

        <li class='or-form-item or-form-gateway-details-name or-gateways'>
          <input class='or-input' type='text' maxlength='256' value='{{gateway.serialNumber}}'
                 name='serialNumber{{i}}'
                 placeholder='Serial Number'
                 disabled
                 data-test='otap-gateway-details-serial-number' />
        </li>

        <li class='or-form-item or-form-gateway-details-name or-gateways'>
          <input class='or-input' type='text' maxlength='256' value='{{gateway.address}}'
                 name='address{{i}}'
                 placeholder='Address'
                 disabled
                 data-test='otap-gateway-details-address' />
        </li>

        <li class='or-form-item or-gateways'>
          <button class='or-button or-iconic or-primary or-negative' type='button' (click)='onDelete(gateway)'
                  data-cy='delete-gateway-{{gateway.id}}}'
                  title='Delete gateway' data-test='form-gateway-details-delete'>
            <span class='or-icon or-tiny or-inverse or-icon-trash'></span>
          </button>
        </li>

        <li class='or-form-item or-gateways'>
          <button class='or-button or-iconic ' type='button' (click)='onRebootGateway(gateway)'
                  data-cy='reboot-gateway-{{gateway.id}}}'
                  title='Reboot gateway' data-test='form-gateway-details'>
            <span class='or-icon or-small or-icon-reload'></span>
          </button>
        </li>

        <ol class='or-gateway-metrics-items'>
          <li>
            <label class=' or-label or-gateway-metrics-item'
                   *ngIf='gateway.gatewayMetric !== undefined && gateway.gatewayMetric.cpuUsage !== undefined'>
              CPU: {{Math.round(gateway.gatewayMetric.cpuUsage)}}%
            </label>

            <label class=' or-label or-gateway-metrics-item'
                   *ngIf='gateway.gatewayMetric !== undefined && gateway.gatewayMetric.diskUsage !== undefined'>
              Disk: {{Math.round(gateway.gatewayMetric.diskUsage)}}%
            </label>

            <label class=' or-label or-gateway-metrics-item'
                   *ngIf=' gateway.gatewayMetric !== undefined && gateway.gatewayMetric.memoryUsage!== undefined'>
              Memory: {{Math.round(gateway.gatewayMetric.memoryUsage)}}%
            </label>
            <label class=' or-label or-gateway-metrics-item'
                   *ngIf=' gateway.sensorNodeCount !== undefined '>
              Sensor nodes : {{gateway.sensorNodeCount}}
            </label>
          </li>
        </ol>
      </ol>

      <ol class='or-form-items otap-gateway-details-input'>
        <div class='or-form-header'>
          <h2 class='or-form-title'>New Gateway</h2>
        </div>
        <li class='or-form-item or-form-gateway-details-name'>
          <input class='or-input' type='text' maxlength='256'
                 formControlName='newSerialNumber'
                 placeholder='Serial Number'
                 required
                 name='newSerialNumber'
                 data-test='otap-form-gateway-details-serial-number' />

        </li>
        <li class='or-form-item'>
          <button class='or-button or-iconic or-primary or-positive' type='button'
                  data-cy='add-gateway-to-job-{{job.id}}}'
                  (click)='onAddGateway()' title='Add gateway' [disabled]='!gatewayForm.valid'
                  data-test='form-gateway-details-add'>
            <span class='or-icon or-small or-inverse or-icon-plus'></span>
          </button>
        </li>
      </ol>
      <div class='otap-gateway-details-buttons'>
        <button
          mat-raised-button
          color='primary'
          type='submit'
          class=' otap-gateway-details-button'
          data-cy='reboot-all-gateways'
          (click)='onRebootAllGateways()'
          [disabled]='gateways.length === 0'
        >
          <span class='or-icon or-small or-inverse or-icon-reload'></span>
          <span class='or-button-label'>Reboot All Gateways</span>
        </button>
        <button
          mat-raised-button
          color='primary'
          type='submit'
          class='submit-btn otap-gateway-details-button'
          data-cy='export-otap-process'
          (click)='onExportOtapProcess()'
          [disabled]='gateways.length === 0 || exportInProgress'>
          <span *ngIf='!exportInProgress' class='or-icon or-small or-inverse or-icon-report'></span>
          <span class='or-button-label'>
          {{ exportInProgress ? 'Loading...' : 'Export' }}
          </span>
        </button>
        <button
          *ngIf='job.status === "STARTED"'
          mat-raised-button
          color='warn'
          type='submit'
          class='submit-btn otap-gateway-details-button'
          data-cy='export-otap-process'
          (click)='onStopJob()'
        >
          <span class='or-icon or-small or-inverse or-icon-deactivate'></span>
          <span class='or-button-label'>Stop</span>
        </button>
        <button
          *ngIf='job.status === "ENDED"'
          mat-raised-button
          color='accent'
          type='submit'
          class='submit-btn otap-gateway-details-button'
          data-cy='export-otap-process'
          (click)='onReopenJob()'
        >
          <span class='or-icon or-small or-inverse or-icon-deactivate'></span>
          <span class='or-button-label'>Reopen</span>
        </button>

        <button
          *ngIf='job.status === "STARTED"'
          mat-raised-button
          color='accent'
          type='submit'
          class='submit-btn otap-gateway-details-button button-alignment-right'
          data-cy='manually-enable-start-otap-process'
          (click)='onManuallyEnableStartButtons()'
          [disabled]='gateways.length === 0'
        >
          <span class='or-icon or-small or-inverse'></span>
          <span class='or-button-label'>Manually enable start buttons</span>
        </button>
      </div>
    </div>
  </form>
</div>
