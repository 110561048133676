import { Tab } from '@app/shared/components/tabs/tab.interface';
import { BuildingAuthorityType } from '@app/shared/models/building-authority-type';
import { SecurityUtils } from '@app/shared/utils/security.utils';

export const emergencyLightingTabs: Tab[] = [
  {
    name: 'Schedules',
    path: '/buildings/{buildingId}/emergency-lighting/schedules',
    dataCy: 'emergency-lighting-schedules-tab',
    isAuthorized: SecurityUtils.hasBuildingAuthority(BuildingAuthorityType.MANAGE_EMERGENCY_LIGHTING)
  },
  {
    name: 'Reports',
    path: '/buildings/{buildingId}/emergency-lighting/reports',
    dataCy: 'emergency-lighting-reports-tab',
    isAuthorized: SecurityUtils.hasBuildingAuthority(BuildingAuthorityType.MANAGE_EMERGENCY_LIGHTING)
  },
  {
    name: 'Manual Tests',
    path: '/buildings/{buildingId}/emergency-lighting/{floorId}/manual-tests',
    dataCy: 'emergency-lighting-manual-tests-tab',
    isAuthorized: SecurityUtils.hasBuildingAuthority(BuildingAuthorityType.MANAGE_EMERGENCY_LIGHTING)
  },
  {
    name: 'Email Schedules',
    path: '/buildings/{buildingId}/emergency-lighting/email-schedules',
    dataCy: 'emergency-lighting-email-schedules-tab',
    isAuthorized: SecurityUtils.hasBuildingAuthority(BuildingAuthorityType.MANAGE_EMERGENCY_LIGHTING)
  }
];
