import { Unit } from './Unit';

export class RuleType {
  constructor(
    public value: RuleType_,
    public name: string,
    public unit?: Unit
  ) {}

  public static MAXLIGHT = new RuleType(
    'MAXLIGHT',
    'Max Light',
    Unit.LIGHT_LEVEL
  );
  public static MINLIGHT = new RuleType(
    'MINLIGHT',
    'Min Light',
    Unit.LIGHT_LEVEL
  );
  public static LOWLIGHT = new RuleType(
    'LOWLIGHT',
    'Low Light',
    Unit.LIGHT_LEVEL
  );
  public static DWELL = new RuleType('DWELL', 'Dwell Time', Unit.DWELL_LENGTH);
  public static LOWLIGHT_TIME = new RuleType(
    'LOWLIGHT_TIME',
    'Low Light Time',
    Unit.DWELL_LENGTH
  );
  public static PERSONALITY = new RuleType(
    'PERSONALITY',
    'Personality',
    Unit.PERSONALITY_TYPE
  );
  public static SCENE = new RuleType('SCENE', 'Scene', Unit.SCENE_TYPE);

  public static all() {
    return [
      RuleType.MAXLIGHT,
      RuleType.MINLIGHT,
      RuleType.LOWLIGHT,
      RuleType.DWELL,
      RuleType.LOWLIGHT_TIME,
      RuleType.PERSONALITY,
      RuleType.SCENE
    ];
  }

  public static fromValue(value: string): RuleType {
    if (value === this.MAXLIGHT.value) {
      return this.MAXLIGHT;
    } else if (value === this.MINLIGHT.value) {
      return this.MINLIGHT;
    } else if (value === this.LOWLIGHT.value) {
      return this.LOWLIGHT;
    } else if (value === this.DWELL.value) {
      return this.DWELL;
    } else if (value === this.LOWLIGHT_TIME.value) {
      return this.LOWLIGHT_TIME;
    } else if (value === this.PERSONALITY.value) {
      return this.PERSONALITY;
    } else if (value === this.SCENE.value) {
      return this.SCENE;
    }
    return null;
  }

  public toString() {
    return this.value;
  }
}

type RuleType_ =
  | 'PERSONALITY'
  | 'SCENE'
  | 'MAXLIGHT'
  | 'MINLIGHT'
  | 'LOWLIGHT'
  | 'DWELL'
  | 'LOWLIGHT_TIME';
