<div class="tenant-dialog">
  <h1 mat-dialog-title>Add User To Tenant</h1>
  <div mat-dialog-content>
    <form [formGroup]="tenantUserForm" autocomplete="off" data-cy="user-tenant-add-form">
      <mat-form-field>
        <mat-select [value]='this.userId' [disabled]='this.mode === 0' id="userId" placeholder='Select User' (selectionChange)='selectUserId($event)' data-cy="user-tenant-user-id">
          <mat-option *ngFor="let user of users$ | async" [value]="user.id" [attr.data-cy]="'userId-option-' + user.id">
            {{ user.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-select [value]='this.tenantId' id="tenantId" placeholder='Select Tenant' (selectionChange)='selectTenantId($event)' data-cy="user-tenant-tenant-id">
          <mat-option *ngFor="let tenant of tenants$ | async" [value]="tenant.id" [attr.data-cy]="'tenantId-option-' + tenant.id">
            {{ tenant.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button (click)="cancel()" data-cy="cancel-tenant">Close</button>
    <button mat-raised-button color='primary' (click)="this.mode === 1 ? this.add() : this.edit()" data-cy="save-tenant" [disabled]="!isAddButtonEnabled">Save</button>
  </div>
</div>
