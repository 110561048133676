import { Injectable, InjectionToken } from '@angular/core';
import * as moment from 'moment';
import * as tz from 'moment-timezone';
import { Dates } from '@app/analytics/metric-widget/query/outline/Dates';
import { TimeScale } from '@app/shared/time/time-scale';
import { Resolution } from '@app/analytics/metric-widget/data-objects/resolution';
import { createAllScales } from '@app/shared/time/time-navigator-scales';
import Moment = moment.Moment;

@Injectable({
  providedIn: 'root'
})
export class TimeNavigatorService {
  private scaleIndex: number;
  private scales = createAllScales();

  constructor() {
    this.reset();
  }

  public resetTo(now?: Moment): void {
    this.scaleIndex = 0;
    this.scales.forEach((scale, i) => {
      scale.reset();
      if (scale.isUsedToInitialize && now != null) {
        scale.setSingleValue(now);
        this.scaleIndex = i;
      }
    });
  }

  public reset(zoneId?: string): void {
    this.resetTo(zoneId != null ? tz.tz(zoneId) : moment());
  }

  public getScale(): TimeScale {
    return this.scales[this.scaleIndex];
  }

  private getNextScale(): TimeScale {
    return this.scales[this.scaleIndex + 1];
  }

  public canZoom(amount: number): boolean {
    return (
      this.scaleIndex + amount >= 0 &&
      this.scaleIndex + amount < this.scales.length - 1 &&
      this.scales[this.scaleIndex + amount].hasValues()
    );
  }

  public zoom(amount: number): void {
    if (!this.canZoom(amount)) {
      return;
    }

    this.scaleIndex += amount;
  }

  public canShift(amount?: number): boolean {
    return this.isSingleMoment(amount ?? this.scaleIndex);
  }

  public shift(amount: number): void {
    if (!this.canShift(amount)) {
      return;
    }

    const time = this.getSingleMoment();
    this.getScale().shiftMoment(time, amount);
    this.setSingleMoment(time);
  }

  public canSelect(): boolean {
    return this.scaleIndex < this.scales.length - 1;
  }

  public select(blocks: number[]): void {
    if (!this.canSelect()) {
      return;
    }

    this.getNextScale().select(blocks);
  }

  public isSingleMoment(endIndex?: number): boolean {
    const scales = endIndex != null ? this.scales.slice(0, endIndex + 1) : this.scales;
    return scales.every((scale) => !scale.hasValues() || scale.isSingleValue());
  }

  public getSingleMoment(): Moment {
    const time = moment();
    this.scales.filter((scale) => scale.isSingleValue()).forEach((scale) => scale.updateMoment(time));
    return time;
  }

  public setSingleMoment(time: Moment): void {
    this.scales.filter((scale) => scale.isSingleValue()).forEach((scale) => scale.setSingleValue(time));
  }

  public setDates(dates: Dates): void {
    this.scales.forEach((scale) => scale.fromDates(dates));
  }

  public getDates(): Dates {
    const dates = new Dates();
    this.scales.slice(0, this.scales.length).forEach((scale) => scale.toDates(dates));
    return dates;
  }

  public getResolution(): Resolution {
    return this.getScale().resolution;
  }

  public setResolution(resolution: Resolution): void {
    if (resolution != null) {
      this.scaleIndex = this.scales.map((scale) => scale.resolution).indexOf(resolution);
    }
  }

  public getPointLabel(value: number): string {
    return this.getScale().getPointLabel(value);
  }

  public isActive(value: number): boolean {
    return this.getNextScale().isActive(value);
  }

  public getLabel(): string {
    if (this.isSingleMoment(this.scaleIndex)) {
      return this.getSingleMoment().format(this.getScale().longFormat);
    }

    return this.getScale().getLabel(this.getSingleMoment());
  }

  public addScale(scale: TimeScale): void {
    this.scales.push(scale);
  }

  public toString(): string {
    return this.scales.map((scale) => scale.resolution.toString() + ': ' + scale.getValues().join(', ')).join(' ');
  }
}
