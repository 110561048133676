import { OrLightingConfigurationController } from './OrLightingConfigurationController';

export class OrLightingConfigurationDirective {
  constructor() {
    return {
      bindToController: true,
      controller: [
        'SensorNodeService',
        'LightingConfigurationService',
        'OrPanelService',
        'QueryContext',
        'BuildingService',
        '$scope',
        OrLightingConfigurationController
      ],
      controllerAs: 'lightingConfiguration',
      replace: true,
      restrict: 'E',
      scope: {
        floorId: '='
      },
      document,
      template: require('raw-loader!./or-lighting-configuration.html').default
    };
  }
}
