import { Injectable } from '@angular/core';
import { MappingResource } from '@app/shared/resources/mapping.resource';
import { forkJoin, Observable } from 'rxjs';
import { NodePoint, UnmappedNode } from '@app/shared/models/unmapped-nodes-datasource';
import { SelectableNode, SensorNodeDTO } from '@app/shared/models/sensor-node';
import { IdsBatch } from '@app/shared/models/sensor-node-batch.interface';
import { finalize } from 'rxjs/operators';
import { VirtualNotification } from '@app/shared/models/virtual-notification-dto.interface';
import { DuplicateMappingResource } from '@app/shared/resources/duplicate-mapping.resource';

export interface NodeErrorMessage {
  nodeId: number;
  message: string;
}

@Injectable({
  providedIn: 'root'
})
export class MappingService {
  private static readonly MAPPING_ID_COUNT = 32;
  private mappingId = 0;
  private postMappingActivity: () => void;
  // various flags to indicate that mapping activity is in progress
  private activityInProgress = false;
  private activityWaiting = false;
  private mappingTried = false;
  private postActivityInProgress = false;
  constructor(
    private readonly mappingResource: MappingResource,
    private readonly duplicateMappingResource: DuplicateMappingResource
  ) {}

  private getAudio(i: number): HTMLAudioElement {
    const audio = new Audio();
    audio.src = `/assets/static/mapping/${i}.wav`;
    return audio;
  }

  private playAudio(i: number, retries = 5, timeBetweenRetries = 100): Observable<void> {
    return new Observable<void>((observer) => {
      const attemptPlayback = (attempt: number) => {
        const audio = this.getAudio(i);
        if (!audio) {
          observer.error(`Audio not loaded: ${i}`);
          return;
        }
        audio.onended = () => {
          if (attempt < retries) {
            setTimeout(() => attemptPlayback(attempt + 1), timeBetweenRetries);
          } else {
            observer.complete();
          }
        };
        audio.oncanplaythrough = () => {
          audio.play().catch(() => observer.error(`Could not play audio: ${i}`));
        };
        audio.load();
      };
      attemptPlayback(1);
    });
  }

  private updateMappingId(): void {
    this.mappingId = (this.mappingId + 1) % MappingService.MAPPING_ID_COUNT;
  }

  get isActivityInProgress(): boolean {
    return this.activityInProgress;
  }

  get isActivityWaiting(): boolean {
    return this.activityWaiting;
  }

  get wasMappingTried(): boolean {
    return this.mappingTried;
  }

  get isPostActivityInProgress(): boolean {
    return this.postActivityInProgress;
  }

  setMappingTried(value: boolean): void {
    this.mappingTried = value;
  }

  setPostActivityInProgress(val: boolean): void {
    this.postActivityInProgress = val;
  }

  setActivityWaiting(value: boolean): void {
    this.activityWaiting = value;
  }

  setPostMappingActivity(callback: () => void): void {
    this.postMappingActivity = callback;
  }

  clearMappingFlags(): void {
    this.activityInProgress = false;
    this.activityWaiting = false;
    this.mappingTried = false;
    this.postActivityInProgress = false;
    this.mappingId = 0;
  }

  doMapping(nodeId: number, buildingId: number): Observable<[void, void]> {
    this.activityInProgress = true;
    return forkJoin([
      this.playAudio(this.mappingId),
      this.mappingResource.map(new VirtualNotification(nodeId, this.mappingId, buildingId))
    ]).pipe(
      finalize(() => {
        this.activityInProgress = false;
        this.updateMappingId();
        this.postMappingActivity?.();
      })
    );
  }

  getUnmappedNodesList(buildingId: number): Observable<UnmappedNode[]> {
    return this.mappingResource.getUnmappedNodesList(buildingId);
  }

  mapGenericNode(target: NodePoint, floorId: number): Observable<SensorNodeDTO> {
    return this.mappingResource.mapGenericNode(target, floorId);
  }

  unmapGenericNode(target: SelectableNode): Observable<NodeErrorMessage[]> {
    return this.mappingResource.unmapGenericNode(target);
  }

  unmapGenericNodes(sensorNodeIdsBatch: IdsBatch): Observable<{}> {
    return this.mappingResource.unmap(sensorNodeIdsBatch);
  }
}
