import { OrScheduleSummaryController } from './OrScheduleSummaryController';

export class OrScheduleSummaryDirective {
  constructor() {
    return {
      bindToController: true,
      controller: ['$scope', 'ScheduleService', '$timeout', OrScheduleSummaryController],
      controllerAs: 'scheduleSummary',
      replace: true,
      restrict: 'E',
      scope: {
        details: '=?',
        onMoveUp: '&',
        onMoveDown: '&',
        schedulesList: '=',
        isNew: '=?',
        onSaveNewSchedule: '&?',
        onDeleteSchedule: '&?',
        onClose: '&?'
      },
      template: require('raw-loader!./or-schedule-summary.html').default
    };
  }
}
