<form #gatewayForm="ngForm" class="or-form or-form-gateway-details" name="gatewayForm"
  novalidate="novalidate">
  <div class="or-form-body" *ngIf="gateway">
    <ol class="or-form-items">

      <li class="or-form-item or-form-item-status or-form-gateway-details-keys"
          *ngIf="!isNew && !gatewayHealth">
        <span class="or-button or-status-icon or-iconic or-primary or-unknown"
              title="The health of this gateway is currently unknown." data-test="form-gateway-details-health"></span>
      </li>

      <li class="or-form-item or-form-item-status or-form-gateway-details-keys"
        *ngIf="!isNew && gatewayHealth && gatewayHealth?.awsIotHealthy && gatewayHealth?.meshHealthy && gatewayHealth?.internetHealthy">
        <span class="or-button or-status-icon or-iconic or-primary or-positive"
          title="AWS IoT, LE Mesh and Internet connections are good." data-test="form-gateway-details-health"></span>
      </li>

      <li class="or-form-item or-form-item-status or-form-gateway-details-keys"
        *ngIf="!isNew && gatewayHealth && gatewayHealth?.awsIotHealthy && !gatewayHealth?.meshHealthy && gatewayHealth?.internetHealthy">
        <span class="or-button or-status-icon or-iconic or-primary or-mild-warning"
          title="AWS IoT and Internet connections are good. LE Mesh - no data." data-test="form-gateway-details-health"></span>
      </li>

      <li class="or-form-item or-form-item-status or-form-gateway-details-keys"
          *ngIf="!isNew && gatewayHealth && !gatewayHealth?.awsIotHealthy && gatewayHealth?.meshHealthy && gatewayHealth?.internetHealthy">
      <span class="or-button or-status-icon or-iconic or-primary or-mild-warning"
            title="LE Mesh and Internet connections are good. AWS IoT is down." data-test="form-gateway-details-health"></span>
      </li>

      <li class="or-form-item or-form-item-status or-form-gateway-details-keys"
          *ngIf="!isNew && gatewayHealth && gatewayHealth?.awsIotHealthy && gatewayHealth?.meshHealthy && !gatewayHealth?.internetHealthy">
      <span class="or-button or-status-icon or-iconic or-primary or-negative"
            title="AWS IoT and LE Mesh are good. Internet connection is down." data-test="form-gateway-details-health"></span>
      </li>

      <li class="or-form-item or-form-item-status or-form-gateway-details-keys"
        *ngIf="!isNew && gatewayHealth && !gatewayHealth?.awsIotHealthy && !gatewayHealth?.meshHealthy && gatewayHealth?.internetHealthy">
        <span class="or-button or-status-icon or-iconic or-primary or-warning"
          title="Internet connection is good. But AWS IoT and LE Mesh connections are down." data-test="form-gateway-details-health"></span>
      </li>

      <li class="or-form-item or-form-item-status or-form-gateway-details-keys"
          *ngIf="!isNew && gatewayHealth && gatewayHealth?.awsIotHealthy && !gatewayHealth?.meshHealthy && !gatewayHealth?.internetHealthy">
        <span class="or-button or-status-icon or-iconic or-primary or-negative"
              title="AWS IoT is good. LE Mesh and Internet connections are down." data-test="form-gateway-details-health"></span>
      </li>

      <li class="or-form-item or-form-item-status or-form-gateway-details-keys"
          *ngIf="!isNew && gatewayHealth && !gatewayHealth?.awsIotHealthy && gatewayHealth?.meshHealthy && !gatewayHealth?.internetHealthy">
        <span class="or-button or-status-icon or-iconic or-primary or-negative"
              title="LE Mesh is good. AWS IoT and Internet connections are down." data-test="form-gateway-details-health"></span>
      </li>

      <li class="or-form-item or-form-item-status or-form-gateway-details-keys"
        *ngIf="!isNew && gatewayHealth && !gatewayHealth?.awsIotHealthy && !gatewayHealth?.meshHealthy && !gatewayHealth?.internetHealthy">
        <span class="or-button or-status-icon or-iconic or-primary or-negative"
          title="AWS IoT, LE Mesh and Internet connections are down." data-test="form-gateway-details-health"></span>
      </li>

      <li class="or-form-item or-form-gateway-details-name">
        <input class="or-input" type="text" maxlength="256" [(ngModel)]="gateway.name" [disabled]="isBusy" placeholder="Name"
          [pattern]="namePattern" required name="name" data-test="form-gateway-details-name"/>
      </li>
      <li class="or-form-item or-form-gateway-details-network-type">
        <select class="or-select" *ngIf="gateway.networkType === wirepas.value" [(ngModel)]="gateway.gatewayType" [disabled]="!isNew || isBusy"
                required="required" name="gatewayType" title="Gateway Version" data-test="form-gateway-details-gateway-type">
          <option disabled [selected]="true" [value]="undefined">Gateway Type</option>
          <option *ngFor="let gatewayType of getAllGatewayTypes()" [value]="gatewayType"
                  [selected]="gatewayType.name === gateway.gatewayType">{{gatewayType.name}}</option>
        </select>
      </li>
      <li class="or-form-item or-form-gateway-details-name">
        <input class="or-input" type="text" maxlength="256" [(ngModel)]="gateway.serialNumber"
          [disabled]="!isNew || isBusy"
          placeholder="Serial Number" [pattern]="serialNumberPattern" required name="serialNumber" data-test="form-gateway-details-serial-number"/>
      </li>
      <li class="or-form-item or-form-gateway-details-name">
        <input class="or-input" type="text" maxlength="256" [(ngModel)]="gateway.address"
          [placeholder]="this.isNew && this.gateway.gatewayType !== this.vesta.value ? 'Address will be looked up via Edge Direct' : 'Gateway Address'"
          (ngModelChange)="onAddressChange()" [pattern]="pattern"
          [required]="gateway.gatewayType === vesta.value" [disabled]="gateway.gatewayType !== vesta.value"
          name="address" data-test="form-gateway-details-address"/>
      </li>
      <li class="or-form-item" appIsAuthorized="MANAGE_AWS_CREDENTIALS" *ngIf="!isNew || gateway.networkType !== wirepas.value">
        <button class="or-button or-iconic" type="button" (click)="isViewEdit = !isViewEdit; resetHiddenFields()"
                [disabled]="isBusy"
          title="View/Edit" data-test="form-gateway-details-view-edit">
          <span class="or-icon or-small or-icon-edit"></span>
        </button>
      </li>

      <li class="or-form-item" *ngIf="!isNew && gatewayForm.pristine">
        <button class="or-button or-iconic or-primary or-negative" type="button" (click)="onDelete()"
          [disabled]="isBusy" title="Delete gateway" data-test="form-gateway-details-delete">
          <span class="or-icon or-tiny or-inverse or-icon-trash"></span>
        </button>
      </li>
      <li class="or-form-item or-form-gateway-details-keys" *ngIf="!isNew && gatewayForm.dirty">
        <button class="or-button or-iconic or-primary or-positive" type="button" (click)="onUpdateGateway()"
          title="Update" [disabled]="isBusy || gatewayForm.invalid || gatewayForm.pristine"
          data-test="form-gateway-details-update">
          <span class="or-icon or-small or-inverse or-icon-checkmark"></span>
        </button>
      </li>
      <li class="or-form-item or-form-gateway-details-keys" *ngIf="!isNew && this.gateway.gatewayType !== this.vesta.value">
        <button class="or-button or-iconic" type="button" (click)="onCompareGatewayStates()" title="Configure"
                appIsAuthorized="MANAGE_AWS_CREDENTIALS"
                [disabled]="isBusy || gatewayForm.invalid"
                data-test="form-gateway-details-compare">
          <span class="or-icon or-small or-inverse or-icon-gateway-config"></span>
        </button>
      </li>
      <li class="or-form-item" *ngIf="isNew">
        <button class="or-button or-iconic or-primary or-positive" type="button" (click)="onAddGateway()"
          title="Add gateway" [disabled]="isBusy || gatewayForm.invalid || gatewayForm.pristine"
          data-test="form-gateway-details-add">

          <span class="or-icon or-small or-inverse or-icon-plus"></span>
        </button>
      </li>
      <li class="or-form-item" *ngIf="isNew || (!isNew && gatewayForm.dirty)">
        <button class="or-button or-iconic" type="button" (click)="isNew? onResetGateway() : gatewayForm.resetForm(gateway.original);"
          title="Reset changes" data-test="form-gateway-details-reset">
          <span class="or-icon or-small or-icon-reload"></span>
        </button>
      </li>
      <li class="or-form-item" *ngIf="!isNew && !hasKeys()">
        <button class="or-button or-iconic" type="button" (click)="onGenerateKeys()" [disabled]="isBusy"
          title="Generate Keys" data-test="form-gateway-details-keys">
          <span class="or-icon or-small or-inverse or-icon-key"></span>
        </button>
      </li>

      <ol class="or-form-items" appIsAuthorized="MANAGE_AWS_CREDENTIALS"
        *ngIf="(isViewEdit || isNew) && (gateway.networkType === wirepas.value)">

        <li class="or-form-item or-form-gateway-details-network-type">
          <select class="or-select" [disabled]="isBusy || gateway.networkType === wirepas.value" [(ngModel)]="gateway.networkType" required name="networkType" title="Network Type"
            data-test="form-gateway-details-network-type">
            <option disabled [selected]="true" [value]="undefined">Network Type</option>
            <option *ngFor="let type of getAllNetworkTypes()" [value]="type.value"
              [selected]="type.value === gateway.networkType">{{ type.name }}</option>
          </select>
        </li>

        <li class="or-form-item or-form-gateway-details-network-type">
          <select class="or-select" *ngIf="gateway.networkType === wirepas.value" [(ngModel)]="gateway.firmwareVersion" [disabled]="isBusy"
            required="required" name="firmwareVersion" title="Firmware Version" data-test="form-gateway-details-firmware-version">
            <option disabled [selected]="true" [value]="undefined">Firmware Version</option>
            <option *ngFor="let firmwareVersion of getAllFirmwareVersions()" [value]="firmwareVersion"
              [selected]="firmwareVersion.name === gateway.firmwareVersion">{{firmwareVersion.name}}</option>
          </select>
        </li>

        <li class="or-form-item" *ngIf="gateway.networkType === wirepas.value">
          <app-checkbox [id]='"default-network-" + gateway.id' [disabled]="isBusy" [(ngModel)]="gateway.isDefaultNetwork" name="isDefaultNetwork" (valueChange)='updateToDefault(["staticIpAddress", "gatewayIpAddress", "subnetMask"])'
            data-test="form-gateway-details-default-network">
          </app-checkbox>

          <label [for]='"default-network-" + gateway.id' class="or-label or-label-right">Default/Auto Network</label>
        </li>
        <li class="or-form-item or-form-gateway-details-ip-address"
          *ngIf="gateway.networkType === wirepas.value && !gateway.isDefaultNetwork">
          <input class="or-input" type="text" [(ngModel)]="gateway.staticIpAddress" [disabled]="isBusy" placeholder="IP Address"
            [pattern]="ipAddressPattern" [required]="gateway.networkType === wirepas.value && !gateway.isDefaultNetwork"
            name="staticIpAddress" data-test="form-gateway-details-static-ip"/>
        </li>
        <li class="or-form-item or-form-gateway-details-ip-address"
          *ngIf="gateway.networkType === wirepas.value && !gateway.isDefaultNetwork">
          <input class="or-input" type="text" [(ngModel)]="gateway.gatewayIpAddress" [disabled]="isBusy" placeholder="Router IP Address"
            [pattern]="ipAddressPattern" [required]="gateway.networkType === wirepas.value && !gateway.isDefaultNetwork"
            name="gatewayIpAddress" data-test="form-gateway-details-gateway-ip"/>
        </li>
        <li class="or-form-item or-form-gateway-details-ip-address"
          *ngIf="gateway.networkType === wirepas.value && !gateway.isDefaultNetwork">
          <input class="or-input" type="text" [(ngModel)]="gateway.subnetMask" [disabled]="isBusy" placeholder="Subnet Mask"
            [pattern]="ipAddressPattern" [required]="gateway.networkType === wirepas.value && !gateway.isDefaultNetwork"
            name="subnetMask" data-test="form-gateway-details-subnet"/>
        </li>

        <li class="or-form-item" *ngIf="gateway.networkType === wirepas.value && !gateway.isDefaultNetwork">
          <app-checkbox [id]='"default-dns-" + gateway.id' [disabled]="isBusy" [(ngModel)]="gateway.isDefaultDns" name="isDefaultDns" (valueChange)='updateToDefault(["dnsServers"])'
            data-test="form-gateway-details-default-dns"></app-checkbox>
          <label [for]='"default-dns-" + gateway.id' class="or-label or-label-right">Default DNS</label>
        </li>
        <li class="or-form-item or-form-gateway-details-ip-address"
          *ngIf="gateway.networkType === wirepas.value && !gateway.isDefaultNetwork && !gateway.isDefaultDns">
          <input class="or-input" type="text" [(ngModel)]="gateway.dnsServers" [disabled]="isBusy" placeholder="DNS Servers"
            [pattern]="dnsServersPattern"
            [required]="gateway.networkType === wirepas.value && !gateway.isDefaultNetwork && !gateway.isDefaultDns"
            name="dnsServers" data-test="form-gateway-details-dns-servers"/>
        </li>
      </ol>
      <ol class="or-form-items" appIsAuthorized="MANAGE_AWS_CREDENTIALS"
        *ngIf="(isViewEdit || isNew) && (gateway.networkType === wirepas.value && gateway.gatewayType !== vesta.value)">
        <li class="or-form-item">
          <app-checkbox [id]='"iot-" + gateway.id' [(ngModel)]="gateway.isAwsIotEnabled" [disabled]="isBusy" name="isAwsIotEnabled" (valueChange)='updateToDefault(["iotLogLevel"])'
            data-test="form-gateway-details-iot-enabled"></app-checkbox>
          <label [for]='"iot-" + gateway.id' class="or-label or-label-right">IoT</label>
        </li>
        <li class="or-form-item or-form-gateway-details-log" *ngIf="gateway.isAwsIotEnabled">
          <select class="or-select" [(ngModel)]="gateway.iotLogLevel" [disabled]="isBusy" required name="iotLogLevel" data-test="form-gateway-details-iot-log-level">
            <option disabled [selected]="true" [value]="undefined">IOT Log Level</option>
            <option *ngFor="let logLevel of getAllLogLevels()" [value]="logLevel"
              [selected]="logLevel.name === gateway.iotLogLevel">{{logLevel.name}}</option>
          </select>
        </li>
        <li class="or-form-item">
          <app-checkbox [id]='"otap-" + gateway.id' [(ngModel)]="gateway.isOtapEnabled" [disabled]="isBusy" name="isOtapEnabled" (valueChange)='updateToDefault(["otapLogLevel"])'
            data-test="form-gateway-details-otap-enabled"></app-checkbox>
          <label [for]='"otap-" + gateway.id' class="or-label or-label-right">OTAP</label>
        </li>
        <li class="or-form-item or-form-gateway-details-log" *ngIf="gateway.isOtapEnabled">
          <select class="or-select" [(ngModel)]="gateway.otapLogLevel" [disabled]="isBusy" required name="otapLogLevel" data-test="form-gateway-details-otap-log-level">
            <option disabled [selected]="true" [value]="undefined">Otap Log Level</option>
            <option *ngFor="let logLevel of getAllLogLevels()" [value]="logLevel"
              [selected]="logLevel.name === gateway.otapLogLevel">{{logLevel.name}}</option>
          </select>
        </li>
        <li class="or-form-item">
          <app-checkbox [id]='"wnt-" + gateway.id' [(ngModel)]="gateway.isWntEnabled" [disabled]="isBusy" name="isWntEnabled"></app-checkbox>
          <label [for]='"wnt-" + gateway.id' class="or-label or-label-right">WNT</label>
        </li>
        <li class="or-form-item">
          <app-checkbox [id]='"bacnet-" + gateway.id' [(ngModel)]="gateway.isBacnetEnabled" [disabled]="isBusy" name="isBacnetEnabled" (valueChange)='resetBacnetFields()'
            data-test="form-gateway-details-bacnet-enabled"></app-checkbox>
          <label [for]='"bacnet-" + gateway.id' class="or-label or-label-right">BACnet</label>
        </li>
        <li class="or-form-item or-form-gateway-details-log" *ngIf="gateway.isBacnetEnabled">
          <select class="or-select" [(ngModel)]="gateway.bacnetLogLevel" [disabled]="isBusy" required name="bacnetLogLevel" data-test="form-gateway-details-bacnet-log-level">
            <option disabled [selected]="true" [value]="undefined">Bacnet Log Level</option>
            <option *ngFor="let logLevel of getAllLogLevels()" [value]="logLevel"
              [selected]="logLevel.name === gateway.bacnetLogLevel">{{logLevel.name}}</option>
          </select>
        </li>
        <li class="or-form-item" *ngIf="gateway.isBacnetEnabled">
          <input class="or-input" type="text" [(ngModel)]="gateway.bacnetDeviceId" [disabled]="isBusy" placeholder="BACnet Device ID"
            [required]='gateway.networkType === wirepas.value' name="bacnetDeviceId" data-test="form-gateway-details-bacnet-device"/>
        </li>
        <li class="or-form-item" *ngIf="gateway.isBacnetEnabled">
          <input class="or-input" type="text" [(ngModel)]="gateway.bacnetPort" [disabled]="isBusy" placeholder="BACnet Port"
            [required]='gateway.networkType === wirepas.value' name="bacnetPort" data-test="form-gateway-details-bacnet-port"/>
        </li>
      </ol>

      <li class="or-form-item or-form-gateway-details-version"
          (change)='onOptionsSelected($event)' appIsAuthorized="MANAGE_AWS_CREDENTIALS" >
        <select class="or-select" *ngIf="isViewEdit || isNew"  type="number" [(ngModel)]="gateway.versionNumber" [disabled]="isBusy"
                required="required" name="versionNumber" title="Gateway Version"  data-test="form-gateway-details-version-number">
          <option disabled [selected]="true" [value]="undefined">Gateway Version</option>
          <option *ngFor="let gatewayVersion of getAllGatewayVersions()" [value]="gatewayVersion.value"
                  [selected]="gatewayVersion.value === gateway.versionNumber">{{gatewayVersion.value}}</option>
        </select>
      </li>

      <li class="or-form-item" appIsAuthorized="VIEW_HIDDEN_COMPONENTS" *ngIf="!isNew">
        <button class="or-button or-iconic" type="button" (click)="onRestartGateway()" [disabled]="isBusy"
          title="Restart gateway" data-test="form-gateway-details-restart">
          <span class="or-icon or-small or-icon-plug"></span>
        </button>
      </li>
      <li class="or-form-item" appIsAuthorized="VIEW_HIDDEN_COMPONENTS" *ngIf="!isNew">
        <span>Last restart:</span><span [ngModel]="gateway.lastRestart" ngDefaultControl name='lastRestart' data-test="form-gateway-details-last-restart"></span>
      </li>
    </ol>
  </div>
</form>
