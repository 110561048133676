export class Weekday {
    constructor(
        public value: Weekday_ | string,
        public fullName: string
    ) {}

    public static SUNDAY = new Weekday('Sun', 'Sunday');
    public static MONDAY = new Weekday('Mon', 'Monday');
    public static TUESDAY = new Weekday('Tue', 'Tuesday');
    public static WEDNESDAY = new Weekday('Wed', 'Wednesday');
    public static THURSDAY = new Weekday('Thu', 'Thursday');
    public static FRIDAY = new Weekday('Fri', 'Friday');
    public static SATURDAY = new Weekday('Sat', 'Saturday');

    public static getAll(): Weekday[] {
        return [
          this.SUNDAY,
          this.MONDAY,
          this.TUESDAY,
          this.WEDNESDAY,
          this.THURSDAY,
          this.FRIDAY,
          this.SATURDAY
        ];
    }

    public getLabel(isShortLabel: boolean): string {
        if (!isShortLabel) {
            return this.value;
        } else {
            return this.value.slice(0, 3);
        }
    }

    public static fromValue(value: string): Weekday {
        return this.getAll().find((value1, index) => {
            if (value1.value === value) {
                return value1;
            }
        });
    }
}

type Weekday_ = 'Sun' | 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri' | 'Sat'
