import { AbstractControl } from '@angular/forms';

export function IpValidator(control: AbstractControl): any {
  const ipv4Regex: RegExp =
    /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/\d{1,2})?$/;
  const ipv6Regex: RegExp = /^([\da-fA-F]{1,4}:){7}[\da-fA-F]{1,4}(\/\d{1,3})?$/;
  if (control.value) {
    const ipValues: string[] = control.value.split(',').map((ip) => ip.trim());
    for (const val of ipValues) {
      if (!(ipv4Regex.test(val) || ipv6Regex.test(val))) {
        return { invalidIp: true };
      }
    }
  }
  return null;
}
