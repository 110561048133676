import { TokenUser, TokenUserSerialised } from '../api/building/TokenUser';
import { ISimpleService } from '../api/ISimpleService';
import { SavedEntity } from '../api/SavedEntity';
import { TokenResource } from "@angularjs/or/angular/resources/TokenResource";

export class TokenService {
  public tokens: TokenUser[];

  private endpoints = {
    token: '/api/v1/token'
  };

  constructor(
    private tokenResource: TokenResource,
                private simpleService: ISimpleService<Tokens, SavedEntity<TokenUser, number>, void, void>,
                private generateApiUrl: (endpoint: string) => string
  ) {}

  public getAllTokensForBuilding(buildingId: number, shouldIncludeDeactivated = false): Promise<TokenUser[]> {
    return this.tokenResource.getAllTokensForBuilding(this.generateApiUrl(`${this.endpoints.token}/${buildingId}`), shouldIncludeDeactivated);
  }

  public getToken(tokenId: number): Promise<TokenUser> {
    return this.tokenResource.retrieve(tokenId);
  }

  public deleteToken(tokenId): Promise<{}> {
    return this.tokenResource.delete(tokenId);
  }

  public addToken(
    buildingId: number,
    tokenDetails: TokenUser
  ): Promise<SavedEntity<TokenUser, number>> {
    return this.simpleService.post(
      this.generateApiUrl(`${this.endpoints.token}/create/${buildingId}`),
      new TokenUserSerialised(tokenDetails)
    );
  }

  public updateToken(buildingId: number, tokenDetails: TokenUser) {
    return this.simpleService.put(
      this.generateApiUrl(
        `${this.endpoints.token}/${buildingId}/${tokenDetails.id}`
      ),
      new TokenUserSerialised(tokenDetails)
    );
  }

  public deactivateToken(buildingId: number, tokenId: number) {
    return this.simpleService.put(
      this.generateApiUrl(
        `${this.endpoints.token}/deactivate/${buildingId}/${tokenId}`
      )
    );
  }
}

export class Tokens {
  constructor(public token: TokenUser[]) {}
}
