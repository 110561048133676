export interface Selectable {
  id?: number;
  selected?: boolean;
  expanded?: boolean;
  x: number;
  y: number;
  discriminator: DISCRIMINATOR;
}

export enum DISCRIMINATOR {
  SN3 = 'SN3',
  EM_DRIVER = 'EM_DRIVER',
  LUMINAIRE = 'LUMINAIRE',
  PN = 'PN',
  HIM84 = 'HIM84'
}
