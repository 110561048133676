<div id="orAdministrationTenantUsers" class="administration-tenants-users">
  <div class="tile medium">
    <div class="tile-header mat-elevation-z8">
      <h2 style="font-size: 15px;">Tenant Users</h2>
    </div>
    <table
      aria-label="List of Tenants"
      mat-table
      class="tenants-table mat-elevation-z8"
      [dataSource]='tenantUserDatasource'>
      <!-- User Name Column -->
      <ng-container matColumnDef="userName">
        <th mat-header-cell *matHeaderCellDef> User Name </th>
        <td mat-cell data-cy="user-name" *matCellDef="let tenantUser">{{tenantUser.userName}}</td>
      </ng-container>
      <!-- Tenant Name -->
      <ng-container matColumnDef="tenantName">
        <th mat-header-cell *matHeaderCellDef> Tenant Name </th>
        <td mat-cell data-cy="tenant-name" class="name-cell" *matCellDef="let tenantUser">{{tenantUser.tenantName}}</td>
      </ng-container>
      <!-- Actions -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Actions </th>
        <td mat-cell class="actions-cell" *matCellDef="let tenantUser">
          <button data-cy="edit-tenant-button"
                  class="action-button edit"
                  mat-mini-fab (click)="doEdit(tenantUser)"
                  matTooltip="Edit users Tenant"
                  matTooltipClass="mat-tooltip"
                  matTooltipPosition="above"
                  aria-label="Button to edit tenant details">
            <mat-icon>edit</mat-icon>
          </button>
          <button data-cy="delete-tenant-user-button"
                  class="action-button delete"
                  mat-mini-fab color="warn"
                  (click)="doDelete(tenantUser)"
                  matTooltip="Delete this user, tenant relationship"
                  matTooltipClass="mat-tooltip"
                  matTooltipPosition="above"
                  aria-label="Button to delete user-tenant relationship">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="tenantDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: tenantDisplayedColumns"></tr>
    </table>
    <mat-card class="mat-spinner spinner-container mat-elevation-z8" data-cy="tenants-user-spinner-icon" *ngIf="tenantUserDatasource?.loading$ | async">
      <mat-spinner></mat-spinner>
    </mat-card>
    <mat-paginator *ngIf='tenantUserDatasource'
                   class="mat-elevation-z8"
                   [length]="tenantUserDatasource.counter$ | async"
                   [pageSizeOptions]="[tenantsPageSize]"
                   [pageSize]="tenantsPageSize"
                   (page)='tenantUserDatasource.loadUserTenants($event.pageIndex, $event.pageSize)'
                   showFirstLastButtons
                   aria-label="Select page for Users">
    </mat-paginator>
  </div>
</div>
<div class="add-user-tenant-button">
  <button
    matTooltip="Add a tenant to this building"
    matTooltipClass="mat-tooltip font-15"
    matTooltipPosition="above"
    aria-label="Button to add tenants to this building"
    data-cy="add-user-tenant"
    mat-fab
    (click)="doAdd()"
    class="mat-elevation-z8 button-alignment"
    color="primary">
    <mat-icon>add</mat-icon>
  </button>
</div>
