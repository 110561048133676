import { OrPanelService } from '../or-panel-service/OrPanelService';
import { CopyToClipboardService } from '@angularjs/or/angular/services/CopyToClipboardService';

export class OrPanelToggleController {
  public isActive: boolean;
  public panelId: string;
  public isPrintable: boolean;
  public timezone: string;

  constructor(private orPanelService: OrPanelService, private copyToClipboardService: CopyToClipboardService) {}

  toggle() {
    this.orPanelService.togglePanel(this.panelId);
    this.isActive = this.orPanelService.getPanel(this.panelId).isActive;
  }

  copyToClipboard() {
    const data = this.orPanelService.copyToClipboard(this.panelId);

    if (data) {
      this.copyToClipboardService.copy(data);
      alert('Copied data to your clipboard! Paste into a spreadsheet to import the data.');
    }
  }
}
