import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SecurityService } from '@services/security.service';
import { IsAuthorizedForBuildingDirective } from './is-authorized-for-building.directive';
import { IsAuthorizedDirective } from './is-authorized.directive';
import { IsFeatureAvailableDirective } from './is-feature-available.directive';

@NgModule({
  declarations: [IsAuthorizedDirective, IsAuthorizedForBuildingDirective, IsFeatureAvailableDirective],
  imports: [CommonModule],
  exports: [IsAuthorizedDirective, IsAuthorizedForBuildingDirective, IsFeatureAvailableDirective],
  providers: [SecurityService]
})
export class AuthorizationModule {}
