import { SensorNodeService } from '@angularjs/or/services/SensorNodeService';
import { BeaconSettingService } from '@angularjs/or/services/BeaconSettingService';
import { IObservableModifiable } from '@angularjs/or/util/IObservable';
import { ITagContext } from '@angularjs/or/api/query/outline/context/IContext';
import { OrPanelService } from '../or-panel/or-panel-service/OrPanelService';
import { BeaconSetting } from '@angularjs/or/api/building/BeaconSetting';
import { IBuildingService } from '@angularjs/or/services/IBuildingService';

export class OrBeaconSettingsController {
  public status = false;
  private originalStatus: boolean;

  public powerLevel: string;
  public indexedPower = 0;
  private originalIndexedPower: number;
  private powers: number[] = [-40, -30, -20, -16, -12, -8, -4];

  public content;
  public indexedContent = 0;
  public contents = [];
  private originalContent;

  public interval;
  public indexedInterval = 0;
  public intervals = [];
  private originalIndexedInterval = 0;

  private uuids = [];
  public uuid;
  private originalUuid;

  public major;
  private originalMajor;

  public minor;
  private originalMinor;

  public form;
  public isBusy: boolean;
  public isDisabled: boolean;
  public selectedNodes;
  private buildingId: number;
  private floorId: number;
  private isReady: boolean;

  get MINOR_GENERATE(): number {
    return -1;
  }

  constructor(
    private nodeService: SensorNodeService,
    private beaconSettingService: BeaconSettingService,
    private buildingService: IBuildingService,
    private orPanelService: OrPanelService,
    private metricContext: IObservableModifiable<ITagContext>,
    private $scope: ng.IScope
  ) {
    this.nodeService.updateBeaconSettingPanel = (update: BeaconSetting): void => {
      this.updatePanel(update);
    };
  }

  public $onInit(): void {
    this.buildingService.getCurrentBuilding().then((building) => {
      this.$scope.$apply(() => {
        this.buildingId = building.id;
      });
    });

    this.isBusy = false;
    this.originalStatus = this.status;
    this.originalIndexedPower = this.indexedPower;
    this.originalUuid = this.uuid;
    BeaconSetting.contents.forEach((item) => {
      const obj = {
        name: item.name,
        value: item.value
      };
      this.contents.push(obj);
    });

    BeaconSetting.intervals.forEach((item) => {
      const obj = {
        name: item.name,
        value: item.value
      };
      this.intervals.push(obj);
    });

    BeaconSetting.uuids.forEach((item) => {
      const obj = {
        name: item.name,
        value: item.value
      };
      this.uuids.push(obj);
    });

    this.content = this.contents[0];
    this.originalContent = this.content;
    this.originalUuid = this.uuid;
    this.originalMajor = this.major;
    this.originalMinor = this.minor;
    this.selectedNodes = this.nodeService.selectedNodes.value();
    this.convert();
    this.isDisabled = this.isPanelDisabled();
  }

  updatePanel(update: BeaconSetting): void {
    this.status = false;
    this.indexedPower = 0;
    this.indexedInterval = 0;
    this.indexedContent = 0;

    this.content = null;
    this.uuid = null;
    this.major = null;
    this.minor = null;
    this.convert();

    if (update != null) {
      if (update.enabled != null) {
        this.status = update.enabled;
      }
      if (update.powerLevel != null) {
        this.indexedPower = this.powers.indexOf(update.powerLevel);
        this.convert();
      }
      if (update.content != null) {
        let myContent = BeaconSetting.contents.find((item) => item.value === update.content);
        if (!myContent) {
          myContent = BeaconSetting.contents[0];
        }
        this.content = myContent;
      }
      if (update.beaconInterval != null) {
        this.intervals.forEach((item, i) => {
          if (item.value === update.beaconInterval) {
            this.indexedInterval = i;
          }
        });
      }
      if (update.uuid != null) {
        let myUuid = BeaconSetting.uuids.find((item) => item.value === update.uuid);
        if (!myUuid) {
          myUuid = BeaconSetting.uuids[0];
        }
        this.uuid = myUuid;
      }
      if (update.major != null) {
        this.major = update.major;
      }
      if (update.minor != null) {
        this.minor = update.minor;
      }
    }
  }

  get selectedTags(): number[] {
    return this.metricContext.value().tagIds;
  }

  convert(): void {
    this.powerLevel = this.powers[this.indexedPower] + ' dBm';
    document.getElementById('dbm').setAttribute('style', 'left:' + (this.indexedPower * 35 - 10) + 'px;');
  }

  showMsg(msg: string): void {
    alert(msg + ' : ' + this.status);
  }

  get selectedSensorNodes(): any {
    return this.selectedNodes;
  }

  public activate(): void {
    this.isBusy = true;
    if (this.orPanelService.getPanel('beaconFloorplan').isActive === true) {
      this.beaconSettingService
        .activate(this.selectedSensorNodes, this.status, this.buildingId)
        .then(() => {
          this.$scope.$apply(() => {
            this.originalStatus = this.status;
            this.isBusy = false;
            alert('The nodes are now ' + (this.status === true ? 'Enabled ' : 'Disabled '));
          });
        })
        .catch(() => {
          this.isBusy = false;
          alert('Something went wrong!');
        });
    } else {
      this.beaconSettingService
        .activateByTag(this.selectedTags, this.status, this.buildingId, this.floorId)
        .then(() => {
          this.$scope.$apply(() => {
            this.originalStatus = this.status;
            this.isBusy = false;
            alert('The nodes are now ' + (this.status === true ? 'Enabled ' : 'Disabled '));
          });
        })
        .catch(() => {
          this.isBusy = false;
          alert('Something went wrong!');
        });
    }
  }

  public setPowerLevel(): void {
    this.isBusy = true;
    if (this.orPanelService.getPanel('beaconFloorplan').isActive === true) {
      this.beaconSettingService
        .updatePowerLevel(this.selectedSensorNodes, this.powers[this.indexedPower], this.buildingId)
        .then(() => {
          this.$scope.$apply(() => {
            this.originalIndexedPower = this.indexedPower;
            this.convert();
            this.isBusy = false;
            alert('The new power level is ' + this.powerLevel);
          });
        })
        .catch(() => {
          this.isBusy = false;
          alert('Something went wrong!');
        });
    } else {
      this.beaconSettingService
        .updatePowerLevelByTag(this.selectedTags, this.powers[this.indexedPower], this.buildingId, this.floorId)
        .then(() => {
          this.$scope.$apply(() => {
            this.originalIndexedPower = this.indexedPower;
            this.convert();
            this.isBusy = false;
            alert('The new power level is ' + this.powerLevel);
          });
        })
        .catch(() => {
          this.isBusy = false;
          alert('Something went wrong!');
        });
    }
  }

  public setContent(): void {
    this.isBusy = true;
    if (this.content) {
      if (this.orPanelService.getPanel('beaconFloorplan').isActive === true) {
        this.beaconSettingService
          .updateContent(this.selectedSensorNodes, this.content.value, this.buildingId)
          .then(() => {
            this.$scope.$apply(() => {
              this.originalContent = this.content;
              this.isBusy = false;
              alert('The beacon content has been set to ' + this.content.name);
            });
          })
          .catch(() => {
            this.isBusy = false;
            alert('Something went wrong!');
          });
      } else {
        this.beaconSettingService
          .updateContentByTag(this.selectedTags, this.content.value, this.buildingId, this.floorId)
          .then(() => {
            this.$scope.$apply(() => {
              this.originalContent = this.content;
              this.isBusy = false;
              alert('The beacon content has been set to ' + this.content.name);
            });
          })
          .catch(() => {
            this.isBusy = false;
            alert('Something went wrong!');
          });
      }
    }
  }

  public setInterval(): void {
    const interval = this.intervals[this.indexedInterval];
    if (interval.value === 100) {
      if (
        !confirm(
          'Repeating beacons at this rate may cause additional RF interference within the building. ' +
            'Continue anyway?'
        )
      ) {
        return;
      }
    }
    this.isBusy = true;
    if (this.orPanelService.getPanel('beaconFloorplan').isActive === true) {
      this.beaconSettingService
        .updateInterval(this.selectedSensorNodes, interval.value, this.buildingId)
        .then(() => {
          this.$scope.$apply(() => {
            this.originalIndexedInterval = this.indexedInterval;
            this.isBusy = false;
            alert('The beacon repetition has been set to ' + interval.name);
          });
        })
        .catch(() => {
          this.isBusy = false;
          alert('Something went wrong!');
        });
    } else {
      this.beaconSettingService
        .updateIntervalByTag(this.selectedTags, interval.value, this.buildingId, this.floorId)
        .then(() => {
          this.$scope.$apply(() => {
            this.originalIndexedInterval = this.indexedInterval;
            this.isBusy = false;
            alert('The beacon repetition has been set to ' + interval.name);
          });
        })
        .catch(() => {
          this.isBusy = false;
          alert('Something went wrong!');
        });
    }
  }

  public setUuid(): void {
    this.isBusy = true;
    if (this.orPanelService.getPanel('beaconFloorplan').isActive === true) {
      this.beaconSettingService
        .updateUuid(this.selectedSensorNodes, this.uuid.value, this.buildingId)
        .then(() => {
          this.$scope.$apply(() => {
            this.originalUuid = this.uuid;
            this.isBusy = false;
            alert('The beacon UUID has been set to ' + this.uuid.name);
          });
        })
        .catch(() => {
          this.isBusy = false;
          alert('Something went wrong!');
        });
    } else {
      this.beaconSettingService
        .updateUuidByTag(this.selectedTags, this.uuid.value, this.buildingId, this.floorId)
        .then(() => {
          this.$scope.$apply(() => {
            this.originalUuid = this.uuid;
            this.isBusy = false;
            alert('The beacon UUID has been set to ' + this.uuid.name);
          });
        })
        .catch(() => {
          this.isBusy = false;
          alert('Something went wrong!');
        });
    }
  }

  public setMajor(): void {
    this.isBusy = true;
    if (!this.major || this.major < 0 || this.major > 65534) {
      this.isBusy = false;
      alert('Major value should be between 0 to 65535');
    } else {
      if (this.orPanelService.getPanel('beaconFloorplan').isActive === true) {
        this.beaconSettingService
          .updateMajor(this.selectedSensorNodes, this.major, this.buildingId)
          .then(() => {
            this.$scope.$apply(() => {
              this.originalMajor = this.major;
              this.isBusy = false;
              alert('The beacon major has been set to ' + this.major);
            });
          })
          .catch(() => {
            this.isBusy = false;
            alert('Something went wrong!');
          });
      } else {
        this.beaconSettingService
          .updateMajorByTag(this.selectedTags, this.major, this.buildingId, this.floorId)
          .then(() => {
            this.$scope.$apply(() => {
              this.originalMajor = this.major;
              this.isBusy = false;
              alert('The beacon major has been set to ' + this.major);
            });
          })
          .catch(() => {
            this.isBusy = false;
            alert('Something went wrong!');
          });
      }
    }
  }

  public setMinor(): void {
    this.isBusy = true;
    if (this.orPanelService.getPanel('beaconFloorplan').isActive === true) {
      this.beaconSettingService
        .updateMinor(this.selectedSensorNodes, this.MINOR_GENERATE, this.buildingId)
        .then((beaconSettings) => {
          this.$scope.$apply(() => {
            this.isBusy = false;
            if (beaconSettings && beaconSettings.length === 1) {
              this.minor = beaconSettings[0].minor;
              this.originalMinor = beaconSettings[0].minor;
              alert('The beacon minor has been set to ' + this.minor);
            } else {
              alert('The beacon minor has been generated');
            }
          });
        })
        .catch(() => {
          this.isBusy = false;
          alert('Something went wrong!');
        });
    } else {
      this.beaconSettingService
        .updateMinorByTag(this.selectedTags, this.MINOR_GENERATE, this.buildingId, this.floorId)
        .then((beaconSettings) => {
          this.$scope.$apply(() => {
            this.isBusy = false;
            if (beaconSettings && beaconSettings.length === 1) {
              this.minor = beaconSettings[0].minor;
              this.originalMinor = beaconSettings[0].minor;
              alert('The beacon minor has been set to ' + this.minor);
            } else {
              alert('The beacon minor has been generated');
            }
          });
        })
        .catch(() => {
          this.isBusy = false;
          alert('Something went wrong!');
        });
    }
  }

  public resetStatus(): void {
    this.status = this.originalStatus;
  }

  public resetPower(): void {
    this.indexedPower = this.originalIndexedPower;
    this.convert();
  }

  public resetContent(): void {
    this.content = this.originalContent;
  }

  public resetInterval(): void {
    this.indexedInterval = this.originalIndexedInterval;
  }

  public isPanelDisabled(): boolean {
    return (
      (!this.isReady && this.orPanelService.getPanel('beaconFloorplan').isActive) ||
      (this.selectedTags.length === 0 && this.orPanelService.getPanel('beaconTags').isActive)
    );
  }

  public resetUuid(): void {
    this.uuid = this.originalUuid;
  }

  public resetMajor(): void {
    this.major = this.originalMajor;
  }

  public resetMinor(): void {
    this.minor = this.originalMinor;
  }
}
