import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Token } from '@app/shared/models/token.interface';
import { Tenant } from '@app/shared/models/tenant.interface';
import { TokenService } from '@services/token/token.service';
import { Building } from '@app/shared/models/building.interface';
import { IpValidator } from '@app/administration/tokens/ip.validator';
import { Observer } from 'rxjs';
import { TokenStatus } from '@app/shared/models/token-status';
import { ConfirmationDialogService } from '@services/confirmation-dialog/confirmation-dialog.service';
import { ToastService } from '@services/toast/toast.service';
import { Role } from '@app/shared/models/role.interface';
import { ConfirmDialogData } from '@components/dialogs/confirm/confirm.component';

@Component({
  selector: 'app-form-token-details',
  templateUrl: './form-token-details.component.html',
  styleUrls: ['./form-token-details.component.scss']
})
export class FormTokenDetailsComponent implements OnInit, OnChanges {
  @Input() token: Token;
  @Input() isNew = true;
  @Input() roles: Role[];
  @Input() tenants: Tenant[];
  @Input() building: Building;
  @Input() isBusy = false;
  @Input() isDeactivated = false;
  @Input() index: number;

  @Output() valueUpdated = new EventEmitter<void>();

  tokenForm: FormGroup;
  oldFormValue: any;

  constructor(
    private fb: FormBuilder,
    private tokenService: TokenService,
    private toast: ToastService,
    private dialogService: ConfirmationDialogService
  ) {}

  ngOnInit(): void {
    this.initForm(this.token);
  }

  /* added to remove retaining data from the form after creation of the token (refresh)*/
  ngOnChanges(changes: SimpleChanges): void {
    if (this.isNew) {
      this.initForm(this.token);
    }
  }

  initForm(token: Token): void {
    this.tokenForm = this.fb.group(
      {
        id: [token ? token.id : null],
        usableToken: [{ value: token ? `${token.id}-${token.token}` : 'WILL BE GENERATED AUTOMATICALLY', disabled: true }],
        roleIds: [
          {
            value: token && token.roles && token.roles.length > 0 ? token.roles.map((r) => r.id) : [],
            disabled: this.isBusy || token?.tokenStatus === TokenStatus.DEACTIVATED.toString()
          }
        ],
        tenantIds: [
          {
            value: token && token.tenantIds && token.tenantIds.length > 0 ? token.tenantIds[0] : '',
            disabled: this.isBusy || token?.tokenStatus === TokenStatus.DEACTIVATED.toString()
          }
        ],
        allowedIpAddresses: [
          {
            value: this.resolveIpAddressesToString(),
            disabled: this.isBusy || token?.tokenStatus === TokenStatus.DEACTIVATED.toString()
          },
          [IpValidator]
        ],
        description: [
          {
            value: token ? token.description : '',
            disabled: this.isBusy || token?.tokenStatus === TokenStatus.DEACTIVATED.toString()
          }
        ],
        tokenStatus: [token ? token.tokenStatus : '']
      },
      { validators: [] }
    );
  }

  resolveIpAddressesToString(): string {
    if (this.token && this.token.allowedIpAddresses) {
      return this.token.allowedIpAddresses.map((addressObj) => addressObj.ipAddress).join(', ');
    } else return '';
  }

  createToken(): void {
    const tokenRequest = this.mapFormValueToRequest();
    const validationMessages = this.validateRequest(tokenRequest);
    if (validationMessages.length === 0) {
      this.tokenService
        .create(this.building.id, tokenRequest)
        .subscribe(this.handleResponse('Successfully created the token.', 'Failed to create the token.'));
    } else {
      this.toast.error({
        message: validationMessages.join('<br>'),
        dataCy: `create-error-toast`
      });
    }
  }

  updateToken(): void {
    const tokenRequest = this.mapFormValueToRequest();
    const validationMessages = this.validateRequest(tokenRequest);
    if (validationMessages.length === 0) {
      this.tokenService
        .update(this.building.id, tokenRequest)
        .subscribe(this.handleResponse('Successfully updated the token.', 'Failed to update the token.'));
    } else {
      this.toast.error({
        message: validationMessages.join('<br>'),
        dataCy: `update-error-toast`
      });
    }
  }

  resetToken(): void {
    const data = new ConfirmDialogData(`Are you sure you want to reset the token?`);
    this.dialogService.open(data).subscribe((confirm: boolean) => {
      if (confirm) {
        this.initForm(this.token);
      }
    });
  }

  deactivateToken(): void {
    const data = new ConfirmDialogData(`Are you sure you want to deactivate the token: "${this.token.token}"? It can not be activated again.`);
    this.dialogService.open(data).subscribe((confirm: boolean) => {
      if (confirm) {
        const formValue = this.tokenForm.value;
        this.tokenService
          .deactivate(this.building.id, formValue.id)
          .subscribe(this.handleResponse('Successfully deactivated the token.', 'Failed to deactivate the token.'));
      }
    });
  }

  mapFormValueToRequest(): any {
    let formValue = JSON.parse(JSON.stringify(this.tokenForm.value));
    formValue = this.mapToRoleIds(formValue);
    formValue = this.mapToIpAddresses(formValue);
    formValue = this.mapToTenantIds(formValue);
    return formValue;
  }

  mapToRoleIds(formValue: any): any {
    formValue.roleIds = formValue?.roleIds ? formValue.roleIds : [];
    return formValue;
  }

  mapToIpAddresses(formValue: any): any {
    formValue.allowedIpAddresses =
      formValue && formValue.allowedIpAddresses ? formValue.allowedIpAddresses.split(',') : [];
    return formValue;
  }

  mapToTenantIds(formValue: any): any {
    formValue.tenantIds = formValue?.tenantIds ? [formValue.tenantIds] : [];
    return formValue;
  }

  handleResponse(successMsg: string, errMsg: string): Observer<any> {
    return {
      next: () => {
        this.valueUpdated.emit();
        this.toast.success({
          message: successMsg,
          dataCy: `send-success-toast`
        });
      },
      error: () => this.handleError(errMsg),
      complete: () => {}
    };
  }

  handleError(message: any): void {
    this.toast.error({
      message,
      dataCy: `send-error-toast`
    });
  }

  validateRequest(request: any): string[] {
    const messages = [];
    if (request) {
      if (request.roleIds.length === 0) {
        messages.push('Need to select at least one Permission');
      }
    } else {
      messages.push('Request does not have any data');
    }
    return messages;
  }
}
