export interface Gradient {
  colorStops(): [number, string][];
}

export class StretchingGradient implements Gradient {
  constructor(
    private underlying: Gradient,
    private min: number,
    private max: number = 1.0
  ) {}

  colorStops(): [number, string][] {
    const stops = this.underlying.colorStops();
    stops.sort((stop2, stop1) => stop2[0] - stop1[0]);
    const min = stops[0][0];
    const max = stops[stops.length - 1][0];
    return stops.map((stop) => {
      const step = (stop[0] - min) / (max - min);

      const val = [step * (this.max - this.min) + this.min, stop[1]] as [
        number,
        string
      ];

      return val;
    });
  }
}

export class BiasingGradient implements Gradient {
  constructor(private underlying: Gradient, private bias: number) {}

  colorStops(): [number, string][] {
    return this.underlying
      .colorStops()
      .map(
        (stop) =>
          [
            (1 - this.bias) * stop[0] + this.bias * Math.sqrt(stop[0]),
            stop[1]
          ] as [number, string]
      );
  }
}

export class HeatmapJsGradient implements Gradient {
  // This one looks better but is very cold
  public static PREVIOUS_GRADIENT = {
    0.53: '#0000FF',
    0.6: '#0060FC',
    0.69: '#06C7D8',
    0.77: '#77FF77',
    0.8: '#DBF41F',
    0.83: '#E8E012',
    0.86: '#EFB81F',
    0.88: '#E46A1F',
    0.91: '#E00300',
    0.97: '#440000',
    1.0: '#440000'
  };

  // Doesn't look as good but has more contrast
  public static DEFAULT_GRADIENT = {
    0.4: '#0000FF', // BLUE
    0.5: '#06C7D8', // TURQUOISE
    0.6: '#7EC93B', // GREEN
    0.7: '#E8E012', // YELLOW
    0.8: '#E00300', // RED
    0.97: '#440000', // CRIMSON
    1.0: '#440000'
  };

  constructor(private gradient: any) {}

  public colorStops() {
    const stops = [];
    for (const key in this.gradient) {
      if (this.gradient.hasOwnProperty(key)) {
        stops.push([parseFloat(key), this.gradient[key]]);
      }
    }
    return stops;
  }
}
