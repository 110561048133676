import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IOtapUpgradeGatewayDTO, OtapUpgradeGatewayDTO } from '@app/shared/models/otap-upgrade-gateway.inerface';
import { OtapUpgradeService } from '@services/otap-upgrade/otap.upgrade.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OtapUpgradeDTO } from '@app/shared/models/otap.upgrade.interface';

@Component({
  selector: 'app-gateway-details',
  templateUrl: './gateway-details.component.html',
  styleUrls: ['./gateway-details.component.scss']
})
export class GatewayDetailsComponent implements OnInit {
  @Input() gateways: OtapUpgradeGatewayDTO[];
  @Input() job: OtapUpgradeDTO;
  @Output() jobStartEnabled: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() exportCompleteEvent: EventEmitter<{ exportId: string; complete: boolean }> = new EventEmitter();
  @Input() set exportStatus(event: { exportId: string; complete: boolean }) {
    if (event && event.complete) {
      this.updateExportStatusAndDownload(event.exportId, event.complete);
    }
  }

  protected readonly Math = Math;
  public newSerialNumber: string;
  public serialNumberPattern = /^[\da-zA-Z-_\s]{16,256}$/;
  public gatewayForm: FormGroup;
  exportInProgress = false;
  exportComplete = false;
  exportId: string | null = null;

  constructor(private otapUpgradeService: OtapUpgradeService, private formBuilder: FormBuilder) {
    this.gatewayForm = this.formBuilder.group({
      newSerialNumber: ['', [Validators.required, Validators.pattern(this.serialNumberPattern)]]
    });
  }

  ngOnInit(): void {}

  public onAddGateway(): void {
    const newGateway: IOtapUpgradeGatewayDTO = {
      serialNumber: this.gatewayForm.value.newSerialNumber,
      jobId: this.job.id
    };

    this.otapUpgradeService.addGatewayToJob(this.job.id, newGateway).subscribe({
      next: (result) => {
        this.gateways.push(result);
        this.gatewayForm.value.newSerialNumber = null;
      },
      error: (err) => {
        alert('The gateway address you provided could not be added to this job. ' + err.error);
      }
    });
  }

  public onDelete(gateway: OtapUpgradeGatewayDTO): void {
    this.otapUpgradeService.deleteGatewayFromJob(gateway.jobId, gateway.id).subscribe({
      next: (response) => {
        this.gateways = this.gateways.filter((gw) => gw.id !== gateway.id);
      },
      error: (error) => {
        alert('The delete request failed');
        console.error('There was an error during the delete request', error);
      }
    });
  }

  public onRebootGateway(rebootGateway: OtapUpgradeGatewayDTO): void {
    this.otapUpgradeService.rebootGateway(this.job.id, rebootGateway.id).subscribe((result) => {
      if (result === true) {
        this.gateways.forEach((gateway) => {
          if (gateway.id === rebootGateway.id) {
            gateway.gatewayHealth.health = false;
          }
        });
      }
    });
  }

  public onRebootAllGateways(): void {
    this.otapUpgradeService.rebootAllGateways(this.job.id).subscribe((result) => {
      if (result === true) {
        this.gateways.forEach((gateway) => {
          gateway.gatewayHealth.health = false;
        });
      }
    });
  }

  public onStopJob(): void {
    this.otapUpgradeService.stopJob(this.job.id).subscribe((result) => {
      if (result !== null) {
        this.job = result;
      }
    });
  }

  public onReopenJob(): void {
    this.otapUpgradeService.reopenJob(this.job.id).subscribe((result) => {
      if (result !== null) {
        this.job = result;

        this.jobStartEnabled.emit(true);
      }
    });
  }

  public onExportOtapProcess(): void {
    this.exportInProgress = true;
    this.exportComplete = false;
    this.exportId = null;

    this.otapUpgradeService.startExport(this.job.id).subscribe((exportId) => {
      this.exportId = exportId;
      this.exportCompleteEvent.emit({ exportId, complete: false });
    });
  }

  public downloadExportedFile(): void {
    if (this.exportId) {
      this.otapUpgradeService.downloadExport(this.job.id, this.exportId).subscribe((data) => {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });

        const fileName = `Otap-${this.job.name.replace(' ', '-')}-${this.job.id}-${
          new Date().toISOString().split('.')[0]
        }Z.xlsx`;
        const a = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
      });
    }
  }

  updateExportStatusAndDownload(exportId: string, complete: boolean): void {
    this.exportId = exportId;
    this.exportInProgress = !complete;
    this.exportComplete = complete;

    this.downloadExportedFile();
  }

  public onManuallyEnableStartButtons(): void {
    this.jobStartEnabled.emit(true);
  }
}
