import { Resource } from '../Resource';
import { TokenUser } from '../../api/building/TokenUser';
import { IResource } from '../../api/IResource';
import {PromiseUtils} from "@angularjs/or/angular/promises/PromiseUtils";

export class TokenResource
  extends Resource<TokenUser, number>
  implements IResource<TokenUser, number> {
    public getAllTokensForBuilding(url: string, shouldIncludeDeactivated: boolean): Promise<TokenUser[]> {

        const params = {shouldIncludeDeactivated};
        return PromiseUtils.wrapSingle(this.$http.get(url, {params}), this.rejectionHandler);
    }
}
