import { OrCheckboxController } from './OrCheckboxController';

export class OrCheckboxDirective {
  constructor() {
    return {
      bindToController: true,
      controller: OrCheckboxController,
      controllerAs: 'checkbox',
      replace: true,
      restrict: 'E',
      require: 'ngModel',
      link: (scope, element, attrs, ngModelCtrl) => {
        scope.$watch('checkbox.isActive', () => ngModelCtrl.$setViewValue(scope.checkbox.isActive));
      },
      scope: {
        isActive: '=?ngModel',
        isDisabled: '=?',
        label: '@?',
        onChange: '&'
      },
      template: require('raw-loader!./or-checkbox.html').default
    };
  }
}
