import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { User } from '@app/shared/models/user.interface';
import { ITenant } from '@app/shared/models/tenant.interface';
import { Observable } from 'rxjs';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatDialogModule, MatSelectModule, MatButtonModule],
  selector: 'app-dialog',
  templateUrl: './tenant-user-dialog.component.html',
  styleUrls: ['./tenant-user-dialog.component.scss']
})
export class TenantUserDialogComponent implements OnInit, OnDestroy {
  readonly mode: FORM_TYPE;
  tenantUserForm: FormGroup;
  userId: number;
  tenantId: number;
  users$: Observable<User[]>;
  tenants$: Observable<ITenant[]>;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<TenantUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      mode: FORM_TYPE;
      users$: Observable<User[]>;
      tenants$: Observable<ITenant[]>;
      userId?: number;
      tenantId?: number;
    }
  ) {
    this.mode = data.mode;
    this.userId = data.userId;
    this.tenantId = data.tenantId;
  }

  ngOnInit(): void {
    this.users$ = this.data.users$;
    this.tenants$ = this.data.tenants$;
    this.tenantUserForm = this.fb.group({
      userId: ['', [Validators.required]],
      tenantId: ['', [Validators.required]]
    });
  }

  selectUserId($event: MatSelectChange): void {
    this.userId = $event.value;
  }

  selectTenantId($event: MatSelectChange): void {
    this.tenantId = $event.value;
  }

  cancel(): void {
    this.dialogRef.close({ event: 'Cancel' });
  }

  add(): void {
    this.dialogRef.close({ event: 'add', data: { userId: this.userId, tenantId: this.tenantId } });
  }

  edit(): void {
    this.dialogRef.close({ event: 'edit', data: { userId: this.userId, tenantId: this.tenantId } });
  }

  get isAddButtonEnabled(): boolean {
    return this.userId != null && this.tenantId != null;
  }

  ngOnDestroy(): void {}
}

export enum FORM_TYPE {
  EDIT,
  ADD
}
