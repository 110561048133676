import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LampTypeTemplate } from '../models/lamp-type-template.interface';
import { LampType, simpleLampTypeGuard } from '../models/lamp-type.interface';
import { SavedEntity } from '../models/saved-entity.interface';
import { ILampTypeTemplateResource } from './lamp-type-template.interface';
import { Resource } from './resource';
import { IResource } from './resource.interface';
import { Environment, environmentToken } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class LampTypeResource
  extends Resource<LampType, number>
  implements IResource<LampType, number>, ILampTypeTemplateResource
{
  constructor(private http: HttpClient, @Inject(environmentToken) private environment: Environment) {
    super(http, `${environment.apiUrl + environment.apiPath}/lamp_type`);
  }

  public addByParentId(item: LampType, buildingId: number): Observable<SavedEntity<LampType, number>> {
    return super.addByParentId(item, buildingId);
  }

  private addTypeMetadata(item: LampType) {
    if (simpleLampTypeGuard(item)) {
      (item as any).type = 'simple';
    }
  }

  public getTemplates(buildingId: number): Observable<LampTypeTemplate[]> {
    return this.http.get<LampTypeTemplate[]>(`${this.baseUrl}/${buildingId}/template`);
  }

  public addTemplate(template: LampTypeTemplate): Observable<SavedEntity<LampTypeTemplate, number>> {
    return this.http.post<SavedEntity<LampTypeTemplate, number>>(this.baseUrl + '/template', template);
  }
}

function lampTypeResourceUrlGenerator(buildingId: number) {
  return buildingId ? `/api/v1/building/${buildingId}/lamp_type` : null;
}
