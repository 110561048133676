import { OrSearchInputController } from './OrSearchInputController';

export class OrSearchInputDirective {
  constructor() {
    return {
      bindToController: true,
      controller: OrSearchInputController,
      controllerAs: 'searchInput',
      replace: true,
      restrict: 'E',
      require: 'ngModel',
      scope: {
        maxlength: '@?',
        name: '@?',
        ngDisabled: '=?',
        onChange: '&',
        ngModel: '=',
        placeholder: '@?'
      },
      template: require('raw-loader!./or-search-input.html').default
    };
  }
}
