export class Gateway {
  constructor(
    public name: string,
    public serialNumber: string,
    public address: string,
    public buildingId: number,
    public id?: number,
    public versionNumber: string = '4.0.64',
    public isDefaultNetwork: boolean = true,
    public staticIpAddress?: string,
    public gatewayIpAddress?: string,
    public dnsServers?: string,
    public subnetMask: string = '255.255.255.0',
    public networkType: string = NetworkType.WIREPAS.value,
    public wirepasVersion?: string,
    public gatewayType: string = GatewayType.GATEWAYONEPOINTTWO.value,
    public iotLogLevel: string = LogLevel.INFO.value,
    public isAwsIotEnabled: boolean = true,
    public otapLogLevel?: string,
    public isOtapEnabled?: boolean,
    public isWntEnabled?: boolean,
    public isAutoRestartEnabled?: boolean,
    public bacnetLogLevel?: string,
    public bacnetDeviceId?: number,
    public bacnetPort?: number,
    public isBacnetEnabled?: boolean,
    public hasKeys?: boolean,
    public lastRestart?: string,
    public lastUpdated?: string
  ) {}

  public static clone(other: Gateway): Gateway {
    return new Gateway(
      other.name,
      other.serialNumber,
      other.address,
      other.buildingId,
      other.id,
      other.versionNumber,
      NetworkType.WIREPAS.equalsString(other.networkType)
        ? other.isDefaultNetwork
        : null,
      NetworkType.SILVAIR.equalsString(other.networkType) ||
      other.isDefaultNetwork
        ? null
        : other.staticIpAddress,
      NetworkType.SILVAIR.equalsString(other.networkType) ||
      other.isDefaultNetwork
        ? null
        : other.gatewayIpAddress,
      NetworkType.SILVAIR.equalsString(other.networkType) ||
      other.isDefaultNetwork ||
      !other.dnsServers
        ? null
        : other.dnsServers.replace(/ /g, ''),
      NetworkType.SILVAIR.equalsString(other.networkType) ||
      other.isDefaultNetwork
        ? null
        : other.subnetMask,
      other.networkType,
      NetworkType.SILVAIR.equalsString(other.networkType)
        ? null
        : other.wirepasVersion,
      NetworkType.SILVAIR.equalsString(other.networkType)
        ? null
        : other.gatewayType,
      NetworkType.SILVAIR.equalsString(other.networkType) ||
      GatewayType.VESTA.equalsString(other.networkType)
        ? null
        : other.iotLogLevel,
      NetworkType.SILVAIR.equalsString(other.networkType) ||
      GatewayType.VESTA.equalsString(other.networkType)
        ? null
        : other.isAwsIotEnabled,
      NetworkType.SILVAIR.equalsString(other.networkType) ||
      GatewayType.VESTA.equalsString(other.networkType)
        ? null
        : other.otapLogLevel,
      NetworkType.SILVAIR.equalsString(other.networkType) ||
      GatewayType.VESTA.equalsString(other.networkType)
        ? null
        : other.isOtapEnabled,
      NetworkType.SILVAIR.equalsString(other.networkType) ||
      GatewayType.VESTA.equalsString(other.networkType)
        ? null
        : other.isWntEnabled,
      NetworkType.SILVAIR.equalsString(other.networkType) ||
      GatewayType.VESTA.equalsString(other.networkType)
        ? null
        : other.isAutoRestartEnabled,
      NetworkType.SILVAIR.equalsString(other.networkType) ||
      GatewayType.VESTA.equalsString(other.networkType)
        ? null
        : other.bacnetLogLevel,
      NetworkType.SILVAIR.equalsString(other.networkType) ||
      GatewayType.VESTA.equalsString(other.networkType)
        ? null
        : other.bacnetDeviceId,
      NetworkType.SILVAIR.equalsString(other.networkType) ||
      GatewayType.VESTA.equalsString(other.networkType)
        ? null
        : other.bacnetPort,
      NetworkType.SILVAIR.equalsString(other.networkType) ||
      GatewayType.VESTA.equalsString(other.networkType)
        ? null
        : other.isBacnetEnabled,
      other.hasKeys,
      this.humanizeTimestamp(other.lastRestart),
      this.humanizeTimestamp(other.lastUpdated)
    );
  }

  public static humanizeTimestamp(unixtime): any {
    if (!unixtime) {
      return 'N/A';
    }
    return new Date(unixtime * 1000);
  }
}

export class NetworkType {
  public static SILVAIR = new NetworkType('SILVAIR', 'Silvair');
  public static WIREPAS = new NetworkType('WIREPAS', 'Wirepas');

  public static all(): NetworkType[] {
    return [NetworkType.SILVAIR, NetworkType.WIREPAS];
  }

  public static fromValue(value: string): NetworkType {
    return value === NetworkType.SILVAIR.value
      ? NetworkType.SILVAIR
      : NetworkType.WIREPAS;
  }
  public constructor(public value: _NetworkType, public name: string) {}

  public equals(other: NetworkType): boolean {
    return this.equalsString(other.value);
  }

  public equalsString(other: string): boolean {
    return other !== null && other === this.value;
  }

  public toString(): string {
    return this.value;
  }
}

export class WirepasVersion {
  public static THREEPOINTFOUR = new WirepasVersion('3.4', '3.4');
  public static FOURPOINTZEROFIVE = new WirepasVersion('4.0.50', '4.0.50');
  public static FOURPOINTSIXFOUR = new WirepasVersion('4.0.64', '4.0.64');

  public static all(): WirepasVersion[] {
    return [
      WirepasVersion.THREEPOINTFOUR,
      WirepasVersion.FOURPOINTZEROFIVE,
      WirepasVersion.FOURPOINTSIXFOUR
    ];
  }

  public static fromValue(value: string): WirepasVersion {
    switch (value) {
      case WirepasVersion.THREEPOINTFOUR.value:
        return WirepasVersion.THREEPOINTFOUR;
      case WirepasVersion.FOURPOINTZEROFIVE.value:
        return WirepasVersion.FOURPOINTZEROFIVE;
      case WirepasVersion.FOURPOINTSIXFOUR.value:
        return WirepasVersion.FOURPOINTSIXFOUR;
    }
  }
  public constructor(public value: _WirepasVersion, public name: string) {}

  public equals(other: WirepasVersion): boolean {
    return this.equalsString(other.value);
  }

  public equalsString(other: string): boolean {
    return other !== null && other === this.value;
  }

  public toString(): string {
    return this.value;
  }
}

export class GatewayType {
  public static VESTA = new GatewayType('VESTA', 'Vesta');
  public static GATEWAYONEPOINTONE = new GatewayType(
    'LINUX GATEWAY 1.1',
    'Linux Gateway 1.1'
  );
  public static GATEWAYONEPOINTTWO = new GatewayType(
    'LINUX GATEWAY 1.2',
    'Linux Gateway 1.2'
  );

  public static all(): GatewayType[] {
    return [
      GatewayType.VESTA,
      GatewayType.GATEWAYONEPOINTONE,
      GatewayType.GATEWAYONEPOINTTWO
    ];
  }

  public static fromValue(value: string): GatewayType {
    switch (value) {
      case GatewayType.VESTA.value:
        return GatewayType.VESTA;
      case GatewayType.GATEWAYONEPOINTONE.value:
        return GatewayType.GATEWAYONEPOINTONE;
      case GatewayType.GATEWAYONEPOINTTWO.value:
        return GatewayType.GATEWAYONEPOINTTWO;
    }
  }
  public constructor(public value: _GatewayType, public name: string) {}

  public equals(other: GatewayType): boolean {
    return this.equalsString(other.value);
  }

  public equalsString(other: string): boolean {
    return other !== null && other === this.value;
  }

  public toString(): string {
    return this.value;
  }
}

export class LogLevel {
  public static ERROR = new LogLevel('ERROR', 'Error');
  public static DEBUG = new LogLevel('DEBUG', 'Debug');
  public static INFO = new LogLevel('INFO', 'Info');

  public static all(): LogLevel[] {
    return [LogLevel.ERROR, LogLevel.DEBUG, LogLevel.INFO];
  }

  public static fromValue(value: string): LogLevel {
    switch (value) {
      case LogLevel.ERROR.value:
        return LogLevel.ERROR;
      case LogLevel.DEBUG.value:
        return LogLevel.DEBUG;
      case LogLevel.INFO.value:
        return LogLevel.INFO;
    }
  }
  public constructor(public value: _LogLevel, public name: string) {}

  public equals(other: LogLevel): boolean {
    return this.equalsString(other.value);
  }

  public equalsString(other: string): boolean {
    return other !== null && other === this.value;
  }

  public toString(): string {
    return this.value;
  }
}

type _NetworkType = 'SILVAIR' | 'WIREPAS';
type _WirepasVersion = '3.4' | '4.0.50' | '4.0.64';
type _GatewayType = 'VESTA' | 'LINUX GATEWAY 1.1' | 'LINUX GATEWAY 1.2';
type _LogLevel = 'ERROR' | 'DEBUG' | 'INFO';
