<div class='or-job-tasks-tile-content'>
  <table class='job-tasks-table'>
    <tr>
      <th> Started</th>
      <th> Ended</th>
      <th *ngIf='dataSource.expectedCount'> Actual count</th>
      <th *ngIf='dataSource.expectedCount'> Expected count</th>
    </tr>
    <tr>
      <td> {{dataSource.startedDateTime ? dateInLocale(dataSource.startedDateTime) : 'N/A'}} </td>
      <td> {{dataSource.endedDateTime ? dateInLocale(dataSource.endedDateTime) : 'N/A'}} </td>
      <td *ngIf='dataSource.expectedCount'> {{dataSource.count ?? 'N/A'}} </td>
      <td *ngIf='dataSource.expectedCount'> {{dataSource.expectedCount?? 'N/A'}} </td>
    </tr>
  </table>
</div>
