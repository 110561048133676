import { Resource } from '@app/shared/resources/resource';
import { Environment, environmentToken } from '@environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EmergencyLightingTestType } from '@app/shared/models/emergency-lighting-test-type';
import { Tag } from '@app/shared/models/tag.interface';
import { EmergencyLightingTestState } from '@app/shared/models/emergency-lighting-test-state';

export interface EmergencyLogPageFilter {
  pageNumber: number;
  pageSize: number;
  filter: {
    buildingId: number | null;
    scheduleIds: number[] | null;
    floorIds: number[] | null;
    tagIds: number[] | null;
    testTypes: EmergencyLightingTestType[] | null;
    testStates: EmergencyLightingTestState[] | null;
    startDate: Date | null;
    endDate: Date | null;
    latestLogs: boolean | null;
  };
}

@Injectable({
  providedIn: 'root'
})
export class EmergencyLogResource extends Resource<IEmergencyLog, number> {
  constructor(private http: HttpClient, @Inject(environmentToken) private environment: Environment) {
    super(http, `${environment.apiUrl}${environment.apiPath}/emergency-lighting-logs`);
  }

  getFilteredLogsForBuilding({ filter, pageNumber, pageSize }: EmergencyLogPageFilter): Observable<any> {
    return this.http.post(
      `${this.baseUrl}`,
      {
        buildingId: filter.buildingId,
        scheduleIds: filter.scheduleIds || [],
        floorIds: filter.floorIds || [],
        tagIds: filter.tagIds || [],
        testTypes: filter.testTypes?.map((testType) => testType.value || testType) || [],
        testStates: filter.testStates?.map((testState) => testState.val || testState) || [],
        startDate: filter.startDate,
        endDate: filter.endDate,
        latestLogs: filter.latestLogs
      },
      {
        params: new HttpParams().set('page', pageNumber.toString()).set('size', pageSize.toString())
      }
    );
  }
}

export interface IEmergencyLogTest {
  id: number;
  buildingId: number;
  nodeId: number;
  lastUpdated: number;
  driverId: number;
  timeStarted: Date;
  type: EmergencyLightingTestType;
  state: EmergencyLightingTestState;
  message: string;
  archived: boolean;
  sensorNodeLink: string;
  scheduleLink: string;
}

export interface IEmergencyLog {
  scheduleId: number;
  scheduleName: string;
  timeStarted: number;
  testsType: EmergencyLightingTestType;
  status: EmergencyLightingTestState;
  tests: IEmergencyLogTest[];
}

export interface IEmergencyLogFlattened {
  timeStarted: number;
  sensorNodeId: number;
  driverId: number;
  testId?: number;
  testsType: EmergencyLightingTestType;
  state: EmergencyLightingTestState;
  message: string;
  timeZone?: string;
}

export interface IEmergencyLogGroup {
  testId?: number;
  sensorNodeId?: number;
  driverId?: number;
  timeStarted?: number;
  scheduleName: string;
  group?: boolean;
  tags?: Tag[];
  timeZone?: string;
}
