import { Schedule } from '../../api/building/Schedule';
import { Resource } from '../Resource';
import { PromiseRejectionHandler } from '../../promises/PromiseRejectionHandler';
import { PromiseUtils } from '../promises/PromiseUtils';

export class ScheduleResource extends Resource<Schedule, number> {
  constructor(
    $http: ng.IHttpService,
    baseUrl: string,
    rejectionHandler: PromiseRejectionHandler
  ) {
    super($http, baseUrl, rejectionHandler);
  }

  public addResource(buildingId: number, item: Schedule): Promise<Schedule> {
    return PromiseUtils.wrapSingle(
      this.$http.post(this.baseUrl + '/' + buildingId, item),
      this.rejectionHandler
    );
  }

  public updateResource(id: number, item: Schedule): Promise<Schedule> {
    return PromiseUtils.wrapSingle(
      this.$http.put(this.baseUrl + '/' + id, item),
      this.rejectionHandler
    );
  }

  public moveUp(id: number): Promise<{}> {
    return PromiseUtils.wrapSingle(
      this.$http.post(this.baseUrl + '/up/' + id, null),
      this.rejectionHandler
    );
  }

  public moveDown(id: number): Promise<{}> {
    return PromiseUtils.wrapSingle(
      this.$http.post(this.baseUrl + '/down/' + id, null),
      this.rejectionHandler
    );
  }

  public getEditableIds(buildingId: number): Promise<number[]> {
    return PromiseUtils.wrapSingle(
      this.$http.get(this.baseUrl + '/' + buildingId + '/editable-ids'),
      this.rejectionHandler
    );
  }
}
