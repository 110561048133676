<div id="orAdministrationEmergencyLighting" class="admin-emergency-lighting-body">
  <form
    *ngIf="details$ | async; else loading"
    [formGroup]="emergencyLightingForm"
    class="form-emergency-lighting-details"
    novalidate="novalidate"
  >
    <ol class="form-items">
      <li>
        <mat-slide-toggle color='primary' (click)="toggleBanner()" formControlName="bannerEnabled">Enable Banner</mat-slide-toggle>
      </li>
      <li class="padding-v-5">
        <app-image-upload
          class="or-form-building-details-logo-upload"
          [imageUrl$]="logoUrl$"
          [error$]="error$"
          (onUpload)="onLogoUpload($event)"
          [caption]="'Upload Logo for ELMT Reports'"
          [width]="360"
          [height]="360"
        >
        </app-image-upload>
      </li>
      <li class='or-inline-item'>
        <button
          mat-raised-button
          data-cy="form-admin-emergency-details-submit"
          type="submit"
          class="submit-btn or-button or-positive"
          (click)="submit()"
          [disabled]="emergencyLightingForm.invalid || emergencyLightingForm.pristine">
          <span class="or-icon or-small or-inverse or-icon-checkmark"></span>
          <span class="or-button-label">Save</span>
        </button>
      </li>
    </ol>
  </form>
  <ng-template #loading>
    Loading details...
  </ng-template>
</div>
