import { OrImgOnLoadController } from './OrImgOnLoadController';

export class OrImgOnLoadDirective {
  constructor() {
    return {
      bindToController: true,
      controller: ['$scope', '$element', OrImgOnLoadController],
      controllerAs: 'imgOnLoad',
      restrict: 'A',
      scope: {
        callback: '=orImgOnLoad',
        target: '=orImgLoadTarget'
      }
    };
  }
}
