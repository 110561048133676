export class OrFloorplanActionsTrayController {
  public showTray: boolean;
  public toolTipClass: string;
  public hover: boolean;
  public buttonClass: string;

  public buttons: TrayButton[];

  public classname = 'or-tray';

  public showToolTipAbove: boolean;
  public trayButtonIconClass: string;
  public label: string;
  public trayButtons: TrayButton;
  public trayButtonToolTip: string;
  public onClick: () => {};
  public disable: boolean;
  public overrideShowTray = false;
  public buildingId: number;

  constructor(private $scope: ng.IScope) {}

  public $onInit() {
    if (this.showToolTipAbove) {
      this.toolTipClass = 'or-button-tooltip-top-right';
    } else {
      this.toolTipClass = 'or-button-tooltip-bottom-right';
    }
    this.$scope.$watch('actionsTray.disable', () => {
      if (this.disable) this.showTray = false;
    });

    this.$scope.$watch('actionsTray.overrideShowTray', (newValue: boolean) => {
      this.showTray = newValue;
    });
  }

  public show(): boolean {
    return this.showTray;
  }

  public toggleTray() {
    if (!this.overrideShowTray) {
      this.showTray = !this.showTray;
      if (this.onClick) {
        this.onClick();
      }
    }
  }

  public onHover(hover: boolean) {
    if (!this.trayButtonToolTip && !this.disable) {
      this.hover = hover;
    }
  }

  public runButtonAction(event: Event, button: TrayButton) {
    button.onClick(event);

    if (button.closeTrayAfter) {
      this.showTray = false;
    }
  }

  public getTrayClass(): string {
    if (this.showTray) {
      return '';
    } else {
      return 'or-tray';
    }
  }
}

export class TrayButton {
  constructor(
    public toolTip: string,
    public iconClass: string,
    public onClick: (Event) => void,
    public onActive?: () => boolean,
    public closeTrayAfter: boolean = false,
    public id?: string,
    public checkTenancy: boolean = false
  ) {}
}
