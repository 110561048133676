import { SensorNode } from '@app/shared/models/sensor-node';

export class SensorNodeChangeHistory {
  public oldX: number;
  public oldY: number;
  public oldIsChanged: boolean;

  constructor(public node: SensorNode) {
    if (!this.node) {
      return;
    }
    this.oldX = node.x;
    this.oldY = node.y;
    this.oldIsChanged = node.isChanged;
  }

  public undo(): void {
    if (!this.node) {
      return;
    }
    this.node.x = this.oldX;
    this.node.y = this.oldY;
    this.node.isChanged = this.oldIsChanged;
  }
}
