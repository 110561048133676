import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent implements OnInit, OnDestroy {
  @ViewChild('fileInput', { static: true }) public fileInput: ElementRef<HTMLInputElement>;

  @Input() public error$: Observable<void>;
  @Input() public imageUrl$: Observable<string>;
  @Input() public caption: string;
  @Input() width: number;
  @Input() height: number;

  @Output() public onUpload = new EventEmitter<File>();

  public isBusy: boolean;
  public newImageUrl: string;
  public previewStyle;
  public isDisabled: boolean;
  public form: any;

  private imageUrlSubscription: Subscription;

  public ngOnInit(): void {
    this.imageUrlSubscription = this.imageUrl$.subscribe((url) => {
      this.newImageUrl = url;
      this.fileInput.nativeElement.value = '';
      this.isBusy = false;
    });
    this.error$.subscribe(() => {
      alert('Invalid image: \n * must be of type jpg,png,jpeg\n * filesize must be < 1mb.');
      this.isBusy = false;
    });
  }

  public ngOnDestroy(): void {
    if (this.imageUrlSubscription) {
      this.imageUrlSubscription.unsubscribe();
    }
  }

  public imagePreview(): string {
    return this.newImageUrl ? 'url(' + this.newImageUrl + ')' : 'none';
  }

  public onFileChange(files: File[]): void {
    if (!files.length) {
      return;
    }
    this.isBusy = true;
    this.onUpload.emit(files[0]);
  }
}
