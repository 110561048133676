export class QueryType {
  constructor(public value: QueryType_) {}

  static TIME_SERIES = new QueryType('TIME_SERIES');
  static PER_NODE = new QueryType('PER_NODE');

  public toString(): string {
    return this.value;
  }
}

type QueryType_ = 'TIME_SERIES' | 'PER_NODE';
