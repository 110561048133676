import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment, environmentToken } from '@environment';
import { Observable } from 'rxjs';
import { Floor } from '../models/floor.interface';
import { SavedEntity } from '../models/saved-entity.interface';
import { SensorNodeBatch } from '../models/sensor-node-batch.interface';
import { SensorNodeDTO } from '../models/sensor-node';
import { IFloorResource } from './floor.resource.interface';
import { Resource } from './resource';

@Injectable({
  providedIn: 'root'
})
export class FloorResource extends Resource<Floor, number> implements IFloorResource {
  private readonly apiUrl: string;
  constructor(private http: HttpClient, @Inject(environmentToken) environment: Environment) {
    super(http, `${environment.apiUrl + environment.apiPath}/floor`);
    this.apiUrl = environment.apiUrl + environment.apiPath;
  }

  public addNodes(floorId: number, nodes: SensorNodeDTO[]): Observable<SavedEntity<SensorNodeBatch, number>[]> {
    return this.http.post<SavedEntity<SensorNodeBatch, number>[]>(`${this.apiUrl}/node/floor/${floorId}`, { nodes });
  }

  getFloorsForBuilding(buildingId: number): Observable<Floor[]> {
    return this.http.get<Floor[]>(`${this.baseUrl}/building/${buildingId}`);
  }

  getFloorImageUrl(floor: Floor): string {
    return `${this.apiUrl}/floor/${floor?.id}/floorplan?id=${floor?.floorplan}`;
  }
}
