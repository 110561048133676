import { OrUserMenuController } from './OrUserMenuController';

export class OrUserMenuDirective {
  constructor() {
    return {
      bindToController: true,
      controller: ['DropdownService', OrUserMenuController],
      controllerAs: 'userMenu',
      replace: true,
      restrict: 'E',
      scope: {
        disableBuildingsMenu: '=',
        disableFloorsMenu: '=',
        disableTenantsMenu: '=',
        disableSessionMenu: '='
      },
      template: require('raw-loader!./or-user-menu.html').default
    };
  }
}
