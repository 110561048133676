import { LampTypeTemplate } from './ILampTypeTemplate';

export class SimpleLampType {
  constructor(
    public powerConsumptionMax?: number,
    public template?: LampTypeTemplate,
    public sensorNodes: number[] = [],
    public building?: number,
    public name = '',
    public id = -1,
    public lampTypeId?: number,
    public lampTypeTemplateId?: number,
    public ratedPower?: number
  ) {}
}

export class SimpleLampTypeOutline extends SimpleLampType {
  constructor(
    powerConsumptionMax: number,
    public lampTypeTemplateId: number,
    public sensorNodeIds: number[]
  ) {
    super(powerConsumptionMax);
  }

  static fromSimpleLampType(
    simpleLampType: SimpleLampType
  ): SimpleLampTypeOutline {
    return new SimpleLampTypeOutline(
      simpleLampType.powerConsumptionMax,
      simpleLampType.template != null ? simpleLampType.template.id : null,
      simpleLampType.sensorNodes
    );
  }
}
