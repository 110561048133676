export interface Pageable<T> {
  content: T[];
  totalPages: number;
  totalElements: number;
  first: boolean;
  last: boolean;
  empty: boolean;
}

export class EmptyPage<T> implements Pageable<T> {
  content;
  totalElements;
  totalPages;
  first;
  last;
  empty;
  constructor() {
    this.content = [];
    this.empty = true;
    this.totalElements = 0;
    this.totalPages = 0;
    this.first = false;
    this.last = false;
  }
}
