import { IEmergencyScheduleRun } from '@app/shared/resources/emergency-calendar-event.resource';
import { EmergencyLightingSchedule } from '@app/shared/models/emergency-lighting-schedule';

export interface IElmtScheduleDto {
  id: number;
  name: string;
  buildingId?: number;
  timezone?: string;
  tagIds: number[];
  floorIds: number[];
  testsType?: string;
  startDateTime: number;
  endDateTime: number;
  schedulingStrategy?: string;
  repeatEvery: number;
  repeatUnit?: string;
  repeatOnWeekdays?: string[];
  repeatEndsOn?: Date;
  repeatEndsAfterNumOccurrences?: number;
  enabled: boolean;
  currentRunStartTime?: number;
  currentRunEstimatedEndTime?: number;
  nextRunStartTime?: number;
  nextRunEstimatedEndTime?: number;
  nodeCount?: number;
  lastRunStartTime?: number;
  scheduleRuns?: IEmergencyScheduleRun[];
  hasRan: boolean;
  editable: boolean;
}

export function scheduleCompare(s1: EmergencyLightingSchedule, s2: EmergencyLightingSchedule): number {
  if (s1.name == null) {
    return -1;
  } else {
    return s1.name.localeCompare(s2.name);
  }
}
