import { DataPoint } from '@app/analytics/metric-widget/data-objects/data-point';
import { QueryResult } from '@app/analytics/metric-widget/data-objects/query-result';
import { Pair } from '@app/analytics/metric-widget/data-objects/pair';

export class ChartData {
  constructor(public values: DataPoint[] = [], public name?: string, public maximum?: number) {}

  public static fromQueryResult(result: QueryResult<number>): ChartData {
    const chart = new ChartData();

    result.values.forEach((pair) => {
      chart.values.push(new DataPoint(pair.key, pair.value));
    });

    chart.maximum = result.max;
    return chart;
  }

  public asCsvData(header?: Pair<string, string>): string {
    const headerData = header != null ? header.key + ',' + header.value + '\n' : '';
    const bodyData = this.values
      .map((point) => {
        const yLabel = point.yLabel === null ? '' : point.yLabel;
        return point.xLabel + ',' + yLabel;
      })
      .join('\n');

    return headerData + bodyData;
  }
}
