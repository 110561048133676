// import t from 'moment';
import { DayOfTheWeek } from './DayOfTheWeek';
import moment, { Moment } from 'moment';
// import Moment = moment.Moment;

export class TimeUtils {
  public static getHoursInDay(skip = 1): string[] {
    const times: string[] = [];
    const time = moment();

    for (let h = 0; h < 24; h += skip) {
      time.hour(h);
      times.push(time.format('HH'));
    }

    return times;
  }

  public static getMinutesInHour(skip = 30): string[] {
    const times: string[] = [];
    const time = moment();

    for (let m = 0; m < 60; m += skip) {
      time.minute(m);
      times.push(time.format('mm'));
    }

    return times;
  }

  public static getTimesInDay(intervalInMinutes: number): string[] {
    const times: string[] = [];
    const start = moment();
    start.hour(0);
    start.minute(0);
    const end = start.clone();
    end.add(1, 'days');
    const current = start.clone();

    while (current.isBefore(end)) {
      times.push(current.format('HH:mm'));
      current.add(intervalInMinutes, 'minutes');
    }

    return times;
  }

  public static getHalfHoursInDay(): string[] {
    const times: string[] = [];

    const time = moment();

    for (let h = 0; h < 24; h++) {
      for (let m = 0; m < 60; m += 30) {
        time.hour(h);
        time.minute(m);
        times.push(time.format('HH:mm'));
      }
    }

    return times;
  }

  public static getIntervalsInDay(): string[] {
    const times: string[] = [];

    const time: Moment = moment();

    for (let h = 0; h < 24; h++) {
      for (let m = 0; m < 60; m += 60) {
        time.hour(h);
        time.minute(m);
        times.push(time.format('HH:mm'));
      }
    }

    return times;
  }

  public static isBetween(start: string, end: string, time: string): boolean {
    const timeToTest: Moment = moment(time, 'HH:mm');
    const startTime: Moment = moment(start, 'HH:mm');
    const endTime: Moment = moment(end, 'HH:mm');
    if (startTime.isSame(endTime)) {
      return true;
    }

    if (startTime.isBefore(endTime)) {
      return (
        timeToTest.isSame(startTime) || timeToTest.isBetween(startTime, endTime)
      );
    } else {
      return !(
        timeToTest.isSame(endTime) || timeToTest.isBetween(endTime, startTime)
      );
    }
  }

  public static isBetweenToNearestHalfHour(
    start: string,
    end: string,
    time: string
  ): boolean {
    const timeToTest: Moment = moment(time, 'HH:mm');
    const startTime: Moment = moment(start, 'HH:mm');
    const endTime: Moment = moment(end, 'HH:mm');
    if (startTime.isSame(endTime)) {
      return true;
    }

    return TimeUtils.isBetweenHalfHourPlusMinus(timeToTest, startTime, endTime);
  }

  public static isSame(start: string, end: string): boolean {
    return moment(start, 'HH:mm').isSame(moment(end, 'HH:mm'));
  }

  public static isBefore(start: string, end: string): boolean {
    return moment(start, 'HH:mm').isBefore(moment(end, 'HH:mm'));
  }

  public static isAfter(start: string, end: string): boolean {
    return moment(start, 'HH:mm').isAfter(moment(end, 'HH:mm'));
  }

  public static isStartAndEndRoundedToSameTime(
    start: string,
    end: string,
    test: string
  ) {
    const startTime = moment(start, 'HH:mm'),
      endTime = moment(end, 'HH:mm'),
      testTime = moment(test, 'HH:mm');
    const roundedStartTime = TimeUtils.roundToNearestHalfHour(startTime);
    const roundedEndTime = TimeUtils.roundToNearestHalfHour(endTime);
    const roundedTestTime = TimeUtils.roundToNearestHalfHour(testTime);
    if (roundedStartTime.isSame(roundedEndTime)) {
      return roundedStartTime.isSame(roundedTestTime);
    } else if (roundedStartTime.isBefore(roundedEndTime)) {
      roundedEndTime.subtract(1, 'd');
      return (
        roundedStartTime.isSame(roundedTestTime) &&
        roundedEndTime.isSame(roundedTestTime)
      );
    } else {
      roundedStartTime.subtract(1, 'd');
      return (
        roundedStartTime.isSame(roundedTestTime) &&
        roundedEndTime.isSame(roundedTestTime)
      );
    }
  }

  public static isStartCloseToEnd(
    start: string,
    end: string,
    gapInMinutes: number
  ): boolean {
    const startTime = moment(start, 'HH:mm'),
      endTime = moment(end, 'HH:mm');
    if (startTime.isSame(endTime)) {
      return true;
    }
    let difference = Math.abs(startTime.diff(endTime, 'minutes'));
    if (startTime.isAfter(endTime)) {
      endTime.add(1, 'd');
      difference = Math.abs(startTime.diff(endTime, 'minutes'));
    }
    return difference <= gapInMinutes;
  }

  public static isRoundedEndAfterStartAndWithinHalfHour(
    start: string,
    end: string
  ): boolean {
    const startTime = moment(start, 'HH:mm'),
      endTime = moment(end, 'HH:mm');
    const roundedStartTime = TimeUtils.roundToNearestHalfHour(startTime);
    const roundedEndTime = TimeUtils.roundToNearestHalfHour(endTime);
    if (startTime.isBefore(endTime)) {
      const isNextDaySame = roundedStartTime.isSame(roundedEndTime);
      roundedStartTime.add(30, 'm');
      const isNextDaySameWithin30m = roundedStartTime.isSame(roundedEndTime);
      return isNextDaySame || isNextDaySameWithin30m;
    } else {
      roundedEndTime.add(1, 'd');
      const isNextDaySame = roundedStartTime.isSame(roundedEndTime);
      roundedStartTime.add(30, 'm');
      const isNextDaySameWithin30m = roundedStartTime.isSame(roundedEndTime);
      return isNextDaySame || isNextDaySameWithin30m;
    }
  }

  public static getTimespanInMinutes(start: string, end: string): number {
    const startTime: Moment = moment(start, 'HH:mm');
    const endTime: Moment = moment(end, 'HH:mm');

    return Math.abs(endTime.diff(startTime, 'minutes'));
  }

  public static getDaysOfTheWeek(): DayOfTheWeek[] {
    return DayOfTheWeek.all();
  }

  private static roundToNearestHalfHour(moment: Moment) {
    const momentCopy = moment.clone();
    const minutes = momentCopy.minutes();
    if (minutes < 15) {
      momentCopy.minutes(0);
    } else if (minutes >= 15 && minutes < 45) {
      momentCopy.minutes(30);
    } else if (minutes >= 45) {
      momentCopy.minutes(0);
      momentCopy.hours(momentCopy.hours() + 1);
    }
    return momentCopy;
  }

  private static isBetweenHalfHourPlusMinus(
    timeToTest: Moment,
    startTime: Moment,
    endTime: Moment
  ): boolean {
    const roundedStartTime = TimeUtils.roundToNearestHalfHour(startTime);
    const roundedEndTime = TimeUtils.roundToNearestHalfHour(endTime);

    if (roundedStartTime.isSame(roundedEndTime)) {
      if (startTime.isBefore(endTime)) {
        if (startTime.isBefore(roundedStartTime)) {
          roundedStartTime.subtract(30, 'm');
        } else {
          roundedEndTime.add(30, 'm');
        }
      } else {
        if (endTime.isBefore(roundedEndTime)) {
          roundedEndTime.subtract(30, 'm');
        } else {
          roundedStartTime.add(30, 'm');
        }
      }
    }

    if (timeToTest.isSame(roundedStartTime)) {
      return true;
    }
    if (timeToTest.isSame(roundedEndTime)) {
      return false;
    }
    if (roundedStartTime.isBefore(roundedEndTime)) {
      return timeToTest.isBetween(roundedStartTime, roundedEndTime);
    } else {
      return !timeToTest.isBetween(roundedEndTime, roundedStartTime);
    }
  }
}
