import * as angular from 'angular'; // Automatically added
import { BleKey } from '@angularjs/or/api/building/BleKey';

export class OrFormBleKeyDetailsController {
  public isNew: boolean;
  public isCurrent: boolean;
  public isBusy: boolean;
  public key;
  public form;
  public initialKey;
  public newKey: BleKey;
  public onAddKey;
  public onUpdateKey;
  public onSendKey;

  public $onInit() {
    this.backupInitialKey();
  }

  backupInitialKey() {
    this.initialKey = angular.copy(this.key);
  }

  restoreInitialKey() {
    this.key = angular.copy(this.initialKey);
  }

  resetKey() {
    const msg =
      'Reset BLE Key' + (this.key.sequence ? ' ' + this.key.sequence : '?');
    if (!confirm(msg)) {
      return;
    }
    this.restoreInitialKey();
    this.resetFormValidationState();
  }

  resetFormValidationState() {
    this.form.$setPristine();
    this.form.$setUntouched();
  }

  addKey() {
    this.resetFormValidationState();
    this.onAddKey();
  }

  updateKey() {
    this.onUpdateKey();
  }

  sendKey() {
    this.onSendKey();
  }
}
