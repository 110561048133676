import { Inject, Injectable } from '@angular/core';
import * as SockJS from 'sockjs-client';
import { Environment, environmentToken } from '@environment';
import { CompatClient, Stomp } from '@stomp/stompjs';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OtapWebsocketService {
  private stompClient: CompatClient;

  constructor(@Inject(environmentToken) private readonly environment: Environment) {}

  private connect(onConnect?: () => void): void {
    if (this.isConnected()) {
      console.warn('Socket already connected');
      return;
    }

    const ws = new SockJS(this.environment.apiUrl + '/ws/upgrade');
    this.stompClient = Stomp.over(ws);
    this.stompClient.connect({}, (frame) => {
      if (onConnect) {
        onConnect();
      }
    });
  }

  public disconnect(): void {
    if (this.stompClient && this.stompClient.connected) {
      this.stompClient.disconnect();
    }
  }

  public startConnection(): void {
    if (this.stompClient && this.stompClient.connected) {
      // Already connected so skipping connection.
      return;
    }
    this.connect();
  }

  public isConnected(): boolean {
    return this.stompClient && this.stompClient.connected;
  }

  public listenToJob(jobId: number, onPacket): Observable<any> {
    return new Observable((observer) => {
      const callback = () => {
        if (this.isConnected()) {
          const subscription = this.stompClient.subscribe(`/topic/job/${jobId}`, onPacket);
          observer.next(subscription);
          observer.complete();
        }
      };

      if (!this.isConnected()) {
        this.connect(callback);
      } else {
        callback();
      }
    });
  }
}
