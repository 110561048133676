import { SensorNodeChangeHistory } from '../api/building/SensorNodeChangeHistory';
import { FloorplanSensorNode } from '../api/building/FloorplanSensorNode';

export class SensorNodeChangeHistoryService {
  private nodeChangeHistories: Map<number, SensorNodeChangeHistory>[] = [];

  constructor() {}

  public startRecSensorNodeChangeHistory(nodes: FloorplanSensorNode[]) {
    const historyList: Map<number, SensorNodeChangeHistory> = new Map();
    nodes.forEach((node) => {
      historyList.set(node.id, new SensorNodeChangeHistory(node));
    });

    if (historyList.size > 0 && historyList != this.getLast()) {
      this.nodeChangeHistories.push(historyList);
    }
  }

  public stopRecSensorNodeChangeHistory() {
    const node = this.getLast();
    if (node && node.size < 0) {
      this.nodeChangeHistories.pop();
    }
  }

  public clearSensorNodeChangeHistory() {
    this.nodeChangeHistories.length = 0;
  }

  public getLast(): Map<number, SensorNodeChangeHistory> {
    if (this.nodeChangeHistories.length == 0) {
      return null;
    }
    return this.nodeChangeHistories[this.nodeChangeHistories.length - 1];
  }

  public undo() {
    const map = this.getLast();
    if (!map || map.size == 0) {
      return;
    }
    map.forEach((item) => {
      item.undo();
    });
    this.nodeChangeHistories.pop();
  }

  public total(): number {
    return this.nodeChangeHistories.length;
  }
}
