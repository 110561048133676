import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { Tab } from '@app/shared/components/tabs/tab.interface';
import { HeaderService } from '@app/shared/services/header.service';
import { UserService } from '@app/shared/services/user/user.service';
import { User } from '@app/shared/models/user.interface';
import { Building } from '@app/shared/models/building.interface';
import { emergencyLightingTabs } from '@app/emergency-lighting/layout/emergency-lighting-tabs';
import { ActivatedRoute } from '@angular/router';
import { FloorService } from '@services/floor.service';
import { NavigationService } from '@services/navigation/navigation.service';
import { EmergencyLightingScheduleService } from '@services/emergency-lighting/emergency-lighting-schedule.service';
import { ElmtSelectedFiltersService } from '@app/emergency-lighting/elmt-tests-filter/elmt-selected-filters.service';

@Component({
  selector: 'app-emergency-lighting',
  templateUrl: './emergency-lighting.component.html',
  styleUrls: ['./emergency-lighting.component.scss']
})
export class EmergencyLightingComponent implements OnInit, OnDestroy {
  public tabs$: Observable<Tab[]>;

  constructor(
    private readonly header: HeaderService,
    private readonly userService: UserService,
    private readonly route: ActivatedRoute,
    private readonly floorService: FloorService,
    private readonly navigationService: NavigationService,
    private readonly scheduleService: EmergencyLightingScheduleService,
    private readonly elmtSelectedFiltersService: ElmtSelectedFiltersService
  ) {}

  ngOnDestroy(): void {
    this.elmtSelectedFiltersService.clearFilters();
  }

  ngOnInit(): void {
    this.header.showBuildingsMenu();
    this.header.showSiteMenu();
    this.header.showUserMenu();
    this.header.showSessionMenu();

    this.tabs$ = this.route.params.pipe(
      switchMap((params) =>
        combineLatest([
          this.userService.getCurrentUser(),
          this.userService.getBuilding(params.buildingId),
          this.floorService.getCurrentFloorId()
        ])
      ),
      map(([user, building, currentFloorId]) => {
        this.navigationService.initNavigation(window.location.href, building);
        return this.createEmergencyLightingMenu(user, building, currentFloorId);
      })
    );
  }

  private createEmergencyLightingMenu(user: User, building: Building, currentFloorId: number): Tab[] {
    const tabs = [];
    this.scheduleService.fetchSchedules(building.id);
    emergencyLightingTabs.forEach((tab) => {
      let resolvedPath = '';
      if (tab.isAuthorized(user, building)) {
        // when user clicks on the "Emergency Lighting" icon in the header, they are navigated to "Schedules" tab
        // by default, which is building wide. When navigation happens for the first time, floorId will not be
        // available, so we set the floorId to be the first floor available in the building
        let floorId = building.floors[0]?.id;
        if (currentFloorId) {
          // whenever user navigates to a different building, we should ideally nullify the current floor id, but
          // currently floorService lives in ng+ land, and we would need to create a bridge between "change building"
          // action and nullifying floor so, we use a hacky way to set default floor id if current building doesn't have
          // the current floor id
          floorId = building.floors.map((f) => f.id).some((f) => f === currentFloorId) ? currentFloorId : floorId;
        }
        resolvedPath = tab.path.replace(/\{buildingId}/i, String(building.id));
        resolvedPath = resolvedPath.replace(/\{floorId}/i, String(floorId));
        // we do this for manual tests page, as it contains "floorId" in its route which can be undefined
        // if building has no floors
        if (!resolvedPath.includes('undefined')) {
          tabs.push({
            ...tab,
            path: resolvedPath
          });
        }
      }
    });
    return tabs;
  }
}
