import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { AuthMethod } from '../models/auth-method';
import { AuthenticationUrlsService } from './authentication-urls.service';
import { WindowRefService } from './window.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  constructor(
    private windowRef: WindowRefService,
    private location: Location,
    private authenticationUrls: AuthenticationUrlsService,
    private http: HttpClient
  ) {
    this.windowRef = windowRef;
    this.location = location;
  }

  authenticate(loginMethod?: AuthMethod, redirectUrl?: string): void {
    console.log('Session Service is setting the window location.', redirectUrl);
    const method = loginMethod ?? AuthMethod.AUTH0;
    const test =
      this.authenticationUrls.authenticateBaseUrl(method) +
      (redirectUrl != null ? encodeURIComponent(redirectUrl) : '/home');
    this.windowRef.goTo(test);
  }

  logout(): void {
    this.http.post(this.authenticationUrls.logoutBaseUrl, null, { responseType: 'text' }).subscribe((response) => {
      this.windowRef.goTo(response);
    });
  }

  handleFailedRequest(response): void {
    if (response.status === 401) {
      this.windowRef.goTo(`${this.authenticationUrls.postLogoutRedirectBaseUrl}?redirectUrl=${this.location.path()}`);
    }
  }
}
