import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { User } from '@app/shared/models/user.interface';
import { MAT_RADIO_DEFAULT_OPTIONS, MatRadioModule } from '@angular/material/radio';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  UntypedFormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { IElmtScheduleDto } from '@app/shared/models/elmt-schedule-dto.interface';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { combineLatestWith, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, map, startWith, takeUntil } from 'rxjs/operators';
import { MatButtonModule } from '@angular/material/button';
import { ElmtEmailTableData } from '@app/shared/models/elmt-email-table-datasource';
import { EmailSchedulesService } from '@app/emergency-lighting/email-schedules/email-schedules.service';
import { Building } from '@app/shared/models/building.interface';
import { TimeUtils } from '@app/shared/utils/time.utils';

function atLeastOneRequired(control: AbstractControl): ValidationErrors | null {
  const list = control.value;
  if (list.length === 0) {
    return { listEmpty: true };
  }
  return null;
}

@Component({
  selector: 'app-elmt-email-schedule-form',
  standalone: true,
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatChipsModule,
    MatDatepickerModule,
    MatIconModule,
    MatInputModule,
    MatRadioModule,
    ReactiveFormsModule
  ],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
    { provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: { color: 'primary' } }
  ],
  templateUrl: './elmt-email-schedule-form.component.html',
  styleUrl: './elmt-email-schedule-form.component.scss'
})
export class ElmtEmailScheduleFormComponent implements OnInit, OnDestroy {
  constructor(private fb: FormBuilder, private emailSchedulesService: EmailSchedulesService) {}

  @Input({ required: true }) users: User[] = [];
  @Input({ required: true }) schedules: IElmtScheduleDto[] = [];
  @Input({ required: true }) building: Building;
  @Output() submitForm = new EventEmitter<{ form: ElmtEmailTableData; edit: boolean }>();
  @ViewChild('userInput') userInput: ElementRef<HTMLInputElement>;
  @ViewChild('scheduleInput') scheduleInput: ElementRef<HTMLInputElement>;
  userInputCtrl = new FormControl('');
  userChipGridCtrl = new FormControl([], [atLeastOneRequired]);
  scheduleInputCtrl = new FormControl({ value: '', disabled: true });
  scheduleChipGridCtrl = new FormControl({ value: [], disabled: true }, { validators: atLeastOneRequired });
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  filteredUsernames: Observable<string[]> = of([]);
  filteredSchedules: Observable<string[]> = of([]);
  emailScheduleFormGroup: UntypedFormGroup;
  minSelectableDate: Date;
  private destroy$ = new Subject<void>();

  ngOnInit(): void {
    this.minSelectableDate = TimeUtils.adjustDateToTimezone(new Date(), this.building.timeZone);
    this.initFormGroup();
    this.setupFilteredUsernames();
    this.setupFilteredSchedules();
    this.setupScheduleRadioChanges();
    this.setupClickedRowSubscription();
    this.emailSchedulesService.formSubmitted$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.resetForm();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initFormGroup(): void {
    this.emailScheduleFormGroup = this.fb.group({
      id: null,
      reportName: ['', [Validators.required, Validators.maxLength(100)]],
      users: [[] as User[]],
      sendDate: [this.minSelectableDate, [Validators.required]],
      schedules: [[] as IElmtScheduleDto[]],
      edit: [false],
      scheduleRadio: ['all']
    });
    // add control validations to the form group, which allows enabling/disabling submit button
    this.emailScheduleFormGroup.addControl('userChipGridCtrl', this.userChipGridCtrl);
    this.emailScheduleFormGroup.addControl('scheduleChipGridCtrl', this.scheduleChipGridCtrl);
  }

  private setupClickedRowSubscription(): void {
    this.emailSchedulesService.clickedRow$.pipe(takeUntil(this.destroy$)).subscribe((row) => {
      const users = this.users.filter((u) => row.userIds.includes(u.id));
      const schedules = this.schedules.filter((s) => row.scheduleIds.includes(s.id));
      const { id, reportName, sendDate } = row;
      const sendDateInBuildingTimezone = TimeUtils.adjustDateToTimezone(
        typeof sendDate === 'number' ? new Date(sendDate * 1000) : sendDate,
        this.building.timeZone
      );
      this.emailScheduleFormGroup.patchValue({
        id,
        reportName,
        users,
        sendDate: sendDateInBuildingTimezone,
        schedules,
        edit: true,
        userChipGridCtrl: users,
        scheduleChipGridCtrl: schedules,
        scheduleRadio: schedules.length === 0 ? 'all' : 'specific'
      });
      this.emailScheduleFormGroup.markAllAsTouched();
    });
  }

  private setupFilteredUsernames(): void {
    this.filteredUsernames = this.userInputCtrl.valueChanges.pipe(
      startWith(null),
      combineLatestWith(this.emailScheduleFormGroup.get('users').valueChanges.pipe(startWith([]))),
      map(([user, _]) => this.showAvailableUsers(user))
    );
  }

  private setupFilteredSchedules(): void {
    this.filteredSchedules = this.scheduleInputCtrl.valueChanges.pipe(
      startWith(null),
      combineLatestWith(this.emailScheduleFormGroup.get('schedules').valueChanges.pipe(startWith([]))),
      map(([schedule, _]) => this.showAvailableSchedules(schedule))
    );
  }

  private setupScheduleRadioChanges(): void {
    this.emailScheduleFormGroup
      .get('scheduleRadio')
      .valueChanges.pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged((previous, current) => previous === current)
      )
      .subscribe((value) => {
        if (value === 'all') {
          this.disableScheduleFormField();
        } else {
          this.enableScheduleFormField();
        }
      });
  }

  private clearUserInput(): void {
    this.userInput.nativeElement.value = '';
    this.userInputCtrl.setValue(null);
  }

  private clearScheduleInput(): void {
    this.scheduleInput.nativeElement.value = '';
    this.scheduleInputCtrl.setValue(null);
  }

  private showAvailableUsers(user: string | null): string[] {
    const availableUsers = this.users
      .filter((u) => !this.selectedUsers.map((selectedUser) => selectedUser.id).includes(u.id))
      .map((u) => u.name);
    return user ? availableUsers.filter((u) => u.toLowerCase().includes(user.toLowerCase())) : availableUsers;
  }

  private showAvailableSchedules(schedule: string): string[] {
    const availableSchedules = this.schedules
      .filter((s) => !this.selectedSchedules.map((selectedSchedule) => selectedSchedule.id).includes(s.id))
      .map((s) => s.name);
    return schedule
      ? availableSchedules.filter((s) => s.toLowerCase().includes(schedule.toLowerCase()))
      : availableSchedules;
  }

  private disableScheduleFormField(): void {
    this.scheduleChipGridCtrl.disable({ emitEvent: false });
    this.scheduleInputCtrl.disable({ emitEvent: false });
  }

  private enableScheduleFormField(): void {
    this.scheduleChipGridCtrl.enable({ emitEvent: false });
    this.scheduleInputCtrl.enable({ emitEvent: false });
  }

  get submitBtnLabel(): string {
    return this.emailScheduleFormGroup.get('edit').value === true ? 'Update' : 'Save';
  }

  get selectedUsers(): User[] {
    return this.emailScheduleFormGroup.get('users').value;
  }

  get selectedSchedules(): IElmtScheduleDto[] {
    return this.emailScheduleFormGroup.get('schedules').value;
  }

  get isFormInvalid(): boolean {
    return this.emailScheduleFormGroup.invalid;
  }

  get isFormUntouched(): boolean {
    return this.emailScheduleFormGroup.untouched;
  }

  removeUser(user: User): void {
    const updatedUsers = this.selectedUsers.filter((u: User) => u.id !== user.id);
    this.emailScheduleFormGroup.patchValue({ users: updatedUsers });
    this.clearUserInput();
  }

  userSelected($event: MatAutocompleteSelectedEvent): void {
    const updatedUsers = [...this.selectedUsers, this.users.find((u) => u.name === $event.option.viewValue)];
    this.emailScheduleFormGroup.patchValue({ users: updatedUsers });
    this.userChipGridCtrl.setErrors(null);
    this.clearUserInput();
  }

  scheduleSelected($event: MatAutocompleteSelectedEvent): void {
    const updatedSchedules = [
      ...this.selectedSchedules,
      this.schedules.find((s) => s.name === $event.option.viewValue)
    ];
    this.emailScheduleFormGroup.patchValue({ schedules: updatedSchedules });
    this.scheduleChipGridCtrl.setErrors(null);
    this.clearScheduleInput();
  }

  removeSchedule(scheduleId: number): void {
    const updatedSchedules = this.selectedSchedules.filter((s) => s.id !== scheduleId);
    this.emailScheduleFormGroup.patchValue({ schedules: updatedSchedules });
    this.clearScheduleInput();
  }

  saveEmailSchedule(): void {
    const { id, reportName, sendDate, edit, scheduleRadio } = this.emailScheduleFormGroup.value;
    const formDate = new Date(sendDate);
    const currentDate = TimeUtils.adjustDateToTimezone(new Date(), this.building.timeZone);
    const combinedDate = new Date(
      formDate.getFullYear(),
      formDate.getMonth(),
      formDate.getDate(),
      currentDate.getHours(),
      currentDate.getMinutes(),
      currentDate.getSeconds()
    );
    const sendDateInUTC =
      Date.UTC(
        combinedDate.getFullYear(),
        combinedDate.getMonth(),
        combinedDate.getDate(),
        combinedDate.getHours(),
        combinedDate.getMinutes(),
        combinedDate.getSeconds()
      ) / 1000;
    const currentFormValue: ElmtEmailTableData = {
      id,
      reportName,
      sendDate: sendDateInUTC,
      userIds: this.selectedUsers.map((user) => user.id),
      scheduleIds: scheduleRadio === 'all' ? [] : this.selectedSchedules.map((schedule) => schedule.id),
      buildingId: this.building.id,
      allSchedules: scheduleRadio === 'all'
    };
    this.submitForm.emit({ form: currentFormValue, edit });
  }

  resetForm(): void {
    this.emailScheduleFormGroup.patchValue({
      id: null,
      reportName: '',
      users: [],
      sendDate: this.minSelectableDate,
      schedules: [],
      edit: false,
      scheduleRadio: 'all'
    });
    this.userInputCtrl.setValue(null);
    this.scheduleInputCtrl.setValue(null);
    this.emailScheduleFormGroup.markAsUntouched();
  }
}
