import * as angular from 'angular'; // Automatically added
import { EmergencyLightingTestState } from '@angularjs/or/data/EmergencyLightingTestState';
import { EmDriver } from '@angularjs/or/api/building/EmDriver';
import { SensorNode } from '@angularjs/or/api/building/SensorNode';
import { SensorNodeService } from '@angularjs/or/services/SensorNodeService';

export class OrFailedTestsCounterController {
  private emDrivers: EmDriver[];

  constructor(private nodeService: SensorNodeService) {}

  public $onInit(): void {
    this.nodeService.refreshNodes.subscribe((nodes) => {
      this.emDrivers = nodes.flatMap((node) => node.emDrivers);
    });
  }

  testsRun(): boolean {
    return angular.isArray(this.emDrivers)
      ? this.emDrivers.filter(
          (n) => n.functionalTestState != null || n.durationTestState != null
        ).length > 0
      : false;
  }

  allTestsPassed(): boolean {
    return this.allFunctionalTestsPassed() && this.allDurationTestsPassed();
  }

  allFunctionalTestsPassed(): boolean {
    return this.getFailedFunctionalTestsCount() == 0;
  }

  allDurationTestsPassed(): boolean {
    return this.getFailedDurationTestsCount() == 0;
  }

  getFailedFunctionalTestsCount(): number {
    return angular.isArray(this.emDrivers)
      ? this.emDrivers.filter(
          (n) =>
            n.functionalTestState != null &&
            (EmergencyLightingTestState.FAILED.equals(n.functionalTestState) ||
              EmergencyLightingTestState.TIMED_OUT.equals(
                n.functionalTestState
              ))
        ).length
      : 0;
  }

  getFailedDurationTestsCount(): number {
    return angular.isArray(this.emDrivers)
      ? this.emDrivers.filter(
          (n) =>
            n.durationTestState != null &&
            (EmergencyLightingTestState.FAILED.equals(n.durationTestState) ||
              EmergencyLightingTestState.TIMED_OUT.equals(n.durationTestState))
        ).length
      : 0;
  }

  getFailedTestsCount(): number {
    return (
      this.getFailedDurationTestsCount() + this.getFailedFunctionalTestsCount()
    );
  }

  getTotalFunctionalTestsCount(): number {
    return angular.isArray(this.emDrivers)
      ? this.emDrivers.filter(
          (n) =>
            n.functionalTestState != null &&
            !EmergencyLightingTestState.CANCELLED.equals(n.functionalTestState)
        ).length
      : 0;
  }

  getTotalDurationTestsCount(): number {
    return angular.isArray(this.emDrivers)
      ? this.emDrivers.filter(
          (n) =>
            n.durationTestState != null &&
            !EmergencyLightingTestState.CANCELLED.equals(n.durationTestState)
        ).length
      : 0;
  }

  getTotalNodesCount(): number {
    return this.getTotalNodes().length;
  }

  private getTotalNodes(): EmDriver[] {
    return angular.isArray(this.emDrivers) ? this.emDrivers : [];
  }
}
