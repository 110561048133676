<div class='task-form' [formGroup]='taskForm'>
  <div class='task-form-inline-picker' *ngFor='let parameter of task.inputParameters'>
    <mat-form-field
      *ngIf="parameter.type === 'URL'"
      class='task-form-parameter-text'
      appearance='fill'>
      <mat-label>{{ parameter.displayName }}</mat-label>
      <input [attr.data-cy]="'task-parameter-' + parameter.displayName"
             matInput [formControlName]='parameter.name'
             type='url'
             [required]='true'>
    </mat-form-field>
    <mat-form-field
      *ngIf="parameter.type === 'NUMBER'"
      appearance='fill'>
      <mat-label>{{ parameter.displayName }}</mat-label>
      <input [attr.data-cy]="'task-parameter-' + parameter.displayName"
             matInput [formControlName]='parameter.name'
             type='number'
             [required]='true'>
    </mat-form-field>
    <mat-form-field
      *ngIf="parameter.type === 'STRING'"
      class='task-form-parameter-text'
      appearance='fill'>
      <mat-label>{{ parameter.displayName }}</mat-label>
      <input [attr.data-cy]="'task-parameter-' + parameter.displayName"
             matInput [formControlName]='parameter.name'
             type='text'
             [required]='true'>
    </mat-form-field>
  </div>
</div>
