import { ISelectableContext } from '@angularjs/or/api/query/outline/context/IContext';
import { IObservableModifiable } from '@angularjs/or/util/IObservable';
import { IBuildingService } from '@angularjs/or/services/IBuildingService';
import { SensorNodeService } from '@angularjs/or/services/SensorNodeService';
import { UIRefreshService } from '@angularjs/or/services/UIRefreshService';
import { Map } from '@angularjs/or/util/Map';
import { SensorNode } from '@angularjs/or/api/building/SensorNode';
import { ArrayUtils } from '@angularjs/or/util/ArrayUtils';
import { ColorUtils } from '@angularjs/or/util/ColorUtils';

export class OrGatewayTagsController {
  private buildingId: number;
  public all: GatewayTag[] = [];
  public gatewayToNodesMap: Map<SensorNode[]> = {};

  constructor(
    private selectableContext: IObservableModifiable<ISelectableContext>,
    private buildingService: IBuildingService,
    private nodeService: SensorNodeService,
    private uiRefreshService: UIRefreshService,
    private $scope: ng.IScope
  ) {}

  public $onInit() {
    this.buildingService.getCurrentBuilding().then((building) => {
      this.$scope.$apply(() => {
        this.buildingId = building.id;
      });
    });
    this.nodeService
      .getGatewayToSensorNodesMap()
      .then((gatewayToNodesMap: Map<SensorNode[]>) => {
        this.gatewayToNodesMap = gatewayToNodesMap;
        let index = 0;
        for (const gatewayAddress in gatewayToNodesMap) {
          ArrayUtils.add(
            this.all,
            new GatewayTag(gatewayAddress, index, false),
            (tag1, tag2) => {
              return tag1.address === tag2.address;
            }
          );
          index++;
        }
      });
  }

  public getCheckoxClass(gatewayTag: GatewayTag): string {
    if (gatewayTag.isActive) {
      return 'or-checked';
    } else {
      return 'or-unchecked';
    }
  }

  public getTextColor(gatewayTag: GatewayTag): any {
    if (gatewayTag) {
      return {
        color: ColorUtils.getConstrastingGrey(
          ColorUtils.getNextColorInWheel(gatewayTag.indexInColorWheel)
        )
      };
    }
    return null;
  }

  public produceClassForGateway(gatewayTag: GatewayTag): {} {
    const styleClass = {};
    styleClass['or-active'] = gatewayTag.isActive;
    styleClass['or-bg-color-wheel-' + (gatewayTag.indexInColorWheel % 10)] =
      true;
    return styleClass;
  }

  public toggle(gatewayTag: GatewayTag) {
    gatewayTag.isActive = !gatewayTag.isActive;
    const nodes: number[] = this.gatewayToNodesMap[gatewayTag.address].map(
      (sensorNode) => sensorNode.id
    );
    this.selectableContext.change((ctx) => {
      const allNodes = ctx.sensorNodeIds;
      if (gatewayTag.isActive) {
        ArrayUtils.addAll(allNodes, nodes, (node1, node2) => node1 === node2);
      } else {
        ArrayUtils.removeAll(
          allNodes,
          nodes,
          (node1, node2) => node1 === node2
        );
      }
      ctx.sensorNodeIds = allNodes;
    });
  }
}

export class GatewayTag {
  constructor(
    public address: string,
    public indexInColorWheel: number,
    public isActive: boolean
  ) {}
}
