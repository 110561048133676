<form
  class="or-form or-form-ble-key-details"
  [formGroup]="keyForm"
  [ngClass]='{ "or-form-ble-key-details-enable-labels": index === 0 }'
  novalidate="novalidate"
  [attr.data-cy]="isNew ? 'new-ble-key-form' : 'existing-ble-key-form'"
>
  <ul class="or-form-items">
    <li class="or-form-item or-form-ble-key-details-sequence">
      <span class="or-label or-label-above" data-cy='label-sequence'>Seq.</span
      ><input
      class="or-input"
      maxlength="256"
      formControlName="sequence"
      placeholder="Seq."
      type="text"
      data-cy='input-ble-key-sequence'
    />
    </li>
    <li class="or-form-item or-form-ble-key-details-description">
      <span class="or-label or-label-above" data-cy='label-description'>Description</span
      ><input
      class="or-input"
      maxlength="256"
      formControlName="description"
      placeholder=""
      type="text"
      data-cy='input-ble-key-description'
    />
    </li>
    <li class="or-form-item or-form-ble-key-details-badge" *ngIf="!isNew">
      <span class="or-label or-label-above" data-cy='label-badge'> Updated Nodes/Total</span>
      <input
      class="or-input"
      maxlength="256"
      formControlName="badge"
      placeholder="N/A"
      type="text"
      data-cy='input-ble-key-badge'/>
    </li>
    <li class="or-form-item or-form-ble-key-details-actions" *ngIf="!isDeactivated">
      <ul class="or-inline-items">
        <li class="or-inline-item" *ngIf="isNew">
          <button
            mat-raised-button
            class="or-button or-iconic or-primary or-positive"
            type="button"
            (click)="createKey()"
            [disabled]="isBusy || keyForm.pristine || keyForm.invalid ? true : null"
            title="Generate BLE Key"
            data-cy='btn-create-ble-key'
          >
            <span class="or-icon or-small or-inverse or-icon-plus"></span>
          </button>
        </li>
        <li class="or-inline-item" *ngIf="!isNew">
          <button
            mat-raised-button
            class="or-button or-iconic or-primary or-positive"
            type="button"
            (click)="updateKey()"
            [disabled]="isBusy || keyForm.pristine || keyForm.invalid ? true : null"
            title="Update BLE Key"
            data-cy='btn-update-ble-key'
          >
            <span class="or-icon or-small or-inverse or-icon-checkmark"></span>
          </button>
        </li>
        <li class="or-inline-item">
          <button
            mat-raised-button
            class="or-button or-iconic"
            type="button"
            color='accent'
            (click)="resetKey()"
            [disabled]="isBusy || keyForm.pristine ? true : null"
            title="Reset Changes"
            data-cy='btn-reset-ble-key'
          >
            <span class="or-icon or-small or-icon-reload"></span>
          </button>
        </li>
        <li class="or-inline-item" *ngIf="!isNew">
          <button
            mat-raised-button
            class="or-button or-iconic or-primary"
            type="button"
            color='primary'
            (click)="sendKey()"
            [disabled]="isBusy ? true : null"
            title="Send BLE Key to Sensor Nodes"
            data-cy='btn-send-ble-key'
          >
            <span class="or-icon or-small or-inverse or-icon-send"></span>
          </button>
        </li>
      </ul>
    </li>
  </ul>
</form>
