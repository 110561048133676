import * as angular from 'angular'; // Automatically added
import { BuildingAuthorityType } from '@app/shared/models/building-authority-type';
import { FormRole } from '@angularjs/or/api/auth/Role';

export class OrFormRoleDetailsController {
  public isNew: boolean;
  public isBusy: boolean;
  public role;
  public form;
  public initialRole;
  public authorityMap = {};
  public authorityNameMap = {};
  public newRole: FormRole;
  public onAddRole;
  public onDeleteRole;
  public onUpdateRole;

  public $onInit() {
    this.backupInitialRole();
    for (const buildingAuthority of BuildingAuthorityType.all()) {
      this.authorityNameMap[buildingAuthority.toString()] =
        buildingAuthority.getName();
    }
  }

  produceAuthorityMap() {
    const authorityMap = {};
    const authority = BuildingAuthorityType;

    authorityMap[authority.ALL.toString()] = this.hasAuthority(
      authority.ALL.value
    );
    authorityMap[authority.VIEW.toString()] = this.hasAuthority(
      authority.VIEW.value
    );
    authorityMap[authority.ANALYTICS.toString()] = this.hasAuthority(
      authority.ANALYTICS.value
    );
    authorityMap[authority.HIGH_RESOLUTION_ANALYTICS.toString()] =
      this.hasAuthority(authority.HIGH_RESOLUTION_ANALYTICS.value);
    authorityMap[authority.CONTROL_LIGHTS.toString()] = this.hasAuthority(
      authority.CONTROL_LIGHTS.value
    );
    authorityMap[authority.MANAGE_NODES.toString()] = this.hasAuthority(
      authority.MANAGE_NODES.value
    );
    authorityMap[authority.MANAGE_FLOORS.toString()] = this.hasAuthority(
      authority.MANAGE_FLOORS.value
    );
    authorityMap[authority.MANAGE_GATEWAYS.toString()] = this.hasAuthority(
      authority.MANAGE_GATEWAYS.value
    );
    authorityMap[authority.MANAGE_BACNET_AREAS.toString()] = this.hasAuthority(
      authority.MANAGE_BACNET_AREAS.value
    );
    authorityMap[authority.MANAGE_BLUETOOTH_BEACONS.toString()] =
      this.hasAuthority(authority.MANAGE_BLUETOOTH_BEACONS.value);
    authorityMap[authority.MANAGE_LIGHTING_CONFIGURATION.toString()] =
      this.hasAuthority(authority.MANAGE_LIGHTING_CONFIGURATION.value);
    authorityMap[authority.MANAGE_TAGS.toString()] = this.hasAuthority(
      authority.MANAGE_TAGS.value
    );
    authorityMap[authority.MANAGE_TENANT.toString()] = this.hasAuthority(
      authority.MANAGE_TENANT.value
    );
    authorityMap[authority.MANAGE_USERS.toString()] = this.hasAuthority(
      authority.MANAGE_USERS.value
    );
    authorityMap[authority.MANAGE_ROLES.toString()] = this.hasAuthority(
      authority.MANAGE_ROLES.value
    );
    authorityMap[authority.MANAGE_BUILDING.toString()] = this.hasAuthority(
      authority.MANAGE_BUILDING.value
    );
    authorityMap[authority.MANAGE_SCHEDULES.toString()] = this.hasAuthority(
      authority.MANAGE_SCHEDULES.value
    );
    authorityMap[authority.MANAGE_EMERGENCY_LIGHTING.toString()] =
      this.hasAuthority(authority.MANAGE_EMERGENCY_LIGHTING.value);
    authorityMap[authority.MAINTENANCE.toString()] = this.hasAuthority(
      authority.MAINTENANCE.value
    );
    authorityMap[authority.MANAGE_CIRCADIAN_RHYTHM.toString()] =
      this.hasAuthority(authority.MANAGE_CIRCADIAN_RHYTHM.value);
    authorityMap[authority.MANAGE_MAINTENANCE_UPDATES.toString()] =
      this.hasAuthority(authority.MANAGE_MAINTENANCE_UPDATES.value);
    authorityMap[authority.MANAGE_DDL.toString()] = this.hasAuthority(
      authority.MANAGE_DDL.value
    );

    return authorityMap;
  }

  hasAuthority(authorityType: string): boolean {
    if (!angular.isObject(this.role)) {
      return;
    }
    const authorityTypes = this.role.authorityTypes;
    const hasAllAuthorityTypes =
      authorityTypes.indexOf(BuildingAuthorityType.ALL.toString()) >= 0;
    const hasThisAuthorityType = authorityTypes.indexOf(authorityType) >= 0;
    return hasThisAuthorityType || hasAllAuthorityTypes;
  }

  toggleAuthority(authorityType: BuildingAuthorityType) {
    if (!angular.isObject(this.role)) {
      return;
    }

    const authorityTypeIndex = this.role.authorityTypes.indexOf(authorityType);

    if (authorityType.toString() === BuildingAuthorityType.ALL.toString()) {
      this.role.authorityTypes.splice(0, this.role.authorityTypes.length);
    }

    if (authorityTypeIndex < 0) {
      this.role.authorityTypes.push(authorityType);
    } else if (
      authorityType.toString() !== BuildingAuthorityType.ALL.toString()
    ) {
      this.role.authorityTypes.splice(authorityTypeIndex, 1);
    }

    this.updateAuthorityMap();
    this.form.$setDirty();
  }

  backupInitialRole() {
    this.initialRole = angular.copy(this.role);
    this.updateAuthorityMap();
  }

  restoreInitialRole() {
    this.role = angular.copy(this.initialRole);
    this.updateAuthorityMap();
  }

  updateAuthorityMap() {
    this.authorityMap = this.produceAuthorityMap();
  }

  resetRole() {
    if (!confirm(`Reset role ${this.role.name}?`)) {
      return;
    }
    this.restoreInitialRole();
    this.resetFormValidationState();
  }

  resetFormValidationState() {
    this.form.$setPristine();
    this.form.$setUntouched();
  }

  addRole() {
    this.onAddRole();
    this.role.authorityTypes.splice(0, this.role.authorityTypes.length);
    this.updateAuthorityMap();
  }
}
