export class ObjectUtils {
  public static coalesce<T>(...items: T[]): T {
    const nonNullItems = items.filter((item) => item != null);

    if (nonNullItems.length > 0) {
      return nonNullItems[0];
    }

    return null;
  }
}
