<div class="or-floorplan-actions-tray">
  <div id="tray-container" data-cy="tray-container-div">
    <button
      [attr.data-cy]="'tray-toggle-' + trayButtonToolTip.toLowerCase().split(' ').join('-')"
      class="or-button"
      (click)="toggleTray()"
      (touchend)="toggleTray()"
      [ngClass]="{'or-active': (showTrayByClick && !disable)}"
      [disabled]="disable"
      [matTooltip]="trayButtonToolTip"
      [matTooltipPosition]="'left'"
    >
      <span class="or-icon or-small" [ngClass]="trayButtonIconClass"></span>
    </button>
    @if (!disable) {
      @for (button of trayButtons; track button.toolTip) {
        <div class="show-hide-tray"
             [ngClass]="(showTrayByClick) ? 'show-tray' : 'hide-tray'">
          <button
            class="or-button or-button-tray"
            [ngClass]="button.onActive?.() ? 'or-active' : ''"
            (click)="runButtonAction(button, $event)"
            (touchend)="runButtonAction(button, $event)"
            [attr.data-cy]="'tray-button-' + button.toolTip.toLowerCase().split(' ').join('-')"
            [matTooltip]="button.toolTip"
            [matTooltipPosition]="'below'"
            [disabled]="disableTrayButtons"
          >
            <span class="or-icon or-small" [ngClass]="button.iconClass"></span>
          </button>
        </div>
      }
    }
  </div>
</div>
