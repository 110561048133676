import { AuthMethod } from '@app/shared/models/auth-method';
import { SimpleService } from '../angular/SimpleService';

export class LoginMethodsService {
  constructor(
    private simpleService: SimpleService<AuthMethod[], any, any, any>,
    private url: string
  ) {}

  getSupportedLoginMethods(): Promise<AuthMethod[]> {
    return new Promise((resolve) => {
      this.simpleService.get(this.url).then((authMethodsJSON: AuthMethod[]) => {
        resolve(
          authMethodsJSON.map((authMethod) =>
            AuthMethod.fromString(authMethod.toString())
          )
        );
      });
    });
  }
}
