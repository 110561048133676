import {
  EmergencyLightGroup,
  EmergencyLightGroupOutline
} from './EmergencyLightGroup';
import { WeekdaySelection } from '../../util/WeekdaySelection';
import { FunctionalTest } from './FunctionalTest';
import { DurationTest } from './DurationTest';
import { EmergencyLightingTestScheduleRun } from './EmergencyLightingTestScheduleRun';

export enum SchedulingStrategy {
  ODDS_AND_EVENS = 'Odds and Evens',
  SIMULTANEOUS = 'Simultaneous'
}

export class EmergencyLightingTestSchedule {
  constructor(
    public id: number,
    public startTime: string,
    public endTime: string,
    public emergencyLightGroup: EmergencyLightGroup,
    public days: WeekdaySelection,
    public emergencyLightingTestScheduleRuns: EmergencyLightingTestScheduleRun[],
    public numberOfFunctionalTestsPerMonth: number,
    public numberOfDurationTestsPerYear: number,
    public schedulingStrategy: string,
    public isEnabled: boolean,
    public isArchived: boolean,
    public startDate?: string
  ) {}
}

export class EmergencyLightingTestScheduleOutline {
  constructor(
    public id: number,
    public group: EmergencyLightGroupOutline,
    public isEnabled: boolean,
    public days: WeekdaySelection = new WeekdaySelection(),
    public startTime: string,
    public endTime: string,
    public functionalTest: FunctionalTest,
    public durationTest: DurationTest,
    public schedulingStrategy: string,
    public isArchived: boolean,
    public startDate?: string
  ) {}
}
