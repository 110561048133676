import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, delay, Observable, of } from 'rxjs';
import { ElmtEmailDataService } from '@services/emergency-lighting/elmt-email-data.service';
import { Filter } from './filter.interface';

export interface ElmtEmailTableData {
  id?: number;
  buildingId: number;
  reportName: string;
  sendDate: Date | number;
  userIds: number[];
  scheduleIds: number[];
  allSchedules: boolean;
}

export abstract class AbstractElmtEmailTableDatasource implements DataSource<ElmtEmailTableData> {
  protected emailDataSubject = new BehaviorSubject<ElmtEmailTableData[]>([]);
  protected loadingSubject = new BehaviorSubject(false);
  protected countSubject = new BehaviorSubject(0);

  public counter$ = this.countSubject.asObservable();

  get isLoading$(): Observable<boolean> {
    return this.loadingSubject.asObservable();
  }

  get hasData(): boolean {
    return this.countSubject.value > 0;
  }

  abstract loadEmailData(buildingId: number, filter: Filter): void;

  connect(_collectionViewer: CollectionViewer): Observable<readonly ElmtEmailTableData[]> {
    return this.emailDataSubject.asObservable();
  }

  disconnect(_collectionViewer: CollectionViewer): void {
    this.emailDataSubject.next([]);
    this.countSubject.next(0);
  }
}

export class ElmtEmailTableDatasource extends AbstractElmtEmailTableDatasource {
  constructor(private readonly emailService: ElmtEmailDataService) {
    super();
  }

  loadEmailData(buildingId: number, filter: Filter): void {
    this.loadingSubject.next(true);
    this.emailService.getScheduledEmailsForBuilding(buildingId, filter).subscribe((emailData) => {
      this.emailDataSubject.next(emailData.content);
      this.loadingSubject.next(false);
      this.countSubject.next(emailData.totalElements);
    });
  }
}

export class MockElmtEmailTableDatasource extends AbstractElmtEmailTableDatasource {
  private MIN_DELAY_FOR_LOADING_SPINNER = 900;
  private generateEmailData(n: number): ElmtEmailTableData[] {
    const emailData = [];
    const start = new Date(2024, 0, 1).getTime();
    const end = new Date(2025, 0, 1).getTime();
    for (let i = 0; i < n; i++) {
      const randomDate = new Date(start + Math.random() * (end - start));
      emailData.push({
        reportName: `Report ${i}`,
        sendDate: randomDate,
        users: [`User ${i}`],
        schedules: [`Schedule ${i}`]
      });
    }
    return emailData;
  }

  loadEmailData(buildingId: number): void {
    this.loadingSubject.next(true);
    const delayedObservable = of(this.generateEmailData(20)).pipe(delay(this.MIN_DELAY_FOR_LOADING_SPINNER));
    delayedObservable.subscribe((emailData) => {
      this.emailDataSubject.next(emailData);
      this.loadingSubject.next(false);
      this.countSubject.next(emailData.length);
    });
  }
}
