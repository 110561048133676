<div class="administration-layout-container">
  <div class="administration-header-container">
    <ul>
      <li>
        <app-tabs [tabs$]="tabs$"></app-tabs>
      </li>
    </ul>
  </div>
  <div class="administration-content-container">
    <router-outlet></router-outlet>
  </div>
</div>