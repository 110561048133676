import { Component, OnInit } from '@angular/core';
import { NavigationService } from '@app/shared/services/navigation/navigation.service';
import { NavigationSection } from '@app/shared/services/navigation/navigation-section';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {
  public menuItems: NavigationSection[] = [];

  constructor(private navigationService: NavigationService) {}

  ngOnInit(): void {
    this.menuItems = this.navigationService.getSiteMenu();
  }
}
