import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { IEmergencyLightingSchedule } from '@app/shared/models/emergency-lighting-schedule.interface';
import { EmergencyLightingSchedule } from '@app/shared/models/emergency-lighting-schedule';
import { EmergencyLightingScheduleService } from '@services/emergency-lighting/emergency-lighting-schedule.service';
import { IEmergencyLightingScheduleForm } from '@app/shared/models/emergency-lighting-schedule-form.interface';

export function scheduleValidator(
  scheduleService: EmergencyLightingScheduleService,
  details: IEmergencyLightingSchedule
): ValidatorFn {
  return (form: FormGroup<IEmergencyLightingScheduleForm>): ValidationErrors | null => {
    const formValue = form.value;

    const currentBuildingSchedules = scheduleService.currentBuildingSchedules;
    const isValidName = validateName(formValue, details, currentBuildingSchedules);
    return isValidName ? {} : { uniqueName: 'Schedule Name must be unique for a building' };
  };
}

function validateName(
  newScheduleForm: { name?: string },
  details: IEmergencyLightingSchedule,
  currentBuildingSchedules: EmergencyLightingSchedule[]
): boolean {
  return (
    currentBuildingSchedules.find(
      (existingSchedule) =>
        existingSchedule.id !== details.id &&
        existingSchedule.name?.trim().toLowerCase() === newScheduleForm.name?.trim().toLowerCase()
    ) == null
  );
}
