import { WeekdaySelection } from '@angularjs/or/util/WeekdaySelection';
import { Tag } from '@angularjs/or/api/building/Tag';
import { Floor } from '@angularjs/or/api/building/Floor';

export class CircadianCurve {
  constructor(
    public name?: string,
    public colourTempValues: number[] = CircadianCurve.STANDARD_CURVE
      .colourTempValues,
    public lightLevelValues: number[] = CircadianCurve.STANDARD_CURVE
      .lightLevelValues,
    public active: boolean = false,
    public enabled: boolean = true,
    public id?: number,
    public buildingId?: number,
    public tags: Tag[] = [],
    public floors: Floor[] = []
  ) {}

  public static STANDARD_CURVE = new CircadianCurve(
    'Standard Curve',
    [
      2700, 2700, 2700, 2700, 2700, 2700, 4000, 6000, 5500, 5500, 5000, 4500,
      4000, 4000, 4000, 4000, 4000, 4000, 3000, 2700, 2700, 2700, 2700, 2700
    ],
    [
      50, 50, 50, 50, 50, 50, 200, 650, 650, 650, 650, 650, 400, 200, 200, 200,
      200, 200, 100, 50, 50, 50, 50, 50
    ]
  );

  public static clone(other: CircadianCurve): CircadianCurve {
    return new CircadianCurve(
      other.name,
      other.colourTempValues,
      other.lightLevelValues,
      other.active,
      other.enabled,
      other.id,
      other.buildingId,
      other.tags,
      other.floors
    );
  }
}
