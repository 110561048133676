import { OrFormUserDetailsController } from './OrFormUserDetailsController';

export class OrFormUserDetailsDirective {
  constructor() {
    return {
      bindToController: true,
      controller: ['$scope', 'BuildingService', OrFormUserDetailsController],
      controllerAs: 'userDetails',
      replace: true,
      restrict: 'E',
      scope: {
        isNew: '=',
        isBusy: '=',
        isDisabled: '=?',
        details: '=?',
        roles: '=?',
        onDeleteUser: '&'
      },
      template: require('raw-loader!./or-form-user-details.html').default
    };
  }
}
