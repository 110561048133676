<div class="invite-users-dialog">

  <h2 mat-dialog-title>{{ title }}</h2>

  <mat-dialog-content>
    <form [formGroup]="inviteUserForm" autocomplete="off" data-cy="invite-user-form">
      <mat-form-field *ngIf='mode === 1'>
        <mat-label>Email Address</mat-label>
        <input matInput formControlName="emailAddress">
        <mat-error *ngIf="inviteUserForm.controls['emailAddress']?.errors?.email">
          Please enter a valid email address
        </mat-error>
        <mat-error *ngIf="inviteUserForm.controls['emailAddress']?.errors?.required">
          Email address is required
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Role(s)</mat-label>
        <mat-select id='roleIds' formControlName="roleIds" data-cy="user-invitation-role-ids" multiple>
          <mat-option disabled>--- Choose role(s) ---</mat-option>
          <mat-option *ngFor="let role of allRoles" [value]="role.id">
            {{ role.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="inviteUserForm.controls['roleIds']?.errors?.emptyArray">
          Please select a role from the list
        </mat-error>
      </mat-form-field>

      <mat-form-field *ngIf='mode === 1 && autoSelectTenant == null'>
        <mat-label>Tenant</mat-label>
        <mat-select id='tenantId' formControlName="tenantId" data-cy="user-invitation-tenant-id">
          <mat-option>--- Choose a tenant ---</mat-option>
          <mat-option *ngFor="let tenant of tenants" [value]="tenant.id">
            {{ tenant.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-raised-button (click)="close()">Close</button>
    <button mat-raised-button color="primary" (click)="save()">Save</button>
  </mat-dialog-actions>

</div>
