import * as angular from 'angular'; // Automatically added
import { TagService } from '@angularjs/or/services/TagService';
import { Tag } from '@angularjs/or/api/building/Tag';
import { Observable } from '@angularjs/or/util/Observable';
import { ArrayUtils } from '@angularjs/or/util/ArrayUtils';
import { EmergencyLightGroup } from '@angularjs/or/api/building/EmergencyLightGroup';
import { ObservableBool } from '@angularjs/or/util/ObservableBool';
import { SensorNodeService } from '@angularjs/or/services/SensorNodeService';
import { Action } from '@angularjs/or/delegates/Delegates';

export class OrLightGroupListController {
  public activeGroups: EmergencyLightGroup[];
  public archivedGroups: EmergencyLightGroup[];
  public tags: Tag[];
  public floors;
  public showArchived = false;
  public isFormActive = false;
  public isNewGroup = false;
  public isBusy = false;
  public onCreateGroup;
  public onArchiveGroup;
  public onUpdateGroup;
  public onHighlightGroup;
  public onClearHighlighting: Action;
  public selectedGroups: EmergencyLightGroup[] = [];
  public selectedGroup: EmergencyLightGroup;
  public selectedGroupOriginal;
  public activeTag;
  public activeFloor;
  public form;
  public isMultiselectModeActive;
  public showArchivedObservable: Observable<ObservableBool>;

  private container;

  constructor(
    private $timeout,
    private $element,
    private tagService: TagService,
    private nodesService: SensorNodeService
  ) {}

  public $onInit() {
    this.container = this.$element[0];
    if (!this.container) {
      console.warn('OrLightGroupListController: failed to find self');
    }
  }

  public isValidGroupProvided() {
    return (
      this.form.groupName.$valid &&
      this.selectedGroup.tagIds &&
      this.selectedGroup.tagIds.length > 0
    );
  }

  private deselectGroup() {
    this.selectedGroup.isActive = false;
    this.activeGroups.forEach((group) => {
      group.isActive = false;
    });
    this.archivedGroups.forEach((group) => {
      group.isActive = false;
    });
  }

  private isGroupSelected(group: EmergencyLightGroup): boolean {
    if (this.selectedGroup?.isActive === true) {
      // when page is first loaded there will be no selectedGroup
      return (
        this.selectedGroup.id !== undefined &&
        this.selectedGroup.id === group.id
      );
    }
    return false;
  }

  public highlightGroup(group) {
    if (this.isMultiselectModeActive) {
      this.toggle(group);
      this.$timeout(this.onHighlightGroup);
    } else {
      if (this.isGroupSelected(group)) {
        this.deselectGroup();
        this.$timeout(this.onClearHighlighting);
      } else {
        this.selectedGroup = group;
        this.selectedGroup.isActive = true;
        this.activeGroups.forEach((group) => {
          group.isActive = group.id === this.selectedGroup.id;
        });
        this.archivedGroups.forEach((group) => {
          group.isActive = group.id === this.selectedGroup.id;
        });
        this.$timeout(this.onHighlightGroup);
      }
    }
  }

  public removeFloor(floor) {
    if (!floor) {
      return;
    }
    // this.selectedGroup.floorId = this.selectedGroup.floorIds.filter(_floorId => {
    //     return _floorId !== floor.id;
    // });
    this.updateAvailableFloors();
  }

  public removeTag(tag) {
    if (!tag) {
      return;
    }
    this.selectedGroup.tagIds = this.selectedGroup.tagIds.filter((_tagId) => {
      return _tagId !== tag.id;
    });
    this.updateAvailableTags();
    this.highlightGroup(this.selectedGroup);
  }

  public decorateTag(tag) {
    if (!tag) {
      return;
    }
    if (!tag.color) {
      return;
    }
    return this.tagService.decorateTag(tag);
  }

  public saveGroup() {
    this.nodesService
      .getNumberOfEmergencyNodes(this.selectedGroup)
      .then((numberOfEmergencyNodes) => {
        if (numberOfEmergencyNodes == 0) {
          alert('There are no emergency luminaires in this group!');
        } else {
          const callback = this.isNewGroup
            ? this.onCreateGroup
            : this.onUpdateGroup;
          this.$timeout(callback);
          this.toggleForm(false);
        }
      });
  }

  public editGroup(isNewGroup, group) {
    this.isNewGroup = !!isNewGroup;
    if (this.isNewGroup) {
      group = {};
      group.tagIds = [];
      group.floorIds = [];
      group.tags = [];
    }
    this.selectedGroup = angular.copy(group);
    this.selectedGroupOriginal = angular.copy(group);
    this.updateAvailableTags();
    this.updateAvailableFloors();
    this.highlightGroup(this.selectedGroup);
    this.toggleForm(true);
  }

  public cloneGroup(group) {
    this.isNewGroup = true;
    this.selectedGroup = angular.copy(group);
    this.selectedGroupOriginal = angular.copy(group);
    this.selectedGroup.name += ' Copy';
    this.updateAvailableTags();
    this.updateAvailableFloors();
    this.highlightGroup(this.selectedGroup);
    this.toggleForm(true);
  }

  private resetActiveFloor() {
    this.activeFloor = undefined;
  }

  private resetActiveTag() {
    this.activeTag = undefined;
  }

  public discardGroupChanges() {
    this.selectedGroup = this.selectedGroupOriginal;
    this.highlightGroup(this.selectedGroup);
    this.toggleForm(false);
  }

  public toggleForm(isActive) {
    this.isFormActive = !!isActive;
    if (this.isFormActive) {
      this.focusAtGroupNameField();
    }
  }

  private focusAtGroupNameField() {
    this.$timeout((_) => {
      const groupNameInput = this.container.querySelector('[name="groupName"]');
      if (!groupNameInput) {
        return;
      }
      groupNameInput.select();
    });
  }

  public addTag(tag) {
    if (!tag) {
      return;
    }
    this.selectedGroup.tagIds.push(tag.id);
    this.updateAvailableTags();
    this.highlightGroup(this.selectedGroup);
  }

  public addFloor(floor) {
    if (!floor) {
      return;
    }
    this.selectedGroup.floorId = floor.id;
    this.updateAvailableFloors();
    this.resetActiveFloor();
  }

  public updateAvailableTags() {
    this.tags.forEach((tag) => {
      tag.isAvailable = !ArrayUtils.contains(this.selectedGroup.tagIds, tag.id);
    });
    this.resetActiveTag();
  }

  public updateAvailableFloors() {
    this.floors.forEach((floor) => {
      floor.isAvailable = this.selectedGroup.floorId === floor.id;
    });
    this.resetActiveFloor();
  }

  public archiveGroup(group) {
    if (!group) {
      console.warn('Nothing to archive, no group active');
      return;
    }
    if (!angular.isFunction(this.onArchiveGroup)) {
      console.warn('onArchiveGroup callback is missing');
      return;
    }
    this.selectedGroup = group;
    this.$timeout(this.onArchiveGroup);
  }

  public toggle(group) {
    group.isActive = !group.isActive;
    if (group.isActive) {
      this.selectedGroups.push(group);
    } else {
      const index = this.selectedGroups.indexOf(group);
      this.selectedGroups.splice(index, 1);
    }
  }

  public toggleShowArchived() {
    if (this.showArchivedObservable) {
      this.showArchivedObservable.change((val) =>
        val.setBool(this.showArchived)
      );
    }
  }

  tagsForGroup(group: EmergencyLightGroup): Tag[] {
    return group.tagIds.map(
      (tagId) => this.tags.filter((tag) => tag.id == tagId)[0]
    );
  }
}
