import { CopyToClipboardService } from '@angularjs/or/angular/services/CopyToClipboardService';

export class CopyToClipboardDirective {
  constructor(copyToClipboardService: CopyToClipboardService) {
    return {
      restrict: 'A',
      link(scope, element, attrs) {
        element.bind('click', () => {
          copyToClipboardService.copy(attrs.copyToClipboard);
        });
      }
    };
  }
}
