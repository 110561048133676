<nav mat-tab-nav-bar class="tellus-mat-tab" [tabPanel]="tabPanel">
  <a mat-tab-link *ngFor="let tab of tabs$ | async"
     [routerLink]="tab.path"
     routerLinkActive #rla="routerLinkActive"
     [isFeatureAvailable]="tab.name"
     [active]="rla.isActive" [attr.data-cy]='tab.dataCy'>
    {{ tab.name }}
  </a>
</nav>
<mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
