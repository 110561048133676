export class SensorNodeStatus {
  constructor(public value: SensorNodeStatus_) {}

  public static readonly DISCONNECTED = new SensorNodeStatus('DISCONNECTED');
  public static readonly FAULTY = new SensorNodeStatus('FAULTY');
  public static readonly OK = new SensorNodeStatus('OK');
  public static readonly UNKNOWN = new SensorNodeStatus('UNKNOWN');

  public static all(): SensorNodeStatus[] {
    return [SensorNodeStatus.DISCONNECTED, SensorNodeStatus.FAULTY, SensorNodeStatus.OK, SensorNodeStatus.UNKNOWN];
  }

  public toString(): SensorNodeStatus_ {
    return this.value;
  }
}

type SensorNodeStatus_ = 'DISCONNECTED' | 'FAULTY' | 'OK' | 'UNKNOWN';
