import angular from 'angular';

export class HttpInterceptor {
  public static Factory() {
    return new HttpInterceptor();
  }

  public request(config: angular.IRequestConfig) {
    config.withCredentials = true;
    return config;
  }

  public response(response) {
    const token = response.headers('X-AUTH-TOKEN');
    if (token !== null) {
      const expireDays = 1;
      const d: Date = new Date();
      d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
      const expires: string = 'expires=' + d.toUTCString();
      document.cookie = 'XSRF-TOKEN=' + token + '; ' + expires + '; path=/';
    }
    return response;
  }
}
