import { Building } from '@angularjs/or/api/building/Building';
import { MultiselectOption } from '../or-multiselect/MultiselectOption';
import { LampTypeService } from '@angularjs/or/services/LampTypeService';
import { LuminaireManufacturer } from '@angularjs/or/api/building/LuminaireManufacturer';
import { SecurityService } from '@angularjs/or/angular/services/SecurityService';
import { GlobalAuthority } from '@app/shared/models/global-authority';
import IFormController = angular.IFormController;
import IScope = angular.IScope;

export class OrFormBuildingDetailsController {
  private options: MultiselectOption<LuminaireManufacturer>[];

  public isNew: boolean;
  public details: Building;
  public form: IFormController;
  public onSubmit;
  public onReset;
  public thumbnailImage: string;
  public imageUrl: string;
  public values = [];
  public manufacturerComparator = (a, b) => a.id == b.id;

  constructor(
    private $scope: IScope,
    private lampTypeService: LampTypeService,
    private thumbnailUrlGenerator: (building: Building) => string,
    private securityService: SecurityService
  ) {}

  public $onInit() {
    this.$scope.$watch('buildingDetails.details', () => {
      if (this.details != null) {
        this.thumbnailImage = this.details.thumbnailImage;
        this.imageUrl = this.thumbnailUrlGenerator(this.details);
      }
    });
    this.securityService
      .isAuthorized(GlobalAuthority.MANAGE_LUMINAIRE_MANUFACTURERS.toString())
      .then((isAuthorised) => {
        if (isAuthorised) {
          this.lampTypeService
            .getLuminaireManufacturers()
            .then((manufacturers) => {
              this.$scope.$apply(() => {
                this.options = manufacturers.map(
                  (m) => new MultiselectOption(m.name, m)
                );
              });
            });
        }
      });
  }

  public onImageUpload(thumbnailImage: string) {
    this.form.$setDirty();
    this.details.thumbnailImage = thumbnailImage;
  }

  public submit() {
    this.onSubmit();
  }

  public reset() {
    this.form.$setPristine();
    this.form.$setUntouched();
    this.onReset();
  }
}
