import { Component, Input, OnInit } from '@angular/core';
import { Address, Building } from '@app/shared/models/building.interface';
import { Floor } from '@app/shared/models/floor.interface';
import { IDropdown } from '@app/shared/models/dropdown.interface';
import { User } from '@app/shared/models/user.interface';
import { BuildingMetadataService } from '@app/shared/services/building-metadata/building-metadata.service';
import { DropdownService } from '@app/shared/services/dropdown.service';
import { NavigationService } from '@app/shared/services/navigation/navigation.service';
import { UserService } from '@app/shared/services/user/user.service';
import { BehaviorSubject, concatMap, Observable, of } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-buildings-menu',
  templateUrl: './buildings-menu.component.html',
  styleUrls: ['../dropdown/dropdown.scss', './buildings-menu.component.scss']
})
export class BuildingsMenuComponent implements IDropdown, OnInit {
  @Input() public user$: Observable<User>;
  @Input() public buildings$: Observable<Building[]>;

  public isActive$ = new BehaviorSubject<boolean>(false);
  public currentBuilding$: Observable<Building>;
  private user: User;

  constructor(
    private userService: UserService,
    private building: BuildingMetadataService,
    private navigationService: NavigationService,
    private dropdownService: DropdownService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.dropdownService.add(this);
    this.currentBuilding$ = this.route.params.pipe(
      concatMap((params) => {
        const { buildingId } = params;
        return buildingId ? this.userService.getBuilding(buildingId) : of(null);
      })
    );
    this.user$ = this.userService.getCurrentUser();
    this.user$.subscribe((u) => (this.user = u));
  }

  navigateToBuilding(building: Building, floor: Floor): void {
    this.toggle();
    this.navigationService.buildingId = building?.id;
    this.navigationService.floorId = floor?.id;
    this.navigationService.navigateToActiveSection(this.user, building?.id, floor?.id);
  }

  navigateToBuildingAdmin(building: Building): void {
    this.toggle();
    this.navigationService.buildingId = building.id;
    this.navigationService.floorId = building.floors[0] ? building.floors[0].id : null;
    this.navigationService.navigateToSection(this.navigationService.getSectionById('building'), building.id);
  }

  produceBuildingThumbnailImageStyle(building: Building): any {
    // console.log(building);
    const url = building ? this.building.thumbnailUrl(building) : undefined;
    // url = this.imageCachingService.updateUrl(url);
    return { 'background-image': url ? 'url(' + url + ')' : 'none' };
  }

  public getStringFromAddress(address: Address): string {
    const fields = [];
    let addressText = '';
    if (address.addressLine1) {
      fields.push(address.addressLine1);
    }
    if (address.addressLine2) {
      fields.push(address.addressLine2);
    }
    if (address.addressLine3) {
      fields.push(address.addressLine3);
    }
    if (address.postcode) {
      fields.push(address.postcode);
    }
    if (address.country) {
      fields.push(address.country);
    }

    for (const i in fields) {
      addressText += fields[i];
      if ((i as any as number) < fields.length - 1) {
        addressText += ' ';
      }
    }

    return addressText;
  }

  toggle(): void {
    this.isActive$.next(!this.isActive$.getValue());
  }

  isActive(): Observable<boolean> {
    return this.isActive$;
  }

  collapse(): void {}

  collapseWithApply(): void {
    // this.zone.run(() => {
    this.collapse();
    // });
  }

  getDropdownName(): string {
    return 'home';
  }

  setActive(state: boolean = true): void {
    this.isActive$.next(state);
  }
}
