<div id='orGlobalAdministrationPacketStreamer'>
  <mat-expansion-panel>
    <mat-expansion-panel-header>Filters</mat-expansion-panel-header>
    <mat-radio-group class='example-radio-group' aria-label="Select an option">
      <mat-radio-button (change)='selectedTrafficType($event)' class='example-radio-button' value="inbound">Inbound</mat-radio-button>
      <mat-radio-button (change)='selectedTrafficType($event)' class='example-radio-button' value="outbound">Outbound</mat-radio-button>
    </mat-radio-group>
    @if (selectedTopic === '/topic/inbound') {
      <mat-form-field class='example-chip-list' appearance='fill'>
        <mat-label>Packet type(s)</mat-label>
        <mat-chip-grid #packetTypeChipList aria-label='Packet type(s)'>
          @for (packetType of packetTypes; track $index) {
            <mat-chip-row
              (removed)='removePacketType(packetType)'>
              {{packetType}}
              <button matChipRemove>
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
          }
          <input
            placeholder='Add packet type...'
            #packetTypeInput
            [formControl]='packetTypeCtrl'
            [matAutocomplete]='packetTypeAuto'
            [matChipInputFor]='packetTypeChipList'
            [matChipInputSeparatorKeyCodes]='separatorKeysCodes'
            (matChipInputTokenEnd)='addPacketType($event)'>
        </mat-chip-grid>
        <mat-autocomplete #packetTypeAuto='matAutocomplete' (optionSelected)='selectedPacketType($event)'>
          @for (packetType of filteredPacketTypes$ | async; track $index) {
            <mat-option [value]='packetType'>
              {{packetType}}
            </mat-option>
          }
        </mat-autocomplete>
      </mat-form-field>
    }
    <mat-form-field class='example-chip-list' appearance='fill'>
      <mat-label>Building</mat-label>
      <mat-select (selectionChange)="selectBuildingId($event)">
        @for (buildingWithNameAndId of allBuildingIdsWithNames$ | async; track $index) {
          <mat-option [value]='buildingWithNameAndId.id'>
            {{buildingWithNameAndId.name}} [{{buildingWithNameAndId.id}}]
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field class='example-chip-list' appearance='fill'>
      <mat-label>Floor</mat-label>
      <mat-select (selectionChange)="selectFloorId($event)">
        @for (floor of allFloors$ | async; track $index) {
          <mat-option [value]='floor.id'>
            {{floor.floorNumber}} - {{floor.name}} [id={{floor.id}}]
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field class='example-chip-list' appearance='fill'>
      <mat-label>Gateway address(es)</mat-label>
      <mat-chip-grid #gatewayAddressesChipList aria-label='Gateway address(es)'>
        @for (gateway of selectedGateways; track $index) {
          <mat-chip-row
            (removed)='removeGatewayAddress(gateway)'>
            {{gateway.name}} [{{gateway.address}}]
            <button matChipRemove>
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>
        }
        <input
          placeholder='Add gateway address...'
          #gatewayAddressesInput
          [formControl]='gatewayAddressesCtrl'
          [matAutocomplete]='gatewayAddressesAuto'
          [matChipInputFor]='gatewayAddressesChipList'
          [matChipInputSeparatorKeyCodes]='separatorKeysCodes'
          (matChipInputTokenEnd)='addGatewayAddress($event)'>
      </mat-chip-grid>
      <mat-autocomplete #gatewayAddressesAuto='matAutocomplete' (optionSelected)='selectGatewayAddress($event)'>
        @for (gateway of allGateways; track $index) {
          <mat-option [value]='gateway'>
            {{gateway.name}} [{{gateway.address}}]
          </mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field class='example-chip-list' appearance='fill'>
      <mat-label>Sensor Node address(es)</mat-label>
      <mat-chip-grid #sensorNodeAddressesChipList aria-label='Sensor Node Addresses'>
        @for (sensorNode of selectedSensorNodeAddresses; track $index) {
          <mat-chip-row
            (removed)='removeSensorNodeAddress(sensorNode)'>
            {{sensorNode.address}} - {{sensorNode.address?.toString(16)}} [id: {{sensorNode.id}}]
            <button matChipRemove>
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>
        }
        <input
          placeholder='Add sensor node address...'
          #sensorNodeAddressesInput
          [formControl]='sensorNodeAddressesCtrl'
          [matAutocomplete]='sensorNodeAddressesAuto'
          [matChipInputFor]='sensorNodeAddressesChipList'
          [matChipInputSeparatorKeyCodes]='separatorKeysCodes'
          (matChipInputTokenEnd)='addSensorNodeAddress($event)'>
      </mat-chip-grid>
      <mat-autocomplete #sensorNodeAddressesAuto='matAutocomplete' (optionSelected)='selectSensorNodeAddress($event)'>
        @for (sensorNode of allSensorNodes$ | async; track $index) {
          <mat-option [value]='sensorNode'>
            {{sensorNode.address}} - {{sensorNode.address?.toString(16)}} [id: {{sensorNode.id}}]
          </mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>
  </mat-expansion-panel>
  <div class='example-action-buttons'>
    <button mat-button (click)='start()'>Start</button>
    <button mat-button (click)='stop()'>Stop</button>
    <button mat-button (click)='clear()'>Clear</button>
<!--    <button mat-button (click)='accordion.openAll()'>Toggle AutoScroll</button>-->
    <button mat-button (click)='accordion.openAll()'>Expand All</button>
    <button mat-button (click)='accordion.closeAll()'>Collapse All</button>
<!--    <button mat-button (click)='clear()'>Export</button>-->
  </div>
  <div class="overflow-y-auto h-[30vh]">
    <mat-accordion class='example-headers-align' multi="true">
      @if (selectedTopic === '/topic/inbound') {
        @for (item of inboundByPayload | keyvalue; track $index) {
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ item.key }}
              </mat-panel-title>
              <mat-panel-description>
                {{ item.value.length }} packets
              </mat-panel-description>
            </mat-expansion-panel-header>
            @for (packet of item.value; track $index) {
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{ packet.timestamp }}
                  </mat-panel-title>
                  <mat-panel-description>
                    {{ packet['@class'].split('.').pop() }}
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <code class="block whitespace-pre overflow-x-scroll">
                  <pre>{{ packet | json }}</pre>
                </code>
              </mat-expansion-panel>
            }
          </mat-expansion-panel>
        }
      }
      @if (selectedTopic === '/topic/outbound') {
        @for (item of outboundByGateway | keyvalue; track $index) {
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ item.value.timestamp }}
              </mat-panel-title>
              <mat-panel-description>
                <p class="font-bold">Gateway: {{ item.key }}</p>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <p class="italic font-bold">Version: {{ item.value.gatewayInfo.versionNumber }}</p>
            <p class="italic font-bold">N/w Type: {{ item.value.gatewayInfo.networkType }}</p>
            <p class="italic font-bold">Messages:</p>
            <code class="block whitespace-pre overflow-x-scroll">
              <pre>{{ item.value.messages | json }}</pre>
            </code>
          </mat-expansion-panel>
        }
      }
    </mat-accordion>
    @if (loading$ | async) {
      <div class="spinner-container">
        <mat-spinner style="margin:0 auto;" diameter="40" mode="indeterminate"></mat-spinner>
      </div>
    }
  </div>
</div>
