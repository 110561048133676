import { Resource } from '../Resource';
import { UserRole } from '../../api/auth/UserRole';
import { IUserRoleResource } from '../../api/IUserRoleResource';
import { PromiseRejectionHandler } from '../../promises/PromiseRejectionHandler';
import { PromiseUtils } from '../promises/PromiseUtils';

export class UserRoleResource
  extends Resource<UserRole, number>
  implements IUserRoleResource
{
  constructor(
    $http: ng.IHttpService,
    baseUrl: string,
    rejectionHandler: PromiseRejectionHandler
  ) {
    super($http, baseUrl, rejectionHandler);
  }

  public getAllForBuilding(buildingId: number): Promise<UserRole[]> {
    return PromiseUtils.wrapSingle(
      this.$http.get(this.baseUrl + '/' + buildingId),
      this.rejectionHandler
    );
  }

  public removeUserFromBuilding(
    buildingId: number,
    userId: number
  ): Promise<{}> {
    return PromiseUtils.wrapSingle(
      this.$http.delete(this.baseUrl + '/' + buildingId + '/' + userId),
      this.rejectionHandler
    );
  }

  public updateUserRoles(buildingId: number, userRoles: UserRole): Promise<{}> {
    return PromiseUtils.wrapSingle(
      this.$http.put(this.baseUrl + '/' + buildingId, userRoles),
      this.rejectionHandler
    );
  }
}
