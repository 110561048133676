export class ObservableBool {
  private bool: boolean;

  constructor(bool: boolean) {
    this.bool = bool;
  }

  getBool(): boolean {
    return this.bool;
  }

  setBool(bool: boolean): void {
    this.bool = bool;
  }

  toggle() {
    this.bool = !this.bool;
  }
}
