import { OrFloorplanActionsTrayController } from './OrFloorplanActionsTrayController';

export class OrFloorplanActionsTrayDirective {
  constructor() {
    return {
      bindToController: true,
      controller: ['$scope', OrFloorplanActionsTrayController],
      controllerAs: 'actionsTray',
      replace: true,
      restrict: 'E',
      scope: {
        trayButtons: '=',
        showToolTipAbove: '=',
        trayButtonIconClass: '=',
        trayButtonToolTip: '@?',
        onClick: '&?',
        disable: '=?',
        label: '@?',
        overrideShowTray: '=?',
        buildingId: '='
      },
      template: require('raw-loader!./or-floorplan-actions-tray.html').default
    };
  }
}
