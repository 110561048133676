<div
  class='or-tag-form p-1'
  appIsAuthorizedForBuilding='MANAGE_TAGS'
  [appBuildingIdToAuthorize]='buildingId'>
  <form *ngIf='tagForm' [formGroup]='tagForm' data-cy='edit-tag-form'>
    <div class='flex flex-col lg:flex-row'>
      <div class='or-inline-item or-tag-form-name my-2 lg:my-0'>
        <input
          [attr.data-cy]="'edit-tag-name-input-' + tag.id"
          id='tag-name-{{tag.id}}'
          class='or-input'
          type='text'
          required='required'
          placeholder='Tag name'
          formControlName='name'
          (change)='updateNodesForTag(tag)'
        />
      </div>
      <div class='flex flex-row'>
        <div class='or-inline-item or-tag-form-color'>
          <input
            [attr.data-cy]="'edit-tag-color-input-' + tag.id"
            id='tag-color-{{tag.id}}'
            class='or-input'
            type='text'
            maxlength='6'
            required='required'
            placeholder='Color'
            formControlName='color'
            (change)='updateNodesForTag(tag)'
          />
        </div>
        <div class='or-inline-item or-form-edit-tag-preview or-tag-form-preview'>
          <button
            [attr.data-cy]="'edit-tag-color-cycle-button-' + tag.id"
            id='tag-cycle-color-{{tag.id}}'
            class='or-button or-iconic or-form-edit-tag-generate-color'
            type='button'
            (click)='cycleColor(tag)'
          >
            <span class='or-icon or-small or-icon-node'></span>
          </button>
        </div>
      </div>
    </div>
    <div class='flex flex-col lg:flex-row my-2'>
      <div class='w-full lg:w-1/2 mr-2 my-2 lg:my-0'>
        <button
          [attr.data-cy]="'edit-tag-reset-button-' + tag.id"
          id='tag-reset-{{tag.id}}'
          class='edit-tag-btn'
          type='button'
          (click)='reset(tag)'
          [disabled]='this.tagForm.pristine'
        >
          <span class='or-icon or-small or-inverse or-icon-cross icon-initial'></span>
          <span class='or-button-label'>Discard</span>
        </button>
      </div>
      <div class='w-full lg:w-1/2'>
        <button
          [attr.data-cy]="'edit-tag-save-button-' + tag.id"
          id='tag-save-{{tag.id}}'
          class='edit-tag-btn'
          type='button'
          (click)='save(tag)'
          [disabled]='this.tagForm.pristine'
        >
          <span class='or-icon or-small or-inverse or-icon-checkmark icon-initial'></span>
          <span class='or-button-label'>Save</span>
        </button>
      </div>
    </div>
  </form>
</div>
