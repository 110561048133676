import { IEmergencyLightingResource } from '../../api/resources/IEmergencyLightingResource';
import { PromiseRejectionHandler } from '../../promises/PromiseRejectionHandler';
import { EmergencyLightingTestType } from '../../data/EmergencyLightingTestType';
import { PromiseUtils } from '../promises/PromiseUtils';
import {
  EmergencyLightingTest,
  EmergencyLightingTestOutline
} from '../../api/building/EmergencyLightingTest';
import { Resource } from '../Resource';

export class EmergencyLightingResource
  extends Resource<EmergencyLightingTest, number>
  implements IEmergencyLightingResource
{
  constructor(
    $http: ng.IHttpService,
    baseUrl: string,
    rejectionHandler: PromiseRejectionHandler
  ) {
    super($http, baseUrl, rejectionHandler);
  }

  public startTest(
    buildingId: number,
    nodeId: number,
    type: EmergencyLightingTestType
  ): Promise<{}> {
    return PromiseUtils.wrapSingle(
      this.$http.get(
        this.baseUrl +
          '/start/' +
          buildingId +
          '/' +
          nodeId +
          '?type=' +
          type.toString()
      ),
      this.rejectionHandler
    );
  }

  public startBatchTest(
    buildingId: number,
    nodes: number[],
    type: EmergencyLightingTestType
  ): Promise<{}> {
    return PromiseUtils.wrapSingle(
      this.$http.post(
        this.baseUrl + '/start/' + buildingId + '?type=' + type.toString(),
        nodes
      ),
      this.rejectionHandler
    );
  }

  public getTestsForBuilding(
    buildingId: number
  ): Promise<EmergencyLightingTest[]> {
    return PromiseUtils.wrapSingle(
      this.$http.get(this.baseUrl + '/' + buildingId),
      this.rejectionHandler
    );
  }

  public saveTest(
    testId: number,
    test: EmergencyLightingTestOutline
  ): Promise<{}> {
    return PromiseUtils.wrapSingle(
      this.$http.put(this.baseUrl + '/' + testId, test),
      this.rejectionHandler
    );
  }

  public cancelTest(buildingId: number, driverId: number): Promise<{}> {
    return PromiseUtils.wrapSingle(
      this.$http.put(
        this.baseUrl + '/cancel/' + buildingId + '/' + driverId,
        null
      ),
      this.rejectionHandler
    );
  }

  public cancelBatchTest(buildingId: number, driverIds: number[]): Promise<{}> {
    return PromiseUtils.wrapSingle(
      this.$http.put(this.baseUrl + '/cancel/' + buildingId, driverIds),
      this.rejectionHandler
    );
  }
}
