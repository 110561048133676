import { CircadianCurve } from '@angularjs/or/data/CircadianCurve';
import { CircadianCurveService } from '@angularjs/or/services/CircadianCurveService';

export class OrCircadianCurveListController {
  public curves: CircadianCurve[];
  public showNewForm = false;
  public isBusy: boolean;

  constructor(
    private $scope: ng.IScope,
    private circadianCurveService: CircadianCurveService
  ) {}

  public $onInit() {
    this.loadCurves();
  }

  private loadCurves() {
    this.isBusy = true;
    this.circadianCurveService.getCurves().then((curves) => {
      this.init(curves);
      this.isBusy = false;
    });
  }

  private init(curves: CircadianCurve[]) {
    this.$scope.$apply(() => {
      this.curves = curves;
      this.isBusy = false;
    });
  }

  createNew() {
    this.showNewForm = true;
  }

  onSaveNewCurve() {
    this.showNewForm = false;
    this.loadCurves();
  }

  onDeleteCurve() {
    this.loadCurves();
  }

  onClose() {
    this.showNewForm = false;
    this.loadCurves();
  }
}
