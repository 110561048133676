export class OrTintDirective {
  constructor() {
    return {
      replace: true,
      restrict: 'E',
      scope: {},
      template: require('raw-loader!./or-tint.html').default
    };
  }
}
