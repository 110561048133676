import { DataType } from '@angularjs/or/data/SensorNodeDataType';
import { Map } from '@angularjs/or/util/Map';
import { IMetricContext } from '@angularjs/or/api/query/outline/context/IContext';
import { IObservableModifiable } from '@angularjs/or/util/IObservable';
import { OrMetricsService } from '../or-metrics-service/OrMetricsService';
import { FeatureService } from '@services/feature.service';

export class OrMetricSelectorController {
  public dataTypeStatuses: Map<boolean> = {};
  public types: {};

  constructor(private metricContext: IObservableModifiable<IMetricContext>, private featureService: FeatureService) {}

  public $onInit() {
    const dataTypes = DataType.all();
    dataTypes.forEach((dataType) => (this.dataTypeStatuses[dataType.toString()] = false));
    this.setActive(this.metricContext.value().dataType.toString());
    this.types = OrMetricsService.metrics;
  }

  public setActive(dataType: string) {
    for (const dataTypeStatus in this.dataTypeStatuses) {
      this.dataTypeStatuses[dataTypeStatus] = dataType == dataTypeStatus;
    }
  }

  public isActive(dataType: string) {
    return this.dataTypeStatuses[dataType] || false;
  }
}
