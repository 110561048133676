import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SecurityService } from '@services/security.service';

@Directive({
  selector: '[appIsAuthorized]'
})
export class IsAuthorizedDirective implements OnInit, OnDestroy {
  @Input('appIsAuthorized') public authority: string;

  private readonly onDestroy$ = new Subject<void>();

  constructor(
    private readonly securityService: SecurityService,
    private readonly renderer: Renderer2,
    private readonly el: ElementRef
  ) {}

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngOnInit(): void {
    this.toggleUnauthorized();

    this.securityService
      .isAuthorized(this.authority)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((hasAuthority) => {
        if (hasAuthority) {
          this.toggleAuthorized();
        }
      });
  }

  toggleUnauthorized(): void {
    this.renderer.addClass(this.el.nativeElement, 'unauthorized');
    this.renderer.removeClass(this.el.nativeElement, 'authorized');
  }

  toggleAuthorized(): void {
    this.renderer.removeClass(this.el.nativeElement, 'unauthorized');
    this.renderer.addClass(this.el.nativeElement, 'authorized');
  }
}
