import * as angular from 'angular'; // Automatically added
import { MultiselectOption } from '../or-multiselect/MultiselectOption';
import { TagService } from '@angularjs/or/services/TagService';
import { Tag } from '@angularjs/or/api/building/Tag';
import { Floor } from '@angularjs/or/api/building/Floor';
import { IBuildingService } from '@angularjs/or/services/IBuildingService';
import { CircadianCurve } from '@angularjs/or/data/CircadianCurve';
import * as d3 from 'd3';
import { CircadianCurveService } from '@angularjs/or/services/CircadianCurveService';
import IScope = angular.IScope;
import IFormController = angular.IFormController;

export class OrFormCircadianCurveController {
  public form: IFormController;
  public details: CircadianCurve;
  public curvesList: CircadianCurve[];
  public selectedCurve: CircadianCurve;
  public tags: Tag[];
  public floors: Floor[];
  public tagOptions: MultiselectOption<Tag>[];
  public floorOptions: MultiselectOption<Floor>[];
  public onSaveNewCurve: () => {};
  public onDeleteCurve: () => {};
  public onClose: () => {};
  public isNew: boolean;
  public svg;
  public colourTemps = [];
  public lightLevels;
  public margin = { top: 20, right: 80, bottom: 50, left: 80 };
  public width = 960 - this.margin.left - this.margin.right;
  public height = 500 - this.margin.top - this.margin.bottom;

  // set the ranges
  public x = d3.scaleLinear().range([0, this.width]);
  public y0 = d3.scaleLinear().range([this.height, 0]);
  public y1 = d3.scaleLinear().range([this.height, 0]);

  public colourTempLine = '';
  public lightLevelLine = '';

  public dragIndex: null | number = null;

  public colourData = d3.range(this.height);
  public colourValues = d3.quantize(d3.interpolateRdYlBu, this.colourData.length).reverse();

  public colourRects = this.colourData.map((val) => {
    const y = val * (this.height / this.colourData.length);
    const height = this.height / this.colourData.length;
    const x = 0;
    const width = 20;
    const fill = this.colourValues[val];
    return {
      y,
      x,
      height,
      width,
      fill
    };
  });

  constructor(
    private scope: IScope,
    private circadianCurveService: CircadianCurveService,
    private tagService: TagService,
    private buildingService: IBuildingService
  ) {}

  public $onInit() {
    if (!this.details.id) {
      this.isNew = true;
    }
    this.updateTagOptions();
    this.updateFloorOptions();
    // this.initialiseCurve();

    this.x.domain([0, 23]);
    this.y0.domain([0, 10000]);
    this.y1.domain([0, 1000]);

    this.scope.$watch(
      'circadianCurve.colourTemps',
      () => {
        // define the 1st line (Color Temperature)
        this.colourTempLine = d3
          .line<{ time: number; colour_temp: number }>()
          .x((d) => {
            return this.x(d.time);
          })
          .y((d) => {
            return this.y0(d.colour_temp);
          })(this.colourTemps);
      },
      true
    );

    this.scope.$watch(
      'circadianCurve.lightLevels',
      () => {
        // define the 2nd line (Light Level)
        this.lightLevelLine = d3
          .line<{ time: number; light_level: number }>()
          .x((d) => {
            return this.x(d.time);
          })
          .y((d) => {
            return this.y1(d.light_level);
          })(this.lightLevels);
      },
      true
    );

    const time = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
    this.colourTemps = time.map((t, i) => {
      return { time: t, colour_temp: this.details.colourTempValues[i] };
    });
    this.lightLevels = time.map((t, i) => {
      return { time: t, light_level: this.details.lightLevelValues[i] };
    });

    const self = this;
    // Wait for html to load before trying to select
    angular.element(document).ready(function () {
      self.svg = d3.select('#circadian-curve-chart-' + self.curvesList.indexOf(self.details));

      // Add the X Axis
      self.svg
        .select('#x-axis')
        .append('g')
        .attr('transform', 'translate(0,' + self.height + ')')
        .call(d3.axisBottom(self.x));

      // text label for the x axis
      self.svg
        .select('#x-axis')
        .append('text')
        .attr('transform', 'translate(' + self.width / 2 + ' ,' + (self.height + self.margin.top + 20) + ')')
        .style('text-anchor', 'middle')
        .text('Hour');

      // Add the Y0 Axis
      self.svg.select('#y0-axis').append('g').attr('class', 'axisSteelBlue').call(d3.axisLeft(self.y0));

      // text label for the y0 axis
      self.svg
        .select('#y0-axis')
        .append('text')
        .attr('transform', 'rotate(-90)')
        .attr('y', 0 - self.margin.left)
        .attr('x', 0 - self.height / 2)
        .attr('dy', '1em')
        .style('text-anchor', 'middle')
        .text('Colour Temperature (Kelvin)');

      // Add the Y1 Axis
      self.svg
        .select('#y1-axis')
        .append('g')
        .attr('class', 'axisRed')
        .attr('transform', 'translate( ' + self.width + ', 0 )')
        .call(d3.axisRight(self.y1));

      // text label for the y1 axis
      self.svg
        .select('#y1-axis')
        .append('text')
        .attr('transform', 'rotate(-90)')
        .attr('y', self.width + 40)
        .attr('x', 0 - self.height / 2)
        .attr('dy', '1em')
        .style('text-anchor', 'middle')
        .text('Light Level (LUX)');
    });
  }

  public onMouseDown(lineType, element, event: MouseEvent) {
    const self = this;
    // Wait for html to load before trying to select
    angular.element(document).ready(function () {
      self.dragIndex = element.time;
      const chartElement = document.getElementById('circadian-curve-chart-' + self.curvesList.indexOf(self.details));
      const chartTop = chartElement.getBoundingClientRect().top;
      const valuesArray = lineType === 'colourTemps' ? self.colourTemps : self.lightLevels;
      const yScale = lineType === 'colourTemps' ? self.y0 : self.y1;
      const property = lineType === 'colourTemps' ? 'colour_temp' : 'light_level';
      const onMouseMove = (e: MouseEvent) => {
        valuesArray[self.dragIndex] = {
          time: valuesArray[self.dragIndex].time,
          [property]: yScale.invert(e.clientY - chartTop - self.margin.top)
        };
      };
      const onMouseUp = (e: MouseEvent) => {
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
        self.dragIndex = null;
      };

      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    });
  }

  public initialiseCurve() {
    // // Define the div for the tooltip
    // let tooltipDiv = d3.select("body").append("div")
    //     .attr("class", "tooltip")
    //     .style("opacity", 0);
    //
    //
    //
    // const dotRadius = 5;
    //
    // this.svg
    //     .selectAll("circle")
    //     .data(this.colourTemps)
    //     .enter()
    //     .append("circle")
    //     .attr("class", "dot")
    //     .attr("r", dotRadius)
    //     .attr("cx", function (d) {
    //         return x(d.time);
    //     })
    //     .attr("cy", function (d) {
    //         return y0(d.colour_temp);
    //     })
    //     .call(d3.drag()
    //         .on("start", (event, d) => this.dragstarted(event, d))
    //         .on("drag", (event, d) => this.dragged(event, d, this, y0, colourTempLine))
    //         .on("end", (event, d) => this.dragended(event, d)))
    //     .on("mouseover", function (event, d) {
    //         tooltipDiv.transition()
    //             .duration(200)
    //             .style("opacity", .9);
    //         tooltipDiv.html(d.colour_temp.toFixed(0) + 'K')
    //             .style("left", (event.pageX) + "px")
    //             .style("top", (event.pageY - 28) + "px");
    //     })
    //     .on("mouseout", function (event, d) {
    //         tooltipDiv.transition()
    //             .duration(500)
    //             .style("opacity", 0);
    //     });
  }

  public updateTagOptions() {
    this.buildingService.getCurrentBuilding().then((building) => {
      this.tagService.getTags(building, true, true).then((tags) => {
        this.scope.$apply(() => {
          this.tagOptions = angular.copy(tags.map((tag) => new MultiselectOption(tag.name, tag)));
        });
      });
    });
  }

  public updateFloorOptions() {
    this.buildingService.getFloorsForCurrentBuilding().then((floors) => {
      this.scope.$apply(() => {
        this.floorOptions = angular.copy(
          floors.map((floor) => new MultiselectOption(floor.floorNumber.toString(), Floor.toOutline(floor)))
        );
      });
    });
  }

  public save() {
    this.details.colourTempValues = this.colourTemps.map((v) => v.colour_temp);
    if (!this.form.$valid) {
      return;
    }
    if (this.details.id != null) {
      this.circadianCurveService.updateCurve(this.details).then((curve) => {
        this.scope.$apply(() => (this.details = CircadianCurve.clone(curve)));
        this.close();
      });
    } else {
      this.circadianCurveService.saveCurve(this.details).then((curve) => {
        this.scope.$apply(() => (this.details = CircadianCurve.clone(curve)));
        if (this.onSaveNewCurve) {
          this.onSaveNewCurve();
        }
      });
    }
  }

  public delete() {
    if (!confirm(`Delete schedule '${this.details.name}?'`)) {
      return;
    }

    if (this.details.id != null) {
      this.circadianCurveService.deleteCurve(this.details).then(() => {
        if (this.onDeleteCurve) {
          this.onDeleteCurve();
        }
      });
    }
  }

  public close() {
    if (this.onClose) {
      this.onClose();
    }
  }
}
