import * as d3 from 'd3';
import { ChartData } from './ChartData';
import { DataPoint } from './DataPoint';
import {
  AlwaysFalse,
  Consumer,
  EmptyConsumer,
  Predicate
} from '../delegates/Delegates';
import BaseEvent = d3.BaseEvent;

export class RenderableChart {
  private static HTML_PREFIX = 'or-metric-widget-chart-bar';

  private chartWidth: number;
  private marginLeft: number;
  private marginRight: number;
  private barThickness: number;
  private scaleX: (x: number) => number;
  private clickCount = 0;
  private timeout;

  constructor(
    private chart: ChartData,
    private container: any,
    private isVertical: boolean = true,
    private canSelect: () => boolean = () => false,
    private onSelect: (points: DataPoint[]) => void = (points) => {},
    private canZoom: Predicate<number> = AlwaysFalse,
    private onZoom: Consumer<number> = EmptyConsumer
  ) {
    this.marginLeft = 40;
    this.marginRight = 60;
    this.barThickness = 14;
  }

  public render() {
    this.container.style.height = this.chart.values.length * this.barThickness;

    this.chartWidth =
      this.container.clientWidth - (this.marginLeft + this.marginRight);
    const domainX = this.fixEmptyDomain([
      0,
      d3.max(this.chart.values, (point: DataPoint) => {
        return point.y;
      })
    ]);
    this.scaleX = d3
      .scaleLinear()
      .range([0, this.chartWidth])
      .domain(domainX)
      .nice();

    const d3Chart = d3.select(this.container);
    d3Chart.selectAll('*').remove();

    const bar = d3Chart
      .selectAll('svg')
      .data(this.chart.values)
      .enter()
      .append('g')
      .attr('class', RenderableChart.HTML_PREFIX)
      .classed(
        RenderableChart.HTML_PREFIX + '-selected',
        (entry) => entry.isActive
      )
      .on('mousedown', (event) =>
        this.onMouseDown(event as unknown as BaseEvent)
      );

    d3.selectAll('svg g.' + RenderableChart.HTML_PREFIX).each((item, i, nodes) => {
      d3.select(nodes[i]).attr('id', RenderableChart.HTML_PREFIX + '-' + i);
    });

    this.renderBar(bar);
  }

  private fixEmptyDomain(domain: number[]) {
    const minDomainValue = 0.0001;
    if (domain.length === 2 && domain[1] === 0) {
      return [domain[0], minDomainValue];
    }
    return domain;
  }

  private onMouseDown(event: Event | BaseEvent) {
    this.clickCount++;

    if (this.clickCount == 1) {
      this.timeout = setTimeout(() => {
        this.clickCount = 0;
        this.singleClick(event);
      }, 300);
    } else {
      clearTimeout(this.timeout);
      this.clickCount = 0;
      this.doubleClick(event);
    }
  }

  private singleClick(event: Event | BaseEvent) {
    const target = ((event as Event).target as Node).parentNode;
    const point = (event as any).target.__data__;

    (event as Event).stopPropagation();

    const isZoomOut = (event as MouseEvent).which == 3;
    const keyboardEvent = event as KeyboardEvent;

    if (isZoomOut && this.canZoom(-1)) {
      this.onZoom(-1);
      return;
    }

    this.select(target, point, keyboardEvent.shiftKey);
  }

  private select(target, point, isMultiSelect: boolean, isActive?: boolean): void {
    if (this.canSelect()) {
      const wasActive = point.isActive;

      let firstIdx = Number.MAX_SAFE_INTEGER;
      let lastIdx = Number.MIN_SAFE_INTEGER;
      if (isMultiSelect) {
        d3.selectAll('svg g.' + RenderableChart.HTML_PREFIX)
          .data()
          .filter((dp: DataPoint) => dp != null)
          .forEach((dp: DataPoint, idx: number) => {
            if ((dp.xLabel === point.xLabel && firstIdx > idx) || (firstIdx > idx && dp.isActive)) {
              firstIdx = idx;
            }
            if ((dp.xLabel === point.xLabel && lastIdx < idx) || (idx > lastIdx && dp.isActive)) {
              lastIdx = idx;
            }
          });
        if (lastIdx < firstIdx) {
          const lt = lastIdx;
          const ft = firstIdx;
          firstIdx = lt;
          lastIdx = ft;
        }
      }

      d3.selectAll('svg g.' + RenderableChart.HTML_PREFIX + '-selected')
        .classed(RenderableChart.HTML_PREFIX + '-selected', false)
        .data()
        .filter((dp: DataPoint) => dp != null)
        .forEach((dp: DataPoint) => (dp.isActive = false));

      point.isActive = isActive || !wasActive;
      if (
        lastIdx !== Number.MAX_SAFE_INTEGER &&
        lastIdx !== Number.MIN_SAFE_INTEGER &&
        firstIdx !== Number.MIN_SAFE_INTEGER &&
        firstIdx !== Number.MAX_SAFE_INTEGER
      ) {
        for (let i = firstIdx; i <= lastIdx; i++) {
          d3.select('svg g#' + RenderableChart.HTML_PREFIX + '-' + i).classed(
            RenderableChart.HTML_PREFIX + '-selected',
            point.isActive
          );
        }
      }

      d3.select(target).classed(
        RenderableChart.HTML_PREFIX + '-selected',
        point.isActive
      );

      const active: DataPoint[] = d3
        .selectAll('svg g.' + RenderableChart.HTML_PREFIX + '-selected')
        .data() as DataPoint[];
      active.forEach((dp) => (dp.isActive = true));
      this.onSelect(active);
    }
  }

  private doubleClick(event: Event | BaseEvent) {
    const target = ((event as Event).target as Node).parentNode;
    const point = (event as any).target.__data__;
    const keyboardEvent = event as KeyboardEvent;
    this.select(target, point, keyboardEvent.shiftKey, true);
    this.onZoom(1);
  }

  private renderBar(bar) {
    this.renderBarContainer(bar);
    this.renderBarValue(bar);
    this.renderBarTip(bar);
    this.renderBarTimestamp(bar);
    this.renderBarLabel(bar);
  }

  private renderBarContainer(bar) {
    bar
      .append('rect')
      .attr('class', RenderableChart.HTML_PREFIX + '-container')
      .attr('height', (e, i) => this.barThickness - 1)
      .attr('width', (e, i) => this.chartWidth)
      .attr('x', (e, i) => this.marginLeft)
      .attr('y', (e, i) => {
        return i * this.barThickness;
      });
  }

  private renderBarValue(bar) {
    bar
      .append('rect')
      .attr('class', RenderableChart.HTML_PREFIX + '-fill')
      .attr('height', (e, i) => this.barThickness - 1)
      .attr('width', (e, i) => Math.max(0, this.scaleX(e.y) - 2))
      .attr('x', (e, i) => this.marginLeft)
      .attr('y', (e, i) => {
        return i * this.barThickness;
      });
  }

  private renderBarTip(bar) {
    bar
      .append('rect')
      .attr('class', RenderableChart.HTML_PREFIX + '-tip')
      .attr('height', (e, i) => this.barThickness - 1)
      .attr('width', (e, i) => 1)
      .attr('x', (e, i) => {
        return Math.max(0, this.marginLeft + this.scaleX(e.y) - 1);
      })
      .attr('y', (e, i) => {
        return i * this.barThickness;
      });
  }

  private renderBarTimestamp(bar) {
    bar
      .append('text')
      .attr(
        'class',
        RenderableChart.HTML_PREFIX +
          '-label ' +
          RenderableChart.HTML_PREFIX +
          '-timestamp'
      )
      .attr('x', (e, i) => this.marginLeft - 4)
      .attr('y', (e, i) => {
        return i * this.barThickness + this.barThickness / 2 + 3;
      })
      .text((entry: DataPoint) => entry.xLabel);
  }

  private renderBarLabel(bar) {
    bar
      .append('text')
      .attr(
        'class',
        RenderableChart.HTML_PREFIX +
          '-label ' +
          RenderableChart.HTML_PREFIX +
          '-value'
      )
      .attr('x', (e, i) => {
        return this.marginLeft + this.scaleX(e.y) + 4;
      })
      .attr('y', (e, i) => {
        return i * this.barThickness + this.barThickness / 2 + 3;
      })
      .text((entry: DataPoint) => {
        return entry.yLabel;
      });
  }
}
