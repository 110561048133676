import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Gateway } from '@app/api/building/gateway/Gateway';
import { GatewayHealth, Gateways, GatewayService } from '@app/shared/services/gateway.service';
import { NavigationSection } from '@app/shared/services/navigation/navigation-section';
import { UserService } from '@app/shared/services/user/user.service';
import { concatMap, from, Observable, of, Subject } from 'rxjs';
import { finalize, map, switchMap, toArray } from 'rxjs/operators';
import {
  CompareConfigDialogComponent,
  DialogData
} from '@app/administration/gateway/compare-config-dialog/compare-config-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { NavigationService } from '@app/shared/services/navigation/navigation.service';
import { MatTableDataSource } from '@angular/material/table';
import { ToastService } from '@services/toast/toast.service';
import { ConfirmationDialogService } from '@services/confirmation-dialog/confirmation-dialog.service';
import { ConfirmDialogData } from '@components/dialogs/confirm/confirm.component';

@Component({
  selector: 'app-gateway',
  templateUrl: './gateway.component.html',
  styleUrls: ['./gateway.component.scss']
})
export class AdministrationGatewaysComponent implements OnInit {
  public gateways$: Observable<GatewayWithHealth[]>;
  public newGatewayDetails: Gateway;
  public isBusy = true;
  public isGatewayListBusy: boolean;
  public buildingId: number;
  public disableFloorsMenu = true;
  public tabs: NavigationSection[];
  public afterUpdate$: Subject<Gateway> = new Subject();
  public onError$: Subject<Gateway> = new Subject();
  private errorMessage =
    'Please ensure the gateway address is entered correctly and all required fields are filled in.';
  dataSource = new MatTableDataSource<GatewayWithHealth>();

  constructor(
    private route: ActivatedRoute,
    private gatewayService: GatewayService,
    private buildingService: UserService,
    private dialog: MatDialog,
    private navigationService: NavigationService,
    private toastService: ToastService,
    private confirmationDialogService: ConfirmationDialogService
  ) {}

  ngOnInit(): void {
    this.route.params
      .pipe(
        concatMap((params) => {
          const { buildingId } = params;
          return this.buildingService.getBuilding(buildingId);
        })
      )
      .subscribe((building) => {
        this.buildingId = building.id;
        this.resetNewGatewayForm();
        this.init();
        this.navigationService.initNavigation(window.location.href, building);
      });
  }

  private init(): void {
    this.isBusy = false;
    this.loadGateways();
  }

  private loadGateways(): void {
    this.isGatewayListBusy = true;
    this.gateways$ = this.gatewayService.getGateways(this.buildingId).pipe(
      map((gatewaysResponse: Gateways) => gatewaysResponse.gateway),
      switchMap((gateways: Gateway[]) => from(gateways)),
      toArray(),
      map((gateways) => gateways.sort(this.compareGateways)),
      finalize(() => (this.isGatewayListBusy = false))
    );
  }

  private getDefaultHealthForGateway(gateway: Gateway): Observable<GatewayHealth> {
    return of(this.gatewayService.produceCleanGatewayHealth(gateway.address));
  }

  private compareGateways = (gateA: GatewayWithHealth, gateB: GatewayWithHealth) => {
    if (gateA.id > gateB.id) {
      return 1;
    } else if (gateA.id < gateB.id) {
      return -1;
    } else {
      return 0;
    }
  };

  public deleteGateway(gatewayId): void {
    const data = new ConfirmDialogData('Gateway is going to be deleted', 'Delete gateway');
    this.confirmationDialogService.open(data).subscribe((confirm) => {
      if (confirm) {
        this.startBusy();
        this.gatewayService.deleteGateway(gatewayId).subscribe(
          () => {
            this.endBusy();
            this.loadGateways();
          },
          () => {
            this.toastService.error({
              message:
                'The gateway could not be deleted. Please delete the assigned BACnet areas or reassign them to another gateway before deleting.',
              dataCy: 'delete-gateway-error'
            });
            this.endBusy();
          },
          () => {
            this.endBusy();
          }
        );
      }
    });
  }

  public startBusy(): void {
    this.isGatewayListBusy = true;
  }

  public endBusy(): void {
    this.isGatewayListBusy = false;
  }

  public reload(): void {
    this.resetNewGatewayForm();
    this.loadGateways();
  }

  public resetGateway(isPrompted?: boolean): void {
    if (isPrompted) {
      const data = new ConfirmDialogData('Reset changes?', 'Reset add new gateway');
      this.confirmationDialogService.open(data).subscribe((confirm) => {
        if (confirm) {
          this.resetNewGatewayForm();
        }
      });
    }
  }

  private resetNewGatewayForm(): void {
    this.newGatewayDetails = this.gatewayService.produceCleanGateway(this.buildingId);
  }

  public onAddGateway(gateway: Gateway): void {
    this.startBusy();
    this.gatewayService.addGateway(gateway).subscribe({
      next: () => {
        this.endBusy();
        this.reload();
      },
      error: (err) => {
        console.log(err);
        this.toastService.error({
          message: 'The gateway address you provided could not be added to this building. ' + err.error,
          dataCy: 'add-gateway-error'
        });
        this.endBusy();
      }
    });
  }

  public onUpdateGateway(gateway: Gateway): void {
    if (gateway.original) {
      this.startBusy();
      this.performUpdate(gateway);
    }
  }

  private performUpdate(gateway: Gateway): void {
    this.gatewayService.updateGateway(gateway.id, gateway).subscribe({
      next: () => {
        // Update original since we have saved
        gateway.original = Gateway.clone(gateway);
        this.afterUpdate$.next(gateway);
      },
      error: (err) => {
        this.toastService.error({
          message: 'The update for gateway failed. ' + err.error,
          dataCy: 'update-gateway-error'
        });
        this.onError$.next(gateway);
        this.endBusy();
      },
      complete: () => {
        this.endBusy();
      }
    });
  }

  public export(): void {
    this.gatewayService.downloadExport(this.buildingId).subscribe((data) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      const a = document.createElement('a');
      const url = window.URL.createObjectURL(blob);

      a.href = url;
      a.download = 'Building Gateway Extract';
      a.click();
      URL.revokeObjectURL(url);
    });
  }

  /**
   * Sync the gateway's configuration with Rigado's edge direct
   */
  public onConfigureWithRigado(gateway: Gateway): void {
    this.startBusy();

    this.gatewayService.compareConfig(gateway.id).subscribe(
      (gatewayConfigComparison) => {
        if (gateway.original) {
          const data = new ConfirmDialogData('Configure Gateway', 'Proceed with configuring the gateway with Rigado?');
          this.confirmationDialogService.open(data).subscribe((confirm) => {
            if (confirm) {
              this.performConfigure(gateway);
            } else {
              this.endBusy();
            }
          });
        }
      },
      () => {
        this.toastService.error({
          message: 'Could not retrieve gateway config. ' + this.errorMessage,
          dataCy: 'compare-gateway-error'
        });
        this.onError$.next(gateway);
        this.endBusy();
      }
    );
  }

  private performConfigure(gateway: Gateway): void {
    this.gatewayService.configureWithRigado(gateway.id, gateway).subscribe(
      () => {
        // Update original since we have saved
        gateway.original = Gateway.clone(gateway);
        this.afterUpdate$.next(gateway);
      },
      () => {
        this.toastService.error({
          message: 'The update for gateway failed. ' + this.errorMessage,
          dataCy: 'configure-gateway-error'
        });
        this.onError$.next(gateway);
        this.endBusy();
      },
      () => {
        this.endBusy();
        this.toastService.info({
          message: 'Gateway configured with Rigado successfully',
          dataCy: 'configure-gateway-info'
        });
      }
    );
  }

  public restartGateway(gateway: Gateway): void {
    const data: ConfirmDialogData = new ConfirmDialogData(
      'Are you sure you want to restart the gateway? (Note: a 30 minute cooldown period applies)',
      'Restart gateway'
    );
    this.confirmationDialogService.open(data).subscribe((confirm) => {
      if (confirm) {
        this.startBusy();
        this.gatewayService
          .requestGatewayRestart(gateway.address, this.gatewayService.produceCleanGatewayHealth(gateway.address))
          .subscribe(
            () => {
              this.endBusy();
            },
            () => {
              this.toastService.warning({
                message: 'The gateway could not be restarted. Please try again later.',
                dataCy: 'restart-gateway-warning'
              });
              this.endBusy();
            }
          );
      }
    });
  }

  public generateKeys(gateway: Gateway): void {
    const data = new ConfirmDialogData(`Generate keys for gateway ${gateway.address}?`, 'Generate keys');
    this.confirmationDialogService.open(data).subscribe((confirm) => {
      if (confirm) {
        this.startBusy();
        this.gatewayService.generateKeys(gateway.id).subscribe(
          () => {
            gateway.hasKeys = true;
            this.endBusy();
          },
          () => {
            this.toastService.warning({
              message: 'The gateway has keys already generated for it. Keys may only be generated once!',
              dataCy: 'generate-keys-gateway-warning'
            });
            this.endBusy();
          }
        );
      }
    });
  }

  /**
   * Fetch the gateway configuration that is stored in RDS and on edge direct
   * and present them side-by-side in dialog to allow the user to compare both configurations
   * @param gateway - Gateway that is queried
   */
  public onCompareGateways(gateway: Gateway): void {
    this.startBusy();
    this.gatewayService.compareConfig(gateway.id).subscribe(
      (gatewayConfigs) => {
        const dialogData: DialogData = {
          gateway: { ...gateway },
          left: JSON.parse(gatewayConfigs?.portalConfig),
          right: JSON.parse(gatewayConfigs?.rigadoConfig),
          isEqual: gatewayConfigs?.portalConfig === gatewayConfigs?.rigadoConfig,
          confirmCallback: () => this.onConfigureWithRigado(gateway)
        };
        this.dialog.open(CompareConfigDialogComponent, {
          width: '1200px',
          data: dialogData
        });
      },
      () => {
        this.toastService.error({
          message: 'Unable to retrieve gateway configuration. Please try again',
          dataCy: 'compare-gateway-error'
        });
        this.endBusy();
      },
      () => {
        this.endBusy();
      }
    );
  }
}

type GatewayWithHealth = Gateway & { health?: GatewayHealth };
