import { Inject, Injectable } from '@angular/core';
import { Resource } from '@app/shared/resources/resource';
import { ITenant } from '@app/shared/models/tenant.interface';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Environment, environmentToken } from '@environment';
import { Observable } from 'rxjs';
import { Pageable } from '@app/shared/models/Pageable.interface';

@Injectable({
  providedIn: 'root'
})
export class TenantResource extends Resource<ITenant, number> {
  constructor(protected httpClient: HttpClient, @Inject(environmentToken) environment: Environment) {
    super(httpClient, `${environment.apiUrl + environment.apiPath}/tenant`);
  }

  public getTenants(
    buildingId: number,
    name?: string,
    pageNumber?: number,
    pageSize?: number
  ): Observable<Pageable<ITenant>> {
    return this.httpClient.get<Pageable<ITenant>>(`${this.baseUrl}/building/${buildingId}`, {
      params: new HttpParams().set('filter', name).set('page', pageNumber.toString()).set('size', pageSize.toString())
    });
  }

  public getAllTenants(buildingId: number): Observable<ITenant[]> {
    return this.httpClient.get<ITenant[]>(`${this.baseUrl}/building/${buildingId}/all`);
  }

  public addTenant(tenant: ITenant): Observable<ITenant> {
    return this.httpClient.post<ITenant>(`${this.baseUrl}`, tenant);
  }

  public updateTenant(updatedTenant: ITenant): Observable<ITenant> {
    return this.httpClient.put<ITenant>(`${this.baseUrl}/${updatedTenant.id}`, updatedTenant);
  }

  public deleteUsersFromTenant(tenantId: number): Observable<{}> {
    return this.httpClient.delete(`${this.baseUrl}/${tenantId}/users`);
  }

  public getUserTenants(buildingId: number): Observable<ITenant[]> {
    return this.httpClient.get<ITenant[]>(`${this.baseUrl}/building/${buildingId}/user`);
  }
}
