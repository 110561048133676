import { OrFormMobileTokenDetailsController } from './OrFormMobileTokenDetailsController';

export class OrFormMobileTokenDetailsDirective {
  constructor() {
    return {
      bindToController: true,
      controller: ['$scope', OrFormMobileTokenDetailsController],
      controllerAs: 'tokenDetails',
      replace: true,
      restrict: 'E',
      scope: {
        enableLabels: '=',
        isBusy: '=',
        isNew: '=',
        newToken: '=?',
        onAddToken: '&',
        onDeactivateToken: '&',
        onUpdateToken: '&',
        token: '='
      },
      template: require('raw-loader!./or-form-mobile-token-details.html').default
    };
  }
}
