import { Map } from '@angularjs/or/util/Map';
import { Panel } from '../Panel';
import { Iterable } from '@angularjs/or/util/Iterable';
import { IClearable, INotifier } from '@angularjs/or/messaging/Channel';
import { Action } from '@angularjs/or/delegates/Delegates';

export class OrPanelService implements INotifier, IClearable {
  private subscribers: Action[] = [];
  public panels: Map<Panel> | Iterable<Panel>;

  constructor() {
    this.panels = {};
  }

  public togglePanel(panelId: string) {
    const panel = this.getPanel(panelId);

    if (panel == null || panel.isAlwaysActive) {
      return;
    }

    if (
      !panel.isMain ||
      !panel.isActive ||
      this.isMoreThanOneMainPanelActive()
    ) {
      panel.isActive = !panel.isActive;
      if (panel.isExclusive) {
        for (const panelKey in this.panels) {
          const other = this.panels[panelKey];
          if (panelKey != panel.panelId && other.isExclusive == true) {
            other.isActive = !panel.isActive;
          }
        }
      }
      this.notify();
    }
  }

  public copyToClipboard(panelId: string): string {
    const panel = this.getPanel(panelId);

    if (panel == null) {
      return null;
    }

    return panel.reportToCsv;
  }

  public setPanel(panel: Panel) {
    this.panels[panel.panelId] = panel;
  }

  public getPanel(panelId: string): Panel {
    return this.panels[panelId];
  }

  public setPanels(panels: Iterable<Panel>) {
    panels.forEach((panel) => this.setPanel(panel));
  }

  public getPanels(): Map<Panel> | Iterable<Panel> {
    return this.panels;
  }

  public isMoreThanOneMainPanelActive(): boolean {
    return (
      Object.keys(this.panels).filter((id) => {
        return this.panels[id].isActive && this.panels[id].isMain;
      }).length > 1
    );
  }

  public onNotify(subscriber: Action) {
    this.subscribers.push(subscriber);
  }

  public clear() {
    this.subscribers = [];
  }

  private notify() {
    this.subscribers.forEach((subscriber) => subscriber());
  }
}
