import { OrFloorplanController } from './OrFloorplanController';

export class OrFloorplanDirective {
  constructor() {
    return {
      bindToController: true,
      controller: [
        '$window',
        '$timeout',
        '$scope',
        '$element',
        '$interval',
        'TagService',
        'SensorNodeService',
        'SensorNodeChangeHistoryService',
        'FloorService',
        'QueryOutlineBuilder',
        'QueryContext',
        'QueryContext',
        'QueryContext',
        'QueryContext',
        'QueryContext',
        'FloorplanUpdater',
        'OrPanelService',
        'QueryExecutor',
        'MappingService',
        'FloorplanZoomService',
        'UIRefreshService',
        'NavigationService',
        'BuildingService',
        'BuildingService',
        OrFloorplanController
      ],
      controllerAs: 'floorplan',
      replace: true,
      restrict: 'E',
      scope: {
        buildingId: '<',
        disableSelection: '=',
        disableDriverSelection: '=',
        enableHeatmap: '=',
        enableNodes: '=?',
        enableDrivers: '=?',
        enableUnmappedNodes: '=areUnmappedNodesEnabled',
        pubSubConnectionDefaultOn: '=?',
        showNodeValues: '=',
        notificationMode: '=',
        floor: '=',
        nodes: '=?',
        isAddModeActive: '=addMode',
        isMoveModeActive: '=?moveMode',
        isMappingModeActive: '=mappingMode',
        isPassiveNodeMappingModeActive: '=passiveNodeMappingMode',
        isCumulativeSelectionActive: '=accumulateSelection',
        isEmergencyLightingTestModeActive: '=emergencyLightingTestMode',
        isManualFunctionalTestModeActive: '=manualFunctionalTestMode',
        isManualDurationTestModeActive: '=manualDurationTestMode',
        isCancelTestModeActive: '=cancelTestMode',
        isSelectionModeActive: '=?selectionMode',
        onMappingModeEnter: '&',
        onMappingModeLeave: '&',
        onPassiveNodeMappingModeEnter: '&',
        onPassiveNodeMappingModeLeave: '&',
        normalizeScale: '=',
        markFaultyNodes: '=?',
        markEmergencyLights: '=?',
        onload: '=',
        suppressNotifications: '=',
        tagIds: '=',
        isSnappingEnabled: '=snapNodes',
        snapResolution: '=?snapResolution',
        nodeToFocusOn: '=?',
        testResults: '=?',
        showLightLevel: '='
      },
      template: require('raw-loader!./or-floorplan.html').default
    };
  }
}
