import { Building } from './building.interface';
import { LampTypeTemplate } from './lamp-type-template.interface';
import { LuminaireDriverDTO } from '@app/shared/models/luminaire-driver';

export interface LampType {
  id?: number;
  name?: string;
  lampTypeId?: number;
  lampTypeTemplateId?: number;
  building?: Building;
  luminaireDrivers?: LuminaireDriverDTO[];
}

export interface SimpleLampType extends LampType {
  powerConsumptionMax?: number;
  template?: LampTypeTemplate;
}

export function simpleLampTypeGuard(lampType: LampType): lampType is SimpleLampType {
  return (lampType as SimpleLampType).powerConsumptionMax != null && (lampType as SimpleLampType).template != null;
}

export interface SimpleLampTypeOutline extends SimpleLampType {
  lampTypeTemplateId: number;
  luminaireDriverIds?: number[];
  sensorNodeIds?: number[];
}

export function simpleLampTypeToOutline(simpleLampType: SimpleLampType): SimpleLampTypeOutline {
  return {
    lampTypeId: 0,
    powerConsumptionMax: simpleLampType.powerConsumptionMax,
    lampTypeTemplateId: simpleLampType.template != null ? simpleLampType.template.id : null,
    luminaireDriverIds: simpleLampType.luminaireDrivers.map((node) => node.id)
  };
}
