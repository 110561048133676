import { OrFormInviteUserController } from './OrFormInviteUserController';

export class OrFormInviteUserDirective {
  constructor() {
    return {
      bindToController: true,
      controller: ['$scope', OrFormInviteUserController],
      controllerAs: 'inviteUser',
      replace: true,
      restrict: 'E',
      scope: {
        roles: '=',
        user: '='
      },
      template: require('raw-loader!./or-form-invite-user.html').default
    };
  }
}
