import { ComplexQueryOutline } from './outline/ComplexQueryOutline';
import { LiveQueryOutline } from './outline/LiveQueryOutline';
import { LiveDataQueryResults } from './outline/LiveDataQueryResults';
import { SimpleQueryOutline } from './outline/SimpleQueryOutline';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { QueryExecutorUrlProvider } from '@app/analytics/metric-widget/query/query-executor-url-provider';
import { QueryResult } from '@app/analytics/metric-widget/data-objects/query-result';
import { IQueryExecutor } from '@app/analytics/metric-widget/query/query-executor';
import { Observable } from 'rxjs';

@Injectable()
export class QueryExecutorService implements IQueryExecutor {
  private canceller;
  private nodeDataCanceller;
  constructor(protected $http: HttpClient, private configuration: QueryExecutorUrlProvider) {}

  doSimpleQuery(outline: SimpleQueryOutline): Observable<QueryResult<number>> {
    throw new Error('Method not implemented.');
  }

  public doComplexQuery(outline: ComplexQueryOutline): Observable<QueryResult<number>> {
    return this.$http.post<QueryResult<number>>(this.configuration.complexQueryUrl, outline);
  }

  public doLiveQuery(outline: LiveQueryOutline): Observable<QueryResult<number>> {
    return this.$http.post<QueryResult<number>>(this.configuration.liveQueryUrl, outline);
  }

  public cancelLiveQuery(): void {
    this.canceller?.resolve();
  }

  public cancelNodeDataQuery(): void {
    this.nodeDataCanceller?.resolve();
  }

  doLiveDataQuery(outline: LiveQueryOutline): Observable<LiveDataQueryResults> {
    return this.$http.post<LiveDataQueryResults>(this.configuration.liveDataQueryUrl, outline);
  }
}
