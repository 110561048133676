<div class="or-failed-tests-counter" [ngClass]="{ 'or-active': showCounter && (getFailedTestsCount() > 0 || getCancelledTestsCount() > 0) }">
  <div
    class="or-inline-item or-failed-tests-counter-body"
    *ngIf="!allTestsPassed()"
    [ngClass]="{ 'or-failed-tests-counter-something-failed': getFailedTestsCount() > 0 }">
    <div data-cy="functional-tests-failed-banner" class="or-failed-tests-counter-functional" *ngIf="testsSummary.failedFunctionTests > 0">
      <span
        class="or-failed-tests-counter-label or-failed-tests-counter-failed">
        {{testsSummary.failedFunctionTests}}
      </span>
      <span class="or-failed-tests-counter-label">of</span>
      <span class="or-failed-tests-counter-label or-failed-tests-counter-total">{{testsSummary.totalFunctionTests}}</span>
      <span class="or-failed-tests-counter-label">functional tests failed</span>
    </div>
    <div data-cy="duration-tests-failed-banner" class="or-failed-tests-counter-duration" *ngIf="testsSummary.failedDurationTests > 0">
      <span class="or-failed-tests-counter-label or-failed-tests-counter-failed">{{testsSummary.failedDurationTests}}</span>
      <span class="or-failed-tests-counter-label">of</span>
      <span class="or-failed-tests-counter-label or-failed-tests-counter-total">{{testsSummary.totalDurationTests}}</span>
      <span class="or-failed-tests-counter-label">duration tests failed</span>
    </div>
  </div>
  <div
    class="or-failed-tests-counter-body"
    *ngIf="!noTestsCancelled()"
    [ngClass]="{ 'or-failed-tests-counter-something-cancelled': getCancelledTestsCount() > 0}">
    <div data-cy="functional-tests-cancelled-banner" *ngIf="testsSummary.cancelledFunctionTests > 0">
      <span class="or-failed-tests-counter-label or-failed-tests-counter-failed">{{testsSummary.cancelledFunctionTests}}</span>
      <span class="or-failed-tests-counter-label">of</span>
      <span class="or-failed-tests-counter-label or-failed-tests-counter-total">{{testsSummary.totalFunctionTests}}</span>
      <span class="or-failed-tests-counter-label">functional tests cancelled</span>
    </div>
    <div data-cy="duration-tests-cancelled-banner" *ngIf="testsSummary.cancelledDurationTests > 0">
      <span class="or-failed-tests-counter-label or-failed-tests-counter-failed">{{testsSummary.cancelledDurationTests}}</span>
      <span class="or-failed-tests-counter-label">of</span>
      <span class="or-failed-tests-counter-label or-failed-tests-counter-total">{{testsSummary.totalDurationTests}}</span>
      <span class="or-failed-tests-counter-label">duration tests cancelled</span>
    </div>
  </div>
</div>
