import { NgModule } from '@angular/core';
import { MetricSelectorComponent } from '@app/analytics/metric-selector/metric-selector.component';
import { MetricWidgetComponent } from '@app/analytics/metric-widget/metric-widget.component';
import { AuthorizationModule } from '@app/shared/directives/authorization.module';
import { INITIAL_SCALE, MAXIMUM_SCALE, MINIMUM_SCALE, SCALE_FACTOR } from '@app/shared/services/image-zoom.service';
import { SharedComponentsModule } from '@app/shared/shared-components.module';
import { HttpClientModule } from '@angular/common/http';
import { QueryExecutorToken } from '@app/analytics/metric-widget/query/query-executor';
import { QueryExecutorService } from '@app/analytics/metric-widget/query/query-executor.service';

@NgModule({
  imports: [
    AuthorizationModule,
    SharedComponentsModule,
    MetricSelectorComponent,
    MetricWidgetComponent,
    HttpClientModule
  ],
  exports: [MetricSelectorComponent, MetricWidgetComponent],
  declarations: [],
  providers: [
    { provide: QueryExecutorToken, useClass: QueryExecutorService },
    { provide: INITIAL_SCALE, useValue: 1.0 },
    { provide: MINIMUM_SCALE, useValue: 0.1 },
    { provide: MAXIMUM_SCALE, useValue: 2.0 },
    { provide: SCALE_FACTOR, useValue: 1.2 }
  ]
})
export class AnalyticsModule {}
