import { Schedule } from '../api/building/Schedule';
import { UserService } from './UserService';
import { ScheduleResource } from '@angularjs/or/angular/resources/ScheduleResource';

export class ScheduleService {
  constructor(private scheduleResource: ScheduleResource, private userService: UserService) {}

  public saveSchedule(schedule: Schedule): Promise<Schedule> {
    return new Promise((resolve, reject) => {
      this.userService.getCurrentBuilding().then((building) => {
        this.scheduleResource
          .addResource(building.id, schedule)
          .then((schedule) => {
            resolve(schedule);
          })
          .catch((e) => {
            alert(`Unable to create schedule`);
            reject(e);
          });
      });
    });
  }

  public updateSchedule(schedule: Schedule): Promise<Schedule> {
    return new Promise((resolve, reject) => {
      this.scheduleResource
        .updateResource(schedule.id, schedule)
        .then((schedule) => {
          resolve(schedule);
        })
        .catch((e) => {
          alert(`Unable to update schedule`);
          reject(e);
        });
    });
  }

  public getSchedules(): Promise<Schedule[]> {
    return new Promise((resolve, reject) => {
      this.userService.getCurrentBuilding().then((building) => {
        this.scheduleResource.retrieveAllByParentId(building.id).then((schedules: Schedule[]) => {
          resolve(schedules.map((schedule) => Schedule.clone(schedule)));
        });
      });
    });
  }

  public deleteSchedule(schedule: Schedule): Promise<{}> {
    return this.scheduleResource.delete(schedule.id);
  }

  public moveUp(schedule: Schedule): Promise<{}> {
    return this.scheduleResource.moveUp(schedule.id);
  }

  public moveDown(schedule: Schedule): Promise<{}> {
    return this.scheduleResource.moveDown(schedule.id);
  }

  public getEditableIds(): Promise<number[]> {
    return this.userService.getCurrentBuilding().then((building) => {
      return this.scheduleResource.getEditableIds(building.id);
    });
  }
}
