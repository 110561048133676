export class DayOfTheWeek {
  constructor(public value: DayOfTheWeek_, public ordinal: number) {}

  public static MONDAY = new DayOfTheWeek('MONDAY', 1);
  public static TUESDAY = new DayOfTheWeek('TUESDAY', 2);
  public static WEDNESDAY = new DayOfTheWeek('WEDNESDAY', 3);
  public static THURSDAY = new DayOfTheWeek('THURSDAY', 4);
  public static FRIDAY = new DayOfTheWeek('FRIDAY', 5);
  public static SATURDAY = new DayOfTheWeek('SATURDAY', 6);
  public static SUNDAY = new DayOfTheWeek('SUNDAY', 7);

  public static all() {
    return [
      DayOfTheWeek.MONDAY,
      DayOfTheWeek.TUESDAY,
      DayOfTheWeek.WEDNESDAY,
      DayOfTheWeek.THURSDAY,
      DayOfTheWeek.FRIDAY,
      DayOfTheWeek.SATURDAY,
      DayOfTheWeek.SUNDAY
    ];
  }

  public static fromOrdinal(ordinal: number): DayOfTheWeek {
    return DayOfTheWeek.all()[ordinal - 1];
  }

  public shortName(): string {
    return this.value.substr(0, 3);
  }

  public toString() {
    return this.value;
  }
}

type DayOfTheWeek_ =
  | 'MONDAY'
  | 'TUESDAY'
  | 'WEDNESDAY'
  | 'THURSDAY'
  | 'FRIDAY'
  | 'SATURDAY'
  | 'SUNDAY';
