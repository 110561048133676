import { Gateway } from '../api/building/Gateway';
import { ISimpleService } from '../api/ISimpleService';
import { SavedEntity } from '../api/SavedEntity';
import { IResource } from '../api/IResource';

export class GatewayService {
  public gateways: Gateway[];
  private endpoints = {
    gateway: '/api/v1/gateway',
    building: '/api/v1/building'
  };

  constructor(
    private simpleService: ISimpleService<
      Gateways,
      SavedEntity<Gateway, number>,
      void,
      void
    >,
    private gatewayResource: IResource<Gateway, number>,
    private generateApiUrl: (endpoint: string) => string
  ) {}

  public getGateways(buildingId: number): Promise<Gateways> {
    return this.simpleService.get(
      this.generateApiUrl(`${this.endpoints.building}/${buildingId}/gateway`)
    );
  }

  public getGateway(gatewayId: number): Promise<Gateway> {
    return this.gatewayResource.retrieve(gatewayId);
  }

  public deleteGateway(gatewayId): Promise<{}> {
    return this.gatewayResource.delete(gatewayId);
  }

  public addGateway(
    gatewayDetails: Gateway
  ): Promise<SavedEntity<Gateway, number>> {
    const gateway = Gateway.clone(gatewayDetails);
    return this.gatewayResource.add(gateway);
  }

  public updateGateway(id: number, gatewayDetails: Gateway): Promise<{}> {
    const gateway = Gateway.clone(gatewayDetails);
    return this.gatewayResource.update(id, gateway);
  }

  public produceCleanGateway(buildingId): Gateway {
    return new Gateway(null, null, null, buildingId);
  }

  public generateKeys(gatewayId): Promise<void> {
    return this.simpleService.put(
      this.generateApiUrl(`${this.endpoints.gateway}/generatekeys/${gatewayId}`)
    );
  }
}

export class Gateways {
  constructor(public gateway: Gateway[]) {}
}
