<form [formGroup]='tagForm' novalidate='novalidate' data-cy='new-tag-form'>
  <div class='flex flex-col'>
    <div class='px-2 pb-4'>
      <h1 class='text-xl' data-cy='new-tag-title'>Create New Tag</h1>
    </div>
    <div class='flex flex-col lg:flex-row'>
      <div class='or-inline-item or-form-edit-tag-name my-2 lg:my-0 w-full'>
        <input
          data-cy='new-tag-name-input'
          matInput
          id='tag-name-new'
          class='or-input'
          type='text'
          placeholder='Tag name'
          formControlName='name' />
      </div>
      <div class='flex flex-row'>
        <div class='or-inline-item or-form-edit-tag-color'>
          <input
            data-cy='new-tag-color-input'
            matInput
            id='tag-color-new'
            class='or-input'
            type='text'
            maxlength='6'
            placeholder='Color'
            formControlName='color' />
        </div>
        <div class='or-inline-item or-form-edit-tag-preview'>
          <button
            data-cy='new-tag-color-cycle-button'
            id='tag-cycle-color-new'
            class='or-button or-iconic or-form-edit-tag-generate-color'
            type='button'
            (click)='cycleColor()'
            [ngStyle]="{'background-color': '#' + tagForm.value.color}">
            <span class='or-icon or-small or-icon-node'></span>
          </button>
        </div>
      </div>
    </div>
    <div class='flex flex-col lg:flex-row my-4'>
      <div class='w-full lg:w-1/2 mr-2 my-2 lg:my-0'>
        <button
          data-cy='new-tag-reset-button'
          id='tag-reset-new'
          class='w-full mr-2 or-primary or-negative rounded text-sm'
          type='button'
          (click)='reset()'
          [disabled]='tagForm.pristine'>
          <span class='or-icon or-small or-inverse or-icon-reload'></span>
          <span class='or-button-label'>Reset</span>
        </button>
      </div>
      <div class='w-full lg:w-1/2'>
        <button
          data-cy='new-tag-add-button'
          id='tag-save-new'
          class='w-full or-primary or-positive text-sm rounded'
          type='button'
          (click)='create()'
          [disabled]='tagForm.pristine || tagForm.invalid'>
          <span class='or-icon or-small or-inverse or-icon-plus'></span><span class='or-button-label'>Add Tag</span>
        </button>
      </div>
    </div>
  </div>
</form>
