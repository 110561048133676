<div>
  <div class='tile medium'>
    <div class='tile-header mat-elevation-z8'>
      <h2 style='font-size: 15px;'>Calendar Events</h2>
    </div>
    <div class='mat-spinner spinner-container'
         data-cy='invitations-spinner-icon'
         *ngIf='calendarDataSource?.loading$ | async'>
      <mat-spinner></mat-spinner>
    </div>
    <table
      aria-label='List of calendar events'
      mat-table
      [dataSource]='calendarDataSource'
      class='mat-elevation-z8 invitations-table'>
      <!-- Name -->
      <ng-container matColumnDef='name'>
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell class='name-cell' *matCellDef='let calendarEvent'>{{calendarEvent.name}}</td>
      </ng-container>
      <!-- Date -->
      <ng-container matColumnDef='date'>
        <th mat-header-cell *matHeaderCellDef>Date</th>
        <td mat-cell class='date-cell' *matCellDef='let calendarEvent'>{{dateInLocale(calendarEvent.date)}}</td>
      </ng-container>
      <!-- Actions -->
      <ng-container matColumnDef='actions'>
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell class='actions-cell' *matCellDef='let calendarEvent'>
          <button data-cy="edit-tenant-button"
                  class="action-button edit"
                  mat-mini-fab
                  (click)="updateCalendarEvent(calendarEvent)"
                  matTooltip="Save tenant details"
                  matTooltipClass="mat-tooltip"
                  matTooltipPosition="above"
                  aria-label="Button to edit tenant details">
            <mat-icon>edit</mat-icon>
          </button>
          <button
            matTooltip='Delete this calendar event'
            matTooltipClass='mat-tooltip'
            matTooltipPosition='above'
            aria-label='Button to delete this calendar event'
            data-cy='delete-calendar-event-button'
            class='action-button delete'
            mat-mini-fab color='warn'
            (click)='removeCalendarEvent(calendarEvent)'>
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>
      <!-- Rows -->
      <tr mat-header-row *matHeaderRowDef='invitationDisplayedColumns'></tr>
      <tr mat-row *matRowDef='let row; columns: invitationDisplayedColumns;'></tr>
    </table>
    <mat-paginator *ngIf='calendarDataSource'
                   class='mat-elevation-z8'
                   #calendarPaginator
                   [length]='calendarDataSource.counter$ | async'
                   [pageSizeOptions]='[calendarEventsPageSize]'
                   [pageSize]='calendarEventsPageSize'
                   (page)="calendarDataSource.loadCalendarEvents(buildingId, $event.pageIndex, $event.pageSize)"
                   showFirstLastButtons
                   aria-label='Select page for Invitation'>
    </mat-paginator>
  </div>
</div>
<div class='new-event-button'>
  <button
    matTooltip='Add event to this building'
    matTooltipClass='mat-tooltip font-15'
    matTooltipPosition='above'
    aria-label='Button to add events to this building'
    mat-fab
    (click)='addCalendarEvent()'
    class='mat-elevation-z8'
    color='primary'>
    <mat-icon>add</mat-icon>
  </button>
</div>
