<div id='orAdministrationMobileTokens'>
  <div class='or-content-body'>
    <mat-spinner *ngIf='isBusy'></mat-spinner>
    <div class='or-form-list or-box'>
      <fieldset style='font-size: 1.4rem'>
        <legend>Mobile Tokens</legend>
      </fieldset>
      <div class='or-content-body' *ngIf='!isBusy'>
        <!--        <or-banner-no-tokens ng-hide="administrationMobileTokens.tokens.length"></or-banner-no-tokens>-->
        <ul class='or-stacked-items or-tokens-list'>
          <li class='or-stacked-item or-tokens-list-item' *ngFor='let token of mobileTokens; let i=index'>
            <app-form-mobile-token-details
              [isBusy]='isBusy'
              [isNew]='false'
              [token]='token'
              [isDeactivated]='false'
              [building]='building'
              [index]='i'
              (valueChanged)='loadDetails()'>
            </app-form-mobile-token-details>
          </li>
          <li class='or-stacked-item or-tokens-list-item'>
            <app-form-mobile-token-details
              [isBusy]='isBusy'
              [isNew]='true'
              [index]='0'
              [building]='building'
              [isDeactivated]='false'
              (valueChanged)='loadDetails()'>
            </app-form-mobile-token-details>
          </li>
        </ul>
        <div>
          <app-checkbox [(ngModel)]='showDeactivatedTokens'
                        ngDefaultControl
                        (valueChange)='toggleShowDeactivatedTokens()'
                        [dataCy]="'checkbox-show-deactivated-tokens'">
          </app-checkbox>
          <span class='margin-left-10'></span>
          <label>Show deactivated tokens</label>
        </div>
        <ul class='or-stacked-items or-tokens-list'
          *ngIf='deactivatedMobileTokens && deactivatedMobileTokens.length > 0 && showDeactivatedTokens'>
          <li class='or-stacked-item or-tokens-list-item' *ngFor='let token of deactivatedMobileTokens; let i=index'>
            <app-form-mobile-token-details
              [isBusy]='isBusy'
              [isNew]='false'
              [building]='building'
              [token]='token'
              [isDeactivated]='true'
              [index]='i'>
            </app-form-mobile-token-details>
          </li>
        </ul>
      </div>
    </div>
    <div class='or-form-list or-box'>
      <fieldset style='font-size: 1.4rem'>
        <legend>BLE Keys</legend>
      </fieldset>
      <div class='or-content-body' *ngIf='!isBusy'>
        <!--        <or-banner-no-keys ng-hide="administrationMobileTokens.keys.length"></or-banner-no-keys>-->
        <ul class='or-stacked-items or-ble-keys-list'>
          <li class='or-stacked-item or-ble-keys-list-item'>
            <app-form-ble-key-details
              [key]='currentBleKey'
              [building]='building'
              [index]='0'
              [isBusy]='isBusy'
              [isNew]='false'
              [isDeactivated]='false'
              (valueChanged)='loadDetails()'>
            </app-form-ble-key-details>
          </li>
          <li class='or-stacked-item or-ble-keys-list-item'>
            <app-form-ble-key-details
              [index]='0'
              [building]='building'
              [isBusy]='isBusy'
              [isNew]='true'
              [isDeactivated]='false'
              (valueChanged)='loadDetails()'>
            </app-form-ble-key-details>
          </li>
        </ul>
        <div>
          <app-checkbox
            [(ngModel)]='showPreviousKeys'
            ngDefaultControl
            (valueChange)='toggleShowPreviousKeys()'
            [dataCy]="'checkbox-show-previous-keys'">
          </app-checkbox>
          <span class='margin-left-10'></span>
          <label>Show previous keys that exist</label>
        </div>
        <ul class='or-stacked-items or-ble-keys-list' *ngIf='previousBleKeys && previousBleKeys.length && showPreviousKeys'>
          <li class='or-stacked-item or-ble-keys-list-item' *ngFor='let key of previousBleKeys; let i=index'>
            <app-form-ble-key-details
              [key]='key'
              [building]='building'
              [index]='i'
              [isNew]='false'
              [isBusy]='false'
              [isDeactivated]='true'
              (valueChanged)='loadDetails()'>
            </app-form-ble-key-details>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
