import { OrFormRuleDetailsController } from './OrFormRuleDetailsController';

export class OrFormRuleDetailsDirective {
  constructor() {
    return {
      bindToController: true,
      controller: ['$scope', OrFormRuleDetailsController],
      controllerAs: 'ruleDetails',
      replace: true,
      restrict: 'E',
      require: 'ngModel',
      scope: {
        rule: '=ngModel',
        ruleOptions: '=',
        tagOptions: '=',
        floorOptions: '=',
        onRemoveRule: '&',
        onChange: '&'
      },
      template: require('raw-loader!./or-form-rule-details.html').default
    };
  }
}
