import { GlobalAuthority } from '@app/shared/models/global-authority';
import { User } from '@angularjs/or/api/auth/User';

export interface IGlobalAuthorityRequest {
  userId: number;
  globalAuthority: GlobalAuthority;
  requestTimestamp: Date;
  emailSent: Date;
  message: string;
  managingCompanyName: string;
  projectName: string;
  status: Status;
  user: User;
}

export class GlobalAuthorityRequest implements IGlobalAuthorityRequest {
  public userId: number;
  public globalAuthority: GlobalAuthority;
  public requestTimestamp: Date;
  public emailSent: Date;
  public message: string;
  public managingCompanyName: string;
  public projectName: string;
  public status: Status;
  public user: User;

  constructor(globalAuthorityRequest: IGlobalAuthorityRequest) {
    this.userId = globalAuthorityRequest.userId;
    this.globalAuthority = globalAuthorityRequest.globalAuthority;
    this.requestTimestamp = globalAuthorityRequest.requestTimestamp;
    this.emailSent = globalAuthorityRequest.emailSent;
    this.message = globalAuthorityRequest.message;
    this.managingCompanyName = globalAuthorityRequest.managingCompanyName;
    this.projectName = globalAuthorityRequest.projectName;
    this.status = globalAuthorityRequest.status;
    this.user = globalAuthorityRequest.user;
  }
}

export enum Status {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  DENIED = 'DENIED'
}
