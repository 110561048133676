<mat-card appearance="outlined" class='or-notification-banner'
          [ngClass]='getStatusClass()'
          [ngStyle]="{'width.px': width, 'height.px': height }">
  <mat-card-header data-cy="notification-header" [ngClass]='getStatusClass()'>
    <mat-icon>{{getIcon()}}</mat-icon>
    <span class='margin-left-10'></span>
    {{header}}
  </mat-card-header>
  <mat-card-content data-cy="notification-content">
    {{content}}
    <span class="p-4">
      <ng-content></ng-content>
    </span>
  </mat-card-content>
</mat-card>

