import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Environment, environmentToken } from '@environment';
import { Observable } from 'rxjs';
import { DuplicateMapping } from '@app/shared/models/duplicate-mapping';

@Injectable({
  providedIn: 'root'
})
export class DuplicateMappingResource {
  private readonly baseUrl: string;
  constructor(protected httpClient: HttpClient, @Inject(environmentToken) environment: Environment) {
    this.baseUrl = `${environment.apiUrl}${environment.apiPath}/duplicate-mapping`;
  }

  getDuplicateAddressMappingsByNodeIds(
    buildingId: number,
    nodeIds: number[]
  ): Observable<Record<number, DuplicateMapping[]>> {
    return this.httpClient.post<Record<number, DuplicateMapping[]>>(
      `${this.baseUrl}/${buildingId}/nodes/address`,
      nodeIds
    );
  }

  getDuplicateGroupMappings(buildingId: number, nodeIds: number[]): Observable<Record<number, DuplicateMapping[]>> {
    return this.httpClient.post<Record<number, DuplicateMapping[]>>(
      `${this.baseUrl}/${buildingId}/nodes/group`,
      nodeIds
    );
  }

  getDuplicateGroupMappingsByNodeIds(
    buildingId: number,
    nodeIds: number[]
  ): Observable<Record<number, DuplicateMapping[]>> {
    return this.httpClient.post<Record<number, DuplicateMapping[]>>(
      `${this.baseUrl}/${buildingId}/nodes/group`,
      nodeIds
    );
  }
}
