export class LightingConfiguration {
  public nodeId: number;
  public zone: number;
  public zoneUpdatedAt: Date;
  public maxLightLevel: number;
  public maxLightLevelUpdatedAt: Date;
  public minLightLevel: number;
  public minLightLevelUpdatedAt: Date;
  public lowLightLevel: number;
  public lowLightLevelUpdatedAt: Date;
  public dwellTime: number;
  public dwellTimeUpdatedAt: Date;
  public lowLightTime: number;
  public lowLightTimeUpdatedAt: Date;
  public updateStatus: string;
  public scene: {
    value: number;
    updatedAt: Date;
  };

  constructor(
    nodeId: number,
    zone?: number,
    scene?: {
      value: number;
      updatedAt: Date;
    },
    maxLightLevel?: number,
    minLightLevel?: number,
    lowLightLevel?: number,
    dwellTime?: number,
    lowLightTime?: number,
    updateStatus?: string
  ) {
    this.nodeId = nodeId;
    this.zone = zone;
    this.scene = scene;
    this.maxLightLevel = maxLightLevel;
    this.minLightLevel = minLightLevel;
    this.lowLightLevel = lowLightLevel;
    this.dwellTime = dwellTime;
    this.lowLightLevel = lowLightTime;
    this.updateStatus = updateStatus;
  }
}
