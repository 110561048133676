import { OrBannerNoSchedulesController } from './OrBannerNoSchedulesController';

export class OrBannerNoSchedulesDirective {
  constructor() {
    return {
      bindToController: true,
      controller: OrBannerNoSchedulesController,
      controllerAs: 'banner',
      replace: true,
      restrict: 'E',
      scope: {},
      template: require('raw-loader!./or-banner-no-schedules.html').default
    };
  }
}
