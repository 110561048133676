<div id="tenant-content-container">
  <div class="table-container">
    <div id="orAdministrationTenantGroups" class="administration-tenants">
      <div class="tile medium">
        <div class="tile-header mat-elevation-z8">
          <h2 style="font-size: 15px;">Tenants</h2>
        </div>
        <table
          aria-label="List of Tenants"
          mat-table
          matSort
          class="tenants-table mat-elevation-z8"
          [dataSource]='tenantDatasource'>
          <!-- ID Column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> # </th>
            <td mat-cell data-cy="tenant-id" *matCellDef="let tenant">{{tenant.id}}</td>
          </ng-container>
          <!-- Tenant Name -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="name"> Tenant Name </th>
            <td mat-cell data-cy="tenant-name" class="name-cell" *matCellDef="let tenant">{{tenant.name}}</td>
          </ng-container>
          <!-- Actions -->
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell class="actions-cell" *matCellDef="let tenant">
              <button data-cy="edit-tenant-button"
                      class="action-button edit"
                      mat-mini-fab (click)="doUpdate(tenant)"
                      matTooltip="Save tenant details"
                      matTooltipClass="mat-tooltip"
                      matTooltipPosition="above"
                      aria-label="Button to edit tenant details">
                <mat-icon>edit</mat-icon>
              </button>
              <span>
                <button data-cy="delete-tenant-button"
                        class="action-button delete"
                        mat-mini-fab color="warn"
                        (click)="doDeleteUsers(tenant)"
                        matTooltip="Remove users from this Tenant"
                        matTooltipClass="mat-tooltip"
                        matTooltipPosition="above"
                        aria-label="Button to delete tenant users">
                  <mat-icon>group_remove</mat-icon>
                </button>
              </span>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="tenantDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: tenantDisplayedColumns"></tr>
        </table>
        <mat-card class="mat-spinner spinner-container mat-elevation-z8" data-cy="tenants-spinner-icon" *ngIf="tenantDatasource?.loading$ | async">
          <mat-spinner></mat-spinner>
        </mat-card>
        <mat-paginator *ngIf='tenantDatasource'
                       class="mat-elevation-z8"
                       [length]="tenantDatasource.counter$ | async"
                       [pageSizeOptions]="[tenantsPageSize]"
                       [pageSize]="tenantsPageSize"
                       (page)='tenantDatasource.loadTenants(null, $event.pageIndex, $event.pageSize)'
                       showFirstLastButtons
                       aria-label="Select page for Users">
        </mat-paginator>
      </div>
      <div class="add-tenant-button">
        <button
          matTooltip="Add a tenant to this building"
          matTooltipClass="mat-tooltip font-15"
          matTooltipPosition="above"
          aria-label="Button to add tenants to this building"
          data-cy="create-tenant"
          mat-fab
          (click)="doAdd()"
          class="mat-elevation-z8 button-alignment"
          color="primary">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="table-container">
    <app-tenant-user [reload]='reloadUsers' isFeatureAvailable='tenantUser' >
    </app-tenant-user>
  </div>
</div>
