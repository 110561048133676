import * as angular from 'angular'; // Automatically added
import { Floor } from '@angularjs/or/api/building/Floor';
import { SensorNodeService } from '@angularjs/or/services/SensorNodeService';
import IFormController = angular.IFormController;

export class OrFormFloorDetailsController {
  public isNew: boolean;
  public isBusy: boolean;
  public details: Floor;
  public form: IFormController;
  public onAddFloor;
  public onUpdateFloor;
  public onDeleteFloor;
  public onResetFloor;
  public floorplanImage: string;
  public imageUrl: string;
  public isRemovable: boolean;

  constructor(
    private floorplanUrlGenerator: (Floor) => string,
    private scope: angular.IScope,
    private nodeService: SensorNodeService
  ) {}

  public $onInit() {
    this.isBusy = true;
    this.floorplanImage = this.details.floorplan;
    this.imageUrl = this.floorplanUrlGenerator(this.details);

    this.scope.$watch('floorDetails.details', () => {
      this.imageUrl = this.floorplanUrlGenerator(this.details);
    });
    if (this.isNew) {
      this.isRemovable = true;
      this.isBusy = false;
    } else {
      this.nodeService
        .getSensorNodesForFloor(this.details.id)
        .then((response) => {
          this.scope.$apply(() => {
            this.isRemovable = response.length == 0;
            this.isBusy = false;
          });
        })
        .catch(() => {
          this.isRemovable = false;
          this.isBusy = false;
        });
    }
  }

  public onImageUpload(floorplanImage: string) {
    this.form.$setDirty();
    this.details.floorplan = floorplanImage;
  }

  public addFloor() {
    this.resetValidationState();
    if (angular.isFunction(this.onAddFloor)) {
      this.onAddFloor();
    }
  }

  public updateFloor() {
    this.resetValidationState();
    if (angular.isFunction(this.onUpdateFloor)) {
      this.onUpdateFloor();
    }
  }

  public deleteFloor() {
    this.resetValidationState();
    if (angular.isFunction(this.onDeleteFloor)) {
      this.onDeleteFloor();
    }
  }

  public resetFloor() {
    if (!confirm('Changes made in the selected floor will be discarded.')) {
      return;
    }
    this.resetValidationState();
    if (angular.isFunction(this.onResetFloor)) {
      this.onResetFloor();
    }
  }

  private resetValidationState() {
    this.form.$setPristine();
    this.form.$setUntouched();
  }
}
