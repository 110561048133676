import { HeatmapDataPoint, HeatmapRenderer } from './HeatmapRenderer';
import { DataInterpolator } from '../interpolator/DataInterpolator';
import { GridProvider } from '../Grid';

export class GridRenderer implements HeatmapRenderer {
  constructor(
    private underlying: HeatmapRenderer,
    private gridProvider: GridProvider,
    private interpolatorFactory: (radius: number) => DataInterpolator
  ) {}

  render(
    dataPoints: HeatmapDataPoint[],
    canvas: HTMLCanvasElement,
    ctx: CanvasRenderingContext2D,
    max: number,
    width: number,
    height: number
  ) {
    const grid = this.gridProvider.compute(width, height);
    const points = this.interpolatorFactory(grid.distance * 0.5).interpolate(
      grid.points,
      dataPoints
    );
    this.underlying.render(points, canvas, ctx, max, width, height);
  }
}
