<form class='schedule-form' name='scheduleForm' [formGroup]='scheduleForm' (ngSubmit)="save()" data-cy="schedule-form">
  @for(key of Object.keys(scheduleForm.errors ? scheduleForm.errors : {}); track key; let i = $index) {
    <mat-error data-cy="schedule-form-error-message" class='error'>{{(i+1) + '. '+scheduleForm.errors[key]}}</mat-error>
  }
  @if (details.hasRan) {
    <app-notification-block
      [header]="'Restricted Editing'"
      [statusClass]="StatusClass.WARNING"
      data-cy="'edit-elmt-schedule-warning-message'">
      Since this schedule has run previously, not all fields are editable.
    </app-notification-block>
  }
  <div class='schedule-form-inline-picker'>
    <mat-form-field appearance='fill'>
      <mat-label>Schedule Name</mat-label>
      <input [attr.data-cy]="scheduleForm?.value?.name+'-schedule-name-input'" matInput formControlName='name'>
      @if (scheduleForm.controls.name?.errors?.required) {
        <mat-error>
          Schedule name is required
        </mat-error>
      }
    </mat-form-field>
    <mat-form-field appearance='fill' [class]="'tests-type-form-field'">
      <mat-label>Test Type</mat-label>
      <mat-select
        [ngClass]="'mat-select-arrow'"
        [attr.data-cy]="scheduleForm?.value?.name+'-schedule-test-type-list'"
        formControlName='testsType'
        (selectionChange)="onChangeTestType()">
        @for (testType of testTypes; track testType) {
          <mat-option [value]='testType'>{{testType.displayValue}}</mat-option>
        }
      </mat-select>
      @if (scheduleForm.controls.testsType?.errors?.required) {
        <mat-error>
          Please select either Functional or Duration
        </mat-error>
      }
    </mat-form-field>
  </div>
  <div>
    <label class='or-label or-form-rule-details-label'>If no floors or tags are selected, the schedule will be applied to
      the whole building</label>
  </div>
  <div class='schedule-form-inline-picker'>
    <mat-label>On specific floor (Optional)</mat-label>
    <app-inline-floors
      [isEditable]="!details.hasRan"
      [floors]="floors"
      [selectedFloorIds]="details.floorIds"
      (updatedSelection)='selectedFloorsChanged($event)'
    ></app-inline-floors>
  </div>
  @if (tags$ | async; as tags) {
    <div class='schedule-form-inline-picker'>
      <mat-label>For tags (Optional)</mat-label>
      <app-inline-tags
        [isEditable]="!details.hasRan"
        [tags]="tags.tags"
        [displayTags]='tags.displayTags'
        [selectedTagIds]='details.tagIds'
        (updatedSelection)='selectedTagsChanged($event)'
      ></app-inline-tags>
    </div>
  }
  <div class='schedule-form-inline-date-time'>
    <mat-form-field appearance='fill' class='schedule-form-date'>
      <mat-label>Start Date</mat-label>
      <input
        [attr.data-cy]="scheduleForm?.value?.name+'-schedule-start-date-input'"
        matInput
        [min]="minSelectableDate"
        formControlName='startDate'
        [placeholder]="'DD/MM/YYYY'"
        [matDatepicker]='datepicker'
        (dateChange)="onChangeStartDate()">
      <mat-datepicker-toggle matSuffix [for]='datepicker'></mat-datepicker-toggle>
      <mat-datepicker #datepicker></mat-datepicker>
      @if (scheduleForm.controls.startDate?.errors?.required) {
        <mat-error>Start date is required</mat-error>
      }
      @if (!details.hasRan && scheduleForm.controls.startDate?.errors?.matDatepickerMin) {
        <mat-error>Start date cannot be in the past</mat-error>
      }
    </mat-form-field>
    <mat-form-field appearance='fill' >
      <mat-label>Start time:</mat-label>
      <mat-select
        [ngClass]="'mat-select-arrow'"
        formControlName='startTime'
        [attr.data-cy]="scheduleForm?.value?.name+'-schedule-start-time-list'">
        @for (time of timeOptions; track time) {
          <ng-container>
            <mat-option [disabled]="time.disabled" [value]='time'>{{time.timeString}}</mat-option>
          </ng-container>
        }
      </mat-select>
      @if (scheduleForm.controls.startTime?.errors?.required) {
        <mat-error>
          Start time is required
        </mat-error>
      }
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance='fill' class="schedule-form-repeat">
      <mat-label>Repeat</mat-label>
      <mat-select
        [ngClass]="'mat-select-arrow'"
        formControlName='scheduleRecurrence'
        (selectionChange)='onChangeScheduleRecurrence($event.value)'
        (openedChange)="onOpenScheduleRecurrence($event)"
        [attr.data-cy]="scheduleForm?.value?.name+'-repeat-list'">
        @for (basicRepeatOption of repeatOptions; track basicRepeatOption.displayName) {
          <mat-option [value]='basicRepeatOption'>{{basicRepeatOption.displayName}}</mat-option>
        }
      </mat-select>
      @if (scheduleForm.controls.scheduleRecurrence?.errors?.required) {
        <mat-error>
          Please enter schedule recurrence
        </mat-error>
      }
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance='fill'>
      <mat-label>Scheduling strategy</mat-label>
      <mat-select
        [ngClass]="'mat-select-arrow'"
        formControlName='schedulingStrategy'
        (selectionChange)="onChangeSchedulingStrategy()"
        [attr.data-cy]="scheduleForm?.value?.name+'-scheduling-strategy-list'">
        @for (schedulingStrategy of schedulingStrategies; track schedulingStrategy.value) {
          <mat-option [value]='schedulingStrategy'>{{schedulingStrategy.displayValue}}</mat-option>
        }
      </mat-select>
      @if (scheduleForm.controls.schedulingStrategy?.errors?.required) {
        <mat-error>
          Please enter scheduling strategy
        </mat-error>
      }
    </mat-form-field>
  </div>
  <div>
    <app-timerange-widget
      [startTime]='scheduleForm.value.startTime?.timeString'
      [endTime]='scheduleForm.value.endTime'
      [minHours]='1'
      [isValidRange]='isValidTimeRange'
    ></app-timerange-widget>
  </div>
  <div>
    <ul class='or-inline-items' appIsAuthorizedForBuilding="MANAGE_EMERGENCY_LIGHTING"
        [appBuildingIdToAuthorize]="buildingId">
      <li class='or-inline-item'>
        <button
          [disabled]="scheduleForm.invalid || !scheduleForm.dirty"
          mat-raised-button
          color='primary'
          [attr.data-cy]="'save-elmt-schedule-button-' + scheduleForm?.value?.name"
          type="submit">
          <span class='or-icon or-small or-inverse or-icon-checkmark'></span>
          <span class='or-button-label'>Save</span>
        </button>
      </li>
      <li class='or-inline-item'>
        <button
          mat-raised-button
          color='accent'
          (click)="close()"
          type="button"
          [attr.data-cy]="'reset-and-close-elmt-schedule-button-' + scheduleForm?.value?.name">
          <span class='or-icon or-small or-icon-reload'></span>
          <span class='or-button-label' [textContent]="isNew ? 'Close' : 'Reset and close' "></span>
        </button>
      </li>
      @if (!isNew && !details.hasRan) {
        <li class='or-inline-item or-form-schedule-action'>
          <button
            mat-raised-button
            color='warn'
            (click)="delete()"
            type="button"
            [attr.data-cy]="'delete-elmt-schedule-button-' + scheduleForm?.value?.name">
            <span class='or-icon or-small or-inverse or-icon-trash'></span>
            <span class='or-button-label'>Delete schedule</span>
          </button>
        </li>
      }
    </ul>
  </div>
</form>
