import { IBacnetArea } from './IBacnetArea';
import { GatewayService } from '../GatewayService';
import { ISimpleService } from '@angularjs/or/api/ISimpleService';
import { SavedEntity } from '@angularjs/or/api/SavedEntity';

const ERR_NO_AREA = 'No valid BACnet area provided';
const ERR_NO_AREA_ID = 'No valid BACnet area id provided';
const ERR_NO_BUILDING_ID = 'No valid building id provided';

export class BacnetAreaService {
  private endpoints = {
    areas: '/api/v1/building/:buildingId/bacnet-area',
    area: '/api/v1/bacnet-area',
    building: '/api/v1/building'
  };

  constructor(
    private simpleService: ISimpleService<
      IBacnetArea[],
      SavedEntity<IBacnetArea, number>,
      void,
      void
    >,
    private gatewayService: GatewayService,
    private generateApiUrl: (endpoint: string) => string
  ) {}

  // `GET /api/v1/building/{buildingId}/bacnet-area` doesn't send gateway
  // identifiers at this stage, so this piece is commented out. The areas and
  // ids (or rather, full gateway objects) for now are being extracted from
  // gateways and mixed together to reflect the original logic.
  // public getAreas(buildingId) {
  //     if (!buildingId) {
  //         console.error(`BacnetAreaService: ${ERR_NO_BUILDING_ID}`);
  //         return;
  //     }
  //     return this.gatewayService.getGateways(buildingId)
  //         .then(response => {
  //             const gateways = response.gateway;
  //             const areas = this.extractAreas(gateways);
  //             return Promise.resolve(areas);
  //         })
  //         .catch(response => {
  //             console.error({ getAreas: response });
  //             return Promise.reject(response);
  //         });
  // }

  private extractAreas(gateways) {
    const areas = gateways.reduce((_areas, gateway) => {
      gateway.bacnetAreas.forEach((area) => {
        area.gateway = gateway;
      });
      return _areas.concat(gateway.bacnetAreas);
    }, []);
    return areas;
  }

  public getArea(areaId: number) {
    if (!areaId) {
      console.error(`BacnetAreaService: ${ERR_NO_AREA_ID}`);
      return;
    }
    const url = `${this.endpoints.area}/${areaId}`;
    return this.simpleService.get(this.generateApiUrl(url));
  }

  public getAreas(buildingId: number) {
    if (!buildingId) {
      console.error(`BacnetAreaService: ${ERR_NO_BUILDING_ID}`);
      return;
    }
    const endpoint = this.endpoints.areas;
    const url = endpoint.replace(':buildingId', buildingId.toString());
    console.debug({
      url,
      endpoint: this.endpoints.areas
    });

    return this.simpleService.get(this.generateApiUrl(url));
  }

  public addArea(area: IBacnetArea) {
    if (!area) {
      console.error(`BacnetAreaService: ${ERR_NO_AREA}`);
      return;
    }
    const url = this.endpoints.area;
    return this.simpleService.post(this.generateApiUrl(url), area);
  }

  public updateArea(area: IBacnetArea) {
    if (!area) {
      console.error(`BacnetAreaService: ${ERR_NO_AREA}`);
      return;
    }
    const url = `${this.endpoints.area}/${area.id}`;
    return this.simpleService.put(this.generateApiUrl(url), area);
  }

  public deleteArea(areaId: number) {
    if (!areaId) {
      console.error(`BacnetAreaService: ${ERR_NO_AREA_ID}`);
      return;
    }
    const url = `${this.endpoints.area}/${areaId}`;
    return this.simpleService.delete(this.generateApiUrl(url));
  }
}
