import { SensorNode } from '@angularjs/or/api/building/SensorNode';
import { Map } from '@angularjs/or/util/Map';
import { ISecurityService } from '@angularjs/or/services/ISecurityService';
import { GlobalAuthority } from '@app/shared/models/global-authority';
import { CopyToClipboardService } from '@angularjs/or/angular/services/CopyToClipboardService';
import { FloorplanSensorNode } from '@angularjs/or/api/building/FloorplanSensorNode';
import { FilterService } from '@angularjs/or/services/FilterService';
import {
  Entity,
  UIRefreshService
} from '@angularjs/or/services/UIRefreshService';
import { IScope } from 'angular';
import { SensorNodeService } from '@angularjs/or/services/SensorNodeService';

export class OrNodesListController {
  public isBusy: boolean;
  public isCompact: boolean;
  protected nodes: FloorplanSensorNode[];
  public filter: SensorNode = SensorNode.from({ nodeType: '' } as SensorNode);
  public fields: Map<string> = SensorNode.getOrderableProperties();
  public allNodeTypes: Map<string> = SensorNode.getNodeTypes();
  public activeNode;
  protected showHidden: boolean;
  public constructor(
    protected $scope: IScope,
    protected $element,
    protected securityService: ISecurityService,
    protected copyToClipboardService: CopyToClipboardService,
    protected filterService: FilterService,
    protected uiRefreshService: UIRefreshService,
    protected nodeService: SensorNodeService
  ) {}
  public $onInit(): void {
    this.securityService
      .isAuthorized(GlobalAuthority.VIEW_HIDDEN_COMPONENTS.toString())
      .then((showHidden) => {
        this.showHidden = showHidden;
      });
    this.nodeService.refreshFloorPlanNodes.subscribe((nodes) => {
      this.nodes = nodes;
    });
  }

  public setActiveNode(node) {
    this.activeNode = node;
  }

  public get sortBy(): string {
    return this.filterService.sortBy;
  }

  public set sortBy(value: string) {
    this.filterService.sortBy = value;
  }

  public get reverse(): boolean {
    return this.filterService.reverse;
  }

  public set reverse(value: boolean) {
    this.filterService.reverse = value;
  }

  public toggleSortOrder(): void {
    this.filterService.reverse = !this.filterService.reverse;
  }

  public getClassIfHidden(): string {
    let hiddenClass = 'or-hide-hidden';
    if (this.showHidden) {
      hiddenClass = 'or-show-hidden';
    }
    return hiddenClass;
  }

  public reloadNodes(): void {
    this.nodes = null;
    this.uiRefreshService.change(Entity.NODE);
  }

  public copyToClipboard(): void {
    if (this.nodes != null) {
      this.copyToClipboardService.copy(this.getNodesAsCsv());
    }
  }

  protected getNodesAsCsv(): string {
    const headerData =
      'Id,Address(Portal),Node Type,Connection Status,Luminaire Drivers,EM Drivers,Driver Faults,Tags\n';
    const nodesData = this.nodes
      .map((node) => {
        let tags = '';
        let lumeDrivers = '';
        let emDrivers = '';
        let nodeAlerts = '';
        const nodeType =
          node.nodeType != null ? node.nodeType : SensorNode.SN3_NODE_TYPE;
        if (node.tags != null) {
          tags = node.tags.map((tag) => tag.name).join(';');
        }
        if (
          Array.isArray(node.luminaireDrivers) &&
          node.luminaireDrivers.length > 0
        ) {
          lumeDrivers = node.luminaireDrivers.map((l) => l.address16).join(';');
        }
        if (Array.isArray(node.emDrivers) && node.emDrivers.length > 0) {
          emDrivers = node.emDrivers.map((e) => e.address16).join(';');
        }
        if (Array.isArray(node.alerts) && node.alerts.length > 0) {
          nodeAlerts = node.alerts.map((a) => a.value).join(';');
        }
        return (
          node.id +
          ',' +
          node.address16 +
          ',' +
          nodeType +
          ',' +
          node.connected +
          ',' +
          lumeDrivers +
          ',' +
          emDrivers +
          ',' +
          nodeAlerts +
          ',' +
          tags
        );
      })
      .join('\n');
    return headerData + nodesData;
  }

  public compare(actual, expected): boolean {
    const actualLower = actual ? actual.toString().toLowerCase() : '';
    const expectedLower = expected ? expected.toString().toLowerCase() : '';

    // comparing SN3 with null should return true. We need to do this check because node with nodeType null is
    // actually a SN3
    if (actual == null && expectedLower === 'sn3') {
      return true;
    }

    return (
      expectedLower === '' ||
      expectedLower
        .split(',')
        .filter((value) => value !== '')
        .filter((value) => actualLower.indexOf(value) !== -1).length > 0
    );
  }

  public scrollTo(element): void {
    const tileElement = element[0];
    const listElement = element[0].parentElement.parentElement;
    const tileParent = element[0].parentElement;
    if (listElement) {
      if (!listElement.scrollTop) {
        listElement.scrollTop = 0;
      }
      const scrollBottom = listElement.scrollTop + listElement.clientHeight;
      const tileHeight = tileElement.offsetHeight + tileElement.offsetTop;

      if (
        !(
          tileParent.offsetTop + tileHeight < scrollBottom &&
          tileParent.offsetTop - tileHeight > listElement.scrollTop
        )
      ) {
        let setTo = Math.abs(
          tileParent.offsetTop -
            (tileElement.offsetTop + tileElement.offsetHeight)
        );
        setTo = setTo < 60 ? 0 : setTo;
        listElement.scrollTop = setTo;
      }
    }
  }
}
