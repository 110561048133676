<h1 class='or-panel-toggle' [ngClass]="{'or-active': !toggleState}">
  <button class="or-panel-toggle-button"  (click)="onToggleClick()">
    <span class="or-panel-toggle-icon" [ngClass]="'or-icon-' + icon">
      <span class="or-icon or-tiny" [ngClass]="['or-icon-' + icon, !toggleState ? 'or-inverse' : '']"></span>
    </span>
    @if (!toggleState){
      <span class="or-button-label or-panel-toggle-label ng-binding">{{ label }}</span>
    }
  </button>
</h1>
