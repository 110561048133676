import { OrNodesListController } from '../or-nodes-list/OrNodesListController';
import { Map } from '@angularjs/or/util/Map';
import { SensorNode } from '@angularjs/or/api/building/SensorNode';
import { ISecurityService } from '@angularjs/or/services/ISecurityService';
import { CopyToClipboardService } from '@angularjs/or/angular/services/CopyToClipboardService';
import { FilterService } from '@angularjs/or/services/FilterService';
import { UIRefreshService } from '@angularjs/or/services/UIRefreshService';
import { BeaconSettingService } from '@angularjs/or/services/BeaconSettingService';
import { Subject } from 'rxjs';
import { SensorNodeService } from '@angularjs/or/services/SensorNodeService';
import angular from 'angular';

export class OrBeaconsNodesListController extends OrNodesListController {
  public fields: Map<string> = SensorNode.getBeaconsOrderableProperties();
  private floorId: number;
  private updatingTimeoutInSeconds = 60;
  private reloadNodesSub: Subject<boolean> = new Subject<boolean>();

  constructor(
    protected $scope: angular.IScope,
    $element,
    protected securityService: ISecurityService,
    protected copyToClipboardService: CopyToClipboardService,
    protected filterService: FilterService,
    protected uiRefreshService: UIRefreshService,
    protected beaconSettingService: BeaconSettingService,
    protected nodeService: SensorNodeService
  ) {
    super($scope, $element, securityService, copyToClipboardService, filterService, uiRefreshService, nodeService);
  }

  public $onInit() {
    super.$onInit();
    this.reloadNodesSub.subscribe((bool) => this.reloadNodes());
  }

  protected getNodesAsCsv() {
    const headerData =
      'Id,Address(Portal),Enabled,Power Level,Beacon Content,Beaconing Repetition,UUID,Major,Minor,Update Status\n';
    const nodesData = this.nodes
      .map((node) => {
        return (
          node.id +
          ',' +
          node.address16 +
          ',' +
          (node.beaconEnabled ? node.beaconEnabled : 'false') +
          ',' +
          node.beaconPowerLevel +
          ',' +
          node.beaconContent.value +
          ',' +
          node.beaconInterval.value +
          ',' +
          node.beaconUUID.value +
          ',' +
          node.beaconMajor +
          ',' +
          node.beaconMinor +
          ',' +
          node.beaconUpdateStatus
        );
      })
      .join('\n');
    return headerData + nodesData;
  }

  public reloadNodes() {
    this.beaconSettingService.checkStatusForUpdatingNodes(this.floorId, this.updatingTimeoutInSeconds).then(() => {
      super.reloadNodes();
    });
  }
}
