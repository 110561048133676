export class OrSearchInputController {
  public maxlength = 32;
  public name: string;
  public disabled = false;
  public onChange;
  public ngModel: string;
  public placeholder: string;

  constructor() {}
}
