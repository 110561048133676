import { ISimpleService } from '../api/ISimpleService';
import { SavedEntity } from '../api/SavedEntity';
import { IResource } from '../api/IResource';
import { MobileToken } from '../api/building/MobileToken';

export class MobileTokenService {
  public tokens: MobileToken[];

  private endpoints = {
    token: '/api/v1/mobile-token'
  };

  constructor(
    private tokenResource: IResource<MobileToken, number>,
    private simpleService: ISimpleService<
      Tokens,
      SavedEntity<MobileToken, number>,
      void,
      void
    >,
    private generateApiUrl: (endpoint: string) => string
  ) {}

  public getAllTokensForBuilding(buildingId: number): Promise<MobileToken[]> {
    return this.tokenResource.retrieveAllByParentId(buildingId);
  }

  public getToken(tokenId: number): Promise<MobileToken> {
    return this.tokenResource.retrieve(tokenId);
  }

  public deleteToken(tokenId): Promise<{}> {
    return this.tokenResource.delete(tokenId);
  }

  public addToken(
    buildingId: number,
    tokenDetails: MobileToken
  ): Promise<SavedEntity<MobileToken, number>> {
    return this.simpleService.post(
      this.generateApiUrl(`${this.endpoints.token}/create/${buildingId}`),
      tokenDetails.description
    );
  }

  public updateToken(buildingId: number, tokenDetails: MobileToken) {
    return this.simpleService.put(
      this.generateApiUrl(
        `${this.endpoints.token}/${buildingId}/${tokenDetails.id}`
      ),
      tokenDetails.description
    );
  }

  public deactivateToken(buildingId: number, tokenId: number) {
    return this.simpleService.put(
      this.generateApiUrl(
        `${this.endpoints.token}/deactivate/${buildingId}/${tokenId}`
      )
    );
  }
}

export class Tokens {
  constructor(public token: MobileToken[]) {}
}
