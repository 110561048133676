<div class="tenant-dialog">
  <h1 mat-dialog-title>{{ title }}</h1>
  <div mat-dialog-content>
    <form [formGroup]="tenantForm" autocomplete="off" data-cy="invite-user-form">
      <mat-form-field>
        <input placeholder="{{action}} Name" data-cy="tenant-name-input" matInput formControlName="tenantName" (keyup)="setActionButtonEnabled()"/>
      </mat-form-field>
    </form>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button (click)="cancel()" data-cy="cancel-tenant">Close</button>
    <button mat-raised-button color='primary' (click)="doAction()" data-cy="save-tenant" [disabled]="!isActionButtonEnabled">Save</button>
  </div>
</div>
