import * as angular from 'angular'; // Automatically added
import { SensorNodeService } from '@angularjs/or/services/SensorNodeService';
import { TagService } from '@angularjs/or/services/TagService';
import { DataType } from '@angularjs/or/data/SensorNodeDataType';
import { FloorService } from '@angularjs/or/services/FloorService';
import { Coordinate } from '@angularjs/or/util/Coordinate';
import { Offset } from '@angularjs/or/util/Offset';
import { IQueryOutlineBuilder } from '@angularjs/or/api/query/outline/IQueryOutlineBuilder';
import { Observable } from '@angularjs/or/util/Observable';
import { QueryExecutor } from '@angularjs/or/angular/QueryExecutor';
import { QueryResult } from '@angularjs/or/api/query/QueryResult';
import {
  IDataTypeContext,
  ILiveModeContext,
  ILocationContext,
  IPositionContext,
  ISelectableContext
} from '@angularjs/or/api/query/outline/context/IContext';
import { SensorNode } from '@angularjs/or/api/building/SensorNode';
import { FloorplanSensorNode } from '@angularjs/or/api/building/FloorplanSensorNode';
import { ObjectUtils } from '@angularjs/or/util/ObjectUtils';
import { MappingService } from '@angularjs/or/services/MappingService';
import { Entity, UIRefreshService } from '@angularjs/or/services/UIRefreshService';
import { ImageZoomService, ZoomOperation } from '@angularjs/or/services/ImageZoomService';
import { ChartData } from '@angularjs/or/view/ChartData';
import { IObservable, IObservableModifiable } from '@angularjs/or/util/IObservable';
import { EmergencyLightingTest } from '@angularjs/or/api/building/EmergencyLightingTest';
import { Area } from '@angularjs/or/util/Area';
import { FloorplanChangeMessage } from '@angularjs/or/messaging/FloorplanChangeMessage';
import { INotifier, ISender } from '@angularjs/or/messaging/Channel';
import { SensorNodeChangeHistoryService } from '@angularjs/or/services/SensorNodeChangeHistoryService';
import { SensorNodeChangeHistory } from '@angularjs/or/api/building/SensorNodeChangeHistory';
import { IBuildingService } from '@angularjs/or/services/IBuildingService';
import { ArrayUtils } from '@angularjs/or/util/ArrayUtils';
import { UserService } from '@angularjs/or/services/UserService';
import { LiveQueryOutline } from '@angularjs/or/api/query/outline/LiveQueryOutline';
import { NavigationService } from '@app/shared/services/navigation/navigation.service';
import { BuildingAuthorityType } from '@app/shared/models/building-authority-type';
import { EmergencyLightingTestType } from '@angularjs/or/data/EmergencyLightingTestType';
import { ITenant } from '@app/shared/models/tenant.interface';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import IIntervalService = angular.IIntervalService;

export class OrFloorplanController {
  private static KEY = 'FLOORPLAN';
  private static FIT_TO_SCREEN_TRANSITION = 'transform 0.3s ease, top 0.3s ease, left 0.3s ease';
  public static NODES_ARE_UPDATED = 'nodes are updated';

  public isActive: boolean;

  private buildingId: number;
  private pendingNodes: SensorNode[] = [];
  private isSaving = false;
  private timeoutPromise;
  private timeoutPromiseLiveNodeData;
  private mappingPromise;
  private isPollingForMappingUpdates: boolean;

  public floorplanImageUrl: string;
  public floorplanImage: any;
  public heatmapScale: number;
  public liveMode: boolean;
  public nodes: FloorplanSensorNode[];
  public nodesToDisplay: FloorplanSensorNode[];
  public chartData: ChartData;
  private queryResults: QueryResult<number>;
  public dataType: DataType;
  public testResults: EmergencyLightingTest[];

  public suppressFloorplan: boolean;
  public suppressNotifications: boolean;
  public enableHeatmap: boolean;
  public normalizeScale: boolean;
  public isAddModeActive: boolean;
  public disableAddModeForNow: boolean;
  public disableMoveModeForNow: boolean;
  public disableMappingModeForNow: boolean;
  public disablePassiveNodeMappingModeForNow: boolean;
  public enableNodes: boolean;
  public enableDrivers: boolean;
  public enableFaultyNodes: boolean;
  public enableMappedNodes: boolean;
  public isMoveModeActive: boolean;
  public isMoveAllModeActive: boolean;
  public isMappingModeActive: boolean;
  public isPassiveNodeMappingModeActive: boolean;
  public refreshResult: boolean;
  public isGatewayModeActive: boolean;
  public enableUnmappedNodes: boolean;
  public pubSubConnectionDefaultOn: boolean;
  public markFaultyNodes: boolean;
  public markEmergencyLights: boolean;
  public disableSelection: boolean;
  public disableDriverSelection: boolean;
  public disableSelectionForNow: boolean;
  public isCumulativeSelectionActive: boolean;
  public isSelectionModeActive: boolean;
  public isEmergencyLightingTestModeActive: boolean;
  public isManualFunctionalTestModeActive: boolean;
  public isManualDurationTestModeActive: boolean;
  public isCancelTestModeActive: boolean;
  public markDuplicatedMapping: boolean;
  public notificationMode: boolean;
  public showNodeValues: boolean;
  public isSnappingEnabled: boolean;
  public gotFloorplanImage: boolean;
  public isDragging: boolean;
  public isSelecting: boolean;
  public tagIds: number[];
  public x: number;
  public y: number;
  public currentTenant: ITenant;

  public dummyNode;
  public onload;
  public zoomLevel = 1;
  public style: Style = new Style(false, new Transform(this.zoomLevel));

  public selectionArea = {
    top: '0px',
    left: '0px',
    width: '0px',
    height: '0px'
  };

  private currentSelectionArea = { top: 0, left: 0, width: 0, height: 0 };
  private canPlaceNode: boolean;
  private snapResolution: number;
  private lastTouchMoveEvent = {};
  private containerSize: Area = new Area(this.element[0].clientWidth, this.element[0].clientHeight);
  private position: Coordinate = null;
  private origin: Coordinate = new Coordinate(0, 0);
  private margins = new Area(this.containerSize.width / 2, this.containerSize.height / 2);
  private clientRect;
  private isUnselectionModeActive: boolean;
  private scaleModifier: number;
  private initialOffset: Offset;
  private initialSelection;
  private isMouseDown: boolean;

  public floorplanContainerSize;
  public activePage: string;
  private imageLoaded = false;
  public showLightLevel: boolean;
  public isPubSubConnectionVisible = false;

  private lastSize: Area = new Area(0, 0);
  private cancelledReason = -1;

  public renderHeatmap = new Subject<FloorplanSensorNode[]>();
  private initialise = true;

  private destroy$ = new Subject<void>();
  constructor(
    private $window: ng.IWindowService,
    private $timeout: ng.ITimeoutService,
    private scope: ng.IScope,
    private element,
    private interval: IIntervalService,
    private tagService: TagService,
    private nodesService: SensorNodeService,
    private nodeChangeHistoryService: SensorNodeChangeHistoryService,
    private floorService: FloorService,
    private outlineBuilder: IObservable<IQueryOutlineBuilder>,
    private selectableContext: IObservableModifiable<ISelectableContext>,
    private dataTypeContext: IObservableModifiable<IDataTypeContext>,
    private locationContext: IObservableModifiable<ILocationContext>,
    private positionContext: IObservableModifiable<IPositionContext>,
    private liveModeContext: IObservableModifiable<ILiveModeContext>,
    private floorplanUpdater: ISender<FloorplanChangeMessage>,
    private panelService: INotifier,
    private queryExecutor: QueryExecutor,
    private mappingService: MappingService,
    private zoomService: Observable<ImageZoomService>,
    private uiRefreshService: UIRefreshService,
    private navigationService: NavigationService,
    private userService: UserService,
    private buildingService: IBuildingService
  ) {}

  public $onInit(): void {
    this.buildingService.getCurrentBuilding().then((building) => {
      this.buildingId = building.id;
    });

    this.activePage = this.navigationService.getActiveSection().info.Id;
    this.isDragging = false;
    this.isMouseDown = false;
    this.isSelecting = false;
    this.canPlaceNode = false;
    this.gotFloorplanImage = false;
    this.snapResolution =
      angular.isNumber(this.snapResolution) && isFinite(this.snapResolution) ? this.snapResolution : 1;
    this.lastTouchMoveEvent = {};

    this.enableNodes = ObjectUtils.coalesce(this.enableNodes, true);
    this.enableDrivers = ObjectUtils.coalesce(this.enableDrivers, true);
    this.enableFaultyNodes = ObjectUtils.coalesce(this.enableFaultyNodes, true);
    this.enableMappedNodes = ObjectUtils.coalesce(this.enableMappedNodes, true);
    this.enableUnmappedNodes = ObjectUtils.coalesce(this.enableUnmappedNodes, true);
    this.isPubSubConnectionVisible = ObjectUtils.coalesce(this.pubSubConnectionDefaultOn, false);
    this.markFaultyNodes = ObjectUtils.coalesce(this.markFaultyNodes, true);
    this.markEmergencyLights = ObjectUtils.coalesce(this.markEmergencyLights, true);

    this.dummyNode = this.nodesService.createTemporaryNode();
    this.suppressFloorplan = false;

    this.locationContext.onChange(() => this.updateData());
    this.outlineBuilder.onChange((value: IQueryOutlineBuilder) => {
      if (!this.liveMode) {
        this.updateNonLiveData();
      }
    });
    this.scope.$watch('floorplan.floor.id', () => this.updateData());
    this.scope.$watch('floorplan.enableUnmappedNodes', () => this.findNodesToDisplay(this.nodes));
    this.scope.$watch('floorplan.enableMappedNodes', () => this.findNodesToDisplay(this.nodes));
    this.scope.$watch('floorplan.enableFaultyNodes', () => this.findNodesToDisplay(this.nodes));
    this.scope.$watch('[ floorplan.x, floorplan.y ]', (position: number[]) => {
      if (position[0] && position[1]) {
        this.centerAt(new Coordinate(position[0], position[1]));
      }
    });
    this.scope.$watch('floorplan.nodeToFocusOn', (nodeToFocusOn: FloorplanSensorNode) => {
      if (nodeToFocusOn != null && nodeToFocusOn.x != null && nodeToFocusOn.y != null) {
        this.centerAt(new Coordinate(nodeToFocusOn.x, nodeToFocusOn.y));
        this.nodesService.clearSelection();
        this.nodesService.addNodeToSelection(nodeToFocusOn, this.isEmergencyLightingTestModeActive);
      }
    });
    this.scope.$watch('floorplan.zoomLevel', () => this.updateZoomLevel());

    this.uiRefreshService.activatedTenant.subscribe((newTenant) => {
      this.currentTenant = newTenant;
    });

    this.nodesService.refreshDuplicatesAfterMapping
      .pipe(takeUntil(this.destroy$))
      .subscribe((duplicateAddressNodes) => {
        this.nodes.forEach((node) => {
          node.duplicateAddressMappings = [];
        });
        duplicateAddressNodes.forEach((duplicateNode) => {
          const index = this.nodes.findIndex((node) => node.id === duplicateNode.id);
          if (index !== -1) {
            this.nodes[index].duplicateAddressMappings = duplicateNode.duplicateAddressMappings;
          }
        });
        this.nodesService.updatingDuplicateMappingInProgress = false;
      });
    this.nodesService.refreshNewlyMappedNodes.pipe(takeUntil(this.destroy$)).subscribe((nodes) => {
      nodes.forEach((node) => {
        if (node.properlyMapped && node.address != null) {
          const index = this.nodes.findIndex((n) => n.id === node.id);
          if (index !== -1) {
            this.nodes[index].properlyMapped = true;
            this.nodes[index].address = node.address;
          } else {
            console.error('The relevant node cannot be found on the floor!');
          }
        }
      });
      this.nodesService.updatingMappedNodeInProgress = false;
    });

    const liveModeSetting = this.liveModeContext.value().liveMode;
    if (liveModeSetting === true) {
      this.liveMode = true;
      this.updateLiveData();
    }

    this.scope.$watch('floorplan.liveMode', (isLive, wasLive) => {
      if (isLive && !wasLive) {
        this.updateLiveData();
        this.liveModeContext.value().liveMode = true;
      }

      if (!isLive && wasLive) {
        this.queryExecutor.cancelLiveQuery();
        this.stopPollingForLiveData();
        this.updateNonLiveData();
        this.liveModeContext.value().liveMode = false;
      }
    });

    this.scope.$watch('floorplan.testResults', () => {
      if (this.testResults != null) {
        this.nodesService.populateFloorNodeTestResults(this.nodes, this.testResults);
      }
    });

    this.scope.$watch('floorplan.isMappingModeActive', () => {
      if (this.isMappingModeActive) {
        this.disableSelectionForNow = true;
        this.disableAddModeForNow = true;
        this.disableMoveModeForNow = true;
        this.nodesService.clearSelection();
      } else {
        this.stopPollingForMappingUpdates();
        this.disableSelectionForNow = false;
        this.disableAddModeForNow = false;
        this.disableMoveModeForNow = false;
        if (this.mappingService.mappingTried) {
          this.mappingService.clearMappingFlags();
          this.updateNodes();
          this.mappingService.mappingTried = false;
        }
      }
    });

    this.scope.$watch('floorplan.isPassiveNodeMappingModeActive', () => {
      if (this.isPassiveNodeMappingModeActive) {
        this.disableSelectionForNow = true;
        this.disableAddModeForNow = true;
        this.disableMoveModeForNow = true;
      } else {
        this.stopPollingForMappingUpdates();
        this.disableSelectionForNow = false;
        this.disableAddModeForNow = false;
        this.disableMoveModeForNow = false;
      }
    });

    this.scope.$watch('floorplan.isAddModeActive', () => {
      if (this.isAddModeActive) {
        this.disableMappingModeForNow = true;
        this.disablePassiveNodeMappingModeForNow = true;
        this.disableSelectionForNow = true;
        this.disableMoveModeForNow = true;
      } else {
        this.disableMappingModeForNow = false;
        this.disablePassiveNodeMappingModeForNow = false;
        this.disableSelectionForNow = false;
        this.disableMoveModeForNow = false;
      }
    });

    this.scope.$watch('floorplan.isMoveModeActive', () => {
      if (this.isMoveModeActive) {
        this.disableAddModeForNow = true;
        this.disableSelectionForNow = true;
        this.disableMappingModeForNow = true;
        this.disablePassiveNodeMappingModeForNow = true;
        this.nodesService.clearSelection();
      } else {
        this.disableAddModeForNow = false;
        this.disableSelectionForNow = false;
        this.disableMappingModeForNow = false;
        this.disablePassiveNodeMappingModeForNow = false;
      }
    });

    this.panelService.onNotify(() => {
      const tickUntilChange = this.interval(() => {
        if (
          this.lastSize.width != this.element[0].clientWidth ||
          this.lastSize.height != this.element[0].clientHeight
        ) {
          this.lastSize.width = this.element[0].clientWidth;
          this.lastSize.height = this.element[0].clientHeight;
          this.centerFloorplan(false, true);
          this.interval.cancel(tickUntilChange);
        }
      }, 10);
    });
    angular.element(this.$window).on('resize', () => this.scope.$apply(() => this.centerFloorplan(false, false)));

    this.scope.$watch(
      () => {
        let val = null;
        const rect = this.element[0];
        if (rect != null) {
          val = [rect.clientWidth, rect.clientHeight];
        }
        return val;
      },
      ([width, height]) => {
        this.floorplanContainerSize = new Area(width, height);
      },
      true
    );

    this.scope.$watch('floorplan.floorplanContainerSize', () => {
      if (this.imageLoaded) {
        this.containerSize = this.floorplanContainerSize;
        this.initializeZoom();
        this.updateCenteringForZoomService();
      }
    });

    this.mappingService.setPostMappingActivity(() => {
      if (!this.isPollingForMappingUpdates) {
        this.isPollingForMappingUpdates = true;
        this.pollForMappingUpdates();
      }
    });

    this.scope.$on('$destroy', () => this.destroyController());

    this.uiRefreshService.onChange(Entity.PAGE, () => {
      this.clearSelection();
    });

    this.uiRefreshService.onChange(Entity.BUILDING, () => {
      this.clearSelection();
    });

    this.uiRefreshService.onChange(Entity.FLOOR, () => {
      this.clearSelection();
    });

    // TODO how to ignore an update from ourself? add meta info about the class that is subscribed...
    const zoomSetting = this.positionContext.value().zoom;
    if (zoomSetting != null) {
      this.zoomLevel = zoomSetting;
      this.zoomService.change((service) => service.setScale(zoomSetting));
    } else {
      this.zoomLevel = this.zoomService.value().getScale();
    }

    this.updateZoomLevel();
    this.zoomService.onChange((value) => {
      if (value.shouldAttemptToRecenter()) {
        this.centerFloorplan(true, true);
      }
      this.zoomLevel = value.getScale();
      this.positionContext.change((context) => {
        context.zoom = this.zoomLevel;
        context.coordinate = this.position;
      });
      this.updateZoomLevel();
    }, OrFloorplanController.KEY);

    this.element.bind('mousewheel DOMMouseScroll', (event) => {
      event.preventDefault();
      this.onScroll(event);
    });

    if (this.showLightLevel) {
      this.userService.getCurrentUser().then((user) => {
        this.buildingService.getCurrentBuilding().then((building) => {
          user.buildingAuthorizations
            .filter((buildingAuth) => buildingAuth.building.id == building.id)
            .forEach((buildingAuth) => {
              if (ArrayUtils.contains(buildingAuth.authorities, BuildingAuthorityType.HIGH_RESOLUTION_ANALYTICS)) {
                this.doLiveNodeDataQuery();
              }
            });
        });
      });
    }

    if (this.enableHeatmap) {
      this.initializeFloorplanWithHeatmap();
    }

    this.nodesService.refreshFloorPlanNodes.subscribe((nodes) => {
      this.nodes = nodes;
      this.nodesService.clearSelection();
      this.pendingNodes.length = 0;
      if (nodes != null && nodes.length != 0) {
        this.findNodesToDisplay(nodes);
        this.updateNewNodesWithExistingLiveData(nodes);
        this.highlightSelectables();
      }

      if (this.initialise) {
        if (this.enableHeatmap) {
          this.initializeFloorplanWithHeatmap();
        }
        this.initialise = false;
      } else {
        this.updateNewNodesWithExistingQueryData();
      }

      if (this.isPubSubConnectionVisible) {
        this.scope.$broadcast(OrFloorplanController.NODES_ARE_UPDATED, this.nodes);
      }
    });
  }

  private findNodesToDisplay(nodes: FloorplanSensorNode[]) {
    if (nodes != null) {
      this.nodesToDisplay = nodes.filter((node) => this.shouldDisplaySensorNode(node));
    } else {
      this.nodesToDisplay = [];
    }
  }

  public shouldDisplaySensorNode(node): boolean {
    // TODO: Need to do better than this ever growing list of conditions that determine whether to show/hide a SN
    return (
      ((node.properlyMapped && this.enableMappedNodes) || (!node.properlyMapped && this.enableUnmappedNodes)) &&
      (this.enableFaultyNodes || !node.isFaulty)
    );
  }

  public shouldDisplayDriver(driver, node): boolean {
    return this.shouldDisplaySensorNode(node) && this.enableDrivers;
  }

  private pollForMappingUpdates() {
    this.mappingPromise = this.$timeout(() => {
      console.debug('Polling for mapping updates :', this.isPollingForMappingUpdates);

      if (this.mappingService.isActivityWaiting) {
        this.mappingService.setPostActivityInProgress(false);
      }
      // the condition is used to prevent the UI from fetching the nodes from backend if a process is in progress or waiting.
      else if (!this.nodesService.isPostMappingInProgress && !this.mappingService.isActivityInProgress) {
        this.mappingService.setPostActivityInProgress(true);
        this.checkAndUpdateNodesAfterMapping();
      }

      if (this.isPollingForMappingUpdates && this.mappingService.getPostActivityTimeout > Date.now()) {
        this.pollForMappingUpdates();
      } else {
        this.stopPollingForMappingUpdates();
        this.mappingService.setPostActivityInProgress(false);
      }
    }, 5000);
  }

  private destroyController() {
    this.zoomService.deregisterAll();
    this.stopPollingForMappingUpdates();
    this.stopPollingForLiveData();
    this.queryExecutor.cancelNodeDataQuery();
    this.$timeout.cancel(this.timeoutPromiseLiveNodeData);
    this.destroy$.next();
    this.destroy$.complete();
    // TODO undo any subscriptions for query context?
  }

  private updateSelectableContext() {
    this.selectableContext.change((ctx) => {
      const nodeIds = this.nodesService.selectedNodes.value().map((node) => node.id);
      ctx.sensorNodeIds = nodeIds;
      const driverIds = this.nodesService.selectedEmDrivers.value().map((driver) => driver.id);
      ctx.emDriverIds = driverIds;
    });
  }

  private updateNodes() {
    this.nodesService.requestNodes.next(true);
  }

  private checkAndUpdateNodesAfterMapping() {
    const unmappedNodeIds = this.nodes
      .filter((node) => !node.properlyMapped || node.address == null)
      .map((node) => node.id);

    if (unmappedNodeIds.length > 0) {
      this.nodesService.requestNodesById.next(unmappedNodeIds);
    }
    this.nodesService.requestDuplicateMappings.next(this.buildingId);
  }

  private doLiveNodeDataQuery() {
    const outline: LiveQueryOutline = this.outlineBuilder.value().buildLiveOutline();
    outline.sensorNodeIds = [];
    outline.tagIds = [];
    outline.zone = undefined;

    this.queryExecutor
      .doLiveDataQuery(outline)
      .then((result) => {
        this.updateFloorplanWithCurrentData(result.lightLevel, this.nodesService.updateNodesLightLevel);
        this.updateFloorplanWithCurrentData(result.presenceLevel, this.nodesService.updateNodesPresence);
        if (this.navigationService.getActiveSection().info.Id === 'heatmap') {
          this.timeoutPromiseLiveNodeData = this.$timeout(() => this.doLiveNodeDataQuery(), 60000);
        }
      })
      .catch((reason) => {
        this.$timeout.cancel(this.timeoutPromiseLiveNodeData);
        if (reason != this.cancelledReason && this.navigationService.getActiveSection().info.Id === 'heatmap') {
          this.timeoutPromiseLiveNodeData = this.$timeout(() => this.doLiveNodeDataQuery(), 60000);
        }
      });
  }

  private doLiveQuery() {
    this.queryExecutor
      .doLiveQuery(this.outlineBuilder.value().buildLiveOutline())
      .then((result) => {
        this.updateFloorplanWithData(result);
        this.timeoutPromise = this.$timeout(() => this.doLiveQuery(), 5000);
      })
      .catch(() => {
        this.timeoutPromise = this.$timeout(() => this.doLiveQuery(), 5000);
      });
  }

  private updateLiveData() {
    this.doLiveQuery();
  }

  private stopPollingForMappingUpdates() {
    this.$timeout.cancel(this.mappingPromise);
    this.isPollingForMappingUpdates = false;
  }

  private stopPollingForLiveData() {
    this.liveMode = false;
    this.$timeout.cancel(this.timeoutPromise);
  }

  private updateNonLiveData() {
    this.$timeout.cancel(this.timeoutPromise);
    this.liveMode = false;
    this.queryExecutor
      .doComplexQuery(this.outlineBuilder.value().buildMainOutline())
      .then((result) => this.updateFloorplanWithData(result));
  }

  private initializeFloorplanWithHeatmap() {
    this.dataType = this.dataTypeContext.value().dataType;
    this.dataTypeContext.onChange((value) => (this.dataType = value.dataType));
    if (!this.liveMode) {
      this.updateNonLiveData();
    }
    this.floorplanUpdater.onReceive((message) => {
      if (!this.liveMode && message.shouldReload) {
        this.updateNonLiveData();
      }
    });
  }

  private updateFloorplanWithData(result: QueryResult<number>) {
    this.chartData = ChartData.fromQueryResult(result);
    this.queryResults = result;
    this.nodesService.populateFloorplanNodeValues(this.nodes, result.values, result.suffix, result.max);
    this.renderHeatmap.next(this.nodes);
  }

  private updateNewNodesWithExistingQueryData(): void {
    if (this.nodes && this.nodes.length != 0 && this.queryResults) {
      this.nodesService.populateFloorplanNodeValues(
        this.nodes,
        this.queryResults.values,
        this.queryResults.suffix,
        this.queryResults.max
      );
      this.renderHeatmap.next(this.nodes);
    } else if (this.nodes && this.nodes.length != 0) {
      this.renderHeatmap.next(this.nodes);
    }
  }

  private updateNewNodesWithExistingLiveData(newNodes: SensorNode[]): void {
    newNodes.forEach((node) => {
      if (this.nodes) {
        const oldNode = this.nodes.filter((oldNode) => {
          return oldNode.id === node.id;
        });
        if (oldNode.length > 0) {
          node.lightLevel = oldNode[0].lightLevel;
          node.presence = oldNode[0].presence;
        }
      }
    });
  }

  private updateFloorplanWithCurrentData(result: QueryResult<number>, func: Function) {
    this.scope.$apply(() => {
      if (this.nodes && this.nodes.length > 0) {
        func(this.nodes, result.values);
      }
    });
  }

  private highlightSelectables(): void {
    const selectedSensorNodes = this.selectableContext.value().sensorNodeIds;
    if (selectedSensorNodes != null) {
      const sNodes = this.getSelectableById(selectedSensorNodes, this.nodes);
      sNodes.forEach((node) => {
        this.nodesService.addNodeToSelection(node, this.isEmergencyLightingTestModeActive);
      });
    }
    const selectedEmDrivers = this.selectableContext.value().emDriverIds;
    if (selectedEmDrivers != null) {
      const emDrivers = this.nodes.flatMap((sn) => sn.emDrivers);
      const sDrivers = this.getSelectableById(selectedEmDrivers, emDrivers);
      sDrivers.forEach((driver) => {
        this.nodesService.addEmDriverToSelection(driver);
      });
    }
  }

  private getSelectableById(ids, selectables): any[] {
    const selection = [];
    for (let nodeIdx = 0; nodeIdx < selectables.length; nodeIdx += 1) {
      for (let nodeIdIdx = 0; nodeIdIdx < ids.length; nodeIdIdx += 1) {
        if (selectables[nodeIdx].id === ids[nodeIdIdx]) {
          selection.push(selectables[nodeIdx]);
        }
      }
    }
    return selection;
  }

  public runTestsInBatch(testType: string): void {
    const unique = (value, index, self) => {
      return self.indexOf(value) === index;
    };
    let selectedDrivers = this.nodesService.selectedNodes.value().flatMap((sn) => sn.emDrivers);
    selectedDrivers = selectedDrivers.concat(this.nodesService.selectedEmDrivers.value()).filter(unique);

    const selectedDriversCount = selectedDrivers.length;
    const testEnum = EmergencyLightingTestType.fromValue(testType);
    if (selectedDriversCount > 0) {
      if (
        confirm(
          `Running ${testType.toLowerCase()} tests on ${selectedDriversCount} ${
            selectedDriversCount > 1 ? ' drivers' : ' driver'
          }`
        )
      ) {
        const driverIds = selectedDrivers.map((driver) => driver.id);
        this.nodesService
          .startEmergencyLightingTestBatch(this.buildingId, driverIds, testEnum)
          .then(() => {
            console.debug('Request for running tests sent successfully');
          })
          .catch((err) => {
            console.error(err);
            alert(`There was some problem in running ${testType.toLowerCase()} tests for selected nodes`);
          })
          .finally(() => {
            this.isSelectionModeActive = false;
          });
      }
    }
  }

  public cancelTestsManually(): void {
    const unique = (value, index, self) => {
      return self.indexOf(value) === index;
    };
    let selectedDrivers = this.nodesService.selectedNodes.value().flatMap((sn) => sn.emDrivers);
    selectedDrivers = selectedDrivers.concat(this.nodesService.selectedEmDrivers.value()).filter(unique);
    const selectedDriversCount = selectedDrivers.length;
    if (selectedDriversCount > 0) {
      if (
        confirm('Cancelling tests for ' + selectedDriversCount + (selectedDriversCount > 1 ? ' drivers' : ' driver'))
      ) {
        const driverIds = selectedDrivers.map((driver) => driver.id);
        this.nodesService
          .cancelEmergencyLightingTestBatch(this.buildingId, driverIds)
          .then(() => {
            console.log('Request for cancelling tests sent successfully');
          })
          .catch((err) => {
            console.error(err);
            alert(`There was some problem in cancelling tests for selected nodes`);
          })
          .finally(() => {
            this.isSelectionModeActive = false;
          });
      }
    }
  }

  public undoFloorplan(): void {
    if (this.pendingNodes.length <= 0) {
      return;
    }

    this.pendingNodes.length = this.pendingNodes.length - 1;
  }

  public saveFloorplan(): void {
    if (this.pendingNodes.length <= 0) {
      return;
    }

    const nodes = angular.copy(this.pendingNodes);

    this.isSaving = true;
    this.nodesService.saveNodes(nodes).then(() => {
      this.reloadNodes();
      this.isSaving = false;
    });
  }

  public discardFloorplan(): void {
    if (!this.pendingNodes.length || confirm('All changes will be lost.')) {
      this.clearPendingNodes();
    }
  }

  public saveChangedSensorNodes(): void {
    this.nodes.forEach((originalNode) => {
      if (originalNode.isChanged) {
        this.nodesService.updateNode(originalNode).then(() => {
          originalNode.reset();
          if (this.nodes.every((node) => !node.isChanged)) {
            this.scope.$apply(() => {
              this.updateNodes();
              this.nodeChangeHistoryService.clearSensorNodeChangeHistory();
            });
          }
        });
      }
    });
  }

  public undoChangedSensorNodes() {
    this.nodeChangeHistoryService.undo();
  }

  public discardChangedSensorNodes() {
    if (this.nodeChangeHistoryService.total() > 0 && confirm('All changes will be lost.')) {
      this.updateNodes();
      this.nodeChangeHistoryService.clearSensorNodeChangeHistory();
    }
  }

  private reloadNodes(): void {
    this.nodesService.requestNodes.next(true);
    this.isAddModeActive = false;
  }

  private clearPendingNodes(): void {
    this.pendingNodes.length = 0;
    this.nodesService.clearSelection();
  }

  public onReloadNodes(): void {
    this.reloadNodes();
  }

  public updateData() {
    this.updateFloor();
  }

  private updateFloor() {
    this.floorService.getCurrentFloor().then(
      (floor) => {
        this.scope.$apply(() => {
          this.floorplanImageUrl = this.floorService.getFloorImageUrl(floor);
        });
      },
      (reason) => console.warn(reason)
    );
  }

  public dismissNotifications() {
    this.suppressNotifications = true;
  }

  public snapValue(value): number {
    return Math.round(value / this.snapResolution) * this.snapResolution;
  }

  public onScroll(event: WheelEvent) {
    const operation = event.deltaY > 0 ? ZoomOperation.ZOOM_OUT : ZoomOperation.ZOOM_IN;
    this.zoomService.change((value) => {
      value.zoom(operation);
      this.scope.$apply(() => {
        this.zoomLevel = value.getScale();
      });
    }, OrFloorplanController.KEY);
  }

  public onTouchEnd($event) {
    this.onMouseUp($event.changedTouches[0]);
  }

  public onTouchMove($event) {
    this.onMouseMove($event.touches[0]);
  }

  public onTouchStart($event) {
    this.onMouseDown($event.touches[0]);
    this.isActive = true;
  }

  public onMouseOver($event) {
    this.isActive = true;
  }

  public initFloorplanImage() {
    this.updateFloorplanImageReference();

    this.centerFloorplan();
    this.position = this.positionContext.value().coordinate;
    if (this.position != null) {
      this.centerAt(this.position, false);
    }
    this.style.setWidth(this.floorplanImage.clientWidth);
    this.style.setHeight(this.floorplanImage.clientHeight);
    this.initializeZoom();
    this.zoomService.change((service) => {
      const zoomSetting = this.positionContext.value().zoom;
      if (zoomSetting != null) {
        service.setScale(zoomSetting);
      } else {
        service.reset();
      }
    });
    this.imageLoaded = true;

    this.nodesService.selectedNodes.value().forEach((node) => {
      this.centerAt(new Coordinate(node.x, node.y));
    });
  }

  public onMouseDown($event) {
    this.isMouseDown = true;
    if ($event.which == 3) {
      this.clearSelection(true);
      return;
    }

    this.updateFloorplanImageReference();
    if (!this.disableSelection && !this.disableSelectionForNow) {
      if (!this.isSelectionModeActive) {
        this.isSelectionModeActive = $event.shiftKey;
      }
      if (!this.isUnselectionModeActive) {
        this.isUnselectionModeActive = $event.altKey && $event.shiftKey;
      }
    }
    if (this.isSelectionModeActive) {
      this.startSelecting($event);
    } else if (!this.suppressFloorplan) {
      this.toggleDragMode($event, true);
    }
    if (this.isAddModeActive) {
      if (!this.suppressFloorplan) {
        this.canPlaceNode = true;
      }
    } else if (this.isMoveModeActive) {
      this.startRecSensorNodeChangeHistory($event);
    }
  }

  public onMouseMove($event) {
    if (!this.isSelectionModeActive) {
      if (this.isMoveModeActive && this.isMouseDown) {
        this.moveSelectedNode($event);
      } else {
        this.moveFloor($event.clientX, $event.clientY);
      }
    } else if (!this.suppressFloorplan) {
      this.moveSelection($event);
    }
    if (this.isAddModeActive) {
      this.updateFloorplanImageReference();
      this.updateDummyNodePosition($event.clientX, $event.clientY);
      this.canPlaceNode = false;
    }
  }

  public onMouseUp($event) {
    this.isMouseDown = false;
    this.updateFloorplanImageReference();
    this.toggleDragMode($event, false);

    if (this.isSelectionModeActive) {
      this.endSelecting();
    } else {
      this.positionContext.change((context) => (context.coordinate = this.position));
    }

    if (this.isAddModeActive) {
      if (this.canPlaceNode) {
        this.placeNode($event.clientX, $event.clientY);
      }
    } else if (this.isMoveModeActive) {
      this.nodeChangeHistoryService.stopRecSensorNodeChangeHistory();
    }
  }

  public onMouseLeave($event) {
    this.isMouseDown = false;
    this.isActive = false;
    this.updateFloorplanImageReference();
    if (this.isDragging) {
      this.toggleDragMode($event, false);
    }
    if (this.isSelecting) {
      this.endSelecting();
    }
    if (this.isMoveModeActive) {
      this.nodeChangeHistoryService.stopRecSensorNodeChangeHistory();
    }
  }

  private validateRelativePosition(x, y) {
    return {
      x: x < 0 ? 0 : x > this.clientRect.width ? this.clientRect.width : x,
      y: y < 0 ? 0 : y > this.clientRect.height ? this.clientRect.height : y
    };
  }

  private placeNode(x, y) {
    this.updateFloorplanImageReference(() => {
      const position: Coordinate = this.getRelativePosition(x, y, this.isSnappingEnabled);
      position.x = position.x * this.scaleModifier;
      position.y = position.y * this.scaleModifier;
      this.pendingNodes.push(this.nodesService.createTemporaryNode(position));
    });
  }

  private getRelativePosition(x, y, snap?): Coordinate {
    const relativeX = x - this.clientRect.left;
    const relativeY = y - this.clientRect.top;
    return new Coordinate(snap ? this.snapValue(relativeX) : relativeX, snap ? this.snapValue(relativeY) : relativeY);
  }

  private updateDummyNodePosition(x, y) {
    const position: Coordinate = this.getRelativePosition(x, y, this.isSnappingEnabled);
    const validPosition = this.validateRelativePosition(position.x, position.y);
    this.dummyNode.x = validPosition.x * this.scaleModifier;
    this.dummyNode.y = validPosition.y * this.scaleModifier;
  }

  public updateZoomLevel() {
    this.scaleModifier = 1 / this.zoomLevel;
    // this.style.transform = 'scale(' + this.zoomLevel + ')';
    this.style.setTransform(new Transform(this.zoomLevel));
  }

  private updateFloorplanImageReference(callback?) {
    this.floorplanImage = this.element.find('img')[0];
    this.clientRect = this.floorplanImage.getBoundingClientRect();
    if (callback) {
      callback();
    }
  }

  public clearSelection(resetSensorNodeContext?: boolean) {
    this.nodesService.clearSelection();
    this.tagService.clearSelection();
    if (resetSensorNodeContext) {
      this.updateSelectableContext();
    }
  }

  public selectAll() {
    const onlySelectEmergencyNodes = this.activePage === 'emergency-lighting-groups';
    this.nodesService.selectAll(onlySelectEmergencyNodes).then(() => {
      this.updateSelectableContext();
      this.scope.$apply();
    });
  }

  public deleteSelectedNodes() {
    this.nodesService.deleteNodes(this.nodesService.selectedNodes.value()).then(() => {
      this.reloadNodes();
    });
  }

  private isCentered(): boolean {
    const centeredCoordinates = this.getCenteredLocationForFloorplan();
    return centeredCoordinates.x == this.origin.x && centeredCoordinates.y == this.origin.y;
  }

  private centerAt(position: Coordinate, setOrigin = true) {
    // this.style.transition = OrFloorplanController.FIT_TO_SCREEN_TRANSITION;
    this.style.setTransition(OrFloorplanController.FIT_TO_SCREEN_TRANSITION);
    this.containerSize = new Area(this.element[0].clientWidth, this.element[0].clientHeight);
    this.updateFloorplanImageReference(() => {
      this.position = position;
      this.origin = new Coordinate(
        this.containerSize.width / 2 - (this.position.x || 0),
        this.containerSize.height / 2 - (this.position.y || 0)
      );
      this.$timeout(() => {
        // this.style.left = this.origin.x + 'px';
        // this.style.top = this.origin.y + 'px';
        this.translate(this.origin.x, this.origin.y);
        // this.style["transform-origin"] = position.x + "px " + position.y + "px 0";
        if (setOrigin) {
          this.style.setTransformOrigin(position);
        }
        this.updateCenteringForZoomService();
        this.$timeout(() => {
          // this.style.transition = null;
          this.style.setTransition(null);
        }, 300);
      }, 1);
    });
  }

  private centerFloorplan(resetOrigin = true, animate?: boolean) {
    // this.style.transition = animate ? OrFloorplanController.FIT_TO_SCREEN_TRANSITION : null;
    this.style.setTransition(animate ? OrFloorplanController.FIT_TO_SCREEN_TRANSITION : null);
    this.containerSize = new Area(this.element[0].clientWidth, this.element[0].clientHeight);
    this.margins = new Area(this.containerSize.width / 2, this.containerSize.height / 2);

    if (resetOrigin || this.position == null) {
      this.origin = this.getCenteredLocationForFloorplan();
    } else {
      this.origin = new Coordinate(
        this.containerSize.width / 2 - (this.position.x || 0),
        this.containerSize.height / 2 - (this.position.y || 0)
      );
    }

    this.gotFloorplanImage = true;
    // this.style.visibility = 'visible';
    this.style.setVisibility(true);
    this.zoomService.change((value) => {
      value.setCentered();
    }, OrFloorplanController.KEY);

    if (resetOrigin) {
      this.position = null;
      this.style.setTransformOrigin(this.position);
    }

    if (animate) {
      this.$timeout(() => {
        // this.style.left = this.origin.x + 'px';
        // this.style.top = this.origin.y + 'px';
        this.translate(this.origin.x, this.origin.y);

        this.$timeout(() => {
          // this.style.transition = null;
          this.style.setTransition(null);
        }, 300);
      }, 1);
    } else {
      // this.style.left = this.origin.x + 'px';
      // this.style.top = this.origin.y + 'px';
      this.translate(this.origin.x, this.origin.y);
    }
  }

  private getCenteredLocationForFloorplan(): Coordinate {
    return new Coordinate(
      (this.containerSize.width - this.floorplanImage.clientWidth) / 2,
      (this.containerSize.height - this.floorplanImage.clientHeight) / 2
    );
  }

  private initializeZoom() {
    let ratio = 1;
    const widthRatio = this.floorplanImage.clientWidth / this.containerSize.width;
    const heightRatio = this.floorplanImage.clientHeight / this.containerSize.height;
    if (widthRatio > heightRatio) {
      ratio = widthRatio;
    } else {
      ratio = heightRatio;
    }
    this.zoomService.change((zoom) => zoom.setDefaultScale(1 / ratio));
  }

  private startSelecting($event) {
    if (!this.isCumulativeSelectionActive) {
      this.clearSelection();
    }
    const position: Coordinate = this.getRelativePosition($event.clientX, $event.clientY);
    this.updateFloorplanImageReference();
    this.isSelecting = true;
    this.initialSelection = {};
    this.initialSelection.left = position.x * this.scaleModifier;
    this.initialSelection.top = position.y * this.scaleModifier;
    this.initialSelection.width = 0;
    this.initialSelection.height = 0;
    this.pauseEvent();
  }

  private moveSelection($event) {
    let position: Coordinate;
    if (this.isSelecting) {
      position = this.getRelativePosition($event.clientX, $event.clientY);
      this.currentSelectionArea.left = this.initialSelection.left;
      this.currentSelectionArea.top = this.initialSelection.top;
      this.currentSelectionArea.width = position.x * this.scaleModifier - this.initialSelection.left;
      this.currentSelectionArea.height = position.y * this.scaleModifier - this.initialSelection.top;
      if (this.currentSelectionArea.width < 0) {
        this.currentSelectionArea.width = -this.currentSelectionArea.width;
        this.currentSelectionArea.left = this.currentSelectionArea.left - this.currentSelectionArea.width;
      }
      if (this.currentSelectionArea.height < 0) {
        this.currentSelectionArea.height = -this.currentSelectionArea.height;
        this.currentSelectionArea.top = this.currentSelectionArea.top - this.currentSelectionArea.height;
      }
      this.selectionArea = {
        left: this.currentSelectionArea.left + 'px',
        top: this.currentSelectionArea.top + 'px',
        width: this.currentSelectionArea.width + 'px',
        height: this.currentSelectionArea.height + 'px'
      };
      return false;
    }
  }

  private startRecSensorNodeChangeHistory($event) {
    let position: Coordinate;
    let distance: number;
    const node: FloorplanSensorNode[] = [];
    position = this.getRelativePosition($event.clientX, $event.clientY);

    const sNodes = this.nodesService.selectedNodes.value();
    sNodes.forEach((selectedNode) => {
      this.nodes.forEach((originalNode) => {
        if (originalNode.id == selectedNode.id) {
          distance = position.distance(
            new Coordinate(originalNode.x / this.scaleModifier, originalNode.y / this.scaleModifier)
          );
          if (distance < 25) {
            node.push(originalNode);
          }
        }
      });
    });
    this.nodeChangeHistoryService.startRecSensorNodeChangeHistory(node);
  }

  private moveSelectedNode($event) {
    const lastChange = this.nodeChangeHistoryService.getLast();
    if (!lastChange || lastChange.size == 0) {
      return;
    }

    // Iterating over a map returns the values in insertion order
    // Using this the .next() here gets us the first entry in the map
    const draggedNode = lastChange.values().next().value.node;

    const newPosition: Coordinate = this.getRelativePosition($event.clientX, $event.clientY);
    const newScaledPosition = new Coordinate(newPosition.x * this.scaleModifier, newPosition.y * this.scaleModifier);
    const diff = newScaledPosition.minus(new Coordinate(draggedNode.x, draggedNode.y));
    let lastChangeNode: SensorNodeChangeHistory;

    draggedNode.update(newScaledPosition.x, newScaledPosition.y);

    if (this.isMoveAllModeActive) {
      this.nodes.forEach((floorplanSensorNode) => {
        if (floorplanSensorNode.id != draggedNode.id) {
          lastChangeNode = lastChange.get(floorplanSensorNode.id);
          if (!lastChangeNode) {
            lastChangeNode = new SensorNodeChangeHistory(floorplanSensorNode);
          }

          lastChangeNode.node.update(floorplanSensorNode.x + diff.x, floorplanSensorNode.y + diff.y);
          lastChange.set(floorplanSensorNode.id, lastChangeNode);
        }
      });
    }
  }

  private endSelecting() {
    const nodesInSelectedArea = this.nodesService.getNodesInArea(this.nodes, this.currentSelectionArea);
    const selectedNodesInSelectedArea = this.nodesService.getSelectedNodesInArea(this.nodes, this.currentSelectionArea);

    const emDriversInSelectedArea = this.nodesService.getEmDriversInArea(
      this.nodes.flatMap((n) => n.emDrivers),
      this.currentSelectionArea
    );
    const selectedEmDriversInSelectedArea = this.nodesService.getSelectedEmDriversInArea(
      this.nodes.flatMap((n) => n.emDrivers),
      this.currentSelectionArea
    );

    if (nodesInSelectedArea.length === selectedNodesInSelectedArea.length) {
      this.nodesService.unselectNodesInArea(this.nodes, this.currentSelectionArea);
    } else {
      const onlySelectEmergencyNodes = this.activePage === 'emergency-lighting-groups';
      this.nodesService.selectNodesInArea(
        this.nodes,
        this.currentSelectionArea,
        this.isEmergencyLightingTestModeActive,
        onlySelectEmergencyNodes
      );
    }

    if (emDriversInSelectedArea.length === selectedEmDriversInSelectedArea.length) {
      this.nodesService.unselectEmDriversInArea(
        this.nodes.flatMap((n) => n.emDrivers),
        this.currentSelectionArea
      );
    } else {
      const onlySelectEmergencyNodes = this.activePage === 'emergency-lighting-groups';
      this.nodesService.selectEmDriversInArea(
        this.nodes.flatMap((n) => n.emDrivers),
        this.currentSelectionArea,
        this.isEmergencyLightingTestModeActive,
        onlySelectEmergencyNodes
      );
    }

    this.isSelectionModeActive = false;

    this.updateSelectableContext();

    this.tagService.selection = this.tagService.getTagsFromSelection();
    this.tagService.updateTagList();
    this.isSelecting = false;
    this.isSelectionModeActive = false;
    this.selectionArea = {
      top: '0px',
      left: '0px',
      width: '0px',
      height: '0px'
    };
    this.currentSelectionArea = {
      top: 0,
      left: 0,
      width: 0,
      height: 0
    };
  }

  private validateOffset(offset: number, offsetLimit: number, margin: number): number {
    if (offset < offsetLimit - margin) {
      offset = offsetLimit - margin;
    }
    if (offset > margin) {
      offset = margin;
    }
    return offset;
  }

  private calculateOffsetX(x: number, initialOffset: Offset): number {
    const offset: number = initialOffset.left + (x - initialOffset.x);
    const offsetLimit: number = this.containerSize.width - this.floorplanImage.clientWidth;
    return this.validateOffset(offset, offsetLimit, this.margins.width);
  }

  private calculateOffsetY(y: number, initialOffset: Offset): number {
    const offset: number = this.initialOffset.top + (y - initialOffset.y);
    const offsetLimit: number = this.containerSize.height - this.floorplanImage.clientHeight;
    return this.validateOffset(offset, offsetLimit, this.margins.height);
  }

  private moveFloor(x: number, y: number): boolean {
    if (this.floorplanImage && this.isDragging) {
      this.origin = new Coordinate(
        this.calculateOffsetX(x, this.initialOffset),
        this.calculateOffsetY(y, this.initialOffset)
      );
      this.position = new Coordinate(
        this.containerSize.width / 2 - this.origin.x,
        this.containerSize.height / 2 - this.origin.y
      );
      // this.style.left = this.origin.x + 'px';
      // this.style.top = this.origin.y + 'px';
      this.translate(this.origin.x, this.origin.y);
      this.setInitialOffset(x, y);
      this.updateCenteringForZoomService();
      return false;
    }
  }

  private updateCenteringForZoomService() {
    this.zoomService.change((value) => {
      if (this.isCentered()) {
        value.setCentered();
      } else {
        value.setUnCentered();
      }
    }, OrFloorplanController.KEY);
  }

  private setInitialOffset(x: number, y: number) {
    this.initialOffset = new Offset(this.origin.x, this.origin.y, x, y);
  }

  private toggleDragMode($event, isActive: boolean) {
    if (!this.isSelectionModeActive) {
      this.isDragging = isActive != null ? !!isActive : !this.isDragging;
      if (this.isDragging) {
        this.setInitialOffset($event.clientX, $event.clientY);
        this.pauseEvent();
      }
    }
  }

  private pauseEvent(): boolean {
    // From: http://stackoverflow.com/questions/5429827/how-can-i-prevent-text-element-selection-with-cursor-drag
    const e = window.event;

    if (e.preventDefault) {
      e.preventDefault();
    }

    e.returnValue = false;
    return false;
  }

  private translate(x: number, y: number) {
    this.style.setLeft(x);
    this.style.setTop(y);
  }

  public toggleGatewayMode(state?: boolean) {
    if (state == null) {
      this.isGatewayModeActive = !this.isGatewayModeActive;
    } else {
      this.isGatewayModeActive = state;
    }
  }
}

export class Transform {
  public scale: number;
  public translateX: number;
  public translateY: number;

  constructor(scale: number, translateX?: number, translateY?: number) {
    this.scale = scale;
    this.translateX = translateX;
    this.translateY = translateY;
  }

  toString(): string {
    let style = '';
    if (this.scale) {
      style += 'scale(' + this.scale + ') ';
    }
    if (this.translateX) {
      style += 'translateX(' + this.translateX + 'px) ';
    }
    if (this.translateY) {
      style += 'translateX(' + this.translateY + 'px) ';
    }

    if (!(this.scale || this.translateX || this.translateY)) {
      style = null;
    }
    return style;
  }
}

export class Style {
  private visibility: string;
  private top: string;
  private left: string;
  private width: string;
  private height: string;
  private transform: string;
  private transition: string;

  constructor(
    visibility: boolean,
    transform?: Transform,
    transition?: string,
    top?: number,
    left?: number,
    width?: number,
    height?: number
  ) {
    this.setVisibility(visibility);
    if (transform) this.setTransform(transform);
    if (transition) this.setTransition(transition);
    if (top) this.setTop(top);
    if (left) this.setLeft(left);
    if (width) this.setWidth(width);
    if (height) this.setHeight(height);
  }

  setVisibility(visibility: boolean) {
    if (visibility) {
      this.visibility = 'visible';
    } else {
      this.visibility = 'hidden';
    }
  }

  setTop(top: number) {
    this.top = top + 'px';
  }

  setLeft(left: number) {
    this.left = left + 'px';
  }

  setWidth(width: number) {
    this.width = width + 'px';
  }

  setHeight(height: number) {
    this.height = height + 'px';
  }

  setTransform(transform: Transform) {
    this.transform = transform.toString();
  }

  setTransition(transition: string) {
    this.transition = transition;
  }

  setTransformOrigin(position: Coordinate) {
    if (position) {
      this['transform-origin'] = position.x + 'px ' + position.y + 'px 0';
    } else {
      this['transform-origin'] = null;
    }
  }
}
