import * as angular from 'angular'; // Automatically added
import IScope = angular.IScope;

export class OrInlineFloorsController {
  public isEditable;
  public onChange;
  public options;
  public availableOptions;
  public selection;
  public activeFloor;

  constructor(private scope: IScope) {}

  public $onInit() {
    this.isEditable =
      typeof this.isEditable === 'boolean' ? this.isEditable : false;
    this.scope.$watch('inlineFloors.options', () => {
      this.updateAvailableOptions();
    });
  }

  removeFloor($index) {
    this.selection.splice($index, 1);
    this.updateAvailableOptions();
  }

  addActiveFloor() {
    if (this.activeFloor) {
      this.selection.push(this.activeFloor);
      this.updateAvailableOptions();
    }
  }

  getAvailableOptions() {
    const selection = this.getSelection();
    const options = this.getOptions();
    return options.filter((floor) => {
      return selection.map((item) => item.id).indexOf(floor.value.id) < 0;
    });
  }

  updateAvailableOptions() {
    this.availableOptions = this.getAvailableOptions();
  }

  getSelection() {
    return angular.isArray(this.selection) ? this.selection : [];
  }

  getOptions() {
    return angular.isArray(this.options) ? this.options : [];
  }

  produceFloorLabel(floor) {
    return floor.name ? floor.name : `Floor ${floor.floorNumber}`;
  }
}
