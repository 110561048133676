import { OrFormWeekdayScheduleController } from './OrFormWeekdayScheduleController';

export class OrFormWeekdayScheduleDirective {
  constructor() {
    return {
      bindToController: true,
      controller: ['$scope', 'ScheduleService', 'TagService', 'BuildingService', OrFormWeekdayScheduleController],
      controllerAs: 'schedule',
      replace: true,
      restrict: 'E',
      scope: {
        details: '=',
        onSaveNewSchedule: '&?',
        onDeleteSchedule: '&?',
        onClose: '&?',
        isNew: '=?'
      },
      template: require('raw-loader!./or-form-weekday-schedule.html').default
    };
  }
}
