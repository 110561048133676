import { Component, EventEmitter, Output } from '@angular/core';
import { GridsterComponent, GridsterConfig, GridsterItemComponent } from 'angular-gridster2';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { DashboardService } from '@services/dashboard.service';
import { MatTooltip } from '@angular/material/tooltip';
import { WidgetItem } from '@app/dashboard/model/widget-item';
import { DashboardGridOptions } from '@app/dashboard/dashboard-config';
import { RefreshPageComponent } from '@app/shared/components/refresh-page/refresh-page.component';
import { CountWidgetComponent } from '@app/dashboard/widgets/count-widget/count-widget.component';
import { ChartWidgetComponent } from '@app/dashboard/widgets/chart-widget/chart-widget.component';
import { DashboardStateService } from '../dashboard-filter/dashboard-state.service';

@Component({
  selector: 'app-occupancy',
  standalone: true,
  imports: [
    GridsterComponent,
    GridsterItemComponent,
    MatIcon,
    MatIconButton,
    MatTooltip,
    RefreshPageComponent,
    CountWidgetComponent,
    ChartWidgetComponent
  ],
  templateUrl: './occupancy.component.html',
  styleUrl: './occupancy.component.scss'
})
export class OccupancyComponent {
  gridOptions: GridsterConfig;
  dashboard: Array<WidgetItem>;
  @Output() refreshEvent = new EventEmitter<WidgetItem>();

  constructor(private stateService: DashboardStateService, private dashboardService: DashboardService) {}

  ngOnInit(): void {
    this.setupDashboard();
    this.stateService.dashboardFilterState$.subscribe({
      next: (value) => {
        this.refreshEvent.emit();
      }
    });
  }

  private setupDashboard(): void {
    this.gridOptions = {
      ...DashboardGridOptions
    };
    this.dashboard = this.dashboardService.getDashboardForOccupancy();
  }

  refreshWidget($event?: MouseEvent | TouchEvent, item?: WidgetItem): void {
    $event?.preventDefault();
    $event?.stopPropagation();
    this.refreshEvent.emit(item);
  }
}
