import * as angular from 'angular'; // Automatically added
import { Tag, TagType } from '@angularjs/or/api/building/Tag';
import { SecurityService } from '@angularjs/or/angular/services/SecurityService';
import { BuildingAuthorityType } from '@app/shared/models/building-authority-type';
import { IBuildingService } from '@angularjs/or/services/IBuildingService';
import IScope = angular.IScope;

export class OrInlineTagsController {
  public isEditable;
  public onChange;
  public options;
  public availableOptions;
  public selection;
  public activeTag: Tag;

  private tagsAdded: number[];
  private manageTenants = false;
  private editTenantMadeTags = false;

  constructor(
    private buildingService: IBuildingService,
    private securityService: SecurityService,
    private scope: IScope
  ) {}

  public $onInit(): void {
    this.tagsAdded = [];
    this.isEditable = typeof this.isEditable === 'boolean' ? this.isEditable : false;
    this.buildingService.getCurrentBuilding().then((building) => {
      this.securityService
        .isAuthorizedForBuilding(BuildingAuthorityType.MANAGE_TENANT.value, building)
        .then((result) => {
          this.manageTenants = result;
        });
      this.securityService.canSeeTenantMadeTags(building.id).then((result) => {
        this.editTenantMadeTags = result;
      });
    });
    this.scope.$watch('inlineTags.options', () => this.updateAvailableOptions());
  }

  canEdit(tag: Tag): boolean {
    if (this.isEditable && this.isOfTenantType(tag)) {
      if (this.tagsAdded.includes(tag.id)) {
        return true;
      } else {
        return this.manageTenants;
      }
    } else if (this.isEditable && this.isOfTenantMadeType(tag)) {
      return this.editTenantMadeTags;
    } else {
      return this.isEditable;
    }
  }

  removeTag($index) {
    this.selection.splice($index, 1);
    this.updateAvailableOptions();
  }

  addActiveTag() {
    if (this.activeTag) {
      this.tagsAdded.push(this.activeTag.id);
      this.selection.push(this.activeTag);
      this.updateAvailableOptions();
    }
  }

  getAvailableOptions() {
    const selection = this.getSelection();
    const options = this.getOptions();
    return options.filter((option) => {
      let check = true;
      if (option.value.tagType === TagType.TENANT) {
        check = this.selection.filter((t) => t.tagType === TagType.TENANT).length === 0;
      }
      return check && this.selection.map((item) => item.id).indexOf(option.value.id) < 0;
    });
  }

  updateAvailableOptions() {
    this.availableOptions = this.getAvailableOptions();
  }

  getSelection() {
    return angular.isArray(this.selection) ? this.selection : [];
  }

  getOptions() {
    return angular.isArray(this.options) ? this.options : [];
  }

  public isOfTenantType(tag: Tag): boolean {
    return tag.tagType === TagType.TENANT;
  }

  public isOfTenantMadeType(tag: Tag): boolean {
    return tag.tagType === TagType.TENANT_MADE;
  }
}
