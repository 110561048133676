import { TagService } from '@angularjs/or/services/TagService';
import { Tag } from '@angularjs/or/api/building/Tag';
import { IBuildingService } from '@angularjs/or/services/IBuildingService';
import { ColorUtils } from '@angularjs/or/util/ColorUtils';
import { IFormController } from 'angular';
import IScope = angular.IScope;

export class OrFormEditTagDirective {
  constructor() {
    return {
      bindToController: true,
      controller: [
        '$scope',
        'BuildingService',
        'TagService',
        OrFormEditTagController
      ],
      controllerAs: 'editTag',
      replace: true,
      restrict: 'E',
      scope: {},
      template: require('raw-loader!./or-form-edit-tag.html').default
    };
  }
}

export class OrFormEditTagController {
  private buildingId;
  public tag: Tag;
  public isDirty = false;
  public form: IFormController;

  constructor(
    private scope: IScope,
    private buildingService: IBuildingService,
    private tagService: TagService
  ) {
    this.buildingService.getCurrentBuilding().then((building) => {
      this.scope.$apply(() => {
        this.buildingId = building.id;
        this.reset();
      });
    });
  }

  public create() {
    this.tagService.addTag(this.tag).then(() => {
      this.scope.$apply(() => {
        this.reset();
        this.isDirty = true;
      });
    });
  }

  public reset() {
    this.tag = new Tag(this.buildingId);
  }

  public cycleColor() {
    this.tag.color = ColorUtils.generateColor();
  }
}
