import {
  SimpleLampType,
  SimpleLampTypeOutline
} from '@angularjs/or/api/building/LampType';
import { IBuildingService } from '@angularjs/or/services/IBuildingService';
import { LampTypeService } from '@angularjs/or/services/LampTypeService';
import { SensorNodeService } from '@angularjs/or/services/SensorNodeService';
import { LampTypeTemplate } from '@angularjs/or/api/building/ILampTypeTemplate';
import { Building } from '@angularjs/or/api/building/Building';
import { FloorplanSensorNode } from '@angularjs/or/api/building/FloorplanSensorNode';
import IFormController = angular.IFormController;

export class OrFormLampTypeDirective {
  constructor() {
    return {
      bindToController: true,
      controller: [
        '$scope',
        'LampTypeService',
        'BuildingService',
        'SensorNodeService',
        OrFormLampTypeController
      ],
      controllerAs: 'lampType',
      replace: true,
      restrict: 'E',
      scope: {},
      template: require('raw-loader!./or-form-lamp-type.html').default
    };
  }
}

export class OrFormLampTypeController {
  private building: Building;

  public form: IFormController;
  public details: SimpleLampType;
  public templates: LampTypeTemplate[];
  public isSingleTypeSelected: boolean;
  public areLampTypesSelected: boolean;
  public areNodesSelected: boolean;

  constructor(
    private $scope: ng.IScope,
    private lampTypeService: LampTypeService,
    private buildingService: IBuildingService,
    private nodeService: SensorNodeService
  ) {
    this.reset();
    this.initialize();
  }

  private initialize() {
    this.buildingService.getCurrentBuilding().then((building) =>
      this.lampTypeService.getTemplates(building.id).then((templates) => {
        this.$scope.$apply(() => {
          this.building = building;
          this.templates = templates;
        });
      })
    );

    this.nodeService.selectedNodes.onChange((nodes: FloorplanSensorNode[]) => {
      this.areNodesSelected = nodes.length > 0;
      const lampTypes = new Set(
        nodes
          .flatMap((node) => node.luminaireDrivers)
          .filter((driver) => driver && driver.lampTypeId && driver.lampType)
          .map((driver) => driver.lampType)
      );

      this.isSingleTypeSelected = lampTypes.size == 1;
      this.areLampTypesSelected = lampTypes.size > 0;

      if (this.templates) {
        lampTypes.forEach(
          (lampType) =>
            (lampType.template = this.templates.filter(
              (template) => lampType.lampTypeTemplateId === template.id
            )[0])
        );
      }

      const lampType = lampTypes.values().next().value;
      if (this.isSingleTypeSelected && lampType != null) {
        this.details = lampType;
      } else {
        this.reset();
      }
    });
  }

  public save() {
    this.details.sensorNodes = this.nodeService.selectedNodes
      .value()
      .map((node) => node.id);
    this.details.building = this.building.id;

    this.lampTypeService
      .saveLampType(
        SimpleLampTypeOutline.fromSimpleLampType(this.details),
        this.building.id
      )
      .then((savedLampType) => {
        if (savedLampType) {
          this.details.lampTypeId = savedLampType.lampTypeId;
          this.details.name = savedLampType.name;
          savedLampType.sensorNodes = this.details.sensorNodes;

          this.nodeService.updateLampTypes(savedLampType);
          this.details.sensorNodes = [];
        } else {
          this.details = null;
        }
      });
  }

  public reset() {
    this.details = new SimpleLampType();
  }
}
