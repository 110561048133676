import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import {
  IOtapUpgradeDTO,
  OtapUpgradeDTO
} from '@app/shared/models/otap.upgrade.interface';
import { OtapUpgradeService } from '@services/otap-upgrade/otap.upgrade.service';

export class OtapUpgradesDatasource implements DataSource<IOtapUpgradeDTO> {
  private otapUpgradesSubject = new BehaviorSubject<IOtapUpgradeDTO[]>([]);
  private jobs: IOtapUpgradeDTO[] = [];
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  private countSubject = new BehaviorSubject<number>(0);
  public counter$ = this.countSubject.asObservable();

  constructor(private otapUpgradeServices: OtapUpgradeService) {}

  loadOtapUpgrades(pageIndex: number, pageSize: number) {
    this.loadingSubject.next(true);

    this.otapUpgradeServices
      .getJobs()
      .pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((res) => {
        const jobs = [];
        res.forEach((item) => {
          jobs.push(new OtapUpgradeDTO(item));
        });
        this.jobs = jobs;
        this.countSubject.next(jobs.length);
        this.updatePaginatedData(pageIndex, pageSize);
      });
  }

  updatePaginatedData(pageIndex: number, pageSize: number) {
    const startIndex = pageIndex * pageSize;
    const endIndex = startIndex + pageSize;
    this.otapUpgradesSubject.next(this.jobs.slice(startIndex, endIndex));
  }

  connect(collectionViewer: CollectionViewer): Observable<IOtapUpgradeDTO[]> {
    return this.otapUpgradesSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.otapUpgradesSubject.complete();
    this.loadingSubject.complete();
    this.countSubject.complete();
  }
}
