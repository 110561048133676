<form
  class="or-form or-form-mobile-token-details"
  [formGroup]="tokenForm"
  [ngClass]='{ "or-form-mobile-token-details-enable-labels": index === 0 }'
  novalidate="novalidate"
  [attr.data-cy]="isNew ? 'new-mobile-token-form' : 'existing-mobile-token-form'"
>
  <ul class="or-form-items">
    <li class="or-form-item or-form-mobile-token-details-token">
      <span class="or-label or-label-above" data-cy='label-token'>Token</span
      ><input
      class="or-input"
      maxlength="256"
      formControlName='token'
      placeholder="WILL BE GENERATED AUTOMATICALLY"
      type="text"
      data-cy='input-mobile-token'
    />
    </li>
    <li class="or-form-item or-form-mobile-token-details-description">
      <span class="or-label or-label-above" data-cy='label-description'>Description</span
      ><input
      class="or-input"
      maxlength="256"
      formControlName='description'
      placeholder=""
      type="text"
      data-cy='input-token-description'
    />
    </li>
    <li
      class="or-form-item or-form-mobile-token-details-actions"
      *ngIf="!isDeactivated"
    >
      <ul class="or-inline-items">
        <li class="or-inline-item" *ngIf='isNew'>
          <button
            mat-raised-button
            class="or-button or-iconic or-primary or-positive"
            type="button"
            (click)="createToken()"
            [disabled]="isBusy || tokenForm.pristine || tokenForm.invalid ? true : null"
            title="Generate Token"
            data-cy='btn-create-token'
          >
            <span class="or-icon or-small or-inverse or-icon-plus"></span>
          </button>
        </li>
        <li class="or-inline-item" *ngIf='!isNew'>
          <button
            mat-raised-button
            class="or-button or-iconic or-primary or-positive"
            type="button"
            (click)="updateToken()"
            [disabled]="isBusy || tokenForm.pristine || tokenForm.invalid ? true : null"
            title="Update Token"
            data-cy='btn-update-token'
          >
            <span class="or-icon or-small or-inverse or-icon-checkmark"></span>
          </button>
        </li>
        <li class="or-inline-item">
          <button
            mat-raised-button
            color='accent'
            class="or-button or-iconic"
            type="button"
            (click)="resetToken()"
            [disabled]="isBusy || tokenForm.pristine ? true : null"
            title="Reset Changes"
            data-cy='btn-reset-token'
          >
            <span class="or-icon or-small or-icon-reload"></span>
          </button>
        </li>
        <li class="or-inline-item" *ngIf="!isNew">
          <button
            mat-raised-button
            class="or-button or-iconic or-primary or-deactivate"
            type="button"
            (click)="deactivateToken()"
            [disabled]="isBusy ? true : null"
            title="Deactivate Token"
            data-cy='btn-deactivate-token'
          >
            <span class="or-icon or-small or-inverse or-icon-deactivate"></span>
          </button>
        </li>
      </ul>
    </li>
  </ul>
</form>
