import { DataType } from '@app/shared/models/sensor-node-data-type';

export enum MetricType {
  PRESENCE = 'PRESENCE',
  OCCUPANCY = 'OCCUPANCY',
  DIM_LEVEL = 'DIM_LEVEL',
  LIGHT_LEVEL = 'LIGHT_LEVEL',
  ENERGY_CONSUMPTION = 'ENERGY_CONSUMPTION',
  CONNECTION_QUALITY = 'CONNECTION_QUALITY'
}

export interface Metric {
  name: string;
  label: string;
  color: string;
  className: string;
  iconClass: string;
  dataType: DataType;
}

export class MetricsTypeProvider {
  public static metrics: Record<MetricType, Metric> = {
    PRESENCE: {
      name: 'PRESENCE',
      label: 'Presence',
      color: '#f44',
      className: 'or-metric-presence',
      iconClass: 'or-icon-presence',
      dataType: DataType.PRESENCE
    },
    OCCUPANCY: {
      name: 'OCCUPANCY',
      label: 'Occupancy',
      color: '#f80',
      className: 'or-metric-occupancy',
      iconClass: 'or-icon-users',
      dataType: DataType.OCCUPANCY
    },
    DIM_LEVEL: {
      name: 'DIM_LEVEL',
      label: 'Light Level',
      color: '#693',
      className: 'or-metric-dim-level',
      iconClass: 'or-icon-light-bulb',
      dataType: DataType.DIM_LEVEL
    },
    LIGHT_LEVEL: {
      name: 'LIGHT_LEVEL',
      label: 'Ambient Light',
      color: '#399',
      className: 'or-metric-light-level',
      iconClass: 'or-icon-sun',
      dataType: DataType.LIGHT_LEVEL
    },
    ENERGY_CONSUMPTION: {
      name: 'ENERGY_CONSUMPTION',
      label: 'Energy Consumption',
      color: '#369',
      className: 'or-metric-energy-consumption',
      iconClass: 'or-icon-energy',
      dataType: DataType.ENERGY_CONSUMPTION
    },
    CONNECTION_QUALITY: {
      name: 'CONNECTION_QUALITY',
      label: 'Connection Quality',
      color: '#639',
      className: 'or-metric-connection-quality',
      iconClass: 'or-icon-connection-quality',
      dataType: DataType.CONNECTION_QUALITY
    }
  };
}
