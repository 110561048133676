<div id='job-tasks-list'>
  <div class='or-content-body'>
    <div class='job-tasks-list'>
      <div *ngFor='let task of tasks; let i = index'>
        <app-job-tasks-summary
          [job]='job'
          [task]='task'
          [index]='i'
          [jobStartEnabled] = 'jobStartEnabled'>
        </app-job-tasks-summary>
      </div>
    </div>
  </div>
</div>
