import { ISimpleService } from '../api/ISimpleService';
import IHttpPromise = angular.IHttpPromise;

export class SimpleService<GET, POST, PUT, DELETE>
  implements ISimpleService<GET, POST, PUT, DELETE>
{
  constructor(private $http: ng.IHttpService) {}

  get(url: string): Promise<GET> {
    return new Promise<GET>((resolve, reject) => {
      const promise: IHttpPromise<GET> = this.$http.get(url);
      promise
        .then((result) => {
          resolve(result.data);
        })
        .catch((reason) => reject(reason.status));
    });
  }

  post(url: string, data?: any): Promise<POST> {
    return new Promise<POST>((resolve, reject) => {
      const promise: IHttpPromise<POST> = this.$http.post(url, data);
      promise
        .then((result) => resolve(result.data))
        .catch((reason) => reject(reason.status));
    });
  }

  put(url: string, data?: any): Promise<PUT> {
    return new Promise<PUT>((resolve, reject) => {
      const promise: IHttpPromise<PUT> = this.$http.put(url, data);
      promise
        .then((result) => resolve(result.data))
        .catch((reason) => reject(reason.status));
    });
  }

  delete(url: string, data?: any): Promise<DELETE> {
    return new Promise<DELETE>((resolve, reject) => {
      const promise: IHttpPromise<DELETE> = this.$http.delete(url, data);
      promise
        .then((result) => resolve(result.data))
        .catch((reason) => reject(reason.status));
    });
  }
}
