import * as angular from 'angular';
import { EmergencyLightingTestScheduleOutline } from '../../api/building/EmergencyLightingTestSchedule';
import { FloorService } from '../FloorService';

export class LightingTestSchedulingService {
  private endpoints = {
    schedule: '/api/v1/emergency-lighting-test-schedule'
  };

  constructor(
    private $http: angular.IHttpService,
    private floorService: FloorService,
    private generateApiUrl: (endpoint: string) => string
  ) {}

  public updateSchedule(schedule: EmergencyLightingTestScheduleOutline) {
    if (!schedule) {
      console.error('LightingTestSchedulingService: No schedule provided');
      return Promise.reject(schedule);
    }
    const endpoint = this.endpoints.schedule;
    return new Promise((resolve, reject) => {
      this.$http
        .put(this.generateApiUrl(`${endpoint}/${schedule.id}`), schedule)
        .then((response) => resolve(response))
        .catch((e) => reject(e));
    });
  }

  public getSchedules(): Promise<EmergencyLightingTestScheduleOutline[]> {
    return new Promise((resolve, reject) => {
      this.floorService
        .getCurrentFloor()
        .then(
          (floor) => {
            const endpoint = this.endpoints.schedule;
            this.$http
              .get<EmergencyLightingTestScheduleOutline[]>(
                this.generateApiUrl(`${endpoint}/${floor.id}`)
              )
              .then((response) => {
                resolve(response.data);
              })
              .catch((e) => reject(e));
          },
          (reason) => console.warn(reason)
        )
        .catch((response) => {
          console.error({ getSchedules: response });
          reject(response);
        });
    });
  }

  public getSchedule(
    scheduleId: number
  ): Promise<EmergencyLightingTestScheduleOutline> {
    return new Promise((resolve, reject) => {
      const endpoint = this.endpoints.schedule;
      this.$http
        .get<EmergencyLightingTestScheduleOutline>(
          this.generateApiUrl(`${endpoint}/schedule/${scheduleId}`)
        )
        .then((response) => resolve(response.data))
        .catch((e) => reject(e));
    });
  }
}
